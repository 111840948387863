import React, { useEffect, useState } from "react";
import { SnapIcon } from "suit";
import { useContextStrict } from "helpers/context-strict";
import SplitIOContext from "context/splitio-context";
import { emptyStringCheck } from "helpers/empty-string-check";

type BudgetCategoryProps = {
  id: string;
  text: string;
  locked: boolean;
  otherCategories: Set<string>;
  handleChange: (inputValue: string, isHidden: boolean) => void;
  setIsSaved: React.Dispatch<React.SetStateAction<boolean>> | undefined;
  canEditCategories: boolean;
};

function BudgetCategory({
  id,
  text,
  locked,
  handleChange,
  otherCategories,
  setIsSaved,
  canEditCategories,
}: BudgetCategoryProps) {
  const [inputValue, setInputValue] = useState(text);
  const [error, setError] = useState(false);

  useEffect(() => {
    setInputValue(text);
  }, [text]);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setError(false);
    const { value } = e.target;
    if (otherCategories.has(value.toLowerCase())) {
      setError(true);
    } else {
      setInputValue(value);
      if (setIsSaved) {
        setIsSaved(false);
      }
    }
  };

  const splits = useContextStrict(SplitIOContext);
  useEffect(() => {
    setSe456flag(splits.isTreatmentOn(splits.act.se456));
  }, [splits]);

  const [se456flag, setSe456flag] = useState<boolean | undefined>();

  const isSave = () => {
    if (se456flag) return id === "";
    else return id === undefined || (text !== inputValue && id !== undefined);
  };

  return (
    <>
      {locked && (
        <div className="flex flex-row mt-4">
          <p className="flex border border-gray-200 text-gray-400 py-2 pl-3 rounded-l-xl w-full">
            <SnapIcon
              icon="lock-closed-solid"
              size="sm"
              className="flex self-center pr-3"
            />
            {text}
          </p>
          <button
            className="border border-gray-200 bg-blue-100 border-l-0 rounded-r-xl px-4 w-24"
            onClick={() => handleChange(text, !locked)}
          >
            Enable
          </button>
        </div>
      )}
      {!locked && (
        <>
          <div className="flex flex-row mt-4">
            <input
              disabled={!canEditCategories}
              className={`border py-2 pl-3 rounded-l-xl w-full ${
                error ? "border-red-300" : "border-gray-300"
              }`}
              defaultValue={text}
              onChange={onChange}
            />
            {isSave() ? (
              <button
                disabled={!canEditCategories || error}
                className="border border-gray-300 rounded-r-xl py-2 px-4 w-24 bg-blue-600 text-white"
                onClick={() => {
                  if (emptyStringCheck(inputValue)) {
                    setError(true);
                  } else {
                    handleChange(inputValue, locked);
                  }
                }}
              >
                Save
              </button>
            ) : (
              <button
                disabled={!canEditCategories || error}
                className="border border-gray-300 rounded-r-xl py-2 px-4 border-l-0 w-24"
                onClick={() => handleChange(text, !locked)}
              >
                Disable
              </button>
            )}
          </div>
          {error && emptyStringCheck(inputValue) ? (
            <p className="text-red-500 text-sm">
              Category name can not be empty
            </p>
          ) : error ? (
            <p className="text-red-500 text-sm">
              Category names must be unique
            </p>
          ) : text === "" ? (
            <p className="text-sm text-gray-500">
              Note: Changes aren't saved automatically
            </p>
          ) : null}
        </>
      )}
    </>
  );
}

export default BudgetCategory;
