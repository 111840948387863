import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { SnapMainNavigation } from "suit";
import { MainNavigationUpdatedEvent } from "suit/components";
import useSplitIO from "hooks/use-splitio";
import usePathToNav from "hooks/use-path-to-nav";
import useModal from "hooks/use-modal";
import { useSideNavigation } from "hooks/side-navigation";
import UserContext from "context/user-context";
import ProgramContext from "context/program-context";
import DisplayContext from "context/display-context";

type MainNavigationProps = {
  isAuthorized: boolean;
  userHasExpiredInvite: boolean;
};

function MainNavigation({
  isAuthorized,
  userHasExpiredInvite,
}: MainNavigationProps) {
  const navigate = useNavigate();

  const user = useContext(UserContext);
  const program = useContext(ProgramContext);
  const display = useContext(DisplayContext);
  const sideNavOptions = useSideNavigation(user);

  const splits = useSplitIO(program?.organization?.id, user?.getUserId());

  const [splitsReady, setSplitsReady] = useState(false);
  const [Se456flag, setSe456flag] = useState<boolean | undefined>();

  const { setPathToNav } = usePathToNav();
  const { toggle } = useModal();

  useEffect(() => {
    splits.isReady?.then(() => setSplitsReady(true));
  }, [setSplitsReady, splits]);

  useEffect(() => {
    if (splitsReady) {
      setSe456flag(splits.isTreatmentOn(splits.act.se456));
    }
  }, [splits, splitsReady]);

  return (
    <SnapMainNavigation
      onNavigationupdate={(event: CustomEvent<MainNavigationUpdatedEvent>) => {
        if (!Se456flag) {
          !!event.detail.path && navigate(event.detail.path);
        } else {
          if (display?.settingsSaved) {
            !!event.detail.path && navigate(event.detail.path);
          } else {
            setPathToNav(event.detail.path ?? "");
            toggle();
          }
        }
      }}
      theme="spend"
      navigationOptionsInput={
        isAuthorized && !userHasExpiredInvite ? sideNavOptions : []
      }
    />
  );
}

export default MainNavigation;
