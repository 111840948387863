import { DropdownMenuItem } from "@snap-mobile/snap-ui/dist/types/utils";
import GroupContext from "context/group-context";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DataCard from "shared-components/data-card";
import { HorizontalValueStyle } from "shared-components/horizontal-label-value";
import { SpinnerContainer } from "shared-components/spinner";
import { VerticalValueStyle } from "shared-components/vertical-label-value";
import { inviteStatusToText } from "helpers/status-text";
import { InvitePlus } from "types/invite";
import { useContextStrict } from "helpers/context-strict";
import SplitIOContext from "context/splitio-context";

type CardsProps = {
  teamUsers: InvitePlus[];
  handleCheckboxAction: (idx: number, data: InvitePlus) => void;
  handleRemoveUser: (idx: number) => void;
  menuItems: DropdownMenuItem[];
  changeRoleToggle: () => void;
  setMenuClickData: React.Dispatch<React.SetStateAction<InvitePlus | null>>;
  canUpdateGroupUser: boolean;
  loading: boolean;
  enableApproverToggle: () => void;
  disableApproverToggle: () => void;
};

function Cards({
  teamUsers,
  handleCheckboxAction,
  handleRemoveUser,
  menuItems,
  changeRoleToggle,
  setMenuClickData,
  canUpdateGroupUser,
  loading,
  enableApproverToggle,
  disableApproverToggle,
}: CardsProps) {
  const splits = useContextStrict(SplitIOContext);
  const [spe1288DualApproval, setSPE1288DualApproval] = useState(false);
  useEffect(() => {
    setSPE1288DualApproval(splits.isTreatmentOn(splits.act.spe1288));
  }, [splits]);

  const navigate = useNavigate();
  const Group = useContext(GroupContext);
  const navigateGroupLink = (groupId: string) => {
    if (Group && !Group.activeGroup) {
      const group = Group?.groups?.find((gr) => gr.id === groupId);
      Group.setAndStoreActiveGroup(group);
    }
    navigate("/groups/staff");
  };
  const menuAction = (value: any, tUser: InvitePlus, idx: number) => {
    setMenuClickData(tUser);
    if (value === "changeRole") {
      changeRoleToggle();
    } else if (value === "removePermissions") {
      handleRemoveUser(idx);
    } else if (value === "disableApprover") {
      disableApproverToggle();
    } else if (value === "enableApprover") {
      enableApproverToggle();
    }
  };
  return (
    <div className="mb-4 lg:mb-0  lg:hidden">
      <SpinnerContainer loading={loading} />
      {teamUsers.map((tUser, idx) => {
        const approverMenuItem: DropdownMenuItem = {
          name: `${tUser.isApprover ? "Disable" : "Enable"} Approver`,
          text: `${tUser.isApprover ? "Disable" : "Enable"} Approver`,
          value: `${tUser.isApprover ? "disable" : "enable"}Approver`,
        };

        const newMenuItems = [...menuItems, approverMenuItem];
        return (
          <DataCard
            className={`flex ${
              tUser.isChecked && "border-blue-400 bg-blue-50"
            }`}
            hasCheckbox={canUpdateGroupUser}
            isChecked={tUser.isChecked}
            checkboxAction={() => handleCheckboxAction(idx, tUser)}
            key={tUser.id || idx}
            title={`${tUser.firstName} ${tUser.lastName}`}
            subTitle={tUser.email ?? ""}
            kvLeft={[
              {
                key: "Role",
                value: "Group Assistant",
                customContainerStyle: "flex flex-col",
                className: "ml-0 mb-2",
              },
              {
                key: "Debit Card",
                value:
                  Number(tUser.debitCard) % 2 === 0 ? "Unassigned" : "Assigned",
                customContainerStyle: "flex flex-col",
                className: "ml-0 mb-2",
              },
              {
                key: "Group",
                value: tUser.group?.name ?? "",
                valueStyle: HorizontalValueStyle.Link,
                customContainerStyle: "flex flex-col",
                className: "ml-0",
                labelAction: () => {
                  navigateGroupLink(tUser.group?.id ?? "");
                },
              },
            ]}
            kvRight={[
              {
                key: "Status",
                value: inviteStatusToText(tUser.status ?? ""),
                valueStyle: VerticalValueStyle.Badge,
              },
            ]}
            action={1}
            menuItems={spe1288DualApproval ? newMenuItems : menuItems}
            menuClickListener={(e) => {
              menuAction(e, tUser, idx);
            }}
          />
        );
      })}
    </div>
  );
}

export default Cards;
