import { DropdownMenuItem } from "@snap-mobile/snap-ui/dist/types/utils";
import React, { useEffect, useState } from "react";
import DataCard from "shared-components/data-card";
import { LabelValueObject } from "types/label-value-object";
import { SpinnerContainer } from "shared-components/spinner";
import { InvitePlus } from "types/invite";
import { useContextStrict } from "helpers/context-strict";
import SplitIOContext from "context/splitio-context";

type CardsProps = {
  collectionData: InvitePlus[];
  getBadge: (status: string) => JSX.Element;
  prepLeftData: (cd: InvitePlus) => LabelValueObject[];
  menuItems: DropdownMenuItem[];
  setMenuClickData: React.Dispatch<React.SetStateAction<InvitePlus | null>>;
  removeUserToggle: () => void;
  changeRoleToggle: () => void;
  loading: boolean;
  handleCheckboxAction: (idx: number, data: InvitePlus) => void;
  enableApproverToggle: () => void;
  disableApproverToggle: () => void;
};

function Cards({
  collectionData,
  getBadge,
  prepLeftData,
  menuItems,
  setMenuClickData,
  removeUserToggle,
  changeRoleToggle,
  loading,
  handleCheckboxAction,
  enableApproverToggle,
  disableApproverToggle,
}: CardsProps) {
  const splits = useContextStrict(SplitIOContext);
  const [spe1288DualApproval, setSPE1288DualApproval] = useState(false);
  useEffect(() => {
    setSPE1288DualApproval(splits.isTreatmentOn(splits.act.spe1288));
  }, [splits]);

  const menuAction = (value: any, cd: InvitePlus) => {
    setMenuClickData(cd);
    if (value === "removePermissions") {
      removeUserToggle();
    } else if (value === "changeRole") {
      changeRoleToggle();
    } else if (value === "disableApprover") {
      disableApproverToggle();
    } else if (value === "enableApprover") {
      enableApproverToggle();
    }
  };
  return (
    <div className="mb-4 lg:mb-0">
      <SpinnerContainer loading={loading} />
      {!loading &&
        collectionData.map((cd, idx) => {
          const approverMenuItem: DropdownMenuItem = {
            name: `${cd.isApprover ? "Disable" : "Enable"} Approver`,
            text: `${cd.isApprover ? "Disable" : "Enable"} Approver`,
            value: `${cd.isApprover ? "disable" : "enable"}Approver`,
          };

          const newMenuItems = [...menuItems, approverMenuItem];
          return (
            <DataCard
              key={idx}
              title={`${cd.firstName} ${cd.lastName}`}
              titleExtra={getBadge(cd?.status || "")}
              titleHasBorder={false}
              kvLeft={prepLeftData(cd)}
              kvRight={[]}
              action={1}
              menuItems={spe1288DualApproval ? newMenuItems : menuItems}
              isArchived={!!cd?.isArchived}
              menuClickListener={(value) => menuAction(value, cd)}
              className={`flex lg:hidden ${
                cd.isChecked && "bg-blue-50 border-blue-400"
              }`}
              hasCheckbox
              isChecked={cd.isChecked}
              checkboxAction={() => handleCheckboxAction(idx, cd)}
            />
          );
        })}
    </div>
  );
}

export default Cards;
