import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  BigInt: any;
  Buffer: any;
  Date: any;
  DateTime: any;
  DateType: any;
  Decimal: any;
  /** The `Int32` scalar type represents non-fractional signed whole numeric values. Int32 can represent values between -(2^31) and 2^31 - 1.  */
  Int32: any;
  /** The `Int64` scalar type represents non-fractional signed whole numeric values. Int64 can represent values between -(2^63) and 2^63 - 1.  */
  Int64: any;
  JSON: any;
  JSONObject: any;
  NonEmptyString: any;
  Time: any;
  /** A `Timestamp` represents a point in time independent of any time zone or calendar, represented as seconds and fractions of seconds at nanosecond resolution in UTC Epoch time. */
  Timestamp: any;
  UUID: any;
  Uint64: any;
  Upload: any;
  Void: any;
  udid: any;
};

export type Account = ExternalBankAccount | StripeAccount;

/** Account balance information */
export type AccountBalanceReturn = {
  __typename?: "AccountBalanceReturn";
  /** Available balance in cents */
  available?: Maybe<Scalars["Int"]>;
  /** Pending balance in cents */
  pending?: Maybe<Scalars["Int"]>;
  type?: Maybe<Scalars["String"]>;
};

export type AccountManager = DriveUser & {
  __typename?: "AccountManager";
  email?: Maybe<Scalars["String"]>;
  firstName?: Maybe<Scalars["String"]>;
  lastName?: Maybe<Scalars["String"]>;
  phone?: Maybe<Scalars["String"]>;
};

export type AccountTransactionsInput = {
  accountId: Scalars["String"];
  createdAfter?: InputMaybe<Scalars["Timestamp"]>;
  createdBefore?: InputMaybe<Scalars["Timestamp"]>;
  exclude?: InputMaybe<Array<InputMaybe<TransactionFilterType>>>;
  externalId?: InputMaybe<Scalars["String"]>;
  metadata?: InputMaybe<Scalars["JSONObject"]>;
  status?: InputMaybe<Status>;
};

export type AccountTransactionsPaginatedInput = {
  accountId: Scalars["String"];
  createdAfter?: InputMaybe<Scalars["Timestamp"]>;
  createdBefore: Scalars["Timestamp"];
  cursor?: InputMaybe<Scalars["String"]>;
  /** Currently only support 1000 */
  pageSize?: InputMaybe<Scalars["Int"]>;
  status?: InputMaybe<Status>;
};

export type AccountTransactionsPaginatedOutput = {
  __typename?: "AccountTransactionsPaginatedOutput";
  nextCursor?: Maybe<Scalars["String"]>;
  transactions: Array<Maybe<Transaction>>;
};

export enum AccountType {
  ExternalBank = "EXTERNAL_BANK",
  /** more TK */
  Stripe = "STRIPE",
}

export type AdhocGiftShopIntentInput = {
  fundraiserId: Scalars["Int"];
  fundraiserUserId?: InputMaybe<Scalars["Int"]>;
  participantUserDirectoryId?: InputMaybe<Scalars["String"]>;
  status: PurchaseIntentStatus;
  userId?: InputMaybe<Scalars["Int"]>;
};

/** See CampaignMembership for details */
export type AdminCampaign = {
  __typename?: "AdminCampaign";
  id: Scalars["ID"];
};

export enum Affiliate {
  Booster = "BOOSTER",
  Foundation = "FOUNDATION",
  Other = "OTHER",
  ParentAssociation = "PARENT_ASSOCIATION",
  ParentOrganization = "PARENT_ORGANIZATION",
  ParentTeacherAssociation = "PARENT_TEACHER_ASSOCIATION",
  ParentTeacherOrganization = "PARENT_TEACHER_ORGANIZATION",
  ParentTeacherStudentAssociation = "PARENT_TEACHER_STUDENT_ASSOCIATION",
  ParentTeacherStudentOrganization = "PARENT_TEACHER_STUDENT_ORGANIZATION",
}

export enum AffiliationTitle {
  ActivitiesDirector = "ACTIVITIES_DIRECTOR",
  AdministrativeAssistant = "ADMINISTRATIVE_ASSISTANT",
  AssistantCoach = "ASSISTANT_COACH",
  AthleticDirector = "ATHLETIC_DIRECTOR",
  BoosterClubLeader = "BOOSTER_CLUB_LEADER",
  BoosterClubMember = "BOOSTER_CLUB_MEMBER",
  BusinessOwner = "BUSINESS_OWNER",
  ClubSportsAdministrator = "CLUB_SPORTS_ADMINISTRATOR",
  ClubSportsDirector = "CLUB_SPORTS_DIRECTOR",
  ClubSportEmployee = "CLUB_SPORT_EMPLOYEE",
  Coach = "COACH",
  DistrictAdministrator = "DISTRICT_ADMINISTRATOR",
  FinancialAdministrator = "FINANCIAL_ADMINISTRATOR",
  FineArtsDirector = "FINE_ARTS_DIRECTOR",
  Other = "OTHER",
  Parent = "PARENT",
  President = "PRESIDENT",
  SchoolAdministrator = "SCHOOL_ADMINISTRATOR",
  SchoolClubAdvisor = "SCHOOL_CLUB_ADVISOR",
  Sponsor = "SPONSOR",
  StateAdministrator = "STATE_ADMINISTRATOR",
  Student = "STUDENT",
  TeacherInstructor = "TEACHER_INSTRUCTOR",
  Volunteer = "VOLUNTEER",
}

export type ApiKey = {
  __typename?: "ApiKey";
  createdAt: Scalars["Date"];
  expiresAt?: Maybe<Scalars["Date"]>;
  id: Scalars["String"];
  label: Scalars["String"];
  maxRps: Scalars["Int"];
  name: Scalars["String"];
  permissions: Array<ApiKeyPermission>;
  secret: Scalars["String"];
  user: User;
};

export type ApiKeyPermission = {
  __typename?: "ApiKeyPermission";
  permission: Permission;
};

export type ApproveFundraiserApprovalSubmissionData = {
  __typename?: "ApproveFundraiserApprovalSubmissionData";
  isSsoApprover: Scalars["Boolean"];
  submitterName: Scalars["String"];
};

export type ApproveFundraiserApprovalSubmissionResult =
  | ApproveFundraiserApprovalSubmissionData
  | InvalidToken;

export type AssignmentInput = {
  id: Scalars["String"];
  type: ChannelTypesEnum;
};

export type AuditLogEntry = {
  __typename?: "AuditLogEntry";
  createdAt: Scalars["DateTime"];
  id: Scalars["String"];
  payload: Scalars["JSON"];
  service: AuditLogService;
  source: AuditLogSource;
  type: AuditLogType;
  userId?: Maybe<Scalars["String"]>;
};

export type AuditLogList = {
  __typename?: "AuditLogList";
  list: Array<Maybe<AuditLogEntry>>;
};

export enum AuditLogService {
  Connect = "Connect",
  Donors = "Donors",
  Drive = "Drive",
  Home = "Home",
  Insights = "Insights",
  Manage = "Manage",
  Orgs = "Orgs",
  Raise = "Raise",
  Spend = "Spend",
  Sponsor = "Sponsor",
  Store = "Store",
  UserDirectory = "UserDirectory",
  Vault = "Vault",
  Wallet = "Wallet",
}

export enum AuditLogSource {
  Backend = "Backend",
  Db = "Db",
  Frontend = "Frontend",
}

export enum AuditLogType {
  Debug = "Debug",
  Error = "Error",
  Info = "Info",
  Warning = "Warning",
}

export type Auth = Tokens & {
  __typename?: "Auth";
  accessToken?: Maybe<Scalars["String"]>;
  refreshToken?: Maybe<Scalars["String"]>;
};

export type AuthChallenge = Tokens & {
  __typename?: "AuthChallenge";
  accessToken?: Maybe<Scalars["String"]>;
  challenges?: Maybe<Array<Maybe<UserChallenge>>>;
  refreshToken?: Maybe<Scalars["String"]>;
};

export type AuthenticationFlow = {
  __typename?: "AuthenticationFlow";
  name?: Maybe<Scalars["String"]>;
  redirectUrl?: Maybe<Scalars["String"]>;
};

export enum BasicCampaignStatus {
  Active = "ACTIVE",
  Closed = "CLOSED",
  Upcoming = "UPCOMING",
}

/** BoolFilter is used for filtering based on boolean values. */
export type BoolFilter = {
  /** Checks if the boolean field is equal to the specified value. */
  equals?: InputMaybe<Scalars["Boolean"]>;
  /** Nested filter for negating a condition. */
  not?: InputMaybe<NestedBoolFilter>;
};

/** Filtering options for nullable Boolean type */
export type BoolNullableFilter = {
  /** Equals operation */
  equals?: InputMaybe<Scalars["Boolean"]>;
  /** Not Equal operation */
  not?: InputMaybe<Scalars["Boolean"]>;
};

export type Brand = {
  __typename?: "Brand";
  label?: Maybe<Scalars["String"]>;
  value?: Maybe<Scalars["Int"]>;
};

export type BudgetTransaction = {
  __typename?: "BudgetTransaction";
  id: Scalars["UUID"];
  status?: Maybe<Status>;
};

export type BulkRewards = {
  __typename?: "BulkRewards";
  id: Scalars["Int"];
  incentiveId?: Maybe<Scalars["Int"]>;
  size?: Maybe<Scalars["String"]>;
  tier?: Maybe<Scalars["Int"]>;
};

/** Owned by Vault Only used in legacy createCard */
export enum Card_Status {
  Active = "active",
  Canceled = "canceled",
  Inactive = "inactive",
}

/** Owned by Vault Only used in legacy createCard */
export enum Card_Type {
  Physical = "physical",
  Virtual = "virtual",
}

export enum CacheControlScope {
  Private = "PRIVATE",
  Public = "PUBLIC",
}

/** @deprecated: will be merged with DriveCampaign instead This type is for Drive's Campaign Management. Users must have the Drive's permission to access any data. We are working on migrating this type to DriveCampaign. */
export type Campaign = {
  __typename?: "Campaign";
  /** Account Manager of Campaign */
  accountManager?: Maybe<AccountManager>;
  /** Activity type of campaign. e.g. 'football', 'baseball', etc. */
  activityType?: Maybe<Scalars["String"]>;
  approvedFunds?: Maybe<ApprovedFunds>;
  /** URL for Generated Report of (Active) Campaign */
  campaignActiveReportUrl?: Maybe<Scalars["String"]>;
  /** City + State string of campaign. e.g. 'Dallas, TX' */
  campaignTerritory?: Maybe<Scalars["String"]>;
  coachJoinCode?: Maybe<Scalars["String"]>;
  /** Donations statistics of campaign. All values will be 0 if campaign is 'upcoming' */
  donations?: Maybe<Donations>;
  /** Participant email delivery info of campaigns */
  emails?: Maybe<EmailsInfo>;
  /** End date of fundraiser */
  endDate?: Maybe<Scalars["DateTime"]>;
  /** Entity ID of campaign. This ID used to obtain financial/payment info */
  entityId?: Maybe<Scalars["Int"]>;
  /** Date when campaign is finalized */
  finalizedDate?: Maybe<Scalars["DateTime"]>;
  /** Shipping status for gear items. Values are 'delivered','pre_transit','in_transit','failure','available_for_pickup','unknown','out_for_delivery', 'return_to_sender','cancelled','error' */
  gearStatus?: Maybe<Scalars["String"]>;
  /** Group Leader */
  groupLeader?: Maybe<GroupLeader>;
  /** Deal ID of campaign in Hubspot. */
  hubspotId?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["Int"]>;
  /** Cover image of campaign */
  image?: Maybe<Scalars["String"]>;
  /** Initial goal amount to fundraise in cents */
  initialGoalCents?: Maybe<Scalars["Int"]>;
  /** Link to invite participants to the campaign */
  inviteParticipantUrl?: Maybe<Scalars["String"]>;
  /** Unique join-code of campaign */
  joinCode?: Maybe<Scalars["Int"]>;
  /** URL for the fundraiser KYC Form. */
  kycFormUrl?: Maybe<Scalars["String"]>;
  /** Campaign kyc status */
  kycStatus?: Maybe<CampaignKycStatusResult>;
  /** Mailing address of campaign. */
  mailingAddress?: Maybe<Scalars["String"]>;
  /** MDM Contact of Campaign */
  mdm?: Maybe<MdmDetails>;
  name?: Maybe<Scalars["String"]>;
  /** Statistics info of campaign participants */
  participants?: Maybe<ParticipantsInfo>;
  /** Last withdrawal payment status. Values are 'Initializing', 'Processing', 'Transferred', 'Cut', 'Deposited', 'Expired' or 'Failed' */
  paymentStatus?: Maybe<Scalars["String"]>;
  /** Payment type when campaign finalized. Values are 'Check to Office', 'Check to Salesrep', 'Check to Fundraiser', or 'Direct Deposit'. */
  paymentType?: Maybe<Scalars["String"]>;
  /** Base64 generated CSV file for preload emails report of event */
  preloadReport?: Maybe<Scalars["String"]>;
  /** Download URL for Final Receipt (PDF) of settled campaigns */
  receiptDownloadUrl?: Maybe<Scalars["String"]>;
  /** Settlement Details for Closed Campaigns */
  settlementDetails?: Maybe<SettlementDetails>;
  /** Settlement Confirmation Status for Closed Campaigns. Values are 'UNSUBMITTED', 'IN_REVIEW' and 'VERIFIED' */
  settlementStatus?: Maybe<Scalars["String"]>;
  slug?: Maybe<Scalars["String"]>;
  /** Start date of fundraiser */
  startDate?: Maybe<Scalars["DateTime"]>;
  status?: Maybe<Scalars["String"]>;
  /** Size of team/group of campaign */
  teamSize?: Maybe<Scalars["Int"]>;
  /** Not in-use. Deprecated */
  testEmails?: Maybe<Scalars["String"]>;
  /** Total amount raised in cents */
  totalRaisedCents?: Maybe<Scalars["Int"]>;
  /** The total raised combined this organization has previously ran campaigns */
  totalRaisedHistory?: Maybe<Scalars["Int"]>;
  /** Tracking link for shipping gear items. */
  trackingLink?: Maybe<Scalars["String"]>;
};

export type CampaignDates = {
  __typename?: "CampaignDates";
  endDateTime: Scalars["String"];
  isAllDay?: Maybe<Scalars["Boolean"]>;
  startDateTime: Scalars["String"];
};

/** Resigned campaigns */
export type CampaignHistoryList = {
  __typename?: "CampaignHistoryList";
  CampaignHistoryEndDate?: Maybe<Scalars["String"]>;
  CampaignHistorySlug?: Maybe<Scalars["String"]>;
  CampaignHistoryStartDate?: Maybe<Scalars["String"]>;
  campaignName?: Maybe<Scalars["String"]>;
  campaignTotalRaised?: Maybe<Scalars["Int"]>;
};

export type CampaignKyc = {
  __typename?: "CampaignKyc";
  status?: Maybe<CampaignKycStatusEnum>;
};

/** Fixed statuses for a campaigns kyc */
export enum CampaignKycStatus {
  ActionRequired = "action_required",
  InReview = "in_review",
  RequireDocs = "require_docs",
  Unsubmitted = "unsubmitted",
  Unverified = "unverified",
  Verified = "verified",
}

export enum CampaignKycStatusEnum {
  ActionNeeded = "ACTION_NEEDED",
  Pending = "PENDING",
  Unsubmitted = "UNSUBMITTED",
  Verified = "VERIFIED",
}

export type CampaignKycStatusResult = {
  __typename?: "CampaignKycStatusResult";
  data?: Maybe<CampaignKycStatus>;
  type?: Maybe<CampaignKycType>;
};

/** Fixed type for a campaigns kyc */
export enum CampaignKycType {
  Stripe = "stripe",
  Wepay = "wepay",
}

export type CampaignList = {
  __typename?: "CampaignList";
  count?: Maybe<Scalars["Int"]>;
  list: Array<Maybe<Campaign>>;
  offset?: Maybe<Scalars["Int"]>;
};

export enum CampaignMemberAssociation {
  Admin = "ADMIN",
  Coach = "COACH",
  Cocoach = "COCOACH",
  Coparticipant = "COPARTICIPANT",
  Participant = "PARTICIPANT",
  Supporter = "SUPPORTER",
}

/** CampaignMembership is our way of securing information based on a users membership type. When a user queries campaignMemberships they can get information on their memberships based on who they are as defined in the JWT. Example of how to use:https://www.notion.so/snap-mobile/Campaign-1b4c4a055bc84aaf8290f078f57a5193 */
export type CampaignMembership =
  | AdminCampaign
  | GroupLeaderCampaign
  | ParticipantCampaign
  | SupporterCampaign;

export type CampaignPersonList = {
  __typename?: "CampaignPersonList";
  id: Scalars["Int"];
};

export type CampaignPersonListData = {
  __typename?: "CampaignPersonListData";
  email: Scalars["String"];
  id?: Maybe<Scalars["Int"]>;
};

export type CampaignPersonListEntries = {
  __typename?: "CampaignPersonListEntries";
  invalidEntries?: Maybe<Array<Maybe<InvalidCampaignPersonListData>>>;
  validEntries?: Maybe<Array<Maybe<CampaignPersonListData>>>;
};

export type CampaignPersonListEntryDelete = {
  __typename?: "CampaignPersonListEntryDelete";
  id: Scalars["Int"];
};

export type CampaignSearchFilter = {
  __typename?: "CampaignSearchFilter";
  dateCreated?: Maybe<Scalars["DateTime"]>;
  dateUpdated?: Maybe<Scalars["DateTime"]>;
  /** Collection (key/values) of saved search criteria. */
  filterCriteria?: Maybe<Scalars["JSON"]>;
  /** Name of the saved search filter. */
  filterName?: Maybe<Scalars["String"]>;
  /** ID of saved search filters. */
  id?: Maybe<Scalars["Int"]>;
};

export type CampaignSmsDataResponse = {
  __typename?: "CampaignSmsDataResponse";
  invalidEntries: Array<InvalidCampaignSmsInviteData>;
  status?: Maybe<CampaignSmsInviteStatus>;
  validEntries: Array<CampaignSmsInviteData>;
};

export type CampaignSmsInviteData = {
  __typename?: "CampaignSmsInviteData";
  id: Scalars["Int"];
  phoneNumber: Scalars["String"];
};

export type CampaignSmsInviteDeleteResponse = {
  __typename?: "CampaignSmsInviteDeleteResponse";
  status: Scalars["String"];
};

export enum CampaignSmsInviteStatus {
  Preloaded = "PRELOADED",
  PreloadQueued = "PRELOAD_QUEUED",
  Queued = "QUEUED",
}

export enum CampaignStatus {
  Active = "ACTIVE",
  Approved = "APPROVED",
  Archive = "ARCHIVE",
  Closed = "CLOSED",
  Deleted = "DELETED",
  Final = "FINAL",
  PendingApproval = "PENDING_APPROVAL",
  Settled = "SETTLED",
}

/** Owned by vault Extended by wallet Card issued by vault via Stripe */
export type Card = {
  __typename?: "Card";
  cardNumber?: Maybe<Scalars["String"]>;
  cardType?: Maybe<VaultCardType>;
  cardholderId?: Maybe<Scalars["String"]>;
  cvv?: Maybe<Scalars["String"]>;
  expirationMonth?: Maybe<Scalars["Int"]>;
  expirationYear?: Maybe<Scalars["Int"]>;
  gatewayId?: Maybe<Scalars["String"]>;
  id: Scalars["String"];
  last4?: Maybe<Scalars["String"]>;
  metadata?: Maybe<VaultCardMetadata>;
  spendingLimitAmount?: Maybe<Scalars["Int"]>;
  spendingLimitInterval?: Maybe<VaultSpendingLimitInterval>;
  status?: Maybe<VaultCardStatus>;
};

export type CarouselItem = {
  __typename?: "CarouselItem";
  id?: Maybe<Scalars["Int"]>;
  image?: Maybe<Scalars["String"]>;
  media_type?: Maybe<Scalars["String"]>;
  url?: Maybe<Scalars["String"]>;
};

export enum CategoryFilterEnum {
  Name = "name",
  Type = "type",
}

export enum CategoryTypeEnum {
  Expense = "expense",
  Income = "income",
}

export type Channel = {
  __typename?: "Channel";
  chatProviderId: Scalars["String"];
  createdAt: Scalars["Date"];
  description?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  isReadOnly?: Maybe<Scalars["Boolean"]>;
  name: Scalars["String"];
  status: ChannelStatusEnum;
  updatedAt?: Maybe<Scalars["Date"]>;
};

export type ChannelAddModeratorsError = Error & {
  __typename?: "ChannelAddModeratorsError";
  message: Scalars["String"];
};

export type ChannelAddUsersError = Error & {
  __typename?: "ChannelAddUsersError";
  message: Scalars["String"];
};

export type ChannelCreateError = Error & {
  __typename?: "ChannelCreateError";
  message: Scalars["String"];
};

export type ChannelMembers = {
  __typename?: "ChannelMembers";
  members?: Maybe<Array<Maybe<User>>>;
};

export type ChannelNotFound = Error & {
  __typename?: "ChannelNotFound";
  message: Scalars["String"];
};

export type ChannelRemoveMembersError = Error & {
  __typename?: "ChannelRemoveMembersError";
  message: Scalars["String"];
};

export enum ChannelStatusEnum {
  Active = "ACTIVE",
  Archived = "ARCHIVED",
  Disabled = "DISABLED",
  Frozen = "FROZEN",
}

export enum ChannelTypesEnum {
  Fundraiser = "fundraiser",
}

export type ChannelUpdateError = Error & {
  __typename?: "ChannelUpdateError";
  message: Scalars["String"];
};

export type ChannelUpdateInput = {
  description?: InputMaybe<Scalars["String"]>;
  isReadOnly?: InputMaybe<Scalars["Boolean"]>;
  name?: InputMaybe<Scalars["String"]>;
  status?: InputMaybe<ChannelStatusEnum>;
};

export type ChannelUpdateMembersError = Error & {
  __typename?: "ChannelUpdateMembersError";
  message: Scalars["String"];
};

export type ChannelUpsertUsersError = Error & {
  __typename?: "ChannelUpsertUsersError";
  message: Scalars["String"];
};

export type Channels = {
  __typename?: "Channels";
  channels?: Maybe<Array<Maybe<Channel>>>;
};

export type ChatChannelAddModeratorsResult =
  | Channel
  | ChannelAddModeratorsError
  | ChannelNotFound
  | NotAuthorized;

export type ChatChannelAddUsersResult =
  | Channel
  | ChannelAddUsersError
  | ChannelNotFound
  | NotAuthorized;

export type ChatChannelCreateResult =
  | Channel
  | ChannelCreateError
  | NotAuthorized;

export type ChatChannelResult =
  | Channel
  | ChannelNotFound
  | MissingArguments
  | NotAuthenticated;

export type ChatChannelUpdateMembersResult =
  | Channel
  | ChannelNotFound
  | ChannelUpdateMembersError
  | MissingArguments
  | NotAuthorized;

export type ChatChannelUpdateResult =
  | Channel
  | ChannelNotFound
  | ChannelUpdateError
  | NotAuthenticated
  | NotAuthorized;

export type ChatChannelsResult = Channels | NotAuthenticated;

export type ChatToken = {
  __typename?: "ChatToken";
  accessToken: Scalars["String"];
};

export type ChatTokenResult = ChatToken | NotAuthenticated;

export type ChatUnreadMessagesResult = NotAuthenticated | UnreadCount;

export type Check = {
  __typename?: "Check";
  amount: Scalars["Decimal"];
  campaignId: Scalars["Int"];
  checkNumber: Scalars["String"];
  created: Scalars["Timestamp"];
  destination: Account;
  effective: Scalars["Timestamp"];
  expiration: Scalars["Timestamp"];
  payee: Payee;
  status: CheckStatus;
  transactionId: Scalars["UUID"];
};

export enum CheckStatus {
  Deposited = "DEPOSITED",
  /** TODO(SHR): update this once we get actual statuses from bill.com */
  Processing = "PROCESSING",
  Sent = "SENT",
}

export type Cheer = {
  __typename?: "Cheer";
  anonymous?: Maybe<Scalars["Boolean"]>;
  createdAt?: Maybe<Scalars["DateTime"]>;
  donorMessage?: Maybe<Scalars["String"]>;
  donorName?: Maybe<Scalars["String"]>;
  firstName?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["Int"]>;
  images?: Maybe<Array<Maybe<Scalars["String"]>>>;
  lastName?: Maybe<Scalars["String"]>;
  subtotalCents?: Maybe<Scalars["Int"]>;
};

/** @deprecated */
export type CheerWallData = {
  __typename?: "CheerWallData";
  createdAt?: Maybe<Scalars["DateTime"]>;
  donorMessage?: Maybe<Scalars["String"]>;
  donorName?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  images?: Maybe<Array<Maybe<Scalars["String"]>>>;
  participant?: Maybe<ParticipantData>;
  subtotalCents?: Maybe<Scalars["Int"]>;
};

export type CombinedPermissions = {
  __typename?: "CombinedPermissions";
  permissions?: Maybe<Array<Maybe<Scalars["String"]>>>;
};

export type CommonCalendar = {
  __typename?: "CommonCalendar";
  activity?: Maybe<Scalars["String"]>;
  bus_count?: Maybe<Scalars["Int"]>;
  comments?: Maybe<Scalars["String"]>;
  confirmed?: Maybe<Scalars["String"]>;
  driver_name?: Maybe<Scalars["String"]>;
  driver_phone?: Maybe<Scalars["String"]>;
  end_time?: Maybe<Scalars["String"]>;
  event_date?: Maybe<Scalars["String"]>;
  facility?: Maybe<Scalars["String"]>;
  g_s?: Maybe<Scalars["String"]>;
  gender?: Maybe<Scalars["String"]>;
  home_field?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["Int"]>;
  levels?: Maybe<Scalars["String"]>;
  opponent?: Maybe<Scalars["String"]>;
  place?: Maybe<Scalars["String"]>;
  season_id?: Maybe<Scalars["Int"]>;
  sports_code?: Maybe<Scalars["String"]>;
  sports_description?: Maybe<Scalars["String"]>;
  sports_group?: Maybe<Scalars["String"]>;
  sports_name?: Maybe<Scalars["String"]>;
  start_time?: Maybe<Scalars["String"]>;
  vehicle_id?: Maybe<Scalars["String"]>;
  vehicle_type?: Maybe<Scalars["String"]>;
};

export type CommonCalendarFiltersInput = {
  activity?: InputMaybe<Scalars["String"]>;
  endDate?: InputMaybe<Scalars["Date"]>;
  facilities?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  gender?: InputMaybe<Scalars["String"]>;
  group?: InputMaybe<Scalars["String"]>;
  homeAway?: InputMaybe<Scalars["String"]>;
  levels?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  opponents?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  startDate?: InputMaybe<Scalars["Date"]>;
  teams?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  vehicleType?: InputMaybe<Scalars["String"]>;
};

export type CommonGrid = {
  __typename?: "CommonGrid";
  activity?: Maybe<Scalars["String"]>;
  bus_count?: Maybe<Scalars["Int"]>;
  comments?: Maybe<Scalars["String"]>;
  confirmed?: Maybe<Scalars["String"]>;
  driver_name?: Maybe<Scalars["String"]>;
  driver_phone?: Maybe<Scalars["String"]>;
  end_time?: Maybe<Scalars["String"]>;
  event_date?: Maybe<Scalars["String"]>;
  facility?: Maybe<Scalars["String"]>;
  g_s?: Maybe<Scalars["String"]>;
  gender?: Maybe<Scalars["String"]>;
  home_field?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["Int"]>;
  levels?: Maybe<Scalars["String"]>;
  place?: Maybe<Scalars["String"]>;
  season_id?: Maybe<Scalars["Int"]>;
  sports_code?: Maybe<Scalars["String"]>;
  sports_description?: Maybe<Scalars["String"]>;
  sports_group?: Maybe<Scalars["String"]>;
  sports_name?: Maybe<Scalars["String"]>;
  start_time?: Maybe<Scalars["String"]>;
  vehicle_id?: Maybe<Scalars["String"]>;
  vehicle_type?: Maybe<Scalars["String"]>;
};

export type CommonGridFiltersInput = {
  endDate?: InputMaybe<Scalars["Date"]>;
  facilities?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  gender?: InputMaybe<Scalars["String"]>;
  levels?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  startDate?: InputMaybe<Scalars["Date"]>;
  teams?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export type CommonSchedules = {
  __typename?: "CommonSchedules";
  activity?: Maybe<Scalars["String"]>;
  bus_count?: Maybe<Scalars["Int"]>;
  bus_departure_location?: Maybe<Scalars["String"]>;
  bus_early_dismissal_time?: Maybe<Scalars["String"]>;
  bus_estimated_return_time?: Maybe<Scalars["String"]>;
  bus_time?: Maybe<Scalars["String"]>;
  cancellation_status?: Maybe<Scalars["String"]>;
  comments?: Maybe<Scalars["String"]>;
  confirmed?: Maybe<Scalars["String"]>;
  driver_name?: Maybe<Scalars["String"]>;
  driver_phone?: Maybe<Scalars["String"]>;
  end_time?: Maybe<Scalars["String"]>;
  event_date?: Maybe<Scalars["String"]>;
  facility?: Maybe<Scalars["String"]>;
  g_s?: Maybe<Scalars["String"]>;
  gender?: Maybe<Scalars["String"]>;
  home_field?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["Int"]>;
  levels?: Maybe<Scalars["String"]>;
  opponent?: Maybe<Scalars["String"]>;
  place?: Maybe<Scalars["String"]>;
  season_id?: Maybe<Scalars["Int"]>;
  sports_code?: Maybe<Scalars["String"]>;
  sports_description?: Maybe<Scalars["String"]>;
  sports_group?: Maybe<Scalars["String"]>;
  sports_name?: Maybe<Scalars["String"]>;
  start_time?: Maybe<Scalars["String"]>;
  vehicle_id?: Maybe<Scalars["String"]>;
  vehicle_type?: Maybe<Scalars["String"]>;
};

export type CommonSchedulesFiltersInput = {
  activity?: InputMaybe<Scalars["String"]>;
  activityName?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  endDate?: InputMaybe<Scalars["Date"]>;
  facilities?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  gender?: InputMaybe<Scalars["String"]>;
  group?: InputMaybe<Scalars["String"]>;
  homeAway?: InputMaybe<Scalars["String"]>;
  levels?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  opponents?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  startDate?: InputMaybe<Scalars["Date"]>;
  vehicleType?: InputMaybe<Scalars["String"]>;
};

export type CommonSheet = {
  __typename?: "CommonSheet";
  activity?: Maybe<Scalars["String"]>;
  bus_count?: Maybe<Scalars["Int"]>;
  comments?: Maybe<Scalars["String"]>;
  confirmed?: Maybe<Scalars["String"]>;
  driver_name?: Maybe<Scalars["String"]>;
  driver_phone?: Maybe<Scalars["String"]>;
  end_time?: Maybe<Scalars["String"]>;
  event_date?: Maybe<Scalars["String"]>;
  facility?: Maybe<Scalars["String"]>;
  fee?: Maybe<Scalars["Float"]>;
  g_s?: Maybe<Scalars["String"]>;
  gender?: Maybe<Scalars["String"]>;
  home_field?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["Int"]>;
  levels?: Maybe<Scalars["String"]>;
  place?: Maybe<Scalars["String"]>;
  season_id?: Maybe<Scalars["Int"]>;
  sports_code?: Maybe<Scalars["String"]>;
  sports_description?: Maybe<Scalars["String"]>;
  sports_group?: Maybe<Scalars["String"]>;
  sports_name?: Maybe<Scalars["String"]>;
  start_time?: Maybe<Scalars["String"]>;
  vehicle_id?: Maybe<Scalars["String"]>;
  vehicle_type?: Maybe<Scalars["String"]>;
};

export type CommonSheetFiltersInput = {
  activity?: InputMaybe<Scalars["String"]>;
  endDate?: InputMaybe<Scalars["Date"]>;
  facilities?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  gender?: InputMaybe<Scalars["String"]>;
  group?: InputMaybe<Scalars["String"]>;
  homeAway?: InputMaybe<Scalars["String"]>;
  levels?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  opponents?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  startDate?: InputMaybe<Scalars["Date"]>;
  teams?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  vehicleType?: InputMaybe<Scalars["String"]>;
};

export type CommsContactResponse = {
  __typename?: "CommsContactResponse";
  contact?: Maybe<Scalars["String"]>;
  date?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  status?: Maybe<RequestStatus>;
};

export type CommsHtmlResponse = {
  __typename?: "CommsHtmlResponse";
  errors?: Maybe<Array<Maybe<Scalars["String"]>>>;
  html?: Maybe<Scalars["String"]>;
  mjml?: Maybe<Scalars["String"]>;
};

export type CommsMessageResponse = {
  __typename?: "CommsMessageResponse";
  contacts?: Maybe<Array<Maybe<MessageContactResponse>>>;
  execution?: Maybe<Array<Maybe<MessageExecutionResponse>>>;
  id: Scalars["ID"];
  sendAt: Scalars["Date"];
  templateAttributes?: Maybe<Scalars["JSON"]>;
};

export type CommsRegisterResponse = {
  __typename?: "CommsRegisterResponse";
  attributes?: Maybe<Array<Maybe<Scalars["String"]>>>;
  createdAt?: Maybe<Scalars["Date"]>;
  emailProvider?: Maybe<SupportedEmailProviders>;
  from?: Maybe<Scalars["String"]>;
  fromName?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  mjml?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  service: SnapService;
  smsProvider?: Maybe<SupportedSmsProviders>;
  subject?: Maybe<Scalars["String"]>;
  testData: Scalars["JSON"];
  text?: Maybe<Scalars["String"]>;
  transport: MessageTransportType;
  type: MessageType;
};

export type CommsSendResponse = {
  __typename?: "CommsSendResponse";
  details?: Maybe<Array<CommsContactResponse>>;
  id: Scalars["ID"];
};

export type CommsTemplateResponse = {
  __typename?: "CommsTemplateResponse";
  attributes?: Maybe<Array<Maybe<Scalars["String"]>>>;
  createdAt?: Maybe<Scalars["Date"]>;
  emailProvider?: Maybe<SupportedEmailProviders>;
  errors?: Maybe<Array<Maybe<Scalars["String"]>>>;
  from?: Maybe<Scalars["String"]>;
  fromName?: Maybe<Scalars["String"]>;
  html?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  mjml?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  service: SnapService;
  smsProvider?: Maybe<SupportedSmsProviders>;
  subject?: Maybe<Scalars["String"]>;
  testData: Scalars["JSON"];
  text?: Maybe<Scalars["String"]>;
  transport: MessageTransportType;
  type: MessageType;
  unsubscribeGroupId?: Maybe<Scalars["Int"]>;
  unsubscribeGroups?: Maybe<Array<Maybe<Scalars["Int"]>>>;
};

export type CommsTestResponse = {
  __typename?: "CommsTestResponse";
  message?: Maybe<Scalars["String"]>;
};

export type CommsUnsubscribeGroup = {
  __typename?: "CommsUnsubscribeGroup";
  description?: Maybe<Scalars["String"]>;
  id: Scalars["Int"];
  name?: Maybe<Scalars["String"]>;
  unsubscribes?: Maybe<Scalars["Int"]>;
};

export type CommsUnsubscribeGroupsResponse = {
  __typename?: "CommsUnsubscribeGroupsResponse";
  groups?: Maybe<Array<CommsUnsubscribeGroup>>;
};

export type Consumer = {
  __typename?: "Consumer";
  accessBy?: Maybe<Scalars["String"]>;
  color: Scalars["String"];
  description: Scalars["String"];
  icon: Scalars["String"];
  id: Scalars["String"];
  inApps: Scalars["Boolean"];
  logo: Scalars["String"];
  mask: Scalars["String"];
  modal?: Maybe<ConsumerModal>;
  title: Scalars["String"];
  url: Scalars["String"];
};

export type ConsumerModal = {
  __typename?: "ConsumerModal";
  descriptions: Array<Maybe<Scalars["String"]>>;
  header: Scalars["String"];
};

export enum ContactTemplateMedium {
  Email = "EMAIL",
  TextMessage = "TEXT_MESSAGE",
}

export enum ContactTemplateType {
  GroupLeader = "GROUP_LEADER",
  Participant = "PARTICIPANT",
}

export type ContractItem = {
  __typename?: "ContractItem";
  event_contract?: Maybe<EventContract>;
  event_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
};

export type ContractSignees = {
  __typename?: "ContractSignees";
  auth_hash?: Maybe<Scalars["String"]>;
  comments?: Maybe<Scalars["String"]>;
  email_id?: Maybe<Scalars["String"]>;
  event_contract_number?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  original_contract?: Maybe<Scalars["String"]>;
  sent_date?: Maybe<Scalars["Date"]>;
  sign_contract?: Maybe<Scalars["String"]>;
  sign_date?: Maybe<Scalars["Date"]>;
  sign_status?: Maybe<Scalars["String"]>;
};

/** Owned by Vault Only used in legacy createCard */
export type CoreCardFields = {
  __typename?: "CoreCardFields";
  cardId: Scalars["String"];
  expirationMonth: Scalars["Int"];
  expirationYear: Scalars["Int"];
  gatewayId?: Maybe<Scalars["String"]>;
  last4: Scalars["String"];
  name: Scalars["String"];
};

export type CreateChildFundraiserParticipantInput = {
  email: Scalars["String"];
  firstName: Scalars["String"];
  fundraiserId: Scalars["ID"];
  groupId?: InputMaybe<Scalars["ID"]>;
  joinType?: InputMaybe<JoinType>;
  lastName: Scalars["String"];
  udid: Scalars["String"];
};

export type CreateEventInput = {
  activity?: InputMaybe<Scalars["String"]>;
  author?: InputMaybe<Scalars["String"]>;
  bus_fee?: InputMaybe<Scalars["Float"]>;
  bus_time?: InputMaybe<Scalars["String"]>;
  cancellation_status?: InputMaybe<Scalars["String"]>;
  comments?: InputMaybe<Scalars["String"]>;
  conference?: InputMaybe<Scalars["String"]>;
  conference_event_id?: InputMaybe<Scalars["Int"]>;
  conference_id?: InputMaybe<Scalars["Int"]>;
  confirmed?: InputMaybe<Scalars["String"]>;
  contract?: InputMaybe<Scalars["String"]>;
  created_at?: InputMaybe<Scalars["Date"]>;
  departure_location?: InputMaybe<Scalars["String"]>;
  directions?: InputMaybe<Scalars["String"]>;
  early_dismissal_required?: InputMaybe<Scalars["String"]>;
  early_dismissal_time?: InputMaybe<Scalars["String"]>;
  end_time?: InputMaybe<Scalars["String"]>;
  estimated_return_time?: InputMaybe<Scalars["String"]>;
  event_date?: InputMaybe<Scalars["Date"]>;
  event_id?: InputMaybe<Scalars["Int"]>;
  exists_in_mls?: InputMaybe<Scalars["Int"]>;
  fee?: InputMaybe<Scalars["Float"]>;
  g_s?: InputMaybe<Scalars["String"]>;
  gate_revenue?: InputMaybe<Scalars["Float"]>;
  headline?: InputMaybe<Scalars["String"]>;
  impact_event?: InputMaybe<Scalars["String"]>;
  lead?: InputMaybe<Scalars["String"]>;
  location?: InputMaybe<Scalars["String"]>;
  loss_points?: InputMaybe<Scalars["Int"]>;
  num_buses?: InputMaybe<Scalars["Int"]>;
  opponent?: InputMaybe<Scalars["String"]>;
  opponent_code?: InputMaybe<Scalars["String"]>;
  opponent_score?: InputMaybe<Scalars["String"]>;
  picture?: InputMaybe<Scalars["String"]>;
  place?: InputMaybe<Scalars["String"]>;
  prep_setup?: InputMaybe<Scalars["String"]>;
  promote?: InputMaybe<Scalars["String"]>;
  results?: InputMaybe<Scalars["String"]>;
  revenue?: InputMaybe<Scalars["Float"]>;
  rollover?: InputMaybe<Scalars["String"]>;
  season_team?: InputMaybe<Scalars["Int"]>;
  start_time?: InputMaybe<Scalars["String"]>;
  team_score?: InputMaybe<Scalars["String"]>;
  title?: InputMaybe<Scalars["String"]>;
  tournament?: InputMaybe<Scalars["String"]>;
  trans_id?: InputMaybe<Scalars["Int"]>;
  transport_comments?: InputMaybe<Scalars["String"]>;
  transportation?: InputMaybe<Scalars["String"]>;
  update_at?: InputMaybe<Scalars["Date"]>;
  web_dir?: InputMaybe<Scalars["String"]>;
  win_points?: InputMaybe<Scalars["Int"]>;
  years?: InputMaybe<Scalars["String"]>;
};

export type CreateEventParticipantsInput = {
  contract_received?: InputMaybe<Scalars["String"]>;
  event_id?: InputMaybe<Scalars["Int"]>;
  id?: InputMaybe<Scalars["Int"]>;
  notes?: InputMaybe<Scalars["String"]>;
  paid?: InputMaybe<Scalars["String"]>;
  school_code?: InputMaybe<Scalars["String"]>;
};

export type CreateEventPreparationInput = {
  comments?: InputMaybe<Scalars["String"]>;
  event?: InputMaybe<Scalars["Int"]>;
  id: Scalars["Int"];
  prep?: InputMaybe<Scalars["String"]>;
  qty?: InputMaybe<Scalars["String"]>;
};

export type CreateEventTransportDetailsInput = {
  driver_name?: InputMaybe<Scalars["String"]>;
  driver_phone?: InputMaybe<Scalars["String"]>;
  event_id?: InputMaybe<Scalars["Int"]>;
  id: Scalars["Int"];
  vehicle_id?: InputMaybe<Scalars["String"]>;
  vehicle_type?: InputMaybe<Scalars["String"]>;
};

export type CreateFacilityInput = {
  Address1: Scalars["String"];
  Address2?: InputMaybe<Scalars["String"]>;
  City: Scalars["String"];
  State: Scalars["String"];
  ZipCode: Scalars["String"];
  directions?: InputMaybe<Scalars["String"]>;
  indoor?: InputMaybe<Scalars["String"]>;
  ml_site_id?: InputMaybe<Scalars["String"]>;
  ml_space?: InputMaybe<Scalars["String"]>;
  ml_space_id?: InputMaybe<Scalars["String"]>;
  picture?: InputMaybe<Scalars["String"]>;
  place_name: Scalars["String"];
  show?: InputMaybe<Scalars["String"]>;
  web?: InputMaybe<Scalars["String"]>;
};

export type CreateLevelInput = {
  Level?: InputMaybe<Scalars["String"]>;
  is_deleted?: InputMaybe<Scalars["Boolean"]>;
};

export type CreateOfficialDutyInput = {
  duty?: InputMaybe<Scalars["String"]>;
  is_deleted?: InputMaybe<Scalars["Boolean"]>;
};

export type CreateOfficialInput = {
  duty?: InputMaybe<Scalars["String"]>;
  event_id?: InputMaybe<Scalars["Int"]>;
  id: Scalars["Int"];
  offic_id?: InputMaybe<Scalars["String"]>;
  organization?: InputMaybe<Scalars["String"]>;
  paid?: InputMaybe<Scalars["String"]>;
  pay_code?: InputMaybe<Scalars["String"]>;
  received?: InputMaybe<Scalars["String"]>;
  salary?: InputMaybe<Scalars["Float"]>;
  ssn?: InputMaybe<Scalars["String"]>;
  voucher_number?: InputMaybe<Scalars["String"]>;
  worker_name?: InputMaybe<Scalars["String"]>;
};

export type CreateOfficialPoolByIdInput = {
  Address?: InputMaybe<Scalars["String"]>;
  City?: InputMaybe<Scalars["String"]>;
  First: Scalars["String"];
  Home_Phone?: InputMaybe<Scalars["String"]>;
  ID: Scalars["String"];
  Last: Scalars["String"];
  SSN?: InputMaybe<Scalars["String"]>;
  State?: InputMaybe<Scalars["String"]>;
  Work_Phone?: InputMaybe<Scalars["String"]>;
  Zip?: InputMaybe<Scalars["String"]>;
  cell_phone?: InputMaybe<Scalars["String"]>;
  email?: InputMaybe<Scalars["String"]>;
  vendor_number?: InputMaybe<Scalars["String"]>;
};

export type CreateOpponentInput = {
  Address?: InputMaybe<Scalars["String"]>;
  Phone?: InputMaybe<Scalars["String"]>;
  SchoolCode: Scalars["String"];
  SchoolName?: InputMaybe<Scalars["String"]>;
  State?: InputMaybe<Scalars["String"]>;
  Zip?: InputMaybe<Scalars["String"]>;
  ad_name?: InputMaybe<Scalars["String"]>;
  city?: InputMaybe<Scalars["String"]>;
  email?: InputMaybe<Scalars["String"]>;
  fax?: InputMaybe<Scalars["String"]>;
  nces_id?: InputMaybe<Scalars["String"]>;
  non_school?: InputMaybe<Scalars["Boolean"]>;
};

export type CreatePreparationInput = {
  duty?: InputMaybe<Scalars["String"]>;
  id: Scalars["Int"];
  is_deleted?: InputMaybe<Scalars["Boolean"]>;
  qty?: InputMaybe<Scalars["String"]>;
};

export type CreateSchoolInfoInput = {
  Schoolname?: InputMaybe<Scalars["String"]>;
  ad?: InputMaybe<Scalars["String"]>;
  ad_contract_signee?: InputMaybe<Scalars["String"]>;
  address?: InputMaybe<Scalars["String"]>;
  ccemail?: InputMaybe<Scalars["String"]>;
  city?: InputMaybe<Scalars["String"]>;
  conf_text_type?: InputMaybe<Scalars["String"]>;
  email?: InputMaybe<Scalars["String"]>;
  email_reminder?: InputMaybe<Scalars["String"]>;
  email_reminder_officials?: InputMaybe<Scalars["String"]>;
  email_reminder_workers?: InputMaybe<Scalars["String"]>;
  enable_cc_email_as_origin?: InputMaybe<Scalars["String"]>;
  enable_ml_updates?: InputMaybe<Scalars["String"]>;
  fax?: InputMaybe<Scalars["String"]>;
  icon?: InputMaybe<Scalars["String"]>;
  id: Scalars["String"];
  mascot?: InputMaybe<Scalars["String"]>;
  message_board_read_at?: InputMaybe<Scalars["Date"]>;
  ml_key?: InputMaybe<Scalars["String"]>;
  phone?: InputMaybe<Scalars["String"]>;
  principal?: InputMaybe<Scalars["String"]>;
  school_timezone?: InputMaybe<Scalars["String"]>;
  secondary_ad_email?: InputMaybe<Scalars["String"]>;
  secondary_ad_name?: InputMaybe<Scalars["String"]>;
  signed_contract_notification?: InputMaybe<Scalars["String"]>;
  ssn_on_file?: InputMaybe<Scalars["String"]>;
  state?: InputMaybe<Scalars["String"]>;
  state_org?: InputMaybe<Scalars["String"]>;
  state_org_abbreviation?: InputMaybe<Scalars["String"]>;
  use_security?: InputMaybe<Scalars["String"]>;
  web_password?: InputMaybe<Scalars["String"]>;
  zip?: InputMaybe<Scalars["String"]>;
};

export type CreateVehicleInput = {
  is_deleted?: InputMaybe<Scalars["Boolean"]>;
  status?: InputMaybe<Scalars["String"]>;
  vehicle_type?: InputMaybe<Scalars["String"]>;
};

export type CreateWorkerInput = {
  duty?: InputMaybe<Scalars["String"]>;
  email?: InputMaybe<Scalars["String"]>;
  event_id?: InputMaybe<Scalars["Int"]>;
  id: Scalars["Int"];
  organization?: InputMaybe<Scalars["String"]>;
  paid?: InputMaybe<Scalars["String"]>;
  pay_code?: InputMaybe<Scalars["String"]>;
  pay_rate?: InputMaybe<Scalars["Float"]>;
  salary?: InputMaybe<Scalars["Float"]>;
  ssn?: InputMaybe<Scalars["String"]>;
  woker_name?: InputMaybe<Scalars["String"]>;
  worker_end_time?: InputMaybe<Scalars["String"]>;
  worker_start_time?: InputMaybe<Scalars["String"]>;
  worker_type?: InputMaybe<Scalars["String"]>;
};

export type CreateWorkerPool = {
  Address?: InputMaybe<Scalars["String"]>;
  City?: InputMaybe<Scalars["String"]>;
  First: Scalars["String"];
  Home_Phone?: InputMaybe<Scalars["String"]>;
  Last: Scalars["String"];
  SSN?: InputMaybe<Scalars["String"]>;
  State?: InputMaybe<Scalars["String"]>;
  Work_Phone?: InputMaybe<Scalars["String"]>;
  Zip?: InputMaybe<Scalars["String"]>;
  cell_phone?: InputMaybe<Scalars["String"]>;
  email?: InputMaybe<Scalars["String"]>;
  pay_rate?: InputMaybe<Scalars["Float"]>;
  worker_type?: InputMaybe<Scalars["String"]>;
};

export type CreatedFundraiserUser = {
  __typename?: "CreatedFundraiserUser";
  id: Scalars["Int"];
};

export type CreatedOtkEntry = {
  __typename?: "CreatedOtkEntry";
  id: Scalars["Int"];
};

export type CreatedParticipantRewards = {
  __typename?: "CreatedParticipantRewards";
  id: Scalars["Int"];
};

export type CurrentCustomersDeals = {
  __typename?: "CurrentCustomersDeals";
  activity?: Maybe<Scalars["String"]>;
  dealName?: Maybe<Scalars["String"]>;
  dealStage?: Maybe<Scalars["String"]>;
  entityId?: Maybe<Scalars["String"]>;
  fundraiserStatus?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  lastLaunch?: Maybe<Scalars["String"]>;
  leaderFirstName?: Maybe<Scalars["String"]>;
  leaderLastName?: Maybe<Scalars["String"]>;
  previousDealId?: Maybe<Scalars["String"]>;
  slug?: Maybe<Scalars["String"]>;
  snapFundraiserId?: Maybe<Scalars["String"]>;
  totalRaised?: Maybe<Scalars["Int"]>;
};

/** Information of custom email templates */
export type CustomContactTemplates = {
  __typename?: "CustomContactTemplates";
  dateCreated?: Maybe<Scalars["DateTime"]>;
  dateUpdated?: Maybe<Scalars["DateTime"]>;
  id?: Maybe<Scalars["Int"]>;
  message?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  subject?: Maybe<Scalars["String"]>;
};

export type DailyCalendarBusScheduleEvents = {
  __typename?: "DailyCalendarBusScheduleEvents";
  activity?: Maybe<Scalars["String"]>;
  activityType?: Maybe<Scalars["String"]>;
  bus_time?: Maybe<Scalars["String"]>;
  calendarDate?: Maybe<Scalars["String"]>;
  comments?: Maybe<Scalars["String"]>;
  confirmed?: Maybe<Scalars["String"]>;
  departure_location?: Maybe<Scalars["String"]>;
  early_dismissal_required?: Maybe<Scalars["String"]>;
  early_dismissal_time?: Maybe<Scalars["String"]>;
  end_time?: Maybe<Scalars["String"]>;
  estimated_return_time?: Maybe<Scalars["String"]>;
  event_comments?: Maybe<Scalars["String"]>;
  event_date?: Maybe<Scalars["String"]>;
  event_id?: Maybe<Scalars["Int"]>;
  g_s?: Maybe<Scalars["String"]>;
  location?: Maybe<Scalars["String"]>;
  num_buses?: Maybe<Scalars["Int"]>;
  num_buses_text?: Maybe<Scalars["Int"]>;
  opponent?: Maybe<Scalars["String"]>;
  place?: Maybe<Scalars["String"]>;
  sport?: Maybe<Scalars["String"]>;
  sportGender?: Maybe<Scalars["String"]>;
  sportLevel?: Maybe<Scalars["String"]>;
  sport_name?: Maybe<Scalars["String"]>;
  start_time?: Maybe<Scalars["String"]>;
};

export type DailyCalendarBusScheduleExportData = {
  __typename?: "DailyCalendarBusScheduleExportData";
  activity?: Maybe<Scalars["String"]>;
  bus_time?: Maybe<Scalars["String"]>;
  comments?: Maybe<Scalars["String"]>;
  confirmed?: Maybe<Scalars["String"]>;
  departure_location?: Maybe<Scalars["String"]>;
  driver_name?: Maybe<Scalars["String"]>;
  driver_phone?: Maybe<Scalars["String"]>;
  early_dismissal_required?: Maybe<Scalars["String"]>;
  early_dismissal_time?: Maybe<Scalars["String"]>;
  end_time?: Maybe<Scalars["String"]>;
  estimated_return_time?: Maybe<Scalars["String"]>;
  event_date?: Maybe<Scalars["String"]>;
  event_id?: Maybe<Scalars["Int"]>;
  g_s?: Maybe<Scalars["String"]>;
  gender?: Maybe<Scalars["String"]>;
  groupval?: Maybe<Scalars["String"]>;
  level1?: Maybe<Scalars["String"]>;
  location?: Maybe<Scalars["String"]>;
  num_buses_text?: Maybe<Scalars["Int"]>;
  opponent?: Maybe<Scalars["String"]>;
  place?: Maybe<Scalars["String"]>;
  sport_description?: Maybe<Scalars["String"]>;
  sport_name?: Maybe<Scalars["String"]>;
  start_time?: Maybe<Scalars["String"]>;
  transport_comments?: Maybe<Scalars["String"]>;
  vehicle_id?: Maybe<Scalars["String"]>;
  vehicle_type?: Maybe<Scalars["String"]>;
};

export type DailyCalendarEvent = {
  __typename?: "DailyCalendarEvent";
  activity?: Maybe<Scalars["String"]>;
  comments?: Maybe<Scalars["String"]>;
  departure_location?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  early_dismissal_time?: Maybe<Scalars["String"]>;
  end_time?: Maybe<Scalars["String"]>;
  estimated_return_time?: Maybe<Scalars["String"]>;
  eventId?: Maybe<Scalars["Int"]>;
  eventTransportDetails?: Maybe<Array<Maybe<EventTransportDetails>>>;
  event_date?: Maybe<Scalars["Date"]>;
  location?: Maybe<Scalars["String"]>;
  num_buses?: Maybe<Scalars["Int"]>;
  officials?: Maybe<Array<Maybe<Official>>>;
  opponent?: Maybe<Scalars["String"]>;
  preparations?: Maybe<Array<Maybe<Preparation>>>;
  schoolInfo?: Maybe<SchoolInfo>;
  season_team?: Maybe<Scalars["Int"]>;
  season_years?: Maybe<Scalars["String"]>;
  start_time?: Maybe<Scalars["String"]>;
  workers?: Maybe<Array<Maybe<Worker>>>;
};

export type DailyCalendarEventTransportDetails = {
  __typename?: "DailyCalendarEventTransportDetails";
  driver_name?: Maybe<Scalars["String"]>;
  driver_phone?: Maybe<Scalars["String"]>;
  event_transport_details_id?: Maybe<Scalars["Int"]>;
  vehicle_id?: Maybe<Scalars["String"]>;
  vehicle_type?: Maybe<Scalars["String"]>;
};

export type DailyCalendarOfficialDuties = {
  __typename?: "DailyCalendarOfficialDuties";
  Address?: Maybe<Scalars["String"]>;
  City?: Maybe<Scalars["String"]>;
  Home_Phone?: Maybe<Scalars["String"]>;
  SSN?: Maybe<Scalars["String"]>;
  State?: Maybe<Scalars["String"]>;
  Work_Phone?: Maybe<Scalars["String"]>;
  Zip?: Maybe<Scalars["String"]>;
  cell_phone?: Maybe<Scalars["String"]>;
  duty?: Maybe<Scalars["String"]>;
  email?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["Int"]>;
  offic_id?: Maybe<Scalars["String"]>;
  paid?: Maybe<Scalars["String"]>;
  salary?: Maybe<Scalars["String"]>;
  vendor_number?: Maybe<Scalars["String"]>;
  voucher_field?: Maybe<Scalars["Int"]>;
  voucher_number?: Maybe<Scalars["String"]>;
  woker_name?: Maybe<Scalars["String"]>;
};

export type DailyCalendarOfficialExport = {
  __typename?: "DailyCalendarOfficialExport";
  address?: Maybe<Scalars["String"]>;
  cell_phone?: Maybe<Scalars["String"]>;
  city?: Maybe<Scalars["String"]>;
  comments?: Maybe<Scalars["String"]>;
  duty?: Maybe<Scalars["String"]>;
  email?: Maybe<Scalars["String"]>;
  end_time?: Maybe<Scalars["String"]>;
  event_date?: Maybe<Scalars["String"]>;
  event_id?: Maybe<Scalars["Int"]>;
  groupVal?: Maybe<Scalars["String"]>;
  home_phone?: Maybe<Scalars["String"]>;
  location?: Maybe<Scalars["String"]>;
  offic_id?: Maybe<Scalars["String"]>;
  opponent?: Maybe<Scalars["String"]>;
  salary?: Maybe<Scalars["String"]>;
  sport_description?: Maybe<Scalars["String"]>;
  ssn?: Maybe<Scalars["String"]>;
  start_time?: Maybe<Scalars["String"]>;
  state?: Maybe<Scalars["String"]>;
  vendor_number?: Maybe<Scalars["String"]>;
  voucher_number?: Maybe<Scalars["String"]>;
  woker_name?: Maybe<Scalars["String"]>;
  work_phone?: Maybe<Scalars["String"]>;
  zip?: Maybe<Scalars["String"]>;
};

export type DailyCalendarOfficialMessage = {
  __typename?: "DailyCalendarOfficialMessage";
  comments?: Maybe<Scalars["String"]>;
  end_time?: Maybe<Scalars["String"]>;
  event_date?: Maybe<Scalars["String"]>;
  event_id?: Maybe<Scalars["Int"]>;
  location?: Maybe<Scalars["String"]>;
  official_duties?: Maybe<Array<Maybe<DailyCalendarOfficialDuties>>>;
  opponent?: Maybe<Scalars["String"]>;
  sport?: Maybe<Scalars["String"]>;
  start_time?: Maybe<Scalars["String"]>;
};

export type DailyCalendarPreparation = {
  __typename?: "DailyCalendarPreparation";
  comments?: Maybe<Scalars["String"]>;
  prep?: Maybe<Scalars["String"]>;
  qty?: Maybe<Scalars["String"]>;
};

export type DailyCalendarPreparationExportData = {
  __typename?: "DailyCalendarPreparationExportData";
  comments?: Maybe<Scalars["String"]>;
  end_time?: Maybe<Scalars["String"]>;
  event_date?: Maybe<Scalars["String"]>;
  event_id?: Maybe<Scalars["Int"]>;
  groupval?: Maybe<Scalars["String"]>;
  location?: Maybe<Scalars["String"]>;
  opponent?: Maybe<Scalars["String"]>;
  prep?: Maybe<Scalars["String"]>;
  qty?: Maybe<Scalars["String"]>;
  sport_description?: Maybe<Scalars["String"]>;
  start_time?: Maybe<Scalars["String"]>;
};

export type DailyCalendarPreparationMessage = {
  __typename?: "DailyCalendarPreparationMessage";
  comments?: Maybe<Scalars["String"]>;
  confirmed?: Maybe<Scalars["String"]>;
  end_time?: Maybe<Scalars["String"]>;
  event_date?: Maybe<Scalars["String"]>;
  event_id?: Maybe<Scalars["Int"]>;
  groupval?: Maybe<Scalars["String"]>;
  location?: Maybe<Scalars["String"]>;
  opponent?: Maybe<Scalars["String"]>;
  place?: Maybe<Scalars["String"]>;
  preparations?: Maybe<Array<Maybe<DailyCalendarPreparation>>>;
  sport?: Maybe<Scalars["String"]>;
  start_time?: Maybe<Scalars["String"]>;
};

export type DailyCalendarWorkerDuties = {
  __typename?: "DailyCalendarWorkerDuties";
  SSN?: Maybe<Scalars["String"]>;
  comments?: Maybe<Scalars["String"]>;
  duty?: Maybe<Scalars["String"]>;
  end_time?: Maybe<Scalars["String"]>;
  event_date?: Maybe<Scalars["String"]>;
  event_id?: Maybe<Scalars["Int"]>;
  groupval?: Maybe<Scalars["String"]>;
  location?: Maybe<Scalars["String"]>;
  opponent?: Maybe<Scalars["String"]>;
  organization?: Maybe<Scalars["String"]>;
  paid?: Maybe<Scalars["String"]>;
  pay_code?: Maybe<Scalars["String"]>;
  phones?: Maybe<Scalars["String"]>;
  salary?: Maybe<Scalars["Float"]>;
  sport_description?: Maybe<Scalars["String"]>;
  start_time?: Maybe<Scalars["String"]>;
  woker_name?: Maybe<Scalars["String"]>;
};

export type DailyCalendarWorkerExportData = {
  __typename?: "DailyCalendarWorkerExportData";
  comments?: Maybe<Scalars["String"]>;
  duty?: Maybe<Scalars["String"]>;
  end_time?: Maybe<Scalars["String"]>;
  event_date?: Maybe<Scalars["String"]>;
  event_id?: Maybe<Scalars["Int"]>;
  groupval?: Maybe<Scalars["String"]>;
  location?: Maybe<Scalars["String"]>;
  opponent?: Maybe<Scalars["String"]>;
  pay_code?: Maybe<Scalars["String"]>;
  phones?: Maybe<Scalars["String"]>;
  sport_description?: Maybe<Scalars["String"]>;
  start_time?: Maybe<Scalars["String"]>;
  woker_name?: Maybe<Scalars["String"]>;
};

export type DailyCalendarWorkerMessage = {
  __typename?: "DailyCalendarWorkerMessage";
  comments?: Maybe<Scalars["String"]>;
  end_time?: Maybe<Scalars["String"]>;
  event_date?: Maybe<Scalars["String"]>;
  event_id?: Maybe<Scalars["Int"]>;
  groupval?: Maybe<Scalars["String"]>;
  location?: Maybe<Scalars["String"]>;
  opponent?: Maybe<Scalars["String"]>;
  sport?: Maybe<Scalars["String"]>;
  start_time?: Maybe<Scalars["String"]>;
  worker_duties?: Maybe<Array<Maybe<DailyCalendarWorkerDuties>>>;
};

export type DateRange = {
  endDate: Scalars["Date"];
  startDate: Scalars["Date"];
};

/** Filtering options for nullable DateTime type */
export type DateTimeNullableFilter = {
  /** Equals operation */
  equals?: InputMaybe<Scalars["String"]>;
  /** Greater Than operation */
  gt?: InputMaybe<Scalars["String"]>;
  /** Greater Than or Equal operation */
  gte?: InputMaybe<Scalars["String"]>;
  /** In operation for multiple values */
  in?: InputMaybe<Array<Scalars["String"]>>;
  /** Less Than operation */
  lt?: InputMaybe<Scalars["String"]>;
  /** Less Than or Equal operation */
  lte?: InputMaybe<Scalars["String"]>;
  /** Not Equal operation */
  not?: InputMaybe<Scalars["String"]>;
  /** Not In operation for multiple values */
  notIn?: InputMaybe<Array<Scalars["String"]>>;
};

export type DeleteCount = {
  __typename?: "DeleteCount";
  count?: Maybe<Scalars["Int"]>;
};

export type DeleteEventInput = {
  event_id: Scalars["Int"];
};

export type DeleteEventParticipantsInput = {
  id: Scalars["Int"];
};

export type DeleteEventPreparationInput = {
  id: Scalars["Int"];
};

export type DeleteEventPreparationsInput = {
  id: Scalars["Int"];
};

export type DeleteEventTransportDetailsInput = {
  id: Scalars["Int"];
};

export type DeleteFacilityInput = {
  place_name: Scalars["String"];
};

export type DeleteLevelInput = {
  ID: Scalars["Int"];
};

export type DeleteManyEventParticipantsInput = {
  ids: Array<InputMaybe<Scalars["Int"]>>;
};

export type DeleteManyEventPreparationsInput = {
  ids: Array<InputMaybe<Scalars["Int"]>>;
};

export type DeleteManyEventTransportDetailsInput = {
  ids: Array<InputMaybe<Scalars["Int"]>>;
};

export type DeleteManyWorkerInput = {
  ids: Array<InputMaybe<Scalars["Int"]>>;
};

export type DeleteOfficialDutiesInput = {
  ids: Array<Scalars["Int"]>;
};

export type DeleteOfficialInput = {
  id: Scalars["Int"];
};

export type DeleteOfficialPoolInput = {
  ids: Array<InputMaybe<Scalars["String"]>>;
};

export type DeleteOpponentInput = {
  SchoolCode: Scalars["String"];
};

export type DeletePreparationInput = {
  id: Scalars["Int"];
};

export type DeleteSchoolInfoInput = {
  id: Scalars["String"];
};

export type DeleteVehicleInput = {
  id: Scalars["Int"];
};

export type DeleteWorkerInput = {
  id: Scalars["Int"];
};

export type DeleteWorkerPoolInput = {
  ids: Array<InputMaybe<Scalars["Int"]>>;
};

export type DeletedIncentiveRecord = {
  __typename?: "DeletedIncentiveRecord";
  id: Scalars["ID"];
};

export type Deposit = {
  __typename?: "Deposit";
  amount: Scalars["Decimal"];
  campaignId: Scalars["Int"];
  created: Scalars["Timestamp"];
  description: Scalars["String"];
  destination: Account;
  effective: Scalars["Timestamp"];
  externalId: Scalars["String"];
  source: Account;
  transactionId: Scalars["UUID"];
};

/** Documents uploaded for validation purposes */
export type Document = {
  __typename?: "Document";
  documentBack?: Maybe<Scalars["String"]>;
  documentFront?: Maybe<Scalars["String"]>;
  gateway: VaultFinancialProvider;
  id: Scalars["String"];
  type: Scalars["String"];
  vaultKybId?: Maybe<Scalars["ID"]>;
  vaultKycId?: Maybe<Scalars["ID"]>;
};

export type DonateIntent = {
  __typename?: "DonateIntent";
  /** Donation amount (without coverage fee) in cents */
  amount?: Maybe<Scalars["Int"]>;
  anonymous?: Maybe<Scalars["Boolean"]>;
  /** Flag to indicate if the Credit card fee is covered by the donor */
  ccFeeCovered?: Maybe<Scalars["Boolean"]>;
  /** Credit card fee percentage, If leave null/undefined, it will be calculated at 0.03 (3%) */
  ccFeePercentage?: Maybe<Scalars["Float"]>;
  /** Fee amount coverage by donor (without coverage fee) in cents */
  coverageAmount?: Maybe<Scalars["Int"]>;
  /** Linked donation if existed */
  donation?: Maybe<DonorDonation>;
  donationId?: Maybe<Scalars["String"]>;
  donorEmail?: Maybe<Scalars["String"]>;
  donorFirstName?: Maybe<Scalars["String"]>;
  donorLastName?: Maybe<Scalars["String"]>;
  fundraiser: DonorFundraiser;
  history?: Maybe<Array<Maybe<DonateIntent>>>;
  id: Scalars["ID"];
  latestCartPurchaseIntent?: Maybe<GiftShopPurchaseIntentData>;
  matchDon?: Maybe<Scalars["String"]>;
  participantId?: Maybe<Scalars["Int"]>;
  participantUserDirectoryId?: Maybe<Scalars["String"]>;
  /** Payment Method used for Strip or Paypal payment */
  paymentMethodId?: Maybe<Scalars["String"]>;
  paymentProvider?: Maybe<PaymentProvider>;
  previousStatus?: Maybe<DonateIntentStatus>;
  /** Share Type of donation */
  shareType?: Maybe<Scalars["String"]>;
  /** The subtotal amount in cents which will be send to Snap's Stripe account snapAmount = (amount + coverageAmount - allocationAmount + tipAmount), where allocationAmount is the subtotalAmount will be sent to team's Stripe connected account, calculated through donation-allocation-amount helper. */
  snapAmount?: Maybe<Scalars["Int"]>;
  status: DonateIntentStatus;
  tipAmount?: Maybe<Scalars["Int"]>;
  /** Total amounts will be charged to donor's credit card. Equals amount + coverageAmount */
  totalAmount?: Maybe<Scalars["Int"]>;
};

export enum DonateIntentStatus {
  Cancelled = "CANCELLED",
  ChargedBack = "CHARGED_BACK",
  Failed = "FAILED",
  Initiated = "INITIATED",
  Pending = "PENDING",
  Processing = "PROCESSING",
  Refunded = "REFUNDED",
  Succeeded = "SUCCEEDED",
}

/** Not used yet. Will be removed soon. */
export type DonationInvite = {
  __typename?: "DonationInvite";
  email?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  lastEmailSentAt?: Maybe<Scalars["String"]>;
  senderName?: Maybe<Scalars["String"]>;
  status?: Maybe<Scalars["String"]>;
};

export type DonationInviteReleaseStatus = {
  __typename?: "DonationInviteReleaseStatus";
  areDonationInviteEmailsReleased: Scalars["Boolean"];
};

export type DonationInviteRemoveData = {
  __typename?: "DonationInviteRemoveData";
  email?: Maybe<Scalars["String"]>;
  id: Scalars["Int"];
};

export type DonationInviteSmsData = {
  __typename?: "DonationInviteSMSData";
  id: Scalars["Int"];
  phoneNumber: Scalars["String"];
  status: Scalars["String"];
};

export type DonationLevel = {
  __typename?: "DonationLevel";
  amount: Scalars["Int"];
  id: Scalars["ID"];
  level: DonationLevels;
  rewardDescription?: Maybe<Scalars["String"]>;
  text?: Maybe<Scalars["String"]>;
};

export enum DonationLevels {
  Five = "FIVE",
  Four = "FOUR",
  One = "ONE",
  Six = "SIX",
  Three = "THREE",
  Two = "TWO",
}

export type DonationTopEarner = {
  __typename?: "DonationTopEarner";
  participantID?: Maybe<Scalars["Int"]>;
};

/** Donation Statistics. Will change name to CampaignDonationStats later. */
export type Donations = {
  __typename?: "Donations";
  /** Total of distinct donors in campaigns */
  AskedToShare?: Maybe<Scalars["Int"]>;
  /** Total count of donations in campaign */
  count?: Maybe<Scalars["Int"]>;
  /** Total donation amount in cents raised through email */
  emailTotal?: Maybe<Scalars["Int"]>;
  /** Total donation amount in cents raised other methods outside text, email and social */
  extraTotal?: Maybe<Scalars["Int"]>;
  /** Total of donation amount in cents raised through forward to friends. */
  fowardTotal?: Maybe<Scalars["Int"]>;
  /** Total quantity of gear items purchased in campaign. */
  gearAdded?: Maybe<Scalars["Int"]>;
  /** Total count of distinct donors that purchased gears. */
  gearPurchased?: Maybe<Scalars["Int"]>;
  /** Total of purchase amount in cents of gear (OTK) items */
  gearTotal?: Maybe<Scalars["Int"]>;
  /** Total donation amounts in cents through retaining supporters from previous closed campaign. Share Type is 'reisgn_a' or 'resign_b' */
  legacySupporters?: Maybe<Scalars["Int"]>;
  /** Total count of distinct donors who are new and donated campaigns through share-type 'supporter_referral' */
  newSupporters?: Maybe<Scalars["Int"]>;
  otherTotal?: Maybe<Scalars["Int"]>;
  /** Total count of donors who are retaining supporters from previous closed campaign. Share Type is 'reisgn_a' or 'resign_b' */
  pastSupporters?: Maybe<Scalars["Int"]>;
  /** Total donation amount in cents raised through social media */
  socialTotal?: Maybe<Scalars["Int"]>;
  /** Total donation amount in cents raised through sms/text */
  textTotal?: Maybe<Scalars["Int"]>;
};

export enum DonationsAndPurchasesEnum {
  Donation = "donation",
  Purchase = "purchase",
}

export type DonationsResponse = {
  __typename?: "DonationsResponse";
  fundraiserTopDonation?: Maybe<TopDonation>;
  fundraiserTotalDonationsRaised?: Maybe<TotalDonationsRaised>;
  participantTopDonation?: Maybe<TopDonation>;
  participantTotalDonationsRaised?: Maybe<TotalDonationsRaised>;
};

export type DonorData = {
  __typename?: "DonorData";
  email?: Maybe<Scalars["String"]>;
  firstName?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  lastName?: Maybe<Scalars["String"]>;
};

export type DonorDonateIntentCreateInput = {
  amount: Scalars["Int"];
  /** This is preference of the donors to be anonymous */
  anonymous?: InputMaybe<Scalars["Boolean"]>;
  /** Flag to indicate if the Credit card fee is covered by the donor */
  ccFeeCovered?: InputMaybe<Scalars["Boolean"]>;
  ccFeePercentage?: InputMaybe<Scalars["Float"]>;
  /** This is the Donation Level ID that will be passed thru the URL on Checkout. */
  donationLevelId?: InputMaybe<Scalars["String"]>;
  donorEmail?: InputMaybe<Scalars["String"]>;
  donorFirstName?: InputMaybe<Scalars["String"]>;
  donorLastName?: InputMaybe<Scalars["String"]>;
  errorType?: InputMaybe<Scalars["String"]>;
  fundraiserId: Scalars["String"];
  matchDon?: InputMaybe<Scalars["String"]>;
  /** This is the IP address of the donor */
  originIp?: InputMaybe<Scalars["String"]>;
  participantId?: InputMaybe<Scalars["Int"]>;
  participantUserDirectoryId?: InputMaybe<Scalars["String"]>;
  /** Share Type of donation will be passed as a search query to the URL on Checkout. Valid values can be found at https://www.notion.so/snap-mobile/508f9d741b434cef85cb2c5cbdf15eb7?v=804cce5f998a415b97d672037112b4ae&pvs=4 under the New Stack Share Types column */
  shareType?: InputMaybe<Scalars["String"]>;
  tipAmount?: InputMaybe<Scalars["Int"]>;
};

export type DonorDonateIntentUpdateInput = {
  amount?: InputMaybe<Scalars["Int"]>;
  /** This is preference of the donors to be anonymous */
  anonymous?: InputMaybe<Scalars["Boolean"]>;
  /** Flag to indicate if the Credit card fee is covered by the donor */
  ccFeeCovered?: InputMaybe<Scalars["Boolean"]>;
  ccFeePercentage?: InputMaybe<Scalars["Float"]>;
  cheerwallName?: InputMaybe<Scalars["String"]>;
  /** This is the Donation Level ID that will be passed thru the URL on Checkout. */
  donationLevelId?: InputMaybe<Scalars["String"]>;
  donorEmail?: InputMaybe<Scalars["String"]>;
  donorFirstName?: InputMaybe<Scalars["String"]>;
  donorLastName?: InputMaybe<Scalars["String"]>;
  donorMessage?: InputMaybe<Scalars["String"]>;
  errorType?: InputMaybe<Scalars["String"]>;
  matchDon?: InputMaybe<Scalars["String"]>;
  participantId?: InputMaybe<Scalars["Int"]>;
  participantUserDirectoryId?: InputMaybe<Scalars["String"]>;
  paymentMethodId?: InputMaybe<Scalars["String"]>;
  paymentProvider?: InputMaybe<PaymentProvider>;
  tipAmount?: InputMaybe<Scalars["Int"]>;
};

/** A donation made by a donor to a fundraiser. */
export type DonorDonation = {
  __typename?: "DonorDonation";
  anonymous?: Maybe<Scalars["Boolean"]>;
  appFeeCents?: Maybe<Scalars["Int"]>;
  authorizationCode?: Maybe<Scalars["String"]>;
  averageDonationCents?: Maybe<Scalars["Int"]>;
  ccFeePercentage?: Maybe<Scalars["Float"]>;
  chargedBackAt?: Maybe<Scalars["DateTime"]>;
  checkoutId?: Maybe<Scalars["String"]>;
  checkoutState?: Maybe<DonorDonationCheckoutState>;
  cheerwallName?: Maybe<Scalars["String"]>;
  countryCode?: Maybe<Scalars["String"]>;
  coverageAmountCents?: Maybe<Scalars["Int"]>;
  createdAt?: Maybe<Scalars["DateTime"]>;
  creditCardId?: Maybe<Scalars["String"]>;
  deletedAt?: Maybe<Scalars["DateTime"]>;
  disputeWonId?: Maybe<Scalars["String"]>;
  donateIntent?: Maybe<DonateIntent>;
  donationLevel?: Maybe<DonorDonationLevel>;
  donationLevelId?: Maybe<Scalars["String"]>;
  donor?: Maybe<DonorUser>;
  donorId?: Maybe<Scalars["String"]>;
  donorMessage?: Maybe<Scalars["String"]>;
  donorName?: Maybe<Scalars["String"]>;
  extCcFeeCents?: Maybe<Scalars["Int"]>;
  fbPostId?: Maybe<Scalars["String"]>;
  fundraiser?: Maybe<DonorFundraiser>;
  fundraiserId?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  intCcFeeCents?: Maybe<Scalars["Int"]>;
  matchDon?: Maybe<Scalars["String"]>;
  newsletterOptIn?: Maybe<Scalars["Boolean"]>;
  origin?: Maybe<Scalars["String"]>;
  originIp?: Maybe<Scalars["String"]>;
  participant?: Maybe<DonorParticipantData>;
  participantId?: Maybe<Scalars["Int"]>;
  participantUserDirectoryId?: Maybe<Scalars["String"]>;
  paymentId?: Maybe<Scalars["String"]>;
  paymentMethodId?: Maybe<Scalars["String"]>;
  paymentProvider?: Maybe<Scalars["String"]>;
  postalCode?: Maybe<Scalars["String"]>;
  raiseId?: Maybe<Scalars["Int"]>;
  refundReason?: Maybe<Scalars["String"]>;
  serviceFeeCents?: Maybe<Scalars["Int"]>;
  /** Share Type of donation */
  shareType?: Maybe<Scalars["String"]>;
  status?: Maybe<DonorDonationStatus>;
  subtotal?: Maybe<Scalars["String"]>;
  subtotalCents?: Maybe<Scalars["Int"]>;
  supporterId?: Maybe<Scalars["Int"]>;
  tipAmountCents?: Maybe<Scalars["Int"]>;
  totalPriceCents?: Maybe<Scalars["Int"]>;
  transactionId?: Maybe<Scalars["String"]>;
  updatedAt?: Maybe<Scalars["DateTime"]>;
};

export enum DonorDonationCheckoutState {
  Authorized = "AUTHORIZED",
  Cancelled = "CANCELLED",
  Captured = "CAPTURED",
  ChargedBack = "CHARGED_BACK",
  Expired = "EXPIRED",
  Failed = "FAILED",
  New = "NEW",
  Refunded = "REFUNDED",
  Released = "RELEASED",
}

/** Donation levels that are displayed to donors on the donation form, suggesting donation specific amounts and detailing why a donor might select them. */
export type DonorDonationLevel = {
  __typename?: "DonorDonationLevel";
  amount: Scalars["Int"];
  createdAt?: Maybe<Scalars["DateTime"]>;
  fundraiserId?: Maybe<Scalars["String"]>;
  id: Scalars["String"];
  impact?: Maybe<Scalars["String"]>;
  productId?: Maybe<Scalars["Int"]>;
  rewardDescription?: Maybe<Scalars["String"]>;
  updatedAt?: Maybe<Scalars["DateTime"]>;
};

export type DonorDonationOfflineInput = {
  /** The amount of the donation in cents */
  amount: Scalars["Int"];
  anonymous?: InputMaybe<Scalars["Boolean"]>;
  donorName?: InputMaybe<Scalars["String"]>;
  email?: InputMaybe<Scalars["String"]>;
  fundraiserId: Scalars["String"];
  participantId?: InputMaybe<Scalars["Int"]>;
  participantUserDirectoryId?: InputMaybe<Scalars["String"]>;
};

export type DonorDonationRecordUpdate = {
  __typename?: "DonorDonationRecordUpdate";
  donateIntentData?: Maybe<DonateIntent>;
  donationId?: Maybe<Scalars["String"]>;
};

export enum DonorDonationStatus {
  Anonymous = "ANONYMOUS",
  ChargedBack = "CHARGED_BACK",
  Complete = "COMPLETE",
  Offline = "OFFLINE",
  Online = "ONLINE",
  Paid = "PAID",
  Refunded = "REFUNDED",
  Refunding = "REFUNDING",
  Released = "RELEASED",
  Unpaid = "UNPAID",
  Updated1 = "UPDATED1",
  Updated2 = "UPDATED2",
}

export type DonorDonationUpdateInput = {
  anonymous?: InputMaybe<Scalars["Boolean"]>;
  cheerwallName?: InputMaybe<Scalars["String"]>;
  donorId?: InputMaybe<Scalars["ID"]>;
  donorMessage?: InputMaybe<Scalars["String"]>;
  donorName?: InputMaybe<Scalars["String"]>;
  participantId?: InputMaybe<Scalars["Int"]>;
  participantUserDirectoryId?: InputMaybe<Scalars["String"]>;
  status?: InputMaybe<DonorDonationStatus>;
  subtotalCents?: InputMaybe<Scalars["Int"]>;
};

export type DonorEmailData = {
  __typename?: "DonorEmailData";
  deliveryStatus?: Maybe<Scalars["String"]>;
  emailAddress?: Maybe<Scalars["String"]>;
  followUpNumber?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  personListEntryID?: Maybe<Scalars["Int"]>;
};

/** A copy of the pertinent fields from the Fundraiser model in raise. */
export type DonorFundraiser = {
  __typename?: "DonorFundraiser";
  ccFeeCovered?: Maybe<Scalars["Boolean"]>;
  ccFeePayer?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  donationMinimumCents?: Maybe<Scalars["Int"]>;
  donations?: Maybe<Array<Maybe<DonorDonation>>>;
  endDate: Scalars["DateTime"];
  feeType?: Maybe<Scalars["String"]>;
  /** The amount of money to raise in dollars. */
  goal?: Maybe<Scalars["Int"]>;
  id: Scalars["ID"];
  logo?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  /** Old ID field from raise */
  raiseId: Scalars["Int"];
  startDate: Scalars["DateTime"];
  status?: Maybe<Scalars["String"]>;
  whyDonations?: Maybe<Scalars["String"]>;
};

export type DonorFundraiserData = {
  __typename?: "DonorFundraiserData";
  ccFeeCovered?: Maybe<Scalars["Boolean"]>;
  ccFeePayer: SnapFeePayer;
  description?: Maybe<Scalars["String"]>;
  donationMinimumCents?: Maybe<Scalars["Int"]>;
  endDate: Scalars["DateTime"];
  feeType?: Maybe<Scalars["String"]>;
  giftShop: Scalars["Boolean"];
  goal?: Maybe<Scalars["Int"]>;
  id: Scalars["String"];
  legalName?: Maybe<Scalars["String"]>;
  logo?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  participantGoal?: Maybe<Scalars["Int"]>;
  personalMessage?: Maybe<Scalars["String"]>;
  primaryColor?: Maybe<Scalars["String"]>;
  programLeader?: Maybe<Scalars["String"]>;
  programType?: Maybe<Scalars["String"]>;
  raiseEntityId?: Maybe<Scalars["Int"]>;
  raiseId: Scalars["Int"];
  redirect?: Maybe<Scalars["Boolean"]>;
  /** If the redirectPath is provided it means you should actually be accessing this data via the redirectPath */
  redirectPath?: Maybe<Scalars["String"]>;
  region?: Maybe<Scalars["String"]>;
  secondaryColor?: Maybe<Scalars["String"]>;
  slug?: Maybe<Scalars["String"]>;
  startDate: Scalars["DateTime"];
  status: FundraiserStatus;
  stripeConnectId?: Maybe<Scalars["String"]>;
  subtotalDollarsSum?: Maybe<Scalars["Float"]>;
  waCampaignSetup?: Maybe<Scalars["Boolean"]>;
  whyDonations?: Maybe<Scalars["String"]>;
};

export type DonorFundraiserPayable =
  | DonorDonation
  | DonorFundraiserPurchase
  | DonorOfflineDonation;

export type DonorFundraiserPayableRefundInput = {
  /** The id of the donation or purchase */
  id: Scalars["ID"];
  /** Optional: The type of fundraiser payable. If not provided, the type will be determined by the id. */
  type?: InputMaybe<DonorFundraiserPayableTypeEnum>;
};

export enum DonorFundraiserPayableTypeEnum {
  Donation = "donation",
  Purchase = "purchase",
}

export type DonorFundraiserPayablesInput = {
  fundraiserId?: InputMaybe<Scalars["String"]>;
  fundraiserRaiseId?: InputMaybe<Scalars["Int"]>;
};

export type DonorFundraiserPurchase = {
  __typename?: "DonorFundraiserPurchase";
  createdAt?: Maybe<Scalars["DateTime"]>;
  deletedAt?: Maybe<Scalars["DateTime"]>;
  donationId?: Maybe<Scalars["String"]>;
  donor?: Maybe<DonorUser>;
  fundraiserUserId?: Maybe<Scalars["Int"]>;
  id: Scalars["ID"];
  lineItems?: Maybe<Array<Maybe<DonorGiftShopItem>>>;
  participant?: Maybe<DonorParticipantData>;
  participantUserDirectoryId?: Maybe<Scalars["String"]>;
  paymentMethodId?: Maybe<Scalars["String"]>;
  paymentProvider?: Maybe<Scalars["String"]>;
  purchaseIntentId?: Maybe<Scalars["String"]>;
  raiseId?: Maybe<Scalars["Int"]>;
  status?: Maybe<Scalars["String"]>;
  transactionId?: Maybe<Scalars["String"]>;
  type?: Maybe<DonationsAndPurchasesEnum>;
  updatedAt?: Maybe<Scalars["DateTime"]>;
};

export type DonorGiftShopItem = {
  __typename?: "DonorGiftShopItem";
  costCents?: Maybe<Scalars["Int"]>;
  createdAt?: Maybe<Scalars["DateTime"]>;
  id: Scalars["ID"];
  image?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  priceCents?: Maybe<Scalars["Int"]>;
  purchasableId?: Maybe<Scalars["Int"]>;
  quantity?: Maybe<Scalars["Int"]>;
  size?: Maybe<Scalars["String"]>;
  status?: Maybe<Scalars["String"]>;
  updatedAt?: Maybe<Scalars["DateTime"]>;
};

export type DonorOfflineDonation = {
  __typename?: "DonorOfflineDonation";
  anonymous?: Maybe<Scalars["Boolean"]>;
  cheerwallName?: Maybe<Scalars["String"]>;
  createdAt?: Maybe<Scalars["DateTime"]>;
  deletedAt?: Maybe<Scalars["DateTime"]>;
  /** This type is meant for admin only */
  donor?: Maybe<DonorUser>;
  donorId?: Maybe<Scalars["String"]>;
  donorMessage?: Maybe<Scalars["String"]>;
  donorName?: Maybe<Scalars["String"]>;
  fundraiser?: Maybe<DonorFundraiser>;
  fundraiserId?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  origin?: Maybe<Scalars["String"]>;
  participant?: Maybe<DonorParticipantData>;
  participantId?: Maybe<Scalars["Int"]>;
  participantUserDirectoryId?: Maybe<Scalars["String"]>;
  raiseId?: Maybe<Scalars["Int"]>;
  status?: Maybe<DonorDonationStatus>;
  subtotalCents?: Maybe<Scalars["Int"]>;
  updatedAt?: Maybe<Scalars["DateTime"]>;
};

export type DonorParticipantData = {
  __typename?: "DonorParticipantData";
  raiseId?: Maybe<Scalars["Int"]>;
  user?: Maybe<User>;
};

export type DonorPayableRefundResponse = {
  __typename?: "DonorPayableRefundResponse";
  payable?: Maybe<DonorFundraiserPayable>;
  refundId?: Maybe<Scalars["String"]>;
};

export type DonorPersonListEntry = {
  __typename?: "DonorPersonListEntry";
  email?: Maybe<Scalars["String"]>;
  id: Scalars["Int"];
};

/** This type is meant for admin only */
export type DonorUser = {
  __typename?: "DonorUser";
  email?: Maybe<Scalars["String"]>;
  firstName?: Maybe<Scalars["String"]>;
  /** Donor Id */
  id: Scalars["ID"];
  lastName?: Maybe<Scalars["String"]>;
  raiseUserId?: Maybe<Scalars["Int"]>;
  user?: Maybe<User>;
};

export type DonorsCheerwall = {
  __typename?: "DonorsCheerwall";
  createdAt?: Maybe<Scalars["DateTime"]>;
  donorMessage?: Maybe<Scalars["String"]>;
  donorName?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  images?: Maybe<Array<Maybe<Scalars["String"]>>>;
  participant?: Maybe<DonorsParticipantPublic>;
  subtotalCents?: Maybe<Scalars["Int"]>;
  type?: Maybe<Scalars["String"]>;
};

export type DonorsCheerwallsResult = {
  __typename?: "DonorsCheerwallsResult";
  cheers?: Maybe<Array<Maybe<DonorsCheerwall>>>;
  count?: Maybe<Scalars["Int"]>;
};

export type DonorsDonationLevel = {
  __typename?: "DonorsDonationLevel";
  amount: Scalars["String"];
  id: Scalars["ID"];
  impact?: Maybe<Scalars["String"]>;
  rewardDescription?: Maybe<Scalars["String"]>;
};

/** @deprecated */
export type DonorsFundraiserCheerWall = {
  __typename?: "DonorsFundraiserCheerWall";
  cheers?: Maybe<Array<Maybe<CheerWallData>>>;
  count?: Maybe<Scalars["Int"]>;
};

export type DonorsParticipantOtkIncentive = {
  __typename?: "DonorsParticipantOTKIncentive";
  description?: Maybe<Scalars["String"]>;
  displayOrder?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  image?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  priceCents?: Maybe<Scalars["Int"]>;
  purchaseLimit?: Maybe<Scalars["Int"]>;
  purchased?: Maybe<Scalars["Int"]>;
  size?: Maybe<Scalars["String"]>;
};

export type DonorsParticipantOtkIncentives = {
  __typename?: "DonorsParticipantOTKIncentives";
  incentives?: Maybe<Array<Maybe<DonorsParticipantOtkIncentive>>>;
};

export type DonorsParticipantPublic = {
  __typename?: "DonorsParticipantPublic";
  data?: Maybe<DonorsParticipantPublicData>;
  raiseId?: Maybe<Scalars["Int"]>;
};

export type DonorsParticipantPublicData = {
  __typename?: "DonorsParticipantPublicData";
  firstName?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  lastName?: Maybe<Scalars["String"]>;
  profilePicture?: Maybe<Scalars["String"]>;
};

export type DonorsParticipantsGiftShop = {
  __typename?: "DonorsParticipantsGiftShop";
  fundraiserUserId?: Maybe<Scalars["Int"]>;
  giftShop?: Maybe<Array<Maybe<DonorsParticipantsGiftShopData>>>;
  totalPurchased?: Maybe<Scalars["Int"]>;
};

export type DonorsParticipantsGiftShopData = {
  __typename?: "DonorsParticipantsGiftShopData";
  costCents?: Maybe<Scalars["Int"]>;
  description?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["Int"]>;
  image?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  netEarnedPercentage?: Maybe<Scalars["Float"]>;
  priceCents?: Maybe<Scalars["Int"]>;
  promoLabel?: Maybe<Scalars["String"]>;
  purchaseLimit?: Maybe<Scalars["Int"]>;
  size?: Maybe<Scalars["String"]>;
};

/** Account User is a user that is registered in Raise's database. Deprecated: Use UserDirectory 's User instead instead */
export type DriveAccountUser = DriveUser & {
  __typename?: "DriveAccountUser";
  email?: Maybe<Scalars["String"]>;
  firstName?: Maybe<Scalars["String"]>;
  id: Scalars["String"];
  lastName?: Maybe<Scalars["String"]>;
  phoneNumber?: Maybe<Scalars["String"]>;
  profilePicture?: Maybe<Scalars["String"]>;
  snapRaiseId?: Maybe<Scalars["Int"]>;
};

/** MDM Actor. e.g. Account Manager/Salereps/Sales Manager */
export type DriveAmSalesReps = {
  __typename?: "DriveAmSalesReps";
  /** Primary contact email of salesrep */
  email?: Maybe<Scalars["String"]>;
  /** End time of salesrep */
  endDate?: Maybe<Scalars["DateTime"]>;
  /** First Name of Salesrep */
  firstName?: Maybe<Scalars["String"]>;
  /** Last Name of Salesrep */
  lastName?: Maybe<Scalars["String"]>;
  /** Primary phone number of salesrep */
  phone?: Maybe<Scalars["String"]>;
  /** The sales manager of this salesrep */
  salesManager?: Maybe<DriveAmSalesReps>;
  /** Salesrep Id. This is Raise's account user id. */
  salesrepId?: Maybe<Scalars["Int"]>;
  /** Start time of salesrep */
  startDate?: Maybe<Scalars["DateTime"]>;
  /** actor type. e.g. 'salesrep', 'salesmanager', 'am' */
  type?: Maybe<Scalars["String"]>;
};

export enum DriveAttendanceStatus {
  Attending = "ATTENDING",
  NotAttending = "NOT_ATTENDING",
}

/** Information of a single drive campaign */
export type DriveCampaign = {
  __typename?: "DriveCampaign";
  /** The account manager of the campaign */
  accountManager?: Maybe<AccountManager>;
  /** @deprecated: not used, in favor of totalRaisedCents */
  donationRaisedCents?: Maybe<Scalars["Int"]>;
  /** The date the campaign ends */
  endDate?: Maybe<Scalars["DateTime"]>;
  /** The date the campaign has been finalized/closed */
  finalizedDate?: Maybe<Scalars["DateTime"]>;
  /** Indicate whether the campaign has any incentives, such as OTK */
  hasIncentive?: Maybe<Scalars["Boolean"]>;
  id: Scalars["Int"];
  /** Name of campaign */
  name?: Maybe<Scalars["String"]>;
  /** Notes for a campaign */
  notes?: Maybe<Scalars["String"]>;
  /** Url-slug of campaign name which is a unique identifier */
  slug?: Maybe<Scalars["String"]>;
  /** The date campaign starts */
  startDate?: Maybe<Scalars["DateTime"]>;
  /** Current status of campaign */
  status?: Maybe<CampaignStatus>;
  /** The size of the team/group attached to campaign */
  teamSize?: Maybe<Scalars["Int"]>;
  /** @deprecated: not used, in favor of `totalRaisedCents` */
  totalRaised?: Maybe<Scalars["BigInt"]>;
  /** Total amount of donation of campaign in cents. */
  totalRaisedCents?: Maybe<Scalars["Int"]>;
};

/** Campaign/Fundraiser details information. NOTE: In the future, we might merged with DriveCampaign or Campaign */
export type DriveCampaignDetails = {
  __typename?: "DriveCampaignDetails";
  /** Account Balance of campaign. */
  accountBalance?: Maybe<AccountBalanceReturn>;
  /** The activity/sport of this campaign, e.g. 'football', 'wrestling', etc. */
  activityType?: Maybe<Scalars["String"]>;
  /** The amount in cents that campaigns was advanced in Wallet. */
  advanceAmount?: Maybe<Scalars["Int"]>;
  /** Bill type when settled/finalized. Values are 'invoice', 'regular' and 'default' */
  billType?: Maybe<Scalars["String"]>;
  /** Credit Card Fee Payer. Values are 'customer' or 'snap'. */
  ccFeePayer?: Maybe<Scalars["String"]>;
  /** Charge back fee in cents. The charge from payment processor when a donor cancels their donation through their bank instead of getting a refund from us directly. (usually $15 per) */
  chargebackFee?: Maybe<Scalars["Int"]>;
  /** The statistic of a donation of campaigns */
  donationStats?: Maybe<DriveCampaignDonationStats>;
  /** The statistics of emails delivered/sent */
  emailDelivery?: Maybe<EmailsInfo>;
  /** The type of snap fee being collected. e.g. 'standard', '1+1', '20%/15%', etc. */
  feeType?: Maybe<Scalars["String"]>;
  /** Total incentives price in cents. */
  gearCost?: Maybe<Scalars["Int"]>;
  /** Group leader of a campaign */
  groupLeader?: Maybe<DriveGroupLeader>;
  id: Scalars["Int"];
  /** Cover Image for Campaign */
  image?: Maybe<Scalars["String"]>;
  /** Goal of how much a fundraiser wants to raise */
  initialGoalCents?: Maybe<Scalars["Int"]>;
  /** kyc processor. Values are 'wepay' or 'stripe' */
  kycProcessor?: Maybe<Scalars["String"]>;
  /** kyc status of a campaign. Values are 'pending', 'active', 'inactive', etc. */
  kycStatus?: Maybe<Scalars["String"]>;
  /** The address to ship the check for this campaign */
  mailingAddress?: Maybe<Scalars["String"]>;
  /** The amount in cents that campaigns was manual-advanced in Raise. */
  manualAdvanceAmount?: Maybe<Scalars["Int"]>;
  /** Net raised amount in cents. Equals totalRaised minus snapFee and adds the otkBonus. It is the amount client received in their check/deposit. */
  netRaisedTotal?: Maybe<Scalars["Int"]>;
  /** Notes for settlement for this campaign */
  notes?: Maybe<Scalars["String"]>;
  /** The legal name of a fundraisers organization */
  organizationLegalName?: Maybe<Scalars["String"]>;
  /** The tin number of the organization */
  organizationTin?: Maybe<Scalars["String"]>;
  /** Total bonus amount of otk in cents */
  otkBonus?: Maybe<Scalars["Int"]>;
  /** The statistics of participants's activity of campaign */
  participantStats?: Maybe<DriveParticipantStats>;
  /** Processing Fee when settled/finalized. */
  processingFee?: Maybe<Scalars["Int"]>;
  /** The date where the campaign has settle its payments from snap */
  settlementDate?: Maybe<Scalars["DateTime"]>;
  /** How the funds has delivered to the organization. Values are 'check_fundraiser', 'check_salesrep', 'check_office' and 'direct_deposit' */
  settlementMethod?: Maybe<Scalars["String"]>;
  /** The amount in cents collected as fee when a campaign ends based of the fee type */
  snapFee?: Maybe<Scalars["Float"]>;
  /** Location of this fundraiser */
  territory?: Maybe<Scalars["String"]>;
  /** The amount of purchase in cents. */
  totalPurchaseCents?: Maybe<Scalars["Int"]>;
  /** Total raised of a fundraiser */
  totalRaisedCents?: Maybe<Scalars["Int"]>;
  /** The total raised combined this organization has previously ran campaigns */
  totalRaisedHistory?: Maybe<Scalars["Int"]>;
};

/** Pulls data on a campaigns donation broken down */
export type DriveCampaignDonationStats = {
  __typename?: "DriveCampaignDonationStats";
  ccCoverageAmountCents?: Maybe<Scalars["Int"]>;
  donationAppFeeCents?: Maybe<Scalars["Int"]>;
  /** The amount that been raised */
  donationRaisedCents?: Maybe<Scalars["Int"]>;
  /** The total count of donations */
  donationsCount?: Maybe<Scalars["Int"]>;
  offlineTotalCents?: Maybe<Scalars["Int"]>;
  onlineTotalCents?: Maybe<Scalars["Int"]>;
  /** The count of participants */
  participantCount?: Maybe<Scalars["Int"]>;
  /** The donated amount in cents that been raised by emails delivered */
  totalEmailDonations?: Maybe<Scalars["Int"]>;
  /** The amount that been raised by miscellaneous forms */
  totalExtraDonations?: Maybe<Scalars["Int"]>;
  totalOtherDonations?: Maybe<Scalars["Int"]>;
  /** The donated amount that been raised by sharing through social media */
  totalSocialDonations?: Maybe<Scalars["Int"]>;
  /** The donated amount in cents that been raised by text message */
  totalTextDonations?: Maybe<Scalars["Int"]>;
};

/** Paginated list of campaigns */
export type DriveCampaignList = {
  __typename?: "DriveCampaignList";
  /** Total campaigns */
  count?: Maybe<Scalars["Int"]>;
  list?: Maybe<Array<Maybe<DriveCampaign>>>;
  offset?: Maybe<Scalars["Int"]>;
};

/** Statistics for a set of campaigns in campaign-search. */
export type DriveCampaignSearchStatistics = {
  __typename?: "DriveCampaignSearchStatistics";
  avgDonationDollars?: Maybe<Scalars["Float"]>;
  avgEmailsDelivered?: Maybe<Scalars["Float"]>;
  avgEmailsPerParticipant?: Maybe<Scalars["Float"]>;
  avgRaisedDollarsPerCampaign?: Maybe<Scalars["Float"]>;
  avgRaisedDollarsPerEmail?: Maybe<Scalars["Float"]>;
  avgTeamSize?: Maybe<Scalars["Float"]>;
  totalCampaign?: Maybe<Scalars["BigInt"]>;
  totalCountDelivered?: Maybe<Scalars["BigInt"]>;
  totalCountParticpants?: Maybe<Scalars["BigInt"]>;
  totalDonations?: Maybe<Scalars["BigInt"]>;
  totalRaisedByEmail?: Maybe<Scalars["Float"]>;
  totalRaisedDollars?: Maybe<Scalars["Float"]>;
};

export type DriveCampaignUpdateInput = {
  notes?: InputMaybe<Scalars["String"]>;
};

export type DriveDateCompareInput = {
  equals?: InputMaybe<Scalars["String"]>;
  gt?: InputMaybe<Scalars["String"]>;
  gte?: InputMaybe<Scalars["String"]>;
  lt?: InputMaybe<Scalars["String"]>;
  lte?: InputMaybe<Scalars["String"]>;
};

/** Represents a Event object and its attributes */
export type DriveEvent = {
  __typename?: "DriveEvent";
  /** List of messages in the activity feed. Order by posted ascending by default. */
  activityFeed?: Maybe<Array<Maybe<DriveEventActivityFeed>>>;
  /** Activity types for an event. e.g. `football`, 'basketball', etc. */
  activityTypes?: Maybe<Array<Scalars["String"]>>;
  /** Event Agenda which include start-time/end-time of event dates. Should be empty is **agendaConfig.isSameTime** is true */
  agenda?: Maybe<Array<DriveEventAgendaItem>>;
  /** The configuration for agenda. Format as follows ```JSON { isSameTime: true, startTime: "09:00AM", endTime: "07:00PM" } ``` where **isSameTime** means all event dates will be occured at same **startTime** and **endTime**. If **isSameTime**, **startTime** and **endTime** should be filled-in */
  agendaConfig?: Maybe<Scalars["JSON"]>;
  /** List of attendees. */
  attendees?: Maybe<Array<DriveEventAttendee>>;
  /** Booth information for an event. */
  booth?: Maybe<DriveEventBooth>;
  /** The user who is the clinic leader. */
  clinicLeader?: Maybe<User>;
  /** The user who created the event. */
  createdBy?: Maybe<User>;
  /** The description of an event. */
  description?: Maybe<Scalars["String"]>;
  /** Indicator that event requires a door prize. */
  doorPrizedRequired?: Maybe<Scalars["Boolean"]>;
  /** The end date of an event. */
  endDate: Scalars["String"];
  /** The hotel address for the attendees */
  hotelAddress?: Maybe<Scalars["String"]>;
  /** The hotel name for the attendees */
  hotelName?: Maybe<Scalars["String"]>;
  /** Url for hubspot link. */
  hubspotTrackingLink?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  /** Notes associated for the event. */
  notes?: Maybe<Scalars["String"]>;
  /** A indicator whether the event had been completed planning phase. */
  planningCompleted?: Maybe<Scalars["Boolean"]>;
  /** The time zone of an event */
  preferedTimezone?: Maybe<Scalars["String"]>;
  /** Description for the sponsorship */
  sponsorDescription?: Maybe<Scalars["String"]>;
  /** Indicator whether the door prize has been confirmed. */
  sponsorDoorPrized?: Maybe<Scalars["Boolean"]>;
  /** The start date of an event. */
  startDate: Scalars["String"];
  /** The events status. */
  status: DriveEventStatus;
  /** The tier level for this event. Valid values is `1`, `2` and `3` */
  tier?: Maybe<Scalars["Int"]>;
  /** The name of the event. */
  title: Scalars["String"];
  /** Venue location information for an event. */
  venue?: Maybe<DriveVenue>;
  /** Website link for an event. */
  websiteLink?: Maybe<Scalars["String"]>;
};

/** Information of a activity feed message */
export type DriveEventActivityFeed = {
  __typename?: "DriveEventActivityFeed";
  /** A list of replies the message */
  children?: Maybe<Array<Maybe<DriveEventActivityFeed>>>;
  /** The date this post was created */
  createdAt?: Maybe<Scalars["DateTime"]>;
  /** The event id */
  eventId: Scalars["ID"];
  /** If the message is pinned to the top */
  featured?: Maybe<Scalars["Boolean"]>;
  /** The ID of the message */
  id: Scalars["String"];
  /** The message posted */
  message?: Maybe<Scalars["String"]>;
  /** The date when this post has been updated */
  updatedAt?: Maybe<Scalars["DateTime"]>;
  /** A users first name and last and link to profile photo */
  user?: Maybe<User>;
  /** The id of the user who posted the message */
  userId: Scalars["ID"];
};

export type DriveEventActivityInput = {
  featured?: InputMaybe<Scalars["Boolean"]>;
  message?: InputMaybe<Scalars["String"]>;
  parentId?: InputMaybe<Scalars["String"]>;
};

export type DriveEventAgendaInput = {
  description?: InputMaybe<Scalars["String"]>;
  endTime?: InputMaybe<Scalars["String"]>;
  location?: InputMaybe<Scalars["String"]>;
  startTime?: InputMaybe<Scalars["String"]>;
  title?: InputMaybe<Scalars["String"]>;
};

/** Attributes for an events agenda */
export type DriveEventAgendaItem = {
  __typename?: "DriveEventAgendaItem";
  description?: Maybe<Scalars["String"]>;
  endTime?: Maybe<Scalars["DateTime"]>;
  id: Scalars["ID"];
  location?: Maybe<Scalars["String"]>;
  startTime?: Maybe<Scalars["DateTime"]>;
  title?: Maybe<Scalars["String"]>;
};

/** Represents an events attendee attributes */
export type DriveEventAttendee = {
  __typename?: "DriveEventAttendee";
  /** The department the attendee is in. */
  department?: Maybe<Scalars["String"]>;
  /** The event ID */
  eventId: Scalars["ID"];
  /** The status of an attendee flight request. Default is `NO_REQUEST`. */
  flightRequest?: Maybe<EventAttendeeRequestStatus>;
  /** The status of an attendee hotel request. Default is `NO_REQUEST`. */
  hotelRequest?: Maybe<EventAttendeeRequestStatus>;
  /** @deprecated Use userId instead */
  id: Scalars["ID"];
  /** Indication whether this attendee a clinic leader. */
  isClinicLeader?: Maybe<Scalars["Boolean"]>;
  /** The status of an attendee attendence. */
  status: DriveAttendanceStatus;
  /** User Information of attendee, includes `firstName`, `lastName`, `email`, etc. defined in UserDirectory. */
  user?: Maybe<User>;
  /** The udId of an attendee. e.g. `ud_abc123` */
  userId: Scalars["ID"];
};

export type DriveEventAttendeeInput = {
  department?: InputMaybe<Scalars["String"]>;
  flightRequest?: InputMaybe<EventAttendeeRequestStatus>;
  hotelRequest?: InputMaybe<EventAttendeeRequestStatus>;
  userId?: InputMaybe<Scalars["ID"]>;
};

/** Booth attributes */
export type DriveEventBooth = {
  __typename?: "DriveEventBooth";
  /** Notes for the booth e.g. Electricity is paid for */
  boothNotes?: Maybe<Scalars["String"]>;
  /** The time to end breakdown or clean up event equipment in Venue. */
  breakdownEndTime?: Maybe<Scalars["DateTime"]>;
  /** The time to start breakdown or clean up event equipment in Venue. */
  breakdownStartTime?: Maybe<Scalars["DateTime"]>;
  /** Indication whether electricity is provided. */
  electricityProvided?: Maybe<Scalars["Boolean"]>;
  /** Indication whether flooring is provided. */
  flooringProvided?: Maybe<Scalars["Boolean"]>;
  /** Indication whether internet is provided. */
  internetProvided?: Maybe<Scalars["Boolean"]>;
  /** The time to end setup for event */
  setupEndTime?: Maybe<Scalars["DateTime"]>;
  /** The time to begin setup for event */
  setupStartTime?: Maybe<Scalars["DateTime"]>;
};

export type DriveEventBoothInput = {
  boothNotes?: InputMaybe<Scalars["String"]>;
  breakdownEndTime?: InputMaybe<Scalars["String"]>;
  breakdownStartTime?: InputMaybe<Scalars["String"]>;
  electricityProvided?: InputMaybe<Scalars["Boolean"]>;
  flooringProvided?: InputMaybe<Scalars["Boolean"]>;
  internetProvided?: InputMaybe<Scalars["Boolean"]>;
  setupEndTime?: InputMaybe<Scalars["String"]>;
  setupStartTime?: InputMaybe<Scalars["String"]>;
};

export type DriveEventInput = {
  activityTypes?: InputMaybe<Array<Scalars["String"]>>;
  agenda?: InputMaybe<Array<DriveEventAgendaInput>>;
  agendaConfig?: InputMaybe<Scalars["JSON"]>;
  attendees?: InputMaybe<Array<DriveEventAttendeeInput>>;
  booth?: InputMaybe<DriveEventBoothInput>;
  clinicLeaderId?: InputMaybe<Scalars["String"]>;
  description?: InputMaybe<Scalars["String"]>;
  endDate?: InputMaybe<Scalars["String"]>;
  hotelAddress?: InputMaybe<Scalars["String"]>;
  hotelName?: InputMaybe<Scalars["String"]>;
  hubspotTrackingLink?: InputMaybe<Scalars["String"]>;
  notes?: InputMaybe<Scalars["String"]>;
  planningCompleted?: InputMaybe<Scalars["Boolean"]>;
  preferedTimezone?: InputMaybe<Scalars["String"]>;
  sponsor?: InputMaybe<DriveEventSponsorInput>;
  startDate?: InputMaybe<Scalars["String"]>;
  status?: InputMaybe<DriveEventStatus>;
  tier?: InputMaybe<Scalars["Int"]>;
  title?: InputMaybe<Scalars["String"]>;
  venue?: InputMaybe<DriveEventVenueInput>;
  websiteLink?: InputMaybe<Scalars["String"]>;
};

/** Paginated list of drive events */
export type DriveEventResults = {
  __typename?: "DriveEventResults";
  events?: Maybe<Array<DriveEvent>>;
  offset?: Maybe<Scalars["Int"]>;
  totalCount?: Maybe<Scalars["Int"]>;
};

export type DriveEventSearchInput = {
  activityTypes?: InputMaybe<Array<Scalars["String"]>>;
  attendees?: InputMaybe<Array<Scalars["String"]>>;
  boothOptions?: InputMaybe<DriveEventBoothInput>;
  dateRange?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  endDate?: InputMaybe<DriveDateCompareInput>;
  eventStatus?: InputMaybe<EventDateStatus>;
  id?: InputMaybe<Scalars["ID"]>;
  sponsor?: InputMaybe<DriveEventSponsorInput>;
  startDate?: InputMaybe<DriveDateCompareInput>;
  states?: InputMaybe<Array<Scalars["String"]>>;
  statuses?: InputMaybe<Array<DriveEventStatus>>;
  tiers?: InputMaybe<Array<Scalars["Int"]>>;
  title?: InputMaybe<Scalars["String"]>;
};

export type DriveEventSponsorInput = {
  doorPrized?: InputMaybe<Scalars["Boolean"]>;
  doorPrizedRequired?: InputMaybe<Scalars["Boolean"]>;
  sponsorDescription?: InputMaybe<Scalars["String"]>;
};

export enum DriveEventStatus {
  Closed = "CLOSED",
  EmailSent = "EMAIL_SENT",
  NotAttending = "NOT_ATTENDING",
  Pending = "PENDING",
  Registered = "REGISTERED",
}

export type DriveEventVenueInput = {
  buildingOrRoom?: InputMaybe<Scalars["String"]>;
  city?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["ID"]>;
  name?: InputMaybe<Scalars["String"]>;
  state?: InputMaybe<Scalars["String"]>;
};

export type DriveFieldInput = {
  label?: InputMaybe<Scalars["String"]>;
  linkText?: InputMaybe<Scalars["String"]>;
  name: Scalars["String"];
  type: DriveFieldType;
  value: Scalars["String"];
};

export enum DriveFieldType {
  Html = "HTML",
  Link = "LINK",
  Text = "TEXT",
}

export type DriveFileInput = {
  content: Scalars["String"];
  description?: InputMaybe<Scalars["String"]>;
  name: Scalars["String"];
};

/** Organization Summary information used by Drive's Get List Application */
export type DriveGetListOrganizationSummaryReturn = {
  __typename?: "DriveGetListOrganizationSummaryReturn";
  /**
   * Entity ID of the organization. Its purpose is for caching in Apollo Client.
   * @deprecated use id
   */
  _id?: Maybe<Scalars["Int"]>;
  activitiesWithoutCampaigns?: Maybe<Scalars["Int"]>;
  averageRaisedPerCampaign?: Maybe<Scalars["Float"]>;
  campaignCompleted?: Maybe<Scalars["Int"]>;
  city?: Maybe<Scalars["String"]>;
  entityId?: Maybe<Scalars["Int"]>;
  hubspotId?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  /** Number of campaigns that completed of this Organization */
  name?: Maybe<Scalars["String"]>;
  /** Total students of the organization. Inherited from NCES data and studentCount from orgs-api. */
  population?: Maybe<Scalars["Int"]>;
  /** @deprecated use potentialRaisedDollars */
  potentialRaised?: Maybe<Scalars["Float"]>;
  /** Total potential raised in dollars for this organization. Equals NCES total students multiply with National RPK. */
  potentialRaisedDollars?: Maybe<Scalars["Float"]>;
  /** @deprecated use saturationPercentage */
  saturationPercent?: Maybe<Scalars["Float"]>;
  /** Saturation percentage of organization. It is calculated by divide total logged-in students by NCES total students of this organization. */
  saturationPercentage?: Maybe<Scalars["Float"]>;
  state?: Maybe<Scalars["String"]>;
  /** Lifetime total raised in dollars of organization. Computed from all non-upcoming campaigns. */
  totalRaised?: Maybe<Scalars["Float"]>;
  /** Lifetime total raised in cents of organization. */
  totalRaisedCents?: Maybe<Scalars["Int"]>;
  type?: Maybe<Scalars["String"]>;
};

export type DriveGetlistProspectsReturn = {
  __typename?: "DriveGetlistProspectsReturn";
  offset?: Maybe<Scalars["Int"]>;
  results?: Maybe<Array<Maybe<HubspotProspectsDeals>>>;
  totalCount?: Maybe<Scalars["Int"]>;
};

export type DriveGetlistWinbackReturn = {
  __typename?: "DriveGetlistWinbackReturn";
  offset?: Maybe<Scalars["Int"]>;
  results?: Maybe<Array<Maybe<WinbackDeals>>>;
  totalCount?: Maybe<Scalars["Int"]>;
};

/** Group Leader information in Drive */
export type DriveGroupLeader = {
  __typename?: "DriveGroupLeader";
  /** The full name of the group leader */
  name?: Maybe<Scalars["String"]>;
};

export type DriveOrgListResults = {
  __typename?: "DriveOrgListResults";
  offset?: Maybe<Scalars["Int"]>;
  orgs?: Maybe<Array<Maybe<DriveGetListOrganizationSummaryReturn>>>;
  totalCount?: Maybe<Scalars["Int"]>;
};

export type DriveOrgUserTrackingSearchInput = {
  trackingIdStartsWith?: InputMaybe<Scalars["String"]>;
  trackingType?: InputMaybe<Scalars["String"]>;
};

/** Organization used by Drive */
export type DriveOrganization = {
  __typename?: "DriveOrganization";
  activity?: Maybe<Scalars["String"]>;
  city?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["Int"]>;
  legalName?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  state?: Maybe<Scalars["String"]>;
};

/** List of organizations used by Drive */
export type DriveOrganizationList = {
  __typename?: "DriveOrganizationList";
  list: Array<Maybe<DriveOrganization>>;
  offset?: Maybe<Scalars["Int"]>;
};

/** Statistics information related to Participants in one campaign */
export type DriveParticipantStats = {
  __typename?: "DriveParticipantStats";
  /** Count of participants who had logged in */
  loggedInCount?: Maybe<Scalars["Int"]>;
  /** Count of participants with 20 emails sent */
  participantsWithAtLeastTwentyEmails?: Maybe<Scalars["Int"]>;
  /** Percentage of active participants of campaign. */
  participationPercent?: Maybe<Scalars["Int"]>;
  /** Count of participants with at least one donation */
  withAtLeastOneDonation?: Maybe<Scalars["Int"]>;
};

/** Information of a sms scheduled for release */
export type DrivePreloadSmsSchedule = {
  __typename?: "DrivePreloadSmsSchedule";
  /** The date for release. Valid format is YYYY-MM-DD */
  date?: Maybe<Scalars["String"]>;
  /** The campaign id */
  fundraiserId?: Maybe<Scalars["Int"]>;
  /** The timeframe for release. */
  timeframe?: Maybe<DriveScheduleTimeFrame>;
  /** The timezone for scheduled release */
  timezone?: Maybe<Scalars["String"]>;
};

/** Input use for create schedule for preload email */
export type DriveScheduleInput = {
  /** Date to send out. Valid format is YYYY-MM-DD */
  date: Scalars["String"];
  /** Timeframe of schedule release. */
  timeframe: DriveScheduleTimeFrame;
  /** Timezone of datetime to send out. e.g. American/New_York */
  timezone?: InputMaybe<Scalars["String"]>;
};

export enum DriveScheduleTimeFrame {
  Afternoon = "AFTERNOON",
  Evening = "EVENING",
  Morning = "MORNING",
}

export type DriveScheduleUpdateInput = {
  /** Date to send out. Valid format is YYYY-MM-DD */
  date?: InputMaybe<Scalars["String"]>;
  /** Timeframe of schedule release. */
  timeframe?: InputMaybe<DriveScheduleTimeFrame>;
  /** Timezone of datetime to send out. e.g. American/New_York */
  timezone?: InputMaybe<Scalars["String"]>;
};

export type DriveTrackingInput = {
  trackingId: Scalars["String"];
  trackingType?: InputMaybe<Scalars["String"]>;
  trackingValue: Scalars["String"];
  trackingValueType?: InputMaybe<Scalars["String"]>;
};

export type DriveUser = {
  email?: Maybe<Scalars["String"]>;
  firstName?: Maybe<Scalars["String"]>;
  lastName?: Maybe<Scalars["String"]>;
};

export type DriveUserSearchInput = {
  isInternal?: InputMaybe<Scalars["Boolean"]>;
  searchTerm?: InputMaybe<Scalars["String"]>;
  userId?: InputMaybe<Scalars["String"]>;
};

export type DriveUsersResult = {
  __typename?: "DriveUsersResult";
  nextPage?: Maybe<Scalars["Boolean"]>;
  users?: Maybe<Array<Maybe<DriveAccountUser>>>;
};

/** Venue or location for Event used by Event Clinic */
export type DriveVenue = {
  __typename?: "DriveVenue";
  /** Building name or Room number. e.g. `Room 330` */
  buildingOrRoom?: Maybe<Scalars["String"]>;
  /** City of Venue location, e.g. `Dallas` */
  city?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  /** Venue Name. e.g. `Hillton Convention Center`, e.g. */
  name: Scalars["String"];
  /** The short-code for US States. e.g. `TX`, 'CA' */
  state?: Maybe<Scalars["String"]>;
};

export type DriveVenueSearchInput = {
  id?: InputMaybe<Scalars["ID"]>;
  name?: InputMaybe<Scalars["String"]>;
  state?: InputMaybe<Scalars["String"]>;
};

export type EditSubmissionDocumentResultObject = {
  __typename?: "EditSubmissionDocumentResultObject";
  s3FileName: Scalars["String"];
};

/** Information of a emails for a camapgin */
export type EmailsInfo = {
  __typename?: "EmailsInfo";
  /** Total emails bounced back due to failed delivery */
  bounced?: Maybe<Scalars["Int"]>;
  /** Total emails loaded by particpants */
  loaded?: Maybe<Scalars["Int"]>;
  /** The schedule (timezone-sensitive) thatthe emails are scheduled to release */
  scheduledReleaseDate?: Maybe<ScheduledReleaseInfo>;
  /** Total emails sent */
  sent?: Maybe<Scalars["Int"]>;
  /** The datetime the emails were sent out */
  sentAt?: Maybe<Scalars["DateTime"]>;
};

export type Error = {
  message: Scalars["String"];
};

export type Event = {
  __typename?: "Event";
  activity?: Maybe<Scalars["String"]>;
  activityLevel?: Maybe<Scalars["String"]>;
  activityType?: Maybe<Scalars["String"]>;
  author?: Maybe<Scalars["String"]>;
  bus_fee?: Maybe<Scalars["Float"]>;
  bus_time?: Maybe<Scalars["String"]>;
  cancellation_status?: Maybe<Scalars["String"]>;
  comments?: Maybe<Scalars["String"]>;
  conference?: Maybe<Scalars["String"]>;
  conference_event_id?: Maybe<Scalars["Int"]>;
  conference_id?: Maybe<Scalars["Int"]>;
  confirmed?: Maybe<Scalars["String"]>;
  confirmedStatusBoolean?: Maybe<Scalars["Boolean"]>;
  contract?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["Date"]>;
  departure_location?: Maybe<Scalars["String"]>;
  directions?: Maybe<Scalars["String"]>;
  early_dismissal_required?: Maybe<Scalars["String"]>;
  early_dismissal_time?: Maybe<Scalars["String"]>;
  end_time?: Maybe<Scalars["String"]>;
  estimated_return_time?: Maybe<Scalars["String"]>;
  event?: Maybe<Scalars["String"]>;
  eventOfficials?: Maybe<Array<Maybe<Official>>>;
  eventTiming?: Maybe<Scalars["String"]>;
  event_date?: Maybe<Scalars["Date"]>;
  event_id: Scalars["Int"];
  exists_in_mls?: Maybe<Scalars["Int"]>;
  fee?: Maybe<Scalars["Float"]>;
  formattedEventDate?: Maybe<Scalars["String"]>;
  formattedEventDateSystem?: Maybe<Scalars["String"]>;
  formattedEventDay?: Maybe<Scalars["String"]>;
  g_s?: Maybe<Scalars["String"]>;
  gate_revenue?: Maybe<Scalars["Float"]>;
  groupval?: Maybe<Scalars["String"]>;
  headline?: Maybe<Scalars["String"]>;
  impact_event?: Maybe<Scalars["String"]>;
  isDuplicate?: Maybe<Scalars["Boolean"]>;
  lead?: Maybe<Scalars["String"]>;
  location?: Maybe<Scalars["String"]>;
  loss_points?: Maybe<Scalars["Int"]>;
  num_buses?: Maybe<Scalars["Int"]>;
  opponent?: Maybe<Scalars["String"]>;
  opponent_code?: Maybe<Scalars["String"]>;
  opponent_score?: Maybe<Scalars["String"]>;
  picture?: Maybe<Scalars["String"]>;
  place?: Maybe<Scalars["String"]>;
  prep_setup?: Maybe<Scalars["String"]>;
  promote?: Maybe<Scalars["String"]>;
  results?: Maybe<Scalars["String"]>;
  revenue?: Maybe<Scalars["Float"]>;
  rollover?: Maybe<Scalars["String"]>;
  rolloverStatusBoolean?: Maybe<Scalars["Boolean"]>;
  seasonInfo?: Maybe<Scalars["String"]>;
  seasonSportCode?: Maybe<Scalars["String"]>;
  season_team?: Maybe<Scalars["Int"]>;
  sportCode?: Maybe<Scalars["String"]>;
  sportDescription?: Maybe<Scalars["String"]>;
  sportGender?: Maybe<Scalars["String"]>;
  sportLevel?: Maybe<Scalars["String"]>;
  sportName?: Maybe<Scalars["String"]>;
  start_time?: Maybe<Scalars["String"]>;
  team_score?: Maybe<Scalars["String"]>;
  title?: Maybe<Scalars["String"]>;
  tournament?: Maybe<Scalars["String"]>;
  trans_id?: Maybe<Scalars["Int"]>;
  transportDetails?: Maybe<Scalars["String"]>;
  transport_comments?: Maybe<Scalars["String"]>;
  transportation?: Maybe<Scalars["String"]>;
  update_at?: Maybe<Scalars["Date"]>;
  vehicle_count?: Maybe<Scalars["Int"]>;
  web_dir?: Maybe<Scalars["String"]>;
  weekdayname?: Maybe<Scalars["String"]>;
  win_points?: Maybe<Scalars["Int"]>;
  years?: Maybe<Scalars["String"]>;
};

export enum EventAttendeeRequestStatus {
  NoRequest = "NO_REQUEST",
  Processed = "PROCESSED",
  Request = "REQUEST",
}

export type EventCalendarFiltersInput = {
  activity?: InputMaybe<Scalars["String"]>;
  activityName?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  endDate?: InputMaybe<Scalars["Date"]>;
  facilities?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  gender?: InputMaybe<Scalars["String"]>;
  group?: InputMaybe<Scalars["String"]>;
  homeAway?: InputMaybe<Scalars["String"]>;
  levels?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  opponents?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  startDate?: InputMaybe<Scalars["Date"]>;
  teams?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  vehicleType?: InputMaybe<Scalars["String"]>;
};

export type EventContract = {
  __typename?: "EventContract";
  comments?: Maybe<Scalars["String"]>;
  event_contract_number?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  main_event_id?: Maybe<Scalars["Int"]>;
  participant?: Maybe<Scalars["String"]>;
  signee_name?: Maybe<Scalars["String"]>;
  signees?: Maybe<Array<Maybe<ContractSignees>>>;
  years?: Maybe<Scalars["String"]>;
};

export enum EventDateStatus {
  Active = "Active",
  Closed = "Closed",
  Upcoming = "Upcoming",
}

export type EventOfficialReport = {
  __typename?: "EventOfficialReport";
  comments?: Maybe<Scalars["String"]>;
  event_id?: Maybe<Scalars["String"]>;
  facility?: Maybe<Scalars["String"]>;
  officials?: Maybe<Array<Maybe<EventOfficials>>>;
  opponent?: Maybe<Scalars["String"]>;
  team?: Maybe<Scalars["String"]>;
};

export type EventOfficialReportFilter = {
  event_date: Scalars["Date"];
};

export type EventOfficials = {
  __typename?: "EventOfficials";
  address?: Maybe<Scalars["String"]>;
  cell_phone?: Maybe<Scalars["String"]>;
  city?: Maybe<Scalars["String"]>;
  duty?: Maybe<Scalars["String"]>;
  email?: Maybe<Scalars["String"]>;
  first_name?: Maybe<Scalars["String"]>;
  home_phone?: Maybe<Scalars["String"]>;
  last_name?: Maybe<Scalars["String"]>;
  official_id?: Maybe<Scalars["String"]>;
  paid?: Maybe<Scalars["String"]>;
  pay_code?: Maybe<Scalars["String"]>;
  received?: Maybe<Scalars["String"]>;
  salary?: Maybe<Scalars["Float"]>;
  ssn?: Maybe<Scalars["String"]>;
  work_phone?: Maybe<Scalars["String"]>;
  zip?: Maybe<Scalars["String"]>;
};

export type EventParticipants = {
  __typename?: "EventParticipants";
  SchoolName?: Maybe<Scalars["String"]>;
  contract_received?: Maybe<Scalars["String"]>;
  event_id?: Maybe<Scalars["Int"]>;
  id: Scalars["Int"];
  notes?: Maybe<Scalars["String"]>;
  paid?: Maybe<Scalars["String"]>;
  school_code?: Maybe<Scalars["String"]>;
};

export type EventParticipantsInput = {
  contract_received?: InputMaybe<Scalars["String"]>;
  event_id?: InputMaybe<Scalars["Int"]>;
  id: Scalars["Int"];
  notes?: InputMaybe<Scalars["String"]>;
  paid?: InputMaybe<Scalars["String"]>;
  school_code?: InputMaybe<Scalars["String"]>;
};

export type EventPreparations = {
  __typename?: "EventPreparations";
  comments?: Maybe<Scalars["String"]>;
  event?: Maybe<Scalars["Int"]>;
  id: Scalars["Int"];
  prep?: Maybe<Scalars["String"]>;
  qty?: Maybe<Scalars["String"]>;
};

export type EventPreparationsInput = {
  comments?: InputMaybe<Scalars["String"]>;
  event?: InputMaybe<Scalars["Int"]>;
  id?: InputMaybe<Scalars["Int"]>;
  prep?: InputMaybe<Scalars["String"]>;
  qty?: InputMaybe<Scalars["String"]>;
};

export type EventTransportDetails = {
  __typename?: "EventTransportDetails";
  driver_name?: Maybe<Scalars["String"]>;
  driver_phone?: Maybe<Scalars["String"]>;
  event_date?: Maybe<Scalars["Date"]>;
  event_id?: Maybe<Scalars["Int"]>;
  id: Scalars["Int"];
  vehicle_id?: Maybe<Scalars["String"]>;
  vehicle_type?: Maybe<Scalars["String"]>;
};

export type EventTransportDetailsDeleteManyCount = {
  __typename?: "EventTransportDetailsDeleteManyCount";
  count?: Maybe<Scalars["Int"]>;
};

export type EventTransportDetailsInput = {
  driver_name?: InputMaybe<Scalars["String"]>;
  driver_phone?: InputMaybe<Scalars["String"]>;
  event_id?: InputMaybe<Scalars["Int"]>;
  id: Scalars["Int"];
  vehicle_id?: InputMaybe<Scalars["String"]>;
  vehicle_type?: InputMaybe<Scalars["String"]>;
};

export type EventsOpponent = {
  __typename?: "EventsOpponent";
  activity?: Maybe<Scalars["String"]>;
  confirmed?: Maybe<Scalars["String"]>;
  end_time?: Maybe<Scalars["String"]>;
  eventId?: Maybe<Scalars["Int"]>;
  event_date?: Maybe<Scalars["Date"]>;
  eventsOpponentId?: Maybe<Scalars["Int"]>;
  season_years?: Maybe<Scalars["String"]>;
  start_time?: Maybe<Scalars["String"]>;
};

export type ExportFile = {
  __typename?: "ExportFile";
  content?: Maybe<Scalars["String"]>;
  fileName?: Maybe<Scalars["String"]>;
};

export type ExternalBankAccount = {
  __typename?: "ExternalBankAccount";
  accountId: Scalars["String"];
  accountType?: Maybe<AccountType>;
  bankName: Scalars["String"];
  campaignId: Scalars["String"];
  last4: Scalars["String"];
};

export enum ExternalTransferDirectionEnum {
  Credit = "Credit",
  Debit = "Debit",
}

/** Owned by Vault Only used in legacy createCard */
export enum Financial_Gateway {
  Stripe = "stripe",
}

export type Facility = {
  __typename?: "Facility";
  Address1?: Maybe<Scalars["String"]>;
  Address2?: Maybe<Scalars["String"]>;
  City?: Maybe<Scalars["String"]>;
  Date?: Maybe<Scalars["Date"]>;
  Facility_id?: Maybe<Scalars["Int"]>;
  State?: Maybe<Scalars["String"]>;
  ZipCode?: Maybe<Scalars["String"]>;
  directions?: Maybe<Scalars["String"]>;
  indoor?: Maybe<Scalars["String"]>;
  is_deleted?: Maybe<Scalars["Boolean"]>;
  location_id?: Maybe<Scalars["Int"]>;
  ml_site_id?: Maybe<Scalars["Int"]>;
  ml_space?: Maybe<Scalars["String"]>;
  ml_space_id?: Maybe<Scalars["Int"]>;
  picture?: Maybe<Scalars["String"]>;
  place_name: Scalars["String"];
  show?: Maybe<Scalars["String"]>;
  specialDirections?: Maybe<Scalars["String"]>;
  web?: Maybe<Scalars["String"]>;
};

export type FacilityLocation = {
  __typename?: "FacilityLocation";
  Address1?: Maybe<Scalars["String"]>;
  Address2?: Maybe<Scalars["String"]>;
  City?: Maybe<Scalars["String"]>;
  State?: Maybe<Scalars["String"]>;
  ZipCode?: Maybe<Scalars["String"]>;
  directions?: Maybe<Scalars["String"]>;
  location_id?: Maybe<Scalars["Int"]>;
  place_name?: Maybe<Scalars["String"]>;
};

export type Family = {
  __typename?: "Family";
  children?: Maybe<Array<Maybe<User>>>;
  parents?: Maybe<Array<Maybe<User>>>;
};

export enum FinAcctStatus {
  ActionRequired = "ACTION_REQUIRED",
  Active = "ACTIVE",
  Approved = "APPROVED",
  AwaitingReapproval = "AWAITING_REAPPROVAL",
  Declined = "DECLINED",
  Pending = "PENDING",
  Terminated = "TERMINATED",
  UnderReview = "UNDER_REVIEW",
}

export enum FinAcctStripeEnv {
  Raise = "RAISE",
  Sponsor = "SPONSOR",
}

export type FinancialAccount = {
  __typename?: "FinancialAccount";
  acctId?: Maybe<Scalars["ID"]>;
  applicationId?: Maybe<Scalars["ID"]>;
  createdAt: Scalars["DateTime"];
  customerId?: Maybe<Scalars["ID"]>;
  id: Scalars["ID"];
  kybId: Scalars["ID"];
  paymentMethodId?: Maybe<Scalars["ID"]>;
  processor: Processor;
  settlementMethod?: Maybe<SettlementMethod>;
  status: FinAcctStatus;
  stripeEnv: FinAcctStripeEnv;
  updatedAt?: Maybe<Scalars["DateTime"]>;
};

export type FinancialAcctOrg = {
  __typename?: "FinancialAcctOrg";
  acctId?: Maybe<Scalars["ID"]>;
  applicationId?: Maybe<Scalars["ID"]>;
  createdAt?: Maybe<Scalars["DateTime"]>;
  customerId?: Maybe<Scalars["ID"]>;
  finAcctId?: Maybe<Scalars["ID"]>;
  kybId?: Maybe<Scalars["ID"]>;
  orgEin?: Maybe<Scalars["String"]>;
  orgId?: Maybe<Scalars["ID"]>;
  orgName?: Maybe<Scalars["String"]>;
  orgType?: Maybe<OrgType>;
  paymentMethodId?: Maybe<Scalars["ID"]>;
  processor?: Maybe<Processor>;
  status?: Maybe<FinAcctStatus>;
  stripeEnv?: Maybe<FinAcctStripeEnv>;
  updatedAt?: Maybe<Scalars["DateTime"]>;
};

export type FinancialAcctUser = {
  __typename?: "FinancialAcctUser";
  acctId?: Maybe<Scalars["ID"]>;
  applicationId?: Maybe<Scalars["ID"]>;
  authorizationCreatedAt?: Maybe<Scalars["DateTime"]>;
  customerId?: Maybe<Scalars["ID"]>;
  finAcctId?: Maybe<Scalars["ID"]>;
  isBeneficialOwner?: Maybe<Scalars["Boolean"]>;
  isContact?: Maybe<Scalars["Boolean"]>;
  isPrincipal?: Maybe<Scalars["Boolean"]>;
  isRepresentative?: Maybe<Scalars["Boolean"]>;
  kybId?: Maybe<Scalars["ID"]>;
  kycId?: Maybe<Scalars["ID"]>;
  orgId?: Maybe<Scalars["ID"]>;
  personId?: Maybe<Scalars["ID"]>;
  processor?: Maybe<Processor>;
  status?: Maybe<FinAcctStatus>;
  userId?: Maybe<Scalars["ID"]>;
};

/** Owned by Vault Duplicated by Wallet Used in legacy createCard */
export type FinancialAddress = {
  __typename?: "FinancialAddress";
  city?: Maybe<Scalars["String"]>;
  line1?: Maybe<Scalars["String"]>;
  line2?: Maybe<Scalars["String"]>;
  state?: Maybe<Scalars["String"]>;
  zip?: Maybe<Scalars["String"]>;
};

/** Owned by Vault Only used in legacy createCard */
export type FinancialAddressInput = {
  city?: InputMaybe<Scalars["String"]>;
  line1?: InputMaybe<Scalars["String"]>;
  line2?: InputMaybe<Scalars["String"]>;
  state?: InputMaybe<Scalars["String"]>;
  zip?: InputMaybe<Scalars["String"]>;
};

/** Filtering options for nullable Float type */
export type FloatNullableFilter = {
  /** Equals operation */
  equals?: InputMaybe<Scalars["Float"]>;
  /** Greater Than operation */
  gt?: InputMaybe<Scalars["Float"]>;
  /** Greater Than or Equal operation */
  gte?: InputMaybe<Scalars["Float"]>;
  /** In operation for multiple values */
  in?: InputMaybe<Array<Scalars["Float"]>>;
  /** Less Than operation */
  lt?: InputMaybe<Scalars["Float"]>;
  /** Less Than or Equal operation */
  lte?: InputMaybe<Scalars["Float"]>;
  /** Not Equal operation */
  not?: InputMaybe<Scalars["Float"]>;
  /** Not In operation for multiple values */
  notIn?: InputMaybe<Array<Scalars["Float"]>>;
};

export enum FundraiserAddressType {
  Check = "CHECK",
  Mailing = "MAILING",
  Shipping = "SHIPPING",
}

export type FundraiserApprovalSubmission = {
  __typename?: "FundraiserApprovalSubmission";
  additionalNotes?: Maybe<Scalars["String"]>;
  campaignRaiseId?: Maybe<Scalars["Int"]>;
  createdAt: Scalars["DateTime"];
  customerSupportInfo?: Maybe<Scalars["String"]>;
  duration?: Maybe<Scalars["String"]>;
  estimatedTotalRaisedCents?: Maybe<Scalars["Int"]>;
  firstName?: Maybe<Scalars["String"]>;
  formId: Scalars["Int"];
  formType?: Maybe<FundraiserApprovalSubmissionFormType>;
  fundraiserApprovalSubmissionDocs: Array<FundraiserApprovalSubmissionDocument>;
  fundraiserApprovers: Array<FundraiserApprover>;
  fundraiserName: Scalars["String"];
  fundraiserServiceWebsite?: Maybe<Scalars["String"]>;
  groupSize?: Maybe<Scalars["Int"]>;
  howThisWorks?: Maybe<Scalars["String"]>;
  id: Scalars["Int"];
  lastName?: Maybe<Scalars["String"]>;
  latestRevision: Scalars["Boolean"];
  newFundraiser: Scalars["Boolean"];
  organizationId?: Maybe<Scalars["String"]>;
  organizationName: Scalars["String"];
  previousRevision?: Maybe<Scalars["Int"]>;
  pricingAndFees?: Maybe<Scalars["String"]>;
  programId?: Maybe<Scalars["String"]>;
  proposedStartDate?: Maybe<Scalars["DateTime"]>;
  reason?: Maybe<Scalars["String"]>;
  revisionRequest?: Maybe<Scalars["String"]>;
  safety?: Maybe<Scalars["String"]>;
  status: FundraiserApprovalSubmissionStatus;
  submittedAt: Scalars["DateTime"];
  submitterId?: Maybe<Scalars["String"]>;
  updatedAt: Scalars["DateTime"];
};

export type FundraiserApprovalSubmissionDocDataInput = {
  filename: Scalars["NonEmptyString"];
  filetype: Scalars["NonEmptyString"];
  id?: InputMaybe<Scalars["Int"]>;
  location: Scalars["NonEmptyString"];
};

export type FundraiserApprovalSubmissionDocument = {
  __typename?: "FundraiserApprovalSubmissionDocument";
  createdAt?: Maybe<Scalars["DateTime"]>;
  filename?: Maybe<Scalars["NonEmptyString"]>;
  filetype?: Maybe<Scalars["NonEmptyString"]>;
  id?: Maybe<Scalars["Int"]>;
  location?: Maybe<Scalars["NonEmptyString"]>;
  s3Filename?: Maybe<Scalars["NonEmptyString"]>;
  status?: Maybe<Scalars["NonEmptyString"]>;
  updatedAt?: Maybe<Scalars["DateTime"]>;
};

export type FundraiserApprovalSubmissionDocumentInput = {
  action: FundraiserApprovalSubmissionDocumentInputAction;
  document: FundraiserApprovalSubmissionDocDataInput;
};

export enum FundraiserApprovalSubmissionDocumentInputAction {
  Add = "ADD",
  Remove = "REMOVE",
}

export enum FundraiserApprovalSubmissionFormType {
  Athon = "ATHON",
  Events = "EVENTS",
  Online = "ONLINE",
  Other = "OTHER",
  Product = "PRODUCT",
  Snap = "SNAP",
}

export type FundraiserApprovalSubmissionResult = {
  __typename?: "FundraiserApprovalSubmissionResult";
  errors: Array<Maybe<Scalars["String"]>>;
  fundraiserApprovalSubmission?: Maybe<FundraiserApprovalSubmission>;
  status: Scalars["String"];
};

export enum FundraiserApprovalSubmissionStatus {
  Approved = "APPROVED",
  Draft = "DRAFT",
  RevisionRequested = "REVISION_REQUESTED",
  Submitted = "SUBMITTED",
}

export type FundraiserApprovalSubmissionsOrgNames = {
  __typename?: "FundraiserApprovalSubmissionsOrgNames";
  fundraiserName: Scalars["String"];
  organizationName: Scalars["String"];
  programName: Scalars["String"];
};

export type FundraiserApprovalSubmissionsOrgNamesResult =
  | FundraiserApprovalSubmissionsOrgNames
  | InvalidToken;

export type FundraiserApprover = {
  __typename?: "FundraiserApprover";
  approverType?: Maybe<FundraiserApproverType>;
  createdAt?: Maybe<Scalars["DateTime"]>;
  email?: Maybe<Scalars["String"]>;
  firstName?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["Int"]>;
  lastName?: Maybe<Scalars["String"]>;
  phone?: Maybe<Scalars["String"]>;
  roleId?: Maybe<Scalars["String"]>;
  updatedAt?: Maybe<Scalars["DateTime"]>;
  userId?: Maybe<Scalars["String"]>;
};

export type FundraiserApproverDataInput = {
  approverType?: InputMaybe<FundraiserApproverType>;
  email?: InputMaybe<Scalars["String"]>;
  firstName?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["Int"]>;
  lastName?: InputMaybe<Scalars["String"]>;
  phone?: InputMaybe<Scalars["String"]>;
  roleId?: InputMaybe<Scalars["String"]>;
  userId?: InputMaybe<Scalars["String"]>;
};

export type FundraiserApproverInput = {
  action: FundraiserApproverInputAction;
  approver: FundraiserApproverDataInput;
};

export enum FundraiserApproverInputAction {
  Add = "ADD",
  Remove = "REMOVE",
  Update = "UPDATE",
}

export enum FundraiserApproverType {
  Primary = "PRIMARY",
  Secondary = "SECONDARY",
}

export type FundraiserCheerwall = {
  __typename?: "FundraiserCheerwall";
  cheers?: Maybe<Array<Maybe<Cheer>>>;
  count?: Maybe<Scalars["Int"]>;
};

export type FundraiserCustomRewardsByLevel = {
  __typename?: "FundraiserCustomRewardsByLevel";
  id?: Maybe<Scalars["Int"]>;
  name?: Maybe<Scalars["String"]>;
  sizes?: Maybe<Scalars["String"]>;
};

export type FundraiserCustomRewardsData = {
  __typename?: "FundraiserCustomRewardsData";
  rewards?: Maybe<Array<Maybe<FundraiserCustomRewardsByLevel>>>;
  rewardsLevel: Scalars["Int"];
};

export type FundraiserDataByCoachJoinCode = {
  __typename?: "FundraiserDataByCoachJoinCode";
  coachJoinCode?: Maybe<Scalars["String"]>;
  entityId?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  joinCode?: Maybe<Scalars["String"]>;
  logo?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  status?: Maybe<Scalars["String"]>;
};

export type FundraiserEntityResource = {
  __typename?: "FundraiserEntityResource";
  entityId?: Maybe<Scalars["Int"]>;
};

export type FundraiserGroup = {
  __typename?: "FundraiserGroup";
  id: Scalars["ID"];
  label?: Maybe<Scalars["String"]>;
};

export enum FundraiserMediaType {
  Image = "IMAGE",
  Vimeo = "VIMEO",
  Youtube = "YOUTUBE",
}

export type FundraiserParticipantPublic = {
  __typename?: "FundraiserParticipantPublic";
  /** Raise Participant Id */
  id: Scalars["Int"];
  participant?: Maybe<ParticipantPublic>;
};

/** @deprecated(reason: "Use participantsPublic instead") */
export type FundraiserParticipants = {
  __typename?: "FundraiserParticipants";
  id: Scalars["Int"];
  participant?: Maybe<User>;
};

export type FundraiserRaisedAmount = {
  __typename?: "FundraiserRaisedAmount";
  subtotalCents?: Maybe<Scalars["Int"]>;
};

export type FundraiserRewardLevelsCount = {
  __typename?: "FundraiserRewardLevelsCount";
  levelsCount?: Maybe<Scalars["Int"]>;
};

export type FundraiserRewardsProduct = {
  __typename?: "FundraiserRewardsProduct";
  default?: Maybe<Scalars["Boolean"]>;
  fitting?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["Int"]>;
  image?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  sizes?: Maybe<Scalars["String"]>;
  tier?: Maybe<Scalars["Int"]>;
};

export type FundraiserSetupProgress = {
  __typename?: "FundraiserSetupProgress";
  nextIncompletedSection?: Maybe<Scalars["String"]>;
  sections: Array<FundraiserSetupProgressSection>;
};

export type FundraiserSetupProgressInput = {
  currentStep: Scalars["Int"];
  id: Scalars["ID"];
  section?: InputMaybe<Scalars["String"]>;
  status: FundraiserSetupProgressStatus;
};

export type FundraiserSetupProgressSection = {
  __typename?: "FundraiserSetupProgressSection";
  currentStep: Scalars["Int"];
  id: Scalars["ID"];
  section: Scalars["String"];
  status: FundraiserSetupProgressStatus;
};

export enum FundraiserSetupProgressStatus {
  Completed = "COMPLETED",
  InProgress = "IN_PROGRESS",
  NotApplicable = "NOT_APPLICABLE",
  NotStarted = "NOT_STARTED",
}

export enum FundraiserStatus {
  Active = "ACTIVE",
  Approved = "APPROVED",
  Archive = "ARCHIVE",
  Closed = "CLOSED",
  Deleted = "DELETED",
  Final = "FINAL",
  PendingApproval = "PENDING_APPROVAL",
  Settled = "SETTLED",
}

export type FundraiserTopDonation = {
  __typename?: "FundraiserTopDonation";
  donorName?: Maybe<Scalars["String"]>;
  subtotalCents?: Maybe<Scalars["Int"]>;
};

export type FundraiserUser = {
  __typename?: "FundraiserUser";
  currentStep?: Maybe<Scalars["String"]>;
  fundraiserId?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["ID"]>;
  joinCode?: Maybe<Scalars["String"]>;
  participantGroupPersonListId?: Maybe<Scalars["String"]>;
  participantQuote?: Maybe<Scalars["String"]>;
  role?: Maybe<Scalars["String"]>;
  supporterId?: Maybe<Scalars["String"]>;
  udid?: Maybe<Scalars["udid"]>;
};

export enum FundraiserUserImportType {
  CsvImport = "csv_import",
  Rollover = "rollover",
}

export type FundraiserUserIncentiveId = {
  __typename?: "FundraiserUserIncentiveID";
  id?: Maybe<Scalars["Int"]>;
};

export type FundraiserUserRole = {
  __typename?: "FundraiserUserRole";
  isGroupLeader?: Maybe<Scalars["Boolean"]>;
  isParticipant?: Maybe<Scalars["Boolean"]>;
  roles?: Maybe<Array<Maybe<Scalars["String"]>>>;
};

export type GalleryItems = {
  __typename?: "GalleryItems";
  campaignLogo?: Maybe<Scalars["String"]>;
  campaignLogoThumb?: Maybe<Scalars["String"]>;
  carouselItems?: Maybe<Array<Maybe<CarouselItem>>>;
};

export type GetCompetitionListInput = {
  from_date?: InputMaybe<Scalars["String"]>;
  opponents?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  to_date?: InputMaybe<Scalars["String"]>;
};

export type GetDailyCalendarBusScheduleByDateInput = {
  from_date?: InputMaybe<Scalars["String"]>;
  to_date?: InputMaybe<Scalars["String"]>;
};

export type GetDailyCalendarEventsByDateInput = {
  from_date?: InputMaybe<Scalars["String"]>;
  group?: InputMaybe<Scalars["String"]>;
  to_date?: InputMaybe<Scalars["String"]>;
};

export type GetDailyCalendarOfficialsByDateInput = {
  from_date?: InputMaybe<Scalars["String"]>;
  to_date?: InputMaybe<Scalars["String"]>;
};

export type GetDailyCalendarPreparationsByDateInput = {
  from_date?: InputMaybe<Scalars["String"]>;
  to_date?: InputMaybe<Scalars["String"]>;
};

export type GetDailyCalendarWorkersByDateInput = {
  from_date?: InputMaybe<Scalars["String"]>;
  to_date?: InputMaybe<Scalars["String"]>;
};

export type GetEventByIdInput = {
  event_id: Scalars["Int"];
};

export type GetEventParticipantsByIdInput = {
  id: Scalars["Int"];
};

export type GetEventPreparationsByEventIdInput = {
  event_id: Scalars["Int"];
};

export type GetEventPreparationsByIdInput = {
  id: Scalars["Int"];
};

export type GetEventTransportDetailsByEventOrDatesInput = {
  event_date_end?: InputMaybe<Scalars["Date"]>;
  event_date_start?: InputMaybe<Scalars["Date"]>;
  event_id?: InputMaybe<Scalars["Int"]>;
};

export type GetEventsFilteredByOpponentStartAndEndDate = {
  endDate: Scalars["String"];
  opponent: Scalars["String"];
  startDate: Scalars["String"];
};

export type GetFacilityByPlaceNameInput = {
  place_name: Scalars["String"];
};

export type GetLevelByIdInput = {
  ID: Scalars["Int"];
};

export type GetOfficialAssignmentsInput = {
  from_date?: InputMaybe<Scalars["String"]>;
  gender?: InputMaybe<Scalars["String"]>;
  levels?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  official_name?: InputMaybe<Scalars["String"]>;
  teams?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  to_date?: InputMaybe<Scalars["String"]>;
};

export type GetOfficialByIdInput = {
  id: Scalars["Int"];
};

export type GetOfficialDutyByIdInput = {
  id: Scalars["Int"];
};

export type GetOfficialPoolByIdInput = {
  ID?: InputMaybe<Scalars["String"]>;
};

export type GetOpponentBySchoolCodeInput = {
  SchoolCode: Scalars["String"];
};

export type GetPreparationByIdInput = {
  id: Scalars["Int"];
};

export type GetSchoolInfoByIdInput = {
  id: Scalars["String"];
};

export type GetVehicleByIdInput = {
  id: Scalars["Int"];
};

export type GetWorkerByIdInput = {
  id: Scalars["Int"];
};

export type GetWorkerPoolByIdInput = {
  ID: Scalars["Int"];
};

export type GetWorkersByEventIdInput = {
  event_id: Scalars["Int"];
};

export type GiftShopIntentInput = {
  donateIntentId: Scalars["String"];
  fundraiserId: Scalars["Int"];
  fundraiserUserId: Scalars["Int"];
  participantUserDirectoryId: Scalars["String"];
  purchaseIntentId?: InputMaybe<Scalars["String"]>;
  status: PurchaseIntentStatus;
  userId: Scalars["Int"];
};

export type GiftShopIntentUpdateInput = {
  donorEmail?: InputMaybe<Scalars["String"]>;
  donorFirstName?: InputMaybe<Scalars["String"]>;
  donorLastName?: InputMaybe<Scalars["String"]>;
  fundraiserUserId?: InputMaybe<Scalars["Int"]>;
  participantUserDirectoryId?: InputMaybe<Scalars["String"]>;
  paymentProvider?: InputMaybe<Scalars["String"]>;
  userId?: InputMaybe<Scalars["Int"]>;
};

export type GiftShopPurchaseIntentData = {
  __typename?: "GiftShopPurchaseIntentData";
  donateIntentId?: Maybe<Scalars["String"]>;
  donorEmail?: Maybe<Scalars["String"]>;
  donorFirstName?: Maybe<Scalars["String"]>;
  donorId?: Maybe<Scalars["String"]>;
  donorLastName?: Maybe<Scalars["String"]>;
  fundraiserUserId?: Maybe<Scalars["Int"]>;
  id: Scalars["String"];
  lineItems: Array<DonorGiftShopItem>;
  participantUserDirectoryId?: Maybe<Scalars["String"]>;
  purchaseIntentId?: Maybe<Scalars["String"]>;
  raiseFundraiserId?: Maybe<Scalars["Int"]>;
  status?: Maybe<Scalars["String"]>;
  userId?: Maybe<Scalars["Int"]>;
};

export type GiftShopPurchaseIntentId = {
  __typename?: "GiftShopPurchaseIntentId";
  id: Scalars["String"];
};

export type GiftShopPurchaseItemUpdate = {
  id: Scalars["String"];
  size: Scalars["String"];
};

export type GroupLeader = {
  __typename?: "GroupLeader";
  email?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  name?: Maybe<Scalars["String"]>;
  phone?: Maybe<Scalars["String"]>;
};

/** See CampaignMembership for details */
export type GroupLeaderCampaign = {
  __typename?: "GroupLeaderCampaign";
  basicStatus: BasicCampaignStatus;
  donationLink: Scalars["String"];
  entityId?: Maybe<Scalars["Int"]>;
  goalInDollars?: Maybe<Scalars["Int"]>;
  id: Scalars["ID"];
  isCocoach: Scalars["Boolean"];
  joinCode?: Maybe<Scalars["String"]>;
  /** Getting Kyc status adds time to your query **expensive** */
  kyc?: Maybe<CampaignKyc>;
  name: Scalars["String"];
  /** Primary group leader */
  primary?: Maybe<PrimaryGroupLeader>;
  raiseUserJoinedAt?: Maybe<Scalars["String"]>;
  /** Getting roster adds time to your query */
  roster?: Maybe<Array<Maybe<Roster>>>;
  status: CampaignStatus;
  teamSize?: Maybe<Scalars["Int"]>;
  totalCentsRaised?: Maybe<Scalars["Int"]>;
  whyDonateText?: Maybe<Scalars["String"]>;
};

export type GroupLeaderDonationLevels = {
  __typename?: "GroupLeaderDonationLevels";
  amount: Scalars["Float"];
  id: Scalars["ID"];
  impact?: Maybe<Scalars["String"]>;
  rewardDescription?: Maybe<Scalars["String"]>;
};

export type GroupLeaderDonationLevelsInput = {
  id: Scalars["ID"];
  impact?: InputMaybe<Scalars["String"]>;
  rewardDescription?: InputMaybe<Scalars["String"]>;
};

export type GroupLeaderEditFundraiserInput = {
  howDonationsAreUsed?: InputMaybe<Scalars["String"]>;
  /** will always be logo.${extension}, the full url will be constructed in the resolver */
  logo?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
  personalMessage?: InputMaybe<Scalars["String"]>;
  primaryColor?: InputMaybe<Scalars["String"]>;
  secondaryColor?: InputMaybe<Scalars["String"]>;
  /** remove setup progress and calculate in the backend */
  setupCompleted?: InputMaybe<Scalars["Boolean"]>;
  whyDonations?: InputMaybe<Scalars["String"]>;
};

export type GroupLeaderFundraiser = {
  __typename?: "GroupLeaderFundraiser";
  activity?: Maybe<Scalars["String"]>;
  activityTier?: Maybe<Scalars["String"]>;
  donationLevels?: Maybe<Array<GroupLeaderDonationLevels>>;
  endDate?: Maybe<Scalars["Date"]>;
  goal?: Maybe<Scalars["Float"]>;
  howDonationsAreUsed?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["ID"]>;
  logo?: Maybe<Scalars["String"]>;
  mediaGallery?: Maybe<Array<GroupLeaderMediaGallery>>;
  name?: Maybe<Scalars["String"]>;
  orgId?: Maybe<Scalars["String"]>;
  participantsCount?: Maybe<Scalars["Int"]>;
  personalMessage?: Maybe<Scalars["String"]>;
  primaryColor?: Maybe<Scalars["String"]>;
  secondaryColor?: Maybe<Scalars["String"]>;
  settlementDate?: Maybe<Scalars["Date"]>;
  setupProgress?: Maybe<FundraiserSetupProgress>;
  shippingAddress?: Maybe<GroupLeaderFundraiserAddress>;
  slug?: Maybe<Scalars["String"]>;
  startDate?: Maybe<Scalars["Date"]>;
  status?: Maybe<FundraiserStatus>;
  whyDonations?: Maybe<Scalars["String"]>;
};

export type GroupLeaderFundraiserAddress = {
  __typename?: "GroupLeaderFundraiserAddress";
  allowUpdate?: Maybe<Scalars["Boolean"]>;
  city?: Maybe<Scalars["String"]>;
  country?: Maybe<Scalars["String"]>;
  fundraiserId?: Maybe<Scalars["ID"]>;
  id?: Maybe<Scalars["ID"]>;
  region?: Maybe<Scalars["String"]>;
  streetLine1?: Maybe<Scalars["String"]>;
  streetLine2?: Maybe<Scalars["String"]>;
  zip?: Maybe<Scalars["String"]>;
};

export type GroupLeaderFundraiserAddressInput = {
  city: Scalars["String"];
  country: Scalars["String"];
  id?: InputMaybe<Scalars["ID"]>;
  region: Scalars["String"];
  streetLine1: Scalars["String"];
  streetLine2?: InputMaybe<Scalars["String"]>;
  zip: Scalars["String"];
};

export type GroupLeaderFundraiserMediaGalleryUpdateInput = {
  add?: InputMaybe<Array<GroupLeaderMediaGalleryInput>>;
  remove?: InputMaybe<Array<Scalars["ID"]>>;
  update?: InputMaybe<GroupLeaderMediaGalleryUpdateInput>;
};

export type GroupLeaderFundraisersPaginated = {
  __typename?: "GroupLeaderFundraisersPaginated";
  fundraisers: Array<Maybe<GroupLeaderFundraiser>>;
  pagination?: Maybe<GroupLeadersPagination>;
};

export type GroupLeaderList = {
  __typename?: "GroupLeaderList";
  count?: Maybe<Scalars["Int"]>;
  cursor?: Maybe<Scalars["String"]>;
  list: Array<Maybe<GroupLeader>>;
};

export type GroupLeaderMediaGallery = {
  __typename?: "GroupLeaderMediaGallery";
  fundraiserId: Scalars["String"];
  id: Scalars["String"];
  image?: Maybe<Scalars["String"]>;
  mediaType?: Maybe<FundraiserMediaType>;
  position?: Maybe<Scalars["Int"]>;
  url?: Maybe<Scalars["String"]>;
};

export type GroupLeaderMediaGalleryInput = {
  fundraiserId: Scalars["ID"];
  id?: InputMaybe<Scalars["ID"]>;
  image?: InputMaybe<Scalars["String"]>;
  mediaType: FundraiserMediaType;
  position?: InputMaybe<Scalars["Int"]>;
  url?: InputMaybe<Scalars["String"]>;
};

export type GroupLeaderMediaGalleryUpdateInput = {
  items?: InputMaybe<Array<GroupLeaderMediaGalleryInput>>;
  setupCompleted?: InputMaybe<Scalars["Boolean"]>;
};

export enum GroupLeadersFundraiserUserRole {
  GroupLeader = "GROUP_LEADER",
  Participant = "PARTICIPANT",
}

export type GroupLeadersPagination = {
  __typename?: "GroupLeadersPagination";
  currentPage?: Maybe<Scalars["Int"]>;
  totalCount?: Maybe<Scalars["Int"]>;
  totalPages?: Maybe<Scalars["Int"]>;
};

export enum GroupRosterFilterEnum {
  GroupId = "groupId",
  Id = "id",
  MemberId = "memberId",
  RosterId = "rosterId",
  SeasonId = "seasonId",
}

export type GroupsOverviewDashboardResponse = {
  __typename?: "GroupsOverviewDashboardResponse";
  count: Scalars["Int"];
  overview?: Maybe<Array<Maybe<SpendGroupsOverview>>>;
};

export type HelpDocumentDeleteInput = {
  key: Scalars["String"];
};

export type HelpDocumentUploadInput = {
  base64File: Scalars["String"];
};

export type HmAccountRole = {
  __typename?: "HmAccountRole";
  account_id?: Maybe<Scalars["Int"]>;
  id: Scalars["String"];
  role_id?: Maybe<Scalars["Int"]>;
};

export type HmGraphSales = {
  __typename?: "HmGraphSales";
  soldDuringWeek?: Maybe<Scalars["Int"]>;
  weekName?: Maybe<Scalars["String"]>;
};

export type HmSalesRep = {
  __typename?: "HmSalesRep";
  rep_email?: Maybe<Scalars["String"]>;
  sales_rep?: Maybe<Scalars["String"]>;
};

export type HmSpendAccount = {
  __typename?: "HmSpendAccount";
  id?: Maybe<Scalars["Int"]>;
  orgTeamMembers?: Maybe<Array<Maybe<Scalars["String"]>>>;
};

export type HmSpendData = {
  __typename?: "HmSpendData";
  credited?: Maybe<Scalars["Int"]>;
  inactiveCards?: Maybe<Scalars["Int"]>;
  paid?: Maybe<Scalars["Int"]>;
  pastDue?: Maybe<Scalars["Int"]>;
  pending?: Maybe<Scalars["Int"]>;
  upcoming?: Maybe<Scalars["Int"]>;
};

export type HmSponsorData = {
  __typename?: "HmSponsorData";
  activeSponsorships?: Maybe<Scalars["Int"]>;
  assetCount?: Maybe<Scalars["Int"]>;
  available?: Maybe<Scalars["Int"]>;
  sponsorShipValue?: Maybe<Scalars["Int"]>;
  totalAssets?: Maybe<Scalars["Int"]>;
};

export type HmStore = {
  __typename?: "HmStore";
  active_stores?: Maybe<Scalars["String"]>;
  monthly_sales?: Maybe<Array<Maybe<HmGraphSales>>>;
  total_orders?: Maybe<Scalars["String"]>;
  total_points?: Maybe<Scalars["String"]>;
  total_sales?: Maybe<Scalars["String"]>;
};

export type HmUser = {
  __typename?: "HmUser";
  email?: Maybe<Scalars["String"]>;
  id: Scalars["Int"];
  name?: Maybe<Scalars["String"]>;
};

/** Hubspot Engagement entity */
export type HubspotCallEngagement = {
  __typename?: "HubspotCallEngagement";
  contactIds?: Maybe<Array<Maybe<Scalars["String"]>>>;
  dealIds?: Maybe<Array<Maybe<Scalars["String"]>>>;
  engagementId?: Maybe<Scalars["String"]>;
  ownerId?: Maybe<Scalars["String"]>;
  type?: Maybe<Scalars["String"]>;
};

export type HubspotProspectsDeals = {
  __typename?: "HubspotProspectsDeals";
  activity?: Maybe<Scalars["String"]>;
  dealName?: Maybe<Scalars["String"]>;
  dealStage?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  isProspectPinned?: Maybe<Scalars["Boolean"]>;
  lastActivityDate?: Maybe<Scalars["String"]>;
  leaderFirstName?: Maybe<Scalars["String"]>;
  leaderLastName?: Maybe<Scalars["String"]>;
  projectedLaunchDate?: Maybe<Scalars["String"]>;
};

export enum HubspotSortDirection {
  Ascending = "ASCENDING",
  Descending = "DESCENDING",
}

/** Common payload for mutations. */
export type IMutationResult = {
  __typename?: "IMutationResult";
  data?: Maybe<Scalars["JSON"]>;
  message?: Maybe<Scalars["String"]>;
};

export type ImageInput = {
  url: Scalars["String"];
};

export type InsAddPreApprovedContactApproverInput = {
  email?: InputMaybe<Scalars["String"]>;
  firstName?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["String"]>;
  lastName?: InputMaybe<Scalars["String"]>;
  phone?: InputMaybe<Scalars["String"]>;
};

export type InsAddPreApprovedContactOrgInput = {
  id?: InputMaybe<Scalars["String"]>;
  modId?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
  raiseId?: InputMaybe<Scalars["Int"]>;
  zipcode?: InputMaybe<Scalars["String"]>;
};

export type InsAddPreApprovedContactsResult = {
  __typename?: "InsAddPreApprovedContactsResult";
  contactsResult?: Maybe<Array<Maybe<InsPreApprovedContactResult>>>;
  errors?: Maybe<Array<Maybe<Scalars["String"]>>>;
  status: Scalars["String"];
};

export type InsAnalyticsSummaryStat = {
  __typename?: "InsAnalyticsSummaryStat";
  amount_raised_cents: Scalars["Int"];
  campaign_id: Scalars["Int"];
  month: Scalars["Int"];
  year: Scalars["Int"];
};

export type InsCampaignDonation = {
  __typename?: "InsCampaignDonation";
  amount_cents?: Maybe<Scalars["Int"]>;
  campaign_id: Scalars["Int"];
  campaign_name?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["DateTime"]>;
  donor_name?: Maybe<Scalars["String"]>;
  id: Scalars["Int"];
  participant_name?: Maybe<Scalars["String"]>;
  slug: Scalars["String"];
};

export type InsCampaignRaiseEntityInfo = {
  __typename?: "InsCampaignRaiseEntityInfo";
  campaign_id: Scalars["Int"];
  entity_id?: Maybe<Scalars["Int"]>;
};

export type InsCampaignSettlement = {
  __typename?: "InsCampaignSettlement";
  amount_cents?: Maybe<Scalars["Int"]>;
  campaign_id: Scalars["Int"];
  campaign_name?: Maybe<Scalars["String"]>;
  id: Scalars["Int"];
  last_updated?: Maybe<Scalars["DateTime"]>;
  method?: Maybe<Scalars["String"]>;
  slug: Scalars["String"];
  status?: Maybe<Scalars["String"]>;
};

export type InsCampaignStat = {
  __typename?: "InsCampaignStat";
  donations_count?: Maybe<Scalars["Int"]>;
  end_date?: Maybe<Scalars["DateTime"]>;
  forecasted_amount_cents?: Maybe<Scalars["Int"]>;
  group_leader_email?: Maybe<Scalars["String"]>;
  group_leader_name?: Maybe<Scalars["String"]>;
  id: Scalars["Int"];
  insights_status?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  participants?: Maybe<Scalars["Int"]>;
  participation?: Maybe<Scalars["Float"]>;
  preloading?: Maybe<Scalars["Float"]>;
  slug: Scalars["String"];
  start_date?: Maybe<Scalars["DateTime"]>;
  total_raised_cents?: Maybe<Scalars["Int"]>;
};

export type InsCampaignsData = {
  __typename?: "InsCampaignsData";
  analyticsSummaryStats?: Maybe<Array<Maybe<InsAnalyticsSummaryStat>>>;
  campaignStats?: Maybe<Array<Maybe<InsCampaignStat>>>;
  donationMapStats?: Maybe<Array<Maybe<InsDonationMapStat>>>;
  inviteStats?: Maybe<Array<Maybe<InsInviteStat>>>;
  lTRChart?: Maybe<InsLtrChartData>;
  monthlyCampaignStats?: Maybe<Array<Maybe<InsMonthlyCampaignStat>>>;
};

export type InsDeletePreApprovedContactResult = {
  __typename?: "InsDeletePreApprovedContactResult";
  errors?: Maybe<Array<Maybe<Scalars["String"]>>>;
  status: Scalars["String"];
};

export type InsDonationMapStat = {
  __typename?: "InsDonationMapStat";
  campaign_id?: Maybe<Scalars["Int"]>;
  campaign_ids?: Maybe<Array<Maybe<Scalars["Int"]>>>;
  donations_amount_cents?: Maybe<Scalars["Int"]>;
  donations_count?: Maybe<Scalars["Int"]>;
  lat?: Maybe<Scalars["Float"]>;
  long?: Maybe<Scalars["Float"]>;
  postal_code?: Maybe<Scalars["String"]>;
};

export type InsDonorParticipantContact = {
  __typename?: "InsDonorParticipantContact";
  campaign_id: Scalars["Int"];
  donor_email?: Maybe<Scalars["String"]>;
  donor_id?: Maybe<Scalars["Int"]>;
  donor_name?: Maybe<Scalars["String"]>;
  participant_email?: Maybe<Scalars["String"]>;
  participant_id?: Maybe<Scalars["Int"]>;
  participant_name?: Maybe<Scalars["String"]>;
};

export type InsEditPreApprovedContactInput = {
  activity?: InputMaybe<Scalars["String"]>;
  email?: InputMaybe<Scalars["String"]>;
  firstName?: InputMaybe<Scalars["String"]>;
  lastName?: InputMaybe<Scalars["String"]>;
  phoneNumber?: InputMaybe<Scalars["String"]>;
};

export type InsEditPreApprovedContactResult = {
  __typename?: "InsEditPreApprovedContactResult";
  error?: Maybe<Scalars["String"]>;
  status: Scalars["String"];
};

export type InsEditPreApprovedInvite = {
  __typename?: "InsEditPreApprovedInvite";
  activity: Scalars["String"];
  createdBy: Scalars["String"];
  email: Scalars["String"];
  firstName: Scalars["String"];
  inviteStatus: Scalars["String"];
  lastName: Scalars["String"];
  orgName: Scalars["String"];
  phoneNumber?: Maybe<Scalars["String"]>;
};

export type InsEditPreApprovedInviteResult = {
  __typename?: "InsEditPreApprovedInviteResult";
  errors?: Maybe<Array<Maybe<Scalars["String"]>>>;
  status: Scalars["String"];
  updatedInvite?: Maybe<InsEditPreApprovedInvite>;
};

export type InsEmailTestPreApprovedContactResult = {
  __typename?: "InsEmailTestPreApprovedContactResult";
  email: Scalars["String"];
  status: Scalars["String"];
};

export type InsInviteStat = {
  __typename?: "InsInviteStat";
  campaign_id?: Maybe<Scalars["Int"]>;
  campaign_ids?: Maybe<Array<Maybe<Scalars["Int"]>>>;
  invite_count: Scalars["Int"];
  invite_type: Scalars["String"];
  total_amount_cents: Scalars["Int"];
};

export type InsLtrChartData = {
  __typename?: "InsLTRChartData";
  activeCampaignsCount?: Maybe<Scalars["Int"]>;
  activeCampaignsParticipation?: Maybe<Scalars["Float"]>;
  activeCampaignsTotalCents?: Maybe<Scalars["Int"]>;
  avgDonationCents?: Maybe<Scalars["Int"]>;
  closedCampaignsCount?: Maybe<Scalars["Int"]>;
  closedCampaignsParticipation?: Maybe<Scalars["Float"]>;
  closedCampaignsTotalCents?: Maybe<Scalars["Int"]>;
  ltr?: Maybe<Scalars["Int"]>;
  upcomingCampaignsCount?: Maybe<Scalars["Int"]>;
  upcomingCampaignsForecastedAmountCents?: Maybe<Scalars["Int"]>;
  upcomingCampaignsParticipantSignIn?: Maybe<Scalars["Float"]>;
};

export type InsMonthlyCampaignStat = {
  __typename?: "InsMonthlyCampaignStat";
  campaign_ids: Array<Maybe<Scalars["Int"]>>;
  month: Scalars["Int"];
  participation?: Maybe<Scalars["Float"]>;
  preloading?: Maybe<Scalars["Float"]>;
  year: Scalars["Int"];
};

export type InsOrg = {
  __typename?: "InsOrg";
  id: Scalars["Int"];
  name?: Maybe<Scalars["String"]>;
};

export type InsOrgCampaignSummary = {
  __typename?: "InsOrgCampaignSummary";
  campaignsCount: Scalars["Int"];
  orgId?: Maybe<Scalars["String"]>;
  totalRaisedCents: Scalars["Int"];
};

export type InsOrgCampaignSummaryInput = {
  campaignIds?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  orgId?: InputMaybe<Scalars["String"]>;
};

export type InsPreApprovedContact = {
  __typename?: "InsPreApprovedContact";
  activity?: Maybe<Scalars["String"]>;
  associated_org_id?: Maybe<Scalars["String"]>;
  associated_org_name?: Maybe<Scalars["String"]>;
  email?: Maybe<Scalars["String"]>;
  first_name?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["Int"]>;
  invite_phone_number?: Maybe<Scalars["String"]>;
  invite_sent_at?: Maybe<Scalars["DateTime"]>;
  invite_status?: Maybe<Scalars["String"]>;
  last_name?: Maybe<Scalars["String"]>;
};

export type InsPreApprovedContactInput = {
  activity: Scalars["String"];
  email: Scalars["String"];
  firstName: Scalars["String"];
  lastName: Scalars["String"];
  phoneNumber?: InputMaybe<Scalars["String"]>;
};

export type InsPreApprovedContactResult = {
  __typename?: "InsPreApprovedContactResult";
  email: Scalars["String"];
  status: Scalars["String"];
};

export type InsResendPreApprovedContactResult = {
  __typename?: "InsResendPreApprovedContactResult";
  errors?: Maybe<Array<Maybe<Scalars["String"]>>>;
  status: Scalars["String"];
};

export type InsSalesRepInfo = {
  __typename?: "InsSalesRepInfo";
  email: Scalars["String"];
  featured_image?: Maybe<Scalars["String"]>;
  hubspot_calendar_link?: Maybe<Scalars["String"]>;
  phone_number?: Maybe<Scalars["String"]>;
  post_title?: Maybe<Scalars["String"]>;
};

export enum InsSetNotificationPreferenceFlag {
  Off = "OFF",
  On = "ON",
}

export type InsSetNotificationPreferences = {
  notificationIds?: InputMaybe<Array<InputMaybe<InsUserNotificationType>>>;
  organizationIds?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  setTo: InsSetNotificationPreferenceFlag;
  type: InsSetNotificationType;
};

export type InsSetNotificationPreferencesResult = {
  __typename?: "InsSetNotificationPreferencesResult";
  errors?: Maybe<Array<Maybe<Scalars["String"]>>>;
  messages?: Maybe<Array<Maybe<Scalars["String"]>>>;
  status: Scalars["String"];
};

export enum InsSetNotificationType {
  Organization = "ORGANIZATION",
  User = "USER",
}

export type InsSetUserPreferencesResult = {
  __typename?: "InsSetUserPreferencesResult";
  errors?: Maybe<Array<Maybe<Scalars["String"]>>>;
  messages?: Maybe<Array<Maybe<Scalars["String"]>>>;
  status: Scalars["String"];
};

export type InsUser = {
  __typename?: "InsUser";
  id: Scalars["Int"];
};

export enum InsUserNotificationType {
  CheckSent = "CHECK_SENT",
  DepositMade = "DEPOSIT_MADE",
  FundraiserCreated = "FUNDRAISER_CREATED",
  FundraiserFinalized = "FUNDRAISER_FINALIZED",
}

export type InsightsGetUserPreference = {
  __typename?: "InsightsGetUserPreference";
  campaign_raise_id?: Maybe<Scalars["Int"]>;
  id: Scalars["Int"];
  org_id?: Maybe<Scalars["String"]>;
  user_id?: Maybe<Scalars["String"]>;
};

/** Filtering options for Int type */
export type IntFilter = {
  /** Equals operation */
  equals?: InputMaybe<Scalars["Int"]>;
  /** Greater Than operation */
  gt?: InputMaybe<Scalars["Int"]>;
  /** Greater Than or Equal operation */
  gte?: InputMaybe<Scalars["Int"]>;
  /** In operation for multiple values */
  in?: InputMaybe<Array<Scalars["Int"]>>;
  /** Less Than operation */
  lt?: InputMaybe<Scalars["Int"]>;
  /** Less Than or Equal operation */
  lte?: InputMaybe<Scalars["Int"]>;
  /** Not Equal operation */
  not?: InputMaybe<Scalars["Int"]>;
  /** Not In operation for multiple values */
  notIn?: InputMaybe<Array<Scalars["Int"]>>;
};

/** Filtering options for nullable Int type */
export type IntNullableFilter = {
  /** Equals operation */
  equals?: InputMaybe<Scalars["Int"]>;
  /** Greater Than operation */
  gt?: InputMaybe<Scalars["Int"]>;
  /** Greater Than or Equal operation */
  gte?: InputMaybe<Scalars["Int"]>;
  /** In operation for multiple values */
  in?: InputMaybe<Array<Scalars["Int"]>>;
  /** Less Than operation */
  lt?: InputMaybe<Scalars["Int"]>;
  /** Less Than or Equal operation */
  lte?: InputMaybe<Scalars["Int"]>;
  /** Not Equal operation */
  not?: InputMaybe<Scalars["Int"]>;
  /** Not In operation for multiple values */
  notIn?: InputMaybe<Array<Scalars["Int"]>>;
};

export type InvalidCampaignPersonListData = {
  __typename?: "InvalidCampaignPersonListData";
  email: Scalars["String"];
  errorMessage?: Maybe<Scalars["String"]>;
};

export type InvalidCampaignSmsInviteData = {
  __typename?: "InvalidCampaignSmsInviteData";
  errorMessage: Scalars["String"];
  phoneNumber?: Maybe<Scalars["String"]>;
};

export type InvalidToken = Error & {
  __typename?: "InvalidToken";
  message: Scalars["String"];
};

export type InviteError = Error & {
  __typename?: "InviteError";
  message: Scalars["String"];
};

export enum InviteFilterEnum {
  Group = "group",
  Program = "program",
  Status = "status",
  Type = "type",
}

export type InviteInfo = {
  __typename?: "InviteInfo";
  email?: Maybe<Scalars["String"]>;
  flow?: Maybe<Scalars["String"]>;
  flowParams?: Maybe<Scalars["JSONObject"]>;
  joincode?: Maybe<Scalars["String"]>;
  params?: Maybe<UserInviteParams>;
  phoneNumber?: Maybe<Scalars["String"]>;
  requester?: Maybe<User>;
  status?: Maybe<Scalars["String"]>;
  type?: Maybe<InviteType>;
};

export type InviteList = {
  __typename?: "InviteList";
  acceptedAt?: Maybe<Scalars["String"]>;
  createdAt?: Maybe<Scalars["String"]>;
  email?: Maybe<Scalars["String"]>;
  expiresAt?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  joincode?: Maybe<Scalars["String"]>;
  params?: Maybe<UserInviteParams>;
  phoneNumber?: Maybe<Scalars["String"]>;
  requester?: Maybe<User>;
  status?: Maybe<UserInviteStatus>;
  type?: Maybe<InviteType>;
};

export type InviteParentArguments = {
  joincode?: InputMaybe<Scalars["String"]>;
};

export type InviteSendResult = InviteError | InviteInfo;

export enum InviteType {
  Org = "org",
  Parent = "parent",
}

export type InviteUserArguments = {
  apps?: InputMaybe<Array<InputMaybe<UserApps>>>;
  consumer?: InputMaybe<UserApps>;
  fundraiserId?: InputMaybe<Scalars["Int"]>;
  occupation?: InputMaybe<UserOccupation>;
  orgsAffiliation?: InputMaybe<Array<UserOrgAffiliationPayload>>;
  permissionIds?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  phoneNumber?: InputMaybe<Scalars["String"]>;
  redirectUrl?: InputMaybe<Scalars["String"]>;
  roleIds?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export type InviteUserOrgsArguments = {
  orgId?: InputMaybe<Scalars["String"]>;
  product?: InputMaybe<UserApps>;
  roleId?: InputMaybe<Scalars["String"]>;
};

export type InvitesListResponse = {
  __typename?: "InvitesListResponse";
  invites?: Maybe<Array<InviteList>>;
  pagination: Pagination;
};

export enum InvoiceFilterEnum {
  Date = "date",
  GroupId = "groupId",
  GroupName = "groupName",
  GroupRosterId = "groupRosterId",
  InviteId = "inviteId",
  PlayerName = "playerName",
  SeasonId = "seasonId",
  Status = "status",
  Unreconciled = "unreconciled",
}

export enum InvoiceGuardianFilterEnum {
  GroupId = "groupId",
  RosterId = "rosterId",
}

export enum InvoiceReportFilterEnum {
  DateAfter = "dateAfter",
  DateBefore = "dateBefore",
  ExcludeArchived = "excludeArchived",
  GroupId = "groupId",
  GroupRosterId = "groupRosterId",
  InviteId = "inviteId",
  OrgId = "orgId",
  PastDueRange = "pastDueRange",
  PlayerName = "playerName",
  Status = "status",
}

/** Owned by Vault Only used in legacy createCard */
export type IssueCardInput = {
  altName?: InputMaybe<Scalars["String"]>;
  cardType: Card_Type;
  metadata?: InputMaybe<VaultCardMetadataInput>;
  recipientName?: InputMaybe<Scalars["String"]>;
  shippingAddress?: InputMaybe<FinancialAddressInput>;
  shippingService?: InputMaybe<Shipping_Service>;
  spendingLimitAmount: Scalars["Int"];
  spendingLimitInterval: Spending_Limit_Interval;
};

export enum JoinCodeType {
  GroupLeader = "GROUP_LEADER",
  Invalid = "INVALID",
  Participant = "PARTICIPANT",
}

export type JoinFundraiserUserInput = {
  email: Scalars["String"];
  firstName: Scalars["String"];
  lastName: Scalars["String"];
  phoneNumber?: InputMaybe<Scalars["String"]>;
};

export enum JoinType {
  Fanx = "FANX",
  Sma = "SMA",
}

export type JoinedFundraiserId = {
  __typename?: "JoinedFundraiserID";
  /** Returns the ID of the fundraiser */
  id?: Maybe<Scalars["Int"]>;
};

export enum Kyb_Structure {
  FreeZoneEstablishment = "free_zone_establishment",
  FreeZoneLlc = "free_zone_llc",
  GovernmentInstrumentality = "government_instrumentality",
  GovernmentalUnit = "governmental_unit",
  IncorporatedNonProfit = "incorporated_non_profit",
  LimitedLiabilityPartnership = "limited_liability_partnership",
  Llc = "llc",
  MultiMemberLlc = "multi_member_llc",
  PrivateCompany = "private_company",
  PrivateCorporation = "private_corporation",
  PrivatePartnership = "private_partnership",
  PublicCompany = "public_company",
  PublicCorporation = "public_corporation",
  PublicPartnership = "public_partnership",
  SingleMemberLlc = "single_member_llc",
  SoleEstablishment = "sole_establishment",
  SoleProprietorship = "sole_proprietorship",
  TaxExemptGovernmentInstrumentality = "tax_exempt_government_instrumentality",
  UnincorporatedAssociation = "unincorporated_association",
  UnincorporatedNonProfit = "unincorporated_non_profit",
}

export enum Kyb_Type {
  Company = "company",
  GovernmentEntity = "government_entity",
  Individual = "individual",
  NonProfit = "non_profit",
}

export type Leader = {
  __typename?: "Leader";
  createdAt: Scalars["DateTime"];
  isConfirmed?: Maybe<Scalars["Boolean"]>;
  title: TeamTitle;
  updatedAt?: Maybe<Scalars["DateTime"]>;
};

export type LegacyTransactionsInput = {
  accountId?: InputMaybe<Scalars["String"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  status?: InputMaybe<Scalars["String"]>;
};

export type LegacyTransactionsOutput = {
  __typename?: "LegacyTransactionsOutput";
  count: Scalars["Int"];
  transactions: Array<Maybe<Transaction>>;
};

export type Level = {
  __typename?: "Level";
  ID: Scalars["Int"];
  Level?: Maybe<Scalars["String"]>;
  is_deleted?: Maybe<Scalars["Boolean"]>;
};

export enum LogoPosition {
  FrontCenter = "FRONT_CENTER",
  FrontLeftArm = "FRONT_LEFT_ARM",
  FrontLeftChest = "FRONT_LEFT_CHEST",
  FrontLeftLeg = "FRONT_LEFT_LEG",
  FrontRightArm = "FRONT_RIGHT_ARM",
  FrontRightChest = "FRONT_RIGHT_CHEST",
  FrontRightLeg = "FRONT_RIGHT_LEG",
}

export type MdmDetails = DriveUser & {
  __typename?: "MDMDetails";
  email?: Maybe<Scalars["String"]>;
  firstName?: Maybe<Scalars["String"]>;
  lastName?: Maybe<Scalars["String"]>;
  phone?: Maybe<Scalars["String"]>;
};

export enum MacroServiceRequestWorkflowState {
  Approved = "approved",
  Canceled = "canceled",
  Closed = "closed",
  Completed = "completed",
  Open = "open",
  Rejected = "rejected",
}

export type MagentoOrder = {
  __typename?: "MagentoOrder";
  baseDiscountAmount?: Maybe<Scalars["Float"]>;
  baseGrandTotal?: Maybe<Scalars["Float"]>;
  baseShippingAmount?: Maybe<Scalars["Float"]>;
  baseSubtotal?: Maybe<Scalars["Float"]>;
  baseTaxAmount?: Maybe<Scalars["Float"]>;
  createdAt?: Maybe<Scalars["ID"]>;
  customerEmail?: Maybe<Scalars["String"]>;
  customerFirstName?: Maybe<Scalars["String"]>;
  customerLastName?: Maybe<Scalars["String"]>;
  discountAmount?: Maybe<Scalars["Float"]>;
  grandTotal?: Maybe<Scalars["Float"]>;
  incrementId?: Maybe<Scalars["String"]>;
  items?: Maybe<Array<Maybe<OrderItem>>>;
  mageworxRewardPointsAmount?: Maybe<Scalars["Float"]>;
  orderId?: Maybe<Scalars["BigInt"]>;
  scopeId?: Maybe<Scalars["BigInt"]>;
  shippingAmount?: Maybe<Scalars["Float"]>;
  state?: Maybe<Scalars["String"]>;
  status?: Maybe<Scalars["String"]>;
  storeDashbordStatus?: Maybe<Scalars["String"]>;
  subtotal?: Maybe<Scalars["Float"]>;
  taxAmount?: Maybe<Scalars["Float"]>;
  totalPaid?: Maybe<Scalars["Float"]>;
  updatedAt?: Maybe<Scalars["String"]>;
};

export type MagentoOrderUnionType = MagentoOrders | OnException;

export type MagentoOrders = {
  __typename?: "MagentoOrders";
  orders?: Maybe<Array<Maybe<MagentoOrder>>>;
};

export type MagentoPointActivities = {
  __typename?: "MagentoPointActivities";
  activities?: Maybe<Array<Maybe<MagentoPointActivity>>>;
};

export type MagentoPointActivity = {
  __typename?: "MagentoPointActivity";
  comment?: Maybe<Scalars["String"]>;
  createdAt?: Maybe<Scalars["String"]>;
  customerId?: Maybe<Scalars["BigInt"]>;
  customerName?: Maybe<Scalars["String"]>;
  expirationDate?: Maybe<Scalars["String"]>;
  orderIncrementId?: Maybe<Scalars["String"]>;
  pointsBalance?: Maybe<Scalars["Float"]>;
  pointsDelta?: Maybe<Scalars["Float"]>;
  receiver?: Maybe<MagentoPointReceiver>;
  scopeId?: Maybe<Scalars["BigInt"]>;
  sender?: Maybe<MagentoPointSender>;
  status?: Maybe<Scalars["String"]>;
  storeCode?: Maybe<Scalars["String"]>;
  storeName?: Maybe<Scalars["String"]>;
  teamName?: Maybe<Scalars["String"]>;
  transactionId?: Maybe<Scalars["BigInt"]>;
  transactionType?: Maybe<Scalars["String"]>;
};

export type MagentoPointActivityUnionType =
  | MagentoPointActivities
  | OnException;

export type MagentoPointReceiver = {
  __typename?: "MagentoPointReceiver";
  email?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  receiverId?: Maybe<Scalars["Int"]>;
};

export type MagentoPointSender = {
  __typename?: "MagentoPointSender";
  email?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  senderId?: Maybe<Scalars["Int"]>;
};

export type MagentoStore = {
  __typename?: "MagentoStore";
  accountManagerId?: Maybe<Scalars["String"]>;
  activityType?: Maybe<Scalars["String"]>;
  baseTotalSales?: Maybe<Scalars["Float"]>;
  brands?: Maybe<Array<Maybe<Brand>>>;
  campaignId?: Maybe<Scalars["Int"]>;
  city?: Maybe<Scalars["String"]>;
  code?: Maybe<Scalars["String"]>;
  digitalLogo?: Maybe<Scalars["String"]>;
  domain?: Maybe<Scalars["String"]>;
  earnedPoints?: Maybe<Scalars["Float"]>;
  earnedPointsBalance?: Maybe<Scalars["Float"]>;
  earnedSharedPoints?: Maybe<Scalars["Float"]>;
  email?: Maybe<Scalars["String"]>;
  embroideryLogo?: Maybe<Scalars["String"]>;
  enabled?: Maybe<Scalars["Int"]>;
  entityId?: Maybe<Scalars["BigInt"]>;
  expiringPoints?: Maybe<Scalars["Float"]>;
  favicon?: Maybe<Scalars["String"]>;
  givenPoints?: Maybe<Scalars["Float"]>;
  givenSharedPoints?: Maybe<Scalars["Float"]>;
  groupLeaderEmail?: Maybe<Scalars["String"]>;
  hatLogo?: Maybe<Scalars["String"]>;
  headerLogo?: Maybe<Scalars["String"]>;
  heroSliderVariableId?: Maybe<Scalars["BigInt"]>;
  homepagePageId?: Maybe<Scalars["String"]>;
  managerId?: Maybe<Scalars["BigInt"]>;
  name?: Maybe<Scalars["String"]>;
  ncesId?: Maybe<Scalars["String"]>;
  orgId?: Maybe<Scalars["Int"]>;
  organizationName?: Maybe<Scalars["String"]>;
  parentStoreId?: Maybe<Scalars["Int"]>;
  pointsPercentage?: Maybe<Scalars["Int"]>;
  primaryColor?: Maybe<Scalars["String"]>;
  productColors?: Maybe<Array<Maybe<ScopeProductColor>>>;
  productPricePercentage?: Maybe<Scalars["Int"]>;
  programId?: Maybe<Scalars["Int"]>;
  raiseId?: Maybe<Scalars["Int"]>;
  salesRepId?: Maybe<Scalars["String"]>;
  secondaryColor?: Maybe<Scalars["String"]>;
  state?: Maybe<Scalars["String"]>;
  status?: Maybe<Scalars["String"]>;
  storeId?: Maybe<Scalars["BigInt"]>;
  storevariablesId?: Maybe<Scalars["BigInt"]>;
  teamName?: Maybe<Scalars["String"]>;
  totalDiscountAmount?: Maybe<Scalars["Float"]>;
  totalOrders?: Maybe<Scalars["BigInt"]>;
  totalPoints?: Maybe<Scalars["Float"]>;
  totalSales?: Maybe<Scalars["Float"]>;
  zipCode?: Maybe<Scalars["Int"]>;
};

export type MagentoStoreManager = {
  __typename?: "MagentoStoreManager";
  email?: Maybe<Scalars["String"]>;
  firstName?: Maybe<Scalars["String"]>;
  groupId?: Maybe<Scalars["Int"]>;
  lastName?: Maybe<Scalars["String"]>;
  managerId?: Maybe<Scalars["BigInt"]>;
  points?: Maybe<Scalars["Float"]>;
  scopeId?: Maybe<Scalars["BigInt"]>;
};

export type MagentoStorePointsEarned = {
  __typename?: "MagentoStorePointsEarned";
  endDate?: Maybe<Scalars["String"]>;
  startDate?: Maybe<Scalars["String"]>;
  totalPoints?: Maybe<Scalars["Float"]>;
};

export type MagentoStorePointsEarnedUnionType =
  | MagentoStoresPointsEarned
  | OnException;

export type MagentoStoreUnionType = MagentoStore | OnException;

export type MagentoStoresPointsEarned = {
  __typename?: "MagentoStoresPointsEarned";
  points?: Maybe<Array<Maybe<MagentoStorePointsEarned>>>;
};

export type ManageCoach = {
  __typename?: "ManageCoach";
  adId?: Maybe<Scalars["Int"]>;
  coachId?: Maybe<Scalars["Int"]>;
  createdAt?: Maybe<Scalars["Date"]>;
  createdBy?: Maybe<Scalars["Int"]>;
  firstName?: Maybe<Scalars["String"]>;
  headCoach?: Maybe<Scalars["Boolean"]>;
  isApproved?: Maybe<Scalars["Boolean"]>;
  lastName?: Maybe<Scalars["String"]>;
  photoId?: Maybe<Scalars["Int"]>;
  schoolId?: Maybe<Scalars["Int"]>;
  seasonId?: Maybe<Scalars["Int"]>;
  summary?: Maybe<Scalars["String"]>;
  title?: Maybe<Scalars["String"]>;
  updatedAt?: Maybe<Scalars["Date"]>;
  updatedBy?: Maybe<Scalars["Int"]>;
};

export type ManageCoachList = {
  __typename?: "ManageCoachList";
  count?: Maybe<Scalars["Int"]>;
  list?: Maybe<Array<Maybe<ManageCoach>>>;
  skip?: Maybe<Scalars["Int"]>;
  take?: Maybe<Scalars["Int"]>;
};

export type ManageCoachListFilter = {
  orderBy?: InputMaybe<ManageCoachListOrderBy>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<ManageCoachListWhere>;
};

export type ManageCoachListOrderBy = {
  address?: InputMaybe<SortOrderEnum>;
  city?: InputMaybe<SortOrderEnum>;
  firstName?: InputMaybe<SortOrderEnum>;
  lastName?: InputMaybe<SortOrderEnum>;
  postalCode?: InputMaybe<SortOrderEnum>;
};

export type ManageCoachListWhere = {
  adId?: InputMaybe<Scalars["Int"]>;
  coachId?: InputMaybe<Scalars["Int"]>;
  createdAt?: InputMaybe<Scalars["Date"]>;
  createdBy?: InputMaybe<Scalars["Int"]>;
  firstName?: InputMaybe<Scalars["String"]>;
  headCoach?: InputMaybe<Scalars["Boolean"]>;
  isApproved?: InputMaybe<Scalars["Boolean"]>;
  lastName?: InputMaybe<Scalars["String"]>;
  photoId?: InputMaybe<Scalars["Int"]>;
  schoolId?: InputMaybe<Scalars["Int"]>;
  seasonId?: InputMaybe<Scalars["Int"]>;
  summary?: InputMaybe<Scalars["String"]>;
  title?: InputMaybe<Scalars["String"]>;
  updatedAt?: InputMaybe<Scalars["Date"]>;
  updatedBy?: InputMaybe<Scalars["Int"]>;
};

export type ManageEvent = {
  __typename?: "ManageEvent";
  /** Type of activity or sport. */
  activity?: Maybe<Scalars["String"]>;
  /** Author or writer of the event story. */
  author?: Maybe<Scalars["String"]>;
  /** Fee associated with transportation or bus. */
  busFee?: Maybe<Scalars["Int"]>;
  /** Scheduled time for the bus or transportation. */
  busTime?: Maybe<Scalars["String"]>;
  /** Status indicating event's cancellation. */
  cancellationStatus?: Maybe<Scalars["String"]>;
  /** Additional comments or notes. */
  comments?: Maybe<Scalars["String"]>;
  /** Conference details or name. */
  conference?: Maybe<Scalars["String"]>;
  /** Event ID related to the conference. */
  conferenceEventId?: Maybe<Scalars["Int"]>;
  /** ID associated with the conference. */
  conferenceId?: Maybe<Scalars["Int"]>;
  /** Confirmation status of the event. */
  confirmed?: Maybe<Scalars["String"]>;
  /** Contract details or identifier. */
  contract?: Maybe<Scalars["String"]>;
  /** Timestamp when the event was created. */
  createdAt?: Maybe<Scalars["Date"]>;
  /** Timestamp when the event result was created. */
  createdResultAt?: Maybe<Scalars["String"]>;
  /** User ID of the creator of the event result. */
  createdResultBy?: Maybe<Scalars["Int"]>;
  /** Location for event departure. */
  departureLocation?: Maybe<Scalars["String"]>;
  /** Link for directions or map. */
  directionLink?: Maybe<Scalars["String"]>;
  /** Directions or map details for the event. */
  directions?: Maybe<Scalars["String"]>;
  /** Indication if early dismissal is required. */
  earlyDismissalRequired?: Maybe<Scalars["String"]>;
  /** Scheduled time for early dismissal. */
  earlyDismissalTime?: Maybe<Scalars["String"]>;
  /** End time of the event. */
  endTime?: Maybe<Scalars["String"]>;
  /** Estimated time of return post event. */
  estimatedReturnTime?: Maybe<Scalars["String"]>;
  /** Indication if the event is complete. */
  eventComplete?: Maybe<Scalars["Int"]>;
  /** Date of the event. */
  eventDate?: Maybe<Scalars["Date"]>;
  /** Date and Time for start of event. */
  eventDateTime?: Maybe<Scalars["String"]>;
  /** Calculated field from event date indicating day of the week. */
  eventDay?: Maybe<Scalars["String"]>;
  /** Calculated field from event ID for event details. */
  eventDetailsPath?: Maybe<Scalars["String"]>;
  /** The unique ID of the event. */
  eventId?: Maybe<Scalars["Int"]>;
  /** Lead or main summary of the event. */
  eventLead?: Maybe<Scalars["String"]>;
  /** Unique ID for the event result. */
  eventResultID?: Maybe<Scalars["Int"]>;
  /** Detailed story or description of the event. */
  eventStory?: Maybe<Scalars["String"]>;
  /** Title or main heading of the event story. */
  eventTitle?: Maybe<Scalars["String"]>;
  /** Type or category of the event. */
  eventType?: Maybe<Scalars["String"]>;
  /** Indication if event exists in MLS system. */
  existsInMls?: Maybe<Scalars["Int"]>;
  /** Fee or cost associated with the event. */
  fee?: Maybe<Scalars["Int"]>;
  /** Revenue generated from gate or entry fee. */
  gateRevenue?: Maybe<Scalars["Int"]>;
  /** Gender specification for the event. */
  gender?: Maybe<Scalars["String"]>;
  /** Value grouping multiple events. */
  groupVal?: Maybe<Scalars["String"]>;
  /** General status of the event. */
  gs?: Maybe<Scalars["String"]>;
  /** Main headline or caption for the event. */
  headline?: Maybe<Scalars["String"]>;
  /** Indication if the event has major impact. */
  impactEvent?: Maybe<Scalars["String"]>;
  /** Indication if event details are approved. */
  isApproved?: Maybe<Scalars["Boolean"]>;
  /** Lead information or summary. */
  lead?: Maybe<Scalars["String"]>;
  /** Level of the event. */
  level?: Maybe<Scalars["String"]>;
  /** Location description or address. */
  location?: Maybe<Scalars["String"]>;
  /** Points deducted for a loss. */
  lossPoints?: Maybe<Scalars["Int"]>;
  /** Number of buses or transportation units. */
  numBuses?: Maybe<Scalars["Int"]>;
  /** Name or description of the opponent. */
  opponent?: Maybe<Scalars["String"]>;
  /** Code identifier for the opponent. */
  opponentCode?: Maybe<Scalars["String"]>;
  /** Opponent details or description. */
  opponentForEvent?: Maybe<ManageOpponent>;
  /** Score achieved by the opponent team. */
  opponentScore?: Maybe<Scalars["String"]>;
  /** Outcome or result of the event. */
  outcome?: Maybe<Scalars["String"]>;
  /** URL or path to event picture. */
  picture?: Maybe<Scalars["String"]>;
  /** Location or venue of the event. */
  place?: Maybe<Scalars["String"]>;
  /** Details for event setup or preparation. */
  prepSetup?: Maybe<Scalars["String"]>;
  /** Indication if event should be promoted. */
  promote?: Maybe<Scalars["String"]>;
  /** Indication if score should be reported. */
  reportScore?: Maybe<Scalars["Int"]>;
  /** Indication if story related to event should be reported. */
  reportStory?: Maybe<Scalars["Int"]>;
  /** Results or outcomes from the event. */
  results?: Maybe<Scalars["String"]>;
  /** Total revenue generated from the event. */
  revenue?: Maybe<Scalars["Int"]>;
  /** Indication if event rolls over to next season. */
  rollover?: Maybe<Scalars["String"]>;
  /** Season details or year. */
  season?: Maybe<Scalars["String"]>;
  /** Associated team for the season. */
  seasonTeam?: Maybe<Scalars["Int"]>;
  /** Indication if event should be showcased on front page. */
  showFrontPage?: Maybe<Scalars["Int"]>;
  /** Start time used for sorting. */
  sortStartTime?: Maybe<Scalars["String"]>;
  /** Start time of the event. */
  startTime?: Maybe<Scalars["String"]>;
  /** Name of the associated team. */
  teamName?: Maybe<Scalars["String"]>;
  /** Score achieved by the home team. */
  teamScore?: Maybe<Scalars["String"]>;
  /** Title or heading of the event. */
  title?: Maybe<Scalars["String"]>;
  /** Indication if the event is part of a tournament. */
  tournament?: Maybe<Scalars["String"]>;
  /** Unique ID for transportation details. */
  transId?: Maybe<Scalars["Int"]>;
  /** Comments related to transportation. */
  transportComments?: Maybe<Scalars["String"]>;
  /** Transportation details for the event. */
  transportation?: Maybe<Scalars["String"]>;
  /** Date when event result was last updated. */
  updateResultDate?: Maybe<Scalars["String"]>;
  /** Timestamp of the last update to the event. */
  updatedAt?: Maybe<Scalars["Date"]>;
  /** Timestamp of the last update to the event result. */
  updatedResultAt?: Maybe<Scalars["String"]>;
  /** User ID of the last updater of the event result. */
  updatedResultBy?: Maybe<Scalars["Int"]>;
  /** Web directory or path for the event. */
  webDir?: Maybe<Scalars["String"]>;
  /** Points awarded for a win. */
  winPoints?: Maybe<Scalars["Int"]>;
  /** Year(s) associated with the event. */
  years?: Maybe<Scalars["String"]>;
};

export type ManageEventList = {
  __typename?: "ManageEventList";
  calendarDate?: Maybe<Scalars["String"]>;
  count?: Maybe<Scalars["Int"]>;
  list?: Maybe<Array<Maybe<ManageEvent>>>;
  skip?: Maybe<Scalars["Int"]>;
  take?: Maybe<Scalars["Int"]>;
};

export type ManageEventListFilter = {
  orderBy?: InputMaybe<ManageEventListOrderBy>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<ManageEventListWhere>;
};

export type ManageEventListOrderBy = {
  confirmed?: InputMaybe<Scalars["String"]>;
  opponent?: InputMaybe<Scalars["String"]>;
};

export type ManageEventListWhere = {
  confirmed?: InputMaybe<Scalars["String"]>;
  month?: InputMaybe<Scalars["Int"]>;
  opponent?: InputMaybe<Scalars["String"]>;
  year?: InputMaybe<Scalars["Int"]>;
};

/** A type representing details about an opponent in the context of school management. */
export type ManageOpponent = {
  __typename?: "ManageOpponent";
  /** The name of the athletic director (AD) associated with the school/opponent. */
  adName?: Maybe<Scalars["String"]>;
  /** The physical address of the school. */
  address?: Maybe<Scalars["String"]>;
  /** The city where the school is located. */
  city?: Maybe<Scalars["String"]>;
  /** The primary contact email address for the school. */
  email?: Maybe<Scalars["String"]>;
  /** The fax number for the school, if available. */
  fax?: Maybe<Scalars["String"]>;
  /** A flag indicating if the opponent's record has been marked as deleted. */
  isDeleted?: Maybe<Scalars["Boolean"]>;
  /** The National Center for Education Statistics (NCES) identifier for the school. */
  ncesId?: Maybe<Scalars["String"]>;
  /** A flag indicating if the entity is not an actual school. */
  nonSchool?: Maybe<Scalars["Boolean"]>;
  /** The primary contact phone number for the school. */
  phone?: Maybe<Scalars["String"]>;
  /** The unique identifier for the school. This is mandatory. */
  schoolCode: Scalars["String"];
  /** The official name of the school. */
  schoolName?: Maybe<Scalars["String"]>;
  /** The state or province where the school is located. */
  state?: Maybe<Scalars["String"]>;
  /** The postal code for the school's address. */
  zip?: Maybe<Scalars["String"]>;
};

/** The object which contains all the input data for the manage organization query */
export type ManageOrganization = {
  __typename?: "ManageOrganization";
  /** The ad of the school */
  ad?: Maybe<Scalars["String"]>;
  /** AD Program ID */
  adProgramID?: Maybe<Scalars["Int"]>;
  /** Ad report */
  adReport?: Maybe<Scalars["Int"]>;
  /** The ad school of the school */
  adSchool?: Maybe<Scalars["Int"]>;
  /** The address of the school */
  address?: Maybe<Scalars["String"]>;
  /** Announcements for the organization */
  announcementsForOrganization?: Maybe<ManageResourceAnnouncementList>;
  /** AOTM Spotlight */
  aotmSpotlight?: Maybe<Scalars["Int"]>;
  /** Auto Approve Score Only */
  autoApproveScoreOnly?: Maybe<Scalars["Int"]>;
  /** Camp Registration Confirmation Text */
  campRegConfirmationTxt?: Maybe<Scalars["String"]>;
  /** The city of the school */
  city?: Maybe<Scalars["String"]>;
  coachForOrganization?: Maybe<ManageCoachList>;
  /** The First Color of the school */
  color1?: Maybe<Scalars["String"]>;
  /** The Second Color of the school */
  color2?: Maybe<Scalars["String"]>;
  /** Conference Name */
  conferenceName?: Maybe<Scalars["String"]>;
  /** Conference URL */
  conferenceURL?: Maybe<Scalars["String"]>;
  /** Custom Label */
  customLabel?: Maybe<Scalars["String"]>;
  /** Custom Label 2 */
  customLabel2?: Maybe<Scalars["String"]>;
  /** Custom Link */
  customLink?: Maybe<Scalars["String"]>;
  /** Custom Link 2 */
  customLink2?: Maybe<Scalars["String"]>;
  /** The Database folder of the school */
  dbFolder?: Maybe<Scalars["String"]>;
  /** Email Blast Enabled */
  emailBlastEnabled?: Maybe<Scalars["Int"]>;
  /** Email Spotlight */
  emailSpotlight?: Maybe<Scalars["Int"]>;
  /** Equipment Enabled */
  equipmentEnabled?: Maybe<Scalars["Boolean"]>;
  /** Event Locations for the organization */
  eventLocationsForOrganization?: Maybe<ManageResourceEventLocationList>;
  /** Facebook Url */
  facebookUrl?: Maybe<Scalars["String"]>;
  /** The fax number of the school */
  fax?: Maybe<Scalars["String"]>;
  /** The featured of the school */
  featured?: Maybe<Scalars["Int"]>;
  /** Feeders Towns for the school */
  feederTowns?: Maybe<Scalars["String"]>;
  /** Gallery Spotlight */
  gallerySpotlight?: Maybe<Scalars["Int"]>;
  /** Has Activities */
  hasActivities?: Maybe<Scalars["Boolean"]>;
  /** Has Access OTM */
  hasAotm?: Maybe<Scalars["Boolean"]>;
  /** Has Athletics */
  hasAthletics?: Maybe<Scalars["Boolean"]>;
  /** Has Email Blast */
  hasEmailBlast?: Maybe<Scalars["String"]>;
  /** Has Facebook Url */
  hasFacebookUrl?: Maybe<Scalars["Boolean"]>;
  /** Has Instagram Url */
  hasInstagramUrl?: Maybe<Scalars["Boolean"]>;
  /** Has Migrated From Access To Sql Server */
  hasMigratedFromAccessToSqlServer?: Maybe<Scalars["Boolean"]>;
  /** Has Registration */
  hasRegistration?: Maybe<Scalars["Boolean"]>;
  /** Has Twitter Url */
  hasTwitterUrl?: Maybe<Scalars["Boolean"]>;
  /** The icon of the school */
  iCon?: Maybe<Scalars["String"]>;
  /** Instagram Url */
  instagramUrl?: Maybe<Scalars["String"]>;
  /** League Keeper school id */
  lKschoolId?: Maybe<Scalars["Int"]>;
  /** The latitude of the school */
  lat?: Maybe<Scalars["Float"]>;
  /** The id of the link to school database */
  linkSchoolId?: Maybe<Scalars["Int"]>;
  /** The logo of the school */
  logo?: Maybe<Scalars["String"]>;
  /** The longitude of the school */
  long?: Maybe<Scalars["Float"]>;
  /** The mascot of the school */
  mascot?: Maybe<Scalars["String"]>;
  /** Does the school have email updates */
  massUpdate?: Maybe<Scalars["Int"]>;
  /** Master School Id */
  masterSchoolId?: Maybe<Scalars["Int"]>;
  /** The message of the school */
  message?: Maybe<Scalars["String"]>;
  /** Motto */
  motto?: Maybe<Scalars["String"]>;
  /** Old School Id */
  oldSchoolId?: Maybe<Scalars["Int"]>;
  /** Pages for the organization */
  pagesForOrganization?: Maybe<ManageResourcePagesList>;
  /** The url path for the school */
  path?: Maybe<Scalars["String"]>;
  /** The phone number of the school */
  phone?: Maybe<Scalars["String"]>;
  /** Photos for the Organization */
  photosForOrganization?: Maybe<ManageResourcePhotosList>;
  playersForOrganization?: Maybe<ManagePlayerList>;
  /** The principal of the school */
  principal?: Maybe<Scalars["String"]>;
  programsForOrganization?: Maybe<ManageProgramList>;
  /** PS School Id */
  psSchoolId?: Maybe<Scalars["String"]>;
  /** PS Store Active */
  psStoreActive?: Maybe<Scalars["Int"]>;
  /** The Registration Email of the school */
  registrationEmail?: Maybe<Scalars["String"]>;
  /** The Registration Enabled of the school */
  registrationEnabled?: Maybe<Scalars["Int"]>;
  /** The Registration Policy of the school */
  registrationPolicy?: Maybe<Scalars["String"]>;
  /** The Registration Type of the school */
  registrationType?: Maybe<Scalars["Int"]>;
  /** The id of the school */
  schoolId?: Maybe<Scalars["Int"]>;
  /** The name of the school */
  schoolName?: Maybe<Scalars["String"]>;
  /** The show of the school */
  show?: Maybe<Scalars["Int"]>;
  /** Show Ad */
  showAd?: Maybe<Scalars["Boolean"]>;
  /** Show School Name And Motto */
  showSchoolNameAndMotto?: Maybe<Scalars["Boolean"]>;
  /** Do you want to show the teams pages first on the site */
  showTeamPagesFirst?: Maybe<Scalars["Int"]>;
  /** A Sign up code for the school */
  signUpCode?: Maybe<Scalars["String"]>;
  /** The site type of the school */
  siteType?: Maybe<Scalars["String"]>;
  /** Sport Registration Confirmation Text */
  sportRegConfirmationTxt?: Maybe<Scalars["String"]>;
  /** The id of the sql database */
  sqlBase?: Maybe<Scalars["Int"]>;
  /** Staff Calendar Enabled */
  staffCalendarEnabled?: Maybe<Scalars["Int"]>;
  /** The state of the school (abbreviation) */
  state?: Maybe<Scalars["String"]>;
  /** The state organization of the school */
  stateOrg?: Maybe<Scalars["String"]>;
  /** Time Zone */
  timeZone?: Maybe<Scalars["String"]>;
  /** Twitter Url */
  twitterUrl?: Maybe<Scalars["String"]>;
  /** Urgent Announcements for the organization */
  urgentAnnouncementsForOrganization?: Maybe<ManageResourceAnnouncementList>;
  /** The web folder of the school that you have chosen */
  webFolder?: Maybe<Scalars["String"]>;
  /** Web Password */
  webPassword?: Maybe<Scalars["String"]>;
  /** The website of the school */
  webSite?: Maybe<Scalars["String"]>;
  /** The zip code of the school */
  zip?: Maybe<Scalars["String"]>;
};

/** The object which contains all the input data for the manage organization query */
export type ManageOrganizationAnnouncementsForOrganizationArgs = {
  filter?: InputMaybe<ManageResourceAnnouncementFilter>;
};

/** The object which contains all the input data for the manage organization query */
export type ManageOrganizationCoachForOrganizationArgs = {
  filter?: InputMaybe<ManageCoachListFilter>;
};

/** The object which contains all the input data for the manage organization query */
export type ManageOrganizationEventLocationsForOrganizationArgs = {
  filter?: InputMaybe<ManageResourceEventLocationFilter>;
};

/** The object which contains all the input data for the manage organization query */
export type ManageOrganizationPagesForOrganizationArgs = {
  filter?: InputMaybe<ManageResourcePagesFilter>;
};

/** The object which contains all the input data for the manage organization query */
export type ManageOrganizationPhotosForOrganizationArgs = {
  filter?: InputMaybe<ManageResourcePhotosFilter>;
};

/** The object which contains all the input data for the manage organization query */
export type ManageOrganizationPlayersForOrganizationArgs = {
  filter?: InputMaybe<ManagePlayerListFilter>;
};

/** The object which contains all the input data for the manage organization query */
export type ManageOrganizationProgramsForOrganizationArgs = {
  filter?: InputMaybe<ManageProgramListFilter>;
};

/** The object which contains all the input data for the manage organization query */
export type ManageOrganizationUrgentAnnouncementsForOrganizationArgs = {
  filter?: InputMaybe<UrgentAnnouncementFilter>;
};

export type ManageOrganizationFilter = {
  orderBy?: InputMaybe<ManageOrganizationOrderBy>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<ManageOrganizationWhere>;
};

export type ManageOrganizationList = {
  __typename?: "ManageOrganizationList";
  count?: Maybe<Scalars["Int"]>;
  list?: Maybe<Array<Maybe<ManageOrganization>>>;
  skip?: Maybe<Scalars["Int"]>;
  take?: Maybe<Scalars["Int"]>;
};

export type ManageOrganizationOrderBy = {
  name?: InputMaybe<SortOrderEnum>;
  state?: InputMaybe<SortOrderEnum>;
};

export type ManageOrganizationWhere = {
  isDeleted?: InputMaybe<Scalars["Boolean"]>;
  name?: InputMaybe<Scalars["String"]>;
  show?: InputMaybe<Scalars["Int"]>;
  state?: InputMaybe<Scalars["String"]>;
};

/** The object which contains all the input data for each player */
export type ManagePlayer = {
  __typename?: "ManagePlayer";
  ch1?: Maybe<Scalars["Int"]>;
  ch2?: Maybe<Scalars["Int"]>;
  ch3?: Maybe<Scalars["Int"]>;
  city?: Maybe<Scalars["String"]>;
  custodyCode?: Maybe<Scalars["Int"]>;
  dayPhone?: Maybe<Scalars["String"]>;
  dob?: Maybe<Scalars["Date"]>;
  fName?: Maybe<Scalars["String"]>;
  feePaid?: Maybe<Scalars["Int"]>;
  gender?: Maybe<Scalars["String"]>;
  gradYear?: Maybe<Scalars["String"]>;
  hatsize?: Maybe<Scalars["String"]>;
  height?: Maybe<Scalars["String"]>;
  homePhone?: Maybe<Scalars["String"]>;
  hospitalPhone?: Maybe<Scalars["String"]>;
  insuranceCompany?: Maybe<Scalars["String"]>;
  insurancePolicyNum?: Maybe<Scalars["String"]>;
  jersey?: Maybe<Scalars["String"]>;
  lName?: Maybe<Scalars["String"]>;
  livesWithCode?: Maybe<Scalars["Int"]>;
  noPress?: Maybe<Scalars["Int"]>;
  permission?: Maybe<Scalars["Int"]>;
  physical?: Maybe<Scalars["Int"]>;
  physicalDate?: Maybe<Scalars["Date"]>;
  physician?: Maybe<Scalars["String"]>;
  physicianTelephone?: Maybe<Scalars["String"]>;
  position?: Maybe<Scalars["String"]>;
  preferredHospital?: Maybe<Scalars["String"]>;
  rosterId?: Maybe<Scalars["Int"]>;
  schoolId?: Maybe<Scalars["Int"]>;
  seasonId?: Maybe<Scalars["Int"]>;
  shirtsize?: Maybe<Scalars["String"]>;
  shortsize?: Maybe<Scalars["String"]>;
  siteStudentId?: Maybe<Scalars["Int"]>;
  stAddress?: Maybe<Scalars["String"]>;
  state?: Maybe<Scalars["String"]>;
  status?: Maybe<Scalars["String"]>;
  studentId?: Maybe<Scalars["String"]>;
  weight?: Maybe<Scalars["String"]>;
  zip?: Maybe<Scalars["String"]>;
};

export type ManagePlayerList = {
  __typename?: "ManagePlayerList";
  count?: Maybe<Scalars["Int"]>;
  list?: Maybe<Array<Maybe<ManagePlayer>>>;
  skip?: Maybe<Scalars["Int"]>;
  take?: Maybe<Scalars["Int"]>;
};

export type ManagePlayerListFilter = {
  orderBy?: InputMaybe<ManagePlayerListOrderBy>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<ManagePlayerListWhere>;
};

export type ManagePlayerListOrderBy = {
  city?: InputMaybe<SortOrderEnum>;
  dob?: InputMaybe<SortOrderEnum>;
  fName?: InputMaybe<SortOrderEnum>;
  gender?: InputMaybe<SortOrderEnum>;
  gradYear?: InputMaybe<SortOrderEnum>;
  height?: InputMaybe<SortOrderEnum>;
  jersey?: InputMaybe<SortOrderEnum>;
  lName?: InputMaybe<SortOrderEnum>;
  permission?: InputMaybe<SortOrderEnum>;
  position?: InputMaybe<SortOrderEnum>;
  rosterId?: InputMaybe<SortOrderEnum>;
  schoolId?: InputMaybe<SortOrderEnum>;
  seasonId?: InputMaybe<SortOrderEnum>;
  siteStudentId?: InputMaybe<SortOrderEnum>;
  state?: InputMaybe<SortOrderEnum>;
  status?: InputMaybe<SortOrderEnum>;
  weight?: InputMaybe<SortOrderEnum>;
  zip?: InputMaybe<SortOrderEnum>;
};

export type ManagePlayerListWhere = {
  ch1?: InputMaybe<Scalars["Int"]>;
  ch2?: InputMaybe<Scalars["Int"]>;
  ch3?: InputMaybe<Scalars["Int"]>;
  city?: InputMaybe<Scalars["String"]>;
  custodyCode?: InputMaybe<Scalars["Int"]>;
  dayPhone?: InputMaybe<Scalars["String"]>;
  dob?: InputMaybe<Scalars["Date"]>;
  fName?: InputMaybe<Scalars["String"]>;
  feePaid?: InputMaybe<Scalars["Int"]>;
  gender?: InputMaybe<Scalars["String"]>;
  gradYear?: InputMaybe<Scalars["String"]>;
  hatsize?: InputMaybe<Scalars["String"]>;
  height?: InputMaybe<Scalars["String"]>;
  homePhone?: InputMaybe<Scalars["String"]>;
  hospitalPhone?: InputMaybe<Scalars["String"]>;
  insuranceCompany?: InputMaybe<Scalars["String"]>;
  insurancePolicyNum?: InputMaybe<Scalars["String"]>;
  jersey?: InputMaybe<Scalars["String"]>;
  lName?: InputMaybe<Scalars["String"]>;
  livesWithCode?: InputMaybe<Scalars["Int"]>;
  noPress?: InputMaybe<Scalars["Int"]>;
  permission?: InputMaybe<Scalars["Int"]>;
  physical?: InputMaybe<Scalars["Int"]>;
  physicalDate?: InputMaybe<Scalars["Date"]>;
  physician?: InputMaybe<Scalars["String"]>;
  physicianTelephone?: InputMaybe<Scalars["String"]>;
  position?: InputMaybe<Scalars["String"]>;
  preferredHospital?: InputMaybe<Scalars["String"]>;
  rosterId?: InputMaybe<Scalars["Int"]>;
  schoolId?: InputMaybe<Scalars["Int"]>;
  seasonId?: InputMaybe<Scalars["Int"]>;
  shirtsize?: InputMaybe<Scalars["String"]>;
  shortsize?: InputMaybe<Scalars["String"]>;
  siteStudentId?: InputMaybe<Scalars["Int"]>;
  stAddress?: InputMaybe<Scalars["String"]>;
  state?: InputMaybe<Scalars["String"]>;
  status?: InputMaybe<Scalars["String"]>;
  studentId?: InputMaybe<Scalars["String"]>;
  weight?: InputMaybe<Scalars["String"]>;
  zip?: InputMaybe<Scalars["String"]>;
};

export type ManageProgram = {
  __typename?: "ManageProgram";
  announcementsForPrograms?: Maybe<ManageResourceAnnouncementList>;
  gender?: Maybe<Scalars["String"]>;
  groupVal?: Maybe<Scalars["String"]>;
  homeField?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["Int"]>;
  level1?: Maybe<Scalars["String"]>;
  photosForPrograms?: Maybe<ManageResourcePhotosList>;
  seasonsForProgram?: Maybe<ManageSeasonList>;
  sportCode?: Maybe<Scalars["String"]>;
  sportDescription?: Maybe<Scalars["String"]>;
  sportName?: Maybe<Scalars["String"]>;
};

export type ManageProgramAnnouncementsForProgramsArgs = {
  filter?: InputMaybe<ManageResourceAnnouncementFilter>;
};

export type ManageProgramPhotosForProgramsArgs = {
  filter?: InputMaybe<ManageResourcePhotosFilter>;
};

export type ManageProgramSeasonsForProgramArgs = {
  filter?: InputMaybe<ManageSeasonListFilter>;
};

export type ManageProgramList = {
  __typename?: "ManageProgramList";
  count?: Maybe<Scalars["Int"]>;
  list?: Maybe<Array<Maybe<ManageProgram>>>;
  skip?: Maybe<Scalars["Int"]>;
  take?: Maybe<Scalars["Int"]>;
};

export type ManageProgramListFilter = {
  orderBy?: InputMaybe<ManageProgramListOrderBy>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<ManageProgramListWhere>;
};

export type ManageProgramListOrderBy = {
  gender?: InputMaybe<OrderType>;
  level1?: InputMaybe<OrderType>;
  sportName?: InputMaybe<OrderType>;
};

export type ManageProgramListWhere = {
  gender?: InputMaybe<Scalars["String"]>;
  groupVal?: InputMaybe<Scalars["String"]>;
  level1?: InputMaybe<Scalars["String"]>;
  sportName?: InputMaybe<Scalars["String"]>;
};

/** The object which contains all the input data for the ManageResource announcement query */
export type ManageResourceAnnouncement = {
  __typename?: "ManageResourceAnnouncement";
  active?: Maybe<Scalars["String"]>;
  announcement?: Maybe<Scalars["String"]>;
  createdAt?: Maybe<Scalars["Date"]>;
  createdBy?: Maybe<Scalars["Int"]>;
  emailFilter?: Maybe<Scalars["String"]>;
  emailRequest?: Maybe<Scalars["Int"]>;
  endDate?: Maybe<Scalars["Date"]>;
  id?: Maybe<Scalars["Int"]>;
  imageId?: Maybe<Scalars["Int"]>;
  isApproved?: Maybe<Scalars["Boolean"]>;
  onFront?: Maybe<Scalars["Int"]>;
  recordStatus?: Maybe<Scalars["Int"]>;
  schoolId?: Maybe<Scalars["Int"]>;
  showUrgentFrom?: Maybe<Scalars["Date"]>;
  showUrgentUntil?: Maybe<Scalars["Date"]>;
  sortVal?: Maybe<Scalars["Int"]>;
  sport?: Maybe<Scalars["String"]>;
  sportName?: Maybe<Scalars["String"]>;
  startDate?: Maybe<Scalars["Date"]>;
  title?: Maybe<Scalars["String"]>;
  updatedAt?: Maybe<Scalars["Date"]>;
  updatedBy?: Maybe<Scalars["Int"]>;
  urgent?: Maybe<Scalars["Boolean"]>;
};

export type ManageResourceAnnouncementFilter = {
  orderBy?: InputMaybe<ManageResourceAnnouncementOrderBy>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<ManageResourceAnnouncementWhere>;
};

export type ManageResourceAnnouncementList = {
  __typename?: "ManageResourceAnnouncementList";
  count?: Maybe<Scalars["Int"]>;
  list?: Maybe<Array<Maybe<ManageResourceAnnouncement>>>;
  skip?: Maybe<Scalars["Int"]>;
  take?: Maybe<Scalars["Int"]>;
};

export type ManageResourceAnnouncementOrderBy = {
  endDate?: InputMaybe<SortOrderEnum>;
  id?: InputMaybe<SortOrderEnum>;
  sortVal?: InputMaybe<SortOrderEnum>;
  startDate?: InputMaybe<SortOrderEnum>;
  title?: InputMaybe<SortOrderEnum>;
};

export type ManageResourceAnnouncementWhere = {
  id?: InputMaybe<Scalars["Int"]>;
  onFront?: InputMaybe<Scalars["Int"]>;
  title?: InputMaybe<Scalars["String"]>;
};

/** The object which contains all the input data for the ManageResource event location query */
export type ManageResourceEventLocation = {
  __typename?: "ManageResourceEventLocation";
  /** The fileName for the event location */
  fileName?: Maybe<Scalars["String"]>;
  /** The sport gender and level for the event location */
  gVal?: Maybe<Scalars["String"]>;
  /** The id for the event location */
  id?: Maybe<Scalars["Int"]>;
  /** The isApproved for the event location */
  isApproved?: Maybe<Scalars["Int"]>;
  /** The type for the event location */
  lType?: Maybe<Scalars["String"]>;
  /** The link for the event location */
  link?: Maybe<Scalars["String"]>;
  /** The name for the event location */
  name?: Maybe<Scalars["String"]>;
  /** The onFornt for the event location */
  onFront?: Maybe<Scalars["Int"]>;
  /** The recordStatus for the event location */
  recordStatus?: Maybe<Scalars["Int"]>;
  /** The school id for the event location */
  schoolNum?: Maybe<Scalars["Int"]>;
  /** The sortOrder for the event location */
  sortOrder?: Maybe<Scalars["Int"]>;
};

export type ManageResourceEventLocationFilter = {
  orderBy?: InputMaybe<ManageResourceEventLocationOrderBy>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<ManageResourceEventLocationWhere>;
};

export type ManageResourceEventLocationList = {
  __typename?: "ManageResourceEventLocationList";
  count?: Maybe<Scalars["Int"]>;
  list?: Maybe<Array<Maybe<ManageResourceEventLocation>>>;
  skip?: Maybe<Scalars["Int"]>;
  take?: Maybe<Scalars["Int"]>;
};

export type ManageResourceEventLocationOrderBy = {
  fileName?: InputMaybe<SortOrderEnum>;
  name?: InputMaybe<SortOrderEnum>;
};

export type ManageResourceEventLocationWhere = {
  fileName?: InputMaybe<PagesNameEnum>;
  name?: InputMaybe<SortOrderEnum>;
};

/** The object which contains all the input data for the ManageResource Pages query */
export type ManageResourcePages = {
  __typename?: "ManageResourcePages";
  createdAt?: Maybe<Scalars["Date"]>;
  createdBy?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  name?: Maybe<Scalars["String"]>;
  pageContent?: Maybe<Scalars["String"]>;
  pageTitle?: Maybe<Scalars["String"]>;
  schoolId?: Maybe<Scalars["Int"]>;
  updatedAt?: Maybe<Scalars["Date"]>;
  updatedBy?: Maybe<Scalars["Int"]>;
};

export type ManageResourcePagesFilter = {
  orderBy?: InputMaybe<ManageResourcePagesOrderBy>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<ManageResourcePagesWhere>;
};

export type ManageResourcePagesList = {
  __typename?: "ManageResourcePagesList";
  count?: Maybe<Scalars["Int"]>;
  list?: Maybe<Array<Maybe<ManageResourcePages>>>;
  skip?: Maybe<Scalars["Int"]>;
  take?: Maybe<Scalars["Int"]>;
};

export type ManageResourcePagesOrderBy = {
  name?: InputMaybe<SortOrderEnum>;
  pageTitle?: InputMaybe<SortOrderEnum>;
};

export type ManageResourcePagesWhere = {
  name?: InputMaybe<PagesNameEnum>;
};

/** The object which contains all the input data for the ManageResource photos query */
export type ManageResourcePhotos = {
  __typename?: "ManageResourcePhotos";
  batch?: Maybe<Scalars["String"]>;
  caption?: Maybe<Scalars["String"]>;
  companyId?: Maybe<Scalars["Int"]>;
  createdAt?: Maybe<Scalars["Date"]>;
  createdBy?: Maybe<Scalars["Int"]>;
  filename?: Maybe<Scalars["String"]>;
  group?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["Int"]>;
  image?: Maybe<Scalars["Buffer"]>;
  layout?: Maybe<Scalars["String"]>;
  level?: Maybe<Scalars["String"]>;
  needsApproval?: Maybe<Scalars["Int"]>;
  projectId?: Maybe<Scalars["Int"]>;
  recordStatus?: Maybe<Scalars["Int"]>;
  rphoto?: Maybe<Scalars["Int"]>;
  school?: Maybe<Scalars["Int"]>;
  sport?: Maybe<Scalars["String"]>;
  src?: Maybe<Scalars["String"]>;
  title?: Maybe<Scalars["String"]>;
  updatedAt?: Maybe<Scalars["Date"]>;
  updatedBy?: Maybe<Scalars["Int"]>;
};

export type ManageResourcePhotosFilter = {
  orderBy?: InputMaybe<ManageResourcePhotosOrderBy>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<ManageResourcePhotosWhere>;
};

export type ManageResourcePhotosList = {
  __typename?: "ManageResourcePhotosList";
  count?: Maybe<Scalars["Int"]>;
  list?: Maybe<Array<Maybe<ManageResourcePhotos>>>;
  skip?: Maybe<Scalars["Int"]>;
  take?: Maybe<Scalars["Int"]>;
};

export type ManageResourcePhotosOrderBy = {
  caption?: InputMaybe<SortOrderEnum>;
  filename?: InputMaybe<SortOrderEnum>;
  id?: InputMaybe<SortOrderEnum>;
  title?: InputMaybe<SortOrderEnum>;
};

export type ManageResourcePhotosWhere = {
  group?: InputMaybe<Scalars["String"]>;
  sport?: InputMaybe<Scalars["String"]>;
};

/** The object which contains all the input data for each roster */
export type ManageRoster = {
  __typename?: "ManageRoster";
  fName?: Maybe<Scalars["String"]>;
  gradYear?: Maybe<Scalars["String"]>;
  height?: Maybe<Scalars["String"]>;
  jersey?: Maybe<Scalars["String"]>;
  lName?: Maybe<Scalars["String"]>;
  level?: Maybe<Scalars["String"]>;
  participantId?: Maybe<Scalars["String"]>;
  position?: Maybe<Scalars["String"]>;
  sortJersey?: Maybe<Scalars["Int"]>;
  weight?: Maybe<Scalars["String"]>;
};

export type ManageRosterList = {
  __typename?: "ManageRosterList";
  count?: Maybe<Scalars["Int"]>;
  list?: Maybe<Array<Maybe<ManageRoster>>>;
};

export type ManageSeason = {
  __typename?: "ManageSeason";
  budget?: Maybe<Scalars["Float"]>;
  coachForProgramSeason?: Maybe<ManageCoachList>;
  defaultTimeForEvent?: Maybe<Scalars["String"]>;
  defaultTimeForPractice?: Maybe<Scalars["String"]>;
  eventsForSeason?: Maybe<ManageEventList>;
  homeField?: Maybe<Scalars["String"]>;
  isDeleted?: Maybe<Scalars["Boolean"]>;
  playersForProgramSeason?: Maybe<ManagePlayerList>;
  preview?: Maybe<Scalars["String"]>;
  rosterForProgramSeason?: Maybe<ManageRosterList>;
  season?: Maybe<Scalars["String"]>;
  seasonId?: Maybe<Scalars["Int"]>;
  sportCode?: Maybe<Scalars["String"]>;
  upcomingEventsForSeason?: Maybe<ManageEventList>;
  webPassword?: Maybe<Scalars["String"]>;
  year?: Maybe<Scalars["String"]>;
};

export type ManageSeasonCoachForProgramSeasonArgs = {
  filter?: InputMaybe<ManageCoachListFilter>;
};

export type ManageSeasonEventsForSeasonArgs = {
  filter?: InputMaybe<ManageEventListFilter>;
};

export type ManageSeasonPlayersForProgramSeasonArgs = {
  filter?: InputMaybe<ManagePlayerListFilter>;
};

export type ManageSeasonList = {
  __typename?: "ManageSeasonList";
  count?: Maybe<Scalars["Int"]>;
  list?: Maybe<Array<Maybe<ManageSeason>>>;
  skip?: Maybe<Scalars["Int"]>;
  take?: Maybe<Scalars["Int"]>;
};

export type ManageSeasonListFilter = {
  orderBy?: InputMaybe<ManageSeasonListOrderBy>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<ManageSeasonListWhere>;
};

export type ManageSeasonListOrderBy = {
  sport_code?: InputMaybe<OrderType>;
  year?: InputMaybe<OrderType>;
};

export type ManageSeasonListWhere = {
  sport_code?: InputMaybe<Scalars["String"]>;
  year?: InputMaybe<Scalars["String"]>;
};

export type ManageUser = {
  __typename?: "ManageUser";
  contracts?: Maybe<Scalars["String"]>;
  events?: Maybe<Scalars["String"]>;
  groups?: Maybe<Array<Maybe<Scalars["String"]>>>;
  id?: Maybe<Scalars["Int"]>;
  is_deleted?: Maybe<Scalars["Boolean"]>;
  is_super_user?: Maybe<Scalars["String"]>;
  maintenance?: Maybe<Scalars["String"]>;
  password?: Maybe<Scalars["String"]>;
  seasons?: Maybe<Scalars["String"]>;
  teams?: Maybe<Scalars["String"]>;
  user_email?: Maybe<Scalars["String"]>;
  user_id?: Maybe<Scalars["String"]>;
  user_level?: Maybe<Scalars["String"]>;
};

export type ManageUserCreateInput = {
  contracts: Scalars["String"];
  events: Scalars["String"];
  groups?: InputMaybe<Array<Scalars["String"]>>;
  maintenance: Scalars["String"];
  password: Scalars["String"];
  seasons: Scalars["String"];
  teams: Scalars["String"];
  user_email: Scalars["String"];
  user_id: Scalars["String"];
  user_level: Scalars["String"];
};

export type ManageUserDeleteInput = {
  id: Scalars["Int"];
};

export type ManageUserInput = {
  id: Scalars["Int"];
};

export type ManageUserUpdateInput = {
  contracts?: InputMaybe<Scalars["String"]>;
  events?: InputMaybe<Scalars["String"]>;
  groups?: InputMaybe<Array<Scalars["String"]>>;
  id: Scalars["Int"];
  maintenance?: InputMaybe<Scalars["String"]>;
  password?: InputMaybe<Scalars["String"]>;
  seasons?: InputMaybe<Scalars["String"]>;
  teams?: InputMaybe<Scalars["String"]>;
  user_email?: InputMaybe<Scalars["String"]>;
  user_id?: InputMaybe<Scalars["String"]>;
  user_level?: InputMaybe<Scalars["String"]>;
};

export type ManageUsersList = {
  __typename?: "ManageUsersList";
  items?: Maybe<Array<Maybe<ManageUser>>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  totalFilteredItems?: Maybe<Scalars["Int"]>;
  totalPages?: Maybe<Scalars["Int"]>;
  totalRows?: Maybe<Scalars["Int"]>;
};

export type ManageUsersListOptions = {
  keyword?: InputMaybe<Scalars["String"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type MarcoEventInput = {
  address?: InputMaybe<MarcoShippingAddress>;
  id: Scalars["Int"];
  items: Array<MarcoOrderItem>;
  location?: InputMaybe<Scalars["String"]>;
  order_status_notes?: InputMaybe<Array<InputMaybe<MarcoOrderStatusNote>>>;
  purchase_order?: InputMaybe<Scalars["String"]>;
  service_requests?: InputMaybe<
    Array<InputMaybe<MacroServiceRequestWorkflowState>>
  >;
  shipments?: InputMaybe<Array<InputMaybe<MarcoShipment>>>;
  shipped_at?: InputMaybe<Scalars["String"]>;
  status?: InputMaybe<MarcoOrderStatus>;
};

export enum MarcoItemWorkflowState {
  Canceled = "canceled",
  Completed = "completed",
  IntakeHold = "intake_hold",
  Open = "open",
  ProductionHold = "production_hold",
  Shipped = "shipped",
}

export type MarcoOrderItem = {
  client_item_num?: InputMaybe<Scalars["String"]>;
  customer_sku?: InputMaybe<Scalars["String"]>;
  description?: InputMaybe<Scalars["String"]>;
  id: Scalars["Int"];
  quantity: Scalars["Int"];
  sku_id?: InputMaybe<Scalars["Int"]>;
  workflow_state?: InputMaybe<MarcoItemWorkflowState>;
};

export enum MarcoOrderStatus {
  Canceled = "canceled",
  Completed = "completed",
  IntakeHold = "intake_hold",
  Open = "open",
  ProductionHold = "production_hold",
  Received = "received",
  Shipped = "shipped",
}

export type MarcoOrderStatusNote = {
  created_at: Scalars["String"];
  note: Scalars["String"];
};

export type MarcoServiceRequest = {
  created_at?: InputMaybe<Scalars["String"]>;
  items_count?: InputMaybe<Scalars["Int"]>;
  reason?: InputMaybe<Scalars["String"]>;
  workflow_state?: InputMaybe<MacroServiceRequestWorkflowState>;
};

export type MarcoShipment = {
  delivered?: InputMaybe<Scalars["Boolean"]>;
  search_link?: InputMaybe<Scalars["String"]>;
  shipping_carrier?: InputMaybe<ShippingProvider>;
  shipping_method?: InputMaybe<Scalars["String"]>;
  tracking_number?: InputMaybe<Scalars["String"]>;
};

export type MarcoShippingAddress = {
  ship_to_address?: InputMaybe<Scalars["String"]>;
  ship_to_address_2?: InputMaybe<Scalars["String"]>;
  ship_to_city?: InputMaybe<Scalars["String"]>;
  ship_to_company_name?: InputMaybe<Scalars["String"]>;
  ship_to_country?: InputMaybe<Scalars["String"]>;
  ship_to_email?: InputMaybe<Scalars["String"]>;
  ship_to_first_name?: InputMaybe<Scalars["String"]>;
  ship_to_last_name?: InputMaybe<Scalars["String"]>;
  ship_to_state?: InputMaybe<Scalars["String"]>;
  ship_to_telephone?: InputMaybe<Scalars["String"]>;
  ship_to_zip_code?: InputMaybe<Scalars["String"]>;
};

export type MarcoWebhookResponse = {
  __typename?: "MarcoWebhookResponse";
  error?: Maybe<Scalars["String"]>;
  success: Scalars["Boolean"];
};

export enum MatchStyle {
  Exact = "EXACT",
  Fuzzy = "FUZZY",
  Predictive = "PREDICTIVE",
}

export type MessageContact = {
  attributes?: InputMaybe<Scalars["JSON"]>;
  to: Scalars["String"];
};

export type MessageContactResponse = {
  __typename?: "MessageContactResponse";
  attributes?: Maybe<Scalars["JSON"]>;
  id?: Maybe<Scalars["String"]>;
  to?: Maybe<Scalars["String"]>;
};

export type MessageExecutionResponse = {
  __typename?: "MessageExecutionResponse";
  createdAt?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  status?: Maybe<Scalars["String"]>;
};

export enum MessageTransportType {
  Email = "EMAIL",
  Sms = "SMS",
}

export enum MessageType {
  Promotional = "PROMOTIONAL",
  Transactional = "TRANSACTIONAL",
}

export type MessagesChannelMembersResult =
  | ChannelMembers
  | ChannelNotFound
  | NotAuthenticated;

export type MessagesChannelRemoveMembersResult =
  | ChannelMembers
  | ChannelNotFound
  | ChannelRemoveMembersError
  | NotAuthenticated
  | NotAuthorized;

export type MessagesChannelsFilterInput = {
  orgId: Scalars["String"];
};

export type MessagesMyChannelsResult = MyChannels | NotAuthenticated;

export type MessagesUpsertUsersResult =
  | ChannelUpsertUsersError
  | MessagesUsers
  | NotAuthorized;

export type MessagesUser = {
  __typename?: "MessagesUser";
  id: Scalars["ID"];
  image?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
};

export type MessagesUsers = {
  __typename?: "MessagesUsers";
  users?: Maybe<Array<Maybe<MessagesUser>>>;
};

export type MissingArguments = Error & {
  __typename?: "MissingArguments";
  message: Scalars["String"];
};

export enum ModType {
  Affiliate = "AFFILIATE",
  Business = "BUSINESS",
  Club = "CLUB",
  District = "DISTRICT",
  Individual = "INDIVIDUAL",
  Other = "OTHER",
  School = "SCHOOL",
  Team = "TEAM",
}

export type ModifyDeleteCount = {
  __typename?: "ModifyDeleteCount";
  count?: Maybe<Scalars["Int"]>;
};

export type ModifyEventInput = {
  activity?: InputMaybe<Scalars["String"]>;
  author?: InputMaybe<Scalars["String"]>;
  bus_fee?: InputMaybe<Scalars["Float"]>;
  bus_time?: InputMaybe<Scalars["String"]>;
  cancellation_status?: InputMaybe<Scalars["String"]>;
  comments?: InputMaybe<Scalars["String"]>;
  conference?: InputMaybe<Scalars["String"]>;
  conference_event_id?: InputMaybe<Scalars["Int"]>;
  conference_id?: InputMaybe<Scalars["Int"]>;
  confirmed?: InputMaybe<Scalars["String"]>;
  contract?: InputMaybe<Scalars["String"]>;
  created_at?: InputMaybe<Scalars["Date"]>;
  departure_location?: InputMaybe<Scalars["String"]>;
  directions?: InputMaybe<Scalars["String"]>;
  early_dismissal_required?: InputMaybe<Scalars["String"]>;
  early_dismissal_time?: InputMaybe<Scalars["String"]>;
  end_time?: InputMaybe<Scalars["String"]>;
  estimated_return_time?: InputMaybe<Scalars["String"]>;
  event_date?: InputMaybe<Scalars["Date"]>;
  event_id: Scalars["Int"];
  exists_in_mls?: InputMaybe<Scalars["Int"]>;
  fee?: InputMaybe<Scalars["Float"]>;
  g_s?: InputMaybe<Scalars["String"]>;
  gate_revenue?: InputMaybe<Scalars["Float"]>;
  headline?: InputMaybe<Scalars["String"]>;
  impact_event?: InputMaybe<Scalars["String"]>;
  lead?: InputMaybe<Scalars["String"]>;
  location?: InputMaybe<Scalars["String"]>;
  loss_points?: InputMaybe<Scalars["Int"]>;
  num_buses?: InputMaybe<Scalars["Int"]>;
  opponent?: InputMaybe<Scalars["String"]>;
  opponent_code?: InputMaybe<Scalars["String"]>;
  opponent_score?: InputMaybe<Scalars["String"]>;
  picture?: InputMaybe<Scalars["String"]>;
  place?: InputMaybe<Scalars["String"]>;
  prep_setup?: InputMaybe<Scalars["String"]>;
  promote?: InputMaybe<Scalars["String"]>;
  results?: InputMaybe<Scalars["String"]>;
  revenue?: InputMaybe<Scalars["Float"]>;
  rollover?: InputMaybe<Scalars["String"]>;
  season_team?: InputMaybe<Scalars["Int"]>;
  start_time?: InputMaybe<Scalars["String"]>;
  team_score?: InputMaybe<Scalars["String"]>;
  title?: InputMaybe<Scalars["String"]>;
  tournament?: InputMaybe<Scalars["String"]>;
  trans_id?: InputMaybe<Scalars["Int"]>;
  transport_comments?: InputMaybe<Scalars["String"]>;
  transportation?: InputMaybe<Scalars["String"]>;
  update_at?: InputMaybe<Scalars["Date"]>;
  web_dir?: InputMaybe<Scalars["String"]>;
  win_points?: InputMaybe<Scalars["Int"]>;
  years?: InputMaybe<Scalars["String"]>;
};

export type ModifyEventParticipantsInput = {
  contract_received?: InputMaybe<Scalars["String"]>;
  event_id?: InputMaybe<Scalars["Int"]>;
  id: Scalars["Int"];
  notes?: InputMaybe<Scalars["String"]>;
  paid?: InputMaybe<Scalars["String"]>;
  school_code?: InputMaybe<Scalars["String"]>;
};

export type ModifyEventPreparationInput = {
  comments?: InputMaybe<Scalars["String"]>;
  event?: InputMaybe<Scalars["Int"]>;
  id: Scalars["Int"];
  prep?: InputMaybe<Scalars["String"]>;
  qty?: InputMaybe<Scalars["String"]>;
};

export type ModifyEventPreparationsInput = {
  comments?: InputMaybe<Scalars["String"]>;
  event?: InputMaybe<Scalars["Int"]>;
  id: Scalars["Int"];
  prep?: InputMaybe<Scalars["String"]>;
  qty?: InputMaybe<Scalars["String"]>;
};

export type ModifyEventTransportDetailsInput = {
  driver_name?: InputMaybe<Scalars["String"]>;
  driver_phone?: InputMaybe<Scalars["String"]>;
  event_id?: InputMaybe<Scalars["Int"]>;
  id: Scalars["Int"];
  vehicle_id?: InputMaybe<Scalars["String"]>;
  vehicle_type?: InputMaybe<Scalars["String"]>;
};

export type ModifyFacilityInput = {
  Address1?: InputMaybe<Scalars["String"]>;
  Address2?: InputMaybe<Scalars["String"]>;
  City?: InputMaybe<Scalars["String"]>;
  State?: InputMaybe<Scalars["String"]>;
  ZipCode?: InputMaybe<Scalars["String"]>;
  directions?: InputMaybe<Scalars["String"]>;
  indoor?: InputMaybe<Scalars["String"]>;
  ml_site_id?: InputMaybe<Scalars["String"]>;
  ml_space?: InputMaybe<Scalars["String"]>;
  ml_space_id?: InputMaybe<Scalars["String"]>;
  picture?: InputMaybe<Scalars["String"]>;
  place_name: Scalars["String"];
  place_name_old: Scalars["String"];
  show?: InputMaybe<Scalars["String"]>;
  web?: InputMaybe<Scalars["String"]>;
};

export type ModifyLevelInput = {
  ID: Scalars["Int"];
  Level?: InputMaybe<Scalars["String"]>;
  is_deleted?: InputMaybe<Scalars["Boolean"]>;
};

export type ModifyManyEventPreparationsByEventInput = {
  comments?: InputMaybe<Scalars["String"]>;
  event: Scalars["Int"];
  prep?: InputMaybe<Scalars["String"]>;
  qty?: InputMaybe<Scalars["String"]>;
};

export type ModifyOfficialDutyInput = {
  duty?: InputMaybe<Scalars["String"]>;
  id: Scalars["Int"];
  is_deleted?: InputMaybe<Scalars["Boolean"]>;
};

export type ModifyOfficialInput = {
  duty?: InputMaybe<Scalars["String"]>;
  event_id?: InputMaybe<Scalars["Int"]>;
  id: Scalars["Int"];
  offic_id?: InputMaybe<Scalars["String"]>;
  organization?: InputMaybe<Scalars["String"]>;
  paid?: InputMaybe<Scalars["String"]>;
  pay_code?: InputMaybe<Scalars["String"]>;
  received?: InputMaybe<Scalars["String"]>;
  salary?: InputMaybe<Scalars["Float"]>;
  ssn?: InputMaybe<Scalars["String"]>;
  voucher_number?: InputMaybe<Scalars["String"]>;
  worker_name?: InputMaybe<Scalars["String"]>;
};

export type ModifyOfficialPoolByIdInput = {
  Address?: InputMaybe<Scalars["String"]>;
  City?: InputMaybe<Scalars["String"]>;
  First?: InputMaybe<Scalars["String"]>;
  Home_Phone?: InputMaybe<Scalars["String"]>;
  ID: Scalars["String"];
  ID_old: Scalars["String"];
  Last?: InputMaybe<Scalars["String"]>;
  SSN?: InputMaybe<Scalars["String"]>;
  State?: InputMaybe<Scalars["String"]>;
  Work_Phone?: InputMaybe<Scalars["String"]>;
  Zip?: InputMaybe<Scalars["String"]>;
  cell_phone?: InputMaybe<Scalars["String"]>;
  email?: InputMaybe<Scalars["String"]>;
  is_deleted?: InputMaybe<Scalars["Boolean"]>;
  vendor_number?: InputMaybe<Scalars["String"]>;
};

export type ModifyOpponentInput = {
  Address?: InputMaybe<Scalars["String"]>;
  Phone?: InputMaybe<Scalars["String"]>;
  SchoolCode: Scalars["String"];
  SchoolName?: InputMaybe<Scalars["String"]>;
  State?: InputMaybe<Scalars["String"]>;
  Zip?: InputMaybe<Scalars["String"]>;
  ad_name?: InputMaybe<Scalars["String"]>;
  city?: InputMaybe<Scalars["String"]>;
  email?: InputMaybe<Scalars["String"]>;
  fax?: InputMaybe<Scalars["String"]>;
  is_deleted?: InputMaybe<Scalars["Boolean"]>;
  nces_id?: InputMaybe<Scalars["String"]>;
  non_school?: InputMaybe<Scalars["Boolean"]>;
};

export type ModifyPreparationInput = {
  duty?: InputMaybe<Scalars["String"]>;
  id: Scalars["Int"];
  is_deleted?: InputMaybe<Scalars["Boolean"]>;
  qty?: InputMaybe<Scalars["String"]>;
};

export type ModifySchoolInfoInput = {
  Schoolname?: InputMaybe<Scalars["String"]>;
  ad?: InputMaybe<Scalars["String"]>;
  ad_contract_signee?: InputMaybe<Scalars["String"]>;
  address?: InputMaybe<Scalars["String"]>;
  ccemail?: InputMaybe<Scalars["String"]>;
  city?: InputMaybe<Scalars["String"]>;
  conf_text_type?: InputMaybe<Scalars["String"]>;
  email?: InputMaybe<Scalars["String"]>;
  email_reminder?: InputMaybe<Scalars["String"]>;
  email_reminder_officials?: InputMaybe<Scalars["String"]>;
  email_reminder_workers?: InputMaybe<Scalars["String"]>;
  enable_cc_email_as_origin?: InputMaybe<Scalars["String"]>;
  enable_ml_updates?: InputMaybe<Scalars["String"]>;
  fax?: InputMaybe<Scalars["String"]>;
  icon?: InputMaybe<Scalars["String"]>;
  id: Scalars["String"];
  mascot?: InputMaybe<Scalars["String"]>;
  message_board_read_at?: InputMaybe<Scalars["Date"]>;
  ml_key?: InputMaybe<Scalars["String"]>;
  phone?: InputMaybe<Scalars["String"]>;
  principal?: InputMaybe<Scalars["String"]>;
  school_timezone?: InputMaybe<Scalars["String"]>;
  secondary_ad_email?: InputMaybe<Scalars["String"]>;
  secondary_ad_name?: InputMaybe<Scalars["String"]>;
  signed_contract_notification?: InputMaybe<Scalars["String"]>;
  ssn_on_file?: InputMaybe<Scalars["String"]>;
  state?: InputMaybe<Scalars["String"]>;
  state_org?: InputMaybe<Scalars["String"]>;
  state_org_abbreviation?: InputMaybe<Scalars["String"]>;
  use_security?: InputMaybe<Scalars["String"]>;
  web_password?: InputMaybe<Scalars["String"]>;
  zip?: InputMaybe<Scalars["String"]>;
};

export type ModifyVehicleInput = {
  id: Scalars["Int"];
  is_deleted?: InputMaybe<Scalars["Boolean"]>;
  status?: InputMaybe<Scalars["String"]>;
  vehicle_type?: InputMaybe<Scalars["String"]>;
};

export type ModifyWorkerInput = {
  duty?: InputMaybe<Scalars["String"]>;
  email?: InputMaybe<Scalars["String"]>;
  event_id?: InputMaybe<Scalars["Int"]>;
  id: Scalars["Int"];
  organization?: InputMaybe<Scalars["String"]>;
  paid?: InputMaybe<Scalars["String"]>;
  pay_code?: InputMaybe<Scalars["String"]>;
  pay_rate?: InputMaybe<Scalars["Float"]>;
  salary?: InputMaybe<Scalars["Float"]>;
  ssn?: InputMaybe<Scalars["String"]>;
  woker_name?: InputMaybe<Scalars["String"]>;
  worker_end_time?: InputMaybe<Scalars["String"]>;
  worker_start_time?: InputMaybe<Scalars["String"]>;
  worker_type?: InputMaybe<Scalars["String"]>;
};

export type ModifyWorkerPoolInput = {
  Address?: InputMaybe<Scalars["String"]>;
  City?: InputMaybe<Scalars["String"]>;
  First?: InputMaybe<Scalars["String"]>;
  Home_Phone?: InputMaybe<Scalars["String"]>;
  ID: Scalars["Int"];
  Last?: InputMaybe<Scalars["String"]>;
  SSN?: InputMaybe<Scalars["String"]>;
  State?: InputMaybe<Scalars["String"]>;
  Work_Phone?: InputMaybe<Scalars["String"]>;
  Zip?: InputMaybe<Scalars["String"]>;
  cell_phone?: InputMaybe<Scalars["String"]>;
  email?: InputMaybe<Scalars["String"]>;
  is_deleted?: InputMaybe<Scalars["Boolean"]>;
  pay_rate?: InputMaybe<Scalars["Float"]>;
  worker_type?: InputMaybe<Scalars["String"]>;
};

export type Mutation = {
  __typename?: "Mutation";
  addParticipantGuardianDonationInviteEmailsV2: Array<ParticipantGuardianReturn>;
  /** Add revision request. New submission will be created. Email will be sent to submitter. */
  addRevisionFundraiserApprovalSubmission: RevisionFundraiserApprovalSubmissionResult;
  /** Approve submission using approver's token. Email will be sent to submitter. */
  approveFundraiserApprovalSubmission: ApproveFundraiserApprovalSubmissionResult;
  campaignPersonListBulkCreate?: Maybe<CampaignPersonListEntries>;
  /** @deprecated Will be using campaignPersonListBulkCreate instead. */
  campaignPersonListCreate?: Maybe<CampaignPersonList>;
  campaignPersonListEntryDelete?: Maybe<CampaignPersonListEntryDelete>;
  /** Create saved search filters for campaigns for current user. */
  campaignSearchFilterCreate?: Maybe<CampaignSearchFilter>;
  /** Remove saved search filters for campaigns for current user. */
  campaignSearchFilterDelete?: Maybe<CampaignSearchFilter>;
  campaignSmsInviteAdd?: Maybe<CampaignSmsDataResponse>;
  campaignSmsInviteDelete: CampaignSmsInviteDeleteResponse;
  /** Create and update content */
  commsRegister: CommsRegisterResponse;
  /** Send message */
  commsSend: CommsSendResponse;
  /** Send Test Message */
  commsTest: CommsTestResponse;
  /** Edit document Status associated to a Fundraiser Approval Submission Form */
  completeFundraiserApprovalSubmissionDocumentUpload: CompleteFundraiserApprovalSubmissionDocumentUploadResult;
  confirmPassword?: Maybe<Scalars["String"]>;
  /** Deletes a custom template based off ID */
  contactTemplateDelete?: Maybe<CustomContactTemplates>;
  /** Saves custom contact-template for current user */
  contactTemplateSave?: Maybe<CustomContactTemplates>;
  /** @deprecated This will be replaced with latestCreateCard after data has been migrated from Spend and Raise */
  createCard: CoreCardFields;
  /** @deprecated(reason: "Use createChildFundraiserParticipantV2 instead") */
  createChildFundraiserParticipant: JoinedFundraiserId;
  createChildFundraiserParticipantV2: JoinedFundraiserId;
  createFundraiserApprovalSubmission: FundraiserApprovalSubmissionResult;
  createFundraiserUser?: Maybe<CreatedFundraiserUser>;
  createOtkParticipantEntry?: Maybe<CreatedOtkEntry>;
  createProduct?: Maybe<ProductResult>;
  createStoreOrder?: Maybe<OrderResult>;
  /** Create the log record with type DEBUG */
  debug?: Maybe<Scalars["Boolean"]>;
  /** Delete documents from fundraiser approval submission. If document is not linked to previous submission (history) it will be removed from S3 as well. */
  deleteFundraiserApprovalSubmissionDocument: Scalars["String"];
  /** Create new donate intent. The status with created always INITIATED */
  donorDonateIntentCreate?: Maybe<DonateIntent>;
  /** Update the status of donate intent to be PROCESSING. This is called when the donation was successfully sent to Stripe, but we are waiting for an charge.succeeded event from Stripe to finalize the payment. */
  donorDonateIntentMarkProcessing?: Maybe<DonateIntent>;
  /** Update donate intent as user update the payment form. The status with created always become PENDING. */
  donorDonateIntentUpdate?: Maybe<DonateIntent>;
  donorsAdhocGiftShopIntentAdd: GiftShopPurchaseIntentId;
  /** create an offline donation */
  donorsDonationCreateOffline?: Maybe<DonorOfflineDonation>;
  donorsDonationDelete?: Maybe<DonorDonation>;
  donorsDonationUpdate?: Maybe<DonorDonation>;
  donorsFundraiserPayableRefund?: Maybe<DonorPayableRefundResponse>;
  donorsGiftShopIntentAdd: GiftShopPurchaseIntentId;
  donorsGiftShopIntentAddMany: GiftShopPurchaseIntentId;
  donorsGiftShopIntentMarkProcessing: GiftShopPurchaseIntentData;
  donorsGiftShopIntentRemove: GiftShopPurchaseIntentId;
  donorsGiftShopIntentUpdate: GiftShopPurchaseIntentId;
  donorsGiftShopIntentUpdateIntent: GiftShopPurchaseIntentData;
  donorsGiftShopPurchaseUpdate?: Maybe<DonorFundraiserPurchase>;
  donorsReceiptsResend: ResendReceiptsResult;
  driveAddHubspotProspectPin?: Maybe<Scalars["String"]>;
  driveAddWinbackPin?: Maybe<Scalars["String"]>;
  /** Finalize the (closed) fundraiser after campaign settled. */
  driveCampaignFinalize?: Maybe<IMutationResult>;
  /** Settle the fundraiser when campaigns start to be closed campaigns. */
  driveCampaignSettle?: Maybe<IMutationResult>;
  /** Update the fundraiser. Right now only fundraiser notes can be updated. */
  driveCampaignUpdate?: Maybe<IMutationResult>;
  /** @deprecated use driveDisableOrgActivity */
  driveDisableActivity?: Maybe<Scalars["String"]>;
  driveDisableOrgActivity?: Maybe<Scalars["String"]>;
  /** @deprecated use driveEnableOrgActivity */
  driveEnableActivity?: Maybe<Scalars["String"]>;
  driveEnableOrgActivity?: Maybe<Scalars["String"]>;
  /** Posts a message in the activity feed */
  driveEventActivityCreate?: Maybe<DriveEventActivityFeed>;
  /** Removeds an activity feed message */
  driveEventActivityRemove?: Maybe<Scalars["String"]>;
  /** Updates the message and feature toggle in a activity message */
  driveEventActivityUpdate?: Maybe<DriveEventActivityFeed>;
  /** Takes in 2 arguments to post the agendas */
  driveEventAgendaCreate?: Maybe<DriveEventAgendaItem>;
  /** Adds an attendee to event. */
  driveEventAttendeeAdd?: Maybe<DriveEventAttendee>;
  /** Updates attendance information of attendee. */
  driveEventAttendeeUpdate?: Maybe<DriveEventAttendee>;
  /** Removes an attendee from event. */
  driveEventAttendeesRemove?: Maybe<Scalars["Int"]>;
  /** Create Event (Drive Event-Tracker) */
  driveEventCreate?: Maybe<DriveEvent>;
  /** Delete Event (Drive Event-Tracker) */
  driveEventRemove?: Maybe<Scalars["String"]>;
  /** Update Event (Drive Event-Tracker) */
  driveEventUpdate?: Maybe<DriveEvent>;
  /** Set tracking by specific organization current user. */
  driveOrgUserTrackingSet?: Maybe<DriveOrgUserTrackingReturn>;
  /** Cancel the scheduled preload sms */
  drivePreloadSmsScheduleCancel?: Maybe<Scalars["String"]>;
  /** Send out preload sms to all participants */
  drivePreloadSmsSend?: Maybe<Scalars["String"]>;
  drivePreloadSmsSendScheduleUpdate?: Maybe<Scalars["String"]>;
  driveRemoveHubspotProspectPin?: Maybe<Scalars["String"]>;
  driveRemoveWinbackPin?: Maybe<Scalars["String"]>;
  /** Resizes the uploaded attachment in S3 in Drive. */
  driveS3ImageResize?: Maybe<Scalars["String"]>;
  driveShareProjectedRaisedResults?: Maybe<IMutationResult>;
  /** Generate S3 generate pre-signed url for Drive to upload. This is used for uploading big attachments to the support ticket */
  driveUploadUrlCreate: Scalars["String"];
  editFundraiserApprovalSubmission: FundraiserApprovalSubmissionResult;
  /** Create the log record with type ERROR */
  error?: Maybe<Scalars["Boolean"]>;
  eventCreate?: Maybe<Event>;
  eventDelete?: Maybe<Event>;
  eventModify?: Maybe<Event>;
  eventParticipantsDeleteMany?: Maybe<DeleteCount>;
  eventParticipantsUpsert?: Maybe<UpsertEventParticipantsCount>;
  eventPreparationCreate?: Maybe<DailyCalendarPreparation>;
  eventPreparationDelete?: Maybe<DailyCalendarPreparation>;
  eventPreparationModify?: Maybe<DailyCalendarPreparation>;
  eventPreparationsCreateMany?: Maybe<Array<Maybe<EventPreparations>>>;
  eventPreparationsDeleteMany?: Maybe<ModifyDeleteCount>;
  eventPreparationsModifyMany?: Maybe<ModifyDeleteCount>;
  eventPreparationsUpsert?: Maybe<UpsertEventPreparationsCount>;
  eventTransportDetailsDeleteMany?: Maybe<EventTransportDetailsDeleteManyCount>;
  eventTransportDetailsUpsertMany?: Maybe<UpsertEventTransportDetailsCount>;
  facilitiesUpsert?: Maybe<Array<Maybe<FacilityLocation>>>;
  facilityCreate?: Maybe<Facility>;
  facilityDelete?: Maybe<Facility>;
  facilityModify?: Maybe<Facility>;
  /** Associate a User with a FinancialAccount. This will also create a User Node only if they exist in User Directory but not Orgs. */
  finAcctUserAssociationCreate: MutationResponse;
  /** Add a deleted_at property to a User's FinancialAccount association. This will NOT delete the relationship. */
  finAcctUserAssociationDelete: MutationResponse;
  /** Update a User's FinancialAccount association properties. This mutation will re-activate a user that has been deleted by default. **Note** There is a known bug preventing status Booleans from updating from True to False. */
  finAcctUserAssociationUpdate: MutationResponse;
  /** Create a processor specific FinancialAccount Node that is associated with an Org. This requires a valid Org id and a valid KYB id */
  financialAcctCreate: MutationResponse;
  /** Change a FinancialAccount status to "TERMINATED". This will NOT delete the node. */
  financialAcctDelete: MutationResponse;
  /** Update a FinancialAccount properties that is already associated with an Org. */
  financialAcctUpdate: MutationResponse;
  fundraiserStoreUrlUpdate?: Maybe<UpdatedFundraiserStoreUrl>;
  /** Send out email to group-leader. Support CC emails */
  groupLeaderEmailSend?: Maybe<IMutationResult>;
  groupLeaderFundraiserAddressUpdate?: Maybe<GroupLeaderFundraiserAddress>;
  groupLeaderFundraiserDonationLevelsUpdate?: Maybe<
    Array<GroupLeaderDonationLevels>
  >;
  groupLeaderFundraiserMediaGalleryUpdate?: Maybe<
    Array<Maybe<GroupLeaderMediaGallery>>
  >;
  /** edit a fundraiser associated with the group leader */
  groupLeaderFundraiserUpdate?: Maybe<GroupLeaderFundraiser>;
  helpDocumentDelete?: Maybe<Scalars["String"]>;
  helpDocumentUpload?: Maybe<Scalars["String"]>;
  /** Update hubspot call engagement for fundraiser */
  hubspotCallEngagementToDealAdd?: Maybe<HubspotCallEngagement>;
  /** Update hubspot note engagement for fundraiser */
  hubspotNoteEngagementToDealAdd?: Maybe<HubspotCallEngagement>;
  /** Create the log record with type INFO */
  info?: Maybe<Scalars["Boolean"]>;
  insightsAddPreApprovedContacts: InsAddPreApprovedContactsResult;
  insightsDeletePreApprovedContact: InsDeletePreApprovedContactResult;
  insightsEditPreApprovedContact: InsEditPreApprovedContactResult;
  insightsEditPreApprovedContactInvite: InsEditPreApprovedInviteResult;
  insightsResendInvitePreApprovedContact: InsResendPreApprovedContactResult;
  insightsSetNotificationPreferences: InsSetNotificationPreferencesResult;
  insightsSetUserPreferences: InsSetUserPreferencesResult;
  inviteAccept?: Maybe<UserInviteResponse>;
  inviteCreate: Scalars["String"];
  inviteDelete?: Maybe<Scalars["String"]>;
  joinFundraiserUser: JoinedFundraiserId;
  joinFundraiserUserById: JoinedFundraiserId;
  latestVaultCardCreate: Scalars["ID"];
  levelCreate?: Maybe<Level>;
  levelDelete?: Maybe<Level>;
  levelModify?: Maybe<Level>;
  /** Create the log record. The most universal and agnostic request. */
  log?: Maybe<Scalars["Boolean"]>;
  login?: Maybe<AuthChallenge>;
  logout?: Maybe<Scalars["Boolean"]>;
  manageUserCreate?: Maybe<ManageUser>;
  manageUserDelete?: Maybe<ManageUser>;
  manageUserUpdate?: Maybe<ManageUser>;
  marcoWebhook: MarcoWebhookResponse;
  messagesChannelAddModerators?: Maybe<ChatChannelAddModeratorsResult>;
  messagesChannelAddUsers?: Maybe<ChatChannelAddUsersResult>;
  messagesChannelCreate?: Maybe<ChatChannelCreateResult>;
  messagesChannelRemoveMembers?: Maybe<MessagesChannelRemoveMembersResult>;
  messagesChannelUpdate?: Maybe<ChatChannelUpdateResult>;
  messagesChannelUpdateMembers?: Maybe<ChatChannelUpdateMembersResult>;
  messagesToken?: Maybe<ChatTokenResult>;
  messagesUpsertUsers?: Maybe<MessagesUpsertUsersResult>;
  netsuiteWebhook?: Maybe<NetsuiteWebhookResponse>;
  noticeCreate?: Maybe<Notice>;
  noticeDelete?: Maybe<Array<Maybe<Notice>>>;
  noticeModify?: Maybe<Notice>;
  officialCreate?: Maybe<Official>;
  officialDelete?: Maybe<Official>;
  officialDutiesDelete?: Maybe<Array<Maybe<OfficialDuty>>>;
  officialDutiesUpsert?: Maybe<Array<Maybe<OfficialDuty>>>;
  officialDutyCreate?: Maybe<OfficialDuty>;
  officialDutyModify?: Maybe<OfficialDuty>;
  officialModify?: Maybe<Official>;
  officialPoolCreate?: Maybe<OfficialPool>;
  officialPoolDelete?: Maybe<Array<Maybe<OfficialPool>>>;
  officialPoolModify?: Maybe<OfficialPool>;
  officialsUpsert?: Maybe<Array<Maybe<OfficialPool>>>;
  opponentCreate?: Maybe<Opponent>;
  opponentDelete?: Maybe<Opponent>;
  opponentModify?: Maybe<Opponent>;
  opponentsUpsert?: Maybe<Array<Maybe<Opponent>>>;
  /** Create a new Affiliate. Affiliates have a parent Org, and this mutation will connect the two. */
  orgAffiliateCreate: MutationResponse;
  /** Create a new Club. Clubs do not have a parent Org. */
  orgClubCreate: MutationResponse;
  /** Create a new District. Districts do not have a parent Org. */
  orgDistrictCreate: MutationResponse;
  /** Update an Org Node's label in parallel with changes being made in MOD. This mutation is intended to preserve User Associations and Financial Account Associations. */
  orgLabelUpdate: MutationResponse;
  /** Create a new Program named after a given activity and connect it to a given Org. */
  orgProgramCreate: MutationResponse;
  /** Create a new School. Schools usually have a parent District, and this mutation will optionally connect the two. */
  orgSchoolCreate: MutationResponse;
  /** Create a new Team for a given activity and connect it to a Program of the same activity under a given Org. */
  orgTeamCreate: MutationResponse;
  participantDonationInviteEdit?: Maybe<DonorEmailData>;
  participantDonationInviteEmailAdd?: Maybe<DonorEmailData>;
  participantDonationInviteRemove?: Maybe<
    Array<Maybe<DonationInviteRemoveData>>
  >;
  participantDonationInviteSMSAdd?: Maybe<DonationInviteSmsData>;
  /** This will update the emails of a Participant's Guardian Email field */
  participantGuardianEmailUpdate: ParticipantGuardianEmailUpdate;
  participantIncentiveSelectionDelete?: Maybe<DeletedIncentiveRecord>;
  participantRewardsBulkCreate?: Maybe<Array<Maybe<BulkRewards>>>;
  participantRewardsCreate?: Maybe<CreatedParticipantRewards>;
  participantSignup: Array<TransportType>;
  /** Send out emails to particpants */
  participantsEmailSend?: Maybe<IMutationResult>;
  /** Sends out texts to particpants */
  participantsTextSend?: Maybe<IMutationResult>;
  paymentsApiCustomerGetOrCreate: PaymentsApiCustomerResponse;
  paymentsApiDetachPaymentMethod?: Maybe<PaymentsApiDetachPaymentMethodResponse>;
  paymentsApiPayment?: Maybe<PaymentsApiPaymentResponse>;
  paymentsApiRefund?: Maybe<PaymentsApiCreateRefundResponse>;
  /** Reschedule time (timezone-sensitive) to send out preload email to all participants */
  preloadEmailRescheduleSend?: Maybe<PreloadEmailSchedule>;
  preloadEmailScheduleCancel?: Maybe<Scalars["Boolean"]>;
  /** Schedule time (timezone-sensitive) to send out preload email to all participants */
  preloadEmailScheduleSend?: Maybe<PreloadEmailSchedule>;
  /** Send out preload email to all participants */
  preloadEmailSend?: Maybe<Scalars["String"]>;
  preparationCreate?: Maybe<Preparation>;
  preparationDelete?: Maybe<Preparation>;
  preparationModify?: Maybe<Preparation>;
  /** Create multiple fundraiser users and return total number of fundraiser users created */
  raiseAdminFundraiserUsersCreateMany: Scalars["Int"];
  roleCreate?: Maybe<Role>;
  roleDelete?: Maybe<Role>;
  rolePermissionsUpdate?: Maybe<Role>;
  roleUpdate?: Maybe<Role>;
  /** Request one or more S3 pre-signed urls to upload files for approval submissions. */
  s3PresignedInsightsApprovalsUrl: Array<S3PresignedInsightsApprovalsUrlResult>;
  samlCallback?: Maybe<Auth>;
  schoolInfoCreate?: Maybe<SchoolInfo>;
  schoolInfoDelete?: Maybe<SchoolInfo>;
  schoolInfoModify?: Maybe<SchoolInfo>;
  seasonCreate?: Maybe<Season>;
  seasonDelete?: Maybe<Array<Maybe<Season>>>;
  seasonModify?: Maybe<Season>;
  seasonPostponeModify?: Maybe<PostponeUnpostponeResponse>;
  seasonScheduleTeamsCreate?: Maybe<Array<Maybe<Season>>>;
  seasonUnpostponeModify?: Maybe<PostponeUnpostponeResponse>;
  /** Add, update or remove Insights configurations for current user Allowed configurations: - VAULT_WELCOME_DISMISSED (YES | default=NO) - VAULT_ORG_APPROVED_DISMISS_BANNER (value should be org_id list) - APPROVALS_WELCOME_DISMISSED (YES | default=NO) Note: value=null to unset */
  setUserInsightsConfigurations: UserInsightsConfigResult;
  /** Update settlement note for a fundraiser */
  settlementIssuesRecord?: Maybe<Scalars["String"]>;
  /** Update settlement status for a fundraiser */
  settlementStatusUpdate?: Maybe<IMutationResult>;
  spendArchiveSeasonMembers?: Maybe<SpendArchiveSeasonMembersResponse>;
  spendAuthorizeAutoPay?: Maybe<SpendAuthorizeAutoPayResponse>;
  spendBudgetCreate?: Maybe<SpendBudgetId>;
  spendBudgetDelete?: Maybe<SpendBudgetId>;
  spendBudgetUpdate?: Maybe<SpendBudgetId>;
  spendCategoryCreate?: Maybe<SpendCategoryId>;
  spendCategoryUpdate?: Maybe<SpendCategoryId>;
  spendCategoryUpsertBulk?: Maybe<Array<Maybe<SpendCategoryId>>>;
  spendCreditMemoCreate?: Maybe<SpendCreditMemoCreateResponse>;
  spendCreditMemoUpdate?: Maybe<SpendCreditMemoUpdateResponse>;
  spendGroupACHCredit?: Maybe<SpendAchCreditResponse>;
  spendGroupArchive?: Maybe<SpendGroupId>;
  spendGroupBankAccessTokenCreate?: Maybe<SpendBankAccessCreateResponse>;
  spendGroupBankAccessTokenDelete?: Maybe<SpendBankAccessDeleteResponse>;
  spendGroupCheckCancel?: Maybe<SpendCancelCheckId>;
  spendGroupCheckDepositPatch?: Maybe<SpendCheckDepositTagsResponse>;
  spendGroupCheckSend?: Maybe<SpendCheckSendResponse>;
  spendGroupCounterpartyCreate?: Maybe<SpendCounterpartyCreateResponse>;
  spendGroupCounterpartyDelete?: Maybe<SpendDeleteCounterpartyResponse>;
  spendGroupCreate?: Maybe<SpendGroupId>;
  spendGroupDebitCardCreate?: Maybe<SpendDebitCardId>;
  spendGroupDuplicate?: Maybe<SpendGroupId>;
  spendGroupExternalTransfer?: Maybe<SpendExternalTransferResponse>;
  spendGroupPayeeCreate?: Maybe<SpendPayeeCreateResponse>;
  spendGroupPayeeUpdate?: Maybe<SpendPayeeCreateResponse>;
  spendGroupRosterCreate?: Maybe<SpendGroupRosterId>;
  spendGroupRosterUpdate?: Maybe<SpendRosterUpdate>;
  spendGroupRostersCreate?: Maybe<Array<Maybe<SpendGroupRosterId>>>;
  spendGroupUpdate?: Maybe<SpendGroupId>;
  spendGuardianSignup?: Maybe<SpendUserId>;
  spendInvoiceArchive?: Maybe<SpendInvoiceId>;
  spendInvoiceChangeRequest?: Maybe<SpendInvoiceId>;
  spendInvoiceCreate?: Maybe<SpendInvoiceId>;
  spendInvoiceOptInOrOut?: Maybe<SpendInvoiceId>;
  spendInvoicePaymentDeauthorize?: Maybe<SpendDeauthorizeResponse>;
  spendInvoiceRefund?: Maybe<SpendInvoiceRefundResponse>;
  spendInvoiceReminderCreate?: Maybe<SpendNotificationId>;
  spendInvoiceUpdate?: Maybe<SpendInvoiceId>;
  spendInvoiceUpdatePaymentMethod?: Maybe<SpendInvoiceIDs>;
  spendNotificationCreate?: Maybe<SpendNotificationStatus>;
  spendOrganizationACHCredit?: Maybe<SpendAchCreditResponse>;
  spendOrganizationAccountTransfer: SpendAccountId;
  spendOrganizationAdminUpdate?: Maybe<SpendOrgAdminUpdateResponse>;
  spendOrganizationBankAccessTokenCreate?: Maybe<SpendBankAccessCreateResponse>;
  spendOrganizationBankAccessTokenDelete?: Maybe<SpendBankAccessDeleteResponse>;
  spendOrganizationCheckCancel?: Maybe<SpendCancelCheckId>;
  spendOrganizationCheckDepositPatch?: Maybe<SpendCheckDepositTagsResponse>;
  spendOrganizationCheckSend?: Maybe<SpendCheckSendResponse>;
  spendOrganizationCounterpartyCreate?: Maybe<SpendCounterpartyCreateResponse>;
  spendOrganizationCounterpartyDelete?: Maybe<SpendDeleteCounterpartyResponse>;
  spendOrganizationCreate?: Maybe<SpendOrganizationId>;
  spendOrganizationDebitCardCreate?: Maybe<SpendDebitCardId>;
  spendOrganizationExternalTransfer?: Maybe<SpendExternalTransferResponse>;
  spendOrganizationPayeeCreate?: Maybe<SpendPayeeCreateResponse>;
  spendOrganizationPayeeUpdate?: Maybe<SpendPayeeCreateResponse>;
  spendOrganizationSignup?: Maybe<SpendSignupFormResponse>;
  spendOrganizationUpdate?: Maybe<SpendOrganization>;
  spendPaymentMethodCreate?: Maybe<SpendPaymentMethodResponse>;
  spendPaymentScheduleCreate?: Maybe<SpendPaymentScheduleId>;
  spendPaymentScheduleRevert?: Maybe<SpendPaymentScheduleId>;
  spendPaymentScheduleRevertMany?: Maybe<Array<Maybe<SpendPaymentScheduleId>>>;
  spendPaymentScheduleUpdate?: Maybe<SpendPaymentScheduleId>;
  spendPaymentScheduleUpdateBySeason?: Maybe<
    Array<Maybe<SpendPaymentScheduleId>>
  >;
  spendRemoveParticipants?: Maybe<SpendRemoveSeasonMemberResponse>;
  spendRoleChange?: Maybe<SpendRoleId>;
  spendSeasonCreate?: Maybe<SpendSeasonId>;
  spendSeasonUpdate?: Maybe<SpendSeasonId>;
  spendSessionCreate?: Maybe<SpendSession>;
  spendSessionDelete?: Maybe<SpendSessionId>;
  spendSessionRefresh?: Maybe<SpendSessionId>;
  spendSettingsUpdate?: Maybe<SpendOrganizationId>;
  spendSignupAgreementCreate?: Maybe<SpendAgreementId>;
  spendTransactionAttachmentCreate?: Maybe<SpendTransactionAttachmentId>;
  spendTransactionAttachmentDelete?: Maybe<SpendTransactionAttachmentId>;
  spendTransactionBudgetUnreconcile?: Maybe<SpendReconcileTransactionId>;
  spendTransactionInvoiceUnreconcile?: Maybe<SpendReconcileTransactionId>;
  spendTransactionNoteCreate?: Maybe<SpendTransactionNoteId>;
  spendTransactionNoteUpdate?: Maybe<SpendTransactionNoteId>;
  /** @deprecated Use spendTransactionReconcileV2 */
  spendTransactionReconcile?: Maybe<SpendReconcileTransactionId>;
  spendTransactionReconcileV2?: Maybe<SpendReconcileTransactionId>;
  spendTransactionsCreate?: Maybe<SpendTransactionsIdList>;
  spendUnitApplication?: Maybe<SpendUnitApplicationResponse>;
  spendUserAchPayment?: Maybe<SpendAchPaymentResponse>;
  spendUserAchPaymentCancel?: Maybe<SpendAchPaymentId>;
  spendUserBankAccessTokenCreate?: Maybe<SpendBankAccessCreateResponse>;
  spendUserBankAccessTokenDelete?: Maybe<SpendBankAccessDeleteResponse>;
  spendUserEmailUpdate?: Maybe<SpendUserEmailUpdateResponse>;
  spendUserInviteArchive?: Maybe<SpendInviteId>;
  spendUserInviteCreate?: Maybe<SpendInviteId>;
  spendUserInviteDelete?: Maybe<SpendInviteId>;
  spendUserInviteDismiss?: Maybe<SpendDismissInviteResponse>;
  spendUserInviteResend?: Maybe<SpendInviteId>;
  spendUserInviteResendAll?: Maybe<SpendAllInviteIds>;
  spendUserInviteStatusUpdate?: Maybe<SpendInviteId>;
  spendUserInviteUpdate?: Maybe<SpendInviteId>;
  spendUserLeaveGroup?: Maybe<SpendUpdateResponse>;
  spendUserNotificationSettingUpdate?: Maybe<SpendUserNotificationSettingId>;
  spendUserPaymentsCardDetach?: Maybe<SpendPaymentMethodDetachResponse>;
  spendUserRoleArchive?: Maybe<SpendUserId>;
  spendUserRoleSet?: Maybe<SpendUserRoleId>;
  spendUserRoleUpdate?: Maybe<SpendUserRoleId>;
  spendUserRoleUpdateIsApprover?: Maybe<SpendUserRoleId>;
  spendUserSignUp?: Maybe<SpendSignUpResponse>;
  spendUserSignupUpdate?: Maybe<SpendUserRoleId>;
  storeBuildRequest?: Maybe<StoreBuildRequestUnionType>;
  storeEditInfo?: Maybe<StoreEditInfoUnionType>;
  storeManagerPointsUpdate?: Maybe<StoreManagerUpdatePointsUnionType>;
  storePaymentIntentCreate?: Maybe<StorePaymantIntentUnionType>;
  storePointsWithdrawalRequest?: Maybe<StorePointsWithdrawalRequestUnionType>;
  storeProfitPercentageUpdate?: Maybe<StoreProfitPercentageUpdated>;
  storeTicketCreate?: Maybe<StoreTicketsUnionType>;
  storeTransactionSave?: Maybe<StoreTransactionUnionType>;
  storeTransferToCustomer?: Maybe<StoreTransferPointsUnionType>;
  storeTransferToGL?: Maybe<StoreTransferPointsUnionType>;
  storeTransferToParticipant?: Maybe<StoreTransferPointsUnionType>;
  storeUpdatePayable?: Maybe<StorePayableInfoUnionType>;
  storeUpdateStatus?: Maybe<MagentoStoreUnionType>;
  storeUserPreferenceUpsert?: Maybe<UserPreference>;
  /** Submits submission draft to be reviewed by approver. Email will be sent to approver. */
  submitFundraiserApprovalSubmission: FundraiserApprovalSubmission;
  /** Send support-ticket email to the support team. */
  supportTicketSend?: Maybe<IMutationResult>;
  teamCreate?: Maybe<Team>;
  teamDelete?: Maybe<Team>;
  teamModify?: Maybe<Team>;
  teamOfficialCreate?: Maybe<TemplateOfficial>;
  teamOfficialDelete?: Maybe<TemplateOfficial>;
  teamOfficialModify?: Maybe<TemplateOfficial>;
  teamPreparationCreate?: Maybe<TemplatePreparation>;
  teamPreparationDelete?: Maybe<TemplatePreparation>;
  teamPreparationModify?: Maybe<TemplatePreparation>;
  teamWorkerCreate?: Maybe<TemplateWorker>;
  teamWorkerDelete?: Maybe<TemplateWorker>;
  teamWorkerModify?: Maybe<TemplateWorker>;
  toggleCardActivation: Scalars["String"];
  updateFundraiserUserTypeConfig?: Maybe<Scalars["Boolean"]>;
  updateParticipantCampaignConfiguration: ParticipantCampaignConfiguration;
  updateParticipantGroup?: Maybe<UpdatedParticipantGroupCount>;
  /** This will update the size of a Participant's OTK item */
  updateParticipantOtkSize: FundraiserUserIncentiveId;
  upgradeToManageUser?: Maybe<Scalars["String"]>;
  upgradeToWalletUser?: Maybe<User>;
  uploadLogoPNG?: Maybe<OrderResult>;
  /** Create a relationship between a User and a Program with a given activity under a given Organization. This will also create a User Node only if they exist in User Directory but not Orgs. */
  userActivityLeadsCreate: MutationResponse;
  /** Delete a relationship between a User and a Program with a given activity under a given Organization. */
  userActivityLeadsDelete: MutationResponse;
  /** Update a relationship between a User and a Program with a given activity under a given Organization. */
  userActivityLeadsUpdate: MutationResponse;
  userApiKeyCreate?: Maybe<ApiKey>;
  userApiKeyDelete: Scalars["String"];
  userApiKeyUpdate?: Maybe<ApiKey>;
  /** @deprecated Please use userAssociationCreate instead */
  userAssociate: MutationResponse;
  /** Create first_seen and last_seen properties for a User and their product specific Org association. */
  userAssociationAccess: MutationResponse;
  /** Associate a User with an Org and a specific product. This will also create a User Node only if they exist in User Directory but not Orgs. */
  userAssociationCreate: MutationResponse;
  /** Add a deleted_at property to a User and their product specific Org association. This will NOT delete the relationship. */
  userAssociationDelete: MutationResponse;
  userBulkInviteSend: UserOrgInvitationResult;
  userChallengeUpdate?: Maybe<UserChallenge>;
  userChildCreate?: Maybe<User>;
  userChildUnassign: Scalars["String"];
  userChildUpdate?: Maybe<User>;
  userCreate?: Maybe<User>;
  userEmailConfirm?: Maybe<Scalars["String"]>;
  userFittingAdd?: Maybe<UserFitting>;
  userImpersonate?: Maybe<Auth>;
  userImpersonateLogout?: Maybe<Auth>;
  /** If it is confirmed that a Group Leader is no longer affiliated with their former Org, mark their relationship with a campaign as 'no_longer_affiliated'. */
  userImpliedAffiliationDelete: MutationResponse;
  /** @deprecated use inviteCreate instead */
  userInvite: Scalars["String"];
  /** @deprecated use inviteAccept instead */
  userInviteAccept?: Maybe<UserInviteResponse>;
  userInviteSend?: Maybe<InviteSendResult>;
  /** Connect a User to an Org with a specific title. This will also create a User Node only if they exist in User Directory but not Orgs. */
  userOrgAffiliationCreate: MutationResponse;
  /** Add a deleted_at timestamp to a User's title specific Org affiliation. */
  userOrgAffiliationDelete: MutationResponse;
  /** Update the descripton, or isConfirmed on the affiliation between a User and an Org. If you want to change the title, you will need to delete the existing affiliation and create a new one. */
  userOrgAffiliationUpdate: MutationResponse;
  userPermissionsUpdate?: Maybe<UserPermissionsList>;
  userPhoneNumberChallengeConfirm?: Maybe<PhoneNumberChallengeConfirmation>;
  userPhoneNumberConfirm?: Maybe<Scalars["String"]>;
  userRefreshSession?: Maybe<Auth>;
  userResetPassword?: Maybe<UserResetPasswordResponseWithAvailableTransport>;
  userResetPasswordByAdmin?: Maybe<UserResetPasswordByAdminResponse>;
  userSignup?: Maybe<SignUpResponseWithAvailableTransport>;
  userSignupConfirm?: Maybe<Auth>;
  userSignupConfirmationResend: Array<TransportType>;
  /** Create a relationship between a User and a specific Team. This will also create a User Node only if they exist in User Directory but not Orgs. */
  userTeamLeadsCreate: MutationResponse;
  /** Add a deleted_at property to a title specific relationship between a User and Team. */
  userTeamLeadsDelete: MutationResponse;
  /** Update the isConfirmed boolean on a title specific relationship between a User and Team. */
  userTeamLeadsUpdate: MutationResponse;
  userUnassignParent?: Maybe<Scalars["String"]>;
  userUpdate?: Maybe<User>;
  userUpdatePassword?: Maybe<User>;
  vaultCreateKybKycSubmit: VaultFormMutationResponse;
  vaultCustomerCreate: Scalars["String"];
  vaultDocumentGatewayUpload?: Maybe<VaultMutationResponse>;
  vaultIncorrectKybSupportReport?: Maybe<VaultMutationResponse>;
  vaultKybCreate: Scalars["ID"];
  vaultKybUpdate: Scalars["ID"];
  vaultKycCreate: Scalars["ID"];
  vaultKycUpdate: Scalars["ID"];
  vaultOrgConfirmationSubmit: VaultFormMutationResponse;
  vaultSetupIntentCreate: Scalars["String"];
  vaultStripeConnectAccountBankAccountDefaultSet: VaultMutationResponse;
  vaultStripeConnectAccountBankAccountDelete: VaultMutationResponse;
  vaultStripeConnectAccountCreate: Scalars["String"];
  vaultStripeConnectAccountOwnersProvided: Scalars["String"];
  vaultStripeConnectAccountPaymentMethodAttach: VaultMutationResponse;
  vaultStripeConnectAccountPaymentMethodDefaultSet: VaultMutationResponse;
  vaultStripeConnectAccountPayoutFrequencySet: VaultMutationResponse;
  vaultStripeConnectAccountRemoveRepresentative?: Maybe<VaultMutationResponse>;
  vaultStripeConnectAccountUpdate: Scalars["String"];
  vaultStripePersonCreate: Scalars["String"];
  vaultStripePersonDelete: Scalars["String"];
  vaultStripePersonUpdate: Scalars["String"];
  vaultUpdateVaultKybAutomated?: Maybe<Scalars["String"]>;
  vehicleCreate?: Maybe<Vehicle>;
  vehicleDelete?: Maybe<Vehicle>;
  vehicleModify?: Maybe<Vehicle>;
  /** Create the log record with type WARNING */
  warning?: Maybe<Scalars["Boolean"]>;
  /** Deprecated: use cron-jobs instead */
  weeklyEventEmailScheduleSend?: Maybe<IMutationResult>;
  workerDeleteMany?: Maybe<WorkerDeleteManyCount>;
  workerPoolCreate?: Maybe<WorkerPool>;
  workerPoolDelete?: Maybe<Array<Maybe<WorkerPool>>>;
  workerPoolModify?: Maybe<WorkerPool>;
  workerUpsertMany?: Maybe<WorkerUpsertManyCount>;
  workersUpsert?: Maybe<Array<Maybe<WorkerPool>>>;
};

export type MutationAddParticipantGuardianDonationInviteEmailsV2Args = {
  emails?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export type MutationAddRevisionFundraiserApprovalSubmissionArgs = {
  revision: Scalars["NonEmptyString"];
  submissionId?: InputMaybe<Scalars["Int"]>;
  token?: InputMaybe<Scalars["String"]>;
};

export type MutationApproveFundraiserApprovalSubmissionArgs = {
  submissionId?: InputMaybe<Scalars["Int"]>;
  token?: InputMaybe<Scalars["String"]>;
};

export type MutationCampaignPersonListBulkCreateArgs = {
  emails: Array<Scalars["String"]>;
  fundraiserId: Scalars["Int"];
  participantUserId: Scalars["Int"];
};

export type MutationCampaignPersonListCreateArgs = {
  contextableId: Scalars["Int"];
  contextableType: Scalars["String"];
  email?: InputMaybe<Scalars["String"]>;
  participantUserId: Scalars["Int"];
  personListType: Scalars["String"];
  phone?: InputMaybe<Scalars["String"]>;
};

export type MutationCampaignPersonListEntryDeleteArgs = {
  personListEntryId: Scalars["Int"];
};

export type MutationCampaignSearchFilterCreateArgs = {
  filterCriteria?: InputMaybe<Scalars["JSON"]>;
  filterName?: InputMaybe<Scalars["String"]>;
};

export type MutationCampaignSearchFilterDeleteArgs = {
  id?: InputMaybe<Scalars["Int"]>;
};

export type MutationCampaignSmsInviteAddArgs = {
  fundraiserId: Scalars["Int"];
  participantUserId: Scalars["Int"];
  phoneNumbers: Array<Scalars["String"]>;
};

export type MutationCampaignSmsInviteDeleteArgs = {
  fundraiserId: Scalars["Int"];
  smsInviteId: Scalars["Int"];
};

export type MutationCommsRegisterArgs = {
  code: Scalars["String"];
  emailProvider?: InputMaybe<SupportedEmailProviders>;
  from?: InputMaybe<Scalars["String"]>;
  fromName?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["String"]>;
  mjml?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
  service?: InputMaybe<SnapService>;
  serviceUserId?: InputMaybe<Scalars["String"]>;
  smsProvider?: InputMaybe<SupportedSmsProviders>;
  subject?: InputMaybe<Scalars["String"]>;
  testData?: InputMaybe<Scalars["JSON"]>;
  text?: InputMaybe<Scalars["String"]>;
  transport?: InputMaybe<MessageTransportType>;
  type?: InputMaybe<MessageType>;
  unsubscribeGroupId?: InputMaybe<Scalars["Int"]>;
  unsubscribeGroups?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
};

export type MutationCommsSendArgs = {
  contacts: Array<InputMaybe<MessageContact>>;
  sendAt?: InputMaybe<Scalars["Date"]>;
  templateAttributes?: InputMaybe<Scalars["JSON"]>;
  templateId?: InputMaybe<Scalars["String"]>;
  templateName?: InputMaybe<Scalars["String"]>;
  workflowId?: InputMaybe<Scalars["String"]>;
};

export type MutationCommsTestArgs = {
  attributes?: InputMaybe<Scalars["JSON"]>;
  challenge?: InputMaybe<Scalars["Boolean"]>;
  mjml?: InputMaybe<Scalars["String"]>;
  templateId?: InputMaybe<Scalars["String"]>;
  text?: InputMaybe<Scalars["String"]>;
};

export type MutationCompleteFundraiserApprovalSubmissionDocumentUploadArgs = {
  approvalSubmissionId: Scalars["Int"];
  s3FileName: Scalars["String"];
};

export type MutationConfirmPasswordArgs = {
  email: Scalars["String"];
  newPassword?: InputMaybe<Scalars["String"]>;
  verificationCode: Scalars["String"];
};

export type MutationContactTemplateDeleteArgs = {
  id?: InputMaybe<Scalars["Int"]>;
};

export type MutationContactTemplateSaveArgs = {
  templateMessage?: InputMaybe<Scalars["String"]>;
  templateName?: InputMaybe<Scalars["String"]>;
  templateSubject?: InputMaybe<Scalars["String"]>;
};

export type MutationCreateCardArgs = {
  card: IssueCardInput;
  gateway?: InputMaybe<Financial_Gateway>;
  organizationId?: InputMaybe<Scalars["String"]>;
  raiseOrganizationId?: InputMaybe<Scalars["String"]>;
};

export type MutationCreateChildFundraiserParticipantArgs = {
  input: CreateChildFundraiserParticipantInput;
};

export type MutationCreateChildFundraiserParticipantV2Args = {
  input: CreateChildFundraiserParticipantInput;
};

export type MutationCreateFundraiserApprovalSubmissionArgs = {
  additionalNotes?: InputMaybe<Scalars["String"]>;
  campaignRaiseId?: InputMaybe<Scalars["Int"]>;
  customerSupportInfo?: InputMaybe<Scalars["String"]>;
  duration?: InputMaybe<Scalars["String"]>;
  estimatedTotalRaisedCents?: InputMaybe<Scalars["Int"]>;
  firstName?: InputMaybe<Scalars["String"]>;
  formType?: InputMaybe<FundraiserApprovalSubmissionFormType>;
  fundraiserApprovalSubmissionDocs?: InputMaybe<
    Array<InputMaybe<FundraiserApprovalSubmissionDocumentInput>>
  >;
  fundraiserApprovers?: InputMaybe<Array<InputMaybe<FundraiserApproverInput>>>;
  fundraiserServiceWebsite?: InputMaybe<Scalars["String"]>;
  groupSize?: InputMaybe<Scalars["Int"]>;
  howThisWorks?: InputMaybe<Scalars["String"]>;
  lastName?: InputMaybe<Scalars["String"]>;
  newFundraiser?: InputMaybe<Scalars["Boolean"]>;
  organizationId?: InputMaybe<Scalars["String"]>;
  pricingAndFees?: InputMaybe<Scalars["String"]>;
  programId?: InputMaybe<Scalars["String"]>;
  proposedStartDate?: InputMaybe<Scalars["DateTime"]>;
  reason?: InputMaybe<Scalars["String"]>;
  revisionRequest?: InputMaybe<Scalars["String"]>;
  safety?: InputMaybe<Scalars["String"]>;
  submitterId: Scalars["NonEmptyString"];
};

export type MutationCreateFundraiserUserArgs = {
  firstName: Scalars["String"];
  lastName: Scalars["String"];
  phoneNumber?: InputMaybe<Scalars["String"]>;
};

export type MutationCreateOtkParticipantEntryArgs = {
  fundraiserId: Scalars["Int"];
  incentiveId: Scalars["Int"];
  size: Scalars["String"];
};

export type MutationCreateProductArgs = {
  product?: InputMaybe<ProductInput>;
};

export type MutationCreateStoreOrderArgs = {
  order?: InputMaybe<OrderInput>;
};

export type MutationDebugArgs = {
  payload: Scalars["JSON"];
  service: AuditLogService;
  source: AuditLogSource;
};

export type MutationDeleteFundraiserApprovalSubmissionDocumentArgs = {
  approvalSubmissionId: Scalars["Int"];
  documentKey: Scalars["String"];
};

export type MutationDonorDonateIntentCreateArgs = {
  input: DonorDonateIntentCreateInput;
};

export type MutationDonorDonateIntentMarkProcessingArgs = {
  data?: InputMaybe<DonorDonateIntentUpdateInput>;
  id: Scalars["ID"];
};

export type MutationDonorDonateIntentUpdateArgs = {
  id: Scalars["ID"];
  input: DonorDonateIntentUpdateInput;
};

export type MutationDonorsAdhocGiftShopIntentAddArgs = {
  intentInput: AdhocGiftShopIntentInput;
  item: PurchaseItemInput;
};

export type MutationDonorsDonationCreateOfflineArgs = {
  offlineDonationData: DonorDonationOfflineInput;
};

export type MutationDonorsDonationDeleteArgs = {
  id: Scalars["ID"];
};

export type MutationDonorsDonationUpdateArgs = {
  data: DonorDonationUpdateInput;
  id: Scalars["ID"];
};

export type MutationDonorsFundraiserPayableRefundArgs = {
  input: DonorFundraiserPayableRefundInput;
};

export type MutationDonorsGiftShopIntentAddArgs = {
  intentInput: GiftShopIntentInput;
  item: PurchaseItemInput;
};

export type MutationDonorsGiftShopIntentAddManyArgs = {
  intentInput: GiftShopIntentInput;
  items: Array<PurchaseItemInput>;
};

export type MutationDonorsGiftShopIntentMarkProcessingArgs = {
  purchaseIntentId: Scalars["String"];
};

export type MutationDonorsGiftShopIntentRemoveArgs = {
  incentiveId: Scalars["Int"];
  purchaseIntentId: Scalars["String"];
};

export type MutationDonorsGiftShopIntentUpdateArgs = {
  item: PurchaseItemInput;
  purchaseIntentId: Scalars["String"];
  status: PurchaseIntentStatus;
};

export type MutationDonorsGiftShopIntentUpdateIntentArgs = {
  intentInput: GiftShopIntentUpdateInput;
  purchaseIntentId: Scalars["String"];
};

export type MutationDonorsGiftShopPurchaseUpdateArgs = {
  data: PurchaseUpdateInput;
  purchaseId: Scalars["String"];
};

export type MutationDonorsReceiptsResendArgs = {
  transactions: Array<TransactionInput>;
};

export type MutationDriveAddHubspotProspectPinArgs = {
  dealId?: InputMaybe<Scalars["String"]>;
  hubspotOrgId?: InputMaybe<Scalars["String"]>;
};

export type MutationDriveAddWinbackPinArgs = {
  dealId?: InputMaybe<Scalars["String"]>;
  hubspotOrgId?: InputMaybe<Scalars["String"]>;
};

export type MutationDriveCampaignFinalizeArgs = {
  fundraiserId: Scalars["Int"];
};

export type MutationDriveCampaignSettleArgs = {
  fundraiserIds?: InputMaybe<Array<Scalars["Int"]>>;
};

export type MutationDriveCampaignUpdateArgs = {
  data: DriveCampaignUpdateInput;
  id: Scalars["Int"];
};

export type MutationDriveDisableActivityArgs = {
  dealId?: InputMaybe<Scalars["String"]>;
  hubspotOrgId?: InputMaybe<Scalars["String"]>;
};

export type MutationDriveDisableOrgActivityArgs = {
  activityId?: InputMaybe<Scalars["String"]>;
  activityType?: InputMaybe<Scalars["String"]>;
  campaignsCount?: InputMaybe<Scalars["Int"]>;
  curricularType?: InputMaybe<Scalars["String"]>;
  hubspotOrgId?: InputMaybe<Scalars["String"]>;
};

export type MutationDriveEnableActivityArgs = {
  dealId?: InputMaybe<Scalars["String"]>;
  hubspotOrgId?: InputMaybe<Scalars["String"]>;
};

export type MutationDriveEnableOrgActivityArgs = {
  activityId?: InputMaybe<Scalars["String"]>;
  hubspotOrgId?: InputMaybe<Scalars["String"]>;
};

export type MutationDriveEventActivityCreateArgs = {
  eventId: Scalars["String"];
  input?: InputMaybe<DriveEventActivityInput>;
};

export type MutationDriveEventActivityRemoveArgs = {
  id: Scalars["ID"];
};

export type MutationDriveEventActivityUpdateArgs = {
  id: Scalars["ID"];
  input: DriveEventActivityInput;
};

export type MutationDriveEventAgendaCreateArgs = {
  eventId: Scalars["ID"];
  input: DriveEventAgendaInput;
};

export type MutationDriveEventAttendeeAddArgs = {
  eventId: Scalars["ID"];
  input: DriveEventAttendeeInput;
  update?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationDriveEventAttendeeUpdateArgs = {
  eventId: Scalars["ID"];
  input: DriveEventAttendeeInput;
  userId: Scalars["ID"];
};

export type MutationDriveEventAttendeesRemoveArgs = {
  eventId: Scalars["ID"];
  userIds: Array<Scalars["String"]>;
};

export type MutationDriveEventCreateArgs = {
  input: DriveEventInput;
};

export type MutationDriveEventRemoveArgs = {
  id: Scalars["ID"];
};

export type MutationDriveEventUpdateArgs = {
  id: Scalars["ID"];
  input: DriveEventInput;
};

export type MutationDriveOrgUserTrackingSetArgs = {
  input?: InputMaybe<DriveTrackingInput>;
  orgId: Scalars["String"];
};

export type MutationDrivePreloadSmsScheduleCancelArgs = {
  fundraiserId: Scalars["Int"];
};

export type MutationDrivePreloadSmsSendArgs = {
  fundraiserId: Scalars["Int"];
  schedule?: InputMaybe<DriveScheduleInput>;
};

export type MutationDrivePreloadSmsSendScheduleUpdateArgs = {
  fundraiserId: Scalars["Int"];
  schedule: DriveScheduleUpdateInput;
};

export type MutationDriveRemoveHubspotProspectPinArgs = {
  dealId?: InputMaybe<Scalars["String"]>;
};

export type MutationDriveRemoveWinbackPinArgs = {
  dealId?: InputMaybe<Scalars["String"]>;
};

export type MutationDriveS3ImageResizeArgs = {
  fileUploaded?: InputMaybe<Scalars["String"]>;
};

export type MutationDriveShareProjectedRaisedResultsArgs = {
  activityType: Scalars["String"];
  earlyAccessApprovedFundsMax: Scalars["Int"];
  earlyAccessApprovedFundsMin: Scalars["Int"];
  location: Scalars["String"];
  participantCount: Scalars["Int"];
  projectedRaisedMax: Scalars["Int"];
  projectedRaisedMin: Scalars["Int"];
  to: Scalars["String"];
};

export type MutationDriveUploadUrlCreateArgs = {
  fileName: Scalars["String"];
};

export type MutationEditFundraiserApprovalSubmissionArgs = {
  additionalNotes?: InputMaybe<Scalars["String"]>;
  campaignRaiseId?: InputMaybe<Scalars["Int"]>;
  customerSupportInfo?: InputMaybe<Scalars["String"]>;
  duration?: InputMaybe<Scalars["String"]>;
  estimatedTotalRaisedCents?: InputMaybe<Scalars["Int"]>;
  firstName?: InputMaybe<Scalars["String"]>;
  formType?: InputMaybe<FundraiserApprovalSubmissionFormType>;
  fundraiserApprovalSubmissionDocs: Array<
    InputMaybe<FundraiserApprovalSubmissionDocumentInput>
  >;
  fundraiserApprovers: Array<InputMaybe<FundraiserApproverInput>>;
  fundraiserServiceWebsite?: InputMaybe<Scalars["String"]>;
  groupSize?: InputMaybe<Scalars["Int"]>;
  howThisWorks?: InputMaybe<Scalars["String"]>;
  id: Scalars["Int"];
  lastName?: InputMaybe<Scalars["String"]>;
  newFundraiser?: InputMaybe<Scalars["Boolean"]>;
  organizationId?: InputMaybe<Scalars["String"]>;
  pricingAndFees?: InputMaybe<Scalars["String"]>;
  programId?: InputMaybe<Scalars["String"]>;
  proposedStartDate?: InputMaybe<Scalars["DateTime"]>;
  reason?: InputMaybe<Scalars["String"]>;
  revisionRequest?: InputMaybe<Scalars["String"]>;
  safety?: InputMaybe<Scalars["String"]>;
  status?: InputMaybe<FundraiserApprovalSubmissionStatus>;
};

export type MutationErrorArgs = {
  payload: Scalars["JSON"];
  service: AuditLogService;
  source: AuditLogSource;
};

export type MutationEventCreateArgs = {
  input?: InputMaybe<CreateEventInput>;
};

export type MutationEventDeleteArgs = {
  input?: InputMaybe<DeleteEventInput>;
};

export type MutationEventModifyArgs = {
  input?: InputMaybe<ModifyEventInput>;
};

export type MutationEventParticipantsDeleteManyArgs = {
  input?: InputMaybe<DeleteManyEventParticipantsInput>;
};

export type MutationEventParticipantsUpsertArgs = {
  input?: InputMaybe<UpsertEventParticipantsInput>;
};

export type MutationEventPreparationCreateArgs = {
  input?: InputMaybe<CreateEventPreparationInput>;
};

export type MutationEventPreparationDeleteArgs = {
  input?: InputMaybe<DeleteEventPreparationInput>;
};

export type MutationEventPreparationModifyArgs = {
  input?: InputMaybe<ModifyEventPreparationInput>;
};

export type MutationEventPreparationsCreateManyArgs = {
  input?: InputMaybe<Array<InputMaybe<EventPreparationsInput>>>;
};

export type MutationEventPreparationsDeleteManyArgs = {
  input?: InputMaybe<DeleteManyEventPreparationsInput>;
};

export type MutationEventPreparationsModifyManyArgs = {
  input?: InputMaybe<ModifyManyEventPreparationsByEventInput>;
};

export type MutationEventPreparationsUpsertArgs = {
  input?: InputMaybe<UpsertEventPreparationsInput>;
};

export type MutationEventTransportDetailsDeleteManyArgs = {
  input?: InputMaybe<DeleteManyEventTransportDetailsInput>;
};

export type MutationEventTransportDetailsUpsertManyArgs = {
  input?: InputMaybe<UpsertEventTransportDetailsInput>;
};

export type MutationFacilitiesUpsertArgs = {
  input?: InputMaybe<Array<InputMaybe<UpsertFacilitiesInput>>>;
};

export type MutationFacilityCreateArgs = {
  input?: InputMaybe<CreateFacilityInput>;
};

export type MutationFacilityDeleteArgs = {
  input?: InputMaybe<DeleteFacilityInput>;
};

export type MutationFacilityModifyArgs = {
  input?: InputMaybe<ModifyFacilityInput>;
};

export type MutationFinAcctUserAssociationCreateArgs = {
  finAcctId: Scalars["ID"];
  isBeneficialOwner?: InputMaybe<Scalars["Boolean"]>;
  isContact?: InputMaybe<Scalars["Boolean"]>;
  isPrincipal?: InputMaybe<Scalars["Boolean"]>;
  isRepresentative?: InputMaybe<Scalars["Boolean"]>;
  kycId?: InputMaybe<Scalars["ID"]>;
  personId?: InputMaybe<Scalars["ID"]>;
  userId: Scalars["ID"];
};

export type MutationFinAcctUserAssociationDeleteArgs = {
  finAcctId: Scalars["ID"];
  userId: Scalars["ID"];
};

export type MutationFinAcctUserAssociationUpdateArgs = {
  finAcctId: Scalars["ID"];
  isBeneficialOwner?: InputMaybe<Scalars["Boolean"]>;
  isContact?: InputMaybe<Scalars["Boolean"]>;
  isPrincipal?: InputMaybe<Scalars["Boolean"]>;
  isRepresentative?: InputMaybe<Scalars["Boolean"]>;
  kycId?: InputMaybe<Scalars["ID"]>;
  personId?: InputMaybe<Scalars["ID"]>;
  userId: Scalars["ID"];
};

export type MutationFinancialAcctCreateArgs = {
  acctId?: InputMaybe<Scalars["ID"]>;
  applicationId?: InputMaybe<Scalars["ID"]>;
  customerId?: InputMaybe<Scalars["ID"]>;
  kybId: Scalars["ID"];
  orgId: Scalars["ID"];
  processor: Processor;
  status?: FinAcctStatus;
  stripeEnv?: FinAcctStripeEnv;
};

export type MutationFinancialAcctDeleteArgs = {
  finAcctId: Scalars["ID"];
};

export type MutationFinancialAcctUpdateArgs = {
  acctId?: InputMaybe<Scalars["ID"]>;
  applicationId?: InputMaybe<Scalars["ID"]>;
  city?: InputMaybe<Scalars["String"]>;
  country?: InputMaybe<Scalars["String"]>;
  customerId?: InputMaybe<Scalars["ID"]>;
  finAcctId: Scalars["ID"];
  kybId?: InputMaybe<Scalars["ID"]>;
  orgId: Scalars["ID"];
  paymentMethodId?: InputMaybe<Scalars["ID"]>;
  recipientName?: InputMaybe<Scalars["String"]>;
  settlementMethod?: InputMaybe<SettlementMethod>;
  stateCode?: InputMaybe<StateCode>;
  status?: InputMaybe<FinAcctStatus>;
  street?: InputMaybe<Scalars["String"]>;
  zipCode?: InputMaybe<Scalars["String"]>;
};

export type MutationFundraiserStoreUrlUpdateArgs = {
  fundraiserId: Scalars["Int"];
  storeUrl?: InputMaybe<Scalars["String"]>;
};

export type MutationGroupLeaderEmailSendArgs = {
  ccEmails?: InputMaybe<Array<Scalars["String"]>>;
  fundraiserId: Scalars["Int"];
  groupLeaderEmail: Scalars["String"];
  message: Scalars["String"];
  subject: Scalars["String"];
};

export type MutationGroupLeaderFundraiserAddressUpdateArgs = {
  addressType: FundraiserAddressType;
  fundraiserId: Scalars["ID"];
  input: GroupLeaderFundraiserAddressInput;
};

export type MutationGroupLeaderFundraiserDonationLevelsUpdateArgs = {
  fundraiserId: Scalars["ID"];
  input: Array<GroupLeaderDonationLevelsInput>;
};

export type MutationGroupLeaderFundraiserMediaGalleryUpdateArgs = {
  fundraiserId: Scalars["ID"];
  input: GroupLeaderFundraiserMediaGalleryUpdateInput;
};

export type MutationGroupLeaderFundraiserUpdateArgs = {
  fundraiserId: Scalars["ID"];
  input: GroupLeaderEditFundraiserInput;
};

export type MutationHelpDocumentDeleteArgs = {
  input: HelpDocumentDeleteInput;
};

export type MutationHelpDocumentUploadArgs = {
  input: HelpDocumentUploadInput;
};

export type MutationHubspotCallEngagementToDealAddArgs = {
  fundraiserId: Scalars["Int"];
};

export type MutationHubspotNoteEngagementToDealAddArgs = {
  fundraiserId: Scalars["Int"];
};

export type MutationInfoArgs = {
  payload: Scalars["JSON"];
  service: AuditLogService;
  source: AuditLogSource;
};

export type MutationInsightsAddPreApprovedContactsArgs = {
  approver: InsAddPreApprovedContactApproverInput;
  contacts: Array<InputMaybe<InsPreApprovedContactInput>>;
  dashboardUrl: Scalars["String"];
  org: InsAddPreApprovedContactOrgInput;
};

export type MutationInsightsDeletePreApprovedContactArgs = {
  contactId: Scalars["Int"];
  userId: Scalars["String"];
};

export type MutationInsightsEditPreApprovedContactArgs = {
  contactId: Scalars["Int"];
  orgId: Scalars["String"];
  senderName: Scalars["String"];
  updatedContact: InsEditPreApprovedContactInput;
};

export type MutationInsightsEditPreApprovedContactInviteArgs = {
  token?: InputMaybe<Scalars["String"]>;
};

export type MutationInsightsResendInvitePreApprovedContactArgs = {
  contactId: Scalars["Int"];
  senderName: Scalars["String"];
};

export type MutationInsightsSetNotificationPreferencesArgs = {
  preferences: Array<InputMaybe<InsSetNotificationPreferences>>;
  userId: Scalars["String"];
};

export type MutationInsightsSetUserPreferencesArgs = {
  campaignIds?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  orgIds?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  setTo: SetUserPreferenceFlag;
  userId: Scalars["String"];
};

export type MutationInviteAcceptArgs = {
  inviteId?: InputMaybe<Scalars["String"]>;
};

export type MutationInviteCreateArgs = {
  email: Scalars["String"];
  parent?: InputMaybe<InviteParentArguments>;
  sendEmail?: InputMaybe<Scalars["Boolean"]>;
  type?: InputMaybe<InviteType>;
  user?: InputMaybe<InviteUserArguments>;
};

export type MutationInviteDeleteArgs = {
  inviteId?: InputMaybe<Scalars["String"]>;
};

export type MutationJoinFundraiserUserArgs = {
  joinCode: Scalars["String"];
  joinType?: InputMaybe<JoinType>;
  role: CampaignMemberAssociation;
};

export type MutationJoinFundraiserUserByIdArgs = {
  fundraiserId: Scalars["Int"];
  importType?: InputMaybe<FundraiserUserImportType>;
  role: CampaignMemberAssociation;
  ssoId: Scalars["String"];
  user: JoinFundraiserUserInput;
};

export type MutationLatestVaultCardCreateArgs = {
  altName?: InputMaybe<Scalars["String"]>;
  attachCardToKybBalance: Scalars["Boolean"];
  cardType: VaultCardType;
  kybId: Scalars["ID"];
  metadata?: InputMaybe<VaultCardMetadataInput>;
  recipientName?: InputMaybe<Scalars["String"]>;
  shippingAddress?: InputMaybe<VaultAddressInput>;
  shippingService?: InputMaybe<VaultShippingService>;
  spendingLimitAmount: Scalars["Int"];
  spendingLimitInterval: VaultSpendingLimitInterval;
};

export type MutationLevelCreateArgs = {
  input?: InputMaybe<CreateLevelInput>;
};

export type MutationLevelDeleteArgs = {
  input?: InputMaybe<DeleteLevelInput>;
};

export type MutationLevelModifyArgs = {
  input?: InputMaybe<ModifyLevelInput>;
};

export type MutationLogArgs = {
  payload: Scalars["JSON"];
  service: AuditLogService;
  source: AuditLogSource;
  type: AuditLogType;
};

export type MutationLoginArgs = {
  consumer?: InputMaybe<Scalars["String"]>;
  email: Scalars["String"];
  password: Scalars["String"];
};

export type MutationLogoutArgs = {
  consumer?: InputMaybe<Scalars["String"]>;
};

export type MutationManageUserCreateArgs = {
  input?: InputMaybe<ManageUserCreateInput>;
};

export type MutationManageUserDeleteArgs = {
  input?: InputMaybe<ManageUserDeleteInput>;
};

export type MutationManageUserUpdateArgs = {
  input?: InputMaybe<ManageUserUpdateInput>;
};

export type MutationMarcoWebhookArgs = {
  status: MarcoEventInput;
};

export type MutationMessagesChannelAddModeratorsArgs = {
  id: Scalars["String"];
  moderatorIds: Array<Scalars["String"]>;
};

export type MutationMessagesChannelAddUsersArgs = {
  id: Scalars["String"];
  userIds: Array<Scalars["String"]>;
};

export type MutationMessagesChannelCreateArgs = {
  assignmentId?: InputMaybe<Scalars["String"]>;
  description?: InputMaybe<Scalars["String"]>;
  message?: InputMaybe<Scalars["String"]>;
  moderatorIds?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  name: Scalars["String"];
  type: ChannelTypesEnum;
  userIds: Array<InputMaybe<Scalars["String"]>>;
};

export type MutationMessagesChannelRemoveMembersArgs = {
  id: Scalars["String"];
  userIds: Array<Scalars["String"]>;
};

export type MutationMessagesChannelUpdateArgs = {
  channel?: InputMaybe<ChannelUpdateInput>;
  id: Scalars["String"];
};

export type MutationMessagesChannelUpdateMembersArgs = {
  assignment?: InputMaybe<AssignmentInput>;
  id?: InputMaybe<Scalars["String"]>;
  moderatorIds?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  userIds?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export type MutationMessagesUpsertUsersArgs = {
  userIds: Array<Scalars["String"]>;
};

export type MutationNetsuiteWebhookArgs = {
  netsuiteEvent?: InputMaybe<NetsuiteEventInput>;
};

export type MutationNoticeCreateArgs = {
  input?: InputMaybe<NoticeCreateInput>;
};

export type MutationNoticeDeleteArgs = {
  input?: InputMaybe<NoticeDeleteInput>;
};

export type MutationNoticeModifyArgs = {
  input?: InputMaybe<NoticeModifyInput>;
};

export type MutationOfficialCreateArgs = {
  input?: InputMaybe<CreateOfficialInput>;
};

export type MutationOfficialDeleteArgs = {
  input?: InputMaybe<DeleteOfficialInput>;
};

export type MutationOfficialDutiesDeleteArgs = {
  input?: InputMaybe<DeleteOfficialDutiesInput>;
};

export type MutationOfficialDutiesUpsertArgs = {
  input?: InputMaybe<Array<InputMaybe<UpsertOfficialDutiesInput>>>;
};

export type MutationOfficialDutyCreateArgs = {
  input?: InputMaybe<CreateOfficialDutyInput>;
};

export type MutationOfficialDutyModifyArgs = {
  input?: InputMaybe<ModifyOfficialDutyInput>;
};

export type MutationOfficialModifyArgs = {
  input?: InputMaybe<ModifyOfficialInput>;
};

export type MutationOfficialPoolCreateArgs = {
  input?: InputMaybe<CreateOfficialPoolByIdInput>;
};

export type MutationOfficialPoolDeleteArgs = {
  input?: InputMaybe<DeleteOfficialPoolInput>;
};

export type MutationOfficialPoolModifyArgs = {
  input?: InputMaybe<ModifyOfficialPoolByIdInput>;
};

export type MutationOfficialsUpsertArgs = {
  input?: InputMaybe<Array<InputMaybe<UpsertOfficialsInput>>>;
};

export type MutationOpponentCreateArgs = {
  input?: InputMaybe<CreateOpponentInput>;
};

export type MutationOpponentDeleteArgs = {
  input?: InputMaybe<DeleteOpponentInput>;
};

export type MutationOpponentModifyArgs = {
  input?: InputMaybe<ModifyOpponentInput>;
};

export type MutationOpponentsUpsertArgs = {
  input?: InputMaybe<Array<InputMaybe<UpsertOpponentsInput>>>;
};

export type MutationOrgAffiliateCreateArgs = {
  city: Scalars["String"];
  description?: InputMaybe<Scalars["String"]>;
  einNumber: Scalars["String"];
  formationYear?: InputMaybe<Scalars["Int"]>;
  hubspotId?: InputMaybe<Scalars["String"]>;
  legalName: Scalars["String"];
  name: Scalars["String"];
  parentOrgId?: InputMaybe<Scalars["ID"]>;
  phoneNumber?: InputMaybe<Scalars["String"]>;
  stateCode: StateCode;
  streetAddress: Scalars["String"];
  type: OrgType;
  url?: InputMaybe<Scalars["String"]>;
  zipCode: Scalars["String"];
};

export type MutationOrgClubCreateArgs = {
  city: Scalars["String"];
  description?: InputMaybe<Scalars["String"]>;
  einNumber: Scalars["String"];
  formationYear?: InputMaybe<Scalars["Int"]>;
  hubspotId?: InputMaybe<Scalars["String"]>;
  legalName: Scalars["String"];
  name: Scalars["String"];
  phoneNumber?: InputMaybe<Scalars["String"]>;
  stateCode: StateCode;
  streetAddress: Scalars["String"];
  url?: InputMaybe<Scalars["String"]>;
  zipCode: Scalars["String"];
};

export type MutationOrgDistrictCreateArgs = {
  city: Scalars["String"];
  description?: InputMaybe<Scalars["String"]>;
  einNumber: Scalars["String"];
  formationYear?: InputMaybe<Scalars["Int"]>;
  hubspotId?: InputMaybe<Scalars["String"]>;
  legalName: Scalars["String"];
  name: Scalars["String"];
  phoneNumber?: InputMaybe<Scalars["String"]>;
  stateCode: StateCode;
  streetAddress: Scalars["String"];
  url?: InputMaybe<Scalars["String"]>;
  zipCode: Scalars["String"];
};

export type MutationOrgLabelUpdateArgs = {
  auxiliary_type?: InputMaybe<ModType>;
  label: OrgType;
  modId: Scalars["ID"];
  parentModId?: InputMaybe<Scalars["ID"]>;
};

export type MutationOrgProgramCreateArgs = {
  activity: ProgramActivity;
  orgId: Scalars["ID"];
};

export type MutationOrgSchoolCreateArgs = {
  city: Scalars["String"];
  description?: InputMaybe<Scalars["String"]>;
  einNumber: Scalars["String"];
  formationYear?: InputMaybe<Scalars["Int"]>;
  hubspotId?: InputMaybe<Scalars["String"]>;
  legalName: Scalars["String"];
  name: Scalars["String"];
  ncesId?: InputMaybe<Scalars["String"]>;
  parentDistrictId?: InputMaybe<Scalars["ID"]>;
  phoneNumber?: InputMaybe<Scalars["String"]>;
  stateCode: StateCode;
  streetAddress: Scalars["String"];
  url?: InputMaybe<Scalars["String"]>;
  zipCode: Scalars["String"];
};

export type MutationOrgTeamCreateArgs = {
  activity: ProgramActivity;
  name: Scalars["String"];
  orgId: Scalars["ID"];
  teamAge: TeamAge;
  teamGender: TeamGender;
  teamGroupType: TeamGroupType;
  teamSize?: InputMaybe<Scalars["Int"]>;
  teamTier: TeamTier;
};

export type MutationParticipantDonationInviteEditArgs = {
  campaignID: Scalars["String"];
  donationInviteID: Scalars["String"];
  newEmail: Scalars["String"];
};

export type MutationParticipantDonationInviteEmailAddArgs = {
  campaignID: Scalars["String"];
  email: Scalars["String"];
};

export type MutationParticipantDonationInviteRemoveArgs = {
  email: Scalars["String"];
  fundraiserId: Scalars["String"];
  personListEntryId: Scalars["String"];
};

export type MutationParticipantDonationInviteSmsAddArgs = {
  campaignID: Scalars["String"];
  phoneNumber: Scalars["String"];
};

export type MutationParticipantGuardianEmailUpdateArgs = {
  guardianEmail: Scalars["String"];
  secondGuardianEmail?: InputMaybe<Scalars["String"]>;
};

export type MutationParticipantIncentiveSelectionDeleteArgs = {
  fundraiserId: Scalars["ID"];
  fundraiserUserIncentiveId: Scalars["ID"];
};

export type MutationParticipantRewardsBulkCreateArgs = {
  fundraiserId: Scalars["Int"];
  rewards: Array<ParticipantRewardsInput>;
};

export type MutationParticipantRewardsCreateArgs = {
  fundraiserId: Scalars["Int"];
  incentiveId: Scalars["Int"];
  size: Scalars["String"];
  tier: Scalars["Int"];
};

export type MutationParticipantSignupArgs = {
  apps?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  email: Scalars["String"];
  password: Scalars["String"];
  phoneNumber: Scalars["String"];
};

export type MutationParticipantsEmailSendArgs = {
  fundraiserId: Scalars["Int"];
  message: Scalars["String"];
  subject: Scalars["String"];
};

export type MutationParticipantsTextSendArgs = {
  fundraiserId: Scalars["Int"];
  message: Scalars["String"];
};

export type MutationPaymentsApiCustomerGetOrCreateArgs = {
  stripeEnv?: InputMaybe<StripeEnv>;
};

export type MutationPaymentsApiDetachPaymentMethodArgs = {
  input?: InputMaybe<PaymentsApiDetachPaymentMethodInput>;
};

export type MutationPaymentsApiPaymentArgs = {
  input?: InputMaybe<PaymentsApiPaymentIput>;
};

export type MutationPaymentsApiRefundArgs = {
  input?: InputMaybe<PaymentsApiRefundInput>;
};

export type MutationPreloadEmailRescheduleSendArgs = {
  dateTime?: InputMaybe<Scalars["DateTime"]>;
  fundraiserId: Scalars["Int"];
  offsetInSeconds?: InputMaybe<Scalars["Int"]>;
  timezone?: InputMaybe<Scalars["String"]>;
};

export type MutationPreloadEmailScheduleCancelArgs = {
  fundraiserId: Scalars["Int"];
};

export type MutationPreloadEmailScheduleSendArgs = {
  dateTime: Scalars["DateTime"];
  fundraiserId: Scalars["Int"];
  timezone: Scalars["String"];
};

export type MutationPreloadEmailSendArgs = {
  fundraiserId: Scalars["Int"];
};

export type MutationPreparationCreateArgs = {
  input?: InputMaybe<CreatePreparationInput>;
};

export type MutationPreparationDeleteArgs = {
  input?: InputMaybe<DeletePreparationInput>;
};

export type MutationPreparationModifyArgs = {
  input?: InputMaybe<ModifyPreparationInput>;
};

export type MutationRaiseAdminFundraiserUsersCreateManyArgs = {
  input: RaiseFundraiserUsersCreateManyInput;
};

export type MutationRoleCreateArgs = {
  data: RoleArguments;
};

export type MutationRoleDeleteArgs = {
  id: Scalars["String"];
};

export type MutationRolePermissionsUpdateArgs = {
  permissionIds: Array<Scalars["String"]>;
  roleId: Scalars["String"];
};

export type MutationRoleUpdateArgs = {
  data: RoleArguments;
  id: Scalars["String"];
};

export type MutationS3PresignedInsightsApprovalsUrlArgs = {
  approvalsFormId: Scalars["Int"];
  approvalsSubmissionId: Scalars["Int"];
  files: Array<InputMaybe<Scalars["NonEmptyString"]>>;
};

export type MutationSamlCallbackArgs = {
  actualEmail?: InputMaybe<Scalars["String"]>;
  apps?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  consumer?: InputMaybe<Scalars["String"]>;
  email?: InputMaybe<Scalars["String"]>;
  firstName?: InputMaybe<Scalars["String"]>;
  lastName?: InputMaybe<Scalars["String"]>;
};

export type MutationSchoolInfoCreateArgs = {
  input?: InputMaybe<CreateSchoolInfoInput>;
};

export type MutationSchoolInfoDeleteArgs = {
  input?: InputMaybe<DeleteSchoolInfoInput>;
};

export type MutationSchoolInfoModifyArgs = {
  input?: InputMaybe<ModifySchoolInfoInput>;
};

export type MutationSeasonCreateArgs = {
  input?: InputMaybe<SeasonCreateInput>;
};

export type MutationSeasonDeleteArgs = {
  input?: InputMaybe<SeasonDeleteInput>;
};

export type MutationSeasonModifyArgs = {
  input?: InputMaybe<SeasonModifyInput>;
};

export type MutationSeasonPostponeModifyArgs = {
  input?: InputMaybe<SeasonPostponeUnpostponeInput>;
};

export type MutationSeasonScheduleTeamsCreateArgs = {
  input?: InputMaybe<SeasonScheduleTeamsCreateInput>;
};

export type MutationSeasonUnpostponeModifyArgs = {
  input?: InputMaybe<SeasonPostponeUnpostponeInput>;
};

export type MutationSetUserInsightsConfigurationsArgs = {
  config: Array<InputMaybe<UserInsightsConfigInput>>;
};

export type MutationSettlementIssuesRecordArgs = {
  fundraiserId: Scalars["Int"];
  note?: InputMaybe<Scalars["String"]>;
};

export type MutationSettlementStatusUpdateArgs = {
  fundraiserId: Scalars["Int"];
  settlementStatus?: InputMaybe<SettlementStatus>;
};

export type MutationSpendArchiveSeasonMembersArgs = {
  input: SpendArchiveSeasonMembersInput;
};

export type MutationSpendAuthorizeAutoPayArgs = {
  input?: InputMaybe<SpendAutoPayInput>;
};

export type MutationSpendBudgetCreateArgs = {
  input: SpendBudgetInput;
};

export type MutationSpendBudgetDeleteArgs = {
  id: Scalars["String"];
};

export type MutationSpendBudgetUpdateArgs = {
  id: Scalars["String"];
  input: SpendBudgetInput;
};

export type MutationSpendCategoryCreateArgs = {
  input: SpendCategoryInput;
};

export type MutationSpendCategoryUpdateArgs = {
  id: Scalars["String"];
  input: SpendCategoryInput;
};

export type MutationSpendCategoryUpsertBulkArgs = {
  input?: InputMaybe<Array<InputMaybe<SpendUpsertCategoryInput>>>;
};

export type MutationSpendCreditMemoCreateArgs = {
  input: SpendCreditMemoInput;
};

export type MutationSpendCreditMemoUpdateArgs = {
  input: SpendCreditMemoUpdateInput;
};

export type MutationSpendGroupAchCreditArgs = {
  input?: InputMaybe<SpendGroupAchCredit>;
};

export type MutationSpendGroupArchiveArgs = {
  id: Scalars["String"];
};

export type MutationSpendGroupBankAccessTokenCreateArgs = {
  id?: InputMaybe<Scalars["String"]>;
  publicToken: Scalars["String"];
  status?: InputMaybe<Scalars["String"]>;
};

export type MutationSpendGroupBankAccessTokenDeleteArgs = {
  id?: InputMaybe<Scalars["String"]>;
};

export type MutationSpendGroupCheckCancelArgs = {
  checkId: Scalars["String"];
};

export type MutationSpendGroupCheckDepositPatchArgs = {
  input: SpendGroupCheckDepositTagsInput;
};

export type MutationSpendGroupCheckSendArgs = {
  input?: InputMaybe<SpendCheckInput>;
};

export type MutationSpendGroupCounterpartyCreateArgs = {
  input?: InputMaybe<SpendCounterpartyCreateInput>;
};

export type MutationSpendGroupCounterpartyDeleteArgs = {
  counterpartyId: Scalars["String"];
  groupId: Scalars["String"];
};

export type MutationSpendGroupCreateArgs = {
  input: SpendGroupInput;
};

export type MutationSpendGroupDebitCardCreateArgs = {
  id?: InputMaybe<Scalars["String"]>;
  input: SpendDebitCardInput;
};

export type MutationSpendGroupDuplicateArgs = {
  id: Scalars["String"];
};

export type MutationSpendGroupExternalTransferArgs = {
  input: SpendGroupExternalTransferInput;
};

export type MutationSpendGroupPayeeCreateArgs = {
  input: SpendPayeeInput;
};

export type MutationSpendGroupPayeeUpdateArgs = {
  input: SpendPayeeUpdateInput;
};

export type MutationSpendGroupRosterCreateArgs = {
  input: SpendGroupRosterInput;
};

export type MutationSpendGroupRosterUpdateArgs = {
  input: SpendRosterUserUpdate;
};

export type MutationSpendGroupRostersCreateArgs = {
  input: SpendGroupRostersInput;
};

export type MutationSpendGroupUpdateArgs = {
  id?: InputMaybe<Scalars["String"]>;
  input: SpendGroupInput;
};

export type MutationSpendGuardianSignupArgs = {
  input: SpendGuardianSignupInput;
};

export type MutationSpendInvoiceArchiveArgs = {
  id: Scalars["String"];
};

export type MutationSpendInvoiceChangeRequestArgs = {
  input: SpendInvoiceRequestChangeInput;
};

export type MutationSpendInvoiceCreateArgs = {
  input: SpendInvoiceInput;
};

export type MutationSpendInvoiceOptInOrOutArgs = {
  input: SpendOptInOrOutInput;
};

export type MutationSpendInvoicePaymentDeauthorizeArgs = {
  input: SpendInvoicePaymentDeauthorizeInput;
};

export type MutationSpendInvoiceRefundArgs = {
  input: SpendInvoiceRefundInput;
};

export type MutationSpendInvoiceReminderCreateArgs = {
  input: SpendInvoiceReminderInput;
};

export type MutationSpendInvoiceUpdateArgs = {
  id: Scalars["String"];
  input: SpendInvoiceInput;
};

export type MutationSpendInvoiceUpdatePaymentMethodArgs = {
  input: SpendInvoicePaymentMethodUpdate;
};

export type MutationSpendNotificationCreateArgs = {
  input: SpendNotificationInput;
};

export type MutationSpendOrganizationAchCreditArgs = {
  input?: InputMaybe<SpendOrgAchCredit>;
};

export type MutationSpendOrganizationAccountTransferArgs = {
  input: SpendAccountTransferInput;
};

export type MutationSpendOrganizationAdminUpdateArgs = {
  input: SpendOrgAdminUpdateInput;
};

export type MutationSpendOrganizationBankAccessTokenCreateArgs = {
  publicToken: Scalars["String"];
  status?: InputMaybe<Scalars["String"]>;
};

export type MutationSpendOrganizationCheckCancelArgs = {
  checkId: Scalars["String"];
};

export type MutationSpendOrganizationCheckDepositPatchArgs = {
  input: SpendOrganizationCheckDepositTagsInput;
};

export type MutationSpendOrganizationCheckSendArgs = {
  input?: InputMaybe<SpendCheckInput>;
};

export type MutationSpendOrganizationCounterpartyCreateArgs = {
  input?: InputMaybe<SpendCounterpartyCreateInput>;
};

export type MutationSpendOrganizationCounterpartyDeleteArgs = {
  counterpartyId: Scalars["String"];
};

export type MutationSpendOrganizationCreateArgs = {
  externalId: Scalars["String"];
  userId: Scalars["String"];
};

export type MutationSpendOrganizationDebitCardCreateArgs = {
  input: SpendDebitCardInput;
};

export type MutationSpendOrganizationExternalTransferArgs = {
  input: SpendOrganizationExternalTransferInput;
};

export type MutationSpendOrganizationPayeeCreateArgs = {
  input: SpendPayeeInput;
};

export type MutationSpendOrganizationPayeeUpdateArgs = {
  input: SpendPayeeUpdateInput;
};

export type MutationSpendOrganizationUpdateArgs = {
  data: SpendOrganizationInput;
  where: Scalars["ID"];
};

export type MutationSpendPaymentMethodCreateArgs = {
  input: SpendPaymentMethodInput;
};

export type MutationSpendPaymentScheduleCreateArgs = {
  input: SpendPaymentScheduleInput;
};

export type MutationSpendPaymentScheduleRevertArgs = {
  id: Scalars["String"];
};

export type MutationSpendPaymentScheduleRevertManyArgs = {
  ids: Array<Scalars["String"]>;
};

export type MutationSpendPaymentScheduleUpdateArgs = {
  id: Scalars["String"];
  input: SpendPaymentScheduleInput;
  rosterIds?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export type MutationSpendPaymentScheduleUpdateBySeasonArgs = {
  input: SpendPaymentScheduleBySeasonInput;
};

export type MutationSpendRemoveParticipantsArgs = {
  input: SpendRemoveSeasonMemberInput;
};

export type MutationSpendRoleChangeArgs = {
  roleId: Scalars["String"];
  sessionId: Scalars["String"];
};

export type MutationSpendSeasonCreateArgs = {
  input: SpendSeasonInput;
};

export type MutationSpendSeasonUpdateArgs = {
  id: Scalars["String"];
  input: SpendSeasonInput;
};

export type MutationSpendSessionCreateArgs = {
  inviteId?: InputMaybe<Scalars["String"]>;
};

export type MutationSpendSessionRefreshArgs = {
  sessionId: Scalars["String"];
};

export type MutationSpendSettingsUpdateArgs = {
  input?: InputMaybe<SpendSettingsInput>;
};

export type MutationSpendSignupAgreementCreateArgs = {
  input: SpendSignupAgreementInput;
};

export type MutationSpendTransactionAttachmentCreateArgs = {
  input: SpendTransactionAttachmentInput;
};

export type MutationSpendTransactionAttachmentDeleteArgs = {
  id: Scalars["String"];
};

export type MutationSpendTransactionBudgetUnreconcileArgs = {
  input: SpendTransactionBudgetUnreconcileInput;
};

export type MutationSpendTransactionInvoiceUnreconcileArgs = {
  input: SpendTransactionInvoiceUnreconcileInput;
};

export type MutationSpendTransactionNoteCreateArgs = {
  input: SpendTranscationNoteInput;
};

export type MutationSpendTransactionNoteUpdateArgs = {
  id: Scalars["String"];
  input: SpendTranscationNoteInput;
};

export type MutationSpendTransactionReconcileArgs = {
  input: SpendTransactionReconcileInput;
};

export type MutationSpendTransactionReconcileV2Args = {
  input: SpendTransactionReconcileV2Input;
};

export type MutationSpendTransactionsCreateArgs = {
  input: SpendTransactionInput;
};

export type MutationSpendUserAchPaymentArgs = {
  input: SpendAchPaymentInput;
};

export type MutationSpendUserAchPaymentCancelArgs = {
  input: SpendAchPaymentCancelInput;
};

export type MutationSpendUserBankAccessTokenCreateArgs = {
  groupId: Scalars["String"];
  publicToken: Scalars["String"];
  status?: InputMaybe<Scalars["String"]>;
};

export type MutationSpendUserEmailUpdateArgs = {
  input?: InputMaybe<SpendUserEmailUpdateInput>;
};

export type MutationSpendUserInviteArchiveArgs = {
  id: Scalars["String"];
};

export type MutationSpendUserInviteCreateArgs = {
  input: SpendInviteInput;
};

export type MutationSpendUserInviteDeleteArgs = {
  id: Scalars["String"];
};

export type MutationSpendUserInviteDismissArgs = {
  inviteId: Scalars["String"];
};

export type MutationSpendUserInviteResendArgs = {
  input: SpendInviteResendInput;
};

export type MutationSpendUserInviteResendAllArgs = {
  input: SpendInviteResendAllInput;
};

export type MutationSpendUserInviteStatusUpdateArgs = {
  id: Scalars["String"];
  status: Scalars["String"];
};

export type MutationSpendUserInviteUpdateArgs = {
  id: Scalars["String"];
};

export type MutationSpendUserLeaveGroupArgs = {
  groupId: Scalars["String"];
};

export type MutationSpendUserNotificationSettingUpdateArgs = {
  input?: InputMaybe<SpendUserNotificationSettingInput>;
};

export type MutationSpendUserPaymentsCardDetachArgs = {
  input?: InputMaybe<SpendPaymentMethodDetach>;
};

export type MutationSpendUserRoleArchiveArgs = {
  groupId?: InputMaybe<Scalars["String"]>;
  userId: Scalars["String"];
};

export type MutationSpendUserRoleSetArgs = {
  roleId: Scalars["String"];
};

export type MutationSpendUserRoleUpdateArgs = {
  groupId?: InputMaybe<Scalars["String"]>;
  roleName: SpendRoleNameEnum;
  userId: Scalars["String"];
};

export type MutationSpendUserRoleUpdateIsApproverArgs = {
  input?: InputMaybe<SpendIsApproverUpdateInput>;
};

export type MutationSpendUserSignUpArgs = {
  input: SpendSignUpInput;
};

export type MutationSpendUserSignupUpdateArgs = {
  input?: InputMaybe<SpendUserSignupInput>;
};

export type MutationStoreBuildRequestArgs = {
  input: StoreBuildRequestInput;
};

export type MutationStoreEditInfoArgs = {
  input: StoreEditInfoInput;
};

export type MutationStoreManagerPointsUpdateArgs = {
  input?: InputMaybe<StoreManagerUpdatePoints>;
};

export type MutationStorePaymentIntentCreateArgs = {
  input: StoreCreatePaymentIntent;
  scopeId: Scalars["Int"];
};

export type MutationStorePointsWithdrawalRequestArgs = {
  input?: InputMaybe<StorePointsWithdrawalRequestInput>;
};

export type MutationStoreProfitPercentageUpdateArgs = {
  store_id: Scalars["Int"];
  updated_product_price_percentage: Scalars["Int"];
};

export type MutationStoreTicketCreateArgs = {
  input: StoreTicketInput;
};

export type MutationStoreTransactionSaveArgs = {
  input: StoreSaveTransaction;
};

export type MutationStoreTransferToCustomerArgs = {
  input?: InputMaybe<StoreTransferCustomer>;
};

export type MutationStoreTransferToGlArgs = {
  input?: InputMaybe<StoreTransferGl>;
};

export type MutationStoreTransferToParticipantArgs = {
  input?: InputMaybe<StoreTransferParticipant>;
};

export type MutationStoreUpdatePayableArgs = {
  input?: InputMaybe<StoreUpdatePayableInput>;
  scopeId: Scalars["Int"];
};

export type MutationStoreUpdateStatusArgs = {
  input: StoreStatusInput;
};

export type MutationStoreUserPreferenceUpsertArgs = {
  featureTourCompleted?: InputMaybe<Scalars["Boolean"]>;
  userId: Scalars["String"];
};

export type MutationSubmitFundraiserApprovalSubmissionArgs = {
  id: Scalars["Int"];
};

export type MutationSupportTicketSendArgs = {
  attachments?: InputMaybe<Array<DriveFileInput>>;
  ccEmails?: InputMaybe<Array<Scalars["String"]>>;
  deviceInfo?: InputMaybe<Scalars["String"]>;
  fundraiserId?: InputMaybe<Scalars["Int"]>;
  message?: InputMaybe<Scalars["String"]>;
  subject: Scalars["String"];
  supportTicketInfo?: InputMaybe<Array<DriveFieldInput>>;
  templateId?: InputMaybe<Scalars["String"]>;
  to: Support_Ticket_Department;
};

export type MutationTeamCreateArgs = {
  input?: InputMaybe<TeamCreateInput>;
};

export type MutationTeamDeleteArgs = {
  input?: InputMaybe<TeamDeleteInput>;
};

export type MutationTeamModifyArgs = {
  input?: InputMaybe<TeamModifyInput>;
};

export type MutationTeamOfficialCreateArgs = {
  input?: InputMaybe<TeamOfficialCreateInput>;
};

export type MutationTeamOfficialDeleteArgs = {
  input?: InputMaybe<TeamOfficialDeleteInput>;
};

export type MutationTeamOfficialModifyArgs = {
  input?: InputMaybe<TeamOfficialModifyInput>;
};

export type MutationTeamPreparationCreateArgs = {
  input?: InputMaybe<TeamPreparationCreateInput>;
};

export type MutationTeamPreparationDeleteArgs = {
  input?: InputMaybe<TeamPreparationDeleteInput>;
};

export type MutationTeamPreparationModifyArgs = {
  input?: InputMaybe<TeamPreparationModifyInput>;
};

export type MutationTeamWorkerCreateArgs = {
  input?: InputMaybe<TeamWorkerCreateInput>;
};

export type MutationTeamWorkerDeleteArgs = {
  input?: InputMaybe<TeamWorkerDeleteInput>;
};

export type MutationTeamWorkerModifyArgs = {
  input?: InputMaybe<TeamWorkerModifyInput>;
};

export type MutationToggleCardActivationArgs = {
  cardId: Scalars["String"];
  status: Card_Status;
};

export type MutationUpdateFundraiserUserTypeConfigArgs = {
  id: Scalars["Int"];
  userTypeConfig: UserTypeConfiguration;
};

export type MutationUpdateParticipantCampaignConfigurationArgs = {
  field?: InputMaybe<ParticipantCampaignConfigInput>;
  id: Scalars["ID"];
};

export type MutationUpdateParticipantGroupArgs = {
  fundraiserId: Scalars["Int"];
  personListsId: Scalars["String"];
  userId: Scalars["Int"];
};

export type MutationUpdateParticipantOtkSizeArgs = {
  fundraiserUserIncentiveId: Scalars["Int"];
  size: Scalars["String"];
};

export type MutationUpgradeToWalletUserArgs = {
  email?: InputMaybe<Scalars["String"]>;
};

export type MutationUploadLogoPngArgs = {
  image?: InputMaybe<ImageInput>;
};

export type MutationUserActivityLeadsCreateArgs = {
  activity: ProgramActivity;
  isConfirmed?: InputMaybe<Scalars["Boolean"]>;
  orgId: Scalars["ID"];
  title: TeamTitle;
  userId: Scalars["ID"];
};

export type MutationUserActivityLeadsDeleteArgs = {
  activity: ProgramActivity;
  orgId: Scalars["ID"];
  title: TeamTitle;
  userId: Scalars["ID"];
};

export type MutationUserActivityLeadsUpdateArgs = {
  activity: ProgramActivity;
  isConfirmed: Scalars["Boolean"];
  orgId: Scalars["ID"];
  title: TeamTitle;
  userId: Scalars["ID"];
};

export type MutationUserApiKeyCreateArgs = {
  expiresPeriod?: InputMaybe<Scalars["String"]>;
  maxRps?: Scalars["Int"];
  name: Scalars["String"];
  permissionIds: Array<Scalars["String"]>;
};

export type MutationUserApiKeyDeleteArgs = {
  id: Scalars["String"];
};

export type MutationUserApiKeyUpdateArgs = {
  id: Scalars["String"];
  maxRps?: Scalars["Int"];
  name: Scalars["String"];
  permissionIds: Array<Scalars["String"]>;
};

export type MutationUserAssociateArgs = {
  orgId: Scalars["ID"];
  product: Product;
  roleId: Scalars["ID"];
  userId: Scalars["ID"];
};

export type MutationUserAssociationAccessArgs = {
  orgId: Scalars["ID"];
  product: Product;
  userId: Scalars["ID"];
};

export type MutationUserAssociationCreateArgs = {
  orgId: Scalars["ID"];
  product: Product;
  roleId: Scalars["ID"];
  userId: Scalars["ID"];
};

export type MutationUserAssociationDeleteArgs = {
  orgId: Scalars["ID"];
  product: Product;
  userId: Scalars["ID"];
};

export type MutationUserBulkInviteSendArgs = {
  inviteType: InviteType;
  orgPayload: Array<UserOrgInvitationPayload>;
};

export type MutationUserChallengeUpdateArgs = {
  id: Scalars["String"];
  status: UserChallengeStatus;
};

export type MutationUserChildCreateArgs = {
  firstName: Scalars["String"];
  lastName: Scalars["String"];
};

export type MutationUserChildUnassignArgs = {
  id: Scalars["String"];
};

export type MutationUserChildUpdateArgs = {
  firstName: Scalars["String"];
  id: Scalars["String"];
  lastName: Scalars["String"];
  profilePicture?: InputMaybe<Scalars["String"]>;
};

export type MutationUserCreateArgs = {
  apps?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  email: Scalars["String"];
  firstName?: InputMaybe<Scalars["String"]>;
  language?: InputMaybe<Scalars["String"]>;
  lastName?: InputMaybe<Scalars["String"]>;
  occupation?: InputMaybe<UserOccupation>;
  parentId?: InputMaybe<Scalars["String"]>;
  password: Scalars["String"];
  phoneNumber: Scalars["String"];
  profilePicture?: InputMaybe<Scalars["String"]>;
  snapRaiseId?: InputMaybe<Scalars["Int"]>;
};

export type MutationUserEmailConfirmArgs = {
  code: Scalars["String"];
};

export type MutationUserFittingAddArgs = {
  fitting: UserFittingPreference;
};

export type MutationUserImpersonateArgs = {
  userId: Scalars["String"];
};

export type MutationUserImpliedAffiliationDeleteArgs = {
  orgId: Scalars["ID"];
  userId: Scalars["ID"];
};

export type MutationUserInviteArgs = {
  email?: InputMaybe<Scalars["String"]>;
  joincode?: InputMaybe<Scalars["String"]>;
};

export type MutationUserInviteAcceptArgs = {
  inviteId?: InputMaybe<Scalars["String"]>;
};

export type MutationUserInviteSendArgs = {
  parentInvite?: InputMaybe<ParentInviteInput>;
  type: InviteType;
};

export type MutationUserOrgAffiliationCreateArgs = {
  description?: InputMaybe<Scalars["String"]>;
  isConfirmed?: InputMaybe<Scalars["Boolean"]>;
  orgId: Scalars["ID"];
  title: AffiliationTitle;
  userId: Scalars["ID"];
};

export type MutationUserOrgAffiliationDeleteArgs = {
  orgId: Scalars["ID"];
  title: AffiliationTitle;
  userId: Scalars["ID"];
};

export type MutationUserOrgAffiliationUpdateArgs = {
  description?: InputMaybe<Scalars["String"]>;
  isConfirmed?: InputMaybe<Scalars["Boolean"]>;
  orgId: Scalars["ID"];
  title: AffiliationTitle;
  userId: Scalars["ID"];
};

export type MutationUserPermissionsUpdateArgs = {
  negativePermissions: Array<UserPermissionAssignment>;
  permissions: Array<UserPermissionAssignment>;
  roleIds: Array<Scalars["String"]>;
  userId: Scalars["String"];
};

export type MutationUserPhoneNumberChallengeConfirmArgs = {
  challengeId: Scalars["String"];
  code: Scalars["String"];
};

export type MutationUserPhoneNumberConfirmArgs = {
  code: Scalars["String"];
};

export type MutationUserRefreshSessionArgs = {
  refreshToken?: InputMaybe<Scalars["String"]>;
};

export type MutationUserResetPasswordArgs = {
  email?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["String"]>;
  transport?: InputMaybe<TransportEnum>;
  withLink?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationUserResetPasswordByAdminArgs = {
  forcePasswordResetChallenge?: InputMaybe<Scalars["Boolean"]>;
  password: Scalars["String"];
  userId: Scalars["String"];
};

export type MutationUserSignupArgs = {
  apps?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  consumer?: InputMaybe<Scalars["String"]>;
  email: Scalars["String"];
  firstName?: InputMaybe<Scalars["String"]>;
  inviteId?: InputMaybe<Scalars["String"]>;
  lastName?: InputMaybe<Scalars["String"]>;
  occupation?: InputMaybe<UserOccupation>;
  password: Scalars["String"];
  phoneNumber: Scalars["String"];
  profilePicture?: InputMaybe<Scalars["String"]>;
};

export type MutationUserSignupConfirmArgs = {
  code: Scalars["String"];
  email: Scalars["String"];
  skip?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationUserSignupConfirmationResendArgs = {
  email: Scalars["String"];
  transport?: InputMaybe<TransportEnum>;
};

export type MutationUserTeamLeadsCreateArgs = {
  isConfirmed?: InputMaybe<Scalars["Boolean"]>;
  teamId: Scalars["ID"];
  title: TeamTitle;
  userId: Scalars["ID"];
};

export type MutationUserTeamLeadsDeleteArgs = {
  teamId: Scalars["ID"];
  title: TeamTitle;
  userId: Scalars["ID"];
};

export type MutationUserTeamLeadsUpdateArgs = {
  isConfirmed: Scalars["Boolean"];
  teamId: Scalars["ID"];
  title: TeamTitle;
  userId: Scalars["ID"];
};

export type MutationUserUnassignParentArgs = {
  email?: InputMaybe<Scalars["String"]>;
  parentId?: InputMaybe<Scalars["String"]>;
};

export type MutationUserUpdateArgs = {
  apps?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  email?: InputMaybe<Scalars["String"]>;
  firstName?: InputMaybe<Scalars["String"]>;
  hsGradYear?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["String"]>;
  isConfirmed?: InputMaybe<Scalars["Boolean"]>;
  isDisabled?: InputMaybe<Scalars["Boolean"]>;
  language?: InputMaybe<Scalars["String"]>;
  lastName?: InputMaybe<Scalars["String"]>;
  occupation?: InputMaybe<UserOccupation>;
  password?: InputMaybe<Scalars["String"]>;
  phoneNumber?: InputMaybe<Scalars["String"]>;
  profilePicture?: InputMaybe<Scalars["String"]>;
};

export type MutationUserUpdatePasswordArgs = {
  email: Scalars["String"];
  newPassword: Scalars["String"];
  oldPassword: Scalars["String"];
};

export type MutationVaultCreateKybKycSubmitArgs = {
  beneficialOwners?: InputMaybe<Array<VaultKycCreateInput>>;
  gateway: VaultFinancialProvider;
  orgData: VaultKybCreateInput;
  representativeData: VaultRepresentativeCreateInput;
  saveToPayableOrgId?: InputMaybe<Scalars["ID"]>;
  stripeData?: InputMaybe<VaultCreateKybKycStripeInput>;
};

export type MutationVaultCustomerCreateArgs = {
  payableOrgName: Scalars["String"];
  stripeEnv?: InputMaybe<VaultStripeEnv>;
};

export type MutationVaultDocumentGatewayUploadArgs = {
  documentBack?: InputMaybe<Scalars["String"]>;
  documentFront: Scalars["String"];
  fileType: Scalars["String"];
  gateway: VaultFinancialProvider;
  stripeConnectAccountId?: InputMaybe<Scalars["String"]>;
  stripeEnv?: InputMaybe<VaultStripeEnv>;
  stripePersonId?: InputMaybe<Scalars["String"]>;
  type: VaultRequiredDocument;
  unitApplicationId?: InputMaybe<Scalars["String"]>;
  vaultId: Scalars["ID"];
};

export type MutationVaultIncorrectKybSupportReportArgs = {
  comment?: InputMaybe<Scalars["String"]>;
  orgAddressCity: Scalars["String"];
  orgAddressState: Scalars["String"];
  orgAddressStreet: Scalars["String"];
  orgAddressZip: Scalars["String"];
  orgEin: Scalars["String"];
  orgLegalName: Scalars["String"];
  raiseCampaignId: Scalars["String"];
  raiseUserId: Scalars["String"];
};

export type MutationVaultKybCreateArgs = {
  address: VaultAddressInput;
  customerFacingName: Scalars["String"];
  description: Scalars["String"];
  email?: InputMaybe<Scalars["String"]>;
  legalName: Scalars["String"];
  phoneNumber: Scalars["String"];
  structure: Kyb_Structure;
  taxId: Scalars["String"];
  type: Kyb_Type;
  url: Scalars["String"];
};

export type MutationVaultKybUpdateArgs = {
  address?: InputMaybe<VaultAddressUpdateInput>;
  customerFacingName?: InputMaybe<Scalars["String"]>;
  description?: InputMaybe<Scalars["String"]>;
  email?: InputMaybe<Scalars["String"]>;
  id: Scalars["ID"];
  legalName?: InputMaybe<Scalars["String"]>;
  phoneNumber?: InputMaybe<Scalars["String"]>;
  structure?: InputMaybe<Kyb_Structure>;
  taxId?: InputMaybe<Scalars["String"]>;
  type?: InputMaybe<Kyb_Type>;
  url?: InputMaybe<Scalars["String"]>;
};

export type MutationVaultKycCreateArgs = {
  address: VaultAddressInput;
  director?: InputMaybe<Scalars["Boolean"]>;
  dob: VaultDobInput;
  email: Scalars["String"];
  executive?: InputMaybe<Scalars["Boolean"]>;
  firstName: Scalars["String"];
  lastName: Scalars["String"];
  owner?: InputMaybe<Scalars["Boolean"]>;
  percentOwnership?: InputMaybe<Scalars["Int"]>;
  phoneNumber: Scalars["String"];
  representative?: InputMaybe<Scalars["Boolean"]>;
  ssn?: InputMaybe<Scalars["String"]>;
  title?: InputMaybe<Scalars["String"]>;
};

export type MutationVaultKycUpdateArgs = {
  address?: InputMaybe<VaultAddressUpdateInput>;
  director?: InputMaybe<Scalars["Boolean"]>;
  dob?: InputMaybe<VaultDobInput>;
  email?: InputMaybe<Scalars["String"]>;
  executive?: InputMaybe<Scalars["Boolean"]>;
  firstName?: InputMaybe<Scalars["String"]>;
  id: Scalars["ID"];
  lastName?: InputMaybe<Scalars["String"]>;
  owner?: InputMaybe<Scalars["Boolean"]>;
  percentOwnership?: InputMaybe<Scalars["Int"]>;
  phoneNumber?: InputMaybe<Scalars["String"]>;
  representative?: InputMaybe<Scalars["Boolean"]>;
  ssn?: InputMaybe<Scalars["String"]>;
  title?: InputMaybe<Scalars["String"]>;
};

export type MutationVaultOrgConfirmationSubmitArgs = {
  gateway: VaultFinancialProvider;
  orgData: VaultFormKybInput;
  primaryRepresentative: VaultRepresentativeCreateInput;
  representativeToReplace?: InputMaybe<VaultRepresentativeCreateInput>;
  stripeData?: InputMaybe<VaultFormStripeInput>;
  submittedOnYourBehalf: Scalars["Boolean"];
};

export type MutationVaultSetupIntentCreateArgs = {
  customerId?: InputMaybe<Scalars["String"]>;
  stripeEnv?: InputMaybe<VaultStripeEnv>;
};

export type MutationVaultStripeConnectAccountBankAccountDefaultSetArgs = {
  bankAccountId: Scalars["String"];
  stripeConnectAccountId: Scalars["String"];
  stripeEnv?: InputMaybe<VaultStripeEnv>;
};

export type MutationVaultStripeConnectAccountBankAccountDeleteArgs = {
  bankAccountId: Scalars["String"];
  stripeConnectAccountId: Scalars["String"];
  stripeEnv?: InputMaybe<VaultStripeEnv>;
};

export type MutationVaultStripeConnectAccountCreateArgs = {
  metadata?: InputMaybe<StripeMetadataInput>;
  statementDescriptor?: InputMaybe<Scalars["String"]>;
  stripeEnv?: InputMaybe<VaultStripeEnv>;
  vaultKybId: Scalars["ID"];
};

export type MutationVaultStripeConnectAccountOwnersProvidedArgs = {
  ownersProvided: Scalars["Boolean"];
  stripeConnectAccountId: Scalars["String"];
  stripeEnv?: InputMaybe<VaultStripeEnv>;
};

export type MutationVaultStripeConnectAccountPaymentMethodAttachArgs = {
  customerId?: InputMaybe<Scalars["String"]>;
  paymentMethodId: Scalars["String"];
  stripeConnectAccountId: Scalars["String"];
  stripeEnv?: InputMaybe<VaultStripeEnv>;
};

export type MutationVaultStripeConnectAccountPaymentMethodDefaultSetArgs = {
  customerId: Scalars["String"];
  paymentMethodId: Scalars["String"];
  stripeConnectAccountId: Scalars["String"];
  stripeEnv?: InputMaybe<VaultStripeEnv>;
};

export type MutationVaultStripeConnectAccountPayoutFrequencySetArgs = {
  payoutInterval: VaultPayoutInterval;
  stripeConnectAccountId: Scalars["String"];
  stripeEnv?: InputMaybe<VaultStripeEnv>;
};

export type MutationVaultStripeConnectAccountRemoveRepresentativeArgs = {
  stripeConnectAccountId: Scalars["String"];
  stripeEnv?: InputMaybe<VaultStripeEnv>;
};

export type MutationVaultStripeConnectAccountUpdateArgs = {
  metadata?: InputMaybe<StripeMetadataInput>;
  statementDescriptor?: InputMaybe<Scalars["String"]>;
  stripeConnectAccountId: Scalars["String"];
  stripeEnv?: InputMaybe<VaultStripeEnv>;
  vaultKybId: Scalars["ID"];
};

export type MutationVaultStripePersonCreateArgs = {
  stripeConnectAccountId: Scalars["String"];
  stripeEnv?: InputMaybe<VaultStripeEnv>;
  vaultKycId: Scalars["ID"];
};

export type MutationVaultStripePersonDeleteArgs = {
  stripeConnectAccountId: Scalars["String"];
  stripeEnv?: InputMaybe<VaultStripeEnv>;
  stripePersonId: Scalars["String"];
};

export type MutationVaultStripePersonUpdateArgs = {
  stripeConnectAccountId: Scalars["String"];
  stripeEnv?: InputMaybe<VaultStripeEnv>;
  stripePersonId: Scalars["String"];
  vaultKycId: Scalars["ID"];
};

export type MutationVaultUpdateVaultKybAutomatedArgs = {
  raiseFundraiserId: Scalars["Int"];
};

export type MutationVehicleCreateArgs = {
  input?: InputMaybe<CreateVehicleInput>;
};

export type MutationVehicleDeleteArgs = {
  input?: InputMaybe<DeleteVehicleInput>;
};

export type MutationVehicleModifyArgs = {
  input?: InputMaybe<ModifyVehicleInput>;
};

export type MutationWarningArgs = {
  payload: Scalars["JSON"];
  service: AuditLogService;
  source: AuditLogSource;
};

export type MutationWorkerDeleteManyArgs = {
  input?: InputMaybe<DeleteManyWorkerInput>;
};

export type MutationWorkerPoolCreateArgs = {
  input?: InputMaybe<CreateWorkerPool>;
};

export type MutationWorkerPoolDeleteArgs = {
  input?: InputMaybe<DeleteWorkerPoolInput>;
};

export type MutationWorkerPoolModifyArgs = {
  input?: InputMaybe<ModifyWorkerPoolInput>;
};

export type MutationWorkerUpsertManyArgs = {
  input?: InputMaybe<UpsertManyWorkersInput>;
};

export type MutationWorkersUpsertArgs = {
  input?: InputMaybe<Array<InputMaybe<UpsertWorkersInput>>>;
};

export type MutationError = {
  __typename?: "MutationError";
  message: Scalars["String"];
  type?: Maybe<Scalars["String"]>;
};

export type MutationResponse = {
  __typename?: "MutationResponse";
  errors: Array<Maybe<MutationError>>;
  properties?: Maybe<OrgsProperties>;
  success: Scalars["Boolean"];
};

export type MyChannels = {
  __typename?: "MyChannels";
  channels?: Maybe<Array<Maybe<Channel>>>;
  count?: Maybe<Scalars["Int"]>;
};

/** NestedBoolFilter is used for nested filtering on boolean fields. */
export type NestedBoolFilter = {
  /** Checks if the boolean field is equal to the specified value. */
  equals?: InputMaybe<Scalars["Boolean"]>;
  /** Nested filter for negating a condition. */
  not?: InputMaybe<NestedBoolFilter>;
};

export type NetsuiteEventInput = {
  eventType?: InputMaybe<Scalars["String"]>;
  record?: InputMaybe<Scalars["JSON"]>;
};

export type NetsuiteWebhookResponse = {
  __typename?: "NetsuiteWebhookResponse";
  error?: Maybe<Scalars["String"]>;
  success?: Maybe<Scalars["Boolean"]>;
};

export type NotAuthenticated = Error & {
  __typename?: "NotAuthenticated";
  message: Scalars["String"];
};

export type NotAuthorized = Error & {
  __typename?: "NotAuthorized";
  message: Scalars["String"];
};

export type Notice = {
  __typename?: "Notice";
  body?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["Date"]>;
  id: Scalars["Int"];
  isUrgent?: Maybe<Scalars["Int"]>;
  title?: Maybe<Scalars["String"]>;
};

export type NoticeCreateInput = {
  body?: InputMaybe<Scalars["String"]>;
  isUrgent?: InputMaybe<Scalars["Int"]>;
  title?: InputMaybe<Scalars["String"]>;
};

export type NoticeDeleteInput = {
  ids?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
};

export type NoticeModifyInput = {
  body?: InputMaybe<Scalars["String"]>;
  id: Scalars["Int"];
  isUrgent?: InputMaybe<Scalars["Int"]>;
  title?: InputMaybe<Scalars["String"]>;
};

export type Official = {
  __typename?: "Official";
  address?: Maybe<Scalars["String"]>;
  city?: Maybe<Scalars["String"]>;
  duty?: Maybe<Scalars["String"]>;
  email?: Maybe<Scalars["String"]>;
  event?: Maybe<Event>;
  event_id?: Maybe<Scalars["Int"]>;
  first_name?: Maybe<Scalars["String"]>;
  home_phone?: Maybe<Scalars["String"]>;
  id: Scalars["Int"];
  last_name?: Maybe<Scalars["String"]>;
  offic_id?: Maybe<Scalars["String"]>;
  organization?: Maybe<Scalars["String"]>;
  paid?: Maybe<Scalars["String"]>;
  pay_code?: Maybe<Scalars["String"]>;
  received?: Maybe<Scalars["String"]>;
  salary?: Maybe<Scalars["Float"]>;
  ssn?: Maybe<Scalars["String"]>;
  state?: Maybe<Scalars["String"]>;
  voucher_number?: Maybe<Scalars["String"]>;
  work_phone?: Maybe<Scalars["String"]>;
  worker_name?: Maybe<Scalars["String"]>;
  zip?: Maybe<Scalars["String"]>;
};

export type OfficialAssignmentsReturn = {
  __typename?: "OfficialAssignmentsReturn";
  grand_total?: Maybe<Scalars["String"]>;
  official_names?: Maybe<Array<Maybe<Scalars["String"]>>>;
  officials?: Maybe<Array<Maybe<TransformedOfficialAssignment>>>;
  total?: Maybe<Array<Maybe<Total>>>;
};

export type OfficialByIdInput = {
  id: Scalars["Int"];
};

export type OfficialDuty = {
  __typename?: "OfficialDuty";
  duty?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["Int"]>;
  is_deleted?: Maybe<Scalars["Boolean"]>;
};

export type OfficialPool = {
  __typename?: "OfficialPool";
  Address?: Maybe<Scalars["String"]>;
  City?: Maybe<Scalars["String"]>;
  First?: Maybe<Scalars["String"]>;
  Home_Phone?: Maybe<Scalars["String"]>;
  ID: Scalars["String"];
  Last?: Maybe<Scalars["String"]>;
  SSN?: Maybe<Scalars["String"]>;
  State?: Maybe<Scalars["String"]>;
  Work_Phone?: Maybe<Scalars["String"]>;
  Zip?: Maybe<Scalars["String"]>;
  cell_phone?: Maybe<Scalars["String"]>;
  email?: Maybe<Scalars["String"]>;
  formattedSSN?: Maybe<Scalars["String"]>;
  is_deleted?: Maybe<Scalars["Boolean"]>;
  vendor_number?: Maybe<Scalars["String"]>;
};

export type OfficialSchedules = {
  __typename?: "OfficialSchedules";
  activity?: Maybe<Scalars["String"]>;
  bus_count?: Maybe<Scalars["Int"]>;
  bus_departure_location?: Maybe<Scalars["String"]>;
  bus_early_dismissal_time?: Maybe<Scalars["String"]>;
  bus_estimated_return_time?: Maybe<Scalars["String"]>;
  bus_time?: Maybe<Scalars["String"]>;
  cancellation_status?: Maybe<Scalars["String"]>;
  comments?: Maybe<Scalars["String"]>;
  confirmed?: Maybe<Scalars["String"]>;
  driver_name?: Maybe<Scalars["String"]>;
  driver_phone?: Maybe<Scalars["String"]>;
  end_time?: Maybe<Scalars["String"]>;
  event_date?: Maybe<Scalars["String"]>;
  facility?: Maybe<Scalars["String"]>;
  gender?: Maybe<Scalars["String"]>;
  home_field?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["Int"]>;
  levels?: Maybe<Scalars["String"]>;
  official_address?: Maybe<Scalars["String"]>;
  official_address_city?: Maybe<Scalars["String"]>;
  official_address_state?: Maybe<Scalars["String"]>;
  official_address_zip?: Maybe<Scalars["String"]>;
  official_duty?: Maybe<Scalars["String"]>;
  official_email?: Maybe<Scalars["String"]>;
  official_first_name?: Maybe<Scalars["String"]>;
  official_home_phone?: Maybe<Scalars["String"]>;
  official_id?: Maybe<Scalars["String"]>;
  official_last_name?: Maybe<Scalars["String"]>;
  official_paid?: Maybe<Scalars["String"]>;
  official_received?: Maybe<Scalars["String"]>;
  official_row_id?: Maybe<Scalars["String"]>;
  official_salary?: Maybe<Scalars["String"]>;
  official_work_phone?: Maybe<Scalars["String"]>;
  opponent?: Maybe<Scalars["String"]>;
  place?: Maybe<Scalars["String"]>;
  season_id?: Maybe<Scalars["Int"]>;
  sports_code?: Maybe<Scalars["String"]>;
  sports_description?: Maybe<Scalars["String"]>;
  sports_group?: Maybe<Scalars["String"]>;
  sports_name?: Maybe<Scalars["String"]>;
  start_time?: Maybe<Scalars["String"]>;
  vehicle_id?: Maybe<Scalars["String"]>;
  vehicle_type?: Maybe<Scalars["String"]>;
};

export type OnException = {
  __typename?: "OnException";
  exceptionCode?: Maybe<Scalars["Int"]>;
  message?: Maybe<Scalars["String"]>;
  type?: Maybe<Scalars["String"]>;
};

export type Opponent = {
  __typename?: "Opponent";
  Address?: Maybe<Scalars["String"]>;
  Phone?: Maybe<Scalars["String"]>;
  SchoolCode?: Maybe<Scalars["String"]>;
  SchoolName?: Maybe<Scalars["String"]>;
  State?: Maybe<Scalars["String"]>;
  Zip?: Maybe<Scalars["String"]>;
  ad_name?: Maybe<Scalars["String"]>;
  city?: Maybe<Scalars["String"]>;
  email?: Maybe<Scalars["String"]>;
  fax?: Maybe<Scalars["String"]>;
  is_deleted?: Maybe<Scalars["Boolean"]>;
  nces_id?: Maybe<Scalars["String"]>;
  non_school?: Maybe<Scalars["Boolean"]>;
};

export type OrderFilterInput = {
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Scalars["String"]>;
  orderBySort?: InputMaybe<Scalars["String"]>;
  scopeId: Scalars["Int"];
  searchBy?: InputMaybe<Scalars["String"]>;
  searchValue?: InputMaybe<Scalars["String"]>;
  status?: InputMaybe<Scalars["String"]>;
};

export type OrderGraphInput = {
  interval?: InputMaybe<Scalars["Int"]>;
  scopeId: Scalars["Int"];
};

export type OrderInput = {
  carrier: ShippingProvider;
  city: Scalars["String"];
  fundraiserId?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["String"]>;
  line2?: InputMaybe<Scalars["String"]>;
  netsuiteId: Scalars["String"];
  packingSlipId: Scalars["String"];
  packingSlipTitle: Scalars["String"];
  products: Array<OrderProductInput>;
  scheduleAt?: InputMaybe<Scalars["String"]>;
  shipTo: Scalars["String"];
  state: Scalars["String"];
  street: Scalars["String"];
  street2?: InputMaybe<Scalars["String"]>;
  update?: InputMaybe<Scalars["Boolean"]>;
  vendor: Vendor;
  zipCode: Scalars["String"];
};

export type OrderItem = {
  __typename?: "OrderItem";
  itemId?: Maybe<Scalars["Int"]>;
  orderId?: Maybe<Scalars["Int"]>;
  parentItemId?: Maybe<Scalars["Int"]>;
  price?: Maybe<Scalars["Float"]>;
  productId?: Maybe<Scalars["Int"]>;
  productName?: Maybe<Scalars["String"]>;
  productType?: Maybe<Scalars["String"]>;
  quantity?: Maybe<Scalars["Int"]>;
  sku?: Maybe<Scalars["String"]>;
};

export type OrderProductInput = {
  logo: Scalars["String"];
  name?: InputMaybe<Scalars["String"]>;
  netsuiteId: Scalars["String"];
  receiverName: Scalars["String"];
  sku?: InputMaybe<Scalars["String"]>;
};

export type OrderResult = {
  __typename?: "OrderResult";
  errors?: Maybe<Array<Maybe<Scalars["String"]>>>;
  status?: Maybe<OrderStatus>;
};

export enum OrderStatus {
  Failure = "FAILURE",
  Success = "SUCCESS",
}

export enum OrderType {
  Asc = "ASC",
  Desc = "DESC",
}

export type OrdersFilter = {
  __typename?: "OrdersFilter";
  orders?: Maybe<Array<Maybe<MagentoOrder>>>;
  pagination?: Maybe<SdPagination>;
};

export type OrdersFilterUnionType = OnException | OrdersFilter;

export type OrdersSummary = {
  __typename?: "OrdersSummary";
  baseSales?: Maybe<Scalars["Float"]>;
  complete?: Maybe<Scalars["BigInt"]>;
  discountAmount?: Maybe<Scalars["Float"]>;
  processing?: Maybe<Scalars["BigInt"]>;
  sales?: Maybe<Scalars["Float"]>;
  scopeId?: Maybe<Scalars["BigInt"]>;
  shipped?: Maybe<Scalars["BigInt"]>;
};

export type OrdersSummaryUnionType = OnException | OrdersSummary;

export type Org = {
  __typename?: "Org";
  createdAt?: Maybe<Scalars["DateTime"]>;
  fields?: Maybe<Scalars["JSONObject"]>;
  financialAccounts?: Maybe<Array<FinancialAccount>>;
  id: Scalars["ID"];
  lastMigrated?: Maybe<Scalars["DateTime"]>;
  /** @deprecated Please use lastMigrated instead */
  migrated?: Maybe<Scalars["DateTime"]>;
  name?: Maybe<Scalars["String"]>;
  parent?: Maybe<Org>;
  /** @deprecated Please use parent.id instead */
  parentId?: Maybe<Scalars["String"]>;
  stores?: Maybe<Array<Store>>;
  type: Scalars["String"];
  updatedAt?: Maybe<Scalars["DateTime"]>;
};

export type OrgFieldsArgs = {
  names?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export type OrgAffiliation = {
  __typename?: "OrgAffiliation";
  affiliations: Array<UserAffiliation>;
  org: Org;
};

export type OrgCampaignAffiliation = {
  __typename?: "OrgCampaignAffiliation";
  campaigns: Array<Org>;
  org: Org;
};

export type OrgCampaignIds = {
  __typename?: "OrgCampaignIds";
  campaignIds: Array<Scalars["ID"]>;
  legacyRaiseIds: Array<Scalars["Int"]>;
  role?: Maybe<Scalars["ID"]>;
  rootOrgId: Scalars["ID"];
};

export type OrgInviteArguments = {
  orgId: Scalars["String"];
  title: Scalars["String"];
};

export type OrgStaffMember = {
  __typename?: "OrgStaffMember";
  orgAffiliations: Array<UserAffiliation>;
  orgId: Scalars["ID"];
  programsLed: Array<ProgramWithTitle>;
  teamsLed: Array<TeamWithTitle>;
  user: UserNode;
};

export enum OrgType {
  Booster = "BOOSTER",
  Business = "BUSINESS",
  Club = "CLUB",
  District = "DISTRICT",
  Foundation = "FOUNDATION",
  Program = "PROGRAM",
  Pta = "PTA",
  School = "SCHOOL",
  Shellschool = "SHELLSCHOOL",
  Team = "TEAM",
}

export enum OrganizationFilterEnum {
  Pending = "pending",
}

export type OrgsProperties = {
  __typename?: "OrgsProperties";
  acctId?: Maybe<Scalars["ID"]>;
  applicationId?: Maybe<Scalars["ID"]>;
  createdAt?: Maybe<Scalars["DateTime"]>;
  customerId?: Maybe<Scalars["ID"]>;
  deletedAt?: Maybe<Scalars["DateTime"]>;
  finAcctId?: Maybe<Scalars["ID"]>;
  firstSeen?: Maybe<Scalars["DateTime"]>;
  internal?: Maybe<Scalars["Boolean"]>;
  isBeneficialOwner?: Maybe<Scalars["Boolean"]>;
  isContact?: Maybe<Scalars["Boolean"]>;
  isPrincipal?: Maybe<Scalars["Boolean"]>;
  isRepresentative?: Maybe<Scalars["Boolean"]>;
  kybId?: Maybe<Scalars["ID"]>;
  kycId?: Maybe<Scalars["ID"]>;
  lastMigrated?: Maybe<Scalars["DateTime"]>;
  lastSeen?: Maybe<Scalars["DateTime"]>;
  /** @deprecated Please use lastMigrated instead */
  migrated?: Maybe<Scalars["DateTime"]>;
  orgId?: Maybe<Scalars["ID"]>;
  parentId?: Maybe<Scalars["ID"]>;
  personId?: Maybe<Scalars["ID"]>;
  processor?: Maybe<Processor>;
  programId?: Maybe<Scalars["ID"]>;
  roleId?: Maybe<Scalars["ID"]>;
  status?: Maybe<FinAcctStatus>;
  type?: Maybe<Array<Maybe<Scalars["String"]>>>;
  updatedAt?: Maybe<Scalars["DateTime"]>;
  userId?: Maybe<Scalars["ID"]>;
};

export type OrgsSearchPaginationInput = {
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type OrgsSearchResponse = {
  __typename?: "OrgsSearchResponse";
  hasNext?: Maybe<Scalars["Boolean"]>;
  hasPrevious?: Maybe<Scalars["Boolean"]>;
  orgs?: Maybe<Array<Maybe<Org>>>;
  total?: Maybe<Scalars["Int"]>;
};

export type OrgsSearchWhereInput = {
  /** List of organization ids to filter from. */
  ids?: InputMaybe<Array<Scalars["ID"]>>;
  /** The string to search in name of organization. */
  nameIncludes?: InputMaybe<Scalars["String"]>;
  /** Organization type. Valid values are "Booster" | "Club" | "District" | "Program" | "School". */
  orgTypes?: InputMaybe<Array<OrgType>>;
  /** Allow to search any properties via JSON query. The key will auto convert to snake_case. */
  properties?: InputMaybe<Scalars["JSON"]>;
};

export type OtkFundraiserProduct = {
  __typename?: "OtkFundraiserProduct";
  /** This otk item image will include the fundraiser logo. */
  dynamicImage?: Maybe<Scalars["String"]>;
  incentives?: Maybe<OtkProduct>;
};

export type OtkParticipantData = {
  __typename?: "OtkParticipantData";
  id: Scalars["ID"];
  incentives?: Maybe<OtkProduct>;
  size?: Maybe<Scalars["String"]>;
};

export type OtkProduct = {
  __typename?: "OtkProduct";
  description?: Maybe<Scalars["String"]>;
  fitting?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  /** This is the base image for the otk item. It will not include the fundraiser logo. */
  image?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  priceCents?: Maybe<Scalars["Int"]>;
  productType?: Maybe<Scalars["String"]>;
  sizes?: Maybe<Scalars["String"]>;
};

export enum PagesNameEnum {
  AboutUs = "AboutUs",
  Mission = "Mission",
  SchoolDirections = "SchoolDirections",
  Sponsors = "Sponsors",
  Staff = "Staff",
}

export type Pagination = {
  __typename?: "Pagination";
  currentPage: Scalars["Int"];
  itemCount: Scalars["Int"];
  pageSize: Scalars["Int"];
};

export type ParentInviteInput = {
  email?: InputMaybe<Scalars["String"]>;
  joincode: Scalars["String"];
  phoneNumber?: InputMaybe<Scalars["String"]>;
};

export type Participant = {
  __typename?: "Participant";
  campaignId?: Maybe<Scalars["String"]>;
  email?: Maybe<Scalars["String"]>;
  firstName?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  lastLogin?: Maybe<Scalars["String"]>;
  lastName?: Maybe<Scalars["String"]>;
  role?: Maybe<Scalars["String"]>;
};

/** See CampaignMembership for details */
export type ParticipantCampaign = {
  __typename?: "ParticipantCampaign";
  areDonationInviteEmailsReleased: Scalars["Boolean"];
  basicStatus: BasicCampaignStatus;
  campaignDates?: Maybe<CampaignDates>;
  campaignGoal?: Maybe<Scalars["Int"]>;
  donationsRaised?: Maybe<ParticipantDonationsRaised>;
  /** Getting donor email addresses adds time to your query */
  donorEmailData?: Maybe<Array<Maybe<DonorEmailData>>>;
  donorPersonListEntries?: Maybe<Array<Maybe<DonorPersonListEntry>>>;
  /** Getting donor phone numbers adds time to your query */
  donorPhoneNumbers?: Maybe<Array<Maybe<DonationInviteSmsData>>>;
  fundraiserRewardLevelsCount?: Maybe<FundraiserRewardLevelsCount>;
  galleryItems?: Maybe<GalleryItems>;
  group?: Maybe<ParticipantGroup>;
  hasCustomRewards?: Maybe<Scalars["Boolean"]>;
  id: Scalars["ID"];
  isCoparticipant?: Maybe<Scalars["Boolean"]>;
  isGuardianLedCampaign: Scalars["Boolean"];
  isOTKEnabled?: Maybe<Scalars["Boolean"]>;
  isRewardsEnabled?: Maybe<Scalars["Boolean"]>;
  isTopEarner: Scalars["Boolean"];
  joinCode?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  newStack_participantFundraiserLink?: Maybe<Scalars["String"]>;
  otk?: Maybe<Array<Maybe<OtkParticipantData>>>;
  participantGoal?: Maybe<Scalars["Int"]>;
  participantGuardianEmails?: Maybe<ParticipantGuardianEmail>;
  participantRewardsData?: Maybe<Array<Maybe<ParticipantRewardsData>>>;
  primaryColor: Scalars["String"];
  raiseUserId?: Maybe<Scalars["Int"]>;
  raiseUserJoinedAt?: Maybe<Scalars["String"]>;
  slug?: Maybe<Scalars["String"]>;
  /** Datetime-ISO format for sms invites released */
  smsInvitesReleasedAt?: Maybe<Scalars["String"]>;
  status: CampaignStatus;
  userFitting?: Maybe<UserFitting>;
};

export type ParticipantCampaignConfigInput = {
  autoImportEmails?: InputMaybe<ParticipantCampaignConfigStates>;
  autoImportTexts?: InputMaybe<ParticipantCampaignConfigStates>;
  giftShop?: InputMaybe<ParticipantCampaignConfigStates>;
  guardianSetup?: InputMaybe<ParticipantCampaignConfigStates>;
  profileSetup?: InputMaybe<ParticipantCampaignConfigStates>;
  rewards?: InputMaybe<ParticipantCampaignConfigStates>;
};

export enum ParticipantCampaignConfigStates {
  Completed = "COMPLETED",
  NotApplicable = "NOT_APPLICABLE",
  NotCompleted = "NOT_COMPLETED",
  Skipped = "SKIPPED",
}

export type ParticipantCheer = {
  __typename?: "ParticipantCheer";
  anonymous?: Maybe<Scalars["Boolean"]>;
  createdAt?: Maybe<Scalars["DateTime"]>;
  donorMessage?: Maybe<Scalars["String"]>;
  donorName?: Maybe<Scalars["String"]>;
  firstName?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["Int"]>;
  images?: Maybe<Array<Maybe<Scalars["String"]>>>;
  lastName?: Maybe<Scalars["String"]>;
  subtotalCents?: Maybe<Scalars["Int"]>;
};

export type ParticipantCheerwall = {
  __typename?: "ParticipantCheerwall";
  cheers?: Maybe<Array<Maybe<ParticipantCheer>>>;
  count?: Maybe<Scalars["Int"]>;
};

export type ParticipantData = {
  __typename?: "ParticipantData";
  data?: Maybe<User>;
  raiseId?: Maybe<Scalars["Int"]>;
};

export type ParticipantDonationsRaised = {
  __typename?: "ParticipantDonationsRaised";
  numberOfDonations: Scalars["Int"];
  subtotalCents: Scalars["Int"];
};

/** This is just a Fundraiser, but specifically for a participant */
export type ParticipantFundraiser = {
  __typename?: "ParticipantFundraiser";
  id?: Maybe<Scalars["ID"]>;
  name?: Maybe<Scalars["String"]>;
  raiseId?: Maybe<Scalars["Int"]>;
  slug?: Maybe<Scalars["String"]>;
};

export type ParticipantFundraiserUser = {
  __typename?: "ParticipantFundraiserUser";
  id?: Maybe<Scalars["ID"]>;
};

export type ParticipantGroup = {
  __typename?: "ParticipantGroup";
  id?: Maybe<Scalars["ID"]>;
  label?: Maybe<Scalars["String"]>;
};

export type ParticipantGuardianDonorEntry = {
  __typename?: "ParticipantGuardianDonorEntry";
  email?: Maybe<Scalars["String"]>;
  error?: Maybe<Scalars["String"]>;
  fundraiserId?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
};

export type ParticipantGuardianEmail = {
  __typename?: "ParticipantGuardianEmail";
  guardianEmail?: Maybe<Scalars["String"]>;
  secondaryGuardianEmail?: Maybe<Scalars["String"]>;
};

export type ParticipantGuardianEmailUpdate = {
  __typename?: "ParticipantGuardianEmailUpdate";
  id?: Maybe<Scalars["Int"]>;
};

export type ParticipantGuardianFundraiser = {
  __typename?: "ParticipantGuardianFundraiser";
  id: Scalars["ID"];
  logo?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  participants?: Maybe<Array<Maybe<ParticipantUser>>>;
  slug: Scalars["String"];
  status: BasicCampaignStatus;
};

export type ParticipantGuardianReturn = {
  __typename?: "ParticipantGuardianReturn";
  entry?: Maybe<Array<ParticipantGuardianDonorEntry>>;
};

export type ParticipantList = {
  __typename?: "ParticipantList";
  count?: Maybe<Scalars["Int"]>;
  cursor?: Maybe<Scalars["String"]>;
  list: Array<Maybe<Participant>>;
};

export type ParticipantPublic = {
  __typename?: "ParticipantPublic";
  firstName?: Maybe<Scalars["String"]>;
  /** User directory id */
  id?: Maybe<Scalars["String"]>;
  lastName?: Maybe<Scalars["String"]>;
  profilePicture?: Maybe<Scalars["String"]>;
};

/** A 'tremendous' reward. The presence means that a reward has been sent to a participant */
export type ParticipantReward = {
  __typename?: "ParticipantReward";
  id?: Maybe<Scalars["ID"]>;
  status?: Maybe<RewardStatus>;
};

export type ParticipantRewardsData = {
  __typename?: "ParticipantRewardsData";
  id?: Maybe<Scalars["Int"]>;
  incentives?: Maybe<FundraiserRewardsProduct>;
  size?: Maybe<Scalars["String"]>;
  tier?: Maybe<Scalars["Int"]>;
};

export type ParticipantRewardsInput = {
  incentiveId: Scalars["Int"];
  size: Scalars["String"];
  tier: Scalars["Int"];
};

export type ParticipantSsoid = {
  __typename?: "ParticipantSSOID";
  userDirectoryId?: Maybe<Scalars["String"]>;
};

export type ParticipantTextTemplate = {
  __typename?: "ParticipantTextTemplate";
  guardianTemplate?: Maybe<Scalars["String"]>;
  participantTemplate?: Maybe<Scalars["String"]>;
};

export type ParticipantTopDonation = {
  __typename?: "ParticipantTopDonation";
  donorName?: Maybe<Scalars["String"]>;
  subtotalCents?: Maybe<Scalars["Int"]>;
};

export type ParticipantUser = {
  __typename?: "ParticipantUser";
  fundraiserUserId: Scalars["Int"];
  user: User;
};

export type ParticipantsInfo = {
  __typename?: "ParticipantsInfo";
  count?: Maybe<Scalars["Int"]>;
  loggedInCount?: Maybe<Scalars["Int"]>;
  withAtLeastOneDonation?: Maybe<Scalars["Int"]>;
  withAtLeastTwentyInvites?: Maybe<Scalars["Int"]>;
};

export enum PastDueSortOptions {
  Amount = "amount",
  DueDate = "dueDate",
  Groups = "groups",
  ParentStatus = "parentStatus",
  ParticipantName = "participantName",
}

export type PayableInfo = {
  __typename?: "PayableInfo";
  campaignId?: Maybe<Scalars["Int"]>;
  city?: Maybe<Scalars["String"]>;
  country?: Maybe<Scalars["String"]>;
  ein?: Maybe<Scalars["Int"]>;
  fullAddressOne?: Maybe<Scalars["String"]>;
  fullAddressTwo?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  payableName?: Maybe<Scalars["String"]>;
  payableType?: Maybe<Scalars["String"]>;
  region?: Maybe<Scalars["String"]>;
  scopeId?: Maybe<Scalars["Int"]>;
  street?: Maybe<Scalars["String"]>;
  zip?: Maybe<Scalars["Int"]>;
};

export type Payee = {
  __typename?: "Payee";
  address: PayeeAddress;
  name: Scalars["String"];
};

export type PayeeAddress = {
  __typename?: "PayeeAddress";
  apartment?: Maybe<Scalars["String"]>;
  attention?: Maybe<Scalars["String"]>;
  city: Scalars["String"];
  state: Scalars["String"];
  street: Scalars["String"];
  zip: Scalars["String"];
};

export enum PaymentProvider {
  Paypal = "PAYPAL",
  Stripe = "STRIPE",
}

export type PaymentsApiCreateRefundResponse = {
  __typename?: "PaymentsApiCreateRefundResponse";
  applicationFeeRefundAmount?: Maybe<Scalars["Int"]>;
  id: Scalars["String"];
  reason?: Maybe<Scalars["String"]>;
  refundApplicationFee?: Maybe<Scalars["Boolean"]>;
  transactionId?: Maybe<Scalars["String"]>;
};

export type PaymentsApiCustomerInput = {
  email: Scalars["String"];
  stripeEnv?: InputMaybe<StripeEnv>;
};

export type PaymentsApiCustomerPaymentMethod = {
  __typename?: "PaymentsApiCustomerPaymentMethod";
  billingAddress?: Maybe<PaymentsApiPmAddress>;
  brand?: Maybe<Scalars["String"]>;
  expiration?: Maybe<PaymentsApiPmExpiration>;
  id: Scalars["String"];
  identifier: Scalars["String"];
  type: PaymentsApiCustomerPaymentMethodType;
  zipCode?: Maybe<Scalars["String"]>;
};

export enum PaymentsApiCustomerPaymentMethodType {
  Bank = "BANK",
  Card = "CARD",
  Other = "OTHER",
}

export type PaymentsApiCustomerResponse = {
  __typename?: "PaymentsApiCustomerResponse";
  customerId?: Maybe<Scalars["String"]>;
  email: Scalars["String"];
  name?: Maybe<Scalars["String"]>;
  paymentMethods: Array<Maybe<PaymentsApiCustomerPaymentMethod>>;
};

export type PaymentsApiDetachPaymentMethodInput = {
  paymentMethodId: Scalars["String"];
  stripeEnv?: InputMaybe<StripeEnv>;
};

export type PaymentsApiDetachPaymentMethodResponse = {
  __typename?: "PaymentsApiDetachPaymentMethodResponse";
  paymentMethodId: Scalars["String"];
  stripeEnv: StripeEnv;
};

export type PaymentsApiPmAddress = {
  __typename?: "PaymentsApiPMAddress";
  city?: Maybe<Scalars["String"]>;
  country?: Maybe<Scalars["String"]>;
  line1?: Maybe<Scalars["String"]>;
  line2?: Maybe<Scalars["String"]>;
  postalCode?: Maybe<Scalars["String"]>;
  state?: Maybe<Scalars["String"]>;
};

export type PaymentsApiPmExpiration = {
  __typename?: "PaymentsApiPMExpiration";
  month: Scalars["Int"];
  year: Scalars["Int"];
};

export type PaymentsApiPaymentIput = {
  amount: Scalars["Int"];
  description?: InputMaybe<Scalars["String"]>;
  finalDestination: Scalars["String"];
  idempotencyKey?: InputMaybe<Scalars["String"]>;
  metadata?: InputMaybe<Scalars["JSONObject"]>;
  paymentMethodId: Scalars["String"];
  snapAmount?: InputMaybe<Scalars["Int"]>;
  snapId: Scalars["String"];
  stripeEnv?: InputMaybe<StripeEnv>;
};

export type PaymentsApiPaymentResponse = {
  __typename?: "PaymentsApiPaymentResponse";
  amount: Scalars["Int"];
  id: Scalars["String"];
  paymentMethodId: Scalars["String"];
  snapAmount: Scalars["Int"];
  snapId: Scalars["String"];
  stripeEnv: StripeEnv;
};

export type PaymentsApiRefundInput = {
  amount?: InputMaybe<Scalars["Int"]>;
  idempotencyKey?: InputMaybe<Scalars["String"]>;
  metadata?: InputMaybe<Scalars["JSONObject"]>;
  refundApplicationFee?: InputMaybe<Scalars["Boolean"]>;
  snapAmount?: InputMaybe<Scalars["Int"]>;
  stripeEnv?: InputMaybe<StripeEnv>;
  transactionId: Scalars["String"];
};

export type Payout = Check | Deposit;

export type Permission = {
  __typename?: "Permission";
  description?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
};

export type PhoneNumberChallengeConfirmation = Tokens & {
  __typename?: "PhoneNumberChallengeConfirmation";
  accessToken?: Maybe<Scalars["String"]>;
  refreshToken?: Maybe<Scalars["String"]>;
};

export type PointActivityFilter = {
  __typename?: "PointActivityFilter";
  activities?: Maybe<Array<Maybe<MagentoPointActivity>>>;
  pagination?: Maybe<SdPagination>;
};

export type PointActivityFilterUnionType = OnException | PointActivityFilter;

export type PointsActivityFilterInput = {
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Scalars["String"]>;
  orderBySort?: InputMaybe<Scalars["String"]>;
  scopeId: Scalars["Int"];
  searchBy?: InputMaybe<Scalars["String"]>;
  searchValue?: InputMaybe<Scalars["ID"]>;
  status?: InputMaybe<Scalars["String"]>;
  type?: InputMaybe<Scalars["String"]>;
};

export type PostponeUnpostponeResponse = {
  __typename?: "PostponeUnpostponeResponse";
  affected_events?: Maybe<Scalars["Int"]>;
  success?: Maybe<Scalars["Boolean"]>;
};

export type PotentialCustomersDeals = {
  __typename?: "PotentialCustomersDeals";
  activity?: Maybe<Scalars["String"]>;
  campaignsCount?: Maybe<Scalars["Int"]>;
  curricularType?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
};

/** Information of a emails scheduled for release */
export type PreloadEmailSchedule = {
  __typename?: "PreloadEmailSchedule";
  /** The date and time for release */
  dateTime?: Maybe<Scalars["DateTime"]>;
  /** The campaign id */
  fundraiserId?: Maybe<Scalars["Int"]>;
  /** The timezone for scheduled release */
  timezone?: Maybe<Scalars["String"]>;
};

/** Information of premade email templates */
export type PremadeContactTemplate = {
  __typename?: "PremadeContactTemplate";
  /** The message of the template. Plain text only */
  message?: Maybe<Scalars["String"]>;
  /** The name of the template */
  name?: Maybe<Scalars["String"]>;
  /** The subject of the template */
  subject?: Maybe<Scalars["String"]>;
  templateMedium?: Maybe<ContactTemplateMedium>;
  /** The type of template */
  templateType?: Maybe<ContactTemplateType>;
};

export type Preparation = {
  __typename?: "Preparation";
  duty?: Maybe<Scalars["String"]>;
  id: Scalars["Int"];
  is_deleted?: Maybe<Scalars["Boolean"]>;
  qty?: Maybe<Scalars["String"]>;
};

export type PreparationByIdInput = {
  id: Scalars["Int"];
};

export type PreparationReport = {
  __typename?: "PreparationReport";
  comments?: Maybe<Scalars["String"]>;
  event?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  prep?: Maybe<Scalars["String"]>;
  qty?: Maybe<Scalars["String"]>;
};

export type PreparationSheet = {
  __typename?: "PreparationSheet";
  activity?: Maybe<Scalars["String"]>;
  bus_count?: Maybe<Scalars["Int"]>;
  comments?: Maybe<Scalars["String"]>;
  confirmed?: Maybe<Scalars["String"]>;
  driver_name?: Maybe<Scalars["String"]>;
  driver_phone?: Maybe<Scalars["String"]>;
  end_time?: Maybe<Scalars["String"]>;
  event_date?: Maybe<Scalars["String"]>;
  facility?: Maybe<Scalars["String"]>;
  fee?: Maybe<Scalars["Float"]>;
  g_s?: Maybe<Scalars["String"]>;
  gender?: Maybe<Scalars["String"]>;
  home_field?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["Int"]>;
  levels?: Maybe<Scalars["String"]>;
  place?: Maybe<Scalars["String"]>;
  preparations?: Maybe<Array<Maybe<PreparationReport>>>;
  season_id?: Maybe<Scalars["Int"]>;
  sports_code?: Maybe<Scalars["String"]>;
  sports_description?: Maybe<Scalars["String"]>;
  sports_group?: Maybe<Scalars["String"]>;
  sports_name?: Maybe<Scalars["String"]>;
  start_time?: Maybe<Scalars["String"]>;
  vehicle_id?: Maybe<Scalars["String"]>;
  vehicle_type?: Maybe<Scalars["String"]>;
};

/** Primary Group Leader The primary group leader is the main contact for a fundraiser The association is not direct with a user but made through email */
export type PrimaryGroupLeader = {
  __typename?: "PrimaryGroupLeader";
  /** Signifies user is primary group leader. Extra query. */
  currentUserIsPrimary: Scalars["Boolean"];
  email?: Maybe<Scalars["String"]>;
  fullName?: Maybe<Scalars["String"]>;
  phoneNumber?: Maybe<Scalars["String"]>;
};

export enum Processor {
  Stripe = "STRIPE",
  Unit = "UNIT",
}

export enum Product {
  Connect = "CONNECT",
  Insights = "INSIGHTS",
  Manage = "MANAGE",
  Raise = "RAISE",
  Spend = "SPEND",
  Sponsor = "SPONSOR",
  Store = "STORE",
}

export type ProductInput = {
  logoPosition: LogoPosition;
  name: Scalars["String"];
  netsuiteId?: InputMaybe<Scalars["String"]>;
  size: ProductSize;
  sku: Scalars["String"];
};

export type ProductResult = {
  __typename?: "ProductResult";
  errors?: Maybe<Array<Maybe<Scalars["String"]>>>;
  id?: Maybe<Scalars["String"]>;
};

export enum ProductSize {
  L = "L",
  M = "M",
  NoSize = "NO_SIZE",
  Osfa = "OSFA",
  S = "S",
  Xl = "XL",
  Xs = "XS",
  Xxl = "XXL",
  Xxxl = "XXXL",
  Xxxxl = "XXXXL",
  Yl = "YL",
  Ym = "YM",
  Ys = "YS",
  Yxl = "YXL",
  Yxs = "YXS",
  Yxxl = "YXXL",
  Yxxxl = "YXXXL",
}

export enum ProductStatus {
  Failure = "FAILURE",
  Success = "SUCCESS",
}

export type ProfitPercentage = {
  __typename?: "ProfitPercentage";
  points_percentage?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["String"]>;
  updated_product_price_percentage?: Maybe<Scalars["String"]>;
};

export enum ProgramActivity {
  ActionSports = "ACTION_SPORTS",
  Archery = "ARCHERY",
  Art = "ART",
  Asb = "ASB",
  Avid = "AVID",
  Badminton = "BADMINTON",
  Band = "BAND",
  Baseball = "BASEBALL",
  Basketball = "BASKETBALL",
  Bowling = "BOWLING",
  Boxing = "BOXING",
  Business = "BUSINESS",
  Cheerleading = "CHEERLEADING",
  Chess = "CHESS",
  Choir = "CHOIR",
  Crew = "CREW",
  CrossCountry = "CROSS_COUNTRY",
  CultureAndLanguage = "CULTURE_AND_LANGUAGE",
  Cycling = "CYCLING",
  Dance = "DANCE",
  Debate = "DEBATE",
  Deca = "DECA",
  Drama = "DRAMA",
  Equestrian = "EQUESTRIAN",
  Fashion = "FASHION",
  Fbla = "FBLA",
  Fccla = "FCCLA",
  Fencing = "FENCING",
  FieldHockey = "FIELD_HOCKEY",
  FilmAndTvProduction = "FILM_AND_TV_PRODUCTION",
  Football = "FOOTBALL",
  FreshmanClass = "FRESHMAN_CLASS",
  Golf = "GOLF",
  GsaAndLgbtqi = "GSA_AND_LGBTQI",
  Gymnastics = "GYMNASTICS",
  Hockey = "HOCKEY",
  Hosa = "HOSA",
  Journalism = "JOURNALISM",
  JuniorClass = "JUNIOR_CLASS",
  KeyAndLink = "KEY_AND_LINK",
  Lacrosse = "LACROSSE",
  MartialArts = "MARTIAL_ARTS",
  ModelUnAndTrial = "MODEL_UN_AND_TRIAL",
  Music = "MUSIC",
  NationalHonorsSociety = "NATIONAL_HONORS_SOCIETY",
  NonProfit = "NON_PROFIT",
  OrchestraAndSymphony = "ORCHESTRA_AND_SYMPHONY",
  Other = "OTHER",
  Outdoors = "OUTDOORS",
  Photography = "PHOTOGRAPHY",
  Polo = "POLO",
  Powerlifting = "POWERLIFTING",
  PromAndHomecoming = "PROM_AND_HOMECOMING",
  Robotics = "ROBOTICS",
  Rotc = "ROTC",
  Rugby = "RUGBY",
  Sailing = "SAILING",
  Scholarship = "SCHOLARSHIP",
  SeniorClass = "SENIOR_CLASS",
  Shooting = "SHOOTING",
  Skiing = "SKIING",
  Soccer = "SOCCER",
  Softball = "SOFTBALL",
  SophomoreClass = "SOPHOMORE_CLASS",
  Stem = "STEM",
  StudentGovernment = "STUDENT_GOVERNMENT",
  Surf = "SURF",
  SwimAndDive = "SWIM_AND_DIVE",
  TableTennis = "TABLE_TENNIS",
  Tennis = "TENNIS",
  TrackAndField = "TRACK_AND_FIELD",
  UltimateFrisbee = "ULTIMATE_FRISBEE",
  VideoGame = "VIDEO_GAME",
  Volleyball = "VOLLEYBALL",
  WaterPolo = "WATER_POLO",
  WinterSports = "WINTER_SPORTS",
  Wrestling = "WRESTLING",
  Writing = "WRITING",
  Yearbook = "YEARBOOK",
}

export type ProgramLeadership = {
  __typename?: "ProgramLeadership";
  org: Org;
  programs: Array<ProgramWithTitle>;
};

export type ProgramWithTitle = {
  __typename?: "ProgramWithTitle";
  program: Org;
  titles: Array<Leader>;
};

export type Providers = {
  email?: InputMaybe<SupportedEmailProviders>;
  sms?: InputMaybe<SupportedSmsProviders>;
};

export type PublicFundraiserData = {
  __typename?: "PublicFundraiserData";
  alternateColor?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  donationMinDollars?: Maybe<Scalars["Int"]>;
  endDate?: Maybe<Scalars["DateTime"]>;
  fanStore?: Maybe<Scalars["Boolean"]>;
  goal?: Maybe<Scalars["Int"]>;
  hasCustomRewards?: Maybe<Scalars["Boolean"]>;
  id: Scalars["ID"];
  incStore?: Maybe<Scalars["Boolean"]>;
  isGuardianLedCampaign?: Maybe<Scalars["Boolean"]>;
  joinCode?: Maybe<Scalars["String"]>;
  logo?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  participantGoal?: Maybe<Scalars["Int"]>;
  personalMessage?: Maybe<Scalars["String"]>;
  primaryColor?: Maybe<Scalars["String"]>;
  programLeader?: Maybe<Scalars["String"]>;
  programType?: Maybe<Scalars["String"]>;
  secondaryColor?: Maybe<Scalars["String"]>;
  slug?: Maybe<Scalars["String"]>;
  startDate?: Maybe<Scalars["DateTime"]>;
  status?: Maybe<Scalars["String"]>;
  whyDonations?: Maybe<Scalars["String"]>;
};

export type PublicGroup = {
  __typename?: "PublicGroup";
  id: Scalars["String"];
  name: Scalars["String"];
  seasons?: Maybe<Array<PublicSeason>>;
};

export type PublicGroupResponse = {
  __typename?: "PublicGroupResponse";
  groups: Array<PublicGroup>;
};

export type PublicSeason = {
  __typename?: "PublicSeason";
  id: Scalars["String"];
  isLinkEnabled: Scalars["Boolean"];
  name: Scalars["String"];
  paymentScheduleStatus: Scalars["Boolean"];
};

export enum PurchaseIntentStatus {
  Cart = "CART",
  Purchased = "PURCHASED",
  Refunded = "REFUNDED",
  Removed = "REMOVED",
  Supermarket = "SUPERMARKET",
}

export type PurchaseItemInput = {
  costCents: Scalars["Int"];
  image: Scalars["String"];
  incentiveId: Scalars["Int"];
  name: Scalars["String"];
  netEarnedCents: Scalars["Float"];
  priceCents: Scalars["Int"];
  size: Scalars["String"];
};

export type PurchaseParticipantUpdateData = {
  fundraiserUserId: Scalars["Int"];
  participantUserDirectoryId: Scalars["String"];
};

export enum PurchaseStatus {
  Cancelled = "CANCELLED",
  Failed = "FAILED",
  Initiated = "INITIATED",
  Processing = "PROCESSING",
  Refunded = "REFUNDED",
  Refunding = "REFUNDING",
  Succeeded = "SUCCEEDED",
}

export type PurchaseUpdateInput = {
  /** Update donor full name */
  donorName?: InputMaybe<Scalars["String"]>;
  /** Update purchased gift shop item sizes */
  lineItems?: InputMaybe<Array<GiftShopPurchaseItemUpdate>>;
  /** Update with different Participant Data */
  participant?: InputMaybe<PurchaseParticipantUpdateData>;
};

export type Query = {
  __typename?: "Query";
  accountTransactions: Array<Maybe<Transaction>>;
  apiReady?: Maybe<Scalars["Boolean"]>;
  auditLogs?: Maybe<AuditLogList>;
  busSchedules?: Maybe<Array<Maybe<BusSchedules>>>;
  /** Return startDateTime and endDateTime as formatted UTC strings */
  campaignDates?: Maybe<CampaignDates>;
  campaignHistoryListGet?: Maybe<Array<Maybe<CampaignHistoryList>>>;
  campaignKyc?: Maybe<CampaignKyc>;
  /** Query uses Users JWT to find their Memberships See CampaignMembership type for details */
  campaignMemberships: Array<CampaignMembership>;
  /** List of saved search filters for campaigns of current user. */
  campaignSearchFiltersGet?: Maybe<Array<Maybe<CampaignSearchFilter>>>;
  /** List campaigns based on logged-in user */
  campaigns?: Maybe<CampaignList>;
  /** Retrieve contact details */
  commsContact?: Maybe<Array<CommsContactResponse>>;
  /** Retrieve Content */
  commsHtml?: Maybe<CommsHtmlResponse>;
  /** Retrieve message details */
  commsMessage: CommsMessageResponse;
  /** Retrieve template details */
  commsTemplate?: Maybe<CommsTemplateResponse>;
  /** Retrieve all templates for a service */
  commsTemplates?: Maybe<Array<CommsRegisterResponse>>;
  /** Retrieve Service Unsubscribe Groups */
  commsUnsubscribeGroups?: Maybe<CommsUnsubscribeGroupsResponse>;
  competitionList?: Maybe<Array<Maybe<TransformedEventReturn>>>;
  consumers?: Maybe<Array<Maybe<Consumer>>>;
  /** List of contact templates based on current user */
  customContactTemplatesGet?: Maybe<Array<Maybe<CustomContactTemplates>>>;
  dailyCalendarBusSchedules?: Maybe<TransformedDailyCalendarBusSchedule>;
  dailyCalendarEvents?: Maybe<
    Array<Maybe<TransformedDailyCalendarEventReturn>>
  >;
  dailyCalendarOfficials?: Maybe<TransformedDailyCalendarOfficials>;
  dailyCalendarPreparations?: Maybe<TransformedDailyCalendarPreparation>;
  dailyCalendarWorkers?: Maybe<TransformedDailyCalendarWorkers>;
  daySchedules?: Maybe<Array<Maybe<CommonSchedules>>>;
  donationLevels: Array<DonorsDonationLevel>;
  donationLevelsByFundraiserId: Array<DonationLevel>;
  /** @deprecated This can be queried for specific personas via CampaignMemberships. */
  donationTopEarner?: Maybe<DonationTopEarner>;
  donationsQuery?: Maybe<DonationsResponse>;
  donorDonateIntent?: Maybe<DonateIntent>;
  donorFundraiserData: DonorFundraiserData;
  donorPersonListEntries?: Maybe<Array<Maybe<DonorPersonListEntry>>>;
  /** @deprecated Use `donorsCheerwalls` query instead */
  donorsCheerwall?: Maybe<DonorsFundraiserCheerWall>;
  donorsCheerwalls?: Maybe<DonorsCheerwallsResult>;
  donorsDonation?: Maybe<DonorDonation>;
  donorsFundraiserPayables?: Maybe<Array<Maybe<DonorFundraiserPayable>>>;
  donorsGeneratePdfFlyer?: Maybe<Scalars["String"]>;
  donorsGiftShopIntent: GiftShopPurchaseIntentData;
  donorsGiftShopPurchase?: Maybe<DonorFundraiserPurchase>;
  donorsParticipantOTKIncentives?: Maybe<DonorsParticipantOtkIncentives>;
  donorsParticipantsGiftShop?: Maybe<DonorsParticipantsGiftShop>;
  donorsRedirects: Array<Redirect>;
  /** List all activity types of campaigns. Used by Drive */
  driveActivityTypes?: Maybe<Array<Maybe<Scalars["String"]>>>;
  /** Get details of campaigns. This endpoint need to considered to combined with searchDriveCampaign endpoint */
  driveCampaignDetails?: Maybe<DriveCampaignDetails>;
  /** Get statistics of the set of campaigns returned by the filter. This endpoint are meant to be used with the searchDriveCampaigns endpoint. Arguments should be the same. */
  driveCampaignSearchStats?: Maybe<DriveCampaignSearchStatistics>;
  /** Show details of a specific event */
  driveEvent?: Maybe<DriveEvent>;
  /** List events */
  driveEvents?: Maybe<DriveEventResults>;
  driveGetEarlyAccessFunds?: Maybe<EarlyAccess>;
  driveGetListActivitiesAvailableList?: Maybe<DriveGetListPotentialCustomersReturn>;
  driveGetListActivitiesNotAvailableList?: Maybe<DriveGetListPotentialCustomersReturn>;
  driveGetListCampaignHistoryList?: Maybe<DriveGetListCurrentCustomersReturn>;
  driveGetListCurrentCustomersList?: Maybe<DriveGetListCurrentCustomersReturn>;
  driveGetListHubspotProspectsList?: Maybe<DriveGetlistProspectsReturn>;
  driveGetListOrgList?: Maybe<DriveOrgListResults>;
  /** Returns Organization Summary information of single organization */
  driveGetListOrganizationSummary?: Maybe<DriveGetListOrganizationSummaryReturn>;
  /** @deprecated changed name to driveGetListActivitiesAvailableList */
  driveGetListPotentialCustomersList?: Maybe<DriveGetListPotentialCustomersReturn>;
  driveGetListWinbackList?: Maybe<DriveGetlistWinbackReturn>;
  driveGetProjectedRaised?: Maybe<EarlyAccess>;
  /** Return all tracking of current user to specific organization in Drive's Get List app. */
  driveOrgUserTrackings?: Maybe<Array<Maybe<DriveOrgUserTrackingReturn>>>;
  /** List organization types used by Drive */
  driveOrganizationTypes?: Maybe<Array<Maybe<Scalars["String"]>>>;
  /** List all salesreps */
  driveSalesreps?: Maybe<Array<Maybe<DriveAmSalesReps>>>;
  /** List all salesreps which had re-assigned any campaigns to current user. This endpoint is applicable for account-manager only. */
  driveSalesrepsByAccountManager?: Maybe<Array<Maybe<DriveAmSalesReps>>>;
  /** List all salesreps which managed by current user. This endpoint is applicable for sales manager only. */
  driveSalesrepsByManager?: Maybe<Array<Maybe<DriveAmSalesReps>>>;
  driveSmsInviteData?: Maybe<SmsInviteData>;
  /**
   * Search Raise users by UserDirectory 's id
   * @deprecated Use UserDirectory 's users query instead
   */
  driveUsers?: Maybe<DriveUsersResult>;
  /** List Event-Tracker's venues */
  driveVenues?: Maybe<Array<Maybe<DriveVenue>>>;
  event: Event;
  eventCalendar?: Maybe<Array<Maybe<CommonCalendar>>>;
  eventContractItems?: Maybe<Array<Maybe<ContractItem>>>;
  eventOfficial: Official;
  eventOfficials?: Maybe<Array<Maybe<Official>>>;
  eventParticipants: EventParticipants;
  eventPreparations: EventPreparations;
  eventPreparationsByEvent?: Maybe<Array<Maybe<EventPreparations>>>;
  eventTransportDetailsByEventOrDates?: Maybe<
    Array<Maybe<EventTransportDetails>>
  >;
  eventWorker: Worker;
  eventWorkers?: Maybe<Array<Maybe<Worker>>>;
  eventWorkersByEvent?: Maybe<Array<Maybe<Worker>>>;
  events?: Maybe<Array<Maybe<Event>>>;
  eventsParticipants?: Maybe<Array<Maybe<EventParticipants>>>;
  eventsPreparations?: Maybe<Array<Maybe<EventPreparations>>>;
  eventsTransportDetails?: Maybe<Array<Maybe<EventTransportDetails>>>;
  facilities?: Maybe<Array<Maybe<Facility>>>;
  facility: Facility;
  facilityCalendar?: Maybe<Array<Maybe<CommonCalendar>>>;
  facilityGrid?: Maybe<Array<Maybe<CommonGrid>>>;
  facilitySchedules?: Maybe<Array<Maybe<CommonSchedules>>>;
  family?: Maybe<Family>;
  feeReportSheets?: Maybe<Array<Maybe<CommonSheet>>>;
  /** Get all Financial Accounts that are associated with a Stripe Connect Account Id If you are looking for Financial Accounts associated with a parent Org, please use the 'financialAcctRoot' query. */
  financialAcctAcctId: Array<FinancialAcctOrg>;
  /** Get all Financial Accounts that are associated with an Org using the Org Id. If you are looking for Financial Accounts associated with a parent Org, please use the 'financialAcctRoot' query. */
  financialAcctOrg: Array<FinancialAcctOrg>;
  /** Get all Financial Accounts that are associated with an Org using the Kyb Id. If you are looking for Financial Accounts associated with a parent Org, please use the 'financialAcctRoot' query. */
  financialAcctOrgByKyb: Array<FinancialAcctOrg>;
  /** Get all Financial Accounts that are associated with a parent Org. This includes parents of parents and all the way up the tree. This will NOT return the Financial Accounts for the target Org, please use the 'financialAcctOrg' query for specific Orgs. */
  financialAcctRoot: Array<FinancialAcctOrg>;
  /** Get all User data associated with a single Financial Account with an Orgs Platform Id. Eg: 'finacct_stripe_abcd1234' */
  financialAcctUsers: Array<FinancialAcctUser>;
  /** Get all User and Financial Account data associated with a single Kyc Id. Eg: 'vkyc_abcd1234' */
  financialAcctUsersByKyc: Array<FinancialAcctUser>;
  fundraiserApprovalSubmissions: Array<Maybe<FundraiserApprovalSubmission>>;
  /** Get fundraiser approval submissions authenticating request by approver's token */
  fundraiserApprovalSubmissionsHistory: Array<FundraiserApprovalSubmission>;
  /** Get fundraiser approval submission related org names authenticating request by approver's token */
  fundraiserApprovalSubmissionsOrgNames: FundraiserApprovalSubmissionsOrgNamesResult;
  fundraiserCheerwall?: Maybe<FundraiserCheerwall>;
  fundraiserCustomRewardsDetails?: Maybe<
    Array<Maybe<FundraiserCustomRewardsData>>
  >;
  fundraiserDataByCoachJoinCode?: Maybe<FundraiserDataByCoachJoinCode>;
  /** resourceId is the fundraiserId */
  fundraiserEntityResources?: Maybe<FundraiserEntityResource>;
  fundraiserGroups?: Maybe<Array<Maybe<FundraiserGroup>>>;
  fundraiserRaisedAmount?: Maybe<FundraiserRaisedAmount>;
  fundraiserRewardsDetails?: Maybe<Array<Maybe<FundraiserRewardsProduct>>>;
  fundraiserTopDonation?: Maybe<FundraiserTopDonation>;
  fundraiserUserRole?: Maybe<FundraiserUserRole>;
  galleryItems?: Maybe<GalleryItems>;
  getCampaignRoster?: Maybe<Array<Maybe<Roster>>>;
  getFundraiserUserID?: Maybe<Scalars["Int"]>;
  getInsightsUserOrgs: Array<Maybe<InsOrg>>;
  getSalesRepHome: HmSalesRep;
  getUserSavedSalesRep: UserSavedRep;
  /** return the most recently created fundraiser associated with the group leader */
  groupLeaderDefaultFundraiser?: Maybe<GroupLeaderFundraiser>;
  /** return a fundraiser by id */
  groupLeaderFundraiser?: Maybe<GroupLeaderFundraiser>;
  /** return a list of fundraisers associated with the group leader, paginated, and filtered by role and status */
  groupLeaderFundraisers?: Maybe<GroupLeaderFundraisersPaginated>;
  /** List of group leaders */
  groupLeaders?: Maybe<GroupLeaderList>;
  helpDocumentsList?: Maybe<Array<Maybe<Scalars["String"]>>>;
  infoSheets?: Maybe<Array<Maybe<CommonSheet>>>;
  insightsCampaignDonations: Array<Maybe<InsCampaignDonation>>;
  insightsCampaignRaiseEntityInfo: InsCampaignRaiseEntityInfo;
  insightsCampaignSettlements: Array<Maybe<InsCampaignSettlement>>;
  insightsCampaignsData: InsCampaignsData;
  insightsDonorParticipantContacts: Array<Maybe<InsDonorParticipantContact>>;
  insightsNotificationPreferences: InsightsUserOrgsNotificationPreferencesResponse;
  insightsOrgsCampaignsSummary: Array<Maybe<InsOrgCampaignSummary>>;
  insightsPreApprovedContacts: Array<Maybe<InsPreApprovedContact>>;
  insightsPreApprovedContactsEmailTest: Array<
    Maybe<InsEmailTestPreApprovedContactResult>
  >;
  insightsSalesRepInfo?: Maybe<InsSalesRepInfo>;
  insightsUserPreferences?: Maybe<Array<Maybe<InsightsGetUserPreference>>>;
  invite?: Maybe<InviteInfo>;
  invites?: Maybe<Array<Maybe<InviteList>>>;
  invitesList?: Maybe<InvitesListResponse>;
  /** joinCode is a string that is used to join a fundraiser */
  joinCodeType: JoinCodeType;
  legacyTransactions: LegacyTransactionsOutput;
  level: Level;
  levelGrid?: Maybe<Array<Maybe<CommonGrid>>>;
  levels?: Maybe<Array<Maybe<Level>>>;
  manageCoach?: Maybe<ManageCoach>;
  manageCoachList?: Maybe<ManageCoachList>;
  manageEvent?: Maybe<ManageEvent>;
  manageEventList?: Maybe<ManageEventList>;
  /** Get a manage organization by web folder */
  manageOrganization?: Maybe<ManageOrganization>;
  /** Get a list of all manage organizations */
  manageOrganizationList?: Maybe<ManageOrganizationList>;
  managePlayerList?: Maybe<ManagePlayerList>;
  manageProgram?: Maybe<ManageProgram>;
  manageProgramList?: Maybe<ManageProgramList>;
  manageSeason?: Maybe<ManageSeason>;
  manageSeasonList?: Maybe<ManageSeasonList>;
  manageUser?: Maybe<ManageUser>;
  manageUsersList?: Maybe<ManageUsersList>;
  me?: Maybe<UserWithPermissions>;
  messagesChannel?: Maybe<ChatChannelResult>;
  messagesChannelMembers?: Maybe<MessagesChannelMembersResult>;
  messagesChannels?: Maybe<ChatChannelsResult>;
  messagesMyChannels?: Maybe<MessagesMyChannelsResult>;
  messagesUnreadCount?: Maybe<ChatUnreadMessagesResult>;
  notices?: Maybe<Array<Maybe<Notice>>>;
  officialAssignments?: Maybe<OfficialAssignmentsReturn>;
  officialDuties?: Maybe<Array<Maybe<OfficialDuty>>>;
  officialDuty?: Maybe<OfficialDuty>;
  officialListsReport?: Maybe<Array<Maybe<EventOfficialReport>>>;
  officialPoolById: OfficialPool;
  officialPools?: Maybe<Array<Maybe<OfficialPool>>>;
  officialSchedules?: Maybe<Array<Maybe<OfficialSchedules>>>;
  opponent: Opponent;
  opponentEventsApplyFilters?: Maybe<Array<Maybe<EventsOpponent>>>;
  opponentSchedules?: Maybe<Array<Maybe<CommonSchedules>>>;
  opponents?: Maybe<Array<Maybe<Opponent>>>;
  /** Get Org Node properties by its Org id. If hierarchy is set to true, it will return all the children of the organization, but NOT the target Org. 'fields' returns JSON of all remaining properties. You can optionally limit this to specific fields by passing in an array of keys. Example: "names": [ "country", "zip_code" ] */
  org?: Maybe<Array<Maybe<Org>>>;
  /** Get Org Node properties from the campaign's Org id (eg: "cam_abc123") You can request a specifc Org type by passing in a label. If hierarchy is set to true, this query will return every Org all the way to the root */
  orgCampaignId?: Maybe<Array<Maybe<Org>>>;
  /** Get all Campaigns under a specific Org. This will return an array of all Campaigns in the type of Org, sorted by start_date descending. */
  orgCampaigns?: Maybe<Array<Maybe<Org>>>;
  /** Get fundraiser approval submission associated to orgs. Latest submission only. */
  orgFundraiserApprovalSubmissions: Array<FundraiserApprovalSubmission>;
  /** Get Org Node properties by its Hubspot id. */
  orgHubspotId: Array<Org>;
  /** Get an Organization's Org platform id by its Raise entities.id OR Get a Campaign's Org platform id by its Raise fundraisers.id This ONLY returns an Org id, not the full Org Node. */
  orgId?: Maybe<Scalars["ID"]>;
  /** Get the Org paid by a given campaign id. This required an Org Platform Campaign Id (eg: "cam_abc123") If you only have a Raise Fundraiser Id, you can use the 'orgId' query to find it. */
  orgPayable?: Maybe<Array<Maybe<Org>>>;
  /** Fuzzy search for Orgs by their name property, returned in order of match quality. Optionally accepts label, ein, city, two letter state code, or zip code. This search has a default limit of 25 and a maximim limit of 100 responses. */
  orgSearch: Array<Org>;
  /** Get all Users that are associated with a specific Org. */
  orgStaffRoster: Array<OrgStaffMember>;
  /** Get all Users that are associated with an Org and a specific product. This includes role and a boolean if the user is internal (snap-raise or snapraise email). */
  orgUserAssociations: Array<Maybe<UserNode>>;
  /** Search for Orgs by their name property, with an option to specify label. This will only return the first 100 orgs. */
  orgs: Array<Org>;
  /**
   * If you are simply searching by name property, please use the 'orgs' query. This advanced search searches by type or specific properties. Default limit is 25 items.
   * @deprecated Please use orgSearch instead
   */
  orgsSearch?: Maybe<OrgsSearchResponse>;
  /** This query will return OTK Fundraiser Product details */
  otkFundraiserData?: Maybe<Array<Maybe<OtkFundraiserProduct>>>;
  /** This query will return OTK Participant details */
  otkParticipantDetails?: Maybe<Array<Maybe<OtkParticipantData>>>;
  /** This query will return the most recent OTK Participant details */
  otkPastParticipantDetails?: Maybe<Array<Maybe<OtkParticipantData>>>;
  participantCampaignConfigurationData?: Maybe<ParticipantCampaignConfiguration>;
  participantCheerwall?: Maybe<ParticipantCheerwall>;
  participantDonationsRaised: ParticipantDonationsRaised;
  /** This query will return the incentives a Participant earned for a given fundraiser */
  participantEarnedRewardsDetails?: Maybe<Array<Maybe<ParticipantRewardsData>>>;
  /** Accepts a udid and returns a participantFundraiser object */
  participantFundraiser?: Maybe<ParticipantFundraiser>;
  participantFundraiserReward?: Maybe<ParticipantReward>;
  participantFundraisers: Array<Maybe<ParticipantFundraiser>>;
  participantGuardianFundraisers?: Maybe<
    Array<Maybe<ParticipantGuardianFundraiser>>
  >;
  participantPublic?: Maybe<ParticipantPublic>;
  /** This query will return Participant Rewards details */
  participantRewardsDetails?: Maybe<Array<Maybe<ParticipantRewardsData>>>;
  participantSSOId?: Maybe<ParticipantSsoid>;
  participantShareMessage: Scalars["String"];
  participantTextTemplate?: Maybe<ParticipantTextTemplate>;
  participantTopDonation?: Maybe<ParticipantTopDonation>;
  /** List of participants */
  participants?: Maybe<ParticipantList>;
  participantsListV2?: Maybe<Array<Maybe<FundraiserParticipants>>>;
  participantsPublic?: Maybe<Array<Maybe<FundraiserParticipantPublic>>>;
  paymentsApiCustomerByEmail: PaymentsApiCustomerResponse;
  payouts: Array<Maybe<Payout>>;
  permissions?: Maybe<Array<Maybe<Permission>>>;
  /** List of pre-made contact templates */
  premadeContactTemplatesGet?: Maybe<Array<Maybe<PremadeContactTemplate>>>;
  preparation: Preparation;
  preparationReportSheets?: Maybe<Array<Maybe<PreparationSheet>>>;
  preparations?: Maybe<Array<Maybe<Preparation>>>;
  /** This resolver is only for testing purposes at this time */
  primaryGroupLeader?: Maybe<PrimaryGroupLeader>;
  /** Identifier uses fundraiser join_code or slug */
  publicFundraiserData?: Maybe<PublicFundraiserData>;
  /** Get user public data from User Directory by userID */
  publicUserData?: Maybe<User>;
  /** A query to get last year's fundraiser by the id of this year's fundraiser */
  raiseAdminFundraiserPast?: Maybe<RaiseAdminFundraiserPast>;
  role?: Maybe<Role>;
  roles?: Maybe<Array<Maybe<Role>>>;
  schoolInfo: SchoolInfo;
  schoolInfos?: Maybe<Array<Maybe<SchoolInfo>>>;
  /** Search campaigns */
  searchDriveCampaigns?: Maybe<DriveCampaignList>;
  /** Search Raise's Organization. @Deprecated: should be replaced by Orgs API */
  searchDriveOrganizationsByName?: Maybe<DriveOrganizationList>;
  season: Season;
  seasonByYearAndTeam?: Maybe<Array<Maybe<Season>>>;
  seasonEventWizard?: Maybe<Array<Maybe<Event>>>;
  seasons?: Maybe<Array<Maybe<Season>>>;
  shipments: ShipmentResponse;
  spendAccountExport?: Maybe<ExportFile>;
  spendBudget?: Maybe<SpendBudgetResponse>;
  spendBudgetExport?: Maybe<ExportFile>;
  spendBudgetTransactions: SpendBudgetTransactionsOutput;
  spendBudgets?: Maybe<SpendBudgetsResponse>;
  spendBudgetsProgramSummary?: Maybe<SpendBudgetSummaryResponse>;
  spendBudgetsSummary?: Maybe<SpendBudgetSummaryResponse>;
  spendBudgetsSummaryV2?: Maybe<SpendBudgetSummaryV2Response>;
  spendCategories?: Maybe<SpendCategoryResponse>;
  spendDataForHome: HmSpendData;
  spendDebitCardInvites?: Maybe<SpendInviteResponse>;
  spendGetGroupCounterparties?: Maybe<SpendCounterpartiesResponse>;
  spendGetInstitutionByRoutingNumber?: Maybe<SpendInstitutionResponse>;
  spendGetOrganizationCounterparties?: Maybe<SpendCounterpartiesResponse>;
  spendGroup?: Maybe<SpendGroup>;
  spendGroupBankAccounts?: Maybe<SpendBankAccountsResponse>;
  spendGroupBankLinkTokenCreate?: Maybe<SpendBankLinkCreateResponse>;
  spendGroupById?: Maybe<SpendGroup>;
  spendGroupDebitCards?: Maybe<SpendDebitCardResponse>;
  spendGroupPayees?: Maybe<SpendPayeeResponse>;
  spendGroupRosters?: Maybe<SpendGroupRosterResponse>;
  spendGroupRostersBySeason?: Maybe<SpendGroupRosterResponse>;
  spendGroupRostersBySeasonV2?: Maybe<SpendGroupRosterResponse>;
  spendGroups?: Maybe<SpendGroupResponse>;
  /** List groups with pagination/filtering and sorting. Valid sort.field: name, seasonStartDate, seasonEndDate, playerCount, paid, upcoming, pastDue, dueToday, processing, paymentScheduleStatus */
  spendGroupsFiltered?: Maybe<SpendGroupsResponse>;
  spendGroupsOverviewDashboard?: Maybe<GroupsOverviewDashboardResponse>;
  spendGroupsPublic?: Maybe<PublicGroupResponse>;
  spendGuardianHighlight?: Maybe<SpendGuardianHighlight>;
  spendGuardianInvoices?: Maybe<SpendGuardianInvoiceResponse>;
  spendInvite?: Maybe<SpendInviteResponse>;
  spendInvites?: Maybe<SpendInviteResponse>;
  spendInvitesFiltered?: Maybe<SpendInviteResponse>;
  spendInvoiceById?: Maybe<SpendInvoice>;
  spendInvoiceHistory?: Maybe<SendEmailResponse>;
  spendInvoices?: Maybe<SpendInvoiceResponse>;
  spendInvoicesExport?: Maybe<ExportFile>;
  spendInvoicesExportV2?: Maybe<ExportFile>;
  spendInvoicesFiltered?: Maybe<SpendInvoiceResponse>;
  spendOrganization?: Maybe<SpendOrganization>;
  spendOrganizationAccountLimits?: Maybe<SpendAccountLimitsResponse>;
  spendOrganizationAccounts?: Maybe<SpendAccountResponse>;
  spendOrganizationBankAccounts?: Maybe<SpendBankAccountsResponse>;
  spendOrganizationBankLinkTokenCreate?: Maybe<SpendBankLinkCreateResponse>;
  spendOrganizationById?: Maybe<SpendOrganization>;
  spendOrganizationDebitCards?: Maybe<SpendDebitCardResponse>;
  spendOrganizationPayees?: Maybe<SpendPayeeResponse>;
  spendOrganizations?: Maybe<SpendOrganizationsResponse>;
  spendPaginatedInvoices?: Maybe<SpendInvoiceResponse>;
  spendPastDueInvoices?: Maybe<SpendPastDueInvoicesOutput>;
  spendPaymentScheduleById?: Maybe<SpendPaymentSchedule>;
  spendPaymentSchedules?: Maybe<Array<Maybe<SpendPaymentSchedule>>>;
  spendRoleCurrent?: Maybe<SpendRole>;
  spendRoles?: Maybe<SpendRoleResponse>;
  spendRosters?: Maybe<SpendRosterResponse>;
  spendRostersFiltered?: Maybe<SpendRosterResponse>;
  spendSeason?: Maybe<SpendSeason>;
  spendSettings?: Maybe<SpendSettings>;
  spendTransaction?: Maybe<SpendBankTransactionResponse>;
  spendTransactionAttachment?: Maybe<TransactionAttachmentsResponse>;
  spendTransactionAttachmentsByPaymentId?: Maybe<TransactionAttachmentsResponse>;
  spendTransactionNotes?: Maybe<TransactionNotesResponse>;
  spendTransactionNotesByPaymentId?: Maybe<TransactionNotesResponse>;
  /** @deprecated Use spendTransactionsFiltered */
  spendTransactions?: Maybe<SpendBankTransactionsResponse>;
  spendTransactionsExport?: Maybe<ExportFile>;
  spendTransactionsFiltered?: Maybe<SpendBankTransactionsResponse>;
  spendTransactionsLegacy?: Maybe<SpendLegacyTransactionsResponse>;
  spendUserAccountBearerToken?: Maybe<SpendAccountBearerTokenResponse>;
  spendUserBankAccounts?: Maybe<SpendBankAccountsResponse>;
  spendUserBankLinkTokenCreate?: Maybe<SpendBankLinkCreateResponse>;
  spendUserExportEmails?: Maybe<ExportFile>;
  spendUserNotificationSetting?: Maybe<SpendUserNotificationSettingResponse>;
  spendUserVerificationToken?: Maybe<SpendVerificationTokenResponse>;
  sponsorDataForHome: HmSponsorData;
  storeAllBrands?: Maybe<StoreBrandsUnionType>;
  storeAllColors?: Maybe<StoreColorsUnionType>;
  storeBestSeller?: Maybe<StoreBaseSellerUnionType>;
  storeByScopeId?: Maybe<MagentoStoreUnionType>;
  storeCampaignPayableInfo?: Maybe<StoreCampaignPayableInfoUnionType>;
  storeDataForHome: HmStore;
  storeEarnedPointsGraph?: Maybe<MagentoStorePointsEarnedUnionType>;
  storeGLCampaigns?: Maybe<StoreUserCampaignUnionType>;
  storeGLParticipants?: Maybe<StoreUserParticipantUnionType>;
  storeOrderFilter?: Maybe<OrdersFilterUnionType>;
  storeOrderItemImages?: Maybe<StoreOrderItemsUnionType>;
  storeOrderSalesGraph?: Maybe<StoreOrderSalesGraphUnionType>;
  storeOrderSummary?: Maybe<OrdersSummaryUnionType>;
  storeOrdersCsv?: Maybe<MagentoOrderUnionType>;
  storePayableFilter?: Maybe<StorePayableFilterUnionType>;
  /** @deprecated Use storePointActivityFilterAdvance Query */
  storePointActivityFilter?: Maybe<PointActivityFilterUnionType>;
  storePointActivityFilterAdvance?: Maybe<PointActivityFilterUnionType>;
  /** @deprecated Use storePointsActivityCsvAdvance Query */
  storePointsActivityCsv?: Maybe<MagentoPointActivityUnionType>;
  storePointsActivityCsvAdvance?: Maybe<MagentoPointActivityUnionType>;
  storePointsWithdrawalRequestFilter?: Maybe<StorePointsWithdrawalFilterUnionType>;
  storeProfitPercentage?: Maybe<ProfitPercentage>;
  storeScopePayableInfo?: Maybe<StorePayableInfoUnionType>;
  storeSubscribedUser?: Maybe<StoreSubscribedUserUnionType>;
  storeTickets?: Maybe<StoreTicketUnionType>;
  storeUserDetails?: Maybe<StoreUserInfoUnionType>;
  storeUserPreference?: Maybe<UserPreference>;
  storesByCampaignIds?: Maybe<StoreFilterUnionType>;
  storesByGLEmail?: Maybe<StoreFilterUnionType>;
  storesSummaryByCampaignIds?: Maybe<StoresSummaryUnionType>;
  storesSummaryByGLEmail?: Maybe<StoresSummaryUnionType>;
  team: Team;
  teamOfficials?: Maybe<Array<Maybe<TemplateOfficial>>>;
  teamOrgSchedules?: Maybe<Array<Maybe<CommonSchedules>>>;
  teamPreparations?: Maybe<Array<Maybe<TemplatePreparation>>>;
  teamWorkers?: Maybe<Array<Maybe<TemplateWorker>>>;
  teams?: Maybe<Array<Maybe<Team>>>;
  transactionDetail?: Maybe<TransactionDetail>;
  transactions: Array<Maybe<Transaction>>;
  transactionsByAccount: Array<Maybe<Transaction>>;
  transactionsByAccountPaginated: AccountTransactionsPaginatedOutput;
  transactionsBySpendDestination: Array<Maybe<Transaction>>;
  transactionsBySpendSource: Array<Maybe<Transaction>>;
  twispCardBalances?: Maybe<Array<Maybe<TwispCardBalances>>>;
  twispCards?: Maybe<Array<Maybe<TwispCards>>>;
  twispStripeHooks?: Maybe<Array<Maybe<TwispStripeHooks>>>;
  twispTransactions?: Maybe<Array<Maybe<TwispTransactions>>>;
  unconfirmedEvents?: Maybe<UnconfirmedEventsList>;
  updateSavedSalesRep: UserSavedRep;
  user?: Maybe<User>;
  /** Get all Programs/Activities that a User leads, grouped by parent Org. */
  userActivityLeadership: Array<ProgramLeadership>;
  /** Get all Orgs that are affiliated with a User. */
  userAffiliations: Array<OrgAffiliation>;
  userApiKey?: Maybe<ApiKey>;
  userApiKeyBySecret?: Maybe<ApiKey>;
  userApiKeys?: Maybe<Array<Maybe<ApiKey>>>;
  /** Get all Org ids that are associated with a single User and a specific product, as well all the Campaign ids in each hierarchy. */
  userAssociationCampaigns: Array<OrgCampaignIds>;
  /** Get all Orgs that are associated with a User and a specific product. */
  userAssociations: Array<Maybe<Org>>;
  userBySecret?: Maybe<UserWithPermissions>;
  /** Get all Financial Account data associated with a single User Directory ID. */
  userFinancialAccounts: Array<FinancialAcctUser>;
  userFitting?: Maybe<UserFitting>;
  /** Get a list of Campaigns grouped by Org that User has been a Group Leader for. */
  userGroupLeadership: Array<OrgCampaignAffiliation>;
  /** Get a list of implied affiliations based on GroupLeader relationships. */
  userImpliedAffiliations: Array<OrgCampaignAffiliation>;
  /** Get all Insights configurations for current user */
  userInsightsConfigurations: Array<Maybe<UserInsightsConfig>>;
  /** Get all titles that are associated with a User and a specific Org. */
  userOrgAffiliation: Array<UserAffiliation>;
  /** Get a list Campaigns grouped by Org that User has been a Participant in. */
  userParticipation: Array<OrgCampaignAffiliation>;
  userPermissions?: Maybe<UserPermissionsList>;
  /** @deprecated use userPublicInfo instead */
  userPublic?: Maybe<UserPublic>;
  userPublicInfo?: Maybe<Array<Maybe<UserPublicInfoResult>>>;
  /** Get all Teams that a User leads. */
  userTeamLeadership: Array<TeamLeadership>;
  userToken: Auth;
  /** Get counts of unconfirmed LEADS, AFFILIATIONS, and IMPLIED affiliations for a User. */
  userUnconfirmedAffiliations: UserAffiliationCounts;
  users?: Maybe<Users>;
  vaultAccountStatus: VaultAccountStatus;
  vaultCard: Card;
  vaultKyb: VaultKyb;
  vaultKyc: VaultKyc;
  vaultStripeConnectAccountBankAccountListFetch?: Maybe<
    Array<Maybe<VaultBankAccount>>
  >;
  vaultStripeConnectAccountFetch?: Maybe<Scalars["JSONObject"]>;
  vaultStripeConnectAccountsBankAccountListFetch?: Maybe<Scalars["JSONObject"]>;
  vaultStripeInvalidRepresentative?: Maybe<Scalars["ID"]>;
  vaultVgsValue: Scalars["String"];
  vehicle: Vehicle;
  vehicles?: Maybe<Array<Maybe<Vehicle>>>;
  workerPoolById: WorkerPool;
  workerPools?: Maybe<Array<Maybe<WorkerPool>>>;
};

export type QueryAccountTransactionsArgs = {
  input: AccountTransactionsInput;
};

export type QueryAuditLogsArgs = {
  limit?: Scalars["Int"];
  offset?: Scalars["Int"];
};

export type QueryBusSchedulesArgs = {
  input?: InputMaybe<CommonSchedulesFiltersInput>;
};

export type QueryCampaignDatesArgs = {
  campaignId: Scalars["String"];
};

export type QueryCampaignHistoryListGetArgs = {
  fundraiserId?: InputMaybe<Scalars["Int"]>;
};

export type QueryCampaignKycArgs = {
  campaignId: Scalars["String"];
};

export type QueryCampaignMembershipsArgs = {
  basicStatus?: InputMaybe<BasicCampaignStatus>;
  fundraiserId?: InputMaybe<Scalars["ID"]>;
};

export type QueryCampaignsArgs = {
  accountManagerIds?: InputMaybe<Array<Scalars["Int"]>>;
  daysClosed?: InputMaybe<Scalars["String"]>;
  daysToLaunch?: InputMaybe<Scalars["String"]>;
  fetchAllCampaigns?: InputMaybe<Scalars["Boolean"]>;
  getTeamSelling?: InputMaybe<Scalars["Boolean"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  salesrepIds?: InputMaybe<Array<Scalars["Int"]>>;
  searchTerm?: InputMaybe<Scalars["String"]>;
  sortDirection?: InputMaybe<SortDirection>;
  sortField?: InputMaybe<SortField>;
  status?: InputMaybe<Scalars["String"]>;
};

export type QueryCommsContactArgs = {
  id: Scalars["ID"];
};

export type QueryCommsHtmlArgs = {
  data?: InputMaybe<Scalars["JSON"]>;
  mjml: Scalars["String"];
};

export type QueryCommsMessageArgs = {
  id: Scalars["ID"];
};

export type QueryCommsTemplateArgs = {
  id: Scalars["ID"];
};

export type QueryCommsTemplatesArgs = {
  name?: InputMaybe<Scalars["String"]>;
  service?: InputMaybe<SnapService>;
  transport?: InputMaybe<MessageTransportType>;
};

export type QueryCommsUnsubscribeGroupsArgs = {
  service?: InputMaybe<SnapService>;
};

export type QueryCompetitionListArgs = {
  input?: InputMaybe<GetCompetitionListInput>;
};

export type QueryConsumersArgs = {
  hidden?: InputMaybe<Scalars["Boolean"]>;
};

export type QueryDailyCalendarBusSchedulesArgs = {
  input?: InputMaybe<GetDailyCalendarBusScheduleByDateInput>;
};

export type QueryDailyCalendarEventsArgs = {
  input?: InputMaybe<GetDailyCalendarEventsByDateInput>;
};

export type QueryDailyCalendarOfficialsArgs = {
  input?: InputMaybe<GetDailyCalendarOfficialsByDateInput>;
};

export type QueryDailyCalendarPreparationsArgs = {
  input?: InputMaybe<GetDailyCalendarPreparationsByDateInput>;
};

export type QueryDailyCalendarWorkersArgs = {
  input?: InputMaybe<GetDailyCalendarWorkersByDateInput>;
};

export type QueryDaySchedulesArgs = {
  input?: InputMaybe<CommonSchedulesFiltersInput>;
};

export type QueryDonationLevelsArgs = {
  raiseFundraiserId: Scalars["Int"];
};

export type QueryDonationLevelsByFundraiserIdArgs = {
  fundraiserId: Scalars["String"];
};

export type QueryDonationTopEarnerArgs = {
  fundraiserId: Scalars["String"];
};

export type QueryDonationsQueryArgs = {
  fundraiserId: Scalars["Int"];
  participantId?: InputMaybe<Scalars["Int"]>;
};

export type QueryDonorDonateIntentArgs = {
  id: Scalars["ID"];
};

export type QueryDonorFundraiserDataArgs = {
  slug?: InputMaybe<Scalars["String"]>;
};

export type QueryDonorPersonListEntriesArgs = {
  fundraiserId: Scalars["Int"];
  userId: Scalars["Int"];
};

export type QueryDonorsCheerwallArgs = {
  fundraiserId: Scalars["Int"];
  participantId?: InputMaybe<Scalars["Int"]>;
  take: Scalars["Int"];
};

export type QueryDonorsCheerwallsArgs = {
  fundraiserId: Scalars["Int"];
  participantId?: InputMaybe<Scalars["Int"]>;
  take: Scalars["Int"];
};

export type QueryDonorsDonationArgs = {
  donateIntentId?: InputMaybe<Scalars["String"]>;
  donationId?: InputMaybe<Scalars["String"]>;
};

export type QueryDonorsFundraiserPayablesArgs = {
  input: DonorFundraiserPayablesInput;
};

export type QueryDonorsGeneratePdfFlyerArgs = {
  participantId?: InputMaybe<Scalars["ID"]>;
  slug: Scalars["String"];
};

export type QueryDonorsGiftShopIntentArgs = {
  purchaseIntentId: Scalars["String"];
};

export type QueryDonorsGiftShopPurchaseArgs = {
  purchaseId: Scalars["String"];
};

export type QueryDonorsParticipantOtkIncentivesArgs = {
  fundraiserId: Scalars["String"];
  participantId: Scalars["String"];
};

export type QueryDonorsParticipantsGiftShopArgs = {
  fundraiserId: Scalars["String"];
  participantId: Scalars["String"];
};

export type QueryDonorsRedirectsArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type QueryDriveCampaignDetailsArgs = {
  fundraiserId: Scalars["Int"];
};

export type QueryDriveCampaignSearchStatsArgs = {
  activityTypes?: InputMaybe<Array<Scalars["String"]>>;
  campaignName?: InputMaybe<Scalars["String"]>;
  campaignStatuses?: InputMaybe<Array<CampaignStatus>>;
  endDate?: InputMaybe<Scalars["DateTime"]>;
  fundraiserId?: InputMaybe<Scalars["Int"]>;
  hasIncentive?: InputMaybe<Scalars["Boolean"]>;
  maxTeamSize?: InputMaybe<Scalars["Int"]>;
  maxTotalRaisedCents?: InputMaybe<Scalars["Int"]>;
  minTeamSize?: InputMaybe<Scalars["Int"]>;
  minTotalRaisedCents?: InputMaybe<Scalars["Int"]>;
  organizationIds?: InputMaybe<Array<Scalars["Int"]>>;
  organizationTypes?: InputMaybe<Array<Scalars["String"]>>;
  salesrepIds?: InputMaybe<Array<Scalars["Int"]>>;
  startDate?: InputMaybe<Scalars["DateTime"]>;
  territories?: InputMaybe<Array<Scalars["String"]>>;
  usStates?: InputMaybe<Array<Scalars["String"]>>;
};

export type QueryDriveEventArgs = {
  id: Scalars["ID"];
};

export type QueryDriveEventsArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  sortBy?: InputMaybe<SortDirection>;
  sortField?: InputMaybe<Scalars["String"]>;
  where?: InputMaybe<DriveEventSearchInput>;
};

export type QueryDriveGetEarlyAccessFundsArgs = {
  activityType: Scalars["String"];
  campaignStatus?: InputMaybe<Scalars["String"]>;
  fundraiserId: Scalars["Int"];
  participantCount: Scalars["Int"];
  state: Scalars["String"];
};

export type QueryDriveGetListActivitiesAvailableListArgs = {
  hubspotId?: InputMaybe<Scalars["String"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  sortField?: InputMaybe<Scalars["String"]>;
};

export type QueryDriveGetListActivitiesNotAvailableListArgs = {
  hubspotId?: InputMaybe<Scalars["String"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  sortBy?: InputMaybe<SortDirection>;
  sortField?: InputMaybe<Scalars["String"]>;
};

export type QueryDriveGetListCampaignHistoryListArgs = {
  hubspotId?: InputMaybe<Scalars["String"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  sortField?: InputMaybe<Scalars["String"]>;
};

export type QueryDriveGetListCurrentCustomersListArgs = {
  hubspotId?: InputMaybe<Scalars["String"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  sortField?: InputMaybe<Scalars["String"]>;
};

export type QueryDriveGetListHubspotProspectsListArgs = {
  hubspotId?: InputMaybe<Scalars["String"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  sortField?: InputMaybe<Scalars["String"]>;
};

export type QueryDriveGetListOrgListArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orgName?: InputMaybe<Scalars["String"]>;
  sortBy?: InputMaybe<SortDirection>;
  sortField?: InputMaybe<Scalars["String"]>;
};

export type QueryDriveGetListOrganizationSummaryArgs = {
  hubspotId?: InputMaybe<Scalars["String"]>;
  orgId: Scalars["Int"];
};

export type QueryDriveGetListPotentialCustomersListArgs = {
  hubspotId?: InputMaybe<Scalars["String"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  sortField?: InputMaybe<Scalars["String"]>;
};

export type QueryDriveGetListWinbackListArgs = {
  hubspotId?: InputMaybe<Scalars["String"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  sortField?: InputMaybe<Scalars["String"]>;
};

export type QueryDriveGetProjectedRaisedArgs = {
  activityType: Scalars["String"];
  participantCount: Scalars["Int"];
  state: Scalars["String"];
};

export type QueryDriveOrgUserTrackingsArgs = {
  orgId: Scalars["String"];
  where?: InputMaybe<DriveOrgUserTrackingSearchInput>;
};

export type QueryDriveSalesrepsByManagerArgs = {
  type?: InputMaybe<SalesrepType>;
};

export type QueryDriveSmsInviteDataArgs = {
  fundraiserId: Scalars["Int"];
};

export type QueryDriveUsersArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<DriveUserSearchInput>;
};

export type QueryDriveVenuesArgs = {
  where?: InputMaybe<DriveVenueSearchInput>;
};

export type QueryEventArgs = {
  input?: InputMaybe<GetEventByIdInput>;
};

export type QueryEventCalendarArgs = {
  input?: InputMaybe<EventCalendarFiltersInput>;
};

export type QueryEventContractItemsArgs = {
  input?: InputMaybe<EventContractItemInput>;
};

export type QueryEventOfficialArgs = {
  input?: InputMaybe<GetOfficialByIdInput>;
};

export type QueryEventParticipantsArgs = {
  input?: InputMaybe<GetEventParticipantsByIdInput>;
};

export type QueryEventPreparationsArgs = {
  input?: InputMaybe<GetEventPreparationsByIdInput>;
};

export type QueryEventPreparationsByEventArgs = {
  input?: InputMaybe<GetEventPreparationsByEventIdInput>;
};

export type QueryEventTransportDetailsByEventOrDatesArgs = {
  input?: InputMaybe<GetEventTransportDetailsByEventOrDatesInput>;
};

export type QueryEventWorkerArgs = {
  input?: InputMaybe<GetWorkerByIdInput>;
};

export type QueryEventWorkersByEventArgs = {
  input?: InputMaybe<GetWorkersByEventIdInput>;
};

export type QueryFacilityArgs = {
  input?: InputMaybe<GetFacilityByPlaceNameInput>;
};

export type QueryFacilityCalendarArgs = {
  input?: InputMaybe<CommonCalendarFiltersInput>;
};

export type QueryFacilityGridArgs = {
  input?: InputMaybe<CommonGridFiltersInput>;
};

export type QueryFacilitySchedulesArgs = {
  input?: InputMaybe<CommonSchedulesFiltersInput>;
};

export type QueryFeeReportSheetsArgs = {
  input?: InputMaybe<CommonSheetFiltersInput>;
};

export type QueryFinancialAcctAcctIdArgs = {
  acctId: Scalars["ID"];
};

export type QueryFinancialAcctOrgArgs = {
  orgId: Scalars["ID"];
};

export type QueryFinancialAcctOrgByKybArgs = {
  kybId: Scalars["ID"];
};

export type QueryFinancialAcctRootArgs = {
  orgId: Scalars["ID"];
};

export type QueryFinancialAcctUsersArgs = {
  finAcctId: Scalars["ID"];
};

export type QueryFinancialAcctUsersByKycArgs = {
  kycId: Scalars["ID"];
};

export type QueryFundraiserApprovalSubmissionsArgs = {
  approverId?: InputMaybe<Scalars["String"]>;
  campaignIds?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  formId?: InputMaybe<Scalars["Int"]>;
  latest?: InputMaybe<Scalars["Boolean"]>;
  status?: InputMaybe<FundraiserApprovalSubmissionStatus>;
  submitterId: Scalars["String"];
};

export type QueryFundraiserApprovalSubmissionsHistoryArgs = {
  formId?: InputMaybe<Scalars["Int"]>;
  token?: InputMaybe<Scalars["String"]>;
};

export type QueryFundraiserApprovalSubmissionsOrgNamesArgs = {
  submissionId?: InputMaybe<Scalars["Int"]>;
  token?: InputMaybe<Scalars["String"]>;
};

export type QueryFundraiserCheerwallArgs = {
  fundraiserId: Scalars["String"];
  take: Scalars["Int"];
};

export type QueryFundraiserCustomRewardsDetailsArgs = {
  fundraiserId: Scalars["String"];
};

export type QueryFundraiserDataByCoachJoinCodeArgs = {
  coachJoinCode: Scalars["String"];
};

export type QueryFundraiserEntityResourcesArgs = {
  resourceId: Scalars["Int"];
};

export type QueryFundraiserGroupsArgs = {
  joinCode: Scalars["String"];
};

export type QueryFundraiserRaisedAmountArgs = {
  fundraiserId: Scalars["String"];
};

export type QueryFundraiserRewardsDetailsArgs = {
  fundraiserId: Scalars["String"];
};

export type QueryFundraiserTopDonationArgs = {
  fundraiserId: Scalars["String"];
};

export type QueryFundraiserUserRoleArgs = {
  fundraiserId: Scalars["Int"];
};

export type QueryGalleryItemsArgs = {
  fundraiserId: Scalars["String"];
};

export type QueryGetCampaignRosterArgs = {
  campaignId: Scalars["String"];
};

export type QueryGetInsightsUserOrgsArgs = {
  userId: Scalars["Int"];
};

export type QueryGetSalesRepHomeArgs = {
  zip: Scalars["String"];
};

export type QueryGetUserSavedSalesRepArgs = {
  udId: Scalars["String"];
};

export type QueryGroupLeaderFundraiserArgs = {
  fundraiserId: Scalars["String"];
};

export type QueryGroupLeaderFundraisersArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  role?: InputMaybe<GroupLeadersFundraiserUserRole>;
  status?: InputMaybe<Array<FundraiserStatus>>;
};

export type QueryGroupLeadersArgs = {
  cursor?: InputMaybe<Scalars["String"]>;
  groupLeaderId?: InputMaybe<Scalars["Int"]>;
  limit?: InputMaybe<Scalars["Int"]>;
};

export type QueryInfoSheetsArgs = {
  input?: InputMaybe<CommonSheetFiltersInput>;
};

export type QueryInsightsCampaignDonationsArgs = {
  campaignIds: Array<InputMaybe<Scalars["Int"]>>;
};

export type QueryInsightsCampaignRaiseEntityInfoArgs = {
  campaignId: Scalars["Int"];
};

export type QueryInsightsCampaignSettlementsArgs = {
  campaignIds: Array<InputMaybe<Scalars["Int"]>>;
};

export type QueryInsightsCampaignsDataArgs = {
  campaignIds: Array<InputMaybe<Scalars["Int"]>>;
};

export type QueryInsightsDonorParticipantContactsArgs = {
  campaignIds: Array<InputMaybe<Scalars["Int"]>>;
};

export type QueryInsightsNotificationPreferencesArgs = {
  userId: Scalars["String"];
};

export type QueryInsightsOrgsCampaignsSummaryArgs = {
  orgCampaignIds: Array<InputMaybe<InsOrgCampaignSummaryInput>>;
};

export type QueryInsightsPreApprovedContactsArgs = {
  orgIds?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export type QueryInsightsPreApprovedContactsEmailTestArgs = {
  emails: Array<InputMaybe<Scalars["String"]>>;
  orgId: Scalars["String"];
};

export type QueryInsightsSalesRepInfoArgs = {
  salesRepEmail: Scalars["String"];
};

export type QueryInsightsUserPreferencesArgs = {
  campaignIds?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  orgIds?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  queryType: UserPreferenceQueryType;
  userId: Scalars["String"];
};

export type QueryInviteArgs = {
  id?: InputMaybe<Scalars["String"]>;
};

export type QueryInvitesArgs = {
  limit?: Scalars["Int"];
  offset?: InputMaybe<Scalars["Int"]>;
  showAll?: InputMaybe<Scalars["Boolean"]>;
};

export type QueryInvitesListArgs = {
  currentPage?: Scalars["Int"];
  pageSize?: Scalars["Int"];
  search?: InputMaybe<Scalars["String"]>;
  showAll?: InputMaybe<Scalars["Boolean"]>;
};

export type QueryJoinCodeTypeArgs = {
  joinCode: Scalars["String"];
};

export type QueryLegacyTransactionsArgs = {
  input: LegacyTransactionsInput;
};

export type QueryLevelArgs = {
  input?: InputMaybe<GetLevelByIdInput>;
};

export type QueryLevelGridArgs = {
  input?: InputMaybe<CommonGridFiltersInput>;
};

export type QueryManageCoachArgs = {
  id: Scalars["Int"];
};

export type QueryManageCoachListArgs = {
  filter?: InputMaybe<ManageCoachListFilter>;
};

export type QueryManageEventArgs = {
  id: Scalars["Int"];
};

export type QueryManageEventListArgs = {
  filter?: InputMaybe<ManageEventListFilter>;
};

export type QueryManageOrganizationListArgs = {
  filter?: InputMaybe<ManageOrganizationFilter>;
};

export type QueryManagePlayerListArgs = {
  filter?: InputMaybe<ManagePlayerListFilter>;
};

export type QueryManageProgramArgs = {
  id: Scalars["Int"];
};

export type QueryManageProgramListArgs = {
  filter?: InputMaybe<ManageProgramListFilter>;
};

export type QueryManageSeasonArgs = {
  seasonId: Scalars["Int"];
};

export type QueryManageSeasonListArgs = {
  filter: ManageSeasonListFilter;
};

export type QueryManageUserArgs = {
  input?: InputMaybe<ManageUserInput>;
};

export type QueryManageUsersListArgs = {
  input?: InputMaybe<ManageUsersListOptions>;
};

export type QueryMessagesChannelArgs = {
  assignment?: InputMaybe<AssignmentInput>;
  id?: InputMaybe<Scalars["String"]>;
};

export type QueryMessagesChannelMembersArgs = {
  id: Scalars["String"];
};

export type QueryMessagesChannelsArgs = {
  filters?: InputMaybe<MessagesChannelsFilterInput>;
};

export type QueryOfficialAssignmentsArgs = {
  input?: InputMaybe<GetOfficialAssignmentsInput>;
};

export type QueryOfficialDutyArgs = {
  input?: InputMaybe<GetOfficialDutyByIdInput>;
};

export type QueryOfficialListsReportArgs = {
  input?: InputMaybe<EventOfficialReportFilter>;
};

export type QueryOfficialPoolByIdArgs = {
  input?: InputMaybe<GetOfficialPoolByIdInput>;
};

export type QueryOfficialSchedulesArgs = {
  input?: InputMaybe<CommonSchedulesFiltersInput>;
};

export type QueryOpponentArgs = {
  input?: InputMaybe<GetOpponentBySchoolCodeInput>;
};

export type QueryOpponentEventsApplyFiltersArgs = {
  input?: InputMaybe<GetEventsFilteredByOpponentStartAndEndDate>;
};

export type QueryOpponentSchedulesArgs = {
  input?: InputMaybe<CommonSchedulesFiltersInput>;
};

export type QueryOrgArgs = {
  hierarchy?: Scalars["Boolean"];
  id: Scalars["ID"];
};

export type QueryOrgCampaignIdArgs = {
  campaignId: Scalars["ID"];
  hierarchy?: Scalars["Boolean"];
  label?: OrgType;
};

export type QueryOrgCampaignsArgs = {
  orgId: Scalars["ID"];
};

export type QueryOrgFundraiserApprovalSubmissionsArgs = {
  orgIds: Array<Scalars["String"]>;
  status?: InputMaybe<Array<InputMaybe<FundraiserApprovalSubmissionStatus>>>;
};

export type QueryOrgHubspotIdArgs = {
  hubspotId: Scalars["String"];
};

export type QueryOrgIdArgs = {
  raiseId: Scalars["Int"];
};

export type QueryOrgPayableArgs = {
  campaignId: Scalars["ID"];
};

export type QueryOrgSearchArgs = {
  city?: InputMaybe<Scalars["String"]>;
  ein?: InputMaybe<Scalars["String"]>;
  label?: InputMaybe<OrgType>;
  limit?: InputMaybe<Scalars["Int"]>;
  matchStyle?: InputMaybe<MatchStyle>;
  name: Scalars["String"];
  offset?: InputMaybe<Scalars["Int"]>;
  stateCode?: InputMaybe<StateCode>;
  zipCode?: InputMaybe<Scalars["String"]>;
};

export type QueryOrgStaffRosterArgs = {
  orgId: Scalars["ID"];
};

export type QueryOrgUserAssociationsArgs = {
  orgId: Scalars["ID"];
  product: Product;
};

export type QueryOrgsArgs = {
  label?: InputMaybe<OrgType>;
  nameIncludes: Scalars["String"];
};

export type QueryOrgsSearchArgs = {
  pagination?: InputMaybe<OrgsSearchPaginationInput>;
  where?: InputMaybe<OrgsSearchWhereInput>;
};

export type QueryOtkFundraiserDataArgs = {
  fundraiserId: Scalars["ID"];
};

export type QueryOtkParticipantDetailsArgs = {
  fundraiserId: Scalars["ID"];
};

export type QueryOtkPastParticipantDetailsArgs = {
  fundraiserId: Scalars["ID"];
};

export type QueryParticipantCampaignConfigurationDataArgs = {
  campaignID: Scalars["ID"];
};

export type QueryParticipantCheerwallArgs = {
  fundraiserId: Scalars["String"];
  participantId: Scalars["String"];
  take: Scalars["Int"];
};

export type QueryParticipantDonationsRaisedArgs = {
  fundraiserId: Scalars["Int"];
  userId: Scalars["Int"];
};

export type QueryParticipantEarnedRewardsDetailsArgs = {
  fundraiserId: Scalars["String"];
  userId: Scalars["Int"];
};

export type QueryParticipantFundraiserArgs = {
  fundraiserId: Scalars["String"];
};

export type QueryParticipantFundraiserRewardArgs = {
  raiseId: Scalars["Int"];
};

export type QueryParticipantFundraisersArgs = {
  udid: Scalars["String"];
};

export type QueryParticipantPublicArgs = {
  fundraiserId: Scalars["Int"];
  userId: Scalars["Int"];
};

export type QueryParticipantRewardsDetailsArgs = {
  fundraiserId: Scalars["String"];
  userId: Scalars["Int"];
};

export type QueryParticipantSsoIdArgs = {
  fundraiserId: Scalars["Int"];
  userId: Scalars["Int"];
};

export type QueryParticipantShareMessageArgs = {
  fundraiserId: Scalars["String"];
  msgType: Scalars["String"];
};

export type QueryParticipantTextTemplateArgs = {
  fundraiserId: Scalars["Int"];
  userId: Scalars["Int"];
};

export type QueryParticipantTopDonationArgs = {
  fundraiserId: Scalars["String"];
  participantId: Scalars["String"];
};

export type QueryParticipantsArgs = {
  cursor?: InputMaybe<Scalars["String"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  participantId?: InputMaybe<Scalars["Int"]>;
};

export type QueryParticipantsListV2Args = {
  fundraiserId: Scalars["Int"];
};

export type QueryParticipantsPublicArgs = {
  fundraiserId: Scalars["Int"];
};

export type QueryPaymentsApiCustomerByEmailArgs = {
  input: PaymentsApiCustomerInput;
};

export type QueryPayoutsArgs = {
  campaignId: Scalars["Int"];
};

export type QueryPreparationArgs = {
  input?: InputMaybe<GetPreparationByIdInput>;
};

export type QueryPreparationReportSheetsArgs = {
  input?: InputMaybe<CommonSheetFiltersInput>;
};

export type QueryPrimaryGroupLeaderArgs = {
  campaignId: Scalars["String"];
};

export type QueryPublicFundraiserDataArgs = {
  fundraiserId?: InputMaybe<Scalars["Int"]>;
  identifier?: InputMaybe<Scalars["String"]>;
};

export type QueryPublicUserDataArgs = {
  userId: Scalars["Int"];
};

export type QueryRaiseAdminFundraiserPastArgs = {
  id: Scalars["Int"];
};

export type QueryRoleArgs = {
  id: Scalars["String"];
};

export type QueryRolesArgs = {
  id?: InputMaybe<Scalars["String"]>;
};

export type QuerySchoolInfoArgs = {
  input?: InputMaybe<GetSchoolInfoByIdInput>;
};

export type QuerySearchDriveCampaignsArgs = {
  activityTypes?: InputMaybe<Array<Scalars["String"]>>;
  campaignName?: InputMaybe<Scalars["String"]>;
  campaignStatuses?: InputMaybe<Array<CampaignStatus>>;
  endDate?: InputMaybe<Scalars["DateTime"]>;
  fundraiserId?: InputMaybe<Scalars["Int"]>;
  hasIncentive?: InputMaybe<Scalars["Boolean"]>;
  isPendingSettlement?: InputMaybe<Scalars["Boolean"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  maxTeamSize?: InputMaybe<Scalars["Int"]>;
  maxTotalRaisedCents?: InputMaybe<Scalars["Int"]>;
  minTeamSize?: InputMaybe<Scalars["Int"]>;
  minTotalRaisedCents?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  organizationIds?: InputMaybe<Array<Scalars["Int"]>>;
  organizationTypes?: InputMaybe<Array<Scalars["String"]>>;
  salesrepIds?: InputMaybe<Array<Scalars["Int"]>>;
  sortDirection?: InputMaybe<SortDirection>;
  sortField?: InputMaybe<SortField>;
  startDate?: InputMaybe<Scalars["DateTime"]>;
  territories?: InputMaybe<Array<Scalars["String"]>>;
  usStates?: InputMaybe<Array<Scalars["String"]>>;
};

export type QuerySearchDriveOrganizationsByNameArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  organizationName?: InputMaybe<Scalars["String"]>;
};

export type QuerySeasonArgs = {
  input?: InputMaybe<SeasonByIdInput>;
};

export type QuerySeasonByYearAndTeamArgs = {
  input?: InputMaybe<SeasonByYearTeamInput>;
};

export type QuerySeasonEventWizardArgs = {
  input?: InputMaybe<SeasonEventWizardInput>;
};

export type QueryShipmentsArgs = {
  externalId?: InputMaybe<ShipmentInput>;
};

export type QuerySpendAccountExportArgs = {
  status: Scalars["String"];
};

export type QuerySpendBudgetArgs = {
  groupId?: InputMaybe<Scalars["String"]>;
  id: Scalars["String"];
};

export type QuerySpendBudgetTransactionsArgs = {
  input?: InputMaybe<SpendBudgetTransactionInput>;
};

export type QuerySpendBudgetsArgs = {
  groupId?: InputMaybe<Scalars["String"]>;
};

export type QuerySpendBudgetsSummaryArgs = {
  groupId: Scalars["String"];
  seasonId: Scalars["String"];
};

export type QuerySpendBudgetsSummaryV2Args = {
  groupId: Scalars["String"];
  seasonId: Scalars["String"];
};

export type QuerySpendCategoriesArgs = {
  filterBy?: InputMaybe<CategoryFilterEnum>;
  filterValue?: InputMaybe<Scalars["String"]>;
  groupId?: InputMaybe<Scalars["String"]>;
  seasonId?: InputMaybe<Scalars["String"]>;
};

export type QuerySpendDataForHomeArgs = {
  udid: Scalars["String"];
};

export type QuerySpendDebitCardInvitesArgs = {
  groupId?: InputMaybe<Scalars["String"]>;
};

export type QuerySpendGetGroupCounterpartiesArgs = {
  groupOrOrgId: Scalars["String"];
};

export type QuerySpendGetInstitutionByRoutingNumberArgs = {
  routingNumber: Scalars["String"];
};

export type QuerySpendGetOrganizationCounterpartiesArgs = {
  groupOrOrgId: Scalars["String"];
};

export type QuerySpendGroupBankAccountsArgs = {
  groupId?: InputMaybe<Scalars["String"]>;
};

export type QuerySpendGroupBankLinkTokenCreateArgs = {
  id?: InputMaybe<Scalars["String"]>;
};

export type QuerySpendGroupByIdArgs = {
  id: Scalars["String"];
};

export type QuerySpendGroupDebitCardsArgs = {
  id?: InputMaybe<Scalars["String"]>;
};

export type QuerySpendGroupPayeesArgs = {
  groupId: Scalars["String"];
};

export type QuerySpendGroupRostersArgs = {
  filterBy?: InputMaybe<GroupRosterFilterEnum>;
  filterValue?: InputMaybe<Scalars["String"]>;
};

export type QuerySpendGroupRostersBySeasonArgs = {
  pagination?: InputMaybe<SpendPaginationInput>;
  seasonId: Scalars["String"];
};

export type QuerySpendGroupRostersBySeasonV2Args = {
  pagination?: InputMaybe<SpendPaginationInput>;
  seasonId: Scalars["String"];
  sort?: InputMaybe<SpendSortInput>;
};

export type QuerySpendGroupsArgs = {
  includeArchive?: InputMaybe<Scalars["Boolean"]>;
};

export type QuerySpendGroupsFilteredArgs = {
  pagination?: InputMaybe<SpendPaginationInput>;
  sort?: InputMaybe<SpendSortInput>;
  where?: InputMaybe<SpendGroupsWhereInput>;
};

export type QuerySpendGroupsOverviewDashboardArgs = {
  pagination?: InputMaybe<SpendPaginationInput>;
};

export type QuerySpendGroupsPublicArgs = {
  orgId: Scalars["String"];
};

export type QuerySpendGuardianInvoicesArgs = {
  filters?: InputMaybe<Array<InputMaybe<SpendGuardianInvoiceFilter>>>;
};

export type QuerySpendInviteArgs = {
  id: Scalars["String"];
};

export type QuerySpendInvitesArgs = {
  filterBy?: InputMaybe<InviteFilterEnum>;
  filterValue?: InputMaybe<Scalars["String"]>;
};

export type QuerySpendInvitesFilteredArgs = {
  pagination?: InputMaybe<SpendPaginationInput>;
  sort?: InputMaybe<SpendSortInput>;
  where?: InputMaybe<SpendInviteWhereInput>;
};

export type QuerySpendInvoiceByIdArgs = {
  id: Scalars["String"];
};

export type QuerySpendInvoiceHistoryArgs = {
  input?: InputMaybe<SpendInvoiceHistoryInput>;
};

export type QuerySpendInvoicesArgs = {
  filterBy?: InputMaybe<InvoiceFilterEnum>;
  filterValue?: InputMaybe<Scalars["String"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  parentSignUp?: InputMaybe<Scalars["Boolean"]>;
};

export type QuerySpendInvoicesExportArgs = {
  filters?: InputMaybe<Array<InputMaybe<SpendInvoiceFilter>>>;
  limit?: InputMaybe<Scalars["Int"]>;
};

export type QuerySpendInvoicesExportV2Args = {
  filters?: InputMaybe<Array<InputMaybe<SpendInvoiceFilter>>>;
  sort?: InputMaybe<SpendSortInput>;
};

export type QuerySpendInvoicesFilteredArgs = {
  filters?: InputMaybe<Array<InputMaybe<SpendInvoiceFilter>>>;
  limit?: InputMaybe<Scalars["Int"]>;
};

export type QuerySpendOrganizationAccountLimitsArgs = {
  groupId?: InputMaybe<Scalars["String"]>;
};

export type QuerySpendOrganizationByIdArgs = {
  id: Scalars["String"];
};

export type QuerySpendOrganizationPayeesArgs = {
  orgId: Scalars["String"];
};

export type QuerySpendOrganizationsArgs = {
  pagination?: InputMaybe<SpendPaginationInput>;
  where?: InputMaybe<SpendOrganizationsWhereInput>;
};

export type QuerySpendPaginatedInvoicesArgs = {
  filters?: InputMaybe<Array<InputMaybe<SpendInvoiceFilter>>>;
  pagination?: InputMaybe<SpendPaginationInput>;
  sort?: InputMaybe<SpendSortInput>;
};

export type QuerySpendPastDueInvoicesArgs = {
  interval?: InputMaybe<SpendPastDueInterval>;
  pagination?: InputMaybe<SpendPaginationInput>;
  sort?: InputMaybe<SpendPastDueSort>;
};

export type QuerySpendPaymentScheduleByIdArgs = {
  id: Scalars["String"];
};

export type QuerySpendPaymentSchedulesArgs = {
  groupIdOrSeasonId?: InputMaybe<Scalars["String"]>;
};

export type QuerySpendRoleCurrentArgs = {
  sessionId: Scalars["String"];
};

export type QuerySpendRostersArgs = {
  filterBy?: InputMaybe<RosterFilterEnum>;
  filterValue?: InputMaybe<Scalars["String"]>;
};

export type QuerySpendRostersFilteredArgs = {
  pagination?: InputMaybe<SpendPaginationInput>;
  sort?: InputMaybe<SpendSortInput>;
  where?: InputMaybe<SpendGroupRostersWhereInput>;
};

export type QuerySpendSeasonArgs = {
  id: Scalars["String"];
};

export type QuerySpendTransactionArgs = {
  id?: InputMaybe<Scalars["String"]>;
};

export type QuerySpendTransactionAttachmentArgs = {
  id: Scalars["String"];
};

export type QuerySpendTransactionAttachmentsByPaymentIdArgs = {
  id: Scalars["String"];
};

export type QuerySpendTransactionNotesArgs = {
  id?: InputMaybe<Scalars["String"]>;
};

export type QuerySpendTransactionNotesByPaymentIdArgs = {
  id?: InputMaybe<Scalars["String"]>;
};

export type QuerySpendTransactionsArgs = {
  filterBy?: InputMaybe<TransactionFilterEnum>;
  filterValue?: InputMaybe<Scalars["String"]>;
  groupId?: InputMaybe<Scalars["String"]>;
  limit?: InputMaybe<Scalars["Int"]>;
};

export type QuerySpendTransactionsExportArgs = {
  allAccounts?: InputMaybe<Scalars["Boolean"]>;
  filters?: InputMaybe<Array<InputMaybe<SpendTransactionFilter>>>;
  limit?: InputMaybe<Scalars["Int"]>;
};

export type QuerySpendTransactionsFilteredArgs = {
  allAccounts?: InputMaybe<Scalars["Boolean"]>;
  dateRange?: InputMaybe<DateRangeEnum>;
  disableCache?: InputMaybe<Scalars["Boolean"]>;
  filters?: InputMaybe<Array<InputMaybe<SpendTransactionFilter>>>;
  pagination?: InputMaybe<SpendPaginationInput>;
  sort?: InputMaybe<SpendSortInput>;
  where?: InputMaybe<SpendTransactionWhereInput>;
};

export type QuerySpendTransactionsLegacyArgs = {
  groupId?: InputMaybe<Scalars["String"]>;
  pagination?: InputMaybe<SpendPaginationInput>;
};

export type QuerySpendUserAccountBearerTokenArgs = {
  input?: InputMaybe<SpendAccountBearerToken>;
};

export type QuerySpendUserExportEmailsArgs = {
  input: SpendExportEmailsInput;
};

export type QuerySponsorDataForHomeArgs = {
  orgId: Scalars["String"];
};

export type QueryStoreBestSellerArgs = {
  scopeId: Scalars["Int"];
};

export type QueryStoreByScopeIdArgs = {
  scopeId: Scalars["Int"];
};

export type QueryStoreCampaignPayableInfoArgs = {
  input?: InputMaybe<StoreCampaignPayableInfoInput>;
};

export type QueryStoreDataForHomeArgs = {
  fundraiserId?: InputMaybe<Array<Scalars["Int"]>>;
  uEmail: Scalars["String"];
};

export type QueryStoreEarnedPointsGraphArgs = {
  input?: InputMaybe<StorePointEarnedGraphInput>;
};

export type QueryStoreGlCampaignsArgs = {
  input?: InputMaybe<StoreUserCampaignsInput>;
};

export type QueryStoreGlParticipantsArgs = {
  input?: InputMaybe<StoreUserParticipantsInput>;
};

export type QueryStoreOrderFilterArgs = {
  input?: InputMaybe<OrderFilterInput>;
};

export type QueryStoreOrderItemImagesArgs = {
  itemIds: Array<Scalars["Int"]>;
};

export type QueryStoreOrderSalesGraphArgs = {
  input?: InputMaybe<OrderGraphInput>;
};

export type QueryStoreOrderSummaryArgs = {
  scopeId: Scalars["Int"];
};

export type QueryStoreOrdersCsvArgs = {
  scopeId: Scalars["Int"];
};

export type QueryStorePayableFilterArgs = {
  input?: InputMaybe<StoreBaseFilterInput>;
};

export type QueryStorePointActivityFilterArgs = {
  input?: InputMaybe<PointsActivityFilterInput>;
};

export type QueryStorePointActivityFilterAdvanceArgs = {
  input?: InputMaybe<PointsActivityFilterInput>;
};

export type QueryStorePointsActivityCsvArgs = {
  scopeId: Scalars["Int"];
};

export type QueryStorePointsActivityCsvAdvanceArgs = {
  scopeId: Scalars["Int"];
};

export type QueryStorePointsWithdrawalRequestFilterArgs = {
  input?: InputMaybe<StoreBaseFilterInput>;
};

export type QueryStoreProfitPercentageArgs = {
  storeId: Scalars["Int"];
};

export type QueryStoreScopePayableInfoArgs = {
  scopeId: Scalars["Int"];
};

export type QueryStoreTicketsArgs = {
  scopeId: Scalars["Int"];
};

export type QueryStoreUserDetailsArgs = {
  email: Scalars["String"];
};

export type QueryStoreUserPreferenceArgs = {
  userId: Scalars["String"];
};

export type QueryStoresByCampaignIdsArgs = {
  input?: InputMaybe<StoreByCampaignIdsInput>;
};

export type QueryStoresByGlEmailArgs = {
  input?: InputMaybe<StoreByGlEmailInput>;
};

export type QueryStoresSummaryByCampaignIdsArgs = {
  input?: InputMaybe<StoreSummaryByCampaignIdsInput>;
};

export type QueryStoresSummaryByGlEmailArgs = {
  input?: InputMaybe<StoresSummaryByGlEmailInput>;
};

export type QueryTeamArgs = {
  input?: InputMaybe<TeamByCodeInput>;
};

export type QueryTeamOfficialsArgs = {
  input?: InputMaybe<OfficialByIdInput>;
};

export type QueryTeamOrgSchedulesArgs = {
  input?: InputMaybe<CommonSchedulesFiltersInput>;
};

export type QueryTeamPreparationsArgs = {
  input?: InputMaybe<PreparationByIdInput>;
};

export type QueryTeamWorkersArgs = {
  input?: InputMaybe<WorkerByIdInput>;
};

export type QueryTransactionDetailArgs = {
  transactionId: Scalars["UUID"];
};

export type QueryTransactionsArgs = {
  input: TransactionsInput;
};

export type QueryTransactionsByAccountArgs = {
  input: AccountTransactionsInput;
};

export type QueryTransactionsByAccountPaginatedArgs = {
  input: AccountTransactionsPaginatedInput;
};

export type QueryTransactionsBySpendDestinationArgs = {
  input: TransactionsInput;
};

export type QueryTransactionsBySpendSourceArgs = {
  input: TransactionsInput;
};

export type QueryTwispCardBalancesArgs = {
  index?: InputMaybe<TwispCardBalances_Indexes>;
  limit?: InputMaybe<Scalars["Int32"]>;
  scope?: InputMaybe<Scalars["String"]>;
  sort?: InputMaybe<Sort>;
  where?: InputMaybe<Where>;
};

export type QueryTwispCardsArgs = {
  index?: InputMaybe<TwispCards_Indexes>;
  limit?: InputMaybe<Scalars["Int32"]>;
  scope?: InputMaybe<Scalars["String"]>;
  sort?: InputMaybe<Sort>;
  where?: InputMaybe<Where>;
};

export type QueryTwispStripeHooksArgs = {
  index?: InputMaybe<TwispStripeHooks_Indexes>;
  limit?: InputMaybe<Scalars["Int32"]>;
  scope?: InputMaybe<Scalars["String"]>;
  sort?: InputMaybe<Sort>;
  where?: InputMaybe<Where>;
};

export type QueryTwispTransactionsArgs = {
  index?: InputMaybe<TwispTransactions_Indexes>;
  limit?: InputMaybe<Scalars["Int32"]>;
  scope?: InputMaybe<Scalars["String"]>;
  sort?: InputMaybe<Sort>;
  where?: InputMaybe<Where>;
};

export type QueryUnconfirmedEventsArgs = {
  input?: InputMaybe<UnconfirmedEventsFiltersInput>;
};

export type QueryUpdateSavedSalesRepArgs = {
  snapRepEmail: Scalars["String"];
  udId: Scalars["String"];
};

export type QueryUserArgs = {
  email?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["String"]>;
};

export type QueryUserActivityLeadershipArgs = {
  userId: Scalars["ID"];
};

export type QueryUserAffiliationsArgs = {
  userId: Scalars["ID"];
};

export type QueryUserApiKeyArgs = {
  id: Scalars["String"];
};

export type QueryUserApiKeyBySecretArgs = {
  secret: Scalars["String"];
};

export type QueryUserAssociationCampaignsArgs = {
  product: Product;
  userId: Scalars["ID"];
};

export type QueryUserAssociationsArgs = {
  id: Scalars["ID"];
  product: Product;
};

export type QueryUserBySecretArgs = {
  secret: Scalars["String"];
};

export type QueryUserFinancialAccountsArgs = {
  userId: Scalars["ID"];
};

export type QueryUserGroupLeadershipArgs = {
  userId: Scalars["ID"];
};

export type QueryUserImpliedAffiliationsArgs = {
  userId: Scalars["ID"];
};

export type QueryUserOrgAffiliationArgs = {
  orgId: Scalars["ID"];
  userId: Scalars["ID"];
};

export type QueryUserParticipationArgs = {
  userId: Scalars["ID"];
};

export type QueryUserPermissionsArgs = {
  userId?: InputMaybe<Scalars["String"]>;
};

export type QueryUserPublicArgs = {
  id?: InputMaybe<Scalars["String"]>;
};

export type QueryUserPublicInfoArgs = {
  id?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
};

export type QueryUserTeamLeadershipArgs = {
  userId: Scalars["ID"];
};

export type QueryUserTokenArgs = {
  expirationTime?: InputMaybe<Scalars["String"]>;
  permissions?: InputMaybe<Array<Scalars["String"]>>;
};

export type QueryUserUnconfirmedAffiliationsArgs = {
  userId: Scalars["ID"];
};

export type QueryUsersArgs = {
  filter?: InputMaybe<UsersFilter>;
  isInternal?: InputMaybe<Scalars["Boolean"]>;
  limit?: Scalars["Int"];
  offset?: InputMaybe<Scalars["Int"]>;
  searchTerm?: InputMaybe<Scalars["String"]>;
  snapRaiseId?: InputMaybe<Scalars["Int"]>;
  snapSpendId?: InputMaybe<Scalars["Int"]>;
};

export type QueryVaultAccountStatusArgs = {
  accountId?: InputMaybe<Scalars["ID"]>;
  accountRepresentativeGatewayId?: InputMaybe<Scalars["String"]>;
  applicationId?: InputMaybe<Scalars["ID"]>;
  beneficialOwnersGatewayIds?: InputMaybe<Array<Scalars["String"]>>;
  provider: VaultFinancialProvider;
  stripeEnv?: InputMaybe<VaultStripeEnv>;
};

export type QueryVaultCardArgs = {
  id: Scalars["String"];
};

export type QueryVaultKybArgs = {
  id: Scalars["ID"];
};

export type QueryVaultKycArgs = {
  id: Scalars["ID"];
};

export type QueryVaultStripeConnectAccountBankAccountListFetchArgs = {
  stripeConnectAccountId: Scalars["String"];
  stripeEnv?: InputMaybe<VaultStripeEnv>;
};

export type QueryVaultStripeConnectAccountFetchArgs = {
  stripeConnectAccountId: Scalars["ID"];
  stripeEnv?: InputMaybe<VaultStripeEnv>;
};

export type QueryVaultStripeConnectAccountsBankAccountListFetchArgs = {
  stripeConnectAccountIds?: InputMaybe<Array<Scalars["String"]>>;
  stripeEnv?: InputMaybe<VaultStripeEnv>;
};

export type QueryVaultStripeInvalidRepresentativeArgs = {
  stripeConnectAccountId: Scalars["ID"];
  stripeEnv?: InputMaybe<VaultStripeEnv>;
};

export type QueryVaultVgsValueArgs = {
  value: Scalars["String"];
};

export type QueryVehicleArgs = {
  input?: InputMaybe<GetVehicleByIdInput>;
};

export type QueryWorkerPoolByIdArgs = {
  input?: InputMaybe<GetWorkerPoolByIdInput>;
};

export type RaiseAdminFundraiser = {
  __typename?: "RaiseAdminFundraiser";
  /** End date of upcoming fundraiser */
  endDate?: Maybe<Scalars["DateTime"]>;
  /** Raise Resource Entity ID of the fundraiser */
  entityId?: Maybe<Scalars["Int"]>;
  /** Int of participants from previous year */
  groupSize?: Maybe<Scalars["Int"]>;
  /** Fundraiser ID */
  id?: Maybe<Scalars["Int"]>;
  /** Fundraiser Image URL */
  image?: Maybe<Scalars["String"]>;
  /** Fundraiser name */
  name?: Maybe<Scalars["String"]>;
  /** A query for raise admin users to get upcoming resign participants */
  participantListForFundraiser?: Maybe<RaiseAdminParticipantList>;
  /** Has the fundraiser been rolled over? */
  rollover?: Maybe<Scalars["Boolean"]>;
  /** Start date of upcoming fundraiser */
  startDate?: Maybe<Scalars["DateTime"]>;
};

export type RaiseAdminFundraiserParticipantListForFundraiserArgs = {
  filter?: InputMaybe<RaiseAdminParticipantFilter>;
};

export type RaiseAdminFundraiserPast = {
  __typename?: "RaiseAdminFundraiserPast";
  /** Current year's fundraiser */
  current?: Maybe<RaiseAdminFundraiser>;
  /** Last year's fundraiser */
  past?: Maybe<RaiseAdminFundraiser>;
};

export type RaiseAdminParticipant = {
  __typename?: "RaiseAdminParticipant";
  /** Participant email */
  email?: Maybe<Scalars["String"]>;
  /** Participant first name */
  firstName?: Maybe<Scalars["String"]>;
  /** Participant ID */
  id?: Maybe<Scalars["Int"]>;
  /** Participant last name */
  lastName?: Maybe<Scalars["String"]>;
  /** Participant's Profile Image */
  profileImage?: Maybe<Scalars["String"]>;
};

export type RaiseAdminParticipantFilter = {
  /** Conditions to order by */
  orderBy?: InputMaybe<RaiseAdminParticipantOrderInput>;
  /** Conditions to filter by */
  where?: InputMaybe<RaiseAdminParticipantWhereInput>;
};

export type RaiseAdminParticipantList = {
  __typename?: "RaiseAdminParticipantList";
  /** Total number of fundraisers */
  count: Scalars["Int"];
  /** List of fundraisers */
  list?: Maybe<Array<Maybe<RaiseAdminParticipant>>>;
};

export type RaiseAdminParticipantOrderInput = {
  email?: InputMaybe<SortOrderEnum>;
  id?: InputMaybe<SortOrderEnum>;
  name?: InputMaybe<SortOrderEnum>;
};

export type RaiseAdminParticipantWhereInput = {
  /** Nullable String Filter for Participant email */
  email?: InputMaybe<Scalars["String"]>;
  /** Nullable String Filter for Participant name */
  name?: InputMaybe<Scalars["String"]>;
  /** Nullable Boolean Filter for Participant Rollover */
  rollover?: InputMaybe<Scalars["Boolean"]>;
};

export type RaiseFundraiserUsersCreateManyInput = {
  fundraiser_id: Scalars["Int"];
  user_ids: Array<Scalars["Int"]>;
};

export type RaiseUser = {
  __typename?: "RaiseUser";
  email?: Maybe<Scalars["String"]>;
  /** SSO User ID */
  id?: Maybe<Scalars["String"]>;
};

export type ReceiptResult = {
  __typename?: "ReceiptResult";
  error?: Maybe<Scalars["String"]>;
  success: Scalars["Boolean"];
  transactionId: Scalars["ID"];
};

export type ReconcileBudget = {
  amount: Scalars["Int"];
  budgetItemId: Scalars["String"];
};

export type ReconcileInvoice = {
  amount: Scalars["Int"];
  budgetItemId?: InputMaybe<Scalars["String"]>;
  invoiceId: Scalars["String"];
};

export type ReconcileInvoiceWithBudget = {
  amount: Scalars["Int"];
  budgetItemId: Scalars["String"];
  invoiceId: Scalars["String"];
  note?: InputMaybe<Scalars["String"]>;
};

export type Redirect = {
  __typename?: "Redirect";
  count: Scalars["Int"];
  createdAt: Scalars["DateTime"];
  fundraiser?: Maybe<DonorFundraiserData>;
  fundraiserId?: Maybe<Scalars["String"]>;
  id: Scalars["String"];
  path: Scalars["String"];
  updatedAt: Scalars["DateTime"];
};

export enum RequestStatus {
  Active = "ACTIVE",
  Canceled = "CANCELED",
  Delivered = "DELIVERED",
  DeliveredWithFailed = "DELIVERED_WITH_FAILED",
  Failed = "FAILED",
  Pending = "PENDING",
  Sent = "SENT",
}

export type ResendReceiptsResult = {
  __typename?: "ResendReceiptsResult";
  results: Array<ReceiptResult>;
};

export enum ResponseStatus {
  Failure = "FAILURE",
  Success = "SUCCESS",
}

export type RevisionFundraiserApprovalSubmissionData = {
  __typename?: "RevisionFundraiserApprovalSubmissionData";
  isSsoApprover: Scalars["Boolean"];
  submitterName: Scalars["String"];
};

export type RevisionFundraiserApprovalSubmissionResult =
  | InvalidToken
  | RevisionFundraiserApprovalSubmissionData
  | UnprocessableSubmission;

export enum RewardStatus {
  Delivered = "DELIVERED",
  Failed = "FAILED",
  Pending = "PENDING",
  Sent = "SENT",
}

export type Role = {
  __typename?: "Role";
  description?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  permissions?: Maybe<Array<Maybe<RolePermission>>>;
  scope?: Maybe<Scalars["String"]>;
  title?: Maybe<Scalars["String"]>;
};

export type RoleArguments = {
  description?: InputMaybe<Scalars["String"]>;
  name: Scalars["String"];
  scope?: InputMaybe<Scalars["String"]>;
  title: Scalars["String"];
};

export type RolePermission = {
  __typename?: "RolePermission";
  id?: Maybe<Scalars["String"]>;
  permission?: Maybe<Permission>;
  permissionId?: Maybe<Scalars["String"]>;
  roleId?: Maybe<Scalars["String"]>;
};

export type Roster = {
  __typename?: "Roster";
  id?: Maybe<Scalars["ID"]>;
  members?: Maybe<Array<Maybe<RosterMember>>>;
  name?: Maybe<Scalars["String"]>;
};

export enum RosterFilterEnum {
  GroupName = "groupName",
  MemberName = "memberName",
  RosterName = "rosterName",
  SeasonName = "seasonName",
}

export type RosterMember = {
  __typename?: "RosterMember";
  association?: Maybe<CampaignMemberAssociation>;
  id?: Maybe<Scalars["ID"]>;
  userId?: Maybe<Scalars["String"]>;
  wasDeleted?: Maybe<Scalars["Boolean"]>;
};

export type S3PresignedInsightsApprovalsUrlResult = {
  __typename?: "S3PresignedInsightsApprovalsUrlResult";
  expires: Scalars["DateTime"];
  filename: Scalars["String"];
  s3Filename: Scalars["String"];
  status: Scalars["String"];
  url: Scalars["String"];
};

export type SdPagination = {
  __typename?: "SDPagination";
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  total?: Maybe<Scalars["BigInt"]>;
};

/** Owned by Vault Only used in legacy createCard */
export enum Shipping_Service {
  Express = "express",
  Priority = "priority",
  Standard = "standard",
}

/** Owned by Vault Only used in legacy createCard */
export enum Spending_Limit_Interval {
  AllTime = "all_time",
  Daily = "daily",
  Monthly = "monthly",
  PerAuthorization = "per_authorization",
  Weekly = "weekly",
  Yearly = "yearly",
}

export enum Support_Ticket_Department {
  Accounts = "ACCOUNTS",
  Hubspot = "HUBSPOT",
  It = "IT",
  Pep = "PEP",
  Sales = "SALES",
  Sponsor = "SPONSOR",
  Store = "STORE",
  Wallet = "WALLET",
  Warehouse = "WAREHOUSE",
}

export enum SalesrepType {
  AccountManager = "ACCOUNT_MANAGER",
  SalesRep = "SALES_REP",
}

/** Information of a emails scheduled for cron jobs */
export type ScheduledReleaseInfo = {
  __typename?: "ScheduledReleaseInfo";
  /** The date and time for release */
  date?: Maybe<Scalars["DateTime"]>;
  /** The timezone for scheduled release */
  timezone?: Maybe<Scalars["String"]>;
};

export enum SchoolCategory {
  Charter = "CHARTER",
  Magnet = "MAGNET",
  Private = "PRIVATE",
  Public = "PUBLIC",
}

export type SchoolInfo = {
  __typename?: "SchoolInfo";
  Schoolname?: Maybe<Scalars["String"]>;
  ad?: Maybe<Scalars["String"]>;
  ad_contract_signee?: Maybe<Scalars["String"]>;
  address?: Maybe<Scalars["String"]>;
  ccemail?: Maybe<Scalars["String"]>;
  city?: Maybe<Scalars["String"]>;
  conf_text_type?: Maybe<Scalars["String"]>;
  email?: Maybe<Scalars["String"]>;
  email_reminder?: Maybe<Scalars["String"]>;
  email_reminder_officials?: Maybe<Scalars["String"]>;
  email_reminder_workers?: Maybe<Scalars["String"]>;
  enable_cc_email_as_origin?: Maybe<Scalars["String"]>;
  enable_ml_updates?: Maybe<Scalars["String"]>;
  fax?: Maybe<Scalars["String"]>;
  icon?: Maybe<Scalars["String"]>;
  id: Scalars["String"];
  mascot?: Maybe<Scalars["String"]>;
  message_board_read_at?: Maybe<Scalars["Date"]>;
  ml_key?: Maybe<Scalars["String"]>;
  phone?: Maybe<Scalars["String"]>;
  principal?: Maybe<Scalars["String"]>;
  school_timezone?: Maybe<Scalars["String"]>;
  secondary_ad_email?: Maybe<Scalars["String"]>;
  secondary_ad_name?: Maybe<Scalars["String"]>;
  signed_contract_notification?: Maybe<Scalars["String"]>;
  ssn_on_file?: Maybe<Scalars["String"]>;
  state?: Maybe<Scalars["String"]>;
  state_org?: Maybe<Scalars["String"]>;
  state_org_abbreviation?: Maybe<Scalars["String"]>;
  use_security?: Maybe<Scalars["String"]>;
  web_password?: Maybe<Scalars["String"]>;
  zip?: Maybe<Scalars["String"]>;
};

export type ScopeProductColor = {
  __typename?: "ScopeProductColor";
  label?: Maybe<Scalars["String"]>;
  value?: Maybe<Scalars["Int"]>;
  visual?: Maybe<Scalars["String"]>;
};

export type Season = {
  __typename?: "Season";
  budget?: Maybe<Scalars["Float"]>;
  created_at?: Maybe<Scalars["Date"]>;
  default_time_for_event?: Maybe<Scalars["String"]>;
  default_time_for_practice?: Maybe<Scalars["String"]>;
  home_field?: Maybe<Scalars["String"]>;
  is_deleted?: Maybe<Scalars["Boolean"]>;
  preview?: Maybe<Scalars["String"]>;
  season?: Maybe<Scalars["String"]>;
  season_id: Scalars["Int"];
  sport?: Maybe<Team>;
  sport_code?: Maybe<Scalars["String"]>;
  web_password?: Maybe<Scalars["String"]>;
  year?: Maybe<Scalars["String"]>;
};

export type SeasonByIdInput = {
  season_id: Scalars["Int"];
};

export type SeasonByYearTeamInput = {
  sport_code?: InputMaybe<Scalars["String"]>;
  year?: InputMaybe<Scalars["String"]>;
};

export type SeasonCreateInput = {
  budget?: InputMaybe<Scalars["Float"]>;
  created_at?: InputMaybe<Scalars["Date"]>;
  default_time_for_event?: InputMaybe<Scalars["String"]>;
  default_time_for_practice?: InputMaybe<Scalars["String"]>;
  home_field?: InputMaybe<Scalars["String"]>;
  is_deleted?: InputMaybe<Scalars["Boolean"]>;
  preview?: InputMaybe<Scalars["String"]>;
  season?: InputMaybe<Scalars["String"]>;
  season_id: Scalars["Int"];
  sport_code?: InputMaybe<Scalars["String"]>;
  web_password?: InputMaybe<Scalars["String"]>;
  year?: InputMaybe<Scalars["String"]>;
};

export type SeasonDeleteInput = {
  season_ids?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
};

export type SeasonEventWizardInput = {
  season_ids?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
};

export type SeasonModifyInput = {
  budget?: InputMaybe<Scalars["Float"]>;
  created_at?: InputMaybe<Scalars["Date"]>;
  default_time_for_event?: InputMaybe<Scalars["String"]>;
  default_time_for_practice?: InputMaybe<Scalars["String"]>;
  home_field?: InputMaybe<Scalars["String"]>;
  is_deleted?: InputMaybe<Scalars["Boolean"]>;
  preview?: InputMaybe<Scalars["String"]>;
  season?: InputMaybe<Scalars["String"]>;
  season_id: Scalars["Int"];
  sport_code?: InputMaybe<Scalars["String"]>;
  web_password?: InputMaybe<Scalars["String"]>;
  year?: InputMaybe<Scalars["String"]>;
};

export type SeasonPostponeUnpostponeInput = {
  event_ids?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  season_id?: InputMaybe<Scalars["Int"]>;
};

export type SeasonScheduleTeamsCreateInput = {
  sport_codes?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  year?: InputMaybe<Scalars["String"]>;
};

export type SendEmailResponse = {
  __typename?: "SendEmailResponse";
  success: Scalars["Boolean"];
};

export enum SetUserPreferenceFlag {
  Off = "OFF",
  On = "ON",
}

/** Settlement details for closed campaign. */
export type SettlementDetails = {
  __typename?: "SettlementDetails";
  /** The amount in cents that campaigns was advanced in Wallet. */
  advanceAmount?: Maybe<Scalars["Int"]>;
  /** Address to send out check to */
  checkingAddress?: Maybe<Scalars["String"]>;
  /** The type of snap fee being collected. e.g. 'standard', '1+1', '20%/15%', etc. */
  feeType?: Maybe<Scalars["String"]>;
  /** Total cost of incentives in cents */
  gearCost?: Maybe<Scalars["Int"]>;
  /** Code to build logo */
  incLogo?: Maybe<Scalars["String"]>;
  /** Primary color to build logo */
  incLogoPrimaryColor?: Maybe<Scalars["String"]>;
  /** Script to build logo */
  incLogoScript?: Maybe<Scalars["String"]>;
  /** Secondary color to build logo */
  incLogoSecondaryColor?: Maybe<Scalars["String"]>;
  /** Filename of Logo of Group of Campaign */
  logoImage?: Maybe<Scalars["String"]>;
  /** The amount in cents that campaigns was manual-advanced in Raise. */
  manualAdvanceAmount?: Maybe<Scalars["Int"]>;
  /** Net raised amount in cents. Equals totalRaised minus snapFee and adds the otkBonus. It is the amount client received in their check/deposit. */
  netRaisedTotal?: Maybe<Scalars["Int"]>;
  /** Notes of campaign */
  notes?: Maybe<Scalars["String"]>;
  /** Legal Name of Organization */
  organizationLegalName?: Maybe<Scalars["String"]>;
  /** TIN of Organization */
  organizationTin?: Maybe<Scalars["String"]>;
  /** Total bonus amount that team will receive through otk purchase cost (usually 10%) in cents. */
  otkBonus?: Maybe<Scalars["Int"]>;
  /** Link to OTK Logo */
  otkLogoUrl?: Maybe<Scalars["String"]>;
  /** Percentage of participation of campaign */
  participationPercent?: Maybe<Scalars["Int"]>;
  /** Notes for settlement for this campaign */
  settlementNotes?: Maybe<Scalars["String"]>;
  /** Default Shipping address to send out gears/merchandise/check */
  shippingAddress?: Maybe<Scalars["String"]>;
  /** Total Fee in cents that Raise collects from campaigns */
  snapFee?: Maybe<Scalars["Int"]>;
};

export enum SettlementMethod {
  DirectDeposit = "DIRECT_DEPOSIT",
  MailedCheck = "MAILED_CHECK",
}

/** Fixed statuses for a campaigns settlement status */
export enum SettlementStatus {
  InReview = "IN_REVIEW",
  Unsubmitted = "UNSUBMITTED",
  Verified = "VERIFIED",
}

/** Attributes for settlement status */
export type SettlementStatusUpdate = {
  __typename?: "SettlementStatusUpdate";
  fundraiserId?: Maybe<Scalars["Int"]>;
  settlementStatus?: Maybe<SettlementStatus>;
};

export type Shipment = {
  __typename?: "Shipment";
  carrier?: Maybe<ShipmentCarrier>;
  customer: Scalars["String"];
  destination?: Maybe<Scalars["String"]>;
  externalId: ShipmentExternalId;
  netsuiteId: Scalars["String"];
  netsuiteSoTranId?: Maybe<Scalars["String"]>;
  netsuiteTranId?: Maybe<Scalars["String"]>;
  shippedDate?: Maybe<Scalars["Timestamp"]>;
  source?: Maybe<ShipmentSource>;
  status?: Maybe<ShipmentStatus>;
  trackingNumbers: Array<Maybe<Scalars["String"]>>;
  updated: Scalars["Timestamp"];
  workflowStage: Scalars["String"];
};

export type ShipmentCampaignId = {
  __typename?: "ShipmentCampaignId";
  campaignId: Scalars["Int"];
};

export enum ShipmentCarrier {
  Fedex = "FEDEX",
  Unknown = "UNKNOWN",
  Usps = "USPS",
}

export type ShipmentExternalId = ShipmentCampaignId | ShipmentOtherId;

export type ShipmentInput = {
  campaignId?: InputMaybe<Scalars["Int"]>;
  externalId?: InputMaybe<Scalars["String"]>;
};

export type ShipmentOtherId = {
  __typename?: "ShipmentOtherId";
  externalId: Scalars["UUID"];
};

export type ShipmentResponse = {
  __typename?: "ShipmentResponse";
  shipments: Array<Maybe<Shipment>>;
  status: ResponseStatus;
};

export enum ShipmentSource {
  Otf = "OTF",
  OtkIncentive = "OTK_INCENTIVE",
  Store = "STORE",
}

export enum ShipmentStatus {
  Processing = "PROCESSING",
  Shipped = "SHIPPED",
}

export enum ShippingProvider {
  Dhl = "DHL",
  FedEx = "FedEx",
  Osm = "OSM",
  Ups = "UPS",
  Usps = "USPS",
}

export type SignUpResponseWithAvailableTransport = {
  __typename?: "SignUpResponseWithAvailableTransport";
  availableTransport: Array<TransportEnum>;
  result: Array<TransportType>;
};

export type SignupAgreement = {
  __typename?: "SignupAgreement";
  content?: Maybe<Scalars["String"]>;
  createdAt?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
};

export type SmsInviteData = {
  __typename?: "SmsInviteData";
  count?: Maybe<Scalars["Int"]>;
  isReleased?: Maybe<Scalars["Boolean"]>;
  smsInviteReleasedAt?: Maybe<Scalars["DateTime"]>;
};

export enum SnapFeePayer {
  Customer = "CUSTOMER",
  Snap = "SNAP",
}

export enum SnapService {
  Campaigns = "campaigns",
  Comms = "comms",
  Connect = "connect",
  Donors = "donors",
  Drive = "drive",
  Fanx = "fanx",
  Fundraisers = "fundraisers",
  Home = "home",
  Insights = "insights",
  Manage = "manage",
  Orgs = "orgs",
  Raise = "raise",
  Spend = "spend",
  Sponsor = "sponsor",
  Store = "store",
  Userdirectory = "userdirectory",
  Vault = "vault",
  Wallet = "wallet",
}

export enum Sort {
  Asc = "ASC",
  Desc = "DESC",
}

export enum SortDirection {
  Asc = "ASC",
  Desc = "DESC",
}

export enum SortField {
  AdvancedFunds = "ADVANCED_FUNDS",
  ClosedStatus = "CLOSED_STATUS",
  DaysLeft = "DAYS_LEFT",
  EndDate = "END_DATE",
  KycStatus = "KYC_STATUS",
  Name = "NAME",
  Participation = "PARTICIPATION",
  Preload = "PRELOAD",
  SettlementDetails = "SETTLEMENT_DETAILS",
  StartDate = "START_DATE",
  Status = "STATUS",
  TotalRaised = "TOTAL_RAISED",
}

export enum SortOrderEnum {
  Asc = "asc",
  Desc = "desc",
}

export type SpendAchCreditResponse = {
  __typename?: "SpendACHCreditResponse";
  paymentId: Scalars["String"];
};

export type SpendAccount = {
  __typename?: "SpendAccount";
  /** @deprecated removed later */
  account?: Maybe<Scalars["String"]>;
  available?: Maybe<Scalars["Int"]>;
  balance?: Maybe<Scalars["Int"]>;
  groupId?: Maybe<Scalars["String"]>;
  groupStatus?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  /** @deprecated removed later */
  routing?: Maybe<Scalars["String"]>;
  status?: Maybe<Scalars["String"]>;
  type?: Maybe<Scalars["String"]>;
};

export type SpendAccountBearerToken = {
  verificationCode?: InputMaybe<Scalars["String"]>;
  verificationToken?: InputMaybe<Scalars["String"]>;
};

export type SpendAccountBearerTokenResponse = {
  __typename?: "SpendAccountBearerTokenResponse";
  expiresIn?: Maybe<Scalars["Int"]>;
  token?: Maybe<Scalars["String"]>;
};

export type SpendAccountId = {
  __typename?: "SpendAccountID";
  id: Scalars["String"];
  reason?: Maybe<Scalars["String"]>;
  status: Scalars["String"];
};

export type SpendAccountLimitsResponse = {
  __typename?: "SpendAccountLimitsResponse";
  ach?: Maybe<SpendAchLimitsData>;
  bookTransfer?: Maybe<SpendBookTransferLimitsData>;
  card?: Maybe<SpendCardLimitsData>;
  checkDeposit?: Maybe<SpendCheckDepositLimitsData>;
  checkPayment?: Maybe<SpendCheckPaymentLimitData>;
  wire?: Maybe<SpendWireLimitsData>;
};

export type SpendAccountResponse = {
  __typename?: "SpendAccountResponse";
  accounts?: Maybe<Array<Maybe<SpendAccount>>>;
  count?: Maybe<Scalars["Int"]>;
  groupsBalance?: Maybe<SpendGroupAccountBalances>;
  totalBalance?: Maybe<Scalars["Int"]>;
};

export type SpendAccountTransferInput = {
  amount: Scalars["Int"];
  description: Scalars["String"];
  fromGroupIdOrOrgId: Scalars["String"];
  toGroupIdOrOrgId: Scalars["String"];
};

export type SpendAchLimits = {
  __typename?: "SpendAchLimits";
  dailyCredit?: Maybe<Scalars["Int"]>;
  dailyDebit?: Maybe<Scalars["Int"]>;
  monthlyCredit?: Maybe<Scalars["Int"]>;
  monthlyDebit?: Maybe<Scalars["Int"]>;
};

export type SpendAchLimitsData = {
  __typename?: "SpendAchLimitsData";
  limits: SpendAchLimits;
};

export type SpendAchPaymentCancelInput = {
  externalId?: InputMaybe<Scalars["String"]>;
  id: Scalars["String"];
};

export type SpendAchPaymentId = {
  __typename?: "SpendAchPaymentID";
  id?: Maybe<Scalars["String"]>;
};

export type SpendAchPaymentInput = {
  authorizedAt: Scalars["String"];
  description?: InputMaybe<Scalars["String"]>;
  externalId: Scalars["String"];
  groupId: Scalars["String"];
  invoiceIds: Array<InputMaybe<Scalars["String"]>>;
  paymentAmount: Scalars["Int"];
};

export type SpendAchPaymentResponse = {
  __typename?: "SpendAchPaymentResponse";
  amount: Scalars["Int"];
  descriptor: Scalars["String"];
  id: Scalars["String"];
  status: Scalars["String"];
  transactionIdList?: Maybe<Array<Maybe<Scalars["String"]>>>;
};

export type SpendAddress = {
  __typename?: "SpendAddress";
  city?: Maybe<Scalars["String"]>;
  state?: Maybe<Scalars["String"]>;
  street?: Maybe<Scalars["String"]>;
  zip?: Maybe<Scalars["String"]>;
};

export type SpendAddressInput = {
  city: Scalars["String"];
  state: Scalars["String"];
  street: Scalars["String"];
  street2?: InputMaybe<Scalars["String"]>;
  zip: Scalars["String"];
};

export type SpendAgreementId = {
  __typename?: "SpendAgreementID";
  id?: Maybe<Scalars["String"]>;
};

export type SpendAllInviteIds = {
  __typename?: "SpendAllInviteIds";
  count: Scalars["Int"];
  inviteIds?: Maybe<Array<Scalars["String"]>>;
};

export type SpendAppliedInvoices = {
  amount: Scalars["Int"];
  invoiceId: Scalars["String"];
};

export type SpendArchiveSeasonMembersInput = {
  groupRosterIds: Array<InputMaybe<Scalars["String"]>>;
  isArchived: Scalars["Boolean"];
};

export type SpendArchiveSeasonMembersResponse = {
  __typename?: "SpendArchiveSeasonMembersResponse";
  success: Scalars["Boolean"];
};

export type SpendAuthorizeAutoPayResponse = {
  __typename?: "SpendAuthorizeAutoPayResponse";
  invoiceIds?: Maybe<Array<Scalars["String"]>>;
};

export type SpendAutoPayInput = {
  cardId?: InputMaybe<Scalars["String"]>;
  groupRosterId: Scalars["String"];
  invoiceIds: Array<Scalars["String"]>;
  paymentMethodSource: Scalars["String"];
};

export type SpendBankAccessCreateResponse = {
  __typename?: "SpendBankAccessCreateResponse";
  id?: Maybe<Scalars["String"]>;
};

export type SpendBankAccessDeleteResponse = {
  __typename?: "SpendBankAccessDeleteResponse";
  success?: Maybe<Scalars["Boolean"]>;
};

export type SpendBankAccount = {
  __typename?: "SpendBankAccount";
  accountId?: Maybe<Scalars["String"]>;
  availableBalance?: Maybe<Scalars["Float"]>;
  currentBalance?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["String"]>;
  institution?: Maybe<SpendInstitution>;
  mask?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  officialName?: Maybe<Scalars["String"]>;
  subtype?: Maybe<Scalars["String"]>;
  type?: Maybe<Scalars["String"]>;
};

export type SpendBankAccountsResponse = {
  __typename?: "SpendBankAccountsResponse";
  externalAccounts?: Maybe<Array<Maybe<SpendBankAccount>>>;
  status?: Maybe<Scalars["String"]>;
};

export type SpendBankLinkCreateResponse = {
  __typename?: "SpendBankLinkCreateResponse";
  expiration?: Maybe<Scalars["String"]>;
  linkToken?: Maybe<Scalars["String"]>;
  requestId?: Maybe<Scalars["String"]>;
};

export type SpendBankTransaction = {
  __typename?: "SpendBankTransaction";
  amount?: Maybe<Scalars["Int"]>;
  attachments?: Maybe<Array<TransactionAttachment>>;
  canCancel?: Maybe<Scalars["Boolean"]>;
  correlationId?: Maybe<Scalars["String"]>;
  created?: Maybe<Scalars["String"]>;
  creditMemo?: Maybe<SpendMemoTransaction>;
  /** @deprecated Can be found in metadata */
  description?: Maybe<Scalars["String"]>;
  destination?: Maybe<Scalars["String"]>;
  direction?: Maybe<Scalars["String"]>;
  effective?: Maybe<Scalars["String"]>;
  external?: Maybe<SpendJoinedExternal>;
  externalId?: Maybe<Scalars["String"]>;
  groupRoster?: Maybe<SpendGroupRoster>;
  hasAttachments?: Maybe<Scalars["Boolean"]>;
  history?: Maybe<Array<Maybe<SpendBankTransactionHistory>>>;
  id?: Maybe<Scalars["String"]>;
  isReconciled?: Maybe<Scalars["Boolean"]>;
  metadata?: Maybe<SpendBankTransactionMetaData>;
  payee?: Maybe<SpendJoinedPayee>;
  processor?: Maybe<Scalars["String"]>;
  reconciliation?: Maybe<SpendReconciledTransaction>;
  snapAmount?: Maybe<Scalars["Int"]>;
  source?: Maybe<Scalars["String"]>;
  status?: Maybe<Scalars["String"]>;
  totalReconciled?: Maybe<Scalars["Int"]>;
  transactionNote?: Maybe<Scalars["String"]>;
  transactionStatus?: Maybe<Scalars["String"]>;
  transactionType?: Maybe<Scalars["String"]>;
  type?: Maybe<Scalars["String"]>;
  typeReconciled?: Maybe<Scalars["String"]>;
};

export type SpendBankTransactionDetail = {
  __typename?: "SpendBankTransactionDetail";
  amount?: Maybe<Scalars["Int"]>;
  canCancel?: Maybe<Scalars["Boolean"]>;
  correlationId?: Maybe<Scalars["String"]>;
  /** @deprecated Can be found in metadata */
  description?: Maybe<Scalars["String"]>;
  destination?: Maybe<Scalars["String"]>;
  direction?: Maybe<Scalars["String"]>;
  effective?: Maybe<Scalars["String"]>;
  externalId?: Maybe<Scalars["String"]>;
  groupRoster?: Maybe<SpendGroupRoster>;
  history?: Maybe<Array<Maybe<SpendBankTransactionHistory>>>;
  id?: Maybe<Scalars["String"]>;
  metadata?: Maybe<SpendBankTransactionMetaData>;
  paymentStatus?: Maybe<Scalars["String"]>;
  processor?: Maybe<Scalars["String"]>;
  reconciliation?: Maybe<SpendReconciledTransaction>;
  snapAmount?: Maybe<Scalars["Int"]>;
  source?: Maybe<Scalars["String"]>;
  status?: Maybe<Scalars["String"]>;
  transactionType?: Maybe<Scalars["String"]>;
  type?: Maybe<Scalars["String"]>;
};

export type SpendBankTransactionHistory = {
  __typename?: "SpendBankTransactionHistory";
  date?: Maybe<Scalars["String"]>;
  status?: Maybe<Scalars["String"]>;
  transactionId?: Maybe<Scalars["String"]>;
};

export type SpendBankTransactionMetaData = {
  __typename?: "SpendBankTransactionMetaData";
  account?: Maybe<Scalars["String"]>;
  addenda?: Maybe<Scalars["String"]>;
  balance?: Maybe<Scalars["String"]>;
  customer?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  destination?: Maybe<Scalars["String"]>;
  externalId?: Maybe<Scalars["String"]>;
  /** @deprecated Can be found in tags as SpendInvoiceIds */
  invoiceId?: Maybe<Scalars["String"]>;
  originalTransaction?: Maybe<Scalars["String"]>;
  payment?: Maybe<Scalars["String"]>;
  processor?: Maybe<Scalars["String"]>;
  /** @deprecated Can be found in tags */
  product?: Maybe<Scalars["String"]>;
  returnedTransaction?: Maybe<Scalars["String"]>;
  snapAmount?: Maybe<Scalars["Int"]>;
  source?: Maybe<Scalars["String"]>;
  status?: Maybe<Scalars["String"]>;
  summary?: Maybe<Scalars["String"]>;
  tags?: Maybe<SpendTransactionTags>;
};

export type SpendBankTransactionResponse = {
  __typename?: "SpendBankTransactionResponse";
  transactionDetail?: Maybe<SpendBankTransactionDetail>;
};

export type SpendBankTransactionsResponse = {
  __typename?: "SpendBankTransactionsResponse";
  count?: Maybe<Scalars["Int"]>;
  transactions?: Maybe<Array<Maybe<SpendBankTransaction>>>;
};

export type SpendBookTransferLimitsData = {
  __typename?: "SpendBookTransferLimitsData";
  limits: SpendAchLimits;
};

export type SpendBudget = {
  __typename?: "SpendBudget";
  category: SpendCategory;
  createdAt: Scalars["String"];
  description: Scalars["String"];
  id: Scalars["String"];
  invoices?: Maybe<Array<SpendInvoice>>;
  isDefault?: Maybe<Scalars["Boolean"]>;
  reconciledBudgetTotal?: Maybe<Scalars["Int"]>;
  reconciledInvoicesTotal?: Maybe<Scalars["Int"]>;
  reconciledTotal?: Maybe<Scalars["Int"]>;
  reconciledTransactions?: Maybe<Array<SpendReconciledBudgetTransaction>>;
  season?: Maybe<SpendSeason>;
  targetAmount: Scalars["Int"];
  targetDateAt: Scalars["String"];
  updatedAt?: Maybe<Scalars["String"]>;
  vaultId?: Maybe<Scalars["String"]>;
};

export type SpendBudgetId = {
  __typename?: "SpendBudgetID";
  id?: Maybe<Scalars["String"]>;
};

export type SpendBudgetInput = {
  categoryId: Scalars["String"];
  description: Scalars["String"];
  isDefault?: InputMaybe<Scalars["Boolean"]>;
  seasonId?: InputMaybe<Scalars["String"]>;
  targetAmount: Scalars["Int"];
  targetDateAt: Scalars["String"];
  vaultId?: InputMaybe<Scalars["String"]>;
};

export type SpendBudgetReconciledTotal = {
  __typename?: "SpendBudgetReconciledTotal";
  id: Scalars["String"];
  reconciledAmount: Scalars["Int"];
  seasonId?: Maybe<Scalars["String"]>;
  targetAmount: Scalars["Int"];
};

export type SpendBudgetResponse = {
  __typename?: "SpendBudgetResponse";
  budget?: Maybe<SpendBudget>;
};

export type SpendBudgetSummaryResponse = {
  __typename?: "SpendBudgetSummaryResponse";
  summaryByCategory?: Maybe<Array<Maybe<SpendCategory>>>;
  summaryUnreconciled?: Maybe<SpendUnreconciledSummary>;
};

export type SpendBudgetSummaryV2Response = {
  __typename?: "SpendBudgetSummaryV2Response";
  reconciledTotals?: Maybe<Array<Maybe<SpendReconciledTotals>>>;
  summaryByCategory?: Maybe<Array<Maybe<SpendCategory>>>;
  summaryUnreconciled?: Maybe<SpendUnreconciledSummary>;
};

export type SpendBudgetTransactionInput = {
  transactionIds: Array<InputMaybe<Scalars["String"]>>;
};

export type SpendBudgetTransactionsOutput = {
  __typename?: "SpendBudgetTransactionsOutput";
  budgetTransactions: Array<Maybe<BudgetTransaction>>;
};

export type SpendBudgetsResponse = {
  __typename?: "SpendBudgetsResponse";
  budgets?: Maybe<Array<Maybe<SpendBudget>>>;
  count?: Maybe<Scalars["Int"]>;
};

export type SpendCancelCheckId = {
  __typename?: "SpendCancelCheckID";
  id: Scalars["String"];
};

export type SpendCardLimits = {
  __typename?: "SpendCardLimits";
  dailyCardTransaction: Scalars["Int"];
  dailyDeposit: Scalars["Int"];
  dailyPurchase: Scalars["Int"];
  dailyWithdrawal: Scalars["Int"];
};

export type SpendCardLimitsData = {
  __typename?: "SpendCardLimitsData";
  limits: SpendCardLimits;
};

export type SpendCategory = {
  __typename?: "SpendCategory";
  budgets?: Maybe<Array<Maybe<SpendBudget>>>;
  createdAt: Scalars["String"];
  id?: Maybe<Scalars["String"]>;
  isDefault: Scalars["Boolean"];
  isHidden: Scalars["Boolean"];
  name: Scalars["String"];
  organizationId: Scalars["String"];
  type: Scalars["String"];
  updatedAt?: Maybe<Scalars["String"]>;
};

export type SpendCategoryId = {
  __typename?: "SpendCategoryID";
  id?: Maybe<Scalars["String"]>;
};

export type SpendCategoryInput = {
  isDefault?: InputMaybe<Scalars["Boolean"]>;
  isHidden?: InputMaybe<Scalars["Boolean"]>;
  name?: InputMaybe<Scalars["String"]>;
  type?: InputMaybe<CategoryTypeEnum>;
};

export type SpendCategoryResponse = {
  __typename?: "SpendCategoryResponse";
  categories?: Maybe<Array<Maybe<SpendCategory>>>;
  count?: Maybe<Scalars["Int"]>;
};

export type SpendCheckDepositLimits = {
  __typename?: "SpendCheckDepositLimits";
  daily: Scalars["Int"];
  monthly: Scalars["Int"];
};

export type SpendCheckDepositLimitsData = {
  __typename?: "SpendCheckDepositLimitsData";
  limits: SpendCheckDepositLimits;
};

export type SpendCheckDepositTagsResponse = {
  __typename?: "SpendCheckDepositTagsResponse";
  success: Scalars["Boolean"];
};

export type SpendCheckInput = {
  address: SpendAddressInput;
  amount: Scalars["Int"];
  description: Scalars["String"];
  name: Scalars["String"];
  note?: InputMaybe<Scalars["String"]>;
  payeeId: Scalars["String"];
  referenceId: Scalars["String"];
  sendAt?: InputMaybe<Scalars["String"]>;
};

export type SpendCheckPaymentLimit = {
  __typename?: "SpendCheckPaymentLimit";
  dailySent: Scalars["Int"];
  monthlySent: Scalars["Int"];
};

export type SpendCheckPaymentLimitData = {
  __typename?: "SpendCheckPaymentLimitData";
  limits?: Maybe<SpendCheckPaymentLimit>;
};

export type SpendCheckSendResponse = {
  __typename?: "SpendCheckSendResponse";
  id?: Maybe<Scalars["String"]>;
  status?: Maybe<Scalars["String"]>;
  type?: Maybe<Scalars["String"]>;
};

export type SpendCounterpartiesResponse = {
  __typename?: "SpendCounterpartiesResponse";
  counterparties: Array<SpendCounterparty>;
};

export type SpendCounterparty = {
  __typename?: "SpendCounterparty";
  accountLastFour: Scalars["String"];
  bankName: Scalars["String"];
  id: Scalars["String"];
  name: Scalars["String"];
  routingNumber: Scalars["String"];
};

export type SpendCounterpartyCreateInput = {
  accountNumber: Scalars["String"];
  accountType: Scalars["String"];
  entityType: Scalars["String"];
  groupOrOrgId: Scalars["String"];
  name: Scalars["String"];
  routingNumber: Scalars["String"];
};

export type SpendCounterpartyCreateResponse = {
  __typename?: "SpendCounterpartyCreateResponse";
  id: Scalars["String"];
};

export type SpendCreditMemoCreateResponse = {
  __typename?: "SpendCreditMemoCreateResponse";
  ids: Array<Scalars["String"]>;
};

export type SpendCreditMemoInput = {
  creditAmount: Scalars["Int"];
  dateToApply: Scalars["String"];
  invoicesApplied: Array<SpendAppliedInvoices>;
  note?: InputMaybe<Scalars["String"]>;
};

export type SpendCreditMemoUpdateInput = {
  creditApplied: Scalars["Int"];
  dateToApply: Scalars["String"];
  id: Scalars["String"];
  invoiceId: Scalars["String"];
  isArchived: Scalars["Boolean"];
  note?: InputMaybe<Scalars["String"]>;
};

export type SpendCreditMemoUpdateResponse = {
  __typename?: "SpendCreditMemoUpdateResponse";
  id: Scalars["String"];
};

export type SpendDeauthorizeResponse = {
  __typename?: "SpendDeauthorizeResponse";
  id?: Maybe<Scalars["String"]>;
  notifSuccess?: Maybe<Scalars["Boolean"]>;
};

export type SpendDebitCard = {
  __typename?: "SpendDebitCard";
  expiration?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  lastFour?: Maybe<Scalars["String"]>;
  status?: Maybe<Scalars["String"]>;
  userId?: Maybe<Scalars["String"]>;
};

export type SpendDebitCardId = {
  __typename?: "SpendDebitCardID";
  id?: Maybe<Scalars["String"]>;
};

export type SpendDebitCardInput = {
  dateOfBirth: Scalars["String"];
  shipping?: InputMaybe<SpendDebitCardShippingInput>;
  userId: Scalars["String"];
};

export type SpendDebitCardResponse = {
  __typename?: "SpendDebitCardResponse";
  cards?: Maybe<Array<Maybe<SpendDebitCard>>>;
  count?: Maybe<Scalars["Int"]>;
};

export type SpendDebitCardShippingInput = {
  city: Scalars["String"];
  postalCode: Scalars["String"];
  state: Scalars["String"];
  street: Scalars["String"];
  street2?: InputMaybe<Scalars["String"]>;
};

export type SpendDeleteCounterpartyResponse = {
  __typename?: "SpendDeleteCounterpartyResponse";
  success: Scalars["Boolean"];
};

export type SpendDismissInviteResponse = {
  __typename?: "SpendDismissInviteResponse";
  inviteId: Scalars["String"];
};

export type SpendExportEmailsInput = {
  emails: Array<InputMaybe<Scalars["String"]>>;
};

export type SpendExternalTransferResponse = {
  __typename?: "SpendExternalTransferResponse";
  amount?: Maybe<Scalars["Int"]>;
  descriptor?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  status?: Maybe<Scalars["String"]>;
};

export type SpendGroup = {
  __typename?: "SpendGroup";
  accountId?: Maybe<Scalars["String"]>;
  archivedAt?: Maybe<Scalars["String"]>;
  createdAt?: Maybe<Scalars["String"]>;
  discountAmount?: Maybe<Scalars["Int"]>;
  discountCutOffDate?: Maybe<Scalars["String"]>;
  enableDiscount?: Maybe<Scalars["Boolean"]>;
  hasAccount?: Maybe<Scalars["Boolean"]>;
  id?: Maybe<Scalars["String"]>;
  isArchived?: Maybe<Scalars["Boolean"]>;
  isRequireAgreement?: Maybe<Scalars["Boolean"]>;
  latestSeason?: Maybe<SpendSeason>;
  legacyAccountId?: Maybe<Scalars["String"]>;
  minimumDiscountPurchase?: Maybe<Scalars["Int"]>;
  name?: Maybe<Scalars["String"]>;
  organizationFees?: Maybe<SpendOrganizationFee>;
  organizationId?: Maybe<Scalars["String"]>;
  programId?: Maybe<Scalars["String"]>;
  seasons?: Maybe<Array<Maybe<SpendSeason>>>;
  seasonsList?: Maybe<Array<Maybe<SpendSeason>>>;
  sharedAccount?: Maybe<Scalars["Boolean"]>;
  unitAmount?: Maybe<Scalars["Int"]>;
};

export type SpendGroupAchCredit = {
  amount: Scalars["Int"];
  counterpartyId: Scalars["String"];
  counterpartyName: Scalars["String"];
  groupId: Scalars["String"];
  note: Scalars["String"];
};

export type SpendGroupAccountBalances = {
  __typename?: "SpendGroupAccountBalances";
  active?: Maybe<Scalars["Int"]>;
  archived?: Maybe<Scalars["Int"]>;
};

export type SpendGroupCheckDepositTagsInput = {
  checkDepositId: Scalars["String"];
  groupId?: InputMaybe<Scalars["String"]>;
};

export type SpendGroupExternalTransferInput = {
  accountId: Scalars["String"];
  amount: Scalars["Int"];
  direction: ExternalTransferDirectionEnum;
  id: Scalars["String"];
  note: Scalars["String"];
};

export type SpendGroupId = {
  __typename?: "SpendGroupID";
  id?: Maybe<Scalars["String"]>;
};

export type SpendGroupInput = {
  discountAmount?: InputMaybe<Scalars["Int"]>;
  discountCutOffDate?: InputMaybe<Scalars["String"]>;
  enableDiscount: Scalars["Boolean"];
  hasAccount?: InputMaybe<Scalars["Boolean"]>;
  isBudgetShared?: InputMaybe<Scalars["Boolean"]>;
  isLinkEnabled: Scalars["Boolean"];
  isRequireAgreement?: InputMaybe<Scalars["Boolean"]>;
  minimumDiscountPurchase?: InputMaybe<Scalars["Int"]>;
  name: Scalars["String"];
  seasonEndAt: Scalars["String"];
  seasonName?: InputMaybe<Scalars["String"]>;
  seasonStartAt: Scalars["String"];
  shareAccount: Scalars["Boolean"];
};

export type SpendGroupResponse = {
  __typename?: "SpendGroupResponse";
  count?: Maybe<Scalars["Int"]>;
  groups?: Maybe<Array<Maybe<SpendGroup>>>;
};

export type SpendGroupRoster = {
  __typename?: "SpendGroupRoster";
  archivedAt?: Maybe<Scalars["String"]>;
  createdAt?: Maybe<Scalars["String"]>;
  group?: Maybe<SpendGroup>;
  groupId?: Maybe<Scalars["String"]>;
  guardianName?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  invite?: Maybe<SpendInvite>;
  inviteId?: Maybe<Scalars["String"]>;
  invoices?: Maybe<Array<Maybe<SpendInvoice>>>;
  isArchived?: Maybe<Scalars["Boolean"]>;
  joinedAt?: Maybe<Scalars["String"]>;
  pastDueDays?: Maybe<Scalars["Int"]>;
  paymentScheduleStatus?: Maybe<Scalars["String"]>;
  phoneNumber?: Maybe<Scalars["String"]>;
  roster?: Maybe<SpendRoster>;
  rosterId?: Maybe<Scalars["String"]>;
  season?: Maybe<SpendSeason>;
  seasonId?: Maybe<Scalars["String"]>;
  settings?: Maybe<SpendGroupRosterSettings>;
  status?: Maybe<SpendGroupRosterStatusEnum>;
  total?: Maybe<SpendTransactionTotals>;
  userId?: Maybe<Scalars["String"]>;
};

export type SpendGroupRosterId = {
  __typename?: "SpendGroupRosterID";
  id?: Maybe<Scalars["String"]>;
};

export type SpendGroupRosterInput = {
  email: Scalars["String"];
  groupId: Scalars["String"];
  name: Scalars["String"];
  seasonId: Scalars["String"];
};

export type SpendGroupRosterResponse = {
  __typename?: "SpendGroupRosterResponse";
  count?: Maybe<Scalars["Int"]>;
  groupRosters?: Maybe<Array<Maybe<SpendGroupRoster>>>;
};

export type SpendGroupRosterSettings = {
  __typename?: "SpendGroupRosterSettings";
  isAutoPayAuthorized?: Maybe<Scalars["Boolean"]>;
};

export type SpendGroupRosterSettingsResponse = {
  __typename?: "SpendGroupRosterSettingsResponse";
  groupRosterId?: Maybe<Scalars["String"]>;
  settingsId?: Maybe<Scalars["String"]>;
};

export enum SpendGroupRosterStatusEnum {
  Archived = "archived",
  AutopayStop = "autopay_stop",
  AwaitingApproval = "awaiting_approval",
  Canceled = "canceled",
  Expired = "expired",
  NoInviteSent = "no_invite_sent",
  NotSignedUp = "not_signed_up",
  Paid = "paid",
  PastDue = "past_due",
}

export type SpendGroupRostersInput = {
  groupId: Scalars["String"];
  rosters?: InputMaybe<Array<InputMaybe<SpendRosterUserInput>>>;
  seasonId: Scalars["String"];
};

export type SpendGroupRostersWhereInput = {
  groupNameIncludes?: InputMaybe<Scalars["String"]>;
  ids?: InputMaybe<Array<Scalars["String"]>>;
  nameIncludes?: InputMaybe<Scalars["String"]>;
  status?: InputMaybe<SpendGroupRosterStatusEnum>;
};

export type SpendGroupsOverview = {
  __typename?: "SpendGroupsOverview";
  balance?: Maybe<Scalars["Int"]>;
  cards?: Maybe<Array<Maybe<SpendDebitCard>>>;
  collected?: Maybe<Scalars["Int"]>;
  groupId?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  pastDue?: Maybe<Scalars["Int"]>;
  paymentScheduleInvoices?: Maybe<Array<Maybe<SpendPaymentSchedule>>>;
  seasonEndAt?: Maybe<Scalars["String"]>;
  seasonName?: Maybe<Scalars["String"]>;
  seasonStartAt?: Maybe<Scalars["String"]>;
  sharedAccount?: Maybe<Scalars["Boolean"]>;
};

export type SpendGroupsResponse = {
  __typename?: "SpendGroupsResponse";
  count?: Maybe<Scalars["Int"]>;
  groups?: Maybe<Array<Maybe<SpendGroup>>>;
  hasNext?: Maybe<Scalars["Boolean"]>;
};

export type SpendGroupsWhereInput = {
  /** Search archived groups only */
  archived?: InputMaybe<Scalars["Boolean"]>;
  /** Search by group name only */
  groupNameIncludes?: InputMaybe<Scalars["String"]>;
  /** Search by list of group id */
  ids?: InputMaybe<Array<Scalars["String"]>>;
  /** Search by group name or latest season name in group */
  nameIncludes?: InputMaybe<Scalars["String"]>;
  orgId?: InputMaybe<Scalars["String"]>;
  /** Search by payment schedule status of latest season in group. Support multiple values. (OR operation) */
  paymentScheduleStatus?: InputMaybe<Array<Scalars["String"]>>;
};

export type SpendGuardianActiveGroup = {
  __typename?: "SpendGuardianActiveGroup";
  /** @deprecated No longer required */
  dueDate?: Maybe<Scalars["String"]>;
  group?: Maybe<SpendGuardianGroup>;
  /** @deprecated No longer required */
  roster?: Maybe<Array<Maybe<SpendRoster>>>;
};

export type SpendGuardianComingSoonInvoice = {
  __typename?: "SpendGuardianComingSoonInvoice";
  groupId?: Maybe<Scalars["String"]>;
  groupName?: Maybe<Scalars["String"]>;
  groupRosterId?: Maybe<Scalars["String"]>;
  invoiceAmountDue?: Maybe<Scalars["String"]>;
  invoiceAutoPayAuthorized?: Maybe<Scalars["Boolean"]>;
  invoiceAutoPayStopped?: Maybe<Scalars["Boolean"]>;
  invoiceDescription?: Maybe<Scalars["String"]>;
  invoiceDueDate?: Maybe<Scalars["String"]>;
  invoiceId?: Maybe<Scalars["String"]>;
  invoiceNote?: Maybe<Scalars["String"]>;
  invoiceOptedOutAt?: Maybe<Scalars["String"]>;
  invoicePaymentMethod?: Maybe<Scalars["String"]>;
  invoiceStatus?: Maybe<Scalars["String"]>;
  isAuthorized?: Maybe<Scalars["Boolean"]>;
  isOptional?: Maybe<Scalars["Boolean"]>;
  optedIn?: Maybe<Scalars["Boolean"]>;
  rosterName?: Maybe<Scalars["String"]>;
  seasonId?: Maybe<Scalars["String"]>;
  seasonName?: Maybe<Scalars["String"]>;
};

export type SpendGuardianCounts = {
  __typename?: "SpendGuardianCounts";
  activeGroups?: Maybe<Scalars["Int"]>;
  comingSoonInvoices?: Maybe<Scalars["Int"]>;
  unauthorizedInvoices?: Maybe<Scalars["Int"]>;
};

export type SpendGuardianGroup = {
  __typename?: "SpendGuardianGroup";
  accountId?: Maybe<Scalars["String"]>;
  archivedAt?: Maybe<Scalars["String"]>;
  createdAt?: Maybe<Scalars["String"]>;
  hasAccount?: Maybe<Scalars["Boolean"]>;
  id?: Maybe<Scalars["String"]>;
  isArchived?: Maybe<Scalars["Boolean"]>;
  latestSeason?: Maybe<SpendSeason>;
  name?: Maybe<Scalars["String"]>;
  organizationId?: Maybe<Scalars["String"]>;
  programId?: Maybe<Scalars["String"]>;
  seasons?: Maybe<Array<Maybe<SpendGuardianSeason>>>;
};

export type SpendGuardianHighlight = {
  __typename?: "SpendGuardianHighlight";
  activeGroups?: Maybe<Array<Maybe<SpendGuardianActiveGroup>>>;
  comingSoonInvoices?: Maybe<Array<Maybe<SpendGuardianComingSoonInvoice>>>;
  counts?: Maybe<SpendGuardianCounts>;
  unauthorizedInvoices?: Maybe<Array<Maybe<SpendGuardianComingSoonInvoice>>>;
};

export type SpendGuardianInvoice = {
  __typename?: "SpendGuardianInvoice";
  amount: Scalars["Int"];
  authorizedAt?: Maybe<Scalars["String"]>;
  balanceDue: Scalars["Int"];
  creditMemos?: Maybe<Array<SpendMemo>>;
  description: Scalars["String"];
  discountAmount: Scalars["Int"];
  dueDate: Scalars["String"];
  groupId: Scalars["String"];
  groupName: Scalars["String"];
  groupRosterId: Scalars["String"];
  id: Scalars["String"];
  isArchived: Scalars["Boolean"];
  isAutoPayAuthorized: Scalars["Boolean"];
  isAutoPayStopped: Scalars["Boolean"];
  isOptional: Scalars["Boolean"];
  isRefunded: Scalars["Boolean"];
  note?: Maybe<Scalars["String"]>;
  optedIn: Scalars["Boolean"];
  optedOutAt?: Maybe<Scalars["String"]>;
  paid: Scalars["Boolean"];
  paidDate?: Maybe<Scalars["String"]>;
  paymentMethodId?: Maybe<Scalars["String"]>;
  paymentMethodSource?: Maybe<Scalars["String"]>;
  refundDate?: Maybe<Scalars["String"]>;
  rosterId: Scalars["String"];
  rosterName: Scalars["String"];
  seasonId: Scalars["String"];
  seasonName: Scalars["String"];
  status: Scalars["String"];
};

export type SpendGuardianInvoiceFilter = {
  by: InvoiceGuardianFilterEnum;
  value: Scalars["String"];
};

export type SpendGuardianInvoiceResponse = {
  __typename?: "SpendGuardianInvoiceResponse";
  count: Scalars["Int"];
  invoices?: Maybe<Array<SpendGuardianInvoice>>;
};

export type SpendGuardianRecentTransaction = {
  __typename?: "SpendGuardianRecentTransaction";
  amountDue?: Maybe<Scalars["String"]>;
  dueDate?: Maybe<Scalars["String"]>;
  groupName?: Maybe<Scalars["String"]>;
  invoiceDescription?: Maybe<Scalars["String"]>;
  paymentMethod?: Maybe<Scalars["String"]>;
  paymentStatus?: Maybe<Scalars["String"]>;
  paymentType?: Maybe<Scalars["String"]>;
  rosterName?: Maybe<Scalars["String"]>;
  transactionId?: Maybe<Scalars["String"]>;
};

export type SpendGuardianSeason = {
  __typename?: "SpendGuardianSeason";
  endDateAt?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  isBudgetShared?: Maybe<Scalars["Boolean"]>;
  name?: Maybe<Scalars["String"]>;
  roster?: Maybe<Array<Maybe<SpendRoster>>>;
  startDateAt?: Maybe<Scalars["String"]>;
};

export type SpendGuardianSignupInput = {
  groupId: Scalars["String"];
  rosterName: Scalars["String"];
  seasonId: Scalars["String"];
};

export type SpendInstitution = {
  __typename?: "SpendInstitution";
  id?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
};

export type SpendInstitutionResponse = {
  __typename?: "SpendInstitutionResponse";
  address: Scalars["String"];
  isACHSupported: Scalars["Boolean"];
  isWireSupported: Scalars["Boolean"];
  name: Scalars["String"];
  routingNumber: Scalars["String"];
};

export type SpendInvite = {
  __typename?: "SpendInvite";
  createdAt?: Maybe<Scalars["String"]>;
  debitCard?: Maybe<Scalars["String"]>;
  debitCards?: Maybe<Array<SpendDebitCard>>;
  email?: Maybe<Scalars["String"]>;
  expiresAt?: Maybe<Scalars["String"]>;
  firstName?: Maybe<Scalars["String"]>;
  group?: Maybe<SpendGroup>;
  groupId?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  isApprover?: Maybe<Scalars["Boolean"]>;
  isArchived?: Maybe<Scalars["Boolean"]>;
  isDeliverable?: Maybe<Scalars["Boolean"]>;
  isDismissed?: Maybe<Scalars["Boolean"]>;
  isUser?: Maybe<Scalars["Boolean"]>;
  lastName?: Maybe<Scalars["String"]>;
  orgName?: Maybe<Scalars["String"]>;
  organizationId?: Maybe<Scalars["String"]>;
  seasonId?: Maybe<Scalars["String"]>;
  status?: Maybe<Scalars["String"]>;
  type?: Maybe<Scalars["String"]>;
  user?: Maybe<User>;
  userId?: Maybe<Scalars["String"]>;
};

export enum SpendInviteAllType {
  Group = "group",
  Guardian = "guardian",
  Program = "program",
}

export type SpendInviteId = {
  __typename?: "SpendInviteID";
  id?: Maybe<Scalars["String"]>;
};

export type SpendInviteInput = {
  email: Scalars["String"];
  firstName: Scalars["String"];
  groupId?: InputMaybe<Scalars["String"]>;
  lastName: Scalars["String"];
  seasonId?: InputMaybe<Scalars["String"]>;
  type: SpendInviteType;
  userId?: InputMaybe<Scalars["String"]>;
};

export type SpendInviteResendAllInput = {
  orgId: Scalars["String"];
  seasonId?: InputMaybe<Scalars["String"]>;
  userType?: InputMaybe<SpendInviteAllType>;
};

export type SpendInviteResendInput = {
  email: Scalars["String"];
  groupId: Scalars["String"];
  groupRosterId: Scalars["String"];
  id?: InputMaybe<Scalars["String"]>;
  seasonId: Scalars["String"];
  userId: Scalars["String"];
};

export type SpendInviteResponse = {
  __typename?: "SpendInviteResponse";
  count?: Maybe<Scalars["Int"]>;
  invites?: Maybe<Array<Maybe<SpendInvite>>>;
};

export enum SpendInviteType {
  GroupObserver = "group_observer",
  GroupStaff = "group_staff",
  Guardian = "guardian",
  ProgramAdmin = "program_admin",
  ProgramObserver = "program_observer",
  ProgramStaff = "program_staff",
}

export type SpendInviteWhereInput = {
  /** Search invitation which is archived */
  archived?: InputMaybe<Scalars["Boolean"]>;
  /** Search by group id */
  groupId?: InputMaybe<Scalars["String"]>;
  /** Search by invite name */
  nameIncludes?: InputMaybe<Scalars["String"]>;
  /** Search by roster name only */
  rosterNameIncludes?: InputMaybe<Scalars["String"]>;
  /** Search by invite status: accepted, pending, undeliverable */
  status?: InputMaybe<Scalars["String"]>;
  /** Search by invite types. Valid values: guardian,group_staff,program_staff, program_observer,program_admin */
  type?: InputMaybe<Array<SpendInviteType>>;
  /** Search by invite type context: group or program. This will be affect invite type filter. */
  typeContext?: InputMaybe<Scalars["String"]>;
};

export type SpendInvoice = {
  __typename?: "SpendInvoice";
  amount?: Maybe<Scalars["Int"]>;
  authorizedAt?: Maybe<Scalars["String"]>;
  balanceDue?: Maybe<Scalars["Int"]>;
  budgetItem?: Maybe<SpendBudget>;
  budgetItemId?: Maybe<Scalars["String"]>;
  createdAt?: Maybe<Scalars["String"]>;
  creditMemos?: Maybe<Array<SpendMemo>>;
  description?: Maybe<Scalars["String"]>;
  discountAmount?: Maybe<Scalars["Int"]>;
  dueDate?: Maybe<Scalars["String"]>;
  feesAmount?: Maybe<Scalars["Int"]>;
  groupRoster?: Maybe<SpendGroupRoster>;
  groupRosterId?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  isArchived?: Maybe<Scalars["Boolean"]>;
  isAutoPayAuthorized?: Maybe<Scalars["Boolean"]>;
  isAutoPayStopped?: Maybe<Scalars["Boolean"]>;
  isOptional?: Maybe<Scalars["Boolean"]>;
  isPending?: Maybe<Scalars["Boolean"]>;
  isReconciled?: Maybe<Scalars["Boolean"]>;
  isRefunded?: Maybe<Scalars["Boolean"]>;
  lastNotifyDate?: Maybe<Scalars["String"]>;
  lastNotifyId?: Maybe<Scalars["String"]>;
  note?: Maybe<Scalars["String"]>;
  notificationAttempts?: Maybe<Scalars["Int"]>;
  optedIn?: Maybe<Scalars["Boolean"]>;
  optedOutAt?: Maybe<Scalars["String"]>;
  paid?: Maybe<Scalars["Boolean"]>;
  paidDate?: Maybe<Scalars["String"]>;
  paymentMethodId?: Maybe<Scalars["String"]>;
  paymentMethodSource?: Maybe<Scalars["String"]>;
  paymentScheduleInvoiceId?: Maybe<Scalars["String"]>;
  paymentScheduleStatus?: Maybe<Scalars["String"]>;
  pendingMessage?: Maybe<SpendInvoicePendingMessage>;
  reconciledTransactions?: Maybe<
    Array<Maybe<SpendReconciledInvoiceTransaction>>
  >;
  refundDate?: Maybe<Scalars["String"]>;
  status?: Maybe<Scalars["String"]>;
  transaction?: Maybe<SpendTransaction>;
  updatedAt?: Maybe<Scalars["String"]>;
};

export type SpendInvoiceDiscountInput = {
  discountAmount: Scalars["Int"];
  invoiceId: Scalars["String"];
};

export type SpendInvoiceFilter = {
  by: InvoiceReportFilterEnum;
  value: Scalars["String"];
};

export type SpendInvoiceHistoryInput = {
  email: Scalars["String"];
  userId: Scalars["String"];
};

export type SpendInvoiceId = {
  __typename?: "SpendInvoiceID";
  id?: Maybe<Scalars["String"]>;
};

export type SpendInvoiceIDs = {
  __typename?: "SpendInvoiceIDs";
  ids?: Maybe<Array<Maybe<Scalars["String"]>>>;
};

export type SpendInvoiceInput = {
  amount?: InputMaybe<Scalars["Int"]>;
  balanceDue?: InputMaybe<Scalars["Int"]>;
  budgetItemId?: InputMaybe<Scalars["String"]>;
  description?: InputMaybe<Scalars["String"]>;
  dueDate?: InputMaybe<Scalars["String"]>;
  groupRosterId?: InputMaybe<Scalars["String"]>;
  isOptional?: InputMaybe<Scalars["Boolean"]>;
  note?: InputMaybe<Scalars["String"]>;
  optedIn?: InputMaybe<Scalars["Boolean"]>;
  paid?: InputMaybe<Scalars["Boolean"]>;
  paidDate?: InputMaybe<Scalars["String"]>;
  paymentScheduleInvoiceId?: InputMaybe<Scalars["String"]>;
};

export type SpendInvoicePaymentDeauthorizeInput = {
  invoiceId?: InputMaybe<Scalars["String"]>;
  message?: InputMaybe<Scalars["String"]>;
};

export type SpendInvoicePaymentMethodUpdate = {
  ids: Array<Scalars["String"]>;
  isAutoPayAuthorized?: InputMaybe<Scalars["Boolean"]>;
  paymentMethodId: Scalars["String"];
  paymentMethodSource: Scalars["String"];
};

export type SpendInvoicePendingMessage = {
  __typename?: "SpendInvoicePendingMessage";
  nextStep?: Maybe<Scalars["String"]>;
  status: Scalars["String"];
};

export type SpendInvoiceRefundInput = {
  amount: Scalars["Int"];
  invoiceId: Scalars["String"];
};

export type SpendInvoiceRefundResponse = {
  __typename?: "SpendInvoiceRefundResponse";
  invoiceId?: Maybe<Scalars["String"]>;
  spendTransactionId?: Maybe<Scalars["String"]>;
};

export type SpendInvoiceReminderInput = {
  email?: InputMaybe<Scalars["String"]>;
  failedPayment?: InputMaybe<Scalars["Boolean"]>;
  groupName?: InputMaybe<Scalars["String"]>;
  invoiceId?: InputMaybe<Scalars["String"]>;
  pastDue?: InputMaybe<Scalars["Boolean"]>;
};

export type SpendInvoiceRequestChangeInput = {
  invoiceId: Scalars["String"];
  note: Scalars["String"];
};

export type SpendInvoiceResponse = {
  __typename?: "SpendInvoiceResponse";
  count?: Maybe<Scalars["Int"]>;
  hasNext?: Maybe<Scalars["Boolean"]>;
  invoices?: Maybe<Array<Maybe<SpendInvoice>>>;
};

export type SpendIsApproverUpdateInput = {
  groupId?: InputMaybe<Scalars["String"]>;
  isEnabled: Scalars["Boolean"];
  type: Scalars["String"];
  userId: Scalars["String"];
};

export type SpendJoinedExternal = {
  __typename?: "SpendJoinedExternal";
  externalId: Scalars["String"];
  groupName: Scalars["String"];
  invoiceDescription: Scalars["String"];
  playerName: Scalars["String"];
  seasonName: Scalars["String"];
};

export type SpendJoinedPayee = {
  __typename?: "SpendJoinedPayee";
  groupName?: Maybe<Scalars["String"]>;
  organizationName?: Maybe<Scalars["String"]>;
  payeeId: Scalars["String"];
  payeeName: Scalars["String"];
  referenceId: Scalars["String"];
};

export type SpendLegacyTransaction = {
  __typename?: "SpendLegacyTransaction";
  amount?: Maybe<Scalars["Int"]>;
  attachments?: Maybe<Array<TransactionAttachment>>;
  correlationId?: Maybe<Scalars["String"]>;
  created?: Maybe<Scalars["Timestamp"]>;
  description?: Maybe<Scalars["String"]>;
  destination?: Maybe<Scalars["String"]>;
  direction?: Maybe<Scalars["String"]>;
  effective: Scalars["Timestamp"];
  externalId: Scalars["String"];
  id: Scalars["UUID"];
  metadata?: Maybe<Scalars["JSON"]>;
  note?: Maybe<TransactionNotes>;
  processor?: Maybe<Scalars["String"]>;
  reconciliation?: Maybe<SpendReconciledTransaction>;
  snapAmount?: Maybe<Scalars["Int"]>;
  source?: Maybe<Scalars["String"]>;
  status: Scalars["String"];
  transactionNote?: Maybe<Scalars["String"]>;
  type?: Maybe<Scalars["String"]>;
};

export type SpendLegacyTransactionsResponse = {
  __typename?: "SpendLegacyTransactionsResponse";
  count?: Maybe<Scalars["Int"]>;
  transactions?: Maybe<Array<Maybe<SpendLegacyTransaction>>>;
};

export type SpendMember = {
  __typename?: "SpendMember";
  child?: Maybe<SpendMemberChild>;
  email?: Maybe<Scalars["String"]>;
  firstName?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  lastName?: Maybe<Scalars["String"]>;
  phoneNumber?: Maybe<Scalars["String"]>;
  signedUp?: Maybe<Scalars["Boolean"]>;
};

export type SpendMemberChild = {
  __typename?: "SpendMemberChild";
  id?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
};

export type SpendMemo = {
  __typename?: "SpendMemo";
  creditAmount: Scalars["Int"];
  creditApplied: Scalars["Int"];
  dateToApply: Scalars["String"];
  groupName?: Maybe<Scalars["String"]>;
  id: Scalars["String"];
  isArchived: Scalars["Boolean"];
  note?: Maybe<Scalars["String"]>;
  title: Scalars["String"];
};

export type SpendMemoInvoice = {
  __typename?: "SpendMemoInvoice";
  amount: Scalars["Int"];
  balanceDue: Scalars["Int"];
  description: Scalars["String"];
  discountAmount: Scalars["Int"];
  dueDate: Scalars["String"];
  groupId: Scalars["String"];
  groupName: Scalars["String"];
  id: Scalars["String"];
  participantName: Scalars["String"];
  rosterId: Scalars["String"];
  seasonId: Scalars["String"];
  seasonName: Scalars["String"];
  status: Scalars["String"];
  userId: Scalars["String"];
};

export type SpendMemoTransaction = {
  __typename?: "SpendMemoTransaction";
  creditAmount: Scalars["Int"];
  creditApplied: Scalars["Int"];
  dateToApply: Scalars["String"];
  groupName?: Maybe<Scalars["String"]>;
  id: Scalars["String"];
  invoice?: Maybe<SpendMemoInvoice>;
  isArchived: Scalars["Boolean"];
  note?: Maybe<Scalars["String"]>;
  title: Scalars["String"];
};

export type SpendNotificationId = {
  __typename?: "SpendNotificationID";
  id?: Maybe<Scalars["String"]>;
};

export type SpendNotificationInput = {
  email: Array<Scalars["String"]>;
  groupId?: InputMaybe<Scalars["String"]>;
  isEmailCCed: Scalars["Boolean"];
  message: Scalars["String"];
  subject?: InputMaybe<Scalars["String"]>;
};

export type SpendNotificationStatus = {
  __typename?: "SpendNotificationStatus";
  status?: Maybe<Scalars["String"]>;
};

export type SpendOptInOrOutInput = {
  invoiceId: Scalars["String"];
  optedIn: Scalars["Boolean"];
};

export type SpendOrgAchCredit = {
  amount: Scalars["Int"];
  counterpartyId: Scalars["String"];
  counterpartyName: Scalars["String"];
  note: Scalars["String"];
};

export type SpendOrgAdminUpdateInput = {
  email: Scalars["String"];
  keepAsAuthorizedUser: Scalars["Boolean"];
  organizationId: Scalars["String"];
};

export type SpendOrgAdminUpdateResponse = {
  __typename?: "SpendOrgAdminUpdateResponse";
  createdAuthorizedUser?: Maybe<Scalars["Boolean"]>;
  id?: Maybe<Scalars["String"]>;
};

export type SpendOrganization = {
  __typename?: "SpendOrganization";
  accountId?: Maybe<Scalars["String"]>;
  achBaseFee?: Maybe<Scalars["Int"]>;
  achPercent?: Maybe<Scalars["Float"]>;
  budgets?: Maybe<Array<Maybe<SpendBudget>>>;
  cardBaseFee?: Maybe<Scalars["Int"]>;
  cardPercent?: Maybe<Scalars["Float"]>;
  city: Scalars["String"];
  debitCards?: Maybe<SpendOrganizationDebitCardCount>;
  email: Scalars["String"];
  externalId?: Maybe<Scalars["String"]>;
  externalTransferOutEnabled?: Maybe<Scalars["Boolean"]>;
  groupBanksEnabled?: Maybe<Scalars["Boolean"]>;
  groups?: Maybe<Array<Maybe<SpendGroup>>>;
  hasLinkedAccount?: Maybe<Scalars["Boolean"]>;
  id?: Maybe<Scalars["String"]>;
  isVerified?: Maybe<Scalars["Boolean"]>;
  legacyAccountId?: Maybe<Scalars["String"]>;
  legacyExternalId?: Maybe<Scalars["String"]>;
  legalName: Scalars["String"];
  logo?: Maybe<Scalars["String"]>;
  nickname?: Maybe<Scalars["String"]>;
  orgId?: Maybe<Scalars["String"]>;
  owner?: Maybe<User>;
  phone: Scalars["String"];
  spendBaseFee?: Maybe<Scalars["Int"]>;
  spendPercent?: Maybe<Scalars["Float"]>;
  state: Scalars["String"];
  street: Scalars["String"];
  street2?: Maybe<Scalars["String"]>;
  userId?: Maybe<Scalars["String"]>;
  website?: Maybe<Scalars["String"]>;
  zip: Scalars["String"];
};

export type SpendOrganizationCheckDepositTagsInput = {
  checkDepositId: Scalars["String"];
};

export type SpendOrganizationDebitCardCount = {
  __typename?: "SpendOrganizationDebitCardCount";
  activated?: Maybe<Scalars["Int"]>;
  assigned?: Maybe<Scalars["Int"]>;
  shipped?: Maybe<Scalars["Int"]>;
  total?: Maybe<Scalars["Int"]>;
};

export type SpendOrganizationExternalTransferInput = {
  accountId: Scalars["String"];
  amount: Scalars["Int"];
  direction: ExternalTransferDirectionEnum;
  note: Scalars["String"];
};

export type SpendOrganizationFee = {
  __typename?: "SpendOrganizationFee";
  achBaseFee?: Maybe<Scalars["Int"]>;
  achPercent?: Maybe<Scalars["Float"]>;
  cardBaseFee?: Maybe<Scalars["Int"]>;
  cardPercent?: Maybe<Scalars["Float"]>;
  spendBaseFee?: Maybe<Scalars["Int"]>;
  spendPercent?: Maybe<Scalars["Float"]>;
};

export type SpendOrganizationId = {
  __typename?: "SpendOrganizationID";
  id?: Maybe<Scalars["String"]>;
};

export type SpendOrganizationInput = {
  achBaseFee: Scalars["Int"];
  achPercent: Scalars["Float"];
  cardBaseFee: Scalars["Int"];
  cardPercent: Scalars["Float"];
  email: Scalars["String"];
  externalTransferOutEnabled?: InputMaybe<Scalars["Boolean"]>;
  groupBanksEnabled?: InputMaybe<Scalars["Boolean"]>;
  isVerified: Scalars["Boolean"];
  legalName: Scalars["String"];
  logo?: InputMaybe<Scalars["String"]>;
  nickname?: InputMaybe<Scalars["String"]>;
  spendBaseFee: Scalars["Int"];
  spendPercent: Scalars["Float"];
  website?: InputMaybe<Scalars["String"]>;
};

export type SpendOrganizationOwner = {
  __typename?: "SpendOrganizationOwner";
  firstName?: Maybe<Scalars["String"]>;
  lastName?: Maybe<Scalars["String"]>;
};

export type SpendOrganizationStatus = {
  __typename?: "SpendOrganizationStatus";
  status?: Maybe<Scalars["Boolean"]>;
};

export type SpendOrganizationsResponse = {
  __typename?: "SpendOrganizationsResponse";
  count?: Maybe<Scalars["Int"]>;
  hasNext?: Maybe<Scalars["Boolean"]>;
  organizations?: Maybe<Array<SpendOrganization>>;
};

export type SpendOrganizationsWhereInput = {
  ids?: InputMaybe<Array<Scalars["String"]>>;
  nameIncludes?: InputMaybe<Scalars["String"]>;
  /** orgId is equivalent to externalId or id from orgs-api */
  orgIds?: InputMaybe<Array<Scalars["String"]>>;
  states?: InputMaybe<Array<Scalars["String"]>>;
  status?: InputMaybe<OrganizationFilterEnum>;
};

export type SpendPaginationInput = {
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export enum SpendPastDueInterval {
  Days1_15 = "days1_15",
  Days16_30 = "days16_30",
  Days31plus = "days31plus",
}

export type SpendPastDueInvoice = {
  __typename?: "SpendPastDueInvoice";
  amount: Scalars["Int"];
  creditMemos?: Maybe<Array<Maybe<CreditMemoAmounts>>>;
  description: Scalars["String"];
  discountAmount: Scalars["Int"];
  dueDate: Scalars["String"];
  email: Scalars["String"];
  groupId: Scalars["String"];
  groupName: Scalars["String"];
  guardianStatus: Scalars["String"];
  name: Scalars["String"];
  rosterId: Scalars["String"];
  seasonId: Scalars["String"];
  seasonName: Scalars["String"];
  status: Scalars["String"];
};

export type SpendPastDueInvoicesOutput = {
  __typename?: "SpendPastDueInvoicesOutput";
  count: Scalars["Int"];
  invoices: Array<Maybe<SpendPastDueInvoice>>;
};

export type SpendPastDueSort = {
  order?: InputMaybe<SpendSortOrderEnum>;
  sortBy?: InputMaybe<PastDueSortOptions>;
};

export type SpendPayNowResponse = {
  __typename?: "SpendPayNowResponse";
  paymentId?: Maybe<Scalars["String"]>;
  status?: Maybe<Scalars["String"]>;
};

export type SpendPayeeCreateResponse = {
  __typename?: "SpendPayeeCreateResponse";
  payeeId?: Maybe<Scalars["String"]>;
};

export type SpendPayeeInput = {
  address1: Scalars["String"];
  address2?: InputMaybe<Scalars["String"]>;
  city: Scalars["String"];
  name: Scalars["String"];
  referenceId: Scalars["String"];
  state: Scalars["String"];
  zipCode: Scalars["String"];
};

export type SpendPayeeResponse = {
  __typename?: "SpendPayeeResponse";
  payees?: Maybe<Array<SpendPayees>>;
};

export type SpendPayeeUpdateInput = {
  address1: Scalars["String"];
  address2?: InputMaybe<Scalars["String"]>;
  city: Scalars["String"];
  id: Scalars["String"];
  isArchived?: InputMaybe<Scalars["Boolean"]>;
  name: Scalars["String"];
  referenceId: Scalars["String"];
  state: Scalars["String"];
  zipCode: Scalars["String"];
};

export type SpendPayees = {
  __typename?: "SpendPayees";
  address1: Scalars["String"];
  address2?: Maybe<Scalars["String"]>;
  city: Scalars["String"];
  id: Scalars["String"];
  name: Scalars["String"];
  state: Scalars["String"];
  zipCode: Scalars["String"];
};

export type SpendPaymentMethodDetach = {
  paymentMethodId: Scalars["String"];
};

export type SpendPaymentMethodDetachResponse = {
  __typename?: "SpendPaymentMethodDetachResponse";
  paymentMethodId?: Maybe<Scalars["String"]>;
  updatedInvoiceIdList?: Maybe<Array<Scalars["String"]>>;
};

export type SpendPaymentMethodInput = {
  amount?: InputMaybe<Scalars["Int"]>;
  authorizedAt?: InputMaybe<Scalars["String"]>;
  discountAmounts?: InputMaybe<Array<SpendInvoiceDiscountInput>>;
  hasApprovedAgreement?: InputMaybe<Scalars["Boolean"]>;
  inviteId?: InputMaybe<Scalars["String"]>;
  invoiceIds?: InputMaybe<Array<Scalars["String"]>>;
  isAutoPayAuthorized?: InputMaybe<Scalars["Boolean"]>;
  paymentMethodId?: InputMaybe<Scalars["String"]>;
  paymentMethodSource?: InputMaybe<Scalars["String"]>;
  paymentMethodTiming?: InputMaybe<Scalars["String"]>;
};

export type SpendPaymentMethodResponse = {
  __typename?: "SpendPaymentMethodResponse";
  groupRosterSetting?: Maybe<SpendGroupRosterSettingsResponse>;
  payment?: Maybe<SpendPayNowResponse>;
  updatedInvoiceIds: Array<Maybe<Scalars["String"]>>;
};

export type SpendPaymentSchedule = {
  __typename?: "SpendPaymentSchedule";
  amountDue?: Maybe<Scalars["Int"]>;
  budgetItem?: Maybe<SpendBudget>;
  budgetItemId?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  dueDate?: Maybe<Scalars["String"]>;
  group?: Maybe<SpendGroup>;
  groupId?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  isArchived?: Maybe<Scalars["Boolean"]>;
  isOptional?: Maybe<Scalars["Boolean"]>;
  lastPublishedState?: Maybe<Scalars["String"]>;
  note?: Maybe<Scalars["String"]>;
  season?: Maybe<SpendSeason>;
  seasonId?: Maybe<Scalars["String"]>;
  status?: Maybe<Scalars["String"]>;
};

export type SpendPaymentScheduleBySeasonInput = {
  seasonId?: InputMaybe<Scalars["String"]>;
  status?: InputMaybe<Scalars["String"]>;
};

export type SpendPaymentScheduleId = {
  __typename?: "SpendPaymentScheduleID";
  id?: Maybe<Scalars["String"]>;
};

export type SpendPaymentScheduleInput = {
  amountDue?: InputMaybe<Scalars["Int"]>;
  budgetItemId?: InputMaybe<Scalars["String"]>;
  description?: InputMaybe<Scalars["String"]>;
  dueDate?: InputMaybe<Scalars["String"]>;
  groupId?: InputMaybe<Scalars["String"]>;
  isOptional?: InputMaybe<Scalars["Boolean"]>;
  note?: InputMaybe<Scalars["String"]>;
  seasonId?: InputMaybe<Scalars["String"]>;
  status?: InputMaybe<Scalars["String"]>;
};

export type SpendPendingInvite = {
  __typename?: "SpendPendingInvite";
  expiresAt: Scalars["String"];
  groupId?: Maybe<Scalars["String"]>;
  groupName?: Maybe<Scalars["String"]>;
  id: Scalars["String"];
  isDismissed: Scalars["Boolean"];
  organizationId: Scalars["String"];
  organizationName: Scalars["String"];
  rosterName?: Maybe<Scalars["String"]>;
  seasonId?: Maybe<Scalars["String"]>;
  seasonName?: Maybe<Scalars["String"]>;
  status?: Maybe<Scalars["String"]>;
  type: Scalars["String"];
};

export type SpendReconcileTransactionId = {
  __typename?: "SpendReconcileTransactionID";
  id?: Maybe<Scalars["String"]>;
};

export type SpendReconciledBudget = {
  __typename?: "SpendReconciledBudget";
  amount: Scalars["Int"];
  budgetId: Scalars["String"];
  budgetName: Scalars["String"];
  categoryId: Scalars["String"];
  categoryName: Scalars["String"];
  description: Scalars["String"];
  groupId?: Maybe<Scalars["String"]>;
  groupName: Scalars["String"];
  invoiceId?: Maybe<Scalars["String"]>;
  seasonId: Scalars["String"];
  seasonName: Scalars["String"];
  type: Scalars["String"];
};

export type SpendReconciledBudgetTransaction = {
  __typename?: "SpendReconciledBudgetTransaction";
  amount?: Maybe<Scalars["Int"]>;
  budgetItemId?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  reconciledTransaction?: Maybe<SpendReconciledTransaction>;
  transactionId?: Maybe<Scalars["String"]>;
};

export type SpendReconciledInvoiceTransaction = {
  __typename?: "SpendReconciledInvoiceTransaction";
  amount?: Maybe<Scalars["Int"]>;
  creditMemos?: Maybe<Array<Maybe<SpendMemo>>>;
  id?: Maybe<Scalars["String"]>;
  invoiceId?: Maybe<Scalars["String"]>;
  note?: Maybe<Scalars["String"]>;
  reconciledTransaction?: Maybe<SpendReconciledTransaction>;
  rosterId?: Maybe<Scalars["String"]>;
  rosterName?: Maybe<Scalars["String"]>;
  transactionId?: Maybe<Scalars["String"]>;
};

export type SpendReconciledTotals = {
  __typename?: "SpendReconciledTotals";
  budgets?: Maybe<Array<SpendBudgetReconciledTotal>>;
  id: Scalars["String"];
  name: Scalars["String"];
  orgId: Scalars["String"];
  type: Scalars["String"];
};

export type SpendReconciledTransaction = {
  __typename?: "SpendReconciledTransaction";
  amount?: Maybe<Scalars["Int"]>;
  budgetTransactions?: Maybe<Array<Maybe<SpendReconciledBudgetTransaction>>>;
  createdAt?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  invoiceTransactions?: Maybe<Array<Maybe<SpendReconciledInvoiceTransaction>>>;
  paymentId?: Maybe<Scalars["String"]>;
  reconciledTo?: Maybe<Array<Maybe<SpendReconciledBudget>>>;
  transaction?: Maybe<SpendBankTransaction>;
  type?: Maybe<Scalars["String"]>;
  updatedAt?: Maybe<Scalars["String"]>;
};

export type SpendRemoveSeasonMemberInput = {
  groupRosterIds: Array<InputMaybe<Scalars["String"]>>;
};

export type SpendRemoveSeasonMemberResponse = {
  __typename?: "SpendRemoveSeasonMemberResponse";
  success: Scalars["Boolean"];
};

export type SpendRole = {
  __typename?: "SpendRole";
  groupId?: Maybe<Scalars["String"]>;
  groupName?: Maybe<Scalars["String"]>;
  id: Scalars["String"];
  isApprover?: Maybe<Scalars["Boolean"]>;
  isArchived?: Maybe<Scalars["Boolean"]>;
  isNotSignedUp?: Maybe<Scalars["Boolean"]>;
  name: Scalars["String"];
  organizationId?: Maybe<Scalars["String"]>;
  organizationName?: Maybe<Scalars["String"]>;
  permissions: Array<Maybe<Scalars["String"]>>;
  seasonId?: Maybe<Scalars["String"]>;
};

export type SpendRoleId = {
  __typename?: "SpendRoleID";
  id?: Maybe<Scalars["String"]>;
};

export enum SpendRoleNameEnum {
  GroupObserver = "group_observer",
  GroupStaff = "group_staff",
  Guardian = "guardian",
  ProgramObserver = "program_observer",
  ProgramStaff = "program_staff",
}

export type SpendRoleResponse = {
  __typename?: "SpendRoleResponse";
  count?: Maybe<Scalars["Int"]>;
  roles?: Maybe<Array<Maybe<SpendRole>>>;
};

export type SpendRoster = {
  __typename?: "SpendRoster";
  email?: Maybe<Scalars["String"]>;
  groupRosters?: Maybe<Array<Maybe<SpendGroupRoster>>>;
  id?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  total?: Maybe<SpendTransactionTotals>;
};

export type SpendRosterResponse = {
  __typename?: "SpendRosterResponse";
  count?: Maybe<Scalars["Int"]>;
  rosters?: Maybe<Array<Maybe<SpendRoster>>>;
};

export type SpendRosterUpdate = {
  __typename?: "SpendRosterUpdate";
  id?: Maybe<Scalars["String"]>;
  invitesUpdated?: Maybe<Array<Scalars["String"]>>;
};

export type SpendRosterUserInput = {
  email: Scalars["String"];
  name: Scalars["String"];
};

export type SpendRosterUserUpdate = {
  email?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
  rosterId: Scalars["String"];
};

export type SpendSeason = {
  __typename?: "SpendSeason";
  budgets?: Maybe<Array<Maybe<SpendBudget>>>;
  endDateAt?: Maybe<Scalars["String"]>;
  groupId?: Maybe<Scalars["String"]>;
  groupRoster?: Maybe<Array<Maybe<SpendGroupRoster>>>;
  id?: Maybe<Scalars["String"]>;
  isActive?: Maybe<Scalars["Boolean"]>;
  isBudgetShared?: Maybe<Scalars["Boolean"]>;
  isLinkEnabled?: Maybe<Scalars["Boolean"]>;
  name?: Maybe<Scalars["String"]>;
  paymentScheduleInvoices?: Maybe<Array<Maybe<SpendPaymentSchedule>>>;
  paymentScheduleStatus?: Maybe<Scalars["String"]>;
  playerCount?: Maybe<Scalars["Int"]>;
  startDateAt?: Maybe<Scalars["String"]>;
  transactionTotals?: Maybe<SpendTransactionTotals>;
};

export type SpendSeasonId = {
  __typename?: "SpendSeasonID";
  id?: Maybe<Scalars["String"]>;
};

export type SpendSeasonInput = {
  copyBudgetItems?: InputMaybe<Scalars["Boolean"]>;
  copyPaymentSchedule?: InputMaybe<Scalars["Boolean"]>;
  endDateAt?: InputMaybe<Scalars["String"]>;
  groupId?: InputMaybe<Scalars["String"]>;
  isBudgetShared?: InputMaybe<Scalars["Boolean"]>;
  isLinkEnabled?: InputMaybe<Scalars["Boolean"]>;
  name?: InputMaybe<Scalars["String"]>;
  startDateAt?: InputMaybe<Scalars["String"]>;
};

export type SpendSession = {
  __typename?: "SpendSession";
  expiresAt?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  inviteId?: Maybe<Scalars["String"]>;
  isDismissed?: Maybe<Scalars["Boolean"]>;
  message?: Maybe<Scalars["String"]>;
  newInvite?: Maybe<Scalars["Boolean"]>;
  pendingInvites?: Maybe<Array<SpendPendingInvite>>;
  role?: Maybe<SpendRole>;
  status?: Maybe<Scalars["String"]>;
  url?: Maybe<Scalars["String"]>;
  userId?: Maybe<Scalars["String"]>;
};

export type SpendSessionId = {
  __typename?: "SpendSessionID";
  id?: Maybe<Scalars["String"]>;
};

export type SpendSettings = {
  __typename?: "SpendSettings";
  debitCardApproval?: Maybe<Scalars["Boolean"]>;
  /** @deprecated duplicate of SpendOrganization.groupBanksEnabled */
  enableGroupBanks?: Maybe<Scalars["Boolean"]>;
  enableProgramAgreements?: Maybe<Scalars["Boolean"]>;
  externalTransferApproval?: Maybe<Scalars["Boolean"]>;
  externalTransferLimit?: Maybe<Scalars["Int"]>;
  internalTransferApproval?: Maybe<Scalars["Boolean"]>;
  internalTransferLimit?: Maybe<Scalars["Int"]>;
  notifyBankActivityAdmins?: Maybe<Scalars["Boolean"]>;
  notifyDueFrequencies?: Maybe<Array<Scalars["String"]>>;
  notifyFailedAchAdmins?: Maybe<Scalars["Boolean"]>;
  notifyPastDueAdmins?: Maybe<Scalars["Boolean"]>;
  notifyPastDueNonUsers?: Maybe<Scalars["Boolean"]>;
  notifyUpcomingNonUsers?: Maybe<Scalars["Boolean"]>;
  /** @deprecated replaced by notifyDueFrequencies */
  pastDueFrequency?: Maybe<Scalars["Int"]>;
  sendCheckApproval?: Maybe<Scalars["Boolean"]>;
  sendCheckLimit?: Maybe<Scalars["Int"]>;
  signUpAgreement?: Maybe<SignupAgreement>;
  signUpLink?: Maybe<Scalars["String"]>;
};

export type SpendSettingsInput = {
  debitCardApproval?: InputMaybe<Scalars["Boolean"]>;
  enableGroupBanks?: InputMaybe<Scalars["Boolean"]>;
  enableProgramAgreements?: InputMaybe<Scalars["Boolean"]>;
  externalTransferApproval?: InputMaybe<Scalars["Boolean"]>;
  externalTransferLimit?: InputMaybe<Scalars["Int"]>;
  internalTransferApproval?: InputMaybe<Scalars["Boolean"]>;
  internalTransferLimit?: InputMaybe<Scalars["Int"]>;
  notifyBankActivityAdmins?: InputMaybe<Scalars["Boolean"]>;
  notifyDueFrequencies?: InputMaybe<Array<Scalars["String"]>>;
  notifyFailedAchAdmins?: InputMaybe<Scalars["Boolean"]>;
  notifyPastDueAdmins?: InputMaybe<Scalars["Boolean"]>;
  notifyPastDueNonUsers?: InputMaybe<Scalars["Boolean"]>;
  notifyUpcomingNonUsers?: InputMaybe<Scalars["Boolean"]>;
  /** @deprecated: pastDueFrequency is replaced with notifyDueFrequencies */
  pastDueFrequency?: InputMaybe<Scalars["Int"]>;
  sendCheckApproval?: InputMaybe<Scalars["Boolean"]>;
  sendCheckLimit?: InputMaybe<Scalars["Int"]>;
};

export type SpendSignUpInput = {
  groupId: Scalars["String"];
  orgId: Scalars["String"];
  participantName: Scalars["String"];
  seasonId: Scalars["String"];
};

export type SpendSignUpResponse = {
  __typename?: "SpendSignUpResponse";
  id?: Maybe<Scalars["String"]>;
};

export type SpendSignupAgreementInput = {
  content: Scalars["String"];
  name: Scalars["String"];
};

export type SpendSignupFormResponse = {
  __typename?: "SpendSignupFormResponse";
  id?: Maybe<Scalars["String"]>;
  status?: Maybe<Scalars["String"]>;
  type?: Maybe<Scalars["String"]>;
  url?: Maybe<Scalars["String"]>;
};

export type SpendSortInput = {
  field: Scalars["String"];
  order?: InputMaybe<SpendSortOrderEnum>;
};

export enum SpendSortOrderEnum {
  Asc = "ASC",
  Desc = "DESC",
}

export type SpendTransaction = {
  __typename?: "SpendTransaction";
  externalId?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  invoiceId?: Maybe<Scalars["String"]>;
  source?: Maybe<Scalars["String"]>;
};

export type SpendTransactionAttachmentId = {
  __typename?: "SpendTransactionAttachmentID";
  id?: Maybe<Scalars["String"]>;
};

export type SpendTransactionAttachmentInput = {
  /** Base-64 encoded file */
  content: Scalars["String"];
  description?: InputMaybe<Scalars["String"]>;
  /** Filename with extension */
  name: Scalars["String"];
  paymentId: Scalars["String"];
};

export type SpendTransactionBudgetUnreconcileInput = {
  reconciledBudgetTransactionIds: Array<Scalars["String"]>;
  reconciledTransactionId: Scalars["String"];
};

export type SpendTransactionFilter = {
  by: TransactionReportFilterEnum;
  value: Scalars["String"];
};

export type SpendTransactionInput = {
  authorizedAt?: InputMaybe<Scalars["String"]>;
  direction?: InputMaybe<Scalars["String"]>;
  discountAmounts?: InputMaybe<Array<SpendInvoiceDiscountInput>>;
  externalId?: InputMaybe<Scalars["String"]>;
  externalPaymentId?: InputMaybe<Scalars["String"]>;
  invoiceIdList?: InputMaybe<Array<Scalars["String"]>>;
  source?: InputMaybe<Scalars["String"]>;
};

export type SpendTransactionInvoiceUnreconcileInput = {
  reconciledInvoiceTransactionIds: Array<Scalars["String"]>;
  reconciledTransactionId: Scalars["String"];
};

export type SpendTransactionNoteId = {
  __typename?: "SpendTransactionNoteID";
  id?: Maybe<Scalars["String"]>;
};

export type SpendTransactionReconcileInput = {
  budgets?: InputMaybe<Array<ReconcileBudget>>;
  invoices?: InputMaybe<Array<ReconcileInvoice>>;
  ledgerTransactionAmount: Scalars["Int"];
  ledgerTransactionId: Scalars["String"];
  reverse?: InputMaybe<Scalars["Boolean"]>;
};

export type SpendTransactionReconcileV2Input = {
  budgets?: InputMaybe<Array<ReconcileBudget>>;
  invoices?: InputMaybe<Array<ReconcileInvoiceWithBudget>>;
  ledgerTransactionAmount: Scalars["Int"];
  ledgerTransactionId: Scalars["String"];
  reverse?: InputMaybe<Scalars["Boolean"]>;
};

export type SpendTransactionResponse = {
  __typename?: "SpendTransactionResponse";
  count?: Maybe<Scalars["Int"]>;
  transactions?: Maybe<Array<Maybe<SpendTransaction>>>;
};

export type SpendTransactionTags = {
  __typename?: "SpendTransactionTags";
  spendAutoPay?: Maybe<Scalars["Boolean"]>;
  spendDestinationId?: Maybe<Scalars["String"]>;
  spendExternalId?: Maybe<Scalars["String"]>;
  spendGroupRosterId?: Maybe<Scalars["String"]>;
  /** @deprecated No longer used due to being too long */
  spendInvoiceIds?: Maybe<Scalars["String"]>;
  spendOriginalPaymentId?: Maybe<Scalars["String"]>;
  spendPaymentType?: Maybe<Scalars["String"]>;
  spendSourceId?: Maybe<Scalars["String"]>;
  spendUserId?: Maybe<Scalars["String"]>;
};

export type SpendTransactionTotals = {
  __typename?: "SpendTransactionTotals";
  credited?: Maybe<Scalars["Int"]>;
  dueToday?: Maybe<Scalars["Int"]>;
  paid?: Maybe<Scalars["Int"]>;
  pastDue?: Maybe<Scalars["Int"]>;
  pastDueDays?: Maybe<Scalars["Int"]>;
  processing?: Maybe<Scalars["Int"]>;
  statuses?: Maybe<Array<Maybe<Scalars["String"]>>>;
  upcoming?: Maybe<Scalars["Int"]>;
};

export type SpendTransactionWhereInput = {
  dateAfter?: InputMaybe<Scalars["String"]>;
  dateBefore?: InputMaybe<Scalars["String"]>;
  direction?: InputMaybe<ExternalTransferDirectionEnum>;
  groupIdOrgId: Scalars["String"];
  hasAttachment?: InputMaybe<Scalars["Boolean"]>;
  method?: InputMaybe<Array<Scalars["String"]>>;
  nameIncludes?: InputMaybe<Scalars["String"]>;
  reconciled?: InputMaybe<Scalars["Boolean"]>;
  status?: InputMaybe<Array<Scalars["String"]>>;
  type?: InputMaybe<Array<Scalars["String"]>>;
};

export type SpendTransactionsIdList = {
  __typename?: "SpendTransactionsIdList";
  transactionIdList?: Maybe<Array<Maybe<Scalars["String"]>>>;
};

export type SpendTranscationNoteInput = {
  content: Scalars["String"];
  paymentId: Scalars["String"];
};

export type SpendUnitApplicationResponse = {
  __typename?: "SpendUnitApplicationResponse";
  url?: Maybe<Scalars["String"]>;
};

export type SpendUnreconciledSummary = {
  __typename?: "SpendUnreconciledSummary";
  credits?: Maybe<TransactionTotalCount>;
  debits?: Maybe<TransactionTotalCount>;
};

export type SpendUpdateResponse = {
  __typename?: "SpendUpdateResponse";
  success?: Maybe<Scalars["Boolean"]>;
};

export type SpendUpsertCategoryInput = {
  id?: InputMaybe<Scalars["String"]>;
  isDefault: Scalars["Boolean"];
  isHidden: Scalars["Boolean"];
  name: Scalars["String"];
  type: CategoryTypeEnum;
};

export type SpendUserEmailUpdateInput = {
  newEmail: Scalars["String"];
  oldEmail: Scalars["String"];
};

export type SpendUserEmailUpdateResponse = {
  __typename?: "SpendUserEmailUpdateResponse";
  invitesUpdatedCount?: Maybe<Scalars["Int"]>;
  rostersUpdatedCount?: Maybe<Scalars["Int"]>;
};

export type SpendUserId = {
  __typename?: "SpendUserID";
  id?: Maybe<Scalars["String"]>;
};

export type SpendUserNotificationSetting = {
  __typename?: "SpendUserNotificationSetting";
  copyPastDueInvoices: Scalars["Boolean"];
  groupId?: Maybe<Scalars["String"]>;
  id: Scalars["String"];
  notifyOnBankActivity: Scalars["Boolean"];
  notifyOnFailedCardPayments: Scalars["Boolean"];
  organizationId?: Maybe<Scalars["String"]>;
  roleName?: Maybe<Scalars["String"]>;
  userRoleId: Scalars["String"];
};

export type SpendUserNotificationSettingId = {
  __typename?: "SpendUserNotificationSettingID";
  id?: Maybe<Scalars["String"]>;
};

export type SpendUserNotificationSettingInput = {
  copyPastDueInvoices?: InputMaybe<Scalars["Boolean"]>;
  id: Scalars["String"];
  notifyOnBankActivity?: InputMaybe<Scalars["Boolean"]>;
  notifyOnFailedCardPayments?: InputMaybe<Scalars["Boolean"]>;
};

export type SpendUserNotificationSettingResponse = {
  __typename?: "SpendUserNotificationSettingResponse";
  notificationSetting?: Maybe<SpendUserNotificationSetting>;
};

export type SpendUserRoleId = {
  __typename?: "SpendUserRoleID";
  id?: Maybe<Scalars["String"]>;
};

export type SpendUserSignupInput = {
  groupId: Scalars["String"];
  inviteId?: InputMaybe<Scalars["String"]>;
  seasonId: Scalars["String"];
  status: Scalars["String"];
};

export type SpendVerificationTokenResponse = {
  __typename?: "SpendVerificationTokenResponse";
  verificationToken?: Maybe<Scalars["String"]>;
};

export type SpendWireLimits = {
  __typename?: "SpendWireLimits";
  dailyTransfer: Scalars["Int"];
  dailyTransferSoft: Scalars["Int"];
  monthlyTransfer: Scalars["Int"];
  monthlyTransferSoft: Scalars["Int"];
};

export type SpendWireLimitsData = {
  __typename?: "SpendWireLimitsData";
  limits?: Maybe<SpendWireLimits>;
};

export enum State {
  Alabama = "ALABAMA",
  Alaska = "ALASKA",
  Arizona = "ARIZONA",
  Arkansas = "ARKANSAS",
  California = "CALIFORNIA",
  Colorado = "COLORADO",
  Connecticut = "CONNECTICUT",
  Delaware = "DELAWARE",
  DistrictOfColumbia = "DISTRICT_OF_COLUMBIA",
  Florida = "FLORIDA",
  Georgia = "GEORGIA",
  Hawaii = "HAWAII",
  Idaho = "IDAHO",
  Illinois = "ILLINOIS",
  Indiana = "INDIANA",
  Iowa = "IOWA",
  Kansas = "KANSAS",
  Kentucky = "KENTUCKY",
  Louisiana = "LOUISIANA",
  Maine = "MAINE",
  Maryland = "MARYLAND",
  Massachusetts = "MASSACHUSETTS",
  Michigan = "MICHIGAN",
  Minnesota = "MINNESOTA",
  Mississippi = "MISSISSIPPI",
  Missouri = "MISSOURI",
  Montana = "MONTANA",
  Nebraska = "NEBRASKA",
  Nevada = "NEVADA",
  NewHampshire = "NEW_HAMPSHIRE",
  NewJersey = "NEW_JERSEY",
  NewMexico = "NEW_MEXICO",
  NewYork = "NEW_YORK",
  NorthCarolina = "NORTH_CAROLINA",
  NorthDakota = "NORTH_DAKOTA",
  Ohio = "OHIO",
  Oklahoma = "OKLAHOMA",
  Oregon = "OREGON",
  Pennsylvania = "PENNSYLVANIA",
  RhodeIsland = "RHODE_ISLAND",
  SouthCarolina = "SOUTH_CAROLINA",
  SouthDakota = "SOUTH_DAKOTA",
  Tennessee = "TENNESSEE",
  Texas = "TEXAS",
  Utah = "UTAH",
  Vermont = "VERMONT",
  Virginia = "VIRGINIA",
  Washington = "WASHINGTON",
  WestVirginia = "WEST_VIRGINIA",
  Wisconsin = "WISCONSIN",
  Wyoming = "WYOMING",
}

export enum StateCode {
  Ak = "AK",
  Al = "AL",
  Ar = "AR",
  As = "AS",
  Az = "AZ",
  Ca = "CA",
  Co = "CO",
  Ct = "CT",
  Dc = "DC",
  De = "DE",
  Fl = "FL",
  Ga = "GA",
  Gu = "GU",
  Hi = "HI",
  Ia = "IA",
  Id = "ID",
  Il = "IL",
  In = "IN",
  Ks = "KS",
  Ky = "KY",
  La = "LA",
  Ma = "MA",
  Md = "MD",
  Me = "ME",
  Mi = "MI",
  Mn = "MN",
  Mo = "MO",
  Mp = "MP",
  Ms = "MS",
  Mt = "MT",
  Nc = "NC",
  Nd = "ND",
  Ne = "NE",
  Nh = "NH",
  Nj = "NJ",
  Nm = "NM",
  Nv = "NV",
  Ny = "NY",
  Oh = "OH",
  Ok = "OK",
  Or = "OR",
  Pa = "PA",
  Pr = "PR",
  Ri = "RI",
  Sc = "SC",
  Sd = "SD",
  Tn = "TN",
  Tx = "TX",
  Um = "UM",
  Ut = "UT",
  Va = "VA",
  Vi = "VI",
  Vt = "VT",
  Wa = "WA",
  Wi = "WI",
  Wv = "WV",
  Wy = "WY",
}

export enum Status {
  Canceled = "Canceled",
  Clearing = "Clearing",
  Pending = "PENDING",
  PendingReview = "PendingReview",
  Rejected = "Rejected",
  Returned = "Returned",
  Settled = "SETTLED",
}

export type Store = {
  __typename?: "Store";
  code?: Maybe<Scalars["String"]>;
  domain?: Maybe<Scalars["String"]>;
  enabled?: Maybe<Scalars["Boolean"]>;
  id: Scalars["ID"];
  name?: Maybe<Scalars["String"]>;
};

export type StoreBaseFilterInput = {
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  searchBy?: InputMaybe<Scalars["String"]>;
  searchValue?: InputMaybe<Scalars["ID"]>;
};

export type StoreBaseSellerUnionType = OnException | StoreScopeBestSeller;

export type StoreBestSeller = {
  __typename?: "StoreBestSeller";
  productId?: Maybe<Scalars["Int"]>;
  productImage?: Maybe<Scalars["String"]>;
  productName?: Maybe<Scalars["String"]>;
  productPrice?: Maybe<Scalars["Float"]>;
  productQuantity?: Maybe<Scalars["Int"]>;
  productType?: Maybe<Scalars["String"]>;
};

export type StoreBrands = {
  __typename?: "StoreBrands";
  brands?: Maybe<Array<Maybe<Brand>>>;
};

export type StoreBrandsUnionType = OnException | StoreBrands;

export type StoreBuildRequest = {
  __typename?: "StoreBuildRequest";
  email?: Maybe<Scalars["String"]>;
  referenceTicket?: Maybe<Scalars["Int"]>;
  storeName?: Maybe<Scalars["String"]>;
  storeRequest?: Maybe<Scalars["Boolean"]>;
  userSsoId?: Maybe<Scalars["String"]>;
};

export type StoreBuildRequestUnionType = OnException | StoreBuildRequest;

export type StoreBuildRequester = {
  email?: InputMaybe<Scalars["String"]>;
  name: Scalars["String"];
};

export type StoreByCampaignIdsInput = {
  all?: InputMaybe<Scalars["Boolean"]>;
  campaignIds: Array<Scalars["Int"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Scalars["String"]>;
  orderBySort?: InputMaybe<Scalars["String"]>;
  scopeIds?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
};

export type StoreByGlEmailInput = {
  all?: InputMaybe<Scalars["Boolean"]>;
  email: Scalars["String"];
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Scalars["String"]>;
  orderBySort?: InputMaybe<Scalars["String"]>;
  scopeIds?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
};

export type StoreCampaign = {
  __typename?: "StoreCampaign";
  accountManagerId?: Maybe<Scalars["Int"]>;
  commissionEligibleSalesrepId?: Maybe<Scalars["Int"]>;
  endDate?: Maybe<Scalars["String"]>;
  entityId?: Maybe<Scalars["Int"]>;
  fullAddress?: Maybe<Scalars["String"]>;
  gool?: Maybe<Scalars["Int"]>;
  groupLeaderEmail?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["Int"]>;
  initialGoalCents?: Maybe<Scalars["Int"]>;
  joinCode?: Maybe<Scalars["Int"]>;
  name?: Maybe<Scalars["String"]>;
  originalSalesrepId?: Maybe<Scalars["Int"]>;
  salesrepId?: Maybe<Scalars["Int"]>;
  settlementConfirmationStatus?: Maybe<Scalars["String"]>;
  settlementMethod?: Maybe<Scalars["String"]>;
  settlementStatus?: Maybe<Scalars["String"]>;
  slug?: Maybe<Scalars["String"]>;
  startDate?: Maybe<Scalars["String"]>;
  status?: Maybe<Scalars["String"]>;
  teamSize?: Maybe<Scalars["Int"]>;
  totalRaisedCents?: Maybe<Scalars["Int"]>;
  userDirectoryId?: Maybe<Scalars["String"]>;
};

export type StoreCampaignPayableInfoInput = {
  campaignId: Scalars["Int"];
  primary?: InputMaybe<Scalars["Boolean"]>;
};

export type StoreCampaignPayableInfoUnionType = OnException | PayableInfo;

export type StoreColors = {
  __typename?: "StoreColors";
  colors?: Maybe<Array<Maybe<ScopeProductColor>>>;
};

export type StoreColorsUnionType = OnException | StoreColors;

export type StoreCreatePaymentIntent = {
  amount: Scalars["Float"];
  email: Scalars["String"];
  paymentMethod?: InputMaybe<Scalars["String"]>;
  userSsoId: Scalars["String"];
};

export type StoreCustomerInput = {
  email: Scalars["String"];
  firstName: Scalars["String"];
  lastName: Scalars["String"];
  phoneNumber?: InputMaybe<Scalars["String"]>;
};

export type StoreEditInfo = {
  __typename?: "StoreEditInfo";
  code?: Maybe<Scalars["String"]>;
  scopeId?: Maybe<Scalars["Int"]>;
};

export type StoreEditInfoInput = {
  activityType?: InputMaybe<Scalars["String"]>;
  brands?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  productColors?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  scopeId: Scalars["Int"];
  storeCode: Scalars["String"];
  storeName?: InputMaybe<Scalars["String"]>;
  themeColor?: InputMaybe<Scalars["String"]>;
};

export type StoreEditInfoUnionType = OnException | StoreEditInfo;

export type StoreFilterUnionType = OnException | StoresFilter;

export type StoreGlInput = {
  email: Scalars["String"];
  firstName: Scalars["String"];
  lastName: Scalars["String"];
  phoneNumber?: InputMaybe<Scalars["String"]>;
};

export type StoreManagerUpdatePoints = {
  managerEmail: Scalars["String"];
  managerId: Scalars["Int"];
  points: Scalars["Float"];
  scopeId: Scalars["Int"];
};

export type StoreManagerUpdatePointsUnionType =
  | MagentoStoreManager
  | OnException;

export type StoreOrderItemImage = {
  __typename?: "StoreOrderItemImage";
  imageUrl?: Maybe<Scalars["String"]>;
  itemId?: Maybe<Scalars["BigInt"]>;
  selectedLogo?: Maybe<Scalars["String"]>;
};

export type StoreOrderItems = {
  __typename?: "StoreOrderItems";
  items?: Maybe<Array<Maybe<StoreOrderItemImage>>>;
};

export type StoreOrderItemsUnionType = OnException | StoreOrderItems;

export type StoreOrderSalesGraphs = {
  __typename?: "StoreOrderSalesGraphs";
  graphs?: Maybe<Array<Maybe<StoreOrderSalesGraph>>>;
};

export type StoreParticipant = {
  __typename?: "StoreParticipant";
  email?: Maybe<Scalars["String"]>;
  firstName?: Maybe<Scalars["String"]>;
  lastName?: Maybe<Scalars["String"]>;
  phoneNumber?: Maybe<Scalars["String"]>;
};

export type StoreParticipantInput = {
  email: Scalars["String"];
  firstName: Scalars["String"];
  lastName: Scalars["String"];
  phoneNumber?: InputMaybe<Scalars["String"]>;
};

export type StorePayableFilter = {
  __typename?: "StorePayableFilter";
  pagination?: Maybe<SdPagination>;
  payables?: Maybe<Array<Maybe<PayableInfo>>>;
};

export type StorePayableFilterUnionType = OnException | StorePayableFilter;

export type StorePayableInfoUnionType = OnException | PayableInfo;

export type StorePaymantIntentUnionType = OnException | StorePaymentIntent;

export type StorePaymentIntent = {
  __typename?: "StorePaymentIntent";
  clientSecret?: Maybe<Scalars["String"]>;
  email?: Maybe<Scalars["String"]>;
  userSsoId?: Maybe<Scalars["String"]>;
};

export type StorePointEarnedGraphInput = {
  interval?: InputMaybe<Scalars["Int"]>;
  managerId: Scalars["Int"];
  scopeId: Scalars["Int"];
};

export type StorePointsWithdrawalFilter = {
  __typename?: "StorePointsWithdrawalFilter";
  pagination?: Maybe<SdPagination>;
  withdrawalRequests?: Maybe<Array<Maybe<StorePointsWithdrawalRequest>>>;
};

export type StorePointsWithdrawalFilterUnionType =
  | OnException
  | StorePointsWithdrawalFilter;

export type StorePointsWithdrawalRequest = {
  __typename?: "StorePointsWithdrawalRequest";
  amount?: Maybe<Scalars["Int"]>;
  createdAt?: Maybe<Scalars["String"]>;
  expactedWithdrawalDate?: Maybe<Scalars["String"]>;
  payableInfo?: Maybe<PayableInfo>;
  pointsType?: Maybe<Scalars["String"]>;
  requesterInfo?: Maybe<StorePointsWithdrawalRequester>;
  scopeInfo?: Maybe<StoresWithdrawalPointsScope>;
  ticketId?: Maybe<Scalars["Int"]>;
  updatedAt?: Maybe<Scalars["String"]>;
};

export type StorePointsWithdrawalRequestInput = {
  changeCampaignPayableDetails: Scalars["Boolean"];
  payableDetails: StoreWithdrawalPointsPayableInput;
  payableType: Scalars["String"];
  pointsType: Scalars["String"];
  requester: StorePointsWithdrawalRequesterInput;
  scope: StoresWithdrawalPointsScopeInput;
  taxDocument?: InputMaybe<Scalars["String"]>;
  withdrawalPointsAmount: Scalars["Int"];
};

export type StorePointsWithdrawalRequestUnionType =
  | OnException
  | StorePointsWithdrawalRequest;

export type StorePointsWithdrawalRequester = {
  __typename?: "StorePointsWithdrawalRequester";
  email?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  userSsoId?: Maybe<Scalars["String"]>;
};

export type StorePointsWithdrawalRequesterInput = {
  email: Scalars["String"];
  name: Scalars["String"];
  userSsoId: Scalars["String"];
};

export type StoreProfitPercentageUpdated = {
  __typename?: "StoreProfitPercentageUpdated";
  updated_at?: Maybe<Scalars["String"]>;
  updated_product_price_percentage?: Maybe<Scalars["String"]>;
};

export type StoreReceiverData = {
  __typename?: "StoreReceiverData";
  email?: Maybe<Scalars["String"]>;
  points?: Maybe<Scalars["Float"]>;
  receiverId?: Maybe<Scalars["BigInt"]>;
};

export type StoreSaveTransaction = {
  amount: Scalars["Float"];
  email: Scalars["String"];
  points: Scalars["Float"];
  scopeId: Scalars["Int"];
  transactionInfo: Scalars["String"];
  userSsoId: Scalars["String"];
};

export type StoreScopeBestSeller = {
  __typename?: "StoreScopeBestSeller";
  bestSellers?: Maybe<Array<Maybe<StoreBestSeller>>>;
};

export type StoreSender = {
  email: Scalars["String"];
  scopeId: Scalars["Int"];
  senderId: Scalars["Int"];
};

export type StoreSenderData = {
  __typename?: "StoreSenderData";
  email?: Maybe<Scalars["String"]>;
  points?: Maybe<Scalars["Float"]>;
  senderId?: Maybe<Scalars["BigInt"]>;
};

export type StoreStatusInput = {
  reasonWhy?: InputMaybe<StoreStatusReasonWhy>;
  scopeId: Scalars["Int"];
  status: Scalars["Boolean"];
};

export type StoreStatusReasonWhy = {
  comment?: InputMaybe<Scalars["String"]>;
  commentOption?: InputMaybe<Scalars["String"]>;
};

export type StoreSubscribedUser = {
  __typename?: "StoreSubscribedUser";
  isSubscribed?: Maybe<Scalars["Boolean"]>;
};

export type StoreSubscribedUserUnionType = OnException | StoreSubscribedUser;

export type StoreSummaryByCampaignIdsInput = {
  campaignIds: Array<Scalars["Int"]>;
  scopeIds?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  timeline?: InputMaybe<Scalars["Int"]>;
};

export type StoreTicket = {
  __typename?: "StoreTicket";
  createdAt?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  message?: Maybe<Scalars["String"]>;
  scopeId?: Maybe<Scalars["Int"]>;
  subject?: Maybe<Scalars["String"]>;
  ticketId?: Maybe<Scalars["String"]>;
  updatedAt?: Maybe<Scalars["String"]>;
};

export type StoreTicketInput = {
  message: Scalars["String"];
  requester: StoreTicketRequester;
  scopeId: Scalars["Int"];
  storeCode: Scalars["String"];
  subject: Scalars["String"];
};

export type StoreTicketRequester = {
  email: Scalars["String"];
  name: Scalars["String"];
};

export type StoreTicketUnionType = OnException | StoreTickets;

export type StoreTickets = {
  __typename?: "StoreTickets";
  tickets?: Maybe<Array<Maybe<StoreTicket>>>;
};

export type StoreTicketsUnionType = OnException | StoreTicket;

export type StoreTransaction = {
  __typename?: "StoreTransaction";
  amount?: Maybe<Scalars["Float"]>;
  email?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  points?: Maybe<Scalars["Float"]>;
  scopeId?: Maybe<Scalars["Int"]>;
  status?: Maybe<Scalars["String"]>;
  transactionId?: Maybe<Scalars["String"]>;
  userSsoId?: Maybe<Scalars["String"]>;
};

export type StoreTransactionUnionType = OnException | StoreTransaction;

export type StoreTransferCustomer = {
  customer?: InputMaybe<StoreCustomerInput>;
  points: Scalars["Float"];
  sender?: InputMaybe<StoreSender>;
};

export type StoreTransferGl = {
  groupLeader?: InputMaybe<StoreGlInput>;
  points: Scalars["Float"];
  sender?: InputMaybe<StoreSender>;
};

export type StoreTransferParticipant = {
  participant?: InputMaybe<StoreParticipantInput>;
  points: Scalars["Float"];
  sender?: InputMaybe<StoreSender>;
};

export type StoreTransferPointsUnionType = OnException | StoreTransferReturn;

export type StoreTransferReturn = {
  __typename?: "StoreTransferReturn";
  points?: Maybe<Scalars["Float"]>;
  receiver?: Maybe<StoreReceiverData>;
  sender?: Maybe<StoreSenderData>;
  status?: Maybe<Scalars["String"]>;
};

export type StoreUpdatePayableInput = {
  ein: Scalars["Int"];
  fullAddressOne: Scalars["String"];
  fullAddressTwo?: InputMaybe<Scalars["String"]>;
  payableName: Scalars["String"];
};

export type StoreUser = {
  __typename?: "StoreUser";
  email?: Maybe<Scalars["String"]>;
  firstName?: Maybe<Scalars["String"]>;
  lastName?: Maybe<Scalars["String"]>;
  points?: Maybe<Scalars["Float"]>;
  userId?: Maybe<Scalars["BigInt"]>;
};

export type StoreUserCampaignUnionType = OnException | StoreUserCampaigns;

export type StoreUserCampaigns = {
  __typename?: "StoreUserCampaigns";
  campaigns?: Maybe<Array<Maybe<StoreCampaign>>>;
  pagination?: Maybe<SdPagination>;
};

export type StoreUserCampaignsInput = {
  all?: InputMaybe<Scalars["Boolean"]>;
  demo?: InputMaybe<Scalars["Boolean"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Scalars["String"]>;
  orderBySort?: InputMaybe<Scalars["String"]>;
  searchBy?: InputMaybe<Scalars["String"]>;
  searchValue?: InputMaybe<Scalars["ID"]>;
};

export type StoreUserInfoUnionType = OnException | StoreUser;

export type StoreUserParticipantUnionType = OnException | StoreUserParticipants;

export type StoreUserParticipants = {
  __typename?: "StoreUserParticipants";
  pagination?: Maybe<SdPagination>;
  participants?: Maybe<Array<Maybe<StoreParticipant>>>;
};

export type StoreUserParticipantsInput = {
  all?: InputMaybe<Scalars["Boolean"]>;
  campaignIds: Array<Scalars["Int"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type StoreWithdrawalPointsPayableInput = {
  city: Scalars["String"];
  country: Scalars["String"];
  ein: Scalars["Int"];
  fullAddressOne: Scalars["String"];
  fullAddressTwo?: InputMaybe<Scalars["String"]>;
  identityId: Scalars["Int"];
  name?: InputMaybe<Scalars["String"]>;
  payableName: Scalars["String"];
  region: Scalars["String"];
  street?: InputMaybe<Scalars["String"]>;
  zip: Scalars["Int"];
};

export type StoresFilter = {
  __typename?: "StoresFilter";
  pagination?: Maybe<SdPagination>;
  stores?: Maybe<Array<Maybe<MagentoStore>>>;
};

export type StoresSummary = {
  __typename?: "StoresSummary";
  baseSales?: Maybe<Scalars["Float"]>;
  discountAmount?: Maybe<Scalars["Float"]>;
  orders?: Maybe<Scalars["BigInt"]>;
  points?: Maybe<Scalars["Float"]>;
  sales?: Maybe<Scalars["Float"]>;
  stores?: Maybe<Scalars["BigInt"]>;
};

export type StoresSummaryByGlEmailInput = {
  email: Scalars["String"];
  scopeIds?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  timeline?: InputMaybe<Scalars["Int"]>;
};

export type StoresSummaryUnionType = OnException | StoresSummary;

export type StoresWithdrawalPointsScope = {
  __typename?: "StoresWithdrawalPointsScope";
  managerEmail?: Maybe<Scalars["String"]>;
  managerId?: Maybe<Scalars["Int"]>;
  scopeCode?: Maybe<Scalars["String"]>;
  scopeId?: Maybe<Scalars["Int"]>;
  teamName?: Maybe<Scalars["String"]>;
};

export type StoresWithdrawalPointsScopeInput = {
  scopeCode: Scalars["String"];
  scopeId: Scalars["Int"];
  teamName: Scalars["String"];
};

/** Filtering options for nullable String type */
export type StringNullableFilter = {
  /** Contains operation */
  contains?: InputMaybe<Scalars["String"]>;
  /** Ends With operation */
  endsWith?: InputMaybe<Scalars["String"]>;
  /** Equals operation */
  equals?: InputMaybe<Scalars["String"]>;
  /** Greater Than operation */
  gt?: InputMaybe<Scalars["String"]>;
  /** Greater Than or Equal operation */
  gte?: InputMaybe<Scalars["String"]>;
  /** In operation for multiple values */
  in?: InputMaybe<Array<Scalars["String"]>>;
  /** Less Than operation */
  lt?: InputMaybe<Scalars["String"]>;
  /** Less Than or Equal operation */
  lte?: InputMaybe<Scalars["String"]>;
  /** Not Equal operation */
  not?: InputMaybe<Scalars["String"]>;
  /** Not In operation for multiple values */
  notIn?: InputMaybe<Array<Scalars["String"]>>;
  /** Starts With operation */
  startsWith?: InputMaybe<Scalars["String"]>;
};

export type StripeAccount = {
  __typename?: "StripeAccount";
  accountId: Scalars["String"];
  accountType?: Maybe<AccountType>;
  campaignId: Scalars["String"];
};

export enum StripeEnv {
  Raise = "RAISE",
  Spend = "SPEND",
  Sponsor = "SPONSOR",
}

/** metadata passed to Stripe API */
export type StripeMetadata = {
  __typename?: "StripeMetadata";
  activityType?: Maybe<Scalars["String"]>;
  entity?: Maybe<Scalars["String"]>;
  fundraiserId?: Maybe<Scalars["String"]>;
  paymentType?: Maybe<Scalars["String"]>;
  transactionId?: Maybe<Scalars["String"]>;
};

/** metadata to be passed to Stripe API */
export type StripeMetadataInput = {
  activityType?: InputMaybe<Scalars["String"]>;
  entity?: InputMaybe<Scalars["String"]>;
  fundraiserId?: InputMaybe<Scalars["String"]>;
  paymentType?: InputMaybe<Scalars["String"]>;
  transactionId?: InputMaybe<Scalars["String"]>;
};

export enum SupportedEmailProviders {
  Sendgrid = "sendgrid",
  Test = "test",
}

export enum SupportedSmsProviders {
  Test = "test",
  Twilio = "twilio",
}

/** See CampaignMembership for details */
export type SupporterCampaign = {
  __typename?: "SupporterCampaign";
  id: Scalars["ID"];
};

export type Team = {
  __typename?: "Team";
  facility?: Maybe<Scalars["String"]>;
  gender?: Maybe<Scalars["String"]>;
  groupval?: Maybe<Scalars["String"]>;
  home_field?: Maybe<Scalars["String"]>;
  is_deleted?: Maybe<Scalars["Boolean"]>;
  level1?: Maybe<Scalars["String"]>;
  sport_code: Scalars["String"];
  sport_description?: Maybe<Scalars["String"]>;
  sport_name: Scalars["String"];
};

export enum TeamAge {
  Adult = "ADULT",
  Preteen = "PRETEEN",
  Teen = "TEEN",
  Youth = "YOUTH",
}

export type TeamByCodeInput = {
  sport_code: Scalars["String"];
};

export type TeamCreateInput = {
  facility?: InputMaybe<Scalars["String"]>;
  gender?: InputMaybe<Scalars["String"]>;
  groupval?: InputMaybe<Scalars["String"]>;
  home_field?: InputMaybe<Scalars["String"]>;
  is_deleted?: InputMaybe<Scalars["Boolean"]>;
  level1?: InputMaybe<Scalars["String"]>;
  sport_code?: InputMaybe<Scalars["String"]>;
  sport_description?: InputMaybe<Scalars["String"]>;
  sport_name?: InputMaybe<Scalars["String"]>;
};

export type TeamDeleteInput = {
  sport_code: Scalars["String"];
};

export enum TeamGender {
  Coed = "COED",
  Female = "FEMALE",
  Male = "MALE",
}

export enum TeamGroupType {
  Club = "CLUB",
  Sport = "SPORT",
}

export type TeamLeadership = {
  __typename?: "TeamLeadership";
  org: Org;
  teams: Array<TeamWithTitle>;
};

export type TeamModifyInput = {
  facility?: InputMaybe<Scalars["String"]>;
  gender?: InputMaybe<Scalars["String"]>;
  groupval?: InputMaybe<Scalars["String"]>;
  home_field?: InputMaybe<Scalars["String"]>;
  is_deleted?: InputMaybe<Scalars["Boolean"]>;
  level1?: InputMaybe<Scalars["String"]>;
  sport_code?: InputMaybe<Scalars["String"]>;
  sport_description?: InputMaybe<Scalars["String"]>;
  sport_name?: InputMaybe<Scalars["String"]>;
};

export type TeamOfficialCreateInput = {
  id: Scalars["Int"];
  pay?: InputMaybe<Scalars["Float"]>;
  pay_code?: InputMaybe<Scalars["String"]>;
  sport: Scalars["String"];
  worker_duty?: InputMaybe<Scalars["String"]>;
};

export type TeamOfficialDeleteInput = {
  id: Scalars["Int"];
};

export type TeamOfficialModifyInput = {
  id: Scalars["Int"];
  pay?: InputMaybe<Scalars["Float"]>;
  pay_code?: InputMaybe<Scalars["String"]>;
  worker_duty?: InputMaybe<Scalars["String"]>;
};

export type TeamPreparationCreateInput = {
  id: Scalars["Int"];
  prep: Scalars["Int"];
  qty?: InputMaybe<Scalars["String"]>;
  sport: Scalars["String"];
};

export type TeamPreparationDeleteInput = {
  id: Scalars["Int"];
};

export type TeamPreparationModifyInput = {
  id: Scalars["Int"];
  prep?: InputMaybe<Scalars["Int"]>;
  qty?: InputMaybe<Scalars["String"]>;
};

export enum TeamTier {
  First = "FIRST",
  Freshman = "FRESHMAN",
  FullProgram = "FULL_PROGRAM",
  JuniorVarsity = "JUNIOR_VARSITY",
  JuniorVarsityC = "JUNIOR_VARSITY_C",
  Second = "SECOND",
  Varsity = "VARSITY",
}

export enum TeamTitle {
  AssistantCoach = "ASSISTANT_COACH",
  BoosterClubLeader = "BOOSTER_CLUB_LEADER",
  BoosterClubMember = "BOOSTER_CLUB_MEMBER",
  ClubSportsAdministrator = "CLUB_SPORTS_ADMINISTRATOR",
  ClubSportsDirector = "CLUB_SPORTS_DIRECTOR",
  ClubSportsEmployee = "CLUB_SPORTS_EMPLOYEE",
  Coach = "COACH",
  FineArtsDirector = "FINE_ARTS_DIRECTOR",
  SchoolClubAdvisor = "SCHOOL_CLUB_ADVISOR",
  TeacherInstructor = "TEACHER_INSTRUCTOR",
}

export type TeamWithTitle = {
  __typename?: "TeamWithTitle";
  team: Org;
  titles: Array<Leader>;
};

export type TeamWorkerCreateInput = {
  home_field?: InputMaybe<Scalars["String"]>;
  id: Scalars["Int"];
  pay?: InputMaybe<Scalars["Float"]>;
  pay_code?: InputMaybe<Scalars["String"]>;
  sport: Scalars["String"];
  worker_duty?: InputMaybe<Scalars["String"]>;
  worker_name?: InputMaybe<Scalars["String"]>;
};

export type TeamWorkerDeleteInput = {
  id: Scalars["Int"];
};

export type TeamWorkerModifyInput = {
  home_field?: InputMaybe<Scalars["String"]>;
  id: Scalars["Int"];
  pay?: InputMaybe<Scalars["Float"]>;
  pay_code?: InputMaybe<Scalars["String"]>;
  worker_duty?: InputMaybe<Scalars["String"]>;
  worker_name?: InputMaybe<Scalars["String"]>;
};

export type TemplateOfficial = {
  __typename?: "TemplateOfficial";
  id: Scalars["Int"];
  pay?: Maybe<Scalars["Float"]>;
  pay_code?: Maybe<Scalars["String"]>;
  sport?: Maybe<Scalars["String"]>;
  worker_duty?: Maybe<Scalars["String"]>;
};

export type TemplatePreparation = {
  __typename?: "TemplatePreparation";
  id: Scalars["Int"];
  prep?: Maybe<Scalars["Int"]>;
  preparation_id?: Maybe<Scalars["String"]>;
  preparation_name?: Maybe<Scalars["String"]>;
  qty?: Maybe<Scalars["String"]>;
  sport?: Maybe<Scalars["String"]>;
};

export type TemplateWorker = {
  __typename?: "TemplateWorker";
  home_field?: Maybe<Scalars["String"]>;
  id: Scalars["Int"];
  pay?: Maybe<Scalars["Float"]>;
  pay_code?: Maybe<Scalars["String"]>;
  sport?: Maybe<Scalars["String"]>;
  worker_duty?: Maybe<Scalars["String"]>;
  worker_name?: Maybe<Scalars["String"]>;
};

export type Tokens = {
  accessToken?: Maybe<Scalars["String"]>;
  refreshToken?: Maybe<Scalars["String"]>;
};

export type TopDonation = {
  __typename?: "TopDonation";
  donorName?: Maybe<Scalars["String"]>;
  subtotalCents?: Maybe<Scalars["Float"]>;
};

export type Total = {
  __typename?: "Total";
  salary?: Maybe<Scalars["String"]>;
  woker_name?: Maybe<Scalars["String"]>;
};

export type TotalDonationsRaised = {
  __typename?: "TotalDonationsRaised";
  numberOfDonations?: Maybe<Scalars["Int"]>;
  subtotalCents?: Maybe<Scalars["Float"]>;
};

export type Transaction = {
  __typename?: "Transaction";
  amount?: Maybe<Scalars["Decimal"]>;
  correlationId?: Maybe<Scalars["String"]>;
  created?: Maybe<Scalars["Timestamp"]>;
  description?: Maybe<Scalars["String"]>;
  destination?: Maybe<Scalars["String"]>;
  direction?: Maybe<Scalars["String"]>;
  effective: Scalars["Timestamp"];
  externalId: Scalars["String"];
  id: Scalars["UUID"];
  metadata?: Maybe<Scalars["JSON"]>;
  processor?: Maybe<Scalars["String"]>;
  snapAmount?: Maybe<Scalars["Decimal"]>;
  source?: Maybe<Scalars["String"]>;
  status: Scalars["String"];
  type?: Maybe<Scalars["String"]>;
};

export type TransactionAttachment = {
  __typename?: "TransactionAttachment";
  createdAt?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  lastUpdatedByUserId?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  paymentId?: Maybe<Scalars["String"]>;
  updatedAt?: Maybe<Scalars["String"]>;
  url?: Maybe<Scalars["String"]>;
};

export type TransactionAttachmentsResponse = {
  __typename?: "TransactionAttachmentsResponse";
  attachments?: Maybe<Array<Maybe<TransactionAttachment>>>;
};

export type TransactionDetail = {
  __typename?: "TransactionDetail";
  amount?: Maybe<Scalars["Decimal"]>;
  description: Scalars["String"];
  destination?: Maybe<Scalars["String"]>;
  direction?: Maybe<Scalars["String"]>;
  effective: Scalars["Timestamp"];
  externalId: Scalars["String"];
  history: Array<Maybe<TransactionHistoryEvent>>;
  id: Scalars["UUID"];
  metadata?: Maybe<Scalars["JSON"]>;
  processor?: Maybe<Scalars["String"]>;
  snapAmount?: Maybe<Scalars["Decimal"]>;
  source?: Maybe<Scalars["String"]>;
  status: Status;
  type?: Maybe<Scalars["String"]>;
};

export enum TransactionFilterEnum {
  Date = "date",
  GroupName = "groupName",
  Method = "method",
  ReconciledStatus = "reconciledStatus",
  SeasonName = "seasonName",
  Status = "status",
  Type = "type",
}

export enum TransactionFilterType {
  ApplicationFee = "ApplicationFee",
  Transfer = "Transfer",
  UserPayment = "UserPayment",
}

export type TransactionHistoryEvent = {
  __typename?: "TransactionHistoryEvent";
  date: Scalars["Timestamp"];
  status: Scalars["String"];
  transactionId: Scalars["UUID"];
};

export type TransactionInput = {
  emailOverride?: InputMaybe<Scalars["String"]>;
  /** It's either going to be a donationId or a purchaseId */
  transactionId: Scalars["ID"];
  transactionType: TransactionType;
};

export type TransactionNotes = {
  __typename?: "TransactionNotes";
  content?: Maybe<Scalars["String"]>;
  createdAt?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  lastUpdatedByUserId?: Maybe<Scalars["String"]>;
  paymentId?: Maybe<Scalars["String"]>;
  updatedAt?: Maybe<Scalars["String"]>;
};

export type TransactionNotesResponse = {
  __typename?: "TransactionNotesResponse";
  notes?: Maybe<Array<Maybe<TransactionNotes>>>;
};

export enum TransactionReportFilterEnum {
  DateAfter = "dateAfter",
  DateBefore = "dateBefore",
  GroupIdOrgId = "groupIdOrgId",
  Method = "method",
  NameIncludes = "nameIncludes",
  Status = "status",
  Type = "type",
}

export type TransactionTotalCount = {
  __typename?: "TransactionTotalCount";
  count?: Maybe<Scalars["Int"]>;
  total?: Maybe<Scalars["Int"]>;
};

export enum TransactionType {
  Donation = "Donation",
  Purchase = "Purchase",
}

export type TransactionsInput = {
  createdAfter?: InputMaybe<Scalars["Timestamp"]>;
  createdBefore?: InputMaybe<Scalars["Timestamp"]>;
  destination?: InputMaybe<Scalars["String"]>;
  exclude?: InputMaybe<Array<InputMaybe<TransactionFilterType>>>;
  externalId?: InputMaybe<Scalars["String"]>;
  metadata?: InputMaybe<Scalars["JSONObject"]>;
  source?: InputMaybe<Scalars["String"]>;
  status?: InputMaybe<Status>;
};

export type TransformedDailyCalendarBusSchedule = {
  __typename?: "TransformedDailyCalendarBusSchedule";
  calendar?: Maybe<Array<Maybe<Scalars["String"]>>>;
  events?: Maybe<Array<Maybe<DailyCalendarBusScheduleEvents>>>;
  exportdata?: Maybe<Array<Maybe<DailyCalendarBusScheduleExportData>>>;
};

export type TransformedDailyCalendarEventReturn = {
  __typename?: "TransformedDailyCalendarEventReturn";
  activity?: Maybe<Scalars["String"]>;
  activityLevel?: Maybe<Scalars["String"]>;
  activityType?: Maybe<Scalars["String"]>;
  author?: Maybe<Scalars["String"]>;
  bus_fee?: Maybe<Scalars["Int"]>;
  bus_time?: Maybe<Scalars["String"]>;
  comments?: Maybe<Scalars["String"]>;
  conference?: Maybe<Scalars["String"]>;
  conference_event_id?: Maybe<Scalars["Int"]>;
  conference_id?: Maybe<Scalars["Int"]>;
  confirmed?: Maybe<Scalars["String"]>;
  confirmedStatusBoolean?: Maybe<Scalars["Boolean"]>;
  contract?: Maybe<Scalars["String"]>;
  departure_location?: Maybe<Scalars["String"]>;
  directions?: Maybe<Scalars["String"]>;
  duty?: Maybe<Scalars["String"]>;
  early_dismissal_required?: Maybe<Scalars["String"]>;
  early_dismissal_time?: Maybe<Scalars["String"]>;
  email?: Maybe<Scalars["String"]>;
  end_time?: Maybe<Scalars["String"]>;
  estimated_return_time?: Maybe<Scalars["String"]>;
  event?: Maybe<Scalars["String"]>;
  eventOfficial?: Maybe<Scalars["String"]>;
  eventOfficialCell?: Maybe<Scalars["String"]>;
  eventOfficialEmail?: Maybe<Scalars["String"]>;
  eventTiming?: Maybe<Scalars["String"]>;
  event_date?: Maybe<Scalars["String"]>;
  event_id?: Maybe<Scalars["Int"]>;
  exists_in_mls?: Maybe<Scalars["Int"]>;
  fee?: Maybe<Scalars["Int"]>;
  formatedEventDate?: Maybe<Scalars["String"]>;
  formatedEventDateSystem?: Maybe<Scalars["String"]>;
  formatedEventDay?: Maybe<Scalars["String"]>;
  g_s?: Maybe<Scalars["String"]>;
  gate_revenue?: Maybe<Scalars["Int"]>;
  groupval?: Maybe<Scalars["String"]>;
  headline?: Maybe<Scalars["String"]>;
  impact_event?: Maybe<Scalars["String"]>;
  isDuplicate?: Maybe<Scalars["String"]>;
  lead?: Maybe<Scalars["String"]>;
  location?: Maybe<Scalars["String"]>;
  loss_points?: Maybe<Scalars["Int"]>;
  noofgames?: Maybe<Scalars["String"]>;
  num_buses?: Maybe<Scalars["Int"]>;
  num_buses_text?: Maybe<Scalars["String"]>;
  opponent?: Maybe<Scalars["String"]>;
  opponent_code?: Maybe<Scalars["String"]>;
  opponent_score?: Maybe<Scalars["String"]>;
  pay_code?: Maybe<Scalars["String"]>;
  picture?: Maybe<Scalars["String"]>;
  place?: Maybe<Scalars["String"]>;
  prep_setup?: Maybe<Scalars["String"]>;
  promote?: Maybe<Scalars["String"]>;
  results?: Maybe<Scalars["String"]>;
  revenue?: Maybe<Scalars["Int"]>;
  rollover?: Maybe<Scalars["String"]>;
  rolloverStatusBoolean?: Maybe<Scalars["Boolean"]>;
  salary?: Maybe<Scalars["Int"]>;
  seasonInfo?: Maybe<Scalars["String"]>;
  seasonSportCode?: Maybe<Scalars["String"]>;
  season_team?: Maybe<Scalars["Int"]>;
  serialnumber?: Maybe<Scalars["String"]>;
  sportCode?: Maybe<Scalars["String"]>;
  sportDescription?: Maybe<Scalars["String"]>;
  sportGender?: Maybe<Scalars["String"]>;
  sportLevel?: Maybe<Scalars["String"]>;
  sportName?: Maybe<Scalars["String"]>;
  start_time?: Maybe<Scalars["String"]>;
  team_score?: Maybe<Scalars["String"]>;
  title?: Maybe<Scalars["String"]>;
  tournament?: Maybe<Scalars["String"]>;
  trans_id?: Maybe<Scalars["Int"]>;
  transportDetails?: Maybe<Scalars["String"]>;
  transport_comments?: Maybe<Scalars["String"]>;
  transportation?: Maybe<Scalars["String"]>;
  vehicle_count?: Maybe<Scalars["String"]>;
  vehiclesTransportDetails?: Maybe<
    Array<Maybe<DailyCalendarEventTransportDetails>>
  >;
  web_dir?: Maybe<Scalars["String"]>;
  weekdayname?: Maybe<Scalars["String"]>;
  win_points?: Maybe<Scalars["Int"]>;
  years?: Maybe<Scalars["String"]>;
};

export type TransformedDailyCalendarOfficials = {
  __typename?: "TransformedDailyCalendarOfficials";
  exportdata?: Maybe<Array<Maybe<DailyCalendarOfficialExport>>>;
  message?: Maybe<Array<Maybe<DailyCalendarOfficialMessage>>>;
};

export type TransformedDailyCalendarPreparation = {
  __typename?: "TransformedDailyCalendarPreparation";
  exportdata?: Maybe<Array<Maybe<DailyCalendarPreparationExportData>>>;
  message?: Maybe<Array<Maybe<DailyCalendarPreparationMessage>>>;
};

export type TransformedDailyCalendarWorkers = {
  __typename?: "TransformedDailyCalendarWorkers";
  exportdata?: Maybe<Array<Maybe<DailyCalendarWorkerExportData>>>;
  message?: Maybe<Array<Maybe<DailyCalendarWorkerMessage>>>;
};

export type TransformedEventReturn = {
  __typename?: "TransformedEventReturn";
  activity?: Maybe<Scalars["String"]>;
  activityLevel?: Maybe<Scalars["String"]>;
  activityType?: Maybe<Scalars["String"]>;
  author?: Maybe<Scalars["String"]>;
  bus_fee?: Maybe<Scalars["Int"]>;
  bus_time?: Maybe<Scalars["String"]>;
  comments?: Maybe<Scalars["String"]>;
  conference?: Maybe<Scalars["String"]>;
  conference_event_id?: Maybe<Scalars["Int"]>;
  conference_id?: Maybe<Scalars["Int"]>;
  confirmed?: Maybe<Scalars["String"]>;
  confirmedStatusBoolean?: Maybe<Scalars["Boolean"]>;
  contract?: Maybe<Scalars["String"]>;
  departure_location?: Maybe<Scalars["String"]>;
  directions?: Maybe<Scalars["String"]>;
  duty?: Maybe<Scalars["String"]>;
  early_dismissal_required?: Maybe<Scalars["String"]>;
  early_dismissal_time?: Maybe<Scalars["String"]>;
  email?: Maybe<Scalars["String"]>;
  end_time?: Maybe<Scalars["String"]>;
  estimated_return_time?: Maybe<Scalars["String"]>;
  event?: Maybe<Scalars["String"]>;
  eventOfficial?: Maybe<Scalars["String"]>;
  eventOfficialCell?: Maybe<Scalars["String"]>;
  eventOfficialEmail?: Maybe<Scalars["String"]>;
  eventTiming?: Maybe<Scalars["String"]>;
  event_date?: Maybe<Scalars["String"]>;
  event_id?: Maybe<Scalars["Int"]>;
  exists_in_mls?: Maybe<Scalars["Int"]>;
  fee?: Maybe<Scalars["Int"]>;
  formatedEventDate?: Maybe<Scalars["String"]>;
  formatedEventDateSystem?: Maybe<Scalars["String"]>;
  formatedEventDay?: Maybe<Scalars["String"]>;
  g_s?: Maybe<Scalars["String"]>;
  gate_revenue?: Maybe<Scalars["Int"]>;
  groupval?: Maybe<Scalars["String"]>;
  headline?: Maybe<Scalars["String"]>;
  impact_event?: Maybe<Scalars["String"]>;
  isDuplicate?: Maybe<Scalars["String"]>;
  lead?: Maybe<Scalars["String"]>;
  location?: Maybe<Scalars["String"]>;
  loss_points?: Maybe<Scalars["Int"]>;
  noofgames?: Maybe<Scalars["String"]>;
  num_buses?: Maybe<Scalars["Int"]>;
  num_buses_text?: Maybe<Scalars["String"]>;
  opponent?: Maybe<Scalars["String"]>;
  opponent_code?: Maybe<Scalars["String"]>;
  opponent_score?: Maybe<Scalars["String"]>;
  pay_code?: Maybe<Scalars["String"]>;
  picture?: Maybe<Scalars["String"]>;
  place?: Maybe<Scalars["String"]>;
  prep_setup?: Maybe<Scalars["String"]>;
  promote?: Maybe<Scalars["String"]>;
  results?: Maybe<Scalars["String"]>;
  revenue?: Maybe<Scalars["Int"]>;
  rollover?: Maybe<Scalars["String"]>;
  rolloverStatusBoolean?: Maybe<Scalars["Boolean"]>;
  salary?: Maybe<Scalars["Int"]>;
  seasonInfo?: Maybe<Scalars["String"]>;
  seasonSportCode?: Maybe<Scalars["String"]>;
  season_team?: Maybe<Scalars["Int"]>;
  serialnumber?: Maybe<Scalars["String"]>;
  sportCode?: Maybe<Scalars["String"]>;
  sportDescription?: Maybe<Scalars["String"]>;
  sportGender?: Maybe<Scalars["String"]>;
  sportLevel?: Maybe<Scalars["String"]>;
  sportName?: Maybe<Scalars["String"]>;
  start_time?: Maybe<Scalars["String"]>;
  team_score?: Maybe<Scalars["String"]>;
  title?: Maybe<Scalars["String"]>;
  tournament?: Maybe<Scalars["String"]>;
  trans_id?: Maybe<Scalars["Int"]>;
  transportDetails?: Maybe<Scalars["String"]>;
  transport_comments?: Maybe<Scalars["String"]>;
  transportation?: Maybe<Scalars["String"]>;
  vehicle_count?: Maybe<Scalars["String"]>;
  web_dir?: Maybe<Scalars["String"]>;
  weekdayname?: Maybe<Scalars["String"]>;
  win_points?: Maybe<Scalars["Int"]>;
  years?: Maybe<Scalars["String"]>;
};

export type TransformedOfficialAssignment = {
  __typename?: "TransformedOfficialAssignment";
  duty?: Maybe<Scalars["String"]>;
  eventActivity?: Maybe<Scalars["String"]>;
  eventDate?: Maybe<Scalars["String"]>;
  eventHomeAway?: Maybe<Scalars["String"]>;
  eventLocation?: Maybe<Scalars["String"]>;
  eventOpponent?: Maybe<Scalars["String"]>;
  eventTime?: Maybe<Scalars["String"]>;
  event_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  name?: Maybe<Scalars["String"]>;
  organization?: Maybe<Scalars["String"]>;
  paid?: Maybe<Scalars["String"]>;
  pay_code?: Maybe<Scalars["String"]>;
  salary?: Maybe<Scalars["Int"]>;
  seasonSportCode?: Maybe<Scalars["String"]>;
  sportDescription?: Maybe<Scalars["String"]>;
  ssn?: Maybe<Scalars["String"]>;
};

export enum TransportEnum {
  Email = "email",
  Sms = "sms",
}

export type TransportType = {
  __typename?: "TransportType";
  recipient?: Maybe<Scalars["String"]>;
  transport?: Maybe<TransportEnum>;
};

export type UnconfirmedEvents = {
  __typename?: "UnconfirmedEvents";
  activity?: Maybe<Scalars["String"]>;
  bus_count?: Maybe<Scalars["Int"]>;
  comments?: Maybe<Scalars["String"]>;
  confirmed?: Maybe<Scalars["String"]>;
  driver_name?: Maybe<Scalars["String"]>;
  driver_phone?: Maybe<Scalars["String"]>;
  end_time?: Maybe<Scalars["String"]>;
  event_date?: Maybe<Scalars["String"]>;
  facility?: Maybe<Scalars["String"]>;
  g_s?: Maybe<Scalars["String"]>;
  gender?: Maybe<Scalars["String"]>;
  home_field?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["Int"]>;
  levels?: Maybe<Scalars["String"]>;
  opponent?: Maybe<Scalars["String"]>;
  place?: Maybe<Scalars["String"]>;
  season_id?: Maybe<Scalars["Int"]>;
  sports_code?: Maybe<Scalars["String"]>;
  sports_description?: Maybe<Scalars["String"]>;
  sports_group?: Maybe<Scalars["String"]>;
  sports_name?: Maybe<Scalars["String"]>;
  start_time?: Maybe<Scalars["String"]>;
  vehicle_id?: Maybe<Scalars["String"]>;
  vehicle_type?: Maybe<Scalars["String"]>;
  year?: Maybe<Scalars["String"]>;
};

export type UnconfirmedEventsFiltersInput = {
  activity?: InputMaybe<Scalars["String"]>;
  gender?: InputMaybe<Scalars["String"]>;
  levels?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  opponents?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  teams?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  year?: InputMaybe<Scalars["String"]>;
};

export type UnconfirmedEventsList = {
  __typename?: "UnconfirmedEventsList";
  items?: Maybe<Array<Maybe<UnconfirmedEvents>>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  totalFilteredItems?: Maybe<Scalars["Int"]>;
  totalPages?: Maybe<Scalars["Int"]>;
  totalRows?: Maybe<Scalars["Int"]>;
};

export type UnprocessableSubmission = Error & {
  __typename?: "UnprocessableSubmission";
  message: Scalars["String"];
};

export type UnreadCount = {
  __typename?: "UnreadCount";
  totalUnreadCount: Scalars["Int"];
};

export type UpdatedFundraiserStoreUrl = {
  __typename?: "UpdatedFundraiserStoreUrl";
  id?: Maybe<Scalars["Int"]>;
};

export type UpdatedParticipantGroupCount = {
  __typename?: "UpdatedParticipantGroupCount";
  count?: Maybe<Scalars["Int"]>;
};

export type UpsertEventParticipantsCount = {
  __typename?: "UpsertEventParticipantsCount";
  count?: Maybe<Scalars["Int"]>;
};

export type UpsertEventParticipantsInput = {
  eventParticipantsArray: Array<InputMaybe<EventParticipantsInput>>;
};

export type UpsertEventPreparationsCount = {
  __typename?: "UpsertEventPreparationsCount";
  count?: Maybe<Scalars["Int"]>;
};

export type UpsertEventPreparationsInput = {
  eventPreparationsArray: Array<InputMaybe<EventPreparationsInput>>;
};

export type UpsertEventTransportDetailsCount = {
  __typename?: "UpsertEventTransportDetailsCount";
  count?: Maybe<Scalars["Int"]>;
};

export type UpsertEventTransportDetailsInput = {
  eventTransportDetailsArray: Array<InputMaybe<EventTransportDetailsInput>>;
};

export type UpsertFacilitiesInput = {
  address1?: InputMaybe<Scalars["String"]>;
  address2?: InputMaybe<Scalars["String"]>;
  city?: InputMaybe<Scalars["String"]>;
  directions?: InputMaybe<Scalars["String"]>;
  facility_name: Scalars["String"];
  location_id?: InputMaybe<Scalars["Int"]>;
  state?: InputMaybe<Scalars["String"]>;
  zipCode?: InputMaybe<Scalars["String"]>;
};

export type UpsertManyWorkersInput = {
  workersArray: Array<InputMaybe<CreateWorkerInput>>;
};

export type UpsertOfficialDutiesInput = {
  duty?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["Int"]>;
};

export type UpsertOfficialsInput = {
  address?: InputMaybe<Scalars["String"]>;
  cell_phone?: InputMaybe<Scalars["String"]>;
  email?: InputMaybe<Scalars["String"]>;
  first_name?: InputMaybe<Scalars["String"]>;
  home_phone?: InputMaybe<Scalars["String"]>;
  last_name?: InputMaybe<Scalars["String"]>;
  official_id: Scalars["String"];
  ssn?: InputMaybe<Scalars["String"]>;
  work_phone?: InputMaybe<Scalars["String"]>;
  zip?: InputMaybe<Scalars["String"]>;
};

export type UpsertOpponentsInput = {
  ad_name?: InputMaybe<Scalars["String"]>;
  address?: InputMaybe<Scalars["String"]>;
  city?: InputMaybe<Scalars["String"]>;
  email?: InputMaybe<Scalars["String"]>;
  fax?: InputMaybe<Scalars["String"]>;
  phone?: InputMaybe<Scalars["String"]>;
  school_code: Scalars["String"];
  school_name?: InputMaybe<Scalars["String"]>;
  state?: InputMaybe<Scalars["String"]>;
  zip?: InputMaybe<Scalars["String"]>;
};

export type UpsertWorkersInput = {
  cell_phone?: InputMaybe<Scalars["String"]>;
  email?: InputMaybe<Scalars["String"]>;
  first_name?: InputMaybe<Scalars["String"]>;
  home_phone?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["Int"]>;
  last_name?: InputMaybe<Scalars["String"]>;
  pay_rate?: InputMaybe<Scalars["Float"]>;
  ssn?: InputMaybe<Scalars["String"]>;
  worker_type?: InputMaybe<WorkerTypes>;
};

export type UrgentAnnouncementFilter = {
  orderBy?: InputMaybe<ManageResourceAnnouncementOrderBy>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
};

export type User = {
  __typename?: "User";
  apps?: Maybe<Array<Maybe<Scalars["String"]>>>;
  createdAt?: Maybe<Scalars["Date"]>;
  email?: Maybe<Scalars["String"]>;
  firstName?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  isConfirmed?: Maybe<Scalars["Boolean"]>;
  isDisabled?: Maybe<Scalars["Boolean"]>;
  kyc?: Maybe<VaultKyc>;
  language?: Maybe<Scalars["String"]>;
  lastLoginAt?: Maybe<Scalars["Date"]>;
  lastName?: Maybe<Scalars["String"]>;
  occupation?: Maybe<UserOccupation>;
  phoneNumber?: Maybe<Scalars["String"]>;
  profilePicture?: Maybe<Scalars["String"]>;
  role?: Maybe<Scalars["String"]>;
  /** @deprecated will be removed in the next release */
  snapRaiseId?: Maybe<Scalars["String"]>;
  /** @deprecated will be removed in the next release */
  snapSpendId?: Maybe<Scalars["String"]>;
};

export type UserAffiliation = {
  __typename?: "UserAffiliation";
  createdAt: Scalars["DateTime"];
  description?: Maybe<Scalars["String"]>;
  isConfirmed?: Maybe<Scalars["Boolean"]>;
  title: AffiliationTitle;
  updatedAt?: Maybe<Scalars["DateTime"]>;
};

export type UserAffiliationCounts = {
  __typename?: "UserAffiliationCounts";
  affiliatedCount: Scalars["Int"];
  impliedCount: Scalars["Int"];
  leadsCount: Scalars["Int"];
  total: Scalars["Int"];
};

export enum UserApps {
  Connect = "connect",
  Drive = "drive",
  Home = "home",
  Insights = "insights",
  Manage = "manage",
  Raise = "raise",
  Spend = "spend",
  SpendNew = "spend_new",
  Sponsor = "sponsor",
  Store = "store",
  Wallet = "wallet",
}

export type UserChallenge = {
  __typename?: "UserChallenge";
  completedAt?: Maybe<Scalars["String"]>;
  id: Scalars["String"];
  name: Scalars["String"];
  params?: Maybe<Scalars["JSONObject"]>;
  skippedAt?: Maybe<Scalars["String"]>;
  status: UserChallengeStatus;
};

export enum UserChallengeStatus {
  Awaiting = "AWAITING",
  Completed = "COMPLETED",
  Skipped = "SKIPPED",
}

export type UserFitting = {
  __typename?: "UserFitting";
  fitting?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
};

export enum UserFittingPreference {
  Mens = "MENS",
  Womens = "WOMENS",
}

export type UserInsightsConfig = {
  __typename?: "UserInsightsConfig";
  createdAt: Scalars["DateTime"];
  id: Scalars["Int"];
  name: Scalars["NonEmptyString"];
  updatedAt: Scalars["DateTime"];
  userId: Scalars["NonEmptyString"];
  value: Scalars["NonEmptyString"];
};

export type UserInsightsConfigInput = {
  name: Scalars["NonEmptyString"];
  value?: InputMaybe<Scalars["NonEmptyString"]>;
};

export type UserInsightsConfigResult = {
  __typename?: "UserInsightsConfigResult";
  messages: Array<Maybe<Scalars["String"]>>;
  status: Scalars["String"];
};

export type UserInviteParams = {
  __typename?: "UserInviteParams";
  occupation?: Maybe<Scalars["String"]>;
  phoneNumber?: Maybe<Scalars["String"]>;
};

export type UserInviteResponse = {
  __typename?: "UserInviteResponse";
  redirect?: Maybe<Scalars["String"]>;
};

export enum UserInviteStatus {
  Accepted = "ACCEPTED",
  Declined = "DECLINED",
  Pending = "PENDING",
}

export type UserNode = {
  __typename?: "UserNode";
  createdAt?: Maybe<Scalars["DateTime"]>;
  id: Scalars["ID"];
  internal?: Maybe<Scalars["Boolean"]>;
  role?: Maybe<Scalars["ID"]>;
  type?: Maybe<Array<Maybe<Scalars["String"]>>>;
  updatedAt?: Maybe<Scalars["DateTime"]>;
};

export enum UserOccupation {
  DistrictAdministrator = "district_administrator",
  FinancialAdministrator = "financial_administrator",
  GroupLeader = "group_leader",
  Other = "other",
  Parent = "parent",
  SchoolDirector = "school_director",
  StateAdministrator = "state_administrator",
  StudentOrParticipant = "student_or_participant",
  Unknown = "unknown",
}

export type UserOrgAffiliationPayload = {
  orgId?: InputMaybe<Scalars["String"]>;
  title?: InputMaybe<Scalars["String"]>;
};

export type UserOrgInvitationPayload = {
  email?: InputMaybe<Scalars["String"]>;
  orgId?: InputMaybe<Scalars["String"]>;
  title?: InputMaybe<Scalars["String"]>;
};

export type UserOrgInvitationResult = {
  __typename?: "UserOrgInvitationResult";
  id?: Maybe<Scalars["String"]>;
  info?: Maybe<Scalars["JSON"]>;
  meta?: Maybe<Scalars["JSON"]>;
  success?: Maybe<Scalars["Boolean"]>;
};

export type UserPermission = {
  __typename?: "UserPermission";
  id: Scalars["String"];
  scope?: Maybe<Scalars["String"]>;
};

export type UserPermissionAssignment = {
  id: Scalars["String"];
  scope?: InputMaybe<Scalars["String"]>;
};

export type UserPermissionsList = {
  __typename?: "UserPermissionsList";
  id?: Maybe<Scalars["String"]>;
  negativePermissions?: Maybe<Array<Maybe<UserPermission>>>;
  permissions?: Maybe<Array<Maybe<UserPermission>>>;
  roleIds?: Maybe<Array<Maybe<Scalars["String"]>>>;
};

export type UserPreference = {
  __typename?: "UserPreference";
  featureTourCompleted?: Maybe<Scalars["Boolean"]>;
  id?: Maybe<Scalars["String"]>;
  userId?: Maybe<Scalars["String"]>;
};

export enum UserPreferenceQueryType {
  All = "ALL",
  Campaign = "CAMPAIGN",
  Org = "ORG",
}

export type UserPublic = {
  __typename?: "UserPublic";
  firstName?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  isInternal?: Maybe<Scalars["Boolean"]>;
  lastName?: Maybe<Scalars["String"]>;
  notMigrated?: Maybe<Scalars["Boolean"]>;
  profilePicture?: Maybe<Scalars["String"]>;
};

export type UserPublicInfo = {
  __typename?: "UserPublicInfo";
  isInternal?: Maybe<Scalars["Boolean"]>;
  notMigrated?: Maybe<Scalars["Boolean"]>;
};

export type UserPublicInfoResult = {
  __typename?: "UserPublicInfoResult";
  info?: Maybe<UserPublicInfo>;
  user?: Maybe<UserPublic>;
};

export type UserResetPasswordByAdminResponse = {
  __typename?: "UserResetPasswordByAdminResponse";
  user?: Maybe<User>;
};

export type UserResetPasswordResponseWithAvailableTransport = {
  __typename?: "UserResetPasswordResponseWithAvailableTransport";
  availableTransport: Array<TransportEnum>;
  info?: Maybe<Scalars["String"]>;
  result: Array<TransportType>;
};

export type UserSavedRep = {
  __typename?: "UserSavedRep";
  snapRepEmail?: Maybe<Scalars["String"]>;
  uuId?: Maybe<Scalars["String"]>;
};

export enum UserTypeConfiguration {
  Default = "DEFAULT",
  GuardianLead = "GUARDIAN_LEAD",
}

export type UserWithPermissions = {
  __typename?: "UserWithPermissions";
  apps?: Maybe<Array<Maybe<Scalars["String"]>>>;
  email?: Maybe<Scalars["String"]>;
  firstName?: Maybe<Scalars["String"]>;
  hasFamily?: Maybe<Scalars["Boolean"]>;
  hsGradYear?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  isInternal?: Maybe<Scalars["Boolean"]>;
  kyc?: Maybe<VaultKyc>;
  language?: Maybe<Scalars["String"]>;
  lastLoginAt?: Maybe<Scalars["Date"]>;
  lastName?: Maybe<Scalars["String"]>;
  occupation?: Maybe<UserOccupation>;
  /** @deprecated use parents instead of parent */
  parent?: Maybe<Scalars["String"]>;
  parents?: Maybe<Array<Maybe<Scalars["String"]>>>;
  permissions?: Maybe<Array<Maybe<Scalars["String"]>>>;
  phoneNumber?: Maybe<Scalars["String"]>;
  profilePicture?: Maybe<Scalars["String"]>;
  /** @deprecated will be removed in the next release */
  snapRaiseId?: Maybe<Scalars["String"]>;
  /** @deprecated will be removed in the next release */
  snapSpendId?: Maybe<Scalars["String"]>;
};

export type Users = {
  __typename?: "Users";
  nextPage?: Maybe<Scalars["Boolean"]>;
  users?: Maybe<Array<Maybe<User>>>;
};

export type UsersFilter = {
  userIds: Array<Scalars["ID"]>;
};

export type VaultAccountStatus = {
  __typename?: "VaultAccountStatus";
  accountRepresentativeRequirements?: Maybe<VaultEntityRequirements>;
  beneficialOwnersRequirements?: Maybe<Array<VaultEntityRequirements>>;
  kybRequirements?: Maybe<VaultEntityRequirements>;
  valid: Scalars["Boolean"];
};

/** Used for addresses in VaultKyb and VaultKyc */
export type VaultAddress = {
  __typename?: "VaultAddress";
  city: Scalars["String"];
  postalCode: Scalars["String"];
  region: Scalars["String"];
  street: Scalars["String"];
  unit?: Maybe<Scalars["String"]>;
};

/** Used for addresses in VaultKyb, VaultKyc and latestCreateCard */
export type VaultAddressInput = {
  city: Scalars["String"];
  postalCode: Scalars["String"];
  region: Scalars["String"];
  street: Scalars["String"];
  unit?: InputMaybe<Scalars["String"]>;
};

/** used in vaultKybUpdate and vaultKycUpdate */
export type VaultAddressUpdateInput = {
  city?: InputMaybe<Scalars["String"]>;
  postalCode?: InputMaybe<Scalars["String"]>;
  region?: InputMaybe<Scalars["String"]>;
  street?: InputMaybe<Scalars["String"]>;
  unit?: InputMaybe<Scalars["String"]>;
};

export type VaultBankAccount = {
  __typename?: "VaultBankAccount";
  account_holder_name?: Maybe<Scalars["String"]>;
  account_holder_type?: Maybe<Scalars["String"]>;
  bank_name: Scalars["String"];
  country: Scalars["String"];
  currency: Scalars["String"];
  default_for_currency: Scalars["Boolean"];
  fingerprint: Scalars["String"];
  id: Scalars["ID"];
  last4: Scalars["String"];
  routing_number: Scalars["String"];
  status: Scalars["String"];
};

/** Additional data passed into the Stripe api Can be read in Stripe dashboard */
export type VaultCardMetadata = {
  __typename?: "VaultCardMetadata";
  description?: Maybe<Scalars["String"]>;
  walletId?: Maybe<Scalars["String"]>;
};

/** Additional data passed into the Stripe api Can be read in Stripe dashboard */
export type VaultCardMetadataInput = {
  description?: InputMaybe<Scalars["String"]>;
  walletId?: InputMaybe<Scalars["String"]>;
};

/** Current status of card */
export enum VaultCardStatus {
  Active = "active",
  /** To deactivate a card set it to canceled */
  Canceled = "canceled",
  /** Cards ship inactive */
  Inactive = "inactive",
}

/** Is the card physical (needs to mailed out) or virtual */
export enum VaultCardType {
  Physical = "physical",
  Virtual = "virtual",
}

export type VaultCreateKybKycStripeInput = {
  metadata?: InputMaybe<StripeMetadataInput>;
  statementDescriptor?: InputMaybe<Scalars["String"]>;
  stripeEnv?: InputMaybe<VaultStripeEnv>;
};

/** Day, Month and Year of birth */
export type VaultDob = {
  __typename?: "VaultDob";
  day?: Maybe<Scalars["String"]>;
  month?: Maybe<Scalars["String"]>;
  year?: Maybe<Scalars["String"]>;
};

/** Day, Month and Year of birth */
export type VaultDobInput = {
  day: Scalars["String"];
  month: Scalars["String"];
  year: Scalars["String"];
};

export enum VaultDocumentProvider {
  Stripe = "stripe",
  Unit = "unit",
}

export type VaultEntityRequirements = {
  __typename?: "VaultEntityRequirements";
  missingFields: Array<Scalars["String"]>;
  requiredDocuments: Array<VaultRequiredDocument>;
};

/** Third party financial providers */
export enum VaultFinancialProvider {
  Stripe = "stripe",
  Unit = "unit",
}

export type VaultFormKybInput = {
  accountId: Scalars["ID"];
  financialAccountNodeId?: InputMaybe<Scalars["ID"]>;
  kybId: Scalars["ID"];
  orgId: Scalars["ID"];
};

export type VaultFormMutationError = {
  __typename?: "VaultFormMutationError";
  details?: Maybe<Scalars["JSONObject"]>;
  field?: Maybe<Scalars["String"]>;
  fieldMessage?: Maybe<Scalars["String"]>;
  message: Scalars["String"];
  retryable: Scalars["Boolean"];
  type: Scalars["String"];
};

export type VaultFormMutationResponse = {
  __typename?: "VaultFormMutationResponse";
  errors?: Maybe<Array<VaultFormMutationError>>;
  success: Scalars["Boolean"];
  successData?: Maybe<VaultFormSuccessData>;
};

export type VaultFormStripeInput = {
  metadata?: InputMaybe<StripeMetadataInput>;
  statementDescriptor?: InputMaybe<Scalars["String"]>;
  stripeEnv?: InputMaybe<VaultStripeEnv>;
};

export type VaultFormSuccessData = {
  __typename?: "VaultFormSuccessData";
  beneficialOwners?: Maybe<Array<VaultKycSuccessData>>;
  kybData: VaultKybSuccessData;
  payableOrgNode?: Maybe<VaultPayableOrgSuccessData>;
  representative: VaultKycSuccessData;
  stripeEnv?: Maybe<VaultStripeEnv>;
};

/** Organizations/Companies */
export type VaultKyb = {
  __typename?: "VaultKyb";
  address: VaultAddress;
  customerFacingName: Scalars["String"];
  description: Scalars["String"];
  email?: Maybe<Scalars["String"]>;
  entityDocument?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  legalName: Scalars["String"];
  phoneNumber: Scalars["String"];
  structure: Kyb_Structure;
  taxId: Scalars["String"];
  type: Kyb_Type;
  url: Scalars["String"];
};

export type VaultKybCreateInput = {
  address: VaultAddressInput;
  customerFacingName: Scalars["String"];
  description: Scalars["String"];
  email?: InputMaybe<Scalars["String"]>;
  legalName: Scalars["String"];
  orgId: Scalars["ID"];
  phoneNumber: Scalars["String"];
  structure: Kyb_Structure;
  taxId: Scalars["String"];
  type: Kyb_Type;
  url: Scalars["String"];
};

export type VaultKybInput = {
  accountId: Scalars["ID"];
  financialAccountNodeId?: InputMaybe<Scalars["ID"]>;
  kybId: Scalars["ID"];
  orgId: Scalars["ID"];
};

export type VaultKybSuccessData = {
  __typename?: "VaultKybSuccessData";
  financialAccountId?: Maybe<Scalars["ID"]>;
  kybId: Scalars["ID"];
  metadata?: Maybe<StripeMetadata>;
  orgId?: Maybe<Scalars["ID"]>;
  stripeConnectAccountId?: Maybe<Scalars["String"]>;
};

/** Individuals */
export type VaultKyc = {
  __typename?: "VaultKyc";
  address: VaultAddress;
  director?: Maybe<Scalars["Boolean"]>;
  dob: VaultDob;
  email: Scalars["String"];
  executive?: Maybe<Scalars["Boolean"]>;
  firstName: Scalars["String"];
  id: Scalars["ID"];
  lastName: Scalars["String"];
  owner?: Maybe<Scalars["Boolean"]>;
  /** If owner=true, percentOwnership is required */
  percentOwnership?: Maybe<Scalars["Int"]>;
  phoneNumber: Scalars["String"];
  representative?: Maybe<Scalars["Boolean"]>;
  ssn?: Maybe<Scalars["String"]>;
  title?: Maybe<Scalars["String"]>;
};

export type VaultKycCreateInput = {
  address: VaultAddressInput;
  director?: InputMaybe<Scalars["Boolean"]>;
  dob: VaultDobInput;
  email: Scalars["String"];
  executive?: InputMaybe<Scalars["Boolean"]>;
  firstName: Scalars["String"];
  lastName: Scalars["String"];
  owner?: InputMaybe<Scalars["Boolean"]>;
  percentOwnership?: InputMaybe<Scalars["Int"]>;
  phoneNumber: Scalars["String"];
  representative?: InputMaybe<Scalars["Boolean"]>;
  ssn?: InputMaybe<Scalars["String"]>;
  title?: InputMaybe<Scalars["String"]>;
};

export type VaultKycSuccessData = {
  __typename?: "VaultKycSuccessData";
  kycId: Scalars["ID"];
  stripePersonId?: Maybe<Scalars["String"]>;
  userId?: Maybe<Scalars["ID"]>;
};

export type VaultMutationError = {
  __typename?: "VaultMutationError";
  message: Scalars["String"];
  type?: Maybe<Scalars["String"]>;
};

export type VaultMutationResponse = {
  __typename?: "VaultMutationResponse";
  errors?: Maybe<Array<Maybe<VaultMutationError>>>;
  success: Scalars["Boolean"];
};

export type VaultPayableOrgSuccessData = {
  __typename?: "VaultPayableOrgSuccessData";
  beneficialOwners?: Maybe<Array<VaultKycSuccessData>>;
  kybData: VaultKybSuccessData;
  representative: VaultKycSuccessData;
};

export enum VaultPayoutInterval {
  Daily = "daily",
  Manual = "manual",
  Monthly = "monthly",
  Weekly = "weekly",
}

export type VaultRepresentativeCreateInput = {
  kycData?: InputMaybe<VaultKycCreateInput>;
  kycId?: InputMaybe<Scalars["ID"]>;
  stripePersonId?: InputMaybe<Scalars["ID"]>;
  userId?: InputMaybe<Scalars["ID"]>;
};

export enum VaultRequiredDocument {
  /** Utility bill, bank statement, lease agreement or current pay stub */
  AddressVerification = "address_verification",
  /** Bank Statement or Void Check */
  BankAccountDocument = "bank_account_document",
  /** Certificate of incorporation or certificate of assumed name */
  CertificateOfIncorporation = "certificate_of_incorporation",
  /** IRS form 147c or IRS form CP-575 */
  EinConfirmation = "ein_confirmation",
  /** Passport or Drivers License */
  IdDocument = "id_document",
  /** Passport, Drivers License or State ID */
  IdDocumentOrPassport = "id_document_or_passport",
  /** 501C3 or SS4 */
  IrsDeterminationLetter = "irs_determination_letter",
  /** Passport */
  Passport = "passport",
  /** Power of attorney */
  PowerOfAttorney = "power_of_attorney",
  /** SSN Card */
  SsnCard = "ssn_card",
}

/** How to send a physical VaultCard */
export enum VaultShippingService {
  Express = "express",
  Priority = "priority",
  Standard = "standard",
}

/** The interval that the spending limit interval of a card is reset */
export enum VaultSpendingLimitInterval {
  AllTime = "all_time",
  Daily = "daily",
  Monthly = "monthly",
  PerAuthorization = "per_authorization",
  Weekly = "weekly",
  Yearly = "yearly",
}

export enum VaultStripeEnv {
  Raise = "RAISE",
  Sponsor = "SPONSOR",
}

/** ip and date of Stripe ToS acceptance */
export type VaultTosAcceptance = {
  date: Scalars["DateTime"];
  ip: Scalars["String"];
};

export enum VaultWeeklyAnchor {
  Friday = "friday",
  Monday = "monday",
  Saturday = "saturday",
  Sunday = "sunday",
  Thursday = "thursday",
  Tuesday = "tuesday",
  Wednesday = "wednesday",
}

export type Vehicle = {
  __typename?: "Vehicle";
  id: Scalars["Int"];
  is_deleted?: Maybe<Scalars["Boolean"]>;
  status?: Maybe<Scalars["String"]>;
  vehicle_type?: Maybe<Scalars["String"]>;
};

export enum Vendor {
  Marco = "MARCO",
  Raise = "RAISE",
  RaiseMarco = "RAISE_MARCO",
  Store = "STORE",
}

/** Input object for index query filters. */
export type Where = {
  and?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  between?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  eq?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  gt?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  gte?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  lt?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  lte?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  partition?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  startsWith?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export type WinbackDeals = {
  __typename?: "WinbackDeals";
  activity?: Maybe<Scalars["String"]>;
  amount?: Maybe<Scalars["String"]>;
  closedLost?: Maybe<Scalars["String"]>;
  dealName?: Maybe<Scalars["String"]>;
  dealStage?: Maybe<Scalars["String"]>;
  hubspotId?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  isWinbackPinned?: Maybe<Scalars["Boolean"]>;
  lastLaunch?: Maybe<Scalars["String"]>;
  leaderFirstName?: Maybe<Scalars["String"]>;
  leaderLastName?: Maybe<Scalars["String"]>;
  previousDealId?: Maybe<Scalars["String"]>;
  previousFundId?: Maybe<Scalars["String"]>;
};

export type Worker = {
  __typename?: "Worker";
  duty?: Maybe<Scalars["String"]>;
  email?: Maybe<Scalars["String"]>;
  event?: Maybe<Event>;
  event_id?: Maybe<Scalars["Int"]>;
  id: Scalars["Int"];
  organization?: Maybe<Scalars["String"]>;
  paid?: Maybe<Scalars["String"]>;
  pay_code?: Maybe<Scalars["String"]>;
  pay_rate?: Maybe<Scalars["Float"]>;
  salary?: Maybe<Scalars["Float"]>;
  ssn?: Maybe<Scalars["String"]>;
  woker_name?: Maybe<Scalars["String"]>;
  worker_end_time?: Maybe<Scalars["String"]>;
  worker_start_time?: Maybe<Scalars["String"]>;
  worker_type?: Maybe<Scalars["String"]>;
};

export type WorkerByIdInput = {
  id: Scalars["Int"];
};

export type WorkerDeleteManyCount = {
  __typename?: "WorkerDeleteManyCount";
  count?: Maybe<Scalars["Int"]>;
};

export type WorkerPool = {
  __typename?: "WorkerPool";
  Address?: Maybe<Scalars["String"]>;
  City?: Maybe<Scalars["String"]>;
  First?: Maybe<Scalars["String"]>;
  Home_Phone?: Maybe<Scalars["String"]>;
  ID: Scalars["Int"];
  Last?: Maybe<Scalars["String"]>;
  SSN?: Maybe<Scalars["String"]>;
  State?: Maybe<Scalars["String"]>;
  Work_Phone?: Maybe<Scalars["String"]>;
  Zip?: Maybe<Scalars["String"]>;
  cell_phone?: Maybe<Scalars["String"]>;
  email?: Maybe<Scalars["String"]>;
  is_deleted?: Maybe<Scalars["String"]>;
  pay_rate?: Maybe<Scalars["Float"]>;
  worker_type?: Maybe<Scalars["String"]>;
};

export enum WorkerTypes {
  C = "c",
  H = "h",
}

export type WorkerUpsertManyCount = {
  __typename?: "WorkerUpsertManyCount";
  count?: Maybe<Scalars["Int"]>;
};

export type ApprovedFunds = {
  __typename?: "approvedFunds";
  approvedFundsCents?: Maybe<Scalars["Int"]>;
  isPaidBack?: Maybe<Scalars["Boolean"]>;
};

export type BusSchedules = {
  __typename?: "busSchedules";
  activity?: Maybe<Scalars["String"]>;
  bus_count?: Maybe<Scalars["Int"]>;
  bus_departure_location?: Maybe<Scalars["String"]>;
  bus_early_dismissal_time?: Maybe<Scalars["String"]>;
  bus_estimated_return_time?: Maybe<Scalars["String"]>;
  bus_time?: Maybe<Scalars["String"]>;
  cancellation_status?: Maybe<Scalars["String"]>;
  comments?: Maybe<Scalars["String"]>;
  confirmed?: Maybe<Scalars["String"]>;
  end_time?: Maybe<Scalars["String"]>;
  event_date?: Maybe<Scalars["String"]>;
  event_transport_details?: Maybe<
    Array<Maybe<DailyCalendarEventTransportDetails>>
  >;
  facility?: Maybe<Scalars["String"]>;
  g_s?: Maybe<Scalars["String"]>;
  gender?: Maybe<Scalars["String"]>;
  home_field?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["Int"]>;
  levels?: Maybe<Scalars["String"]>;
  opponent?: Maybe<Scalars["String"]>;
  place?: Maybe<Scalars["String"]>;
  season_id?: Maybe<Scalars["Int"]>;
  sports_code?: Maybe<Scalars["String"]>;
  sports_description?: Maybe<Scalars["String"]>;
  sports_group?: Maybe<Scalars["String"]>;
  sports_name?: Maybe<Scalars["String"]>;
  start_time?: Maybe<Scalars["String"]>;
  vehicle_type?: Maybe<Scalars["String"]>;
};

export type CompleteFundraiserApprovalSubmissionDocumentUploadResult = {
  __typename?: "completeFundraiserApprovalSubmissionDocumentUploadResult";
  filename: Scalars["String"];
  s3FileName: Scalars["String"];
};

export type CreditMemoAmounts = {
  __typename?: "creditMemoAmounts";
  creditApplied?: Maybe<Scalars["Int"]>;
};

export enum DateRangeEnum {
  EighteenMonths = "eighteenMonths",
  SixMonths = "sixMonths",
  ThirtyDays = "thirtyDays",
  ThreeMonths = "threeMonths",
  TwelveMonths = "twelveMonths",
}

export type DriveGetListCurrentCustomersReturn = {
  __typename?: "driveGetListCurrentCustomersReturn";
  offset?: Maybe<Scalars["Int"]>;
  results?: Maybe<Array<Maybe<CurrentCustomersDeals>>>;
  totalCount?: Maybe<Scalars["Int"]>;
};

export type DriveGetListPotentialCustomersReturn = {
  __typename?: "driveGetListPotentialCustomersReturn";
  offset?: Maybe<Scalars["Int"]>;
  results?: Maybe<Array<Maybe<PotentialCustomersDeals>>>;
  totalCount?: Maybe<Scalars["Int"]>;
};

/** Last clicked tracking information used by Drive's Get List Application */
export type DriveOrgUserTrackingReturn = {
  __typename?: "driveOrgUserTrackingReturn";
  /** ID of the organization. */
  orgId?: Maybe<Scalars["String"]>;
  /** A unique tracking id */
  trackingId?: Maybe<Scalars["String"]>;
  trackingType?: Maybe<Scalars["String"]>;
  /** The value of the tracking type */
  trackingValue?: Maybe<Scalars["String"]>;
  trackingValueType?: Maybe<Scalars["String"]>;
  /** ID of the user. */
  userId?: Maybe<Scalars["String"]>;
};

export type EarlyAccess = {
  __typename?: "earlyAccess";
  activityType?: Maybe<Scalars["String"]>;
  earlyAccessApprovedFundsMax?: Maybe<Scalars["Int"]>;
  earlyAccessApprovedFundsMin?: Maybe<Scalars["Int"]>;
  isNationalRpk?: Maybe<Scalars["Boolean"]>;
  location?: Maybe<Scalars["String"]>;
  participantCount?: Maybe<Scalars["Int"]>;
  projectedRaisedMax?: Maybe<Scalars["Int"]>;
  projectedRaisedMin?: Maybe<Scalars["Int"]>;
};

export type EventContractItemInput = {
  event_id: Scalars["Int"];
};

export type InsightsUserOrgsNotificationPreferencesInput = {
  __typename?: "insightsUserOrgsNotificationPreferencesInput";
  userId?: Maybe<Scalars["String"]>;
};

export type InsightsUserOrgsNotificationPreferencesResponse = {
  __typename?: "insightsUserOrgsNotificationPreferencesResponse";
  errors?: Maybe<Array<Maybe<Scalars["String"]>>>;
  orgNotificationPreferences: Array<Maybe<OrgNotificationPreference>>;
  status: Scalars["String"];
  userNotificationPreferences: Array<Maybe<UserNotificationPreference>>;
};

export type OrgNotificationPreference = {
  __typename?: "orgNotificationPreference";
  id?: Maybe<Scalars["Int"]>;
  org_id?: Maybe<Scalars["String"]>;
};

export type ParticipantCampaignConfiguration = {
  __typename?: "participantCampaignConfiguration";
  autoImportEmails: ParticipantCampaignConfigStates;
  autoImportTexts: ParticipantCampaignConfigStates;
  campaignID: Scalars["String"];
  giftShop: ParticipantCampaignConfigStates;
  guardianSetup: ParticipantCampaignConfigStates;
  id: Scalars["ID"];
  profileSetup: ParticipantCampaignConfigStates;
  rewards: ParticipantCampaignConfigStates;
  updatedAt: Scalars["DateTime"];
  userID: Scalars["String"];
};

export enum Responses {
  Failed = "FAILED",
  Ok = "OK",
}

export type StoreBuildRequestInput = {
  campaignId?: InputMaybe<Scalars["Int"]>;
  comment?: InputMaybe<Scalars["String"]>;
  primaryColor: Scalars["String"];
  requester: StoreBuildRequester;
  secondaryColor: Scalars["String"];
  storeActivity: Scalars["String"];
  storeLogo: Scalars["String"];
  storeName: Scalars["String"];
};

export type StoreOrderSalesGraph = {
  __typename?: "storeOrderSalesGraph";
  baseTotalSales?: Maybe<Scalars["Float"]>;
  endDate?: Maybe<Scalars["String"]>;
  scopeId?: Maybe<Scalars["BigInt"]>;
  startDate?: Maybe<Scalars["String"]>;
  totalDiscountAmount?: Maybe<Scalars["Float"]>;
  totalOrders?: Maybe<Scalars["BigInt"]>;
  totalSales?: Maybe<Scalars["Float"]>;
};

export type StoreOrderSalesGraphUnionType = OnException | StoreOrderSalesGraphs;

export type TwispCardBalances = {
  __typename?: "twispCardBalances";
  allTxs?: Maybe<Array<Maybe<TwispTransactions>>>;
  authorization_balance?: Maybe<Scalars["Int64"]>;
  balance?: Maybe<Scalars["Int64"]>;
  card_id?: Maybe<Scalars["String"]>;
  card_limit?: Maybe<Scalars["Int64"]>;
  history?: Maybe<Array<Maybe<TwispCardBalances>>>;
  seq?: Maybe<Scalars["Uint64"]>;
  settled_balance?: Maybe<Scalars["Int64"]>;
  visibleTxs?: Maybe<Array<Maybe<TwispTransactions>>>;
};

export type TwispCardBalancesHistoryArgs = {
  limit?: InputMaybe<Scalars["Int32"]>;
  sort?: InputMaybe<Sort>;
};

/** Indexes for table twispCardBalances */
export enum TwispCardBalances_Indexes {
  CardId = "card_id",
}

export type TwispCards = {
  __typename?: "twispCards";
  card_id?: Maybe<Scalars["String"]>;
  history?: Maybe<Array<Maybe<TwispCards>>>;
  last4?: Maybe<Scalars["String"]>;
  seq?: Maybe<Scalars["Uint64"]>;
};

export type TwispCardsHistoryArgs = {
  limit?: InputMaybe<Scalars["Int32"]>;
  sort?: InputMaybe<Sort>;
};

/** Indexes for table twispCards */
export enum TwispCards_Indexes {
  CardId = "card_id",
}

export type TwispStripeHooks = {
  __typename?: "twispStripeHooks";
  account?: Maybe<Scalars["String"]>;
  api_version?: Maybe<Scalars["String"]>;
  created?: Maybe<Scalars["Int64"]>;
  data?: Maybe<Scalars["JSON"]>;
  history?: Maybe<Array<Maybe<TwispStripeHooks>>>;
  id?: Maybe<Scalars["String"]>;
  livemode?: Maybe<Scalars["Boolean"]>;
  object?: Maybe<Scalars["String"]>;
  pending_webhooks?: Maybe<Scalars["Int64"]>;
  processed?: Maybe<Scalars["Timestamp"]>;
  request?: Maybe<Scalars["JSON"]>;
  seq?: Maybe<Scalars["Uint64"]>;
  type?: Maybe<Scalars["String"]>;
};

export type TwispStripeHooksDataArgs = {
  expression?: InputMaybe<Scalars["String"]>;
};

export type TwispStripeHooksHistoryArgs = {
  limit?: InputMaybe<Scalars["Int32"]>;
  sort?: InputMaybe<Sort>;
};

export type TwispStripeHooksRequestArgs = {
  expression?: InputMaybe<Scalars["String"]>;
};

/** Indexes for table twispStripeHooks */
export enum TwispStripeHooks_Indexes {
  Id = "id",
}

export type TwispTransactions = {
  __typename?: "twispTransactions";
  amount?: Maybe<Scalars["Int64"]>;
  card_id?: Maybe<Scalars["String"]>;
  correlation?: Maybe<Scalars["String"]>;
  created?: Maybe<Scalars["Int64"]>;
  description?: Maybe<Scalars["String"]>;
  event_created?: Maybe<Scalars["Int64"]>;
  history?: Maybe<Array<Maybe<TwispTransactions>>>;
  id?: Maybe<Scalars["String"]>;
  last4?: Maybe<Scalars["String"]>;
  meta?: Maybe<Scalars["JSON"]>;
  seq?: Maybe<Scalars["Uint64"]>;
  status?: Maybe<Scalars["String"]>;
  visible?: Maybe<Scalars["Boolean"]>;
};

export type TwispTransactionsHistoryArgs = {
  limit?: InputMaybe<Scalars["Int32"]>;
  sort?: InputMaybe<Sort>;
};

export type TwispTransactionsMetaArgs = {
  expression?: InputMaybe<Scalars["String"]>;
};

/** Indexes for table twispTransactions */
export enum TwispTransactions_Indexes {
  CardId = "card_id",
  CardIdViz = "card_id_viz",
  Id = "id",
}

export type UserNotificationPreference = {
  __typename?: "userNotificationPreference";
  id?: Maybe<Scalars["Int"]>;
  name?: Maybe<Scalars["String"]>;
};

export type ApiStatusQueryVariables = Exact<{ [key: string]: never }>;

export type ApiStatusQuery = {
  __typename?: "Query";
  apiReady?: boolean | null;
};

export type SpendBudgetCreateMutationVariables = Exact<{
  input: SpendBudgetInput;
}>;

export type SpendBudgetCreateMutation = {
  __typename?: "Mutation";
  spendBudgetCreate?: {
    __typename?: "SpendBudgetID";
    id?: string | null;
  } | null;
};

export type SpendBudgetUpdateMutationVariables = Exact<{
  spendBudgetUpdateId: Scalars["String"];
  input: SpendBudgetInput;
}>;

export type SpendBudgetUpdateMutation = {
  __typename?: "Mutation";
  spendBudgetUpdate?: {
    __typename?: "SpendBudgetID";
    id?: string | null;
  } | null;
};

export type SpendBudgetDeleteMutationVariables = Exact<{
  spendBudgetDeleteId: Scalars["String"];
}>;

export type SpendBudgetDeleteMutation = {
  __typename?: "Mutation";
  spendBudgetDelete?: {
    __typename?: "SpendBudgetID";
    id?: string | null;
  } | null;
};

export type SpendTransactionBudgetUnreconcileMutationVariables = Exact<{
  input: SpendTransactionBudgetUnreconcileInput;
}>;

export type SpendTransactionBudgetUnreconcileMutation = {
  __typename?: "Mutation";
  spendTransactionBudgetUnreconcile?: {
    __typename?: "SpendReconcileTransactionID";
    id?: string | null;
  } | null;
};

export type SpendCategoryCreateMutationVariables = Exact<{
  input: SpendCategoryInput;
}>;

export type SpendCategoryCreateMutation = {
  __typename?: "Mutation";
  spendCategoryCreate?: {
    __typename?: "SpendCategoryID";
    id?: string | null;
  } | null;
};

export type SpendCategoryUpsertBulkMutationVariables = Exact<{
  input?: InputMaybe<
    | Array<InputMaybe<SpendUpsertCategoryInput>>
    | InputMaybe<SpendUpsertCategoryInput>
  >;
}>;

export type SpendCategoryUpsertBulkMutation = {
  __typename?: "Mutation";
  spendCategoryUpsertBulk?: Array<{
    __typename?: "SpendCategoryID";
    id?: string | null;
  } | null> | null;
};

export type SpendCreditMemoCreateMutationVariables = Exact<{
  input: SpendCreditMemoInput;
}>;

export type SpendCreditMemoCreateMutation = {
  __typename?: "Mutation";
  spendCreditMemoCreate?: {
    __typename?: "SpendCreditMemoCreateResponse";
    ids: Array<string>;
  } | null;
};

export type SpendCreditMemoUpdateMutationVariables = Exact<{
  input: SpendCreditMemoUpdateInput;
}>;

export type SpendCreditMemoUpdateMutation = {
  __typename?: "Mutation";
  spendCreditMemoUpdate?: {
    __typename?: "SpendCreditMemoUpdateResponse";
    id: string;
  } | null;
};

export type SpendGroupArchiveMutationVariables = Exact<{
  spendGroupArchiveId: Scalars["String"];
}>;

export type SpendGroupArchiveMutation = {
  __typename?: "Mutation";
  spendGroupArchive?: {
    __typename?: "SpendGroupID";
    id?: string | null;
  } | null;
};

export type SpendGroupCreateMutationVariables = Exact<{
  input: SpendGroupInput;
}>;

export type SpendGroupCreateMutation = {
  __typename?: "Mutation";
  spendGroupCreate?: { __typename?: "SpendGroupID"; id?: string | null } | null;
};

export type SpendGroupRostersCreateMutationVariables = Exact<{
  input: SpendGroupRostersInput;
}>;

export type SpendGroupRostersCreateMutation = {
  __typename?: "Mutation";
  spendGroupRostersCreate?: Array<{
    __typename?: "SpendGroupRosterID";
    id?: string | null;
  } | null> | null;
};

export type SpendGroupDuplicateMutationVariables = Exact<{
  spendGroupDuplicateId: Scalars["String"];
}>;

export type SpendGroupDuplicateMutation = {
  __typename?: "Mutation";
  spendGroupDuplicate?: {
    __typename?: "SpendGroupID";
    id?: string | null;
  } | null;
};

export type SpendSeasonCreateMutationVariables = Exact<{
  input: SpendSeasonInput;
}>;

export type SpendSeasonCreateMutation = {
  __typename?: "Mutation";
  spendSeasonCreate?: {
    __typename?: "SpendSeasonID";
    id?: string | null;
  } | null;
};

export type SpendGroupAndSeasonUpdateMutationVariables = Exact<{
  spendGroupInput: SpendGroupInput;
  spendGroupUpdateId?: InputMaybe<Scalars["String"]>;
  spendSeasonUpdateId: Scalars["String"];
  spendSeasonInput: SpendSeasonInput;
}>;

export type SpendGroupAndSeasonUpdateMutation = {
  __typename?: "Mutation";
  spendGroupUpdate?: { __typename?: "SpendGroupID"; id?: string | null } | null;
  spendSeasonUpdate?: {
    __typename?: "SpendSeasonID";
    id?: string | null;
  } | null;
};

export type SpendGroupDebitCardCreateMutationVariables = Exact<{
  input: SpendDebitCardInput;
  id?: InputMaybe<Scalars["String"]>;
}>;

export type SpendGroupDebitCardCreateMutation = {
  __typename?: "Mutation";
  spendGroupDebitCardCreate?: {
    __typename?: "SpendDebitCardID";
    id?: string | null;
  } | null;
};

export type SpendGroupExternalTransferMutationVariables = Exact<{
  input: SpendGroupExternalTransferInput;
}>;

export type SpendGroupExternalTransferMutation = {
  __typename?: "Mutation";
  spendGroupExternalTransfer?: {
    __typename?: "SpendExternalTransferResponse";
    amount?: number | null;
    descriptor?: string | null;
    id?: string | null;
    status?: string | null;
  } | null;
};

export type SpendPaymentMethodCreateMutationVariables = Exact<{
  input: SpendPaymentMethodInput;
}>;

export type SpendPaymentMethodCreateMutation = {
  __typename?: "Mutation";
  spendPaymentMethodCreate?: {
    __typename?: "SpendPaymentMethodResponse";
    updatedInvoiceIds: Array<string | null>;
    payment?: {
      __typename?: "SpendPayNowResponse";
      status?: string | null;
      paymentId?: string | null;
    } | null;
    groupRosterSetting?: {
      __typename?: "SpendGroupRosterSettingsResponse";
      settingsId?: string | null;
      groupRosterId?: string | null;
    } | null;
  } | null;
};

export type SpendSeasonUpdateMutationVariables = Exact<{
  id: Scalars["String"];
  input: SpendSeasonInput;
}>;

export type SpendSeasonUpdateMutation = {
  __typename?: "Mutation";
  spendSeasonUpdate?: {
    __typename?: "SpendSeasonID";
    id?: string | null;
  } | null;
};

export type SpendGroupCheckDepositPatchMutationVariables = Exact<{
  input: SpendGroupCheckDepositTagsInput;
}>;

export type SpendGroupCheckDepositPatchMutation = {
  __typename?: "Mutation";
  spendGroupCheckDepositPatch?: {
    __typename?: "SpendCheckDepositTagsResponse";
    success: boolean;
  } | null;
};

export type SpendUserLeaveGroupMutationVariables = Exact<{
  groupId: Scalars["String"];
}>;

export type SpendUserLeaveGroupMutation = {
  __typename?: "Mutation";
  spendUserLeaveGroup?: {
    __typename?: "SpendUpdateResponse";
    success?: boolean | null;
  } | null;
};

export type SpendArchiveSeasonMembersMutationVariables = Exact<{
  input: SpendArchiveSeasonMembersInput;
}>;

export type SpendArchiveSeasonMembersMutation = {
  __typename?: "Mutation";
  spendArchiveSeasonMembers?: {
    __typename?: "SpendArchiveSeasonMembersResponse";
    success: boolean;
  } | null;
};

export type SpendRemoveParticipantsMutationVariables = Exact<{
  input: SpendRemoveSeasonMemberInput;
}>;

export type SpendRemoveParticipantsMutation = {
  __typename?: "Mutation";
  spendRemoveParticipants?: {
    __typename?: "SpendRemoveSeasonMemberResponse";
    success: boolean;
  } | null;
};

export type SpendGroupPayeeCreateMutationVariables = Exact<{
  input: SpendPayeeInput;
}>;

export type SpendGroupPayeeCreateMutation = {
  __typename?: "Mutation";
  spendGroupPayeeCreate?: {
    __typename?: "SpendPayeeCreateResponse";
    payeeId?: string | null;
  } | null;
};

export type SpendGroupCheckSendMutationVariables = Exact<{
  input: SpendCheckInput;
}>;

export type SpendGroupCheckSendMutation = {
  __typename?: "Mutation";
  spendGroupCheckSend?: {
    __typename?: "SpendCheckSendResponse";
    id?: string | null;
    type?: string | null;
  } | null;
};

export type SpendGroupCheckCancelMutationVariables = Exact<{
  checkId: Scalars["String"];
}>;

export type SpendGroupCheckCancelMutation = {
  __typename?: "Mutation";
  spendGroupCheckCancel?: {
    __typename?: "SpendCancelCheckID";
    id: string;
  } | null;
};

export type SpendGroupPayeeUpdateMutationVariables = Exact<{
  input: SpendPayeeUpdateInput;
}>;

export type SpendGroupPayeeUpdateMutation = {
  __typename?: "Mutation";
  spendGroupPayeeUpdate?: {
    __typename?: "SpendPayeeCreateResponse";
    payeeId?: string | null;
  } | null;
};

export type SpendUpdateGroupRosterMutationVariables = Exact<{
  input: SpendRosterUserUpdate;
}>;

export type SpendUpdateGroupRosterMutation = {
  __typename?: "Mutation";
  spendGroupRosterUpdate?: {
    __typename?: "SpendRosterUpdate";
    id?: string | null;
    invitesUpdated?: Array<string> | null;
  } | null;
};

export type SpendGroupCounterpartyDeleteMutationVariables = Exact<{
  counterpartyId: Scalars["String"];
  groupId: Scalars["String"];
}>;

export type SpendGroupCounterpartyDeleteMutation = {
  __typename?: "Mutation";
  spendGroupCounterpartyDelete?: {
    __typename?: "SpendDeleteCounterpartyResponse";
    success: boolean;
  } | null;
};

export type SpendGroupAchCreditMutationVariables = Exact<{
  input?: InputMaybe<SpendGroupAchCredit>;
}>;

export type SpendGroupAchCreditMutation = {
  __typename?: "Mutation";
  spendGroupACHCredit?: {
    __typename?: "SpendACHCreditResponse";
    paymentId: string;
  } | null;
};

export type SpendUserInviteCreateMutationVariables = Exact<{
  input: SpendInviteInput;
}>;

export type SpendUserInviteCreateMutation = {
  __typename?: "Mutation";
  spendUserInviteCreate?: {
    __typename?: "SpendInviteID";
    id?: string | null;
  } | null;
};

export type SpendUserInviteResendMutationVariables = Exact<{
  input: SpendInviteResendInput;
}>;

export type SpendUserInviteResendMutation = {
  __typename?: "Mutation";
  spendUserInviteResend?: {
    __typename?: "SpendInviteID";
    id?: string | null;
  } | null;
};

export type SpendUserInviteUpdateMutationVariables = Exact<{
  spendUserInviteUpdateId: Scalars["String"];
}>;

export type SpendUserInviteUpdateMutation = {
  __typename?: "Mutation";
  spendUserInviteUpdate?: {
    __typename?: "SpendInviteID";
    id?: string | null;
  } | null;
};

export type SpendUserInviteDeleteMutationVariables = Exact<{
  spendUserInviteDeleteId: Scalars["String"];
}>;

export type SpendUserInviteDeleteMutation = {
  __typename?: "Mutation";
  spendUserInviteDelete?: {
    __typename?: "SpendInviteID";
    id?: string | null;
  } | null;
};

export type SpendUserInviteArchiveMutationVariables = Exact<{
  spendUserInviteArchiveId: Scalars["String"];
}>;

export type SpendUserInviteArchiveMutation = {
  __typename?: "Mutation";
  spendUserInviteArchive?: {
    __typename?: "SpendInviteID";
    id?: string | null;
  } | null;
};

export type SpendUserInviteStatusUpdateMutationVariables = Exact<{
  spendUserInviteStatusUpdateId: Scalars["String"];
  status: Scalars["String"];
}>;

export type SpendUserInviteStatusUpdateMutation = {
  __typename?: "Mutation";
  spendUserInviteStatusUpdate?: {
    __typename?: "SpendInviteID";
    id?: string | null;
  } | null;
};

export type SpendUserInviteDismissMutationVariables = Exact<{
  inviteId: Scalars["String"];
}>;

export type SpendUserInviteDismissMutation = {
  __typename?: "Mutation";
  spendUserInviteDismiss?: {
    __typename?: "SpendDismissInviteResponse";
    inviteId: string;
  } | null;
};

export type SpendInvoiceCreateMutationVariables = Exact<{
  input: SpendInvoiceInput;
}>;

export type SpendInvoiceCreateMutation = {
  __typename?: "Mutation";
  spendInvoiceCreate?: {
    __typename?: "SpendInvoiceID";
    id?: string | null;
  } | null;
};

export type SpendInvoiceReminderCreateMutationVariables = Exact<{
  input: SpendInvoiceReminderInput;
}>;

export type SpendInvoiceReminderCreateMutation = {
  __typename?: "Mutation";
  spendInvoiceReminderCreate?: {
    __typename?: "SpendNotificationID";
    id?: string | null;
  } | null;
};

export type SpendInvoiceUpdateMutationVariables = Exact<{
  spendInvoiceUpdateId: Scalars["String"];
  input: SpendInvoiceInput;
}>;

export type SpendInvoiceUpdateMutation = {
  __typename?: "Mutation";
  spendInvoiceUpdate?: {
    __typename?: "SpendInvoiceID";
    id?: string | null;
  } | null;
};

export type SpendInvoiceOptInOrOutMutationVariables = Exact<{
  input: SpendOptInOrOutInput;
}>;

export type SpendInvoiceOptInOrOutMutation = {
  __typename?: "Mutation";
  spendInvoiceOptInOrOut?: {
    __typename?: "SpendInvoiceID";
    id?: string | null;
  } | null;
};

export type SpendInvoicePaymentDeauthorizeMutationVariables = Exact<{
  input: SpendInvoicePaymentDeauthorizeInput;
}>;

export type SpendInvoicePaymentDeauthorizeMutation = {
  __typename?: "Mutation";
  spendInvoicePaymentDeauthorize?: {
    __typename?: "SpendDeauthorizeResponse";
    id?: string | null;
    notifSuccess?: boolean | null;
  } | null;
};

export type SpendInvoiceChangeRequestMutationVariables = Exact<{
  input: SpendInvoiceRequestChangeInput;
}>;

export type SpendInvoiceChangeRequestMutation = {
  __typename?: "Mutation";
  spendInvoiceChangeRequest?: {
    __typename?: "SpendInvoiceID";
    id?: string | null;
  } | null;
};

export type SpendInvoiceUpdatePaymentMethodMutationVariables = Exact<{
  input: SpendInvoicePaymentMethodUpdate;
}>;

export type SpendInvoiceUpdatePaymentMethodMutation = {
  __typename?: "Mutation";
  spendInvoiceUpdatePaymentMethod?: {
    __typename?: "SpendInvoiceIDs";
    ids?: Array<string | null> | null;
  } | null;
};

export type SpendInvoiceRefundMutationVariables = Exact<{
  input: SpendInvoiceRefundInput;
}>;

export type SpendInvoiceRefundMutation = {
  __typename?: "Mutation";
  spendInvoiceRefund?: {
    __typename?: "SpendInvoiceRefundResponse";
    invoiceId?: string | null;
    spendTransactionId?: string | null;
  } | null;
};

export type SpendPaymentScheduleArchiveMutationVariables = Exact<{
  spendPaymentScheduleUpdateId: Scalars["String"];
  input: SpendPaymentScheduleInput;
  rosterIds?: InputMaybe<
    Array<InputMaybe<Scalars["String"]>> | InputMaybe<Scalars["String"]>
  >;
}>;

export type SpendPaymentScheduleArchiveMutation = {
  __typename?: "Mutation";
  spendPaymentScheduleUpdate?: {
    __typename?: "SpendPaymentScheduleID";
    id?: string | null;
  } | null;
};

export type SpendPaymentScheduleRevertMutationVariables = Exact<{
  spendPaymentScheduleRevertId: Scalars["String"];
}>;

export type SpendPaymentScheduleRevertMutation = {
  __typename?: "Mutation";
  spendPaymentScheduleRevert?: {
    __typename?: "SpendPaymentScheduleID";
    id?: string | null;
  } | null;
};

export type SpendPaymentScheduleRevertManyMutationVariables = Exact<{
  spendPaymentScheduleRevertIds: Array<Scalars["String"]> | Scalars["String"];
}>;

export type SpendPaymentScheduleRevertManyMutation = {
  __typename?: "Mutation";
  spendPaymentScheduleRevertMany?: Array<{
    __typename?: "SpendPaymentScheduleID";
    id?: string | null;
  } | null> | null;
};

export type SpendTransactionInvoiceUnreconcileMutationVariables = Exact<{
  input: SpendTransactionInvoiceUnreconcileInput;
}>;

export type SpendTransactionInvoiceUnreconcileMutation = {
  __typename?: "Mutation";
  spendTransactionInvoiceUnreconcile?: {
    __typename?: "SpendReconcileTransactionID";
    id?: string | null;
  } | null;
};

export type SpendInvoiceArchiveMutationVariables = Exact<{
  spendInvoiceArchiveId: Scalars["String"];
}>;

export type SpendInvoiceArchiveMutation = {
  __typename?: "Mutation";
  spendInvoiceArchive?: {
    __typename?: "SpendInvoiceID";
    id?: string | null;
  } | null;
};

export type SpendAuthorizeAutoPayMutationVariables = Exact<{
  input?: InputMaybe<SpendAutoPayInput>;
}>;

export type SpendAuthorizeAutoPayMutation = {
  __typename?: "Mutation";
  spendAuthorizeAutoPay?: {
    __typename?: "SpendAuthorizeAutoPayResponse";
    invoiceIds?: Array<string> | null;
  } | null;
};

export type SpendNotificationCreateMutationVariables = Exact<{
  input: SpendNotificationInput;
}>;

export type SpendNotificationCreateMutation = {
  __typename?: "Mutation";
  spendNotificationCreate?: {
    __typename?: "SpendNotificationStatus";
    status?: string | null;
  } | null;
};

export type SpendPaymentScheduleCreateMutationVariables = Exact<{
  input: SpendPaymentScheduleInput;
}>;

export type SpendPaymentScheduleCreateMutation = {
  __typename?: "Mutation";
  spendPaymentScheduleCreate?: {
    __typename?: "SpendPaymentScheduleID";
    id?: string | null;
  } | null;
};

export type SpendPaymentScheduleUpdateMutationVariables = Exact<{
  spendPaymentScheduleUpdateId: Scalars["String"];
  input: SpendPaymentScheduleInput;
}>;

export type SpendPaymentScheduleUpdateMutation = {
  __typename?: "Mutation";
  spendPaymentScheduleUpdate?: {
    __typename?: "SpendPaymentScheduleID";
    id?: string | null;
  } | null;
};

export type SpendUserAchPaymentMutationVariables = Exact<{
  input: SpendAchPaymentInput;
}>;

export type SpendUserAchPaymentMutation = {
  __typename?: "Mutation";
  spendUserAchPayment?: {
    __typename?: "SpendAchPaymentResponse";
    id: string;
    status: string;
    amount: number;
    descriptor: string;
    transactionIdList?: Array<string | null> | null;
  } | null;
};

export type SpendUserAchPaymentCancelMutationVariables = Exact<{
  input: SpendAchPaymentCancelInput;
}>;

export type SpendUserAchPaymentCancelMutation = {
  __typename?: "Mutation";
  spendUserAchPaymentCancel?: {
    __typename?: "SpendAchPaymentID";
    id?: string | null;
  } | null;
};

export type DeleteCardPaymentMutationVariables = Exact<{
  input?: InputMaybe<PaymentsApiDetachPaymentMethodInput>;
}>;

export type DeleteCardPaymentMutation = {
  __typename?: "Mutation";
  paymentsApiDetachPaymentMethod?: {
    __typename?: "PaymentsApiDetachPaymentMethodResponse";
    paymentMethodId: string;
  } | null;
};

export type PaymentsApiDetachPaymentMethodMutationVariables = Exact<{
  input?: InputMaybe<PaymentsApiDetachPaymentMethodInput>;
}>;

export type PaymentsApiDetachPaymentMethodMutation = {
  __typename?: "Mutation";
  paymentsApiDetachPaymentMethod?: {
    __typename?: "PaymentsApiDetachPaymentMethodResponse";
    stripeEnv: StripeEnv;
    paymentMethodId: string;
  } | null;
};

export type PaymentsApiCustomerMutationVariables = Exact<{
  [key: string]: never;
}>;

export type PaymentsApiCustomerMutation = {
  __typename?: "Mutation";
  paymentsApiCustomer: {
    __typename?: "PaymentsApiCustomerResponse";
    customerId?: string | null;
    email: string;
    name?: string | null;
    paymentMethods: Array<{
      __typename?: "PaymentsApiCustomerPaymentMethod";
      brand?: string | null;
      id: string;
      identifier: string;
      type: PaymentsApiCustomerPaymentMethodType;
      zipCode?: string | null;
      billingAddress?: {
        __typename?: "PaymentsApiPMAddress";
        city?: string | null;
        country?: string | null;
        line1?: string | null;
        line2?: string | null;
        postalCode?: string | null;
        state?: string | null;
      } | null;
      expiration?: {
        __typename?: "PaymentsApiPMExpiration";
        month: number;
        year: number;
      } | null;
    } | null>;
  };
};

export type SpendUserBankAccessTokenCreateMutationVariables = Exact<{
  publicToken: Scalars["String"];
  groupId: Scalars["String"];
  status?: InputMaybe<Scalars["String"]>;
}>;

export type SpendUserBankAccessTokenCreateMutation = {
  __typename?: "Mutation";
  spendUserBankAccessTokenCreate?: {
    __typename?: "SpendBankAccessCreateResponse";
    id?: string | null;
  } | null;
};

export type SpendGroupBankAccessTokenCreateMutationVariables = Exact<{
  publicToken: Scalars["String"];
  spendGroupBankAccessTokenCreateId?: InputMaybe<Scalars["String"]>;
  status?: InputMaybe<Scalars["String"]>;
}>;

export type SpendGroupBankAccessTokenCreateMutation = {
  __typename?: "Mutation";
  spendGroupBankAccessTokenCreate?: {
    __typename?: "SpendBankAccessCreateResponse";
    id?: string | null;
  } | null;
};

export type SpendOrganizationBankAccessTokenCreateMutationVariables = Exact<{
  publicToken: Scalars["String"];
  status?: InputMaybe<Scalars["String"]>;
}>;

export type SpendOrganizationBankAccessTokenCreateMutation = {
  __typename?: "Mutation";
  spendOrganizationBankAccessTokenCreate?: {
    __typename?: "SpendBankAccessCreateResponse";
    id?: string | null;
  } | null;
};

export type SpendUserBankAccessTokenDeleteMutationVariables = Exact<{
  [key: string]: never;
}>;

export type SpendUserBankAccessTokenDeleteMutation = {
  __typename?: "Mutation";
  spendUserBankAccessTokenDelete?: {
    __typename?: "SpendBankAccessDeleteResponse";
    success?: boolean | null;
  } | null;
};

export type SpendGroupBankAccessTokenDeleteMutationVariables = Exact<{
  spendGroupBankAccessTokenDeleteId?: InputMaybe<Scalars["String"]>;
}>;

export type SpendGroupBankAccessTokenDeleteMutation = {
  __typename?: "Mutation";
  spendGroupBankAccessTokenDelete?: {
    __typename?: "SpendBankAccessDeleteResponse";
    success?: boolean | null;
  } | null;
};

export type SpendOrganizationBankAccessTokenDeleteMutationVariables = Exact<{
  [key: string]: never;
}>;

export type SpendOrganizationBankAccessTokenDeleteMutation = {
  __typename?: "Mutation";
  spendOrganizationBankAccessTokenDelete?: {
    __typename?: "SpendBankAccessDeleteResponse";
    success?: boolean | null;
  } | null;
};

export type SpendRoleChangeMutationVariables = Exact<{
  roleId: Scalars["String"];
  sessionId: Scalars["String"];
}>;

export type SpendRoleChangeMutation = {
  __typename?: "Mutation";
  spendRoleChange?: { __typename?: "SpendRoleID"; id?: string | null } | null;
};

export type SpendUserRoleUpdateMutationVariables = Exact<{
  groupId?: InputMaybe<Scalars["String"]>;
  roleName: SpendRoleNameEnum;
  userId: Scalars["String"];
}>;

export type SpendUserRoleUpdateMutation = {
  __typename?: "Mutation";
  spendUserRoleUpdate?: {
    __typename?: "SpendUserRoleID";
    id?: string | null;
  } | null;
};

export type SpendUserRoleSetMutationVariables = Exact<{
  roleId: Scalars["String"];
}>;

export type SpendUserRoleSetMutation = {
  __typename?: "Mutation";
  spendUserRoleSet?: {
    __typename?: "SpendUserRoleID";
    id?: string | null;
  } | null;
};

export type SpendUserRoleUpdateIsApproverMutationVariables = Exact<{
  input?: InputMaybe<SpendIsApproverUpdateInput>;
}>;

export type SpendUserRoleUpdateIsApproverMutation = {
  __typename?: "Mutation";
  spendUserRoleUpdateIsApprover?: {
    __typename?: "SpendUserRoleID";
    id?: string | null;
  } | null;
};

export type SpendSessionCreateMutationVariables = Exact<{
  inviteId?: InputMaybe<Scalars["String"]>;
}>;

export type SpendSessionCreateMutation = {
  __typename?: "Mutation";
  spendSessionCreate?: {
    __typename?: "SpendSession";
    url?: string | null;
    status?: string | null;
    expiresAt?: string | null;
    message?: string | null;
    id?: string | null;
    userId?: string | null;
    inviteId?: string | null;
    isDismissed?: boolean | null;
    role?: {
      __typename?: "SpendRole";
      groupId?: string | null;
      id: string;
      name: string;
      organizationId?: string | null;
      permissions: Array<string | null>;
      isArchived?: boolean | null;
      seasonId?: string | null;
    } | null;
    pendingInvites?: Array<{
      __typename?: "SpendPendingInvite";
      id: string;
      type: string;
      groupId?: string | null;
      groupName?: string | null;
      rosterName?: string | null;
      seasonName?: string | null;
      organizationId: string;
      organizationName: string;
      expiresAt: string;
      status?: string | null;
      isDismissed: boolean;
    }> | null;
  } | null;
};

export type SpendSessionDeleteMutationVariables = Exact<{
  [key: string]: never;
}>;

export type SpendSessionDeleteMutation = {
  __typename?: "Mutation";
  spendSessionDelete?: {
    __typename?: "SpendSessionID";
    id?: string | null;
  } | null;
};

export type SpendSessionRefreshMutationVariables = Exact<{
  sessionId: Scalars["String"];
}>;

export type SpendSessionRefreshMutation = {
  __typename?: "Mutation";
  spendSessionRefresh?: {
    __typename?: "SpendSessionID";
    id?: string | null;
  } | null;
};

export type MutationMutationVariables = Exact<{ [key: string]: never }>;

export type MutationMutation = {
  __typename?: "Mutation";
  logout?: boolean | null;
};

export type SpendUnitApplicationMutationVariables = Exact<{
  [key: string]: never;
}>;

export type SpendUnitApplicationMutation = {
  __typename?: "Mutation";
  spendUnitApplication?: {
    __typename?: "SpendUnitApplicationResponse";
    url?: string | null;
  } | null;
};

export type SpendUserSignupUpdateMutationVariables = Exact<{
  input?: InputMaybe<SpendUserSignupInput>;
}>;

export type SpendUserSignupUpdateMutation = {
  __typename?: "Mutation";
  spendUserSignupUpdate?: {
    __typename?: "SpendUserRoleID";
    id?: string | null;
  } | null;
};

export type SpendTransactionsCreateMutationVariables = Exact<{
  input: SpendTransactionInput;
}>;

export type SpendTransactionsCreateMutation = {
  __typename?: "Mutation";
  spendTransactionsCreate?: {
    __typename?: "SpendTransactionsIdList";
    transactionIdList?: Array<string | null> | null;
  } | null;
};

export type SpendTransactionReconcileMutationVariables = Exact<{
  input: SpendTransactionReconcileInput;
}>;

export type SpendTransactionReconcileMutation = {
  __typename?: "Mutation";
  spendTransactionReconcile?: {
    __typename?: "SpendReconcileTransactionID";
    id?: string | null;
  } | null;
};

export type SpendTransactionReconcileV2MutationVariables = Exact<{
  input: SpendTransactionReconcileV2Input;
}>;

export type SpendTransactionReconcileV2Mutation = {
  __typename?: "Mutation";
  spendTransactionReconcileV2?: {
    __typename?: "SpendReconcileTransactionID";
    id?: string | null;
  } | null;
};

export type SpendTransactionNoteCreateMutationVariables = Exact<{
  input: SpendTranscationNoteInput;
}>;

export type SpendTransactionNoteCreateMutation = {
  __typename?: "Mutation";
  spendTransactionNoteCreate?: {
    __typename?: "SpendTransactionNoteID";
    id?: string | null;
  } | null;
};

export type SpendTransactionNoteUpdateMutationVariables = Exact<{
  spendTransactionNoteUpdateId: Scalars["String"];
  input: SpendTranscationNoteInput;
}>;

export type SpendTransactionNoteUpdateMutation = {
  __typename?: "Mutation";
  spendTransactionNoteUpdate?: {
    __typename?: "SpendTransactionNoteID";
    id?: string | null;
  } | null;
};

export type SpendTransactionAttachmentCreateMutationVariables = Exact<{
  input: SpendTransactionAttachmentInput;
}>;

export type SpendTransactionAttachmentCreateMutation = {
  __typename?: "Mutation";
  spendTransactionAttachmentCreate?: {
    __typename?: "SpendTransactionAttachmentID";
    id?: string | null;
  } | null;
};

export type SpendTransactionAttachmentDeleteMutationVariables = Exact<{
  id: Scalars["String"];
}>;

export type SpendTransactionAttachmentDeleteMutation = {
  __typename?: "Mutation";
  spendTransactionAttachmentDelete?: {
    __typename?: "SpendTransactionAttachmentID";
    id?: string | null;
  } | null;
};

export type UserImpersonateLogoutMutationVariables = Exact<{
  [key: string]: never;
}>;

export type UserImpersonateLogoutMutation = {
  __typename?: "Mutation";
  userImpersonateLogout?: {
    __typename?: "Auth";
    accessToken?: string | null;
    refreshToken?: string | null;
  } | null;
};

export type SpendUserNotificationSettingUpdateMutationVariables = Exact<{
  input?: InputMaybe<SpendUserNotificationSettingInput>;
}>;

export type SpendUserNotificationSettingUpdateMutation = {
  __typename?: "Mutation";
  spendUserNotificationSettingUpdate?: {
    __typename?: "SpendUserNotificationSettingID";
    id?: string | null;
  } | null;
};

export type SpendPaymentsCardDetachMutationVariables = Exact<{
  input?: InputMaybe<SpendPaymentMethodDetach>;
}>;

export type SpendPaymentsCardDetachMutation = {
  __typename?: "Mutation";
  spendUserPaymentsCardDetach?: {
    __typename?: "SpendPaymentMethodDetachResponse";
    paymentMethodId?: string | null;
  } | null;
};

export type SpendBudgetsQueryVariables = Exact<{ [key: string]: never }>;

export type SpendBudgetsQuery = {
  __typename?: "Query";
  spendBudgets?: {
    __typename?: "SpendBudgetsResponse";
    count?: number | null;
    budgets?: Array<{
      __typename?: "SpendBudget";
      createdAt: string;
      description: string;
      id: string;
      isDefault?: boolean | null;
      targetAmount: number;
      targetDateAt: string;
      updatedAt?: string | null;
      vaultId?: string | null;
      category: {
        __typename?: "SpendCategory";
        createdAt: string;
        id?: string | null;
        isDefault: boolean;
        isHidden: boolean;
        name: string;
        organizationId: string;
        type: string;
        updatedAt?: string | null;
      };
      invoices?: Array<{
        __typename?: "SpendInvoice";
        amount?: number | null;
        balanceDue?: number | null;
        budgetItemId?: string | null;
        createdAt?: string | null;
        description?: string | null;
        dueDate?: string | null;
        groupRosterId?: string | null;
        id?: string | null;
        isOptional?: boolean | null;
        lastNotifyDate?: string | null;
        lastNotifyId?: string | null;
        notificationAttempts?: number | null;
        optedIn?: boolean | null;
        paid?: boolean | null;
        paidDate?: string | null;
        paymentScheduleInvoiceId?: string | null;
        updatedAt?: string | null;
      }> | null;
      season?: {
        __typename?: "SpendSeason";
        id?: string | null;
        name?: string | null;
      } | null;
    } | null> | null;
  } | null;
};

export type GroupBudgetSummaryQueryVariables = Exact<{
  groupId?: InputMaybe<Scalars["String"]>;
  filterBy?: InputMaybe<TransactionFilterEnum>;
  filterValue?: InputMaybe<Scalars["String"]>;
}>;

export type GroupBudgetSummaryQuery = {
  __typename?: "Query";
  spendCategories?: {
    __typename?: "SpendCategoryResponse";
    count?: number | null;
    categories?: Array<{
      __typename?: "SpendCategory";
      id?: string | null;
      name: string;
      type: string;
      budgets?: Array<{
        __typename?: "SpendBudget";
        targetAmount: number;
        invoices?: Array<{
          __typename?: "SpendInvoice";
          id?: string | null;
          amount?: number | null;
        }> | null;
      } | null> | null;
    } | null> | null;
  } | null;
  spendTransactions?: {
    __typename?: "SpendBankTransactionsResponse";
    count?: number | null;
    transactions?: Array<{
      __typename?: "SpendBankTransaction";
      amount?: number | null;
      snapAmount?: number | null;
      metadata?: {
        __typename?: "SpendBankTransactionMetaData";
        source?: string | null;
        destination?: string | null;
      } | null;
      reconciliation?: {
        __typename?: "SpendReconciledTransaction";
        id?: string | null;
      } | null;
    } | null> | null;
  } | null;
};

export type SpendBudgetsSummaryQueryVariables = Exact<{
  groupId: Scalars["String"];
  seasonId: Scalars["String"];
}>;

export type SpendBudgetsSummaryQuery = {
  __typename?: "Query";
  spendBudgetsSummary?: {
    __typename?: "SpendBudgetSummaryResponse";
    summaryByCategory?: Array<{
      __typename?: "SpendCategory";
      id?: string | null;
      name: string;
      type: string;
      budgets?: Array<{
        __typename?: "SpendBudget";
        id: string;
        targetAmount: number;
        targetDateAt: string;
        description: string;
        reconciledBudgetTotal?: number | null;
        reconciledInvoicesTotal?: number | null;
        reconciledTotal?: number | null;
        invoices?: Array<{
          __typename?: "SpendInvoice";
          id?: string | null;
          amount?: number | null;
        }> | null;
        reconciledTransactions?: Array<{
          __typename?: "SpendReconciledBudgetTransaction";
          amount?: number | null;
          budgetItemId?: string | null;
          id?: string | null;
          transactionId?: string | null;
          reconciledTransaction?: {
            __typename?: "SpendReconciledTransaction";
            amount?: number | null;
            id?: string | null;
            paymentId?: string | null;
            updatedAt?: string | null;
            createdAt?: string | null;
            type?: string | null;
          } | null;
        }> | null;
      } | null> | null;
    } | null> | null;
    summaryUnreconciled?: {
      __typename?: "SpendUnreconciledSummary";
      debits?: {
        __typename?: "TransactionTotalCount";
        count?: number | null;
        total?: number | null;
      } | null;
      credits?: {
        __typename?: "TransactionTotalCount";
        count?: number | null;
        total?: number | null;
      } | null;
    } | null;
  } | null;
};

export type SpendBudgetsProgramSummaryQueryVariables = Exact<{
  [key: string]: never;
}>;

export type SpendBudgetsProgramSummaryQuery = {
  __typename?: "Query";
  spendBudgetsProgramSummary?: {
    __typename?: "SpendBudgetSummaryResponse";
    summaryByCategory?: Array<{
      __typename?: "SpendCategory";
      id?: string | null;
      name: string;
      type: string;
      budgets?: Array<{
        __typename?: "SpendBudget";
        id: string;
        targetAmount: number;
        targetDateAt: string;
        description: string;
        reconciledBudgetTotal?: number | null;
        reconciledInvoicesTotal?: number | null;
        invoices?: Array<{
          __typename?: "SpendInvoice";
          id?: string | null;
          amount?: number | null;
        }> | null;
      } | null> | null;
    } | null> | null;
    summaryUnreconciled?: {
      __typename?: "SpendUnreconciledSummary";
      debits?: {
        __typename?: "TransactionTotalCount";
        count?: number | null;
        total?: number | null;
      } | null;
      credits?: {
        __typename?: "TransactionTotalCount";
        count?: number | null;
        total?: number | null;
      } | null;
    } | null;
  } | null;
};

export type SpendBudgetExportQueryVariables = Exact<{ [key: string]: never }>;

export type SpendBudgetExportQuery = {
  __typename?: "Query";
  spendBudgetExport?: {
    __typename?: "ExportFile";
    content?: string | null;
    fileName?: string | null;
  } | null;
};

export type SpendBudgetsSummaryEmptyQueryVariables = Exact<{
  [key: string]: never;
}>;

export type SpendBudgetsSummaryEmptyQuery = {
  __typename?: "Query";
  spendBudgetsProgramSummary?: {
    __typename?: "SpendBudgetSummaryResponse";
    summaryByCategory?: Array<{
      __typename?: "SpendCategory";
      id?: string | null;
    } | null> | null;
    summaryUnreconciled?: {
      __typename?: "SpendUnreconciledSummary";
      credits?: {
        __typename?: "TransactionTotalCount";
        total?: number | null;
      } | null;
      debits?: {
        __typename?: "TransactionTotalCount";
        total?: number | null;
      } | null;
    } | null;
  } | null;
};

export type SpendBudgetQueryVariables = Exact<{
  spendBudgetId: Scalars["String"];
  spendGroupId?: InputMaybe<Scalars["String"]>;
}>;

export type SpendBudgetQuery = {
  __typename?: "Query";
  spendBudget?: {
    __typename?: "SpendBudgetResponse";
    budget?: {
      __typename?: "SpendBudget";
      reconciledTransactions?: Array<{
        __typename?: "SpendReconciledBudgetTransaction";
        reconciledTransaction?: {
          __typename?: "SpendReconciledTransaction";
          id?: string | null;
          amount?: number | null;
          createdAt?: string | null;
          transaction?: {
            __typename?: "SpendBankTransaction";
            totalReconciled?: number | null;
            type?: string | null;
            effective?: string | null;
            metadata?: {
              __typename?: "SpendBankTransactionMetaData";
              status?: string | null;
              description?: string | null;
              summary?: string | null;
            } | null;
            history?: Array<{
              __typename?: "SpendBankTransactionHistory";
              date?: string | null;
              status?: string | null;
            } | null> | null;
          } | null;
        } | null;
      }> | null;
    } | null;
  } | null;
};

export type SpendBudgetsSummarySimpleQueryVariables = Exact<{
  groupId: Scalars["String"];
  seasonId: Scalars["String"];
}>;

export type SpendBudgetsSummarySimpleQuery = {
  __typename?: "Query";
  spendBudgetsSummary?: {
    __typename?: "SpendBudgetSummaryResponse";
    summaryByCategory?: Array<{
      __typename?: "SpendCategory";
      name: string;
      id?: string | null;
      type: string;
      budgets?: Array<{
        __typename?: "SpendBudget";
        description: string;
        id: string;
      } | null> | null;
    } | null> | null;
  } | null;
};

export type SpendCategoriesAddInvoiceQueryVariables = Exact<{
  groupId?: InputMaybe<Scalars["String"]>;
  seasonId?: InputMaybe<Scalars["String"]>;
  filterBy?: InputMaybe<CategoryFilterEnum>;
  filterValue?: InputMaybe<Scalars["String"]>;
}>;

export type SpendCategoriesAddInvoiceQuery = {
  __typename?: "Query";
  spendCategories?: {
    __typename?: "SpendCategoryResponse";
    categories?: Array<{
      __typename?: "SpendCategory";
      budgets?: Array<{
        __typename?: "SpendBudget";
        id: string;
        description: string;
      } | null> | null;
    } | null> | null;
  } | null;
};

export type SpendBudgetByIdQueryVariables = Exact<{
  spendBudgetId: Scalars["String"];
}>;

export type SpendBudgetByIdQuery = {
  __typename?: "Query";
  spendBudget?: {
    __typename?: "SpendBudgetResponse";
    budget?: {
      __typename?: "SpendBudget";
      id: string;
      description: string;
      targetAmount: number;
      category: { __typename?: "SpendCategory"; name: string; type: string };
    } | null;
  } | null;
};

export type SpendCategoriesQueryVariables = Exact<{
  filterBy?: InputMaybe<CategoryFilterEnum>;
  filterValue: Scalars["String"];
}>;

export type SpendCategoriesQuery = {
  __typename?: "Query";
  spendCategories?: {
    __typename?: "SpendCategoryResponse";
    categories?: Array<{
      __typename?: "SpendCategory";
      id?: string | null;
      name: string;
      isDefault: boolean;
      type: string;
      organizationId: string;
      isHidden: boolean;
    } | null> | null;
  } | null;
};

export type SpendAllCategoriesQueryVariables = Exact<{ [key: string]: never }>;

export type SpendAllCategoriesQuery = {
  __typename?: "Query";
  spendCategories?: {
    __typename?: "SpendCategoryResponse";
    categories?: Array<{
      __typename?: "SpendCategory";
      organizationId: string;
      name: string;
      isHidden: boolean;
      isDefault: boolean;
      id?: string | null;
      createdAt: string;
      updatedAt?: string | null;
      type: string;
    } | null> | null;
  } | null;
};

export type SpendBudgetCategoriesQueryVariables = Exact<{
  filterBy?: InputMaybe<CategoryFilterEnum>;
  filterValue?: InputMaybe<Scalars["String"]>;
  groupId?: InputMaybe<Scalars["String"]>;
  seasonId?: InputMaybe<Scalars["String"]>;
}>;

export type SpendBudgetCategoriesQuery = {
  __typename?: "Query";
  spendCategories?: {
    __typename?: "SpendCategoryResponse";
    count?: number | null;
    categories?: Array<{
      __typename?: "SpendCategory";
      id?: string | null;
      name: string;
      type: string;
      isDefault: boolean;
      isHidden: boolean;
      organizationId: string;
      createdAt: string;
      updatedAt?: string | null;
      budgets?: Array<{
        __typename?: "SpendBudget";
        id: string;
        description: string;
      } | null> | null;
    } | null> | null;
  } | null;
};

export type SpendGroupQueryVariables = Exact<{ [key: string]: never }>;

export type SpendGroupQuery = {
  __typename?: "Query";
  spendGroup?: {
    __typename?: "SpendGroup";
    id?: string | null;
    isArchived?: boolean | null;
    name?: string | null;
    accountId?: string | null;
    sharedAccount?: boolean | null;
    discountAmount?: number | null;
    discountCutOffDate?: string | null;
    enableDiscount?: boolean | null;
    isRequireAgreement?: boolean | null;
    minimumDiscountPurchase?: number | null;
    legacyAccountId?: string | null;
    organizationFees?: {
      __typename?: "SpendOrganizationFee";
      achBaseFee?: number | null;
      achPercent?: number | null;
      cardBaseFee?: number | null;
      cardPercent?: number | null;
      spendBaseFee?: number | null;
      spendPercent?: number | null;
    } | null;
    seasons?: Array<{
      __typename?: "SpendSeason";
      id?: string | null;
      name?: string | null;
      groupRoster?: Array<{
        __typename?: "SpendGroupRoster";
        id?: string | null;
        inviteId?: string | null;
        isArchived?: boolean | null;
        userId?: string | null;
        rosterId?: string | null;
        roster?: {
          __typename?: "SpendRoster";
          email?: string | null;
          id?: string | null;
          name?: string | null;
        } | null;
      } | null> | null;
      budgets?: Array<{
        __typename?: "SpendBudget";
        id: string;
        description: string;
        createdAt: string;
        targetAmount: number;
        targetDateAt: string;
        category: {
          __typename?: "SpendCategory";
          id?: string | null;
          type: string;
          name: string;
          createdAt: string;
          isDefault: boolean;
          isHidden: boolean;
          organizationId: string;
        };
      } | null> | null;
    } | null> | null;
  } | null;
};

export type SpendGroupsFilteredQueryVariables = Exact<{
  where?: InputMaybe<SpendGroupsWhereInput>;
  pagination?: InputMaybe<SpendPaginationInput>;
  sort?: InputMaybe<SpendSortInput>;
}>;

export type SpendGroupsFilteredQuery = {
  __typename?: "Query";
  spendGroupsFiltered?: {
    __typename?: "SpendGroupsResponse";
    count?: number | null;
    groups?: Array<{
      __typename?: "SpendGroup";
      id?: string | null;
      name?: string | null;
      accountId?: string | null;
      organizationId?: string | null;
      hasAccount?: boolean | null;
      isArchived?: boolean | null;
      sharedAccount?: boolean | null;
      discountAmount?: number | null;
      discountCutOffDate?: string | null;
      enableDiscount?: boolean | null;
      isRequireAgreement?: boolean | null;
      minimumDiscountPurchase?: number | null;
      unitAmount?: number | null;
      legacyAccountId?: string | null;
      seasons?: Array<{
        __typename?: "SpendSeason";
        id?: string | null;
        name?: string | null;
        startDateAt?: string | null;
        endDateAt?: string | null;
        playerCount?: number | null;
        paymentScheduleStatus?: string | null;
        isLinkEnabled?: boolean | null;
        isBudgetShared?: boolean | null;
        budgets?: Array<{
          __typename?: "SpendBudget";
          id: string;
          description: string;
          createdAt: string;
          targetAmount: number;
          targetDateAt: string;
          category: {
            __typename?: "SpendCategory";
            id?: string | null;
            type: string;
            name: string;
            createdAt: string;
            isDefault: boolean;
            isHidden: boolean;
            organizationId: string;
          };
        } | null> | null;
        groupRoster?: Array<{
          __typename?: "SpendGroupRoster";
          id?: string | null;
          inviteId?: string | null;
          isArchived?: boolean | null;
          rosterId?: string | null;
          joinedAt?: string | null;
          roster?: {
            __typename?: "SpendRoster";
            email?: string | null;
            id?: string | null;
            name?: string | null;
          } | null;
        } | null> | null;
      } | null> | null;
      latestSeason?: {
        __typename?: "SpendSeason";
        id?: string | null;
        name?: string | null;
        paymentScheduleStatus?: string | null;
        isBudgetShared?: boolean | null;
        isLinkEnabled?: boolean | null;
        startDateAt?: string | null;
        endDateAt?: string | null;
        groupId?: string | null;
        playerCount?: number | null;
        transactionTotals?: {
          __typename?: "SpendTransactionTotals";
          paid?: number | null;
          processing?: number | null;
          upcoming?: number | null;
          pastDue?: number | null;
          dueToday?: number | null;
          credited?: number | null;
        } | null;
      } | null;
    } | null> | null;
  } | null;
};

export type SpendGroupsQueryVariables = Exact<{ [key: string]: never }>;

export type SpendGroupsQuery = {
  __typename?: "Query";
  spendGroups?: {
    __typename?: "SpendGroupResponse";
    groups?: Array<{
      __typename?: "SpendGroup";
      id?: string | null;
      accountId?: string | null;
      organizationId?: string | null;
      name?: string | null;
      hasAccount?: boolean | null;
      seasons?: Array<{
        __typename?: "SpendSeason";
        id?: string | null;
        name?: string | null;
        startDateAt?: string | null;
        endDateAt?: string | null;
        playerCount?: number | null;
        paymentScheduleStatus?: string | null;
        isLinkEnabled?: boolean | null;
        isBudgetShared?: boolean | null;
        budgets?: Array<{
          __typename?: "SpendBudget";
          id: string;
          description: string;
          category: {
            __typename?: "SpendCategory";
            id?: string | null;
            type: string;
          };
        } | null> | null;
        transactionTotals?: {
          __typename?: "SpendTransactionTotals";
          paid?: number | null;
          processing?: number | null;
          upcoming?: number | null;
          pastDue?: number | null;
          credited?: number | null;
        } | null;
        groupRoster?: Array<{
          __typename?: "SpendGroupRoster";
          id?: string | null;
          inviteId?: string | null;
          isArchived?: boolean | null;
          rosterId?: string | null;
          roster?: {
            __typename?: "SpendRoster";
            email?: string | null;
            id?: string | null;
            name?: string | null;
          } | null;
        } | null> | null;
      } | null> | null;
    } | null> | null;
  } | null;
};

export type SpendGroupByIdQueryVariables = Exact<{
  spendGroupByIdId: Scalars["String"];
}>;

export type SpendGroupByIdQuery = {
  __typename?: "Query";
  spendGroupById?: {
    __typename?: "SpendGroup";
    id?: string | null;
    accountId?: string | null;
    organizationId?: string | null;
    name?: string | null;
    hasAccount?: boolean | null;
    isArchived?: boolean | null;
    sharedAccount?: boolean | null;
    seasons?: Array<{
      __typename?: "SpendSeason";
      id?: string | null;
      name?: string | null;
      startDateAt?: string | null;
      endDateAt?: string | null;
      playerCount?: number | null;
      paymentScheduleStatus?: string | null;
      isLinkEnabled?: boolean | null;
      isBudgetShared?: boolean | null;
      budgets?: Array<{
        __typename?: "SpendBudget";
        id: string;
        description: string;
        createdAt: string;
        targetAmount: number;
        targetDateAt: string;
        category: {
          __typename?: "SpendCategory";
          id?: string | null;
          type: string;
          name: string;
          createdAt: string;
          isDefault: boolean;
          isHidden: boolean;
          organizationId: string;
        };
      } | null> | null;
      transactionTotals?: {
        __typename?: "SpendTransactionTotals";
        paid?: number | null;
        processing?: number | null;
        upcoming?: number | null;
        pastDue?: number | null;
        credited?: number | null;
      } | null;
      groupRoster?: Array<{
        __typename?: "SpendGroupRoster";
        id?: string | null;
        inviteId?: string | null;
        isArchived?: boolean | null;
        rosterId?: string | null;
        roster?: {
          __typename?: "SpendRoster";
          email?: string | null;
          id?: string | null;
          name?: string | null;
        } | null;
      } | null> | null;
    } | null> | null;
  } | null;
};

export type SpendSignupGroupIdQueryVariables = Exact<{
  spendGroupByIdId: Scalars["String"];
}>;

export type SpendSignupGroupIdQuery = {
  __typename?: "Query";
  spendGroupById?: {
    __typename?: "SpendGroup";
    id?: string | null;
    name?: string | null;
    accountId?: string | null;
    sharedAccount?: boolean | null;
    discountAmount?: number | null;
    discountCutOffDate?: string | null;
    enableDiscount?: boolean | null;
    isRequireAgreement?: boolean | null;
    minimumDiscountPurchase?: number | null;
    legacyAccountId?: string | null;
    organizationFees?: {
      __typename?: "SpendOrganizationFee";
      achBaseFee?: number | null;
      achPercent?: number | null;
      cardBaseFee?: number | null;
      cardPercent?: number | null;
      spendBaseFee?: number | null;
      spendPercent?: number | null;
    } | null;
    seasons?: Array<{
      __typename?: "SpendSeason";
      id?: string | null;
      name?: string | null;
      groupRoster?: Array<{
        __typename?: "SpendGroupRoster";
        id?: string | null;
        inviteId?: string | null;
        isArchived?: boolean | null;
        userId?: string | null;
        rosterId?: string | null;
        roster?: {
          __typename?: "SpendRoster";
          email?: string | null;
          id?: string | null;
          name?: string | null;
        } | null;
      } | null> | null;
    } | null> | null;
  } | null;
};

export type SpendGroupByIdSettingsQueryVariables = Exact<{
  spendGroupByIdId: Scalars["String"];
}>;

export type SpendGroupByIdSettingsQuery = {
  __typename?: "Query";
  spendGroupById?: {
    __typename?: "SpendGroup";
    id?: string | null;
    accountId?: string | null;
    organizationId?: string | null;
    name?: string | null;
    latestSeason?: {
      __typename?: "SpendSeason";
      id?: string | null;
      startDateAt?: string | null;
      endDateAt?: string | null;
      isLinkEnabled?: boolean | null;
      name?: string | null;
      paymentScheduleStatus?: string | null;
      transactionTotals?: {
        __typename?: "SpendTransactionTotals";
        paid?: number | null;
        processing?: number | null;
        upcoming?: number | null;
        pastDue?: number | null;
        dueToday?: number | null;
        credited?: number | null;
      } | null;
    } | null;
  } | null;
};

export type SpendBudgetGroupsQueryVariables = Exact<{ [key: string]: never }>;

export type SpendBudgetGroupsQuery = {
  __typename?: "Query";
  spendGroups?: {
    __typename?: "SpendGroupResponse";
    groups?: Array<{
      __typename?: "SpendGroup";
      id?: string | null;
      organizationId?: string | null;
      name?: string | null;
      accountId?: string | null;
      hasAccount?: boolean | null;
      seasons?: Array<{
        __typename?: "SpendSeason";
        id?: string | null;
        name?: string | null;
        isBudgetShared?: boolean | null;
        budgets?: Array<{
          __typename?: "SpendBudget";
          id: string;
          targetAmount: number;
          category: { __typename?: "SpendCategory"; type: string };
          invoices?: Array<{
            __typename?: "SpendInvoice";
            id?: string | null;
            amount?: number | null;
            paid?: boolean | null;
            isReconciled?: boolean | null;
          }> | null;
        } | null> | null;
      } | null> | null;
    } | null> | null;
  } | null;
};

export type SpendGroupRostersQueryVariables = Exact<{ [key: string]: never }>;

export type SpendGroupRostersQuery = {
  __typename?: "Query";
  spendGroupRosters?: {
    __typename?: "SpendGroupRosterResponse";
    count?: number | null;
    groupRosters?: Array<{
      __typename?: "SpendGroupRoster";
      archivedAt?: string | null;
      createdAt?: string | null;
      groupId?: string | null;
      id?: string | null;
      isArchived?: boolean | null;
      joinedAt?: string | null;
      rosterId?: string | null;
      userId?: string | null;
      paymentScheduleStatus?: string | null;
      roster?: {
        __typename?: "SpendRoster";
        name?: string | null;
        email?: string | null;
        id?: string | null;
      } | null;
      group?: {
        __typename?: "SpendGroup";
        id?: string | null;
        name?: string | null;
        accountId?: string | null;
      } | null;
      season?: {
        __typename?: "SpendSeason";
        id?: string | null;
        name?: string | null;
        startDateAt?: string | null;
        endDateAt?: string | null;
      } | null;
      invoices?: Array<{
        __typename?: "SpendInvoice";
        id?: string | null;
        amount?: number | null;
        paid?: boolean | null;
        paidDate?: string | null;
        dueDate?: string | null;
        balanceDue?: number | null;
        description?: string | null;
        isOptional?: boolean | null;
        optedIn?: boolean | null;
        authorizedAt?: string | null;
        transaction?: {
          __typename?: "SpendTransaction";
          id?: string | null;
          invoiceId?: string | null;
          externalId?: string | null;
        } | null;
        reconciledTransactions?: Array<{
          __typename?: "SpendReconciledInvoiceTransaction";
          id?: string | null;
        } | null> | null;
      } | null> | null;
    } | null> | null;
  } | null;
};

export type SpendGroupRostersAndPaymentScheduleQueryVariables = Exact<{
  seasonId: Scalars["String"];
  groupIdOrSeasonId: Scalars["String"];
}>;

export type SpendGroupRostersAndPaymentScheduleQuery = {
  __typename?: "Query";
  spendGroupRosters?: {
    __typename?: "SpendGroupRosterResponse";
    count?: number | null;
    groupRosters?: Array<{
      __typename?: "SpendGroupRoster";
      inviteId?: string | null;
      archivedAt?: string | null;
      createdAt?: string | null;
      groupId?: string | null;
      id?: string | null;
      isArchived?: boolean | null;
      seasonId?: string | null;
      rosterId?: string | null;
      userId?: string | null;
      invite?: {
        __typename?: "SpendInvite";
        status?: string | null;
        isArchived?: boolean | null;
        id?: string | null;
      } | null;
      roster?: {
        __typename?: "SpendRoster";
        name?: string | null;
        email?: string | null;
        id?: string | null;
      } | null;
      group?: {
        __typename?: "SpendGroup";
        id?: string | null;
        name?: string | null;
        accountId?: string | null;
      } | null;
      season?: {
        __typename?: "SpendSeason";
        id?: string | null;
        name?: string | null;
        startDateAt?: string | null;
        endDateAt?: string | null;
      } | null;
      invoices?: Array<{
        __typename?: "SpendInvoice";
        id?: string | null;
        amount?: number | null;
        paid?: boolean | null;
        paidDate?: string | null;
        dueDate?: string | null;
        balanceDue?: number | null;
        description?: string | null;
        status?: string | null;
        transaction?: {
          __typename?: "SpendTransaction";
          id?: string | null;
          invoiceId?: string | null;
          externalId?: string | null;
        } | null;
        reconciledTransactions?: Array<{
          __typename?: "SpendReconciledInvoiceTransaction";
          id?: string | null;
        } | null> | null;
      } | null> | null;
    } | null> | null;
  } | null;
  spendPaymentSchedules?: Array<{
    __typename?: "SpendPaymentSchedule";
    id?: string | null;
    amountDue?: number | null;
    dueDate?: string | null;
    description?: string | null;
    status?: string | null;
    note?: string | null;
    isOptional?: boolean | null;
    budgetItemId?: string | null;
    seasonId?: string | null;
    groupId?: string | null;
    budgetItem?: {
      __typename?: "SpendBudget";
      id: string;
      description: string;
      targetAmount: number;
      targetDateAt: string;
      vaultId?: string | null;
      isDefault?: boolean | null;
      createdAt: string;
      updatedAt?: string | null;
      reconciledBudgetTotal?: number | null;
      reconciledInvoicesTotal?: number | null;
      reconciledTotal?: number | null;
      category: {
        __typename?: "SpendCategory";
        name: string;
        type: string;
        id?: string | null;
      };
      invoices?: Array<{
        __typename?: "SpendInvoice";
        balanceDue?: number | null;
        description?: string | null;
        paid?: boolean | null;
        id?: string | null;
        amount?: number | null;
        reconciledTransactions?: Array<{
          __typename?: "SpendReconciledInvoiceTransaction";
          amount?: number | null;
          id?: string | null;
          invoiceId?: string | null;
          transactionId?: string | null;
        } | null> | null;
      }> | null;
    } | null;
    season?: {
      __typename?: "SpendSeason";
      id?: string | null;
      name?: string | null;
      startDateAt?: string | null;
      endDateAt?: string | null;
    } | null;
    group?: {
      __typename?: "SpendGroup";
      id?: string | null;
      name?: string | null;
      organizationId?: string | null;
      programId?: string | null;
      createdAt?: string | null;
      archivedAt?: string | null;
      hasAccount?: boolean | null;
    } | null;
  } | null> | null;
};

export type SpendSimpleGroupsQueryVariables = Exact<{ [key: string]: never }>;

export type SpendSimpleGroupsQuery = {
  __typename?: "Query";
  spendGroups?: {
    __typename?: "SpendGroupResponse";
    groups?: Array<{
      __typename?: "SpendGroup";
      id?: string | null;
      name?: string | null;
    } | null> | null;
  } | null;
};

export type SpendGroupDebitCardsQueryVariables = Exact<{
  spendGroupDebitCardsId?: InputMaybe<Scalars["String"]>;
}>;

export type SpendGroupDebitCardsQuery = {
  __typename?: "Query";
  spendGroupDebitCards?: {
    __typename?: "SpendDebitCardResponse";
    count?: number | null;
    cards?: Array<{
      __typename?: "SpendDebitCard";
      expiration?: string | null;
      id?: string | null;
      lastFour?: string | null;
      status?: string | null;
      userId?: string | null;
    } | null> | null;
  } | null;
};

export type SpendGroupRosterInvoicesQueryVariables = Exact<{
  filterValue?: InputMaybe<Scalars["String"]>;
  filterBy?: InputMaybe<GroupRosterFilterEnum>;
}>;

export type SpendGroupRosterInvoicesQuery = {
  __typename?: "Query";
  spendGroupRosters?: {
    __typename?: "SpendGroupRosterResponse";
    count?: number | null;
    groupRosters?: Array<{
      __typename?: "SpendGroupRoster";
      invoices?: Array<{
        __typename?: "SpendInvoice";
        description?: string | null;
        dueDate?: string | null;
        paymentMethodSource?: string | null;
        paymentMethodId?: string | null;
        balanceDue?: number | null;
        paid?: boolean | null;
        id?: string | null;
      } | null> | null;
      group?: { __typename?: "SpendGroup"; name?: string | null } | null;
      roster?: { __typename?: "SpendRoster"; name?: string | null } | null;
    } | null> | null;
  } | null;
};

export type SpendGroupRostersParentInvoicesQueryVariables = Exact<{
  filterBy?: InputMaybe<GroupRosterFilterEnum>;
  filterValue?: InputMaybe<Scalars["String"]>;
}>;

export type SpendGroupRostersParentInvoicesQuery = {
  __typename?: "Query";
  spendGroupRosters?: {
    __typename?: "SpendGroupRosterResponse";
    count?: number | null;
    groupRosters?: Array<{
      __typename?: "SpendGroupRoster";
      userId?: string | null;
      group?: {
        __typename?: "SpendGroup";
        id?: string | null;
        name?: string | null;
      } | null;
      season?: { __typename?: "SpendSeason"; name?: string | null } | null;
      invoices?: Array<{
        __typename?: "SpendInvoice";
        balanceDue?: number | null;
        amount?: number | null;
        paidDate?: string | null;
        dueDate?: string | null;
        paid?: boolean | null;
        paymentMethodSource?: string | null;
        description?: string | null;
        id?: string | null;
        isAutoPayAuthorized?: boolean | null;
        isAutoPayStopped?: boolean | null;
        authorizedAt?: string | null;
        optedIn?: boolean | null;
        refundDate?: string | null;
        status?: string | null;
        isRefunded?: boolean | null;
        discountAmount?: number | null;
        isOptional?: boolean | null;
      } | null> | null;
      roster?: {
        __typename?: "SpendRoster";
        id?: string | null;
        name?: string | null;
      } | null;
      invite?: { __typename?: "SpendInvite"; status?: string | null } | null;
    } | null> | null;
  } | null;
};

export type SpendGroupBankAccountsQueryVariables = Exact<{
  groupId?: InputMaybe<Scalars["String"]>;
}>;

export type SpendGroupBankAccountsQuery = {
  __typename?: "Query";
  spendGroupBankAccounts?: {
    __typename?: "SpendBankAccountsResponse";
    status?: string | null;
    externalAccounts?: Array<{
      __typename?: "SpendBankAccount";
      accountId?: string | null;
      availableBalance?: number | null;
      currentBalance?: number | null;
      mask?: string | null;
      name?: string | null;
      officialName?: string | null;
      subtype?: string | null;
      type?: string | null;
      institution?: {
        __typename?: "SpendInstitution";
        id?: string | null;
        name?: string | null;
      } | null;
    } | null> | null;
  } | null;
};

export type SpendParentSignUpGroupRostersQueryVariables = Exact<{
  [key: string]: never;
}>;

export type SpendParentSignUpGroupRostersQuery = {
  __typename?: "Query";
  spendGroupRosters?: {
    __typename?: "SpendGroupRosterResponse";
    groupRosters?: Array<{
      __typename?: "SpendGroupRoster";
      roster?: {
        __typename?: "SpendRoster";
        email?: string | null;
        id?: string | null;
        name?: string | null;
        groupRosters?: Array<{
          __typename?: "SpendGroupRoster";
          invoices?: Array<{
            __typename?: "SpendInvoice";
            amount?: number | null;
            isOptional?: boolean | null;
            optedIn?: boolean | null;
            description?: string | null;
            dueDate?: string | null;
            id?: string | null;
          } | null> | null;
        } | null> | null;
      } | null;
      group?: { __typename?: "SpendGroup"; name?: string | null } | null;
      season?: { __typename?: "SpendSeason"; name?: string | null } | null;
    } | null> | null;
  } | null;
};

export type SpendGroupPayeesQueryVariables = Exact<{
  groupId: Scalars["String"];
}>;

export type SpendGroupPayeesQuery = {
  __typename?: "Query";
  spendGroupPayees?: {
    __typename?: "SpendPayeeResponse";
    payees?: Array<{
      __typename?: "SpendPayees";
      id: string;
      name: string;
      address1: string;
      address2?: string | null;
      city: string;
      state: string;
      zipCode: string;
    }> | null;
  } | null;
};

export type GetGroupRosterByIdsQueryVariables = Exact<{
  filterBy?: InputMaybe<GroupRosterFilterEnum>;
  filterValue?: InputMaybe<Scalars["String"]>;
}>;

export type GetGroupRosterByIdsQuery = {
  __typename?: "Query";
  spendGroupRosters?: {
    __typename?: "SpendGroupRosterResponse";
    count?: number | null;
    groupRosters?: Array<{
      __typename?: "SpendGroupRoster";
      rosterId?: string | null;
    } | null> | null;
  } | null;
};

export type SpendUserSignUpMutationVariables = Exact<{
  input: SpendSignUpInput;
}>;

export type SpendUserSignUpMutation = {
  __typename?: "Mutation";
  spendUserSignUp?: {
    __typename?: "SpendSignUpResponse";
    id?: string | null;
  } | null;
};

export type SpendGroupsPublicQueryVariables = Exact<{
  orgId: Scalars["String"];
}>;

export type SpendGroupsPublicQuery = {
  __typename?: "Query";
  spendGroupsPublic?: {
    __typename?: "PublicGroupResponse";
    groups: Array<{
      __typename?: "PublicGroup";
      id: string;
      name: string;
      seasons?: Array<{
        __typename?: "PublicSeason";
        id: string;
        name: string;
        isLinkEnabled: boolean;
        paymentScheduleStatus: boolean;
      }> | null;
    }>;
  } | null;
};

export type SpendGroupsWithSeasonRosterQueryVariables = Exact<{
  includeArchive?: InputMaybe<Scalars["Boolean"]>;
}>;

export type SpendGroupsWithSeasonRosterQuery = {
  __typename?: "Query";
  spendGroups?: {
    __typename?: "SpendGroupResponse";
    count?: number | null;
    groups?: Array<{
      __typename?: "SpendGroup";
      id?: string | null;
      name?: string | null;
      isArchived?: boolean | null;
      seasons?: Array<{
        __typename?: "SpendSeason";
        id?: string | null;
        name?: string | null;
        groupRoster?: Array<{
          __typename?: "SpendGroupRoster";
          id?: string | null;
          roster?: {
            __typename?: "SpendRoster";
            email?: string | null;
            id?: string | null;
            name?: string | null;
          } | null;
        } | null> | null;
      } | null> | null;
    } | null> | null;
  } | null;
};

export type SpendGetGroupCounterpartiesQueryVariables = Exact<{
  groupOrOrgId: Scalars["String"];
}>;

export type SpendGetGroupCounterpartiesQuery = {
  __typename?: "Query";
  spendGetGroupCounterparties?: {
    __typename?: "SpendCounterpartiesResponse";
    counterparties: Array<{
      __typename?: "SpendCounterparty";
      id: string;
      name: string;
      bankName: string;
      routingNumber: string;
      accountLastFour: string;
    }>;
  } | null;
};

export type SpendInvitesQueryVariables = Exact<{ [key: string]: never }>;

export type SpendInvitesQuery = {
  __typename?: "Query";
  spendInvites?: {
    __typename?: "SpendInviteResponse";
    count?: number | null;
    invites?: Array<{
      __typename?: "SpendInvite";
      createdAt?: string | null;
      email?: string | null;
      expiresAt?: string | null;
      firstName?: string | null;
      groupId?: string | null;
      id?: string | null;
      isUser?: boolean | null;
      lastName?: string | null;
      organizationId?: string | null;
      status?: string | null;
      type?: string | null;
      userId?: string | null;
    } | null> | null;
  } | null;
};

export type SpendFilteredInvitesQueryVariables = Exact<{
  filterBy?: InputMaybe<InviteFilterEnum>;
  filterValue?: InputMaybe<Scalars["String"]>;
}>;

export type SpendFilteredInvitesQuery = {
  __typename?: "Query";
  spendInvites?: {
    __typename?: "SpendInviteResponse";
    invites?: Array<{
      __typename?: "SpendInvite";
      email?: string | null;
      userId?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      status?: string | null;
      type?: string | null;
      id?: string | null;
      groupId?: string | null;
      isDeliverable?: boolean | null;
      isArchived?: boolean | null;
    } | null> | null;
  } | null;
};

export type SpendInvitesFilteredQueryVariables = Exact<{
  where?: InputMaybe<SpendInviteWhereInput>;
  pagination?: InputMaybe<SpendPaginationInput>;
  sort?: InputMaybe<SpendSortInput>;
  includeGroup?: InputMaybe<Scalars["Boolean"]>;
  includeCards?: InputMaybe<Scalars["Boolean"]>;
}>;

export type SpendInvitesFilteredQuery = {
  __typename?: "Query";
  spendInvitesFiltered?: {
    __typename?: "SpendInviteResponse";
    count?: number | null;
    invites?: Array<{
      __typename?: "SpendInvite";
      id?: string | null;
      email?: string | null;
      userId?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      status?: string | null;
      type?: string | null;
      expiresAt?: string | null;
      groupId?: string | null;
      debitCard?: string | null;
      isDeliverable?: boolean | null;
      isArchived?: boolean | null;
      isApprover?: boolean | null;
      group?: {
        __typename?: "SpendGroup";
        id?: string | null;
        name?: string | null;
      } | null;
      debitCards?: Array<{
        __typename?: "SpendDebitCard";
        id?: string | null;
        lastFour?: string | null;
        status?: string | null;
      }> | null;
    } | null> | null;
  } | null;
};

export type SpendDebitCardInvitesQueryVariables = Exact<{
  groupId?: InputMaybe<Scalars["String"]>;
}>;

export type SpendDebitCardInvitesQuery = {
  __typename?: "Query";
  spendDebitCardInvites?: {
    __typename?: "SpendInviteResponse";
    count?: number | null;
    invites?: Array<{
      __typename?: "SpendInvite";
      firstName?: string | null;
      lastName?: string | null;
      userId?: string | null;
      groupId?: string | null;
      organizationId?: string | null;
    } | null> | null;
  } | null;
};

export type SpendEmailInviteQueryVariables = Exact<{
  id: Scalars["String"];
}>;

export type SpendEmailInviteQuery = {
  __typename?: "Query";
  spendInvite?: {
    __typename?: "SpendInviteResponse";
    invites?: Array<{
      __typename?: "SpendInvite";
      id?: string | null;
      email?: string | null;
    } | null> | null;
  } | null;
  me?: {
    __typename?: "UserWithPermissions";
    id?: string | null;
    email?: string | null;
    firstName?: string | null;
    lastName?: string | null;
  } | null;
};

export type SpendInviteQueryVariables = Exact<{
  spendInviteId: Scalars["String"];
}>;

export type SpendInviteQuery = {
  __typename?: "Query";
  spendInvite?: {
    __typename?: "SpendInviteResponse";
    count?: number | null;
    invites?: Array<{
      __typename?: "SpendInvite";
      id?: string | null;
      isArchived?: boolean | null;
      status?: string | null;
      expiresAt?: string | null;
      group?: { __typename?: "SpendGroup"; isArchived?: boolean | null } | null;
    } | null> | null;
  } | null;
};

export type SpendPaginatedInvoicesQueryVariables = Exact<{
  pagination?: InputMaybe<SpendPaginationInput>;
  filters?: InputMaybe<
    Array<InputMaybe<SpendInvoiceFilter>> | InputMaybe<SpendInvoiceFilter>
  >;
  sort?: InputMaybe<SpendSortInput>;
}>;

export type SpendPaginatedInvoicesQuery = {
  __typename?: "Query";
  spendPaginatedInvoices?: {
    __typename?: "SpendInvoiceResponse";
    hasNext?: boolean | null;
    count?: number | null;
    invoices?: Array<{
      __typename?: "SpendInvoice";
      amount?: number | null;
      description?: string | null;
      discountAmount?: number | null;
      balanceDue?: number | null;
      dueDate?: string | null;
      paid?: boolean | null;
      createdAt?: string | null;
      status?: string | null;
      isRefunded?: boolean | null;
      groupRoster?: {
        __typename?: "SpendGroupRoster";
        roster?: {
          __typename?: "SpendRoster";
          id?: string | null;
          name?: string | null;
          email?: string | null;
        } | null;
        season?: {
          __typename?: "SpendSeason";
          id?: string | null;
          name?: string | null;
        } | null;
        group?: {
          __typename?: "SpendGroup";
          id?: string | null;
          name?: string | null;
        } | null;
        invite?: { __typename?: "SpendInvite"; status?: string | null } | null;
      } | null;
    } | null> | null;
  } | null;
};

export type SpendInvoicesExportQueryVariables = Exact<{
  limit?: InputMaybe<Scalars["Int"]>;
  filters?: InputMaybe<
    Array<InputMaybe<SpendInvoiceFilter>> | InputMaybe<SpendInvoiceFilter>
  >;
}>;

export type SpendInvoicesExportQuery = {
  __typename?: "Query";
  spendInvoicesExport?: {
    __typename?: "ExportFile";
    content?: string | null;
    fileName?: string | null;
  } | null;
};

export type SpendInvoicesExportV2QueryVariables = Exact<{
  filters?: InputMaybe<
    Array<InputMaybe<SpendInvoiceFilter>> | InputMaybe<SpendInvoiceFilter>
  >;
}>;

export type SpendInvoicesExportV2Query = {
  __typename?: "Query";
  spendInvoicesExportV2?: {
    __typename?: "ExportFile";
    content?: string | null;
    fileName?: string | null;
  } | null;
};

export type InvoicesQueryVariables = Exact<{
  filterBy?: InputMaybe<InvoiceFilterEnum>;
  filterValue?: InputMaybe<Scalars["String"]>;
}>;

export type InvoicesQuery = {
  __typename?: "Query";
  spendInvoices?: {
    __typename?: "SpendInvoiceResponse";
    count?: number | null;
    invoices?: Array<{
      __typename?: "SpendInvoice";
      id?: string | null;
      amount?: number | null;
      paid?: boolean | null;
      paidDate?: string | null;
      dueDate?: string | null;
      balanceDue?: number | null;
      description?: string | null;
      optedIn?: boolean | null;
      isOptional?: boolean | null;
      groupRosterId?: string | null;
      budgetItemId?: string | null;
      lastNotifyId?: string | null;
      lastNotifyDate?: string | null;
      notificationAttempts?: number | null;
      paymentScheduleInvoiceId?: string | null;
      createdAt?: string | null;
      updatedAt?: string | null;
      isReconciled?: boolean | null;
      discountAmount?: number | null;
      paymentMethodId?: string | null;
      paymentMethodSource?: string | null;
      isAutoPayAuthorized?: boolean | null;
      groupRoster?: {
        __typename?: "SpendGroupRoster";
        id?: string | null;
        roster?: {
          __typename?: "SpendRoster";
          id?: string | null;
          name?: string | null;
        } | null;
        group?: {
          __typename?: "SpendGroup";
          id?: string | null;
          name?: string | null;
        } | null;
        season?: {
          __typename?: "SpendSeason";
          id?: string | null;
          name?: string | null;
        } | null;
      } | null;
      budgetItem?: {
        __typename?: "SpendBudget";
        description: string;
        id: string;
      } | null;
      reconciledTransactions?: Array<{
        __typename?: "SpendReconciledInvoiceTransaction";
        id?: string | null;
        transactionId?: string | null;
        amount?: number | null;
      } | null> | null;
      transaction?: {
        __typename?: "SpendTransaction";
        id?: string | null;
        externalId?: string | null;
        invoiceId?: string | null;
        source?: string | null;
      } | null;
    } | null> | null;
  } | null;
};

export type GetUsersPastDueInvoicesQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetUsersPastDueInvoicesQuery = {
  __typename?: "Query";
  spendRosters?: {
    __typename?: "SpendRosterResponse";
    count?: number | null;
    rosters?: Array<{
      __typename?: "SpendRoster";
      id?: string | null;
      email?: string | null;
      name?: string | null;
      groupRosters?: Array<{
        __typename?: "SpendGroupRoster";
        season?: { __typename?: "SpendSeason"; name?: string | null } | null;
        group?: {
          __typename?: "SpendGroup";
          name?: string | null;
          id?: string | null;
        } | null;
        invoices?: Array<{
          __typename?: "SpendInvoice";
          id?: string | null;
          dueDate?: string | null;
          balanceDue?: number | null;
          amount?: number | null;
          paid?: boolean | null;
          description?: string | null;
          status?: string | null;
          isArchived?: boolean | null;
        } | null> | null;
        invite?: {
          __typename?: "SpendInvite";
          firstName?: string | null;
          lastName?: string | null;
          email?: string | null;
          status?: string | null;
        } | null;
      } | null> | null;
    } | null> | null;
  } | null;
};

export type GetInvoicesWithPaymentIdQueryVariables = Exact<{
  filterValue?: InputMaybe<Scalars["String"]>;
  filterBy?: InputMaybe<GroupRosterFilterEnum>;
}>;

export type GetInvoicesWithPaymentIdQuery = {
  __typename?: "Query";
  spendGroupRosters?: {
    __typename?: "SpendGroupRosterResponse";
    count?: number | null;
    groupRosters?: Array<{
      __typename?: "SpendGroupRoster";
      invoices?: Array<{
        __typename?: "SpendInvoice";
        paymentMethodId?: string | null;
        id?: string | null;
        paid?: boolean | null;
        status?: string | null;
      } | null> | null;
    } | null> | null;
  } | null;
};

export type SpendInvoicesQueryVariables = Exact<{
  filterValue?: InputMaybe<Scalars["String"]>;
  filterBy?: InputMaybe<InvoiceFilterEnum>;
  parentSignUp?: InputMaybe<Scalars["Boolean"]>;
}>;

export type SpendInvoicesQuery = {
  __typename?: "Query";
  spendInvoices?: {
    __typename?: "SpendInvoiceResponse";
    count?: number | null;
    invoices?: Array<{
      __typename?: "SpendInvoice";
      description?: string | null;
      amount?: number | null;
      optedIn?: boolean | null;
      isOptional?: boolean | null;
      groupRosterId?: string | null;
      dueDate?: string | null;
      balanceDue?: number | null;
      id?: string | null;
      isArchived?: boolean | null;
      paid?: boolean | null;
      creditMemos?: Array<{
        __typename?: "SpendMemo";
        id: string;
        title: string;
        note?: string | null;
        dateToApply: string;
        creditApplied: number;
        creditAmount: number;
        isArchived: boolean;
      }> | null;
    } | null> | null;
  } | null;
};

export type SpendInvoiceHistoryQueryVariables = Exact<{
  input?: InputMaybe<SpendInvoiceHistoryInput>;
}>;

export type SpendInvoiceHistoryQuery = {
  __typename?: "Query";
  spendInvoiceHistory?: {
    __typename?: "SendEmailResponse";
    success: boolean;
  } | null;
};

export type SpendInvoiceByIdQueryVariables = Exact<{
  id: Scalars["String"];
}>;

export type SpendInvoiceByIdQuery = {
  __typename?: "Query";
  spendInvoiceById?: {
    __typename?: "SpendInvoice";
    amount?: number | null;
    balanceDue?: number | null;
    budgetItemId?: string | null;
    createdAt?: string | null;
    description?: string | null;
    dueDate?: string | null;
    feesAmount?: number | null;
    groupRosterId?: string | null;
    id?: string | null;
    isArchived?: boolean | null;
    isAutoPayAuthorized?: boolean | null;
    isOptional?: boolean | null;
    isReconciled?: boolean | null;
    isRefunded?: boolean | null;
    lastNotifyId?: string | null;
    lastNotifyDate?: string | null;
    note?: string | null;
    notificationAttempts?: number | null;
    optedIn?: boolean | null;
    paid?: boolean | null;
    paidDate?: string | null;
    paymentMethodId?: string | null;
    paymentMethodSource?: string | null;
    paymentScheduleInvoiceId?: string | null;
    refundDate?: string | null;
    status?: string | null;
    updatedAt?: string | null;
    groupRoster?: {
      __typename?: "SpendGroupRoster";
      id?: string | null;
      seasonId?: string | null;
      group?: { __typename?: "SpendGroup"; name?: string | null } | null;
      roster?: { __typename?: "SpendRoster"; name?: string | null } | null;
      season?: {
        __typename?: "SpendSeason";
        id?: string | null;
        name?: string | null;
      } | null;
    } | null;
  } | null;
};

export type SpendPaginatedInvoicesForAutoPayQueryVariables = Exact<{
  [key: string]: never;
}>;

export type SpendPaginatedInvoicesForAutoPayQuery = {
  __typename?: "Query";
  spendPaginatedInvoices?: {
    __typename?: "SpendInvoiceResponse";
    invoices?: Array<{
      __typename?: "SpendInvoice";
      isAutoPayAuthorized?: boolean | null;
      groupRosterId?: string | null;
      paid?: boolean | null;
      balanceDue?: number | null;
      authorizedAt?: string | null;
      optedOutAt?: string | null;
      optedIn?: boolean | null;
      isOptional?: boolean | null;
      groupRoster?: {
        __typename?: "SpendGroupRoster";
        joinedAt?: string | null;
        roster?: { __typename?: "SpendRoster"; name?: string | null } | null;
        settings?: {
          __typename?: "SpendGroupRosterSettings";
          isAutoPayAuthorized?: boolean | null;
        } | null;
        invite?: {
          __typename?: "SpendInvite";
          status?: string | null;
          isUser?: boolean | null;
        } | null;
      } | null;
    } | null> | null;
  } | null;
};

export type SpendGuardianInvoicesQueryVariables = Exact<{
  filters?: InputMaybe<
    | Array<InputMaybe<SpendGuardianInvoiceFilter>>
    | InputMaybe<SpendGuardianInvoiceFilter>
  >;
}>;

export type SpendGuardianInvoicesQuery = {
  __typename?: "Query";
  spendGuardianInvoices?: {
    __typename?: "SpendGuardianInvoiceResponse";
    count: number;
    invoices?: Array<{
      __typename?: "SpendGuardianInvoice";
      amount: number;
      authorizedAt?: string | null;
      balanceDue: number;
      description: string;
      discountAmount: number;
      dueDate: string;
      id: string;
      isAutoPayAuthorized: boolean;
      isAutoPayStopped: boolean;
      isOptional: boolean;
      isRefunded: boolean;
      optedIn: boolean;
      paid: boolean;
      paidDate?: string | null;
      paymentMethodId?: string | null;
      paymentMethodSource?: string | null;
      refundDate?: string | null;
      status: string;
      rosterId: string;
      rosterName: string;
      groupName: string;
      seasonName: string;
      groupId: string;
      groupRosterId: string;
      seasonId: string;
      optedOutAt?: string | null;
      isArchived: boolean;
      note?: string | null;
      creditMemos?: Array<{
        __typename?: "SpendMemo";
        id: string;
        title: string;
        note?: string | null;
        dateToApply: string;
        creditApplied: number;
        creditAmount: number;
        isArchived: boolean;
      }> | null;
    }> | null;
  } | null;
};

export type SpendPastDueInvoicesQueryVariables = Exact<{
  pagination?: InputMaybe<SpendPaginationInput>;
  interval?: InputMaybe<SpendPastDueInterval>;
  sort?: InputMaybe<SpendPastDueSort>;
}>;

export type SpendPastDueInvoicesQuery = {
  __typename?: "Query";
  spendPastDueInvoices?: {
    __typename?: "SpendPastDueInvoicesOutput";
    count: number;
    invoices: Array<{
      __typename?: "SpendPastDueInvoice";
      groupName: string;
      email: string;
      name: string;
      groupId: string;
      status: string;
      rosterId: string;
      amount: number;
      seasonName: string;
      seasonId: string;
      description: string;
      dueDate: string;
      guardianStatus: string;
      discountAmount: number;
      creditMemos?: Array<{
        __typename?: "creditMemoAmounts";
        creditApplied?: number | null;
      } | null> | null;
    } | null>;
  } | null;
};

export type SpendGroupsOverviewDashboardQueryVariables = Exact<{
  pagination?: InputMaybe<SpendPaginationInput>;
}>;

export type SpendGroupsOverviewDashboardQuery = {
  __typename?: "Query";
  spendGroupsOverviewDashboard?: {
    __typename?: "GroupsOverviewDashboardResponse";
    count: number;
    overview?: Array<{
      __typename?: "SpendGroupsOverview";
      groupId?: string | null;
      name?: string | null;
      seasonName?: string | null;
      seasonStartAt?: string | null;
      seasonEndAt?: string | null;
      balance?: number | null;
      collected?: number | null;
      pastDue?: number | null;
      sharedAccount?: boolean | null;
      cards?: Array<{
        __typename?: "SpendDebitCard";
        expiration?: string | null;
        id?: string | null;
        lastFour?: string | null;
        status?: string | null;
      } | null> | null;
    } | null> | null;
  } | null;
};

export type SpendOrganizationQueryVariables = Exact<{ [key: string]: never }>;

export type SpendOrganizationQuery = {
  __typename?: "Query";
  spendOrganization?: {
    __typename?: "SpendOrganization";
    email: string;
    id?: string | null;
    isVerified?: boolean | null;
    legalName: string;
    nickname?: string | null;
    logo?: string | null;
    orgId?: string | null;
    phone: string;
    userId?: string | null;
    website?: string | null;
    hasLinkedAccount?: boolean | null;
    externalTransferOutEnabled?: boolean | null;
    city: string;
    state: string;
    street: string;
    street2?: string | null;
    zip: string;
    cardBaseFee?: number | null;
    cardPercent?: number | null;
    spendBaseFee?: number | null;
    spendPercent?: number | null;
    achBaseFee?: number | null;
    achPercent?: number | null;
    legacyAccountId?: string | null;
    owner?: {
      __typename?: "User";
      email?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      id?: string | null;
    } | null;
    debitCards?: {
      __typename?: "SpendOrganizationDebitCardCount";
      activated?: number | null;
      assigned?: number | null;
      shipped?: number | null;
      total?: number | null;
    } | null;
    budgets?: Array<{
      __typename?: "SpendBudget";
      createdAt: string;
      description: string;
      id: string;
      isDefault?: boolean | null;
      targetAmount: number;
      targetDateAt: string;
      updatedAt?: string | null;
      category: {
        __typename?: "SpendCategory";
        id?: string | null;
        type: string;
        createdAt: string;
        isDefault: boolean;
        isHidden: boolean;
        name: string;
        organizationId: string;
      };
    } | null> | null;
    groups?: Array<{
      __typename?: "SpendGroup";
      name?: string | null;
      id?: string | null;
      isArchived?: boolean | null;
      hasAccount?: boolean | null;
      sharedAccount?: boolean | null;
    } | null> | null;
  } | null;
};

export type SpendOrganizationDebitCardsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type SpendOrganizationDebitCardsQuery = {
  __typename?: "Query";
  spendOrganizationDebitCards?: {
    __typename?: "SpendDebitCardResponse";
    count?: number | null;
    cards?: Array<{
      __typename?: "SpendDebitCard";
      expiration?: string | null;
      id?: string | null;
      lastFour?: string | null;
      status?: string | null;
      userId?: string | null;
    } | null> | null;
  } | null;
};

export type SpendOrganizationAccountsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type SpendOrganizationAccountsQuery = {
  __typename?: "Query";
  spendOrganizationAccounts?: {
    __typename?: "SpendAccountResponse";
    count?: number | null;
    totalBalance?: number | null;
    accounts?: Array<{
      __typename?: "SpendAccount";
      available?: number | null;
      balance?: number | null;
      id?: string | null;
      name?: string | null;
      status?: string | null;
      type?: string | null;
      groupStatus?: string | null;
      groupId?: string | null;
    } | null> | null;
    groupsBalance?: {
      __typename?: "SpendGroupAccountBalances";
      active?: number | null;
      archived?: number | null;
    } | null;
  } | null;
};

export type SpendOrganizationBankAccountsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type SpendOrganizationBankAccountsQuery = {
  __typename?: "Query";
  spendOrganizationBankAccounts?: {
    __typename?: "SpendBankAccountsResponse";
    status?: string | null;
    externalAccounts?: Array<{
      __typename?: "SpendBankAccount";
      accountId?: string | null;
      availableBalance?: number | null;
      currentBalance?: number | null;
      mask?: string | null;
      name?: string | null;
      officialName?: string | null;
      subtype?: string | null;
      type?: string | null;
      institution?: {
        __typename?: "SpendInstitution";
        id?: string | null;
        name?: string | null;
      } | null;
    } | null> | null;
  } | null;
};

export type SpendOrgSettingsQueryVariables = Exact<{ [key: string]: never }>;

export type SpendOrgSettingsQuery = {
  __typename?: "Query";
  spendSettings?: {
    __typename?: "SpendSettings";
    enableGroupBanks?: boolean | null;
    enableProgramAgreements?: boolean | null;
    notifyBankActivityAdmins?: boolean | null;
    notifyDueFrequencies?: Array<string> | null;
    notifyFailedAchAdmins?: boolean | null;
    notifyPastDueAdmins?: boolean | null;
    notifyPastDueNonUsers?: boolean | null;
    notifyUpcomingNonUsers?: boolean | null;
    pastDueFrequency?: number | null;
    signUpLink?: string | null;
    internalTransferApproval?: boolean | null;
    internalTransferLimit?: number | null;
    externalTransferApproval?: boolean | null;
    externalTransferLimit?: number | null;
    sendCheckApproval?: boolean | null;
    sendCheckLimit?: number | null;
    debitCardApproval?: boolean | null;
    signUpAgreement?: {
      __typename?: "SignupAgreement";
      content?: string | null;
      name?: string | null;
      createdAt?: string | null;
    } | null;
  } | null;
};

export type SpendAccountExportQueryVariables = Exact<{
  status: Scalars["String"];
}>;

export type SpendAccountExportQuery = {
  __typename?: "Query";
  spendAccountExport?: {
    __typename?: "ExportFile";
    content?: string | null;
    fileName?: string | null;
  } | null;
};

export type SpendOrganizationPayeesQueryVariables = Exact<{
  orgId: Scalars["String"];
}>;

export type SpendOrganizationPayeesQuery = {
  __typename?: "Query";
  spendOrganizationPayees?: {
    __typename?: "SpendPayeeResponse";
    payees?: Array<{
      __typename?: "SpendPayees";
      id: string;
      name: string;
      address1: string;
      address2?: string | null;
      city: string;
      state: string;
      zipCode: string;
    }> | null;
  } | null;
};

export type SpendOrganizationAccountLimitsQueryVariables = Exact<{
  groupId?: InputMaybe<Scalars["String"]>;
}>;

export type SpendOrganizationAccountLimitsQuery = {
  __typename?: "Query";
  spendOrganizationAccountLimits?: {
    __typename?: "SpendAccountLimitsResponse";
    ach?: {
      __typename?: "SpendAchLimitsData";
      limits: {
        __typename?: "SpendAchLimits";
        dailyDebit?: number | null;
        dailyCredit?: number | null;
        monthlyDebit?: number | null;
        monthlyCredit?: number | null;
      };
    } | null;
    bookTransfer?: {
      __typename?: "SpendBookTransferLimitsData";
      limits: {
        __typename?: "SpendAchLimits";
        dailyDebit?: number | null;
        dailyCredit?: number | null;
        monthlyDebit?: number | null;
        monthlyCredit?: number | null;
      };
    } | null;
    card?: {
      __typename?: "SpendCardLimitsData";
      limits: {
        __typename?: "SpendCardLimits";
        dailyWithdrawal: number;
        dailyDeposit: number;
        dailyPurchase: number;
        dailyCardTransaction: number;
      };
    } | null;
    checkDeposit?: {
      __typename?: "SpendCheckDepositLimitsData";
      limits: {
        __typename?: "SpendCheckDepositLimits";
        daily: number;
        monthly: number;
      };
    } | null;
    wire?: {
      __typename?: "SpendWireLimitsData";
      limits?: {
        __typename?: "SpendWireLimits";
        dailyTransfer: number;
        monthlyTransfer: number;
        dailyTransferSoft: number;
        monthlyTransferSoft: number;
      } | null;
    } | null;
    checkPayment?: {
      __typename?: "SpendCheckPaymentLimitData";
      limits?: {
        __typename?: "SpendCheckPaymentLimit";
        dailySent: number;
        monthlySent: number;
      } | null;
    } | null;
  } | null;
};

export type SpendGetOrganizationCounterpartiesQueryVariables = Exact<{
  groupOrOrgId: Scalars["String"];
}>;

export type SpendGetOrganizationCounterpartiesQuery = {
  __typename?: "Query";
  spendGetOrganizationCounterparties?: {
    __typename?: "SpendCounterpartiesResponse";
    counterparties: Array<{
      __typename?: "SpendCounterparty";
      id: string;
      name: string;
      bankName: string;
      accountLastFour: string;
      routingNumber: string;
    }>;
  } | null;
};

export type SpendPaymentScheduleByIdQueryVariables = Exact<{
  spendPaymentScheduleByIdId: Scalars["String"];
}>;

export type SpendPaymentScheduleByIdQuery = {
  __typename?: "Query";
  spendPaymentScheduleById?: {
    __typename?: "SpendPaymentSchedule";
    amountDue?: number | null;
    budgetItemId?: string | null;
    description?: string | null;
    dueDate?: string | null;
    groupId?: string | null;
    id?: string | null;
    isOptional?: boolean | null;
    note?: string | null;
    seasonId?: string | null;
    status?: string | null;
  } | null;
};

export type SpendPaymentSchedulesQueryVariables = Exact<{
  [key: string]: never;
}>;

export type SpendPaymentSchedulesQuery = {
  __typename?: "Query";
  spendPaymentSchedules?: Array<{
    __typename?: "SpendPaymentSchedule";
    amountDue?: number | null;
    budgetItemId?: string | null;
    description?: string | null;
    dueDate?: string | null;
    groupId?: string | null;
    id?: string | null;
    isOptional?: boolean | null;
    note?: string | null;
    seasonId?: string | null;
    status?: string | null;
  } | null> | null;
};

export type SpendPaymentSchedulesByGroupOrSeasonQueryVariables = Exact<{
  groupIdOrSeasonId?: InputMaybe<Scalars["String"]>;
}>;

export type SpendPaymentSchedulesByGroupOrSeasonQuery = {
  __typename?: "Query";
  spendPaymentSchedules?: Array<{
    __typename?: "SpendPaymentSchedule";
    id?: string | null;
    amountDue?: number | null;
    dueDate?: string | null;
    description?: string | null;
    status?: string | null;
    note?: string | null;
    isOptional?: boolean | null;
    budgetItemId?: string | null;
    seasonId?: string | null;
    groupId?: string | null;
    budgetItem?: {
      __typename?: "SpendBudget";
      id: string;
      description: string;
      targetAmount: number;
      targetDateAt: string;
      vaultId?: string | null;
      isDefault?: boolean | null;
      createdAt: string;
      updatedAt?: string | null;
      reconciledBudgetTotal?: number | null;
      reconciledInvoicesTotal?: number | null;
      reconciledTotal?: number | null;
      category: {
        __typename?: "SpendCategory";
        name: string;
        type: string;
        id?: string | null;
      };
      invoices?: Array<{
        __typename?: "SpendInvoice";
        balanceDue?: number | null;
        description?: string | null;
        paid?: boolean | null;
        id?: string | null;
        amount?: number | null;
        reconciledTransactions?: Array<{
          __typename?: "SpendReconciledInvoiceTransaction";
          amount?: number | null;
          id?: string | null;
          invoiceId?: string | null;
          transactionId?: string | null;
        } | null> | null;
      }> | null;
    } | null;
    season?: {
      __typename?: "SpendSeason";
      id?: string | null;
      name?: string | null;
      startDateAt?: string | null;
      endDateAt?: string | null;
    } | null;
    group?: {
      __typename?: "SpendGroup";
      id?: string | null;
      name?: string | null;
      organizationId?: string | null;
      programId?: string | null;
      createdAt?: string | null;
      archivedAt?: string | null;
      hasAccount?: boolean | null;
    } | null;
  } | null> | null;
};

export type MyPermissionsQueryVariables = Exact<{ [key: string]: never }>;

export type MyPermissionsQuery = {
  __typename?: "Query";
  me?: {
    __typename?: "UserWithPermissions";
    email?: string | null;
    permissions?: Array<string | null> | null;
    id?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    phoneNumber?: string | null;
    parents?: Array<string | null> | null;
  } | null;
};

export type SpendUserBankLinkTokenCreateQueryVariables = Exact<{
  [key: string]: never;
}>;

export type SpendUserBankLinkTokenCreateQuery = {
  __typename?: "Query";
  spendUserBankLinkTokenCreate?: {
    __typename?: "SpendBankLinkCreateResponse";
    expiration?: string | null;
    requestId?: string | null;
    linkToken?: string | null;
  } | null;
};

export type SpendGroupBankLinkTokenCreateQueryVariables = Exact<{
  spendGroupBankLinkTokenCreateId?: InputMaybe<Scalars["String"]>;
}>;

export type SpendGroupBankLinkTokenCreateQuery = {
  __typename?: "Query";
  spendGroupBankLinkTokenCreate?: {
    __typename?: "SpendBankLinkCreateResponse";
    expiration?: string | null;
    linkToken?: string | null;
    requestId?: string | null;
  } | null;
};

export type SpendOrganizationBankLinkTokenCreateQueryVariables = Exact<{
  [key: string]: never;
}>;

export type SpendOrganizationBankLinkTokenCreateQuery = {
  __typename?: "Query";
  spendOrganizationBankLinkTokenCreate?: {
    __typename?: "SpendBankLinkCreateResponse";
    linkToken?: string | null;
    expiration?: string | null;
    requestId?: string | null;
  } | null;
};

export type SpendRostersFilteredQueryVariables = Exact<{
  where?: InputMaybe<SpendGroupRostersWhereInput>;
  pagination?: InputMaybe<SpendPaginationInput>;
  sort?: InputMaybe<SpendSortInput>;
}>;

export type SpendRostersFilteredQuery = {
  __typename?: "Query";
  spendRostersFiltered?: {
    __typename?: "SpendRosterResponse";
    count?: number | null;
    rosters?: Array<{
      __typename?: "SpendRoster";
      name?: string | null;
      id?: string | null;
      email?: string | null;
      groupRosters?: Array<{
        __typename?: "SpendGroupRoster";
        id?: string | null;
        groupId?: string | null;
        userId?: string | null;
        seasonId?: string | null;
        rosterId?: string | null;
        inviteId?: string | null;
        isArchived?: boolean | null;
        createdAt?: string | null;
        joinedAt?: string | null;
        status?: SpendGroupRosterStatusEnum | null;
        invite?: {
          __typename?: "SpendInvite";
          id?: string | null;
          status?: string | null;
          expiresAt?: string | null;
        } | null;
        group?: {
          __typename?: "SpendGroup";
          id?: string | null;
          name?: string | null;
          latestSeason?: {
            __typename?: "SpendSeason";
            id?: string | null;
            name?: string | null;
            startDateAt?: string | null;
            endDateAt?: string | null;
          } | null;
          seasons?: Array<{
            __typename?: "SpendSeason";
            id?: string | null;
            name?: string | null;
            startDateAt?: string | null;
            endDateAt?: string | null;
          } | null> | null;
        } | null;
        total?: {
          __typename?: "SpendTransactionTotals";
          pastDue?: number | null;
          upcoming?: number | null;
          paid?: number | null;
          dueToday?: number | null;
          credited?: number | null;
          processing?: number | null;
          pastDueDays?: number | null;
        } | null;
      } | null> | null;
    } | null> | null;
  } | null;
};

export type SpendRostersQueryVariables = Exact<{ [key: string]: never }>;

export type SpendRostersQuery = {
  __typename?: "Query";
  spendRosters?: {
    __typename?: "SpendRosterResponse";
    rosters?: Array<{
      __typename?: "SpendRoster";
      id?: string | null;
      name?: string | null;
      email?: string | null;
      groupRosters?: Array<{
        __typename?: "SpendGroupRoster";
        userId?: string | null;
        inviteId?: string | null;
        seasonId?: string | null;
        season?: {
          __typename?: "SpendSeason";
          name?: string | null;
          startDateAt?: string | null;
          endDateAt?: string | null;
        } | null;
        group?: {
          __typename?: "SpendGroup";
          name?: string | null;
          id?: string | null;
        } | null;
        invoices?: Array<{
          __typename?: "SpendInvoice";
          amount?: number | null;
          balanceDue?: number | null;
          paid?: boolean | null;
          dueDate?: string | null;
        } | null> | null;
        invite?: { __typename?: "SpendInvite"; status?: string | null } | null;
      } | null> | null;
    } | null> | null;
  } | null;
};

export type GetParticipantsDetailsQueryVariables = Exact<{
  filterBy?: InputMaybe<GroupRosterFilterEnum>;
  filterValue?: InputMaybe<Scalars["String"]>;
}>;

export type GetParticipantsDetailsQuery = {
  __typename?: "Query";
  spendGroupRosters?: {
    __typename?: "SpendGroupRosterResponse";
    count?: number | null;
    groupRosters?: Array<{
      __typename?: "SpendGroupRoster";
      id?: string | null;
      userId?: string | null;
      rosterId?: string | null;
      isArchived?: boolean | null;
      joinedAt?: string | null;
      phoneNumber?: string | null;
      guardianName?: string | null;
      seasonId?: string | null;
      invoices?: Array<{
        __typename?: "SpendInvoice";
        id?: string | null;
        amount?: number | null;
        description?: string | null;
        paid?: boolean | null;
        paidDate?: string | null;
        paymentMethodSource?: string | null;
        isOptional?: boolean | null;
        dueDate?: string | null;
        status?: string | null;
        balanceDue?: number | null;
        note?: string | null;
        feesAmount?: number | null;
        discountAmount?: number | null;
        budgetItemId?: string | null;
        isRefunded?: boolean | null;
        creditMemos?: Array<{
          __typename?: "SpendMemo";
          creditAmount: number;
          creditApplied: number;
          dateToApply: string;
          id: string;
          isArchived: boolean;
          note?: string | null;
          title: string;
        }> | null;
        reconciledTransactions?: Array<{
          __typename?: "SpendReconciledInvoiceTransaction";
          note?: string | null;
        } | null> | null;
      } | null> | null;
      invite?: {
        __typename?: "SpendInvite";
        email?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        status?: string | null;
      } | null;
      roster?: {
        __typename?: "SpendRoster";
        name?: string | null;
        email?: string | null;
      } | null;
      group?: {
        __typename?: "SpendGroup";
        name?: string | null;
        accountId?: string | null;
        id?: string | null;
        sharedAccount?: boolean | null;
        latestSeason?: {
          __typename?: "SpendSeason";
          name?: string | null;
          startDateAt?: string | null;
          endDateAt?: string | null;
        } | null;
        seasons?: Array<{
          __typename?: "SpendSeason";
          id?: string | null;
          name?: string | null;
          startDateAt?: string | null;
          endDateAt?: string | null;
        } | null> | null;
      } | null;
    } | null> | null;
  } | null;
};

export type SpendGroupRostersBySeasonQueryVariables = Exact<{
  seasonId: Scalars["String"];
  pagination?: InputMaybe<SpendPaginationInput>;
}>;

export type SpendGroupRostersBySeasonQuery = {
  __typename?: "Query";
  spendGroupRostersBySeason?: {
    __typename?: "SpendGroupRosterResponse";
    count?: number | null;
    groupRosters?: Array<{
      __typename?: "SpendGroupRoster";
      id?: string | null;
      userId?: string | null;
      isArchived?: boolean | null;
      rosterId?: string | null;
      seasonId?: string | null;
      invite?: {
        __typename?: "SpendInvite";
        id?: string | null;
        email?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        status?: string | null;
        createdAt?: string | null;
        expiresAt?: string | null;
        organizationId?: string | null;
        userId?: string | null;
        isUser?: boolean | null;
        groupId?: string | null;
        type?: string | null;
        isDeliverable?: boolean | null;
        isArchived?: boolean | null;
      } | null;
      roster?: {
        __typename?: "SpendRoster";
        id?: string | null;
        name?: string | null;
        email?: string | null;
      } | null;
      total?: {
        __typename?: "SpendTransactionTotals";
        paid?: number | null;
        upcoming?: number | null;
        pastDue?: number | null;
        credited?: number | null;
        dueToday?: number | null;
        processing?: number | null;
        statuses?: Array<string | null> | null;
      } | null;
      group?: {
        __typename?: "SpendGroup";
        id?: string | null;
        name?: string | null;
      } | null;
    } | null> | null;
  } | null;
};

export type SpendGroupRostersBySeasonV2QueryVariables = Exact<{
  seasonId: Scalars["String"];
  sort?: InputMaybe<SpendSortInput>;
  pagination?: InputMaybe<SpendPaginationInput>;
}>;

export type SpendGroupRostersBySeasonV2Query = {
  __typename?: "Query";
  spendGroupRostersBySeasonV2?: {
    __typename?: "SpendGroupRosterResponse";
    count?: number | null;
    groupRosters?: Array<{
      __typename?: "SpendGroupRoster";
      id?: string | null;
      userId?: string | null;
      isArchived?: boolean | null;
      rosterId?: string | null;
      seasonId?: string | null;
      invite?: {
        __typename?: "SpendInvite";
        id?: string | null;
        email?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        status?: string | null;
        createdAt?: string | null;
        expiresAt?: string | null;
        organizationId?: string | null;
        userId?: string | null;
        isUser?: boolean | null;
        groupId?: string | null;
        type?: string | null;
        isDeliverable?: boolean | null;
        isArchived?: boolean | null;
      } | null;
      roster?: {
        __typename?: "SpendRoster";
        id?: string | null;
        name?: string | null;
        email?: string | null;
      } | null;
      total?: {
        __typename?: "SpendTransactionTotals";
        paid?: number | null;
        upcoming?: number | null;
        pastDue?: number | null;
        credited?: number | null;
        dueToday?: number | null;
        processing?: number | null;
        statuses?: Array<string | null> | null;
      } | null;
      group?: {
        __typename?: "SpendGroup";
        id?: string | null;
        name?: string | null;
      } | null;
    } | null> | null;
  } | null;
};

export type GetSpendRosterQueryVariables = Exact<{
  filterValue?: InputMaybe<Scalars["String"]>;
}>;

export type GetSpendRosterQuery = {
  __typename?: "Query";
  spendGroupRosters?: {
    __typename?: "SpendGroupRosterResponse";
    count?: number | null;
    groupRosters?: Array<{
      __typename?: "SpendGroupRoster";
      inviteId?: string | null;
      archivedAt?: string | null;
      createdAt?: string | null;
      groupId?: string | null;
      id?: string | null;
      isArchived?: boolean | null;
      seasonId?: string | null;
      rosterId?: string | null;
      userId?: string | null;
      invite?: {
        __typename?: "SpendInvite";
        status?: string | null;
        isArchived?: boolean | null;
        id?: string | null;
      } | null;
      roster?: {
        __typename?: "SpendRoster";
        name?: string | null;
        email?: string | null;
        id?: string | null;
      } | null;
      group?: {
        __typename?: "SpendGroup";
        id?: string | null;
        name?: string | null;
        accountId?: string | null;
      } | null;
      season?: {
        __typename?: "SpendSeason";
        id?: string | null;
        name?: string | null;
        startDateAt?: string | null;
        endDateAt?: string | null;
      } | null;
      invoices?: Array<{
        __typename?: "SpendInvoice";
        id?: string | null;
        amount?: number | null;
        paid?: boolean | null;
        paidDate?: string | null;
        dueDate?: string | null;
        balanceDue?: number | null;
        description?: string | null;
        status?: string | null;
        transaction?: {
          __typename?: "SpendTransaction";
          id?: string | null;
          invoiceId?: string | null;
          externalId?: string | null;
        } | null;
        reconciledTransactions?: Array<{
          __typename?: "SpendReconciledInvoiceTransaction";
          id?: string | null;
        } | null> | null;
      } | null> | null;
    } | null> | null;
  } | null;
};

export type SpendSeasonQueryVariables = Exact<{
  spendSeasonId: Scalars["String"];
}>;

export type SpendSeasonQuery = {
  __typename?: "Query";
  spendSeason?: {
    __typename?: "SpendSeason";
    id?: string | null;
    endDateAt?: string | null;
    groupId?: string | null;
    isActive?: boolean | null;
    isBudgetShared?: boolean | null;
    isLinkEnabled?: boolean | null;
    name?: string | null;
    paymentScheduleStatus?: string | null;
    playerCount?: number | null;
    startDateAt?: string | null;
    transactionTotals?: {
      __typename?: "SpendTransactionTotals";
      credited?: number | null;
      dueToday?: number | null;
      paid?: number | null;
      pastDue?: number | null;
      pastDueDays?: number | null;
      processing?: number | null;
      upcoming?: number | null;
      statuses?: Array<string | null> | null;
    } | null;
  } | null;
};

export type SpendSettingsQueryVariables = Exact<{ [key: string]: never }>;

export type SpendSettingsQuery = {
  __typename?: "Query";
  spendSettings?: {
    __typename?: "SpendSettings";
    signUpAgreement?: {
      __typename?: "SignupAgreement";
      content?: string | null;
      name?: string | null;
    } | null;
  } | null;
};

export type SpendUserNotificationSettingQueryVariables = Exact<{
  [key: string]: never;
}>;

export type SpendUserNotificationSettingQuery = {
  __typename?: "Query";
  spendUserNotificationSetting?: {
    __typename?: "SpendUserNotificationSettingResponse";
    notificationSetting?: {
      __typename?: "SpendUserNotificationSetting";
      id: string;
      notifyOnFailedCardPayments: boolean;
      notifyOnBankActivity: boolean;
      copyPastDueInvoices: boolean;
      userRoleId: string;
      organizationId?: string | null;
      roleName?: string | null;
      groupId?: string | null;
    } | null;
  } | null;
};

export type GetNotesAndAttachmentsQueryVariables = Exact<{
  id: Scalars["String"];
}>;

export type GetNotesAndAttachmentsQuery = {
  __typename?: "Query";
  spendTransactionNotesByPaymentId?: {
    __typename?: "TransactionNotesResponse";
    notes?: Array<{
      __typename?: "TransactionNotes";
      content?: string | null;
      createdAt?: string | null;
      id?: string | null;
      lastUpdatedByUserId?: string | null;
      paymentId?: string | null;
      updatedAt?: string | null;
    } | null> | null;
  } | null;
  spendTransactionAttachmentsByPaymentId?: {
    __typename?: "TransactionAttachmentsResponse";
    attachments?: Array<{
      __typename?: "TransactionAttachment";
      createdAt?: string | null;
      description?: string | null;
      id?: string | null;
      lastUpdatedByUserId?: string | null;
      name?: string | null;
      paymentId?: string | null;
      updatedAt?: string | null;
      url?: string | null;
    } | null> | null;
  } | null;
};

export type GetNotesQueryVariables = Exact<{
  id?: InputMaybe<Scalars["String"]>;
}>;

export type GetNotesQuery = {
  __typename?: "Query";
  spendTransactionNotesByPaymentId?: {
    __typename?: "TransactionNotesResponse";
    notes?: Array<{
      __typename?: "TransactionNotes";
      content?: string | null;
      createdAt?: string | null;
      id?: string | null;
      lastUpdatedByUserId?: string | null;
      paymentId?: string | null;
      updatedAt?: string | null;
    } | null> | null;
  } | null;
};

export type GetAttachmentsQueryVariables = Exact<{
  id: Scalars["String"];
}>;

export type GetAttachmentsQuery = {
  __typename?: "Query";
  spendTransactionAttachmentsByPaymentId?: {
    __typename?: "TransactionAttachmentsResponse";
    attachments?: Array<{
      __typename?: "TransactionAttachment";
      createdAt?: string | null;
      description?: string | null;
      id?: string | null;
      lastUpdatedByUserId?: string | null;
      name?: string | null;
      paymentId?: string | null;
      updatedAt?: string | null;
      url?: string | null;
    } | null> | null;
  } | null;
};

export type SpendTransactionsFilteredQueryVariables = Exact<{
  filters?: InputMaybe<
    | Array<InputMaybe<SpendTransactionFilter>>
    | InputMaybe<SpendTransactionFilter>
  >;
  pagination?: InputMaybe<SpendPaginationInput>;
  disableCache?: InputMaybe<Scalars["Boolean"]>;
  allAccounts?: InputMaybe<Scalars["Boolean"]>;
  sort?: InputMaybe<SpendSortInput>;
}>;

export type SpendTransactionsFilteredQuery = {
  __typename?: "Query";
  spendTransactionsFiltered?: {
    __typename?: "SpendBankTransactionsResponse";
    count?: number | null;
    transactions?: Array<{
      __typename?: "SpendBankTransaction";
      id?: string | null;
      processor?: string | null;
      externalId?: string | null;
      direction?: string | null;
      description?: string | null;
      type?: string | null;
      amount?: number | null;
      snapAmount?: number | null;
      effective?: string | null;
      correlationId?: string | null;
      source?: string | null;
      destination?: string | null;
      transactionType?: string | null;
      transactionStatus?: string | null;
      transactionNote?: string | null;
      status?: string | null;
      metadata?: {
        __typename?: "SpendBankTransactionMetaData";
        account?: string | null;
        addenda?: string | null;
        customer?: string | null;
        description?: string | null;
        destination?: string | null;
        externalId?: string | null;
        payment?: string | null;
        processor?: string | null;
        source?: string | null;
        summary?: string | null;
        tags?: {
          __typename?: "SpendTransactionTags";
          spendDestinationId?: string | null;
          spendExternalId?: string | null;
          spendInvoiceIds?: string | null;
          spendPaymentType?: string | null;
          spendOriginalPaymentId?: string | null;
          spendSourceId?: string | null;
        } | null;
      } | null;
      reconciliation?: {
        __typename?: "SpendReconciledTransaction";
        amount?: number | null;
        createdAt?: string | null;
        id?: string | null;
        type?: string | null;
        updatedAt?: string | null;
      } | null;
      groupRoster?: {
        __typename?: "SpendGroupRoster";
        group?: { __typename?: "SpendGroup"; name?: string | null } | null;
        roster?: { __typename?: "SpendRoster"; name?: string | null } | null;
      } | null;
      attachments?: Array<{
        __typename?: "TransactionAttachment";
        id?: string | null;
        name?: string | null;
        url?: string | null;
      }> | null;
      payee?: {
        __typename?: "SpendJoinedPayee";
        payeeId: string;
        referenceId: string;
        organizationName?: string | null;
        groupName?: string | null;
        payeeName: string;
      } | null;
      external?: {
        __typename?: "SpendJoinedExternal";
        externalId: string;
        invoiceDescription: string;
        playerName: string;
        groupName: string;
        seasonName: string;
      } | null;
      creditMemo?: {
        __typename?: "SpendMemoTransaction";
        id: string;
        title: string;
        note?: string | null;
        dateToApply: string;
        creditApplied: number;
        creditAmount: number;
        isArchived: boolean;
        groupName?: string | null;
        invoice?: {
          __typename?: "SpendMemoInvoice";
          id: string;
          description: string;
          participantName: string;
          rosterId: string;
          groupName: string;
          groupId: string;
          status: string;
          dueDate: string;
          seasonId: string;
          seasonName: string;
          amount: number;
          balanceDue: number;
          discountAmount: number;
        } | null;
      } | null;
    } | null> | null;
  } | null;
};

export type SpendTransactionsWheredQueryVariables = Exact<{
  pagination?: InputMaybe<SpendPaginationInput>;
  where?: InputMaybe<SpendTransactionWhereInput>;
  disableCache?: InputMaybe<Scalars["Boolean"]>;
}>;

export type SpendTransactionsWheredQuery = {
  __typename?: "Query";
  spendTransactionsFiltered?: {
    __typename?: "SpendBankTransactionsResponse";
    count?: number | null;
    transactions?: Array<{
      __typename?: "SpendBankTransaction";
      id?: string | null;
      processor?: string | null;
      externalId?: string | null;
      description?: string | null;
      direction?: string | null;
      type?: string | null;
      amount?: number | null;
      snapAmount?: number | null;
      effective?: string | null;
      correlationId?: string | null;
      source?: string | null;
      destination?: string | null;
      hasAttachments?: boolean | null;
      transactionType?: string | null;
      transactionStatus?: string | null;
      transactionNote?: string | null;
      isReconciled?: boolean | null;
      totalReconciled?: number | null;
      typeReconciled?: string | null;
      status?: string | null;
      metadata?: {
        __typename?: "SpendBankTransactionMetaData";
        account?: string | null;
        addenda?: string | null;
        customer?: string | null;
        description?: string | null;
        destination?: string | null;
        externalId?: string | null;
        payment?: string | null;
        processor?: string | null;
        source?: string | null;
        summary?: string | null;
        tags?: {
          __typename?: "SpendTransactionTags";
          spendDestinationId?: string | null;
          spendExternalId?: string | null;
          spendInvoiceIds?: string | null;
          spendPaymentType?: string | null;
          spendOriginalPaymentId?: string | null;
          spendSourceId?: string | null;
        } | null;
      } | null;
      reconciliation?: {
        __typename?: "SpendReconciledTransaction";
        amount?: number | null;
        createdAt?: string | null;
        id?: string | null;
        type?: string | null;
        updatedAt?: string | null;
        budgetTransactions?: Array<{
          __typename?: "SpendReconciledBudgetTransaction";
          amount?: number | null;
          id?: string | null;
          budgetItemId?: string | null;
          transactionId?: string | null;
        } | null> | null;
        invoiceTransactions?: Array<{
          __typename?: "SpendReconciledInvoiceTransaction";
          amount?: number | null;
          id?: string | null;
          invoiceId?: string | null;
          transactionId?: string | null;
          rosterName?: string | null;
        } | null> | null;
        reconciledTo?: Array<{
          __typename?: "SpendReconciledBudget";
          amount: number;
          description: string;
          type: string;
          invoiceId?: string | null;
          budgetId: string;
          groupName: string;
          seasonName: string;
          seasonId: string;
        } | null> | null;
      } | null;
      groupRoster?: {
        __typename?: "SpendGroupRoster";
        group?: { __typename?: "SpendGroup"; name?: string | null } | null;
        roster?: { __typename?: "SpendRoster"; name?: string | null } | null;
      } | null;
      attachments?: Array<{
        __typename?: "TransactionAttachment";
        id?: string | null;
        name?: string | null;
        url?: string | null;
      }> | null;
      payee?: {
        __typename?: "SpendJoinedPayee";
        payeeId: string;
        referenceId: string;
        organizationName?: string | null;
        groupName?: string | null;
        payeeName: string;
      } | null;
      external?: {
        __typename?: "SpendJoinedExternal";
        externalId: string;
        invoiceDescription: string;
        playerName: string;
        groupName: string;
        seasonName: string;
      } | null;
    } | null> | null;
  } | null;
};

export type SpendTransactionsCompletedWheredQueryVariables = Exact<{
  pagination?: InputMaybe<SpendPaginationInput>;
  where?: InputMaybe<SpendTransactionWhereInput>;
  disableCache?: InputMaybe<Scalars["Boolean"]>;
  dateRange?: InputMaybe<DateRangeEnum>;
}>;

export type SpendTransactionsCompletedWheredQuery = {
  __typename?: "Query";
  spendTransactionsFiltered?: {
    __typename?: "SpendBankTransactionsResponse";
    count?: number | null;
    transactions?: Array<{
      __typename?: "SpendBankTransaction";
      created?: string | null;
      id?: string | null;
      processor?: string | null;
      externalId?: string | null;
      description?: string | null;
      direction?: string | null;
      type?: string | null;
      amount?: number | null;
      snapAmount?: number | null;
      effective?: string | null;
      correlationId?: string | null;
      source?: string | null;
      destination?: string | null;
      hasAttachments?: boolean | null;
      transactionType?: string | null;
      transactionStatus?: string | null;
      transactionNote?: string | null;
      isReconciled?: boolean | null;
      totalReconciled?: number | null;
      typeReconciled?: string | null;
      status?: string | null;
      metadata?: {
        __typename?: "SpendBankTransactionMetaData";
        account?: string | null;
        addenda?: string | null;
        customer?: string | null;
        description?: string | null;
        destination?: string | null;
        externalId?: string | null;
        payment?: string | null;
        processor?: string | null;
        source?: string | null;
        summary?: string | null;
        tags?: {
          __typename?: "SpendTransactionTags";
          spendDestinationId?: string | null;
          spendExternalId?: string | null;
          spendInvoiceIds?: string | null;
          spendPaymentType?: string | null;
          spendOriginalPaymentId?: string | null;
          spendSourceId?: string | null;
        } | null;
      } | null;
      reconciliation?: {
        __typename?: "SpendReconciledTransaction";
        amount?: number | null;
        createdAt?: string | null;
        id?: string | null;
        type?: string | null;
        updatedAt?: string | null;
        budgetTransactions?: Array<{
          __typename?: "SpendReconciledBudgetTransaction";
          amount?: number | null;
          id?: string | null;
          budgetItemId?: string | null;
          transactionId?: string | null;
        } | null> | null;
        invoiceTransactions?: Array<{
          __typename?: "SpendReconciledInvoiceTransaction";
          amount?: number | null;
          id?: string | null;
          invoiceId?: string | null;
          transactionId?: string | null;
          rosterName?: string | null;
        } | null> | null;
        reconciledTo?: Array<{
          __typename?: "SpendReconciledBudget";
          amount: number;
          description: string;
          type: string;
          invoiceId?: string | null;
          budgetId: string;
          groupName: string;
          seasonName: string;
          seasonId: string;
        } | null> | null;
      } | null;
      groupRoster?: {
        __typename?: "SpendGroupRoster";
        group?: { __typename?: "SpendGroup"; name?: string | null } | null;
        roster?: { __typename?: "SpendRoster"; name?: string | null } | null;
      } | null;
      attachments?: Array<{
        __typename?: "TransactionAttachment";
        id?: string | null;
        name?: string | null;
        url?: string | null;
      }> | null;
      payee?: {
        __typename?: "SpendJoinedPayee";
        payeeId: string;
        referenceId: string;
        organizationName?: string | null;
        groupName?: string | null;
        payeeName: string;
      } | null;
      external?: {
        __typename?: "SpendJoinedExternal";
        externalId: string;
        invoiceDescription: string;
        playerName: string;
        groupName: string;
        seasonName: string;
      } | null;
    } | null> | null;
  } | null;
};

export type SpendTransactionsPendingWheredQueryVariables = Exact<{
  pagination?: InputMaybe<SpendPaginationInput>;
  where?: InputMaybe<SpendTransactionWhereInput>;
  disableCache?: InputMaybe<Scalars["Boolean"]>;
}>;

export type SpendTransactionsPendingWheredQuery = {
  __typename?: "Query";
  spendTransactionsFiltered?: {
    __typename?: "SpendBankTransactionsResponse";
    count?: number | null;
    transactions?: Array<{
      __typename?: "SpendBankTransaction";
      created?: string | null;
      id?: string | null;
      processor?: string | null;
      externalId?: string | null;
      description?: string | null;
      direction?: string | null;
      type?: string | null;
      amount?: number | null;
      snapAmount?: number | null;
      effective?: string | null;
      correlationId?: string | null;
      source?: string | null;
      destination?: string | null;
      hasAttachments?: boolean | null;
      transactionType?: string | null;
      transactionStatus?: string | null;
      transactionNote?: string | null;
      isReconciled?: boolean | null;
      totalReconciled?: number | null;
      typeReconciled?: string | null;
      status?: string | null;
      metadata?: {
        __typename?: "SpendBankTransactionMetaData";
        account?: string | null;
        addenda?: string | null;
        customer?: string | null;
        description?: string | null;
        destination?: string | null;
        externalId?: string | null;
        payment?: string | null;
        processor?: string | null;
        source?: string | null;
        summary?: string | null;
        tags?: {
          __typename?: "SpendTransactionTags";
          spendDestinationId?: string | null;
          spendExternalId?: string | null;
          spendInvoiceIds?: string | null;
          spendPaymentType?: string | null;
          spendOriginalPaymentId?: string | null;
          spendSourceId?: string | null;
        } | null;
      } | null;
      reconciliation?: {
        __typename?: "SpendReconciledTransaction";
        amount?: number | null;
        createdAt?: string | null;
        id?: string | null;
        type?: string | null;
        updatedAt?: string | null;
        budgetTransactions?: Array<{
          __typename?: "SpendReconciledBudgetTransaction";
          amount?: number | null;
          id?: string | null;
          budgetItemId?: string | null;
          transactionId?: string | null;
        } | null> | null;
        invoiceTransactions?: Array<{
          __typename?: "SpendReconciledInvoiceTransaction";
          amount?: number | null;
          id?: string | null;
          invoiceId?: string | null;
          transactionId?: string | null;
          rosterName?: string | null;
        } | null> | null;
        reconciledTo?: Array<{
          __typename?: "SpendReconciledBudget";
          amount: number;
          description: string;
          type: string;
          invoiceId?: string | null;
          budgetId: string;
          groupName: string;
          seasonName: string;
          seasonId: string;
        } | null> | null;
      } | null;
      groupRoster?: {
        __typename?: "SpendGroupRoster";
        group?: { __typename?: "SpendGroup"; name?: string | null } | null;
        roster?: { __typename?: "SpendRoster"; name?: string | null } | null;
      } | null;
      attachments?: Array<{
        __typename?: "TransactionAttachment";
        id?: string | null;
        name?: string | null;
        url?: string | null;
      }> | null;
      payee?: {
        __typename?: "SpendJoinedPayee";
        payeeId: string;
        referenceId: string;
        organizationName?: string | null;
        groupName?: string | null;
        payeeName: string;
      } | null;
      external?: {
        __typename?: "SpendJoinedExternal";
        externalId: string;
        invoiceDescription: string;
        playerName: string;
        groupName: string;
        seasonName: string;
      } | null;
    } | null> | null;
  } | null;
};

export type SpendTransactionsExportQueryVariables = Exact<{
  filters?: InputMaybe<
    | Array<InputMaybe<SpendTransactionFilter>>
    | InputMaybe<SpendTransactionFilter>
  >;
  limit?: InputMaybe<Scalars["Int"]>;
  allAccounts?: InputMaybe<Scalars["Boolean"]>;
}>;

export type SpendTransactionsExportQuery = {
  __typename?: "Query";
  spendTransactionsExport?: {
    __typename?: "ExportFile";
    content?: string | null;
    fileName?: string | null;
  } | null;
};

export type SpendTransactionsFilteredForParentDashQueryVariables = Exact<{
  pagination?: InputMaybe<SpendPaginationInput>;
}>;

export type SpendTransactionsFilteredForParentDashQuery = {
  __typename?: "Query";
  spendTransactionsFiltered?: {
    __typename?: "SpendBankTransactionsResponse";
    count?: number | null;
    transactions?: Array<{
      __typename?: "SpendBankTransaction";
      amount?: number | null;
      correlationId?: string | null;
      destination?: string | null;
      effective?: string | null;
      externalId?: string | null;
      direction?: string | null;
      id?: string | null;
      processor?: string | null;
      snapAmount?: number | null;
      source?: string | null;
      status?: string | null;
      type?: string | null;
      transactionType?: string | null;
      transactionStatus?: string | null;
      metadata?: {
        __typename?: "SpendBankTransactionMetaData";
        destination?: string | null;
        source?: string | null;
        description?: string | null;
        status?: string | null;
        summary?: string | null;
        processor?: string | null;
        payment?: string | null;
        externalId?: string | null;
        addenda?: string | null;
        tags?: {
          __typename?: "SpendTransactionTags";
          spendDestinationId?: string | null;
          spendExternalId?: string | null;
          spendInvoiceIds?: string | null;
          spendOriginalPaymentId?: string | null;
          spendPaymentType?: string | null;
          spendSourceId?: string | null;
          spendUserId?: string | null;
        } | null;
      } | null;
      groupRoster?: {
        __typename?: "SpendGroupRoster";
        group?: {
          __typename?: "SpendGroup";
          id?: string | null;
          name?: string | null;
        } | null;
        roster?: {
          __typename?: "SpendRoster";
          id?: string | null;
          name?: string | null;
        } | null;
      } | null;
      external?: {
        __typename?: "SpendJoinedExternal";
        externalId: string;
        invoiceDescription: string;
        playerName: string;
        groupName: string;
        seasonName: string;
      } | null;
      creditMemo?: {
        __typename?: "SpendMemoTransaction";
        id: string;
        title: string;
        note?: string | null;
        dateToApply: string;
        creditApplied: number;
        creditAmount: number;
        isArchived: boolean;
        groupName?: string | null;
        invoice?: {
          __typename?: "SpendMemoInvoice";
          id: string;
          description: string;
          participantName: string;
          rosterId: string;
          groupName: string;
          groupId: string;
          status: string;
          dueDate: string;
          seasonId: string;
          seasonName: string;
          amount: number;
          balanceDue: number;
          discountAmount: number;
        } | null;
      } | null;
    } | null> | null;
  } | null;
};

export type SpendTransactionByIdQueryVariables = Exact<{
  spendTransactionId?: InputMaybe<Scalars["String"]>;
}>;

export type SpendTransactionByIdQuery = {
  __typename?: "Query";
  spendTransaction?: {
    __typename?: "SpendBankTransactionResponse";
    transactionDetail?: {
      __typename?: "SpendBankTransactionDetail";
      id?: string | null;
      canCancel?: boolean | null;
      amount?: number | null;
      description?: string | null;
      destination?: string | null;
      direction?: string | null;
      effective?: string | null;
      externalId?: string | null;
      processor?: string | null;
      type?: string | null;
      status?: string | null;
      snapAmount?: number | null;
      metadata?: {
        __typename?: "SpendBankTransactionMetaData";
        account?: string | null;
        addenda?: string | null;
        customer?: string | null;
        description?: string | null;
        destination?: string | null;
        externalId?: string | null;
        payment?: string | null;
        processor?: string | null;
        snapAmount?: number | null;
        source?: string | null;
        summary?: string | null;
        status?: string | null;
        originalTransaction?: string | null;
        returnedTransaction?: string | null;
        tags?: {
          __typename?: "SpendTransactionTags";
          spendDestinationId?: string | null;
          spendExternalId?: string | null;
          spendInvoiceIds?: string | null;
          spendPaymentType?: string | null;
          spendAutoPay?: boolean | null;
          spendOriginalPaymentId?: string | null;
          spendUserId?: string | null;
          spendSourceId?: string | null;
        } | null;
      } | null;
      history?: Array<{
        __typename?: "SpendBankTransactionHistory";
        date?: string | null;
        status?: string | null;
        transactionId?: string | null;
      } | null> | null;
      reconciliation?: {
        __typename?: "SpendReconciledTransaction";
        amount?: number | null;
        createdAt?: string | null;
        id?: string | null;
        paymentId?: string | null;
        type?: string | null;
        updatedAt?: string | null;
        budgetTransactions?: Array<{
          __typename?: "SpendReconciledBudgetTransaction";
          budgetItemId?: string | null;
          amount?: number | null;
          id?: string | null;
          transactionId?: string | null;
        } | null> | null;
        invoiceTransactions?: Array<{
          __typename?: "SpendReconciledInvoiceTransaction";
          amount?: number | null;
          id?: string | null;
          invoiceId?: string | null;
          transactionId?: string | null;
          rosterName?: string | null;
          rosterId?: string | null;
          creditMemos?: Array<{
            __typename?: "SpendMemo";
            id: string;
            title: string;
            note?: string | null;
            dateToApply: string;
            creditApplied: number;
            creditAmount: number;
            isArchived: boolean;
          } | null> | null;
        } | null> | null;
        reconciledTo?: Array<{
          __typename?: "SpendReconciledBudget";
          amount: number;
          description: string;
          type: string;
          invoiceId?: string | null;
          budgetId: string;
          budgetName: string;
          categoryName: string;
          categoryId: string;
          groupName: string;
          seasonName: string;
          groupId?: string | null;
        } | null> | null;
      } | null;
      groupRoster?: {
        __typename?: "SpendGroupRoster";
        group?: { __typename?: "SpendGroup"; name?: string | null } | null;
        season?: { __typename?: "SpendSeason"; name?: string | null } | null;
      } | null;
    } | null;
  } | null;
};

export type SpendTransactionsLegacyQueryVariables = Exact<{
  pagination?: InputMaybe<SpendPaginationInput>;
  groupId?: InputMaybe<Scalars["String"]>;
}>;

export type SpendTransactionsLegacyQuery = {
  __typename?: "Query";
  spendTransactionsLegacy?: {
    __typename?: "SpendLegacyTransactionsResponse";
    count?: number | null;
    transactions?: Array<{
      __typename?: "SpendLegacyTransaction";
      amount?: number | null;
      correlationId?: string | null;
      created?: any | null;
      description?: string | null;
      destination?: string | null;
      direction?: string | null;
      effective: any;
      externalId: string;
      id: any;
      metadata?: any | null;
      processor?: string | null;
      snapAmount?: number | null;
      source?: string | null;
      status: string;
      transactionNote?: string | null;
      type?: string | null;
      attachments?: Array<{
        __typename?: "TransactionAttachment";
        createdAt?: string | null;
        description?: string | null;
        id?: string | null;
        lastUpdatedByUserId?: string | null;
        name?: string | null;
        paymentId?: string | null;
        updatedAt?: string | null;
        url?: string | null;
      }> | null;
      note?: {
        __typename?: "TransactionNotes";
        content?: string | null;
        createdAt?: string | null;
        id?: string | null;
        lastUpdatedByUserId?: string | null;
        paymentId?: string | null;
        updatedAt?: string | null;
      } | null;
      reconciliation?: {
        __typename?: "SpendReconciledTransaction";
        amount?: number | null;
        createdAt?: string | null;
        id?: string | null;
        paymentId?: string | null;
        type?: string | null;
        updatedAt?: string | null;
        reconciledTo?: Array<{
          __typename?: "SpendReconciledBudget";
          amount: number;
          description: string;
          type: string;
          invoiceId?: string | null;
          budgetId: string;
          budgetName: string;
          categoryName: string;
          categoryId: string;
          groupName: string;
          seasonName: string;
          groupId?: string | null;
        } | null> | null;
        budgetTransactions?: Array<{
          __typename?: "SpendReconciledBudgetTransaction";
          amount?: number | null;
          budgetItemId?: string | null;
          id?: string | null;
          transactionId?: string | null;
        } | null> | null;
        invoiceTransactions?: Array<{
          __typename?: "SpendReconciledInvoiceTransaction";
          amount?: number | null;
          id?: string | null;
          invoiceId?: string | null;
          rosterId?: string | null;
          rosterName?: string | null;
          transactionId?: string | null;
        } | null> | null;
      } | null;
    } | null> | null;
  } | null;
};

export type SpendUserVerificationTokenQueryVariables = Exact<{
  [key: string]: never;
}>;

export type SpendUserVerificationTokenQuery = {
  __typename?: "Query";
  spendUserVerificationToken?: {
    __typename?: "SpendVerificationTokenResponse";
    verificationToken?: string | null;
  } | null;
};

export type SpendUserAccountBearerTokenQueryVariables = Exact<{
  input?: InputMaybe<SpendAccountBearerToken>;
}>;

export type SpendUserAccountBearerTokenQuery = {
  __typename?: "Query";
  spendUserAccountBearerToken?: {
    __typename?: "SpendAccountBearerTokenResponse";
    expiresIn?: number | null;
    token?: string | null;
  } | null;
};

export type SpendGetInstitutionByRoutingNumberQueryVariables = Exact<{
  routingNumber: Scalars["String"];
}>;

export type SpendGetInstitutionByRoutingNumberQuery = {
  __typename?: "Query";
  spendGetInstitutionByRoutingNumber?: {
    __typename?: "SpendInstitutionResponse";
    routingNumber: string;
    name: string;
    address: string;
    isACHSupported: boolean;
    isWireSupported: boolean;
  } | null;
};

export type ExternalAccountsQueryVariables = Exact<{ [key: string]: never }>;

export type ExternalAccountsQuery = {
  __typename?: "Query";
  spendUserBankAccounts?: {
    __typename?: "SpendBankAccountsResponse";
    status?: string | null;
    externalAccounts?: Array<{
      __typename?: "SpendBankAccount";
      id?: string | null;
      accountId?: string | null;
      availableBalance?: number | null;
      currentBalance?: number | null;
      mask?: string | null;
      name?: string | null;
      officialName?: string | null;
      subtype?: string | null;
      type?: string | null;
      institution?: {
        __typename?: "SpendInstitution";
        id?: string | null;
        name?: string | null;
      } | null;
    } | null> | null;
  } | null;
};

export type SpendGuardianHighlightQueryVariables = Exact<{
  [key: string]: never;
}>;

export type SpendGuardianHighlightQuery = {
  __typename?: "Query";
  spendGuardianHighlight?: {
    __typename?: "SpendGuardianHighlight";
    activeGroups?: Array<{
      __typename?: "SpendGuardianActiveGroup";
      dueDate?: string | null;
      group?: {
        __typename?: "SpendGuardianGroup";
        accountId?: string | null;
        archivedAt?: string | null;
        createdAt?: string | null;
        hasAccount?: boolean | null;
        id?: string | null;
        isArchived?: boolean | null;
        name?: string | null;
        organizationId?: string | null;
        programId?: string | null;
        seasons?: Array<{
          __typename?: "SpendGuardianSeason";
          id?: string | null;
          name?: string | null;
          startDateAt?: string | null;
          endDateAt?: string | null;
          isBudgetShared?: boolean | null;
          roster?: Array<{
            __typename?: "SpendRoster";
            id?: string | null;
            name?: string | null;
            email?: string | null;
          } | null> | null;
        } | null> | null;
        latestSeason?: {
          __typename?: "SpendSeason";
          id?: string | null;
          name?: string | null;
          startDateAt?: string | null;
          endDateAt?: string | null;
          isBudgetShared?: boolean | null;
        } | null;
      } | null;
    } | null> | null;
    comingSoonInvoices?: Array<{
      __typename?: "SpendGuardianComingSoonInvoice";
      invoiceAmountDue?: string | null;
      invoiceDescription?: string | null;
      invoiceDueDate?: string | null;
      invoiceId?: string | null;
      invoiceStatus?: string | null;
      rosterName?: string | null;
      seasonName?: string | null;
      invoiceAutoPayAuthorized?: boolean | null;
      invoicePaymentMethod?: string | null;
      groupName?: string | null;
      isOptional?: boolean | null;
      optedIn?: boolean | null;
    } | null> | null;
    counts?: {
      __typename?: "SpendGuardianCounts";
      activeGroups?: number | null;
      comingSoonInvoices?: number | null;
    } | null;
  } | null;
};

export type SpendGuardianHighlight2QueryVariables = Exact<{
  [key: string]: never;
}>;

export type SpendGuardianHighlight2Query = {
  __typename?: "Query";
  spendGuardianHighlight?: {
    __typename?: "SpendGuardianHighlight";
    unauthorizedInvoices?: Array<{
      __typename?: "SpendGuardianComingSoonInvoice";
      invoiceId?: string | null;
      invoiceDescription?: string | null;
      seasonName?: string | null;
      rosterName?: string | null;
      invoiceDueDate?: string | null;
      invoiceAmountDue?: string | null;
      invoiceStatus?: string | null;
      invoiceAutoPayAuthorized?: boolean | null;
      invoiceAutoPayStopped?: boolean | null;
      invoicePaymentMethod?: string | null;
      groupName?: string | null;
      isOptional?: boolean | null;
      optedIn?: boolean | null;
      groupRosterId?: string | null;
      groupId?: string | null;
      isAuthorized?: boolean | null;
      seasonId?: string | null;
    } | null> | null;
    activeGroups?: Array<{
      __typename?: "SpendGuardianActiveGroup";
      dueDate?: string | null;
      group?: {
        __typename?: "SpendGuardianGroup";
        accountId?: string | null;
        archivedAt?: string | null;
        createdAt?: string | null;
        hasAccount?: boolean | null;
        id?: string | null;
        isArchived?: boolean | null;
        name?: string | null;
        organizationId?: string | null;
        programId?: string | null;
        seasons?: Array<{
          __typename?: "SpendGuardianSeason";
          id?: string | null;
          name?: string | null;
          startDateAt?: string | null;
          endDateAt?: string | null;
          isBudgetShared?: boolean | null;
          roster?: Array<{
            __typename?: "SpendRoster";
            id?: string | null;
            name?: string | null;
            email?: string | null;
          } | null> | null;
        } | null> | null;
        latestSeason?: {
          __typename?: "SpendSeason";
          id?: string | null;
          name?: string | null;
          startDateAt?: string | null;
          endDateAt?: string | null;
          isBudgetShared?: boolean | null;
        } | null;
      } | null;
    } | null> | null;
    comingSoonInvoices?: Array<{
      __typename?: "SpendGuardianComingSoonInvoice";
      invoiceAmountDue?: string | null;
      invoiceDescription?: string | null;
      invoiceDueDate?: string | null;
      invoiceId?: string | null;
      invoiceStatus?: string | null;
      rosterName?: string | null;
      seasonName?: string | null;
      invoiceAutoPayStopped?: boolean | null;
      invoiceAutoPayAuthorized?: boolean | null;
      invoicePaymentMethod?: string | null;
      invoiceOptedOutAt?: string | null;
      groupName?: string | null;
      isAuthorized?: boolean | null;
      isOptional?: boolean | null;
      optedIn?: boolean | null;
      invoiceNote?: string | null;
    } | null> | null;
    counts?: {
      __typename?: "SpendGuardianCounts";
      activeGroups?: number | null;
      comingSoonInvoices?: number | null;
      unauthorizedInvoices?: number | null;
    } | null;
  } | null;
};

export type SpendUserExportEmailsQueryVariables = Exact<{
  input: SpendExportEmailsInput;
}>;

export type SpendUserExportEmailsQuery = {
  __typename?: "Query";
  spendUserExportEmails?: {
    __typename?: "ExportFile";
    content?: string | null;
    fileName?: string | null;
  } | null;
};

export type RolesQueryVariables = Exact<{ [key: string]: never }>;

export type RolesQuery = {
  __typename?: "Query";
  spendRoles?: {
    __typename?: "SpendRoleResponse";
    count?: number | null;
    roles?: Array<{
      __typename?: "SpendRole";
      groupId?: string | null;
      id: string;
      isArchived?: boolean | null;
      name: string;
      organizationId?: string | null;
      permissions: Array<string | null>;
      isNotSignedUp?: boolean | null;
      organizationName?: string | null;
      groupName?: string | null;
    } | null> | null;
  } | null;
};

export type PermissionsQueryVariables = Exact<{ [key: string]: never }>;

export type PermissionsQuery = {
  __typename?: "Query";
  me?: {
    __typename?: "UserWithPermissions";
    email?: string | null;
    permissions?: Array<string | null> | null;
    id?: string | null;
  } | null;
};

export type SuperBasicUserInfoQueryVariables = Exact<{ [key: string]: never }>;

export type SuperBasicUserInfoQuery = {
  __typename?: "Query";
  me?: {
    __typename?: "UserWithPermissions";
    firstName?: string | null;
    lastName?: string | null;
    id?: string | null;
  } | null;
};

export const ApiStatusDocument = gql`
  query ApiStatus {
    apiReady @client
  }
`;

/**
 * __useApiStatusQuery__
 *
 * To run a query within a React component, call `useApiStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useApiStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useApiStatusQuery({
 *   variables: {
 *   },
 * });
 */
export function useApiStatusQuery(
  baseOptions?: Apollo.QueryHookOptions<ApiStatusQuery, ApiStatusQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ApiStatusQuery, ApiStatusQueryVariables>(
    ApiStatusDocument,
    options
  );
}
export function useApiStatusLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ApiStatusQuery,
    ApiStatusQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ApiStatusQuery, ApiStatusQueryVariables>(
    ApiStatusDocument,
    options
  );
}
export type ApiStatusQueryHookResult = ReturnType<typeof useApiStatusQuery>;
export type ApiStatusLazyQueryHookResult = ReturnType<
  typeof useApiStatusLazyQuery
>;
export type ApiStatusQueryResult = Apollo.QueryResult<
  ApiStatusQuery,
  ApiStatusQueryVariables
>;
export const SpendBudgetCreateDocument = gql`
  mutation SpendBudgetCreate($input: SpendBudgetInput!) {
    spendBudgetCreate(input: $input) {
      id
    }
  }
`;
export type SpendBudgetCreateMutationFn = Apollo.MutationFunction<
  SpendBudgetCreateMutation,
  SpendBudgetCreateMutationVariables
>;

/**
 * __useSpendBudgetCreateMutation__
 *
 * To run a mutation, you first call `useSpendBudgetCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSpendBudgetCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [spendBudgetCreateMutation, { data, loading, error }] = useSpendBudgetCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSpendBudgetCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SpendBudgetCreateMutation,
    SpendBudgetCreateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SpendBudgetCreateMutation,
    SpendBudgetCreateMutationVariables
  >(SpendBudgetCreateDocument, options);
}
export type SpendBudgetCreateMutationHookResult = ReturnType<
  typeof useSpendBudgetCreateMutation
>;
export type SpendBudgetCreateMutationResult =
  Apollo.MutationResult<SpendBudgetCreateMutation>;
export type SpendBudgetCreateMutationOptions = Apollo.BaseMutationOptions<
  SpendBudgetCreateMutation,
  SpendBudgetCreateMutationVariables
>;
export const SpendBudgetUpdateDocument = gql`
  mutation SpendBudgetUpdate(
    $spendBudgetUpdateId: String!
    $input: SpendBudgetInput!
  ) {
    spendBudgetUpdate(id: $spendBudgetUpdateId, input: $input) {
      id
    }
  }
`;
export type SpendBudgetUpdateMutationFn = Apollo.MutationFunction<
  SpendBudgetUpdateMutation,
  SpendBudgetUpdateMutationVariables
>;

/**
 * __useSpendBudgetUpdateMutation__
 *
 * To run a mutation, you first call `useSpendBudgetUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSpendBudgetUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [spendBudgetUpdateMutation, { data, loading, error }] = useSpendBudgetUpdateMutation({
 *   variables: {
 *      spendBudgetUpdateId: // value for 'spendBudgetUpdateId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSpendBudgetUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SpendBudgetUpdateMutation,
    SpendBudgetUpdateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SpendBudgetUpdateMutation,
    SpendBudgetUpdateMutationVariables
  >(SpendBudgetUpdateDocument, options);
}
export type SpendBudgetUpdateMutationHookResult = ReturnType<
  typeof useSpendBudgetUpdateMutation
>;
export type SpendBudgetUpdateMutationResult =
  Apollo.MutationResult<SpendBudgetUpdateMutation>;
export type SpendBudgetUpdateMutationOptions = Apollo.BaseMutationOptions<
  SpendBudgetUpdateMutation,
  SpendBudgetUpdateMutationVariables
>;
export const SpendBudgetDeleteDocument = gql`
  mutation SpendBudgetDelete($spendBudgetDeleteId: String!) {
    spendBudgetDelete(id: $spendBudgetDeleteId) {
      id
    }
  }
`;
export type SpendBudgetDeleteMutationFn = Apollo.MutationFunction<
  SpendBudgetDeleteMutation,
  SpendBudgetDeleteMutationVariables
>;

/**
 * __useSpendBudgetDeleteMutation__
 *
 * To run a mutation, you first call `useSpendBudgetDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSpendBudgetDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [spendBudgetDeleteMutation, { data, loading, error }] = useSpendBudgetDeleteMutation({
 *   variables: {
 *      spendBudgetDeleteId: // value for 'spendBudgetDeleteId'
 *   },
 * });
 */
export function useSpendBudgetDeleteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SpendBudgetDeleteMutation,
    SpendBudgetDeleteMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SpendBudgetDeleteMutation,
    SpendBudgetDeleteMutationVariables
  >(SpendBudgetDeleteDocument, options);
}
export type SpendBudgetDeleteMutationHookResult = ReturnType<
  typeof useSpendBudgetDeleteMutation
>;
export type SpendBudgetDeleteMutationResult =
  Apollo.MutationResult<SpendBudgetDeleteMutation>;
export type SpendBudgetDeleteMutationOptions = Apollo.BaseMutationOptions<
  SpendBudgetDeleteMutation,
  SpendBudgetDeleteMutationVariables
>;
export const SpendTransactionBudgetUnreconcileDocument = gql`
  mutation SpendTransactionBudgetUnreconcile(
    $input: SpendTransactionBudgetUnreconcileInput!
  ) {
    spendTransactionBudgetUnreconcile(input: $input) {
      id
    }
  }
`;
export type SpendTransactionBudgetUnreconcileMutationFn =
  Apollo.MutationFunction<
    SpendTransactionBudgetUnreconcileMutation,
    SpendTransactionBudgetUnreconcileMutationVariables
  >;

/**
 * __useSpendTransactionBudgetUnreconcileMutation__
 *
 * To run a mutation, you first call `useSpendTransactionBudgetUnreconcileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSpendTransactionBudgetUnreconcileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [spendTransactionBudgetUnreconcileMutation, { data, loading, error }] = useSpendTransactionBudgetUnreconcileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSpendTransactionBudgetUnreconcileMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SpendTransactionBudgetUnreconcileMutation,
    SpendTransactionBudgetUnreconcileMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SpendTransactionBudgetUnreconcileMutation,
    SpendTransactionBudgetUnreconcileMutationVariables
  >(SpendTransactionBudgetUnreconcileDocument, options);
}
export type SpendTransactionBudgetUnreconcileMutationHookResult = ReturnType<
  typeof useSpendTransactionBudgetUnreconcileMutation
>;
export type SpendTransactionBudgetUnreconcileMutationResult =
  Apollo.MutationResult<SpendTransactionBudgetUnreconcileMutation>;
export type SpendTransactionBudgetUnreconcileMutationOptions =
  Apollo.BaseMutationOptions<
    SpendTransactionBudgetUnreconcileMutation,
    SpendTransactionBudgetUnreconcileMutationVariables
  >;
export const SpendCategoryCreateDocument = gql`
  mutation SpendCategoryCreate($input: SpendCategoryInput!) {
    spendCategoryCreate(input: $input) {
      id
    }
  }
`;
export type SpendCategoryCreateMutationFn = Apollo.MutationFunction<
  SpendCategoryCreateMutation,
  SpendCategoryCreateMutationVariables
>;

/**
 * __useSpendCategoryCreateMutation__
 *
 * To run a mutation, you first call `useSpendCategoryCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSpendCategoryCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [spendCategoryCreateMutation, { data, loading, error }] = useSpendCategoryCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSpendCategoryCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SpendCategoryCreateMutation,
    SpendCategoryCreateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SpendCategoryCreateMutation,
    SpendCategoryCreateMutationVariables
  >(SpendCategoryCreateDocument, options);
}
export type SpendCategoryCreateMutationHookResult = ReturnType<
  typeof useSpendCategoryCreateMutation
>;
export type SpendCategoryCreateMutationResult =
  Apollo.MutationResult<SpendCategoryCreateMutation>;
export type SpendCategoryCreateMutationOptions = Apollo.BaseMutationOptions<
  SpendCategoryCreateMutation,
  SpendCategoryCreateMutationVariables
>;
export const SpendCategoryUpsertBulkDocument = gql`
  mutation SpendCategoryUpsertBulk($input: [SpendUpsertCategoryInput]) {
    spendCategoryUpsertBulk(input: $input) {
      id
    }
  }
`;
export type SpendCategoryUpsertBulkMutationFn = Apollo.MutationFunction<
  SpendCategoryUpsertBulkMutation,
  SpendCategoryUpsertBulkMutationVariables
>;

/**
 * __useSpendCategoryUpsertBulkMutation__
 *
 * To run a mutation, you first call `useSpendCategoryUpsertBulkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSpendCategoryUpsertBulkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [spendCategoryUpsertBulkMutation, { data, loading, error }] = useSpendCategoryUpsertBulkMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSpendCategoryUpsertBulkMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SpendCategoryUpsertBulkMutation,
    SpendCategoryUpsertBulkMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SpendCategoryUpsertBulkMutation,
    SpendCategoryUpsertBulkMutationVariables
  >(SpendCategoryUpsertBulkDocument, options);
}
export type SpendCategoryUpsertBulkMutationHookResult = ReturnType<
  typeof useSpendCategoryUpsertBulkMutation
>;
export type SpendCategoryUpsertBulkMutationResult =
  Apollo.MutationResult<SpendCategoryUpsertBulkMutation>;
export type SpendCategoryUpsertBulkMutationOptions = Apollo.BaseMutationOptions<
  SpendCategoryUpsertBulkMutation,
  SpendCategoryUpsertBulkMutationVariables
>;
export const SpendCreditMemoCreateDocument = gql`
  mutation SpendCreditMemoCreate($input: SpendCreditMemoInput!) {
    spendCreditMemoCreate(input: $input) {
      ids
    }
  }
`;
export type SpendCreditMemoCreateMutationFn = Apollo.MutationFunction<
  SpendCreditMemoCreateMutation,
  SpendCreditMemoCreateMutationVariables
>;

/**
 * __useSpendCreditMemoCreateMutation__
 *
 * To run a mutation, you first call `useSpendCreditMemoCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSpendCreditMemoCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [spendCreditMemoCreateMutation, { data, loading, error }] = useSpendCreditMemoCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSpendCreditMemoCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SpendCreditMemoCreateMutation,
    SpendCreditMemoCreateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SpendCreditMemoCreateMutation,
    SpendCreditMemoCreateMutationVariables
  >(SpendCreditMemoCreateDocument, options);
}
export type SpendCreditMemoCreateMutationHookResult = ReturnType<
  typeof useSpendCreditMemoCreateMutation
>;
export type SpendCreditMemoCreateMutationResult =
  Apollo.MutationResult<SpendCreditMemoCreateMutation>;
export type SpendCreditMemoCreateMutationOptions = Apollo.BaseMutationOptions<
  SpendCreditMemoCreateMutation,
  SpendCreditMemoCreateMutationVariables
>;
export const SpendCreditMemoUpdateDocument = gql`
  mutation SpendCreditMemoUpdate($input: SpendCreditMemoUpdateInput!) {
    spendCreditMemoUpdate(input: $input) {
      id
    }
  }
`;
export type SpendCreditMemoUpdateMutationFn = Apollo.MutationFunction<
  SpendCreditMemoUpdateMutation,
  SpendCreditMemoUpdateMutationVariables
>;

/**
 * __useSpendCreditMemoUpdateMutation__
 *
 * To run a mutation, you first call `useSpendCreditMemoUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSpendCreditMemoUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [spendCreditMemoUpdateMutation, { data, loading, error }] = useSpendCreditMemoUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSpendCreditMemoUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SpendCreditMemoUpdateMutation,
    SpendCreditMemoUpdateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SpendCreditMemoUpdateMutation,
    SpendCreditMemoUpdateMutationVariables
  >(SpendCreditMemoUpdateDocument, options);
}
export type SpendCreditMemoUpdateMutationHookResult = ReturnType<
  typeof useSpendCreditMemoUpdateMutation
>;
export type SpendCreditMemoUpdateMutationResult =
  Apollo.MutationResult<SpendCreditMemoUpdateMutation>;
export type SpendCreditMemoUpdateMutationOptions = Apollo.BaseMutationOptions<
  SpendCreditMemoUpdateMutation,
  SpendCreditMemoUpdateMutationVariables
>;
export const SpendGroupArchiveDocument = gql`
  mutation SpendGroupArchive($spendGroupArchiveId: String!) {
    spendGroupArchive(id: $spendGroupArchiveId) {
      id
    }
  }
`;
export type SpendGroupArchiveMutationFn = Apollo.MutationFunction<
  SpendGroupArchiveMutation,
  SpendGroupArchiveMutationVariables
>;

/**
 * __useSpendGroupArchiveMutation__
 *
 * To run a mutation, you first call `useSpendGroupArchiveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSpendGroupArchiveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [spendGroupArchiveMutation, { data, loading, error }] = useSpendGroupArchiveMutation({
 *   variables: {
 *      spendGroupArchiveId: // value for 'spendGroupArchiveId'
 *   },
 * });
 */
export function useSpendGroupArchiveMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SpendGroupArchiveMutation,
    SpendGroupArchiveMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SpendGroupArchiveMutation,
    SpendGroupArchiveMutationVariables
  >(SpendGroupArchiveDocument, options);
}
export type SpendGroupArchiveMutationHookResult = ReturnType<
  typeof useSpendGroupArchiveMutation
>;
export type SpendGroupArchiveMutationResult =
  Apollo.MutationResult<SpendGroupArchiveMutation>;
export type SpendGroupArchiveMutationOptions = Apollo.BaseMutationOptions<
  SpendGroupArchiveMutation,
  SpendGroupArchiveMutationVariables
>;
export const SpendGroupCreateDocument = gql`
  mutation SpendGroupCreate($input: SpendGroupInput!) {
    spendGroupCreate(input: $input) {
      id
    }
  }
`;
export type SpendGroupCreateMutationFn = Apollo.MutationFunction<
  SpendGroupCreateMutation,
  SpendGroupCreateMutationVariables
>;

/**
 * __useSpendGroupCreateMutation__
 *
 * To run a mutation, you first call `useSpendGroupCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSpendGroupCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [spendGroupCreateMutation, { data, loading, error }] = useSpendGroupCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSpendGroupCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SpendGroupCreateMutation,
    SpendGroupCreateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SpendGroupCreateMutation,
    SpendGroupCreateMutationVariables
  >(SpendGroupCreateDocument, options);
}
export type SpendGroupCreateMutationHookResult = ReturnType<
  typeof useSpendGroupCreateMutation
>;
export type SpendGroupCreateMutationResult =
  Apollo.MutationResult<SpendGroupCreateMutation>;
export type SpendGroupCreateMutationOptions = Apollo.BaseMutationOptions<
  SpendGroupCreateMutation,
  SpendGroupCreateMutationVariables
>;
export const SpendGroupRostersCreateDocument = gql`
  mutation SpendGroupRostersCreate($input: SpendGroupRostersInput!) {
    spendGroupRostersCreate(input: $input) {
      id
    }
  }
`;
export type SpendGroupRostersCreateMutationFn = Apollo.MutationFunction<
  SpendGroupRostersCreateMutation,
  SpendGroupRostersCreateMutationVariables
>;

/**
 * __useSpendGroupRostersCreateMutation__
 *
 * To run a mutation, you first call `useSpendGroupRostersCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSpendGroupRostersCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [spendGroupRostersCreateMutation, { data, loading, error }] = useSpendGroupRostersCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSpendGroupRostersCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SpendGroupRostersCreateMutation,
    SpendGroupRostersCreateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SpendGroupRostersCreateMutation,
    SpendGroupRostersCreateMutationVariables
  >(SpendGroupRostersCreateDocument, options);
}
export type SpendGroupRostersCreateMutationHookResult = ReturnType<
  typeof useSpendGroupRostersCreateMutation
>;
export type SpendGroupRostersCreateMutationResult =
  Apollo.MutationResult<SpendGroupRostersCreateMutation>;
export type SpendGroupRostersCreateMutationOptions = Apollo.BaseMutationOptions<
  SpendGroupRostersCreateMutation,
  SpendGroupRostersCreateMutationVariables
>;
export const SpendGroupDuplicateDocument = gql`
  mutation SpendGroupDuplicate($spendGroupDuplicateId: String!) {
    spendGroupDuplicate(id: $spendGroupDuplicateId) {
      id
    }
  }
`;
export type SpendGroupDuplicateMutationFn = Apollo.MutationFunction<
  SpendGroupDuplicateMutation,
  SpendGroupDuplicateMutationVariables
>;

/**
 * __useSpendGroupDuplicateMutation__
 *
 * To run a mutation, you first call `useSpendGroupDuplicateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSpendGroupDuplicateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [spendGroupDuplicateMutation, { data, loading, error }] = useSpendGroupDuplicateMutation({
 *   variables: {
 *      spendGroupDuplicateId: // value for 'spendGroupDuplicateId'
 *   },
 * });
 */
export function useSpendGroupDuplicateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SpendGroupDuplicateMutation,
    SpendGroupDuplicateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SpendGroupDuplicateMutation,
    SpendGroupDuplicateMutationVariables
  >(SpendGroupDuplicateDocument, options);
}
export type SpendGroupDuplicateMutationHookResult = ReturnType<
  typeof useSpendGroupDuplicateMutation
>;
export type SpendGroupDuplicateMutationResult =
  Apollo.MutationResult<SpendGroupDuplicateMutation>;
export type SpendGroupDuplicateMutationOptions = Apollo.BaseMutationOptions<
  SpendGroupDuplicateMutation,
  SpendGroupDuplicateMutationVariables
>;
export const SpendSeasonCreateDocument = gql`
  mutation SpendSeasonCreate($input: SpendSeasonInput!) {
    spendSeasonCreate(input: $input) {
      id
    }
  }
`;
export type SpendSeasonCreateMutationFn = Apollo.MutationFunction<
  SpendSeasonCreateMutation,
  SpendSeasonCreateMutationVariables
>;

/**
 * __useSpendSeasonCreateMutation__
 *
 * To run a mutation, you first call `useSpendSeasonCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSpendSeasonCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [spendSeasonCreateMutation, { data, loading, error }] = useSpendSeasonCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSpendSeasonCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SpendSeasonCreateMutation,
    SpendSeasonCreateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SpendSeasonCreateMutation,
    SpendSeasonCreateMutationVariables
  >(SpendSeasonCreateDocument, options);
}
export type SpendSeasonCreateMutationHookResult = ReturnType<
  typeof useSpendSeasonCreateMutation
>;
export type SpendSeasonCreateMutationResult =
  Apollo.MutationResult<SpendSeasonCreateMutation>;
export type SpendSeasonCreateMutationOptions = Apollo.BaseMutationOptions<
  SpendSeasonCreateMutation,
  SpendSeasonCreateMutationVariables
>;
export const SpendGroupAndSeasonUpdateDocument = gql`
  mutation SpendGroupAndSeasonUpdate(
    $spendGroupInput: SpendGroupInput!
    $spendGroupUpdateId: String
    $spendSeasonUpdateId: String!
    $spendSeasonInput: SpendSeasonInput!
  ) {
    spendGroupUpdate(input: $spendGroupInput, id: $spendGroupUpdateId) {
      id
    }
    spendSeasonUpdate(id: $spendSeasonUpdateId, input: $spendSeasonInput) {
      id
    }
  }
`;
export type SpendGroupAndSeasonUpdateMutationFn = Apollo.MutationFunction<
  SpendGroupAndSeasonUpdateMutation,
  SpendGroupAndSeasonUpdateMutationVariables
>;

/**
 * __useSpendGroupAndSeasonUpdateMutation__
 *
 * To run a mutation, you first call `useSpendGroupAndSeasonUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSpendGroupAndSeasonUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [spendGroupAndSeasonUpdateMutation, { data, loading, error }] = useSpendGroupAndSeasonUpdateMutation({
 *   variables: {
 *      spendGroupInput: // value for 'spendGroupInput'
 *      spendGroupUpdateId: // value for 'spendGroupUpdateId'
 *      spendSeasonUpdateId: // value for 'spendSeasonUpdateId'
 *      spendSeasonInput: // value for 'spendSeasonInput'
 *   },
 * });
 */
export function useSpendGroupAndSeasonUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SpendGroupAndSeasonUpdateMutation,
    SpendGroupAndSeasonUpdateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SpendGroupAndSeasonUpdateMutation,
    SpendGroupAndSeasonUpdateMutationVariables
  >(SpendGroupAndSeasonUpdateDocument, options);
}
export type SpendGroupAndSeasonUpdateMutationHookResult = ReturnType<
  typeof useSpendGroupAndSeasonUpdateMutation
>;
export type SpendGroupAndSeasonUpdateMutationResult =
  Apollo.MutationResult<SpendGroupAndSeasonUpdateMutation>;
export type SpendGroupAndSeasonUpdateMutationOptions =
  Apollo.BaseMutationOptions<
    SpendGroupAndSeasonUpdateMutation,
    SpendGroupAndSeasonUpdateMutationVariables
  >;
export const SpendGroupDebitCardCreateDocument = gql`
  mutation SpendGroupDebitCardCreate(
    $input: SpendDebitCardInput!
    $id: String
  ) {
    spendGroupDebitCardCreate(input: $input, id: $id) {
      id
    }
  }
`;
export type SpendGroupDebitCardCreateMutationFn = Apollo.MutationFunction<
  SpendGroupDebitCardCreateMutation,
  SpendGroupDebitCardCreateMutationVariables
>;

/**
 * __useSpendGroupDebitCardCreateMutation__
 *
 * To run a mutation, you first call `useSpendGroupDebitCardCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSpendGroupDebitCardCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [spendGroupDebitCardCreateMutation, { data, loading, error }] = useSpendGroupDebitCardCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSpendGroupDebitCardCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SpendGroupDebitCardCreateMutation,
    SpendGroupDebitCardCreateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SpendGroupDebitCardCreateMutation,
    SpendGroupDebitCardCreateMutationVariables
  >(SpendGroupDebitCardCreateDocument, options);
}
export type SpendGroupDebitCardCreateMutationHookResult = ReturnType<
  typeof useSpendGroupDebitCardCreateMutation
>;
export type SpendGroupDebitCardCreateMutationResult =
  Apollo.MutationResult<SpendGroupDebitCardCreateMutation>;
export type SpendGroupDebitCardCreateMutationOptions =
  Apollo.BaseMutationOptions<
    SpendGroupDebitCardCreateMutation,
    SpendGroupDebitCardCreateMutationVariables
  >;
export const SpendGroupExternalTransferDocument = gql`
  mutation SpendGroupExternalTransfer(
    $input: SpendGroupExternalTransferInput!
  ) {
    spendGroupExternalTransfer(input: $input) {
      amount
      descriptor
      id
      status
    }
  }
`;
export type SpendGroupExternalTransferMutationFn = Apollo.MutationFunction<
  SpendGroupExternalTransferMutation,
  SpendGroupExternalTransferMutationVariables
>;

/**
 * __useSpendGroupExternalTransferMutation__
 *
 * To run a mutation, you first call `useSpendGroupExternalTransferMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSpendGroupExternalTransferMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [spendGroupExternalTransferMutation, { data, loading, error }] = useSpendGroupExternalTransferMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSpendGroupExternalTransferMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SpendGroupExternalTransferMutation,
    SpendGroupExternalTransferMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SpendGroupExternalTransferMutation,
    SpendGroupExternalTransferMutationVariables
  >(SpendGroupExternalTransferDocument, options);
}
export type SpendGroupExternalTransferMutationHookResult = ReturnType<
  typeof useSpendGroupExternalTransferMutation
>;
export type SpendGroupExternalTransferMutationResult =
  Apollo.MutationResult<SpendGroupExternalTransferMutation>;
export type SpendGroupExternalTransferMutationOptions =
  Apollo.BaseMutationOptions<
    SpendGroupExternalTransferMutation,
    SpendGroupExternalTransferMutationVariables
  >;
export const SpendPaymentMethodCreateDocument = gql`
  mutation SpendPaymentMethodCreate($input: SpendPaymentMethodInput!) {
    spendPaymentMethodCreate(input: $input) {
      updatedInvoiceIds
      payment {
        status
        paymentId
      }
      groupRosterSetting {
        settingsId
        groupRosterId
      }
    }
  }
`;
export type SpendPaymentMethodCreateMutationFn = Apollo.MutationFunction<
  SpendPaymentMethodCreateMutation,
  SpendPaymentMethodCreateMutationVariables
>;

/**
 * __useSpendPaymentMethodCreateMutation__
 *
 * To run a mutation, you first call `useSpendPaymentMethodCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSpendPaymentMethodCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [spendPaymentMethodCreateMutation, { data, loading, error }] = useSpendPaymentMethodCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSpendPaymentMethodCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SpendPaymentMethodCreateMutation,
    SpendPaymentMethodCreateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SpendPaymentMethodCreateMutation,
    SpendPaymentMethodCreateMutationVariables
  >(SpendPaymentMethodCreateDocument, options);
}
export type SpendPaymentMethodCreateMutationHookResult = ReturnType<
  typeof useSpendPaymentMethodCreateMutation
>;
export type SpendPaymentMethodCreateMutationResult =
  Apollo.MutationResult<SpendPaymentMethodCreateMutation>;
export type SpendPaymentMethodCreateMutationOptions =
  Apollo.BaseMutationOptions<
    SpendPaymentMethodCreateMutation,
    SpendPaymentMethodCreateMutationVariables
  >;
export const SpendSeasonUpdateDocument = gql`
  mutation SpendSeasonUpdate($id: String!, $input: SpendSeasonInput!) {
    spendSeasonUpdate(id: $id, input: $input) {
      id
    }
  }
`;
export type SpendSeasonUpdateMutationFn = Apollo.MutationFunction<
  SpendSeasonUpdateMutation,
  SpendSeasonUpdateMutationVariables
>;

/**
 * __useSpendSeasonUpdateMutation__
 *
 * To run a mutation, you first call `useSpendSeasonUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSpendSeasonUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [spendSeasonUpdateMutation, { data, loading, error }] = useSpendSeasonUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSpendSeasonUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SpendSeasonUpdateMutation,
    SpendSeasonUpdateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SpendSeasonUpdateMutation,
    SpendSeasonUpdateMutationVariables
  >(SpendSeasonUpdateDocument, options);
}
export type SpendSeasonUpdateMutationHookResult = ReturnType<
  typeof useSpendSeasonUpdateMutation
>;
export type SpendSeasonUpdateMutationResult =
  Apollo.MutationResult<SpendSeasonUpdateMutation>;
export type SpendSeasonUpdateMutationOptions = Apollo.BaseMutationOptions<
  SpendSeasonUpdateMutation,
  SpendSeasonUpdateMutationVariables
>;
export const SpendGroupCheckDepositPatchDocument = gql`
  mutation SpendGroupCheckDepositPatch(
    $input: SpendGroupCheckDepositTagsInput!
  ) {
    spendGroupCheckDepositPatch(input: $input) {
      success
    }
  }
`;
export type SpendGroupCheckDepositPatchMutationFn = Apollo.MutationFunction<
  SpendGroupCheckDepositPatchMutation,
  SpendGroupCheckDepositPatchMutationVariables
>;

/**
 * __useSpendGroupCheckDepositPatchMutation__
 *
 * To run a mutation, you first call `useSpendGroupCheckDepositPatchMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSpendGroupCheckDepositPatchMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [spendGroupCheckDepositPatchMutation, { data, loading, error }] = useSpendGroupCheckDepositPatchMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSpendGroupCheckDepositPatchMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SpendGroupCheckDepositPatchMutation,
    SpendGroupCheckDepositPatchMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SpendGroupCheckDepositPatchMutation,
    SpendGroupCheckDepositPatchMutationVariables
  >(SpendGroupCheckDepositPatchDocument, options);
}
export type SpendGroupCheckDepositPatchMutationHookResult = ReturnType<
  typeof useSpendGroupCheckDepositPatchMutation
>;
export type SpendGroupCheckDepositPatchMutationResult =
  Apollo.MutationResult<SpendGroupCheckDepositPatchMutation>;
export type SpendGroupCheckDepositPatchMutationOptions =
  Apollo.BaseMutationOptions<
    SpendGroupCheckDepositPatchMutation,
    SpendGroupCheckDepositPatchMutationVariables
  >;
export const SpendUserLeaveGroupDocument = gql`
  mutation SpendUserLeaveGroup($groupId: String!) {
    spendUserLeaveGroup(groupId: $groupId) {
      success
    }
  }
`;
export type SpendUserLeaveGroupMutationFn = Apollo.MutationFunction<
  SpendUserLeaveGroupMutation,
  SpendUserLeaveGroupMutationVariables
>;

/**
 * __useSpendUserLeaveGroupMutation__
 *
 * To run a mutation, you first call `useSpendUserLeaveGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSpendUserLeaveGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [spendUserLeaveGroupMutation, { data, loading, error }] = useSpendUserLeaveGroupMutation({
 *   variables: {
 *      groupId: // value for 'groupId'
 *   },
 * });
 */
export function useSpendUserLeaveGroupMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SpendUserLeaveGroupMutation,
    SpendUserLeaveGroupMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SpendUserLeaveGroupMutation,
    SpendUserLeaveGroupMutationVariables
  >(SpendUserLeaveGroupDocument, options);
}
export type SpendUserLeaveGroupMutationHookResult = ReturnType<
  typeof useSpendUserLeaveGroupMutation
>;
export type SpendUserLeaveGroupMutationResult =
  Apollo.MutationResult<SpendUserLeaveGroupMutation>;
export type SpendUserLeaveGroupMutationOptions = Apollo.BaseMutationOptions<
  SpendUserLeaveGroupMutation,
  SpendUserLeaveGroupMutationVariables
>;
export const SpendArchiveSeasonMembersDocument = gql`
  mutation SpendArchiveSeasonMembers($input: SpendArchiveSeasonMembersInput!) {
    spendArchiveSeasonMembers(input: $input) {
      success
    }
  }
`;
export type SpendArchiveSeasonMembersMutationFn = Apollo.MutationFunction<
  SpendArchiveSeasonMembersMutation,
  SpendArchiveSeasonMembersMutationVariables
>;

/**
 * __useSpendArchiveSeasonMembersMutation__
 *
 * To run a mutation, you first call `useSpendArchiveSeasonMembersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSpendArchiveSeasonMembersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [spendArchiveSeasonMembersMutation, { data, loading, error }] = useSpendArchiveSeasonMembersMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSpendArchiveSeasonMembersMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SpendArchiveSeasonMembersMutation,
    SpendArchiveSeasonMembersMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SpendArchiveSeasonMembersMutation,
    SpendArchiveSeasonMembersMutationVariables
  >(SpendArchiveSeasonMembersDocument, options);
}
export type SpendArchiveSeasonMembersMutationHookResult = ReturnType<
  typeof useSpendArchiveSeasonMembersMutation
>;
export type SpendArchiveSeasonMembersMutationResult =
  Apollo.MutationResult<SpendArchiveSeasonMembersMutation>;
export type SpendArchiveSeasonMembersMutationOptions =
  Apollo.BaseMutationOptions<
    SpendArchiveSeasonMembersMutation,
    SpendArchiveSeasonMembersMutationVariables
  >;
export const SpendRemoveParticipantsDocument = gql`
  mutation SpendRemoveParticipants($input: SpendRemoveSeasonMemberInput!) {
    spendRemoveParticipants(input: $input) {
      success
    }
  }
`;
export type SpendRemoveParticipantsMutationFn = Apollo.MutationFunction<
  SpendRemoveParticipantsMutation,
  SpendRemoveParticipantsMutationVariables
>;

/**
 * __useSpendRemoveParticipantsMutation__
 *
 * To run a mutation, you first call `useSpendRemoveParticipantsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSpendRemoveParticipantsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [spendRemoveParticipantsMutation, { data, loading, error }] = useSpendRemoveParticipantsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSpendRemoveParticipantsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SpendRemoveParticipantsMutation,
    SpendRemoveParticipantsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SpendRemoveParticipantsMutation,
    SpendRemoveParticipantsMutationVariables
  >(SpendRemoveParticipantsDocument, options);
}
export type SpendRemoveParticipantsMutationHookResult = ReturnType<
  typeof useSpendRemoveParticipantsMutation
>;
export type SpendRemoveParticipantsMutationResult =
  Apollo.MutationResult<SpendRemoveParticipantsMutation>;
export type SpendRemoveParticipantsMutationOptions = Apollo.BaseMutationOptions<
  SpendRemoveParticipantsMutation,
  SpendRemoveParticipantsMutationVariables
>;
export const SpendGroupPayeeCreateDocument = gql`
  mutation SpendGroupPayeeCreate($input: SpendPayeeInput!) {
    spendGroupPayeeCreate(input: $input) {
      payeeId
    }
  }
`;
export type SpendGroupPayeeCreateMutationFn = Apollo.MutationFunction<
  SpendGroupPayeeCreateMutation,
  SpendGroupPayeeCreateMutationVariables
>;

/**
 * __useSpendGroupPayeeCreateMutation__
 *
 * To run a mutation, you first call `useSpendGroupPayeeCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSpendGroupPayeeCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [spendGroupPayeeCreateMutation, { data, loading, error }] = useSpendGroupPayeeCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSpendGroupPayeeCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SpendGroupPayeeCreateMutation,
    SpendGroupPayeeCreateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SpendGroupPayeeCreateMutation,
    SpendGroupPayeeCreateMutationVariables
  >(SpendGroupPayeeCreateDocument, options);
}
export type SpendGroupPayeeCreateMutationHookResult = ReturnType<
  typeof useSpendGroupPayeeCreateMutation
>;
export type SpendGroupPayeeCreateMutationResult =
  Apollo.MutationResult<SpendGroupPayeeCreateMutation>;
export type SpendGroupPayeeCreateMutationOptions = Apollo.BaseMutationOptions<
  SpendGroupPayeeCreateMutation,
  SpendGroupPayeeCreateMutationVariables
>;
export const SpendGroupCheckSendDocument = gql`
  mutation SpendGroupCheckSend($input: SpendCheckInput!) {
    spendGroupCheckSend(input: $input) {
      id
      type
    }
  }
`;
export type SpendGroupCheckSendMutationFn = Apollo.MutationFunction<
  SpendGroupCheckSendMutation,
  SpendGroupCheckSendMutationVariables
>;

/**
 * __useSpendGroupCheckSendMutation__
 *
 * To run a mutation, you first call `useSpendGroupCheckSendMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSpendGroupCheckSendMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [spendGroupCheckSendMutation, { data, loading, error }] = useSpendGroupCheckSendMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSpendGroupCheckSendMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SpendGroupCheckSendMutation,
    SpendGroupCheckSendMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SpendGroupCheckSendMutation,
    SpendGroupCheckSendMutationVariables
  >(SpendGroupCheckSendDocument, options);
}
export type SpendGroupCheckSendMutationHookResult = ReturnType<
  typeof useSpendGroupCheckSendMutation
>;
export type SpendGroupCheckSendMutationResult =
  Apollo.MutationResult<SpendGroupCheckSendMutation>;
export type SpendGroupCheckSendMutationOptions = Apollo.BaseMutationOptions<
  SpendGroupCheckSendMutation,
  SpendGroupCheckSendMutationVariables
>;
export const SpendGroupCheckCancelDocument = gql`
  mutation SpendGroupCheckCancel($checkId: String!) {
    spendGroupCheckCancel(checkId: $checkId) {
      id
    }
  }
`;
export type SpendGroupCheckCancelMutationFn = Apollo.MutationFunction<
  SpendGroupCheckCancelMutation,
  SpendGroupCheckCancelMutationVariables
>;

/**
 * __useSpendGroupCheckCancelMutation__
 *
 * To run a mutation, you first call `useSpendGroupCheckCancelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSpendGroupCheckCancelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [spendGroupCheckCancelMutation, { data, loading, error }] = useSpendGroupCheckCancelMutation({
 *   variables: {
 *      checkId: // value for 'checkId'
 *   },
 * });
 */
export function useSpendGroupCheckCancelMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SpendGroupCheckCancelMutation,
    SpendGroupCheckCancelMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SpendGroupCheckCancelMutation,
    SpendGroupCheckCancelMutationVariables
  >(SpendGroupCheckCancelDocument, options);
}
export type SpendGroupCheckCancelMutationHookResult = ReturnType<
  typeof useSpendGroupCheckCancelMutation
>;
export type SpendGroupCheckCancelMutationResult =
  Apollo.MutationResult<SpendGroupCheckCancelMutation>;
export type SpendGroupCheckCancelMutationOptions = Apollo.BaseMutationOptions<
  SpendGroupCheckCancelMutation,
  SpendGroupCheckCancelMutationVariables
>;
export const SpendGroupPayeeUpdateDocument = gql`
  mutation SpendGroupPayeeUpdate($input: SpendPayeeUpdateInput!) {
    spendGroupPayeeUpdate(input: $input) {
      payeeId
    }
  }
`;
export type SpendGroupPayeeUpdateMutationFn = Apollo.MutationFunction<
  SpendGroupPayeeUpdateMutation,
  SpendGroupPayeeUpdateMutationVariables
>;

/**
 * __useSpendGroupPayeeUpdateMutation__
 *
 * To run a mutation, you first call `useSpendGroupPayeeUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSpendGroupPayeeUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [spendGroupPayeeUpdateMutation, { data, loading, error }] = useSpendGroupPayeeUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSpendGroupPayeeUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SpendGroupPayeeUpdateMutation,
    SpendGroupPayeeUpdateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SpendGroupPayeeUpdateMutation,
    SpendGroupPayeeUpdateMutationVariables
  >(SpendGroupPayeeUpdateDocument, options);
}
export type SpendGroupPayeeUpdateMutationHookResult = ReturnType<
  typeof useSpendGroupPayeeUpdateMutation
>;
export type SpendGroupPayeeUpdateMutationResult =
  Apollo.MutationResult<SpendGroupPayeeUpdateMutation>;
export type SpendGroupPayeeUpdateMutationOptions = Apollo.BaseMutationOptions<
  SpendGroupPayeeUpdateMutation,
  SpendGroupPayeeUpdateMutationVariables
>;
export const SpendUpdateGroupRosterDocument = gql`
  mutation SpendUpdateGroupRoster($input: SpendRosterUserUpdate!) {
    spendGroupRosterUpdate(input: $input) {
      id
      invitesUpdated
    }
  }
`;
export type SpendUpdateGroupRosterMutationFn = Apollo.MutationFunction<
  SpendUpdateGroupRosterMutation,
  SpendUpdateGroupRosterMutationVariables
>;

/**
 * __useSpendUpdateGroupRosterMutation__
 *
 * To run a mutation, you first call `useSpendUpdateGroupRosterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSpendUpdateGroupRosterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [spendUpdateGroupRosterMutation, { data, loading, error }] = useSpendUpdateGroupRosterMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSpendUpdateGroupRosterMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SpendUpdateGroupRosterMutation,
    SpendUpdateGroupRosterMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SpendUpdateGroupRosterMutation,
    SpendUpdateGroupRosterMutationVariables
  >(SpendUpdateGroupRosterDocument, options);
}
export type SpendUpdateGroupRosterMutationHookResult = ReturnType<
  typeof useSpendUpdateGroupRosterMutation
>;
export type SpendUpdateGroupRosterMutationResult =
  Apollo.MutationResult<SpendUpdateGroupRosterMutation>;
export type SpendUpdateGroupRosterMutationOptions = Apollo.BaseMutationOptions<
  SpendUpdateGroupRosterMutation,
  SpendUpdateGroupRosterMutationVariables
>;
export const SpendGroupCounterpartyDeleteDocument = gql`
  mutation SpendGroupCounterpartyDelete(
    $counterpartyId: String!
    $groupId: String!
  ) {
    spendGroupCounterpartyDelete(
      counterpartyId: $counterpartyId
      groupId: $groupId
    ) {
      success
    }
  }
`;
export type SpendGroupCounterpartyDeleteMutationFn = Apollo.MutationFunction<
  SpendGroupCounterpartyDeleteMutation,
  SpendGroupCounterpartyDeleteMutationVariables
>;

/**
 * __useSpendGroupCounterpartyDeleteMutation__
 *
 * To run a mutation, you first call `useSpendGroupCounterpartyDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSpendGroupCounterpartyDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [spendGroupCounterpartyDeleteMutation, { data, loading, error }] = useSpendGroupCounterpartyDeleteMutation({
 *   variables: {
 *      counterpartyId: // value for 'counterpartyId'
 *      groupId: // value for 'groupId'
 *   },
 * });
 */
export function useSpendGroupCounterpartyDeleteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SpendGroupCounterpartyDeleteMutation,
    SpendGroupCounterpartyDeleteMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SpendGroupCounterpartyDeleteMutation,
    SpendGroupCounterpartyDeleteMutationVariables
  >(SpendGroupCounterpartyDeleteDocument, options);
}
export type SpendGroupCounterpartyDeleteMutationHookResult = ReturnType<
  typeof useSpendGroupCounterpartyDeleteMutation
>;
export type SpendGroupCounterpartyDeleteMutationResult =
  Apollo.MutationResult<SpendGroupCounterpartyDeleteMutation>;
export type SpendGroupCounterpartyDeleteMutationOptions =
  Apollo.BaseMutationOptions<
    SpendGroupCounterpartyDeleteMutation,
    SpendGroupCounterpartyDeleteMutationVariables
  >;
export const SpendGroupAchCreditDocument = gql`
  mutation SpendGroupACHCredit($input: SpendGroupACHCredit) {
    spendGroupACHCredit(input: $input) {
      paymentId
    }
  }
`;
export type SpendGroupAchCreditMutationFn = Apollo.MutationFunction<
  SpendGroupAchCreditMutation,
  SpendGroupAchCreditMutationVariables
>;

/**
 * __useSpendGroupAchCreditMutation__
 *
 * To run a mutation, you first call `useSpendGroupAchCreditMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSpendGroupAchCreditMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [spendGroupAchCreditMutation, { data, loading, error }] = useSpendGroupAchCreditMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSpendGroupAchCreditMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SpendGroupAchCreditMutation,
    SpendGroupAchCreditMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SpendGroupAchCreditMutation,
    SpendGroupAchCreditMutationVariables
  >(SpendGroupAchCreditDocument, options);
}
export type SpendGroupAchCreditMutationHookResult = ReturnType<
  typeof useSpendGroupAchCreditMutation
>;
export type SpendGroupAchCreditMutationResult =
  Apollo.MutationResult<SpendGroupAchCreditMutation>;
export type SpendGroupAchCreditMutationOptions = Apollo.BaseMutationOptions<
  SpendGroupAchCreditMutation,
  SpendGroupAchCreditMutationVariables
>;
export const SpendUserInviteCreateDocument = gql`
  mutation SpendUserInviteCreate($input: SpendInviteInput!) {
    spendUserInviteCreate(input: $input) {
      id
    }
  }
`;
export type SpendUserInviteCreateMutationFn = Apollo.MutationFunction<
  SpendUserInviteCreateMutation,
  SpendUserInviteCreateMutationVariables
>;

/**
 * __useSpendUserInviteCreateMutation__
 *
 * To run a mutation, you first call `useSpendUserInviteCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSpendUserInviteCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [spendUserInviteCreateMutation, { data, loading, error }] = useSpendUserInviteCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSpendUserInviteCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SpendUserInviteCreateMutation,
    SpendUserInviteCreateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SpendUserInviteCreateMutation,
    SpendUserInviteCreateMutationVariables
  >(SpendUserInviteCreateDocument, options);
}
export type SpendUserInviteCreateMutationHookResult = ReturnType<
  typeof useSpendUserInviteCreateMutation
>;
export type SpendUserInviteCreateMutationResult =
  Apollo.MutationResult<SpendUserInviteCreateMutation>;
export type SpendUserInviteCreateMutationOptions = Apollo.BaseMutationOptions<
  SpendUserInviteCreateMutation,
  SpendUserInviteCreateMutationVariables
>;
export const SpendUserInviteResendDocument = gql`
  mutation SpendUserInviteResend($input: SpendInviteResendInput!) {
    spendUserInviteResend(input: $input) {
      id
    }
  }
`;
export type SpendUserInviteResendMutationFn = Apollo.MutationFunction<
  SpendUserInviteResendMutation,
  SpendUserInviteResendMutationVariables
>;

/**
 * __useSpendUserInviteResendMutation__
 *
 * To run a mutation, you first call `useSpendUserInviteResendMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSpendUserInviteResendMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [spendUserInviteResendMutation, { data, loading, error }] = useSpendUserInviteResendMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSpendUserInviteResendMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SpendUserInviteResendMutation,
    SpendUserInviteResendMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SpendUserInviteResendMutation,
    SpendUserInviteResendMutationVariables
  >(SpendUserInviteResendDocument, options);
}
export type SpendUserInviteResendMutationHookResult = ReturnType<
  typeof useSpendUserInviteResendMutation
>;
export type SpendUserInviteResendMutationResult =
  Apollo.MutationResult<SpendUserInviteResendMutation>;
export type SpendUserInviteResendMutationOptions = Apollo.BaseMutationOptions<
  SpendUserInviteResendMutation,
  SpendUserInviteResendMutationVariables
>;
export const SpendUserInviteUpdateDocument = gql`
  mutation SpendUserInviteUpdate($spendUserInviteUpdateId: String!) {
    spendUserInviteUpdate(id: $spendUserInviteUpdateId) {
      id
    }
  }
`;
export type SpendUserInviteUpdateMutationFn = Apollo.MutationFunction<
  SpendUserInviteUpdateMutation,
  SpendUserInviteUpdateMutationVariables
>;

/**
 * __useSpendUserInviteUpdateMutation__
 *
 * To run a mutation, you first call `useSpendUserInviteUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSpendUserInviteUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [spendUserInviteUpdateMutation, { data, loading, error }] = useSpendUserInviteUpdateMutation({
 *   variables: {
 *      spendUserInviteUpdateId: // value for 'spendUserInviteUpdateId'
 *   },
 * });
 */
export function useSpendUserInviteUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SpendUserInviteUpdateMutation,
    SpendUserInviteUpdateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SpendUserInviteUpdateMutation,
    SpendUserInviteUpdateMutationVariables
  >(SpendUserInviteUpdateDocument, options);
}
export type SpendUserInviteUpdateMutationHookResult = ReturnType<
  typeof useSpendUserInviteUpdateMutation
>;
export type SpendUserInviteUpdateMutationResult =
  Apollo.MutationResult<SpendUserInviteUpdateMutation>;
export type SpendUserInviteUpdateMutationOptions = Apollo.BaseMutationOptions<
  SpendUserInviteUpdateMutation,
  SpendUserInviteUpdateMutationVariables
>;
export const SpendUserInviteDeleteDocument = gql`
  mutation SpendUserInviteDelete($spendUserInviteDeleteId: String!) {
    spendUserInviteDelete(id: $spendUserInviteDeleteId) {
      id
    }
  }
`;
export type SpendUserInviteDeleteMutationFn = Apollo.MutationFunction<
  SpendUserInviteDeleteMutation,
  SpendUserInviteDeleteMutationVariables
>;

/**
 * __useSpendUserInviteDeleteMutation__
 *
 * To run a mutation, you first call `useSpendUserInviteDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSpendUserInviteDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [spendUserInviteDeleteMutation, { data, loading, error }] = useSpendUserInviteDeleteMutation({
 *   variables: {
 *      spendUserInviteDeleteId: // value for 'spendUserInviteDeleteId'
 *   },
 * });
 */
export function useSpendUserInviteDeleteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SpendUserInviteDeleteMutation,
    SpendUserInviteDeleteMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SpendUserInviteDeleteMutation,
    SpendUserInviteDeleteMutationVariables
  >(SpendUserInviteDeleteDocument, options);
}
export type SpendUserInviteDeleteMutationHookResult = ReturnType<
  typeof useSpendUserInviteDeleteMutation
>;
export type SpendUserInviteDeleteMutationResult =
  Apollo.MutationResult<SpendUserInviteDeleteMutation>;
export type SpendUserInviteDeleteMutationOptions = Apollo.BaseMutationOptions<
  SpendUserInviteDeleteMutation,
  SpendUserInviteDeleteMutationVariables
>;
export const SpendUserInviteArchiveDocument = gql`
  mutation SpendUserInviteArchive($spendUserInviteArchiveId: String!) {
    spendUserInviteArchive(id: $spendUserInviteArchiveId) {
      id
    }
  }
`;
export type SpendUserInviteArchiveMutationFn = Apollo.MutationFunction<
  SpendUserInviteArchiveMutation,
  SpendUserInviteArchiveMutationVariables
>;

/**
 * __useSpendUserInviteArchiveMutation__
 *
 * To run a mutation, you first call `useSpendUserInviteArchiveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSpendUserInviteArchiveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [spendUserInviteArchiveMutation, { data, loading, error }] = useSpendUserInviteArchiveMutation({
 *   variables: {
 *      spendUserInviteArchiveId: // value for 'spendUserInviteArchiveId'
 *   },
 * });
 */
export function useSpendUserInviteArchiveMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SpendUserInviteArchiveMutation,
    SpendUserInviteArchiveMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SpendUserInviteArchiveMutation,
    SpendUserInviteArchiveMutationVariables
  >(SpendUserInviteArchiveDocument, options);
}
export type SpendUserInviteArchiveMutationHookResult = ReturnType<
  typeof useSpendUserInviteArchiveMutation
>;
export type SpendUserInviteArchiveMutationResult =
  Apollo.MutationResult<SpendUserInviteArchiveMutation>;
export type SpendUserInviteArchiveMutationOptions = Apollo.BaseMutationOptions<
  SpendUserInviteArchiveMutation,
  SpendUserInviteArchiveMutationVariables
>;
export const SpendUserInviteStatusUpdateDocument = gql`
  mutation SpendUserInviteStatusUpdate(
    $spendUserInviteStatusUpdateId: String!
    $status: String!
  ) {
    spendUserInviteStatusUpdate(
      id: $spendUserInviteStatusUpdateId
      status: $status
    ) {
      id
    }
  }
`;
export type SpendUserInviteStatusUpdateMutationFn = Apollo.MutationFunction<
  SpendUserInviteStatusUpdateMutation,
  SpendUserInviteStatusUpdateMutationVariables
>;

/**
 * __useSpendUserInviteStatusUpdateMutation__
 *
 * To run a mutation, you first call `useSpendUserInviteStatusUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSpendUserInviteStatusUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [spendUserInviteStatusUpdateMutation, { data, loading, error }] = useSpendUserInviteStatusUpdateMutation({
 *   variables: {
 *      spendUserInviteStatusUpdateId: // value for 'spendUserInviteStatusUpdateId'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useSpendUserInviteStatusUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SpendUserInviteStatusUpdateMutation,
    SpendUserInviteStatusUpdateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SpendUserInviteStatusUpdateMutation,
    SpendUserInviteStatusUpdateMutationVariables
  >(SpendUserInviteStatusUpdateDocument, options);
}
export type SpendUserInviteStatusUpdateMutationHookResult = ReturnType<
  typeof useSpendUserInviteStatusUpdateMutation
>;
export type SpendUserInviteStatusUpdateMutationResult =
  Apollo.MutationResult<SpendUserInviteStatusUpdateMutation>;
export type SpendUserInviteStatusUpdateMutationOptions =
  Apollo.BaseMutationOptions<
    SpendUserInviteStatusUpdateMutation,
    SpendUserInviteStatusUpdateMutationVariables
  >;
export const SpendUserInviteDismissDocument = gql`
  mutation SpendUserInviteDismiss($inviteId: String!) {
    spendUserInviteDismiss(inviteId: $inviteId) {
      inviteId
    }
  }
`;
export type SpendUserInviteDismissMutationFn = Apollo.MutationFunction<
  SpendUserInviteDismissMutation,
  SpendUserInviteDismissMutationVariables
>;

/**
 * __useSpendUserInviteDismissMutation__
 *
 * To run a mutation, you first call `useSpendUserInviteDismissMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSpendUserInviteDismissMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [spendUserInviteDismissMutation, { data, loading, error }] = useSpendUserInviteDismissMutation({
 *   variables: {
 *      inviteId: // value for 'inviteId'
 *   },
 * });
 */
export function useSpendUserInviteDismissMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SpendUserInviteDismissMutation,
    SpendUserInviteDismissMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SpendUserInviteDismissMutation,
    SpendUserInviteDismissMutationVariables
  >(SpendUserInviteDismissDocument, options);
}
export type SpendUserInviteDismissMutationHookResult = ReturnType<
  typeof useSpendUserInviteDismissMutation
>;
export type SpendUserInviteDismissMutationResult =
  Apollo.MutationResult<SpendUserInviteDismissMutation>;
export type SpendUserInviteDismissMutationOptions = Apollo.BaseMutationOptions<
  SpendUserInviteDismissMutation,
  SpendUserInviteDismissMutationVariables
>;
export const SpendInvoiceCreateDocument = gql`
  mutation SpendInvoiceCreate($input: SpendInvoiceInput!) {
    spendInvoiceCreate(input: $input) {
      id
    }
  }
`;
export type SpendInvoiceCreateMutationFn = Apollo.MutationFunction<
  SpendInvoiceCreateMutation,
  SpendInvoiceCreateMutationVariables
>;

/**
 * __useSpendInvoiceCreateMutation__
 *
 * To run a mutation, you first call `useSpendInvoiceCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSpendInvoiceCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [spendInvoiceCreateMutation, { data, loading, error }] = useSpendInvoiceCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSpendInvoiceCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SpendInvoiceCreateMutation,
    SpendInvoiceCreateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SpendInvoiceCreateMutation,
    SpendInvoiceCreateMutationVariables
  >(SpendInvoiceCreateDocument, options);
}
export type SpendInvoiceCreateMutationHookResult = ReturnType<
  typeof useSpendInvoiceCreateMutation
>;
export type SpendInvoiceCreateMutationResult =
  Apollo.MutationResult<SpendInvoiceCreateMutation>;
export type SpendInvoiceCreateMutationOptions = Apollo.BaseMutationOptions<
  SpendInvoiceCreateMutation,
  SpendInvoiceCreateMutationVariables
>;
export const SpendInvoiceReminderCreateDocument = gql`
  mutation SpendInvoiceReminderCreate($input: SpendInvoiceReminderInput!) {
    spendInvoiceReminderCreate(input: $input) {
      id
    }
  }
`;
export type SpendInvoiceReminderCreateMutationFn = Apollo.MutationFunction<
  SpendInvoiceReminderCreateMutation,
  SpendInvoiceReminderCreateMutationVariables
>;

/**
 * __useSpendInvoiceReminderCreateMutation__
 *
 * To run a mutation, you first call `useSpendInvoiceReminderCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSpendInvoiceReminderCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [spendInvoiceReminderCreateMutation, { data, loading, error }] = useSpendInvoiceReminderCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSpendInvoiceReminderCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SpendInvoiceReminderCreateMutation,
    SpendInvoiceReminderCreateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SpendInvoiceReminderCreateMutation,
    SpendInvoiceReminderCreateMutationVariables
  >(SpendInvoiceReminderCreateDocument, options);
}
export type SpendInvoiceReminderCreateMutationHookResult = ReturnType<
  typeof useSpendInvoiceReminderCreateMutation
>;
export type SpendInvoiceReminderCreateMutationResult =
  Apollo.MutationResult<SpendInvoiceReminderCreateMutation>;
export type SpendInvoiceReminderCreateMutationOptions =
  Apollo.BaseMutationOptions<
    SpendInvoiceReminderCreateMutation,
    SpendInvoiceReminderCreateMutationVariables
  >;
export const SpendInvoiceUpdateDocument = gql`
  mutation SpendInvoiceUpdate(
    $spendInvoiceUpdateId: String!
    $input: SpendInvoiceInput!
  ) {
    spendInvoiceUpdate(id: $spendInvoiceUpdateId, input: $input) {
      id
    }
  }
`;
export type SpendInvoiceUpdateMutationFn = Apollo.MutationFunction<
  SpendInvoiceUpdateMutation,
  SpendInvoiceUpdateMutationVariables
>;

/**
 * __useSpendInvoiceUpdateMutation__
 *
 * To run a mutation, you first call `useSpendInvoiceUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSpendInvoiceUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [spendInvoiceUpdateMutation, { data, loading, error }] = useSpendInvoiceUpdateMutation({
 *   variables: {
 *      spendInvoiceUpdateId: // value for 'spendInvoiceUpdateId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSpendInvoiceUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SpendInvoiceUpdateMutation,
    SpendInvoiceUpdateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SpendInvoiceUpdateMutation,
    SpendInvoiceUpdateMutationVariables
  >(SpendInvoiceUpdateDocument, options);
}
export type SpendInvoiceUpdateMutationHookResult = ReturnType<
  typeof useSpendInvoiceUpdateMutation
>;
export type SpendInvoiceUpdateMutationResult =
  Apollo.MutationResult<SpendInvoiceUpdateMutation>;
export type SpendInvoiceUpdateMutationOptions = Apollo.BaseMutationOptions<
  SpendInvoiceUpdateMutation,
  SpendInvoiceUpdateMutationVariables
>;
export const SpendInvoiceOptInOrOutDocument = gql`
  mutation SpendInvoiceOptInOrOut($input: SpendOptInOrOutInput!) {
    spendInvoiceOptInOrOut(input: $input) {
      id
    }
  }
`;
export type SpendInvoiceOptInOrOutMutationFn = Apollo.MutationFunction<
  SpendInvoiceOptInOrOutMutation,
  SpendInvoiceOptInOrOutMutationVariables
>;

/**
 * __useSpendInvoiceOptInOrOutMutation__
 *
 * To run a mutation, you first call `useSpendInvoiceOptInOrOutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSpendInvoiceOptInOrOutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [spendInvoiceOptInOrOutMutation, { data, loading, error }] = useSpendInvoiceOptInOrOutMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSpendInvoiceOptInOrOutMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SpendInvoiceOptInOrOutMutation,
    SpendInvoiceOptInOrOutMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SpendInvoiceOptInOrOutMutation,
    SpendInvoiceOptInOrOutMutationVariables
  >(SpendInvoiceOptInOrOutDocument, options);
}
export type SpendInvoiceOptInOrOutMutationHookResult = ReturnType<
  typeof useSpendInvoiceOptInOrOutMutation
>;
export type SpendInvoiceOptInOrOutMutationResult =
  Apollo.MutationResult<SpendInvoiceOptInOrOutMutation>;
export type SpendInvoiceOptInOrOutMutationOptions = Apollo.BaseMutationOptions<
  SpendInvoiceOptInOrOutMutation,
  SpendInvoiceOptInOrOutMutationVariables
>;
export const SpendInvoicePaymentDeauthorizeDocument = gql`
  mutation SpendInvoicePaymentDeauthorize(
    $input: SpendInvoicePaymentDeauthorizeInput!
  ) {
    spendInvoicePaymentDeauthorize(input: $input) {
      id
      notifSuccess
    }
  }
`;
export type SpendInvoicePaymentDeauthorizeMutationFn = Apollo.MutationFunction<
  SpendInvoicePaymentDeauthorizeMutation,
  SpendInvoicePaymentDeauthorizeMutationVariables
>;

/**
 * __useSpendInvoicePaymentDeauthorizeMutation__
 *
 * To run a mutation, you first call `useSpendInvoicePaymentDeauthorizeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSpendInvoicePaymentDeauthorizeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [spendInvoicePaymentDeauthorizeMutation, { data, loading, error }] = useSpendInvoicePaymentDeauthorizeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSpendInvoicePaymentDeauthorizeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SpendInvoicePaymentDeauthorizeMutation,
    SpendInvoicePaymentDeauthorizeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SpendInvoicePaymentDeauthorizeMutation,
    SpendInvoicePaymentDeauthorizeMutationVariables
  >(SpendInvoicePaymentDeauthorizeDocument, options);
}
export type SpendInvoicePaymentDeauthorizeMutationHookResult = ReturnType<
  typeof useSpendInvoicePaymentDeauthorizeMutation
>;
export type SpendInvoicePaymentDeauthorizeMutationResult =
  Apollo.MutationResult<SpendInvoicePaymentDeauthorizeMutation>;
export type SpendInvoicePaymentDeauthorizeMutationOptions =
  Apollo.BaseMutationOptions<
    SpendInvoicePaymentDeauthorizeMutation,
    SpendInvoicePaymentDeauthorizeMutationVariables
  >;
export const SpendInvoiceChangeRequestDocument = gql`
  mutation SpendInvoiceChangeRequest($input: SpendInvoiceRequestChangeInput!) {
    spendInvoiceChangeRequest(input: $input) {
      id
    }
  }
`;
export type SpendInvoiceChangeRequestMutationFn = Apollo.MutationFunction<
  SpendInvoiceChangeRequestMutation,
  SpendInvoiceChangeRequestMutationVariables
>;

/**
 * __useSpendInvoiceChangeRequestMutation__
 *
 * To run a mutation, you first call `useSpendInvoiceChangeRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSpendInvoiceChangeRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [spendInvoiceChangeRequestMutation, { data, loading, error }] = useSpendInvoiceChangeRequestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSpendInvoiceChangeRequestMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SpendInvoiceChangeRequestMutation,
    SpendInvoiceChangeRequestMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SpendInvoiceChangeRequestMutation,
    SpendInvoiceChangeRequestMutationVariables
  >(SpendInvoiceChangeRequestDocument, options);
}
export type SpendInvoiceChangeRequestMutationHookResult = ReturnType<
  typeof useSpendInvoiceChangeRequestMutation
>;
export type SpendInvoiceChangeRequestMutationResult =
  Apollo.MutationResult<SpendInvoiceChangeRequestMutation>;
export type SpendInvoiceChangeRequestMutationOptions =
  Apollo.BaseMutationOptions<
    SpendInvoiceChangeRequestMutation,
    SpendInvoiceChangeRequestMutationVariables
  >;
export const SpendInvoiceUpdatePaymentMethodDocument = gql`
  mutation SpendInvoiceUpdatePaymentMethod(
    $input: SpendInvoicePaymentMethodUpdate!
  ) {
    spendInvoiceUpdatePaymentMethod(input: $input) {
      ids
    }
  }
`;
export type SpendInvoiceUpdatePaymentMethodMutationFn = Apollo.MutationFunction<
  SpendInvoiceUpdatePaymentMethodMutation,
  SpendInvoiceUpdatePaymentMethodMutationVariables
>;

/**
 * __useSpendInvoiceUpdatePaymentMethodMutation__
 *
 * To run a mutation, you first call `useSpendInvoiceUpdatePaymentMethodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSpendInvoiceUpdatePaymentMethodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [spendInvoiceUpdatePaymentMethodMutation, { data, loading, error }] = useSpendInvoiceUpdatePaymentMethodMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSpendInvoiceUpdatePaymentMethodMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SpendInvoiceUpdatePaymentMethodMutation,
    SpendInvoiceUpdatePaymentMethodMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SpendInvoiceUpdatePaymentMethodMutation,
    SpendInvoiceUpdatePaymentMethodMutationVariables
  >(SpendInvoiceUpdatePaymentMethodDocument, options);
}
export type SpendInvoiceUpdatePaymentMethodMutationHookResult = ReturnType<
  typeof useSpendInvoiceUpdatePaymentMethodMutation
>;
export type SpendInvoiceUpdatePaymentMethodMutationResult =
  Apollo.MutationResult<SpendInvoiceUpdatePaymentMethodMutation>;
export type SpendInvoiceUpdatePaymentMethodMutationOptions =
  Apollo.BaseMutationOptions<
    SpendInvoiceUpdatePaymentMethodMutation,
    SpendInvoiceUpdatePaymentMethodMutationVariables
  >;
export const SpendInvoiceRefundDocument = gql`
  mutation SpendInvoiceRefund($input: SpendInvoiceRefundInput!) {
    spendInvoiceRefund(input: $input) {
      invoiceId
      spendTransactionId
    }
  }
`;
export type SpendInvoiceRefundMutationFn = Apollo.MutationFunction<
  SpendInvoiceRefundMutation,
  SpendInvoiceRefundMutationVariables
>;

/**
 * __useSpendInvoiceRefundMutation__
 *
 * To run a mutation, you first call `useSpendInvoiceRefundMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSpendInvoiceRefundMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [spendInvoiceRefundMutation, { data, loading, error }] = useSpendInvoiceRefundMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSpendInvoiceRefundMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SpendInvoiceRefundMutation,
    SpendInvoiceRefundMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SpendInvoiceRefundMutation,
    SpendInvoiceRefundMutationVariables
  >(SpendInvoiceRefundDocument, options);
}
export type SpendInvoiceRefundMutationHookResult = ReturnType<
  typeof useSpendInvoiceRefundMutation
>;
export type SpendInvoiceRefundMutationResult =
  Apollo.MutationResult<SpendInvoiceRefundMutation>;
export type SpendInvoiceRefundMutationOptions = Apollo.BaseMutationOptions<
  SpendInvoiceRefundMutation,
  SpendInvoiceRefundMutationVariables
>;
export const SpendPaymentScheduleArchiveDocument = gql`
  mutation SpendPaymentScheduleArchive(
    $spendPaymentScheduleUpdateId: String!
    $input: SpendPaymentScheduleInput!
    $rosterIds: [String]
  ) {
    spendPaymentScheduleUpdate(
      id: $spendPaymentScheduleUpdateId
      input: $input
      rosterIds: $rosterIds
    ) {
      id
    }
  }
`;
export type SpendPaymentScheduleArchiveMutationFn = Apollo.MutationFunction<
  SpendPaymentScheduleArchiveMutation,
  SpendPaymentScheduleArchiveMutationVariables
>;

/**
 * __useSpendPaymentScheduleArchiveMutation__
 *
 * To run a mutation, you first call `useSpendPaymentScheduleArchiveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSpendPaymentScheduleArchiveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [spendPaymentScheduleArchiveMutation, { data, loading, error }] = useSpendPaymentScheduleArchiveMutation({
 *   variables: {
 *      spendPaymentScheduleUpdateId: // value for 'spendPaymentScheduleUpdateId'
 *      input: // value for 'input'
 *      rosterIds: // value for 'rosterIds'
 *   },
 * });
 */
export function useSpendPaymentScheduleArchiveMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SpendPaymentScheduleArchiveMutation,
    SpendPaymentScheduleArchiveMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SpendPaymentScheduleArchiveMutation,
    SpendPaymentScheduleArchiveMutationVariables
  >(SpendPaymentScheduleArchiveDocument, options);
}
export type SpendPaymentScheduleArchiveMutationHookResult = ReturnType<
  typeof useSpendPaymentScheduleArchiveMutation
>;
export type SpendPaymentScheduleArchiveMutationResult =
  Apollo.MutationResult<SpendPaymentScheduleArchiveMutation>;
export type SpendPaymentScheduleArchiveMutationOptions =
  Apollo.BaseMutationOptions<
    SpendPaymentScheduleArchiveMutation,
    SpendPaymentScheduleArchiveMutationVariables
  >;
export const SpendPaymentScheduleRevertDocument = gql`
  mutation SpendPaymentScheduleRevert($spendPaymentScheduleRevertId: String!) {
    spendPaymentScheduleRevert(id: $spendPaymentScheduleRevertId) {
      id
    }
  }
`;
export type SpendPaymentScheduleRevertMutationFn = Apollo.MutationFunction<
  SpendPaymentScheduleRevertMutation,
  SpendPaymentScheduleRevertMutationVariables
>;

/**
 * __useSpendPaymentScheduleRevertMutation__
 *
 * To run a mutation, you first call `useSpendPaymentScheduleRevertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSpendPaymentScheduleRevertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [spendPaymentScheduleRevertMutation, { data, loading, error }] = useSpendPaymentScheduleRevertMutation({
 *   variables: {
 *      spendPaymentScheduleRevertId: // value for 'spendPaymentScheduleRevertId'
 *   },
 * });
 */
export function useSpendPaymentScheduleRevertMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SpendPaymentScheduleRevertMutation,
    SpendPaymentScheduleRevertMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SpendPaymentScheduleRevertMutation,
    SpendPaymentScheduleRevertMutationVariables
  >(SpendPaymentScheduleRevertDocument, options);
}
export type SpendPaymentScheduleRevertMutationHookResult = ReturnType<
  typeof useSpendPaymentScheduleRevertMutation
>;
export type SpendPaymentScheduleRevertMutationResult =
  Apollo.MutationResult<SpendPaymentScheduleRevertMutation>;
export type SpendPaymentScheduleRevertMutationOptions =
  Apollo.BaseMutationOptions<
    SpendPaymentScheduleRevertMutation,
    SpendPaymentScheduleRevertMutationVariables
  >;
export const SpendPaymentScheduleRevertManyDocument = gql`
  mutation SpendPaymentScheduleRevertMany(
    $spendPaymentScheduleRevertIds: [String!]!
  ) {
    spendPaymentScheduleRevertMany(ids: $spendPaymentScheduleRevertIds) {
      id
    }
  }
`;
export type SpendPaymentScheduleRevertManyMutationFn = Apollo.MutationFunction<
  SpendPaymentScheduleRevertManyMutation,
  SpendPaymentScheduleRevertManyMutationVariables
>;

/**
 * __useSpendPaymentScheduleRevertManyMutation__
 *
 * To run a mutation, you first call `useSpendPaymentScheduleRevertManyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSpendPaymentScheduleRevertManyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [spendPaymentScheduleRevertManyMutation, { data, loading, error }] = useSpendPaymentScheduleRevertManyMutation({
 *   variables: {
 *      spendPaymentScheduleRevertIds: // value for 'spendPaymentScheduleRevertIds'
 *   },
 * });
 */
export function useSpendPaymentScheduleRevertManyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SpendPaymentScheduleRevertManyMutation,
    SpendPaymentScheduleRevertManyMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SpendPaymentScheduleRevertManyMutation,
    SpendPaymentScheduleRevertManyMutationVariables
  >(SpendPaymentScheduleRevertManyDocument, options);
}
export type SpendPaymentScheduleRevertManyMutationHookResult = ReturnType<
  typeof useSpendPaymentScheduleRevertManyMutation
>;
export type SpendPaymentScheduleRevertManyMutationResult =
  Apollo.MutationResult<SpendPaymentScheduleRevertManyMutation>;
export type SpendPaymentScheduleRevertManyMutationOptions =
  Apollo.BaseMutationOptions<
    SpendPaymentScheduleRevertManyMutation,
    SpendPaymentScheduleRevertManyMutationVariables
  >;
export const SpendTransactionInvoiceUnreconcileDocument = gql`
  mutation SpendTransactionInvoiceUnreconcile(
    $input: SpendTransactionInvoiceUnreconcileInput!
  ) {
    spendTransactionInvoiceUnreconcile(input: $input) {
      id
    }
  }
`;
export type SpendTransactionInvoiceUnreconcileMutationFn =
  Apollo.MutationFunction<
    SpendTransactionInvoiceUnreconcileMutation,
    SpendTransactionInvoiceUnreconcileMutationVariables
  >;

/**
 * __useSpendTransactionInvoiceUnreconcileMutation__
 *
 * To run a mutation, you first call `useSpendTransactionInvoiceUnreconcileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSpendTransactionInvoiceUnreconcileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [spendTransactionInvoiceUnreconcileMutation, { data, loading, error }] = useSpendTransactionInvoiceUnreconcileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSpendTransactionInvoiceUnreconcileMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SpendTransactionInvoiceUnreconcileMutation,
    SpendTransactionInvoiceUnreconcileMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SpendTransactionInvoiceUnreconcileMutation,
    SpendTransactionInvoiceUnreconcileMutationVariables
  >(SpendTransactionInvoiceUnreconcileDocument, options);
}
export type SpendTransactionInvoiceUnreconcileMutationHookResult = ReturnType<
  typeof useSpendTransactionInvoiceUnreconcileMutation
>;
export type SpendTransactionInvoiceUnreconcileMutationResult =
  Apollo.MutationResult<SpendTransactionInvoiceUnreconcileMutation>;
export type SpendTransactionInvoiceUnreconcileMutationOptions =
  Apollo.BaseMutationOptions<
    SpendTransactionInvoiceUnreconcileMutation,
    SpendTransactionInvoiceUnreconcileMutationVariables
  >;
export const SpendInvoiceArchiveDocument = gql`
  mutation SpendInvoiceArchive($spendInvoiceArchiveId: String!) {
    spendInvoiceArchive(id: $spendInvoiceArchiveId) {
      id
    }
  }
`;
export type SpendInvoiceArchiveMutationFn = Apollo.MutationFunction<
  SpendInvoiceArchiveMutation,
  SpendInvoiceArchiveMutationVariables
>;

/**
 * __useSpendInvoiceArchiveMutation__
 *
 * To run a mutation, you first call `useSpendInvoiceArchiveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSpendInvoiceArchiveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [spendInvoiceArchiveMutation, { data, loading, error }] = useSpendInvoiceArchiveMutation({
 *   variables: {
 *      spendInvoiceArchiveId: // value for 'spendInvoiceArchiveId'
 *   },
 * });
 */
export function useSpendInvoiceArchiveMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SpendInvoiceArchiveMutation,
    SpendInvoiceArchiveMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SpendInvoiceArchiveMutation,
    SpendInvoiceArchiveMutationVariables
  >(SpendInvoiceArchiveDocument, options);
}
export type SpendInvoiceArchiveMutationHookResult = ReturnType<
  typeof useSpendInvoiceArchiveMutation
>;
export type SpendInvoiceArchiveMutationResult =
  Apollo.MutationResult<SpendInvoiceArchiveMutation>;
export type SpendInvoiceArchiveMutationOptions = Apollo.BaseMutationOptions<
  SpendInvoiceArchiveMutation,
  SpendInvoiceArchiveMutationVariables
>;
export const SpendAuthorizeAutoPayDocument = gql`
  mutation SpendAuthorizeAutoPay($input: SpendAutoPayInput) {
    spendAuthorizeAutoPay(input: $input) {
      invoiceIds
    }
  }
`;
export type SpendAuthorizeAutoPayMutationFn = Apollo.MutationFunction<
  SpendAuthorizeAutoPayMutation,
  SpendAuthorizeAutoPayMutationVariables
>;

/**
 * __useSpendAuthorizeAutoPayMutation__
 *
 * To run a mutation, you first call `useSpendAuthorizeAutoPayMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSpendAuthorizeAutoPayMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [spendAuthorizeAutoPayMutation, { data, loading, error }] = useSpendAuthorizeAutoPayMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSpendAuthorizeAutoPayMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SpendAuthorizeAutoPayMutation,
    SpendAuthorizeAutoPayMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SpendAuthorizeAutoPayMutation,
    SpendAuthorizeAutoPayMutationVariables
  >(SpendAuthorizeAutoPayDocument, options);
}
export type SpendAuthorizeAutoPayMutationHookResult = ReturnType<
  typeof useSpendAuthorizeAutoPayMutation
>;
export type SpendAuthorizeAutoPayMutationResult =
  Apollo.MutationResult<SpendAuthorizeAutoPayMutation>;
export type SpendAuthorizeAutoPayMutationOptions = Apollo.BaseMutationOptions<
  SpendAuthorizeAutoPayMutation,
  SpendAuthorizeAutoPayMutationVariables
>;
export const SpendNotificationCreateDocument = gql`
  mutation SpendNotificationCreate($input: SpendNotificationInput!) {
    spendNotificationCreate(input: $input) {
      status
    }
  }
`;
export type SpendNotificationCreateMutationFn = Apollo.MutationFunction<
  SpendNotificationCreateMutation,
  SpendNotificationCreateMutationVariables
>;

/**
 * __useSpendNotificationCreateMutation__
 *
 * To run a mutation, you first call `useSpendNotificationCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSpendNotificationCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [spendNotificationCreateMutation, { data, loading, error }] = useSpendNotificationCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSpendNotificationCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SpendNotificationCreateMutation,
    SpendNotificationCreateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SpendNotificationCreateMutation,
    SpendNotificationCreateMutationVariables
  >(SpendNotificationCreateDocument, options);
}
export type SpendNotificationCreateMutationHookResult = ReturnType<
  typeof useSpendNotificationCreateMutation
>;
export type SpendNotificationCreateMutationResult =
  Apollo.MutationResult<SpendNotificationCreateMutation>;
export type SpendNotificationCreateMutationOptions = Apollo.BaseMutationOptions<
  SpendNotificationCreateMutation,
  SpendNotificationCreateMutationVariables
>;
export const SpendPaymentScheduleCreateDocument = gql`
  mutation SpendPaymentScheduleCreate($input: SpendPaymentScheduleInput!) {
    spendPaymentScheduleCreate(input: $input) {
      id
    }
  }
`;
export type SpendPaymentScheduleCreateMutationFn = Apollo.MutationFunction<
  SpendPaymentScheduleCreateMutation,
  SpendPaymentScheduleCreateMutationVariables
>;

/**
 * __useSpendPaymentScheduleCreateMutation__
 *
 * To run a mutation, you first call `useSpendPaymentScheduleCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSpendPaymentScheduleCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [spendPaymentScheduleCreateMutation, { data, loading, error }] = useSpendPaymentScheduleCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSpendPaymentScheduleCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SpendPaymentScheduleCreateMutation,
    SpendPaymentScheduleCreateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SpendPaymentScheduleCreateMutation,
    SpendPaymentScheduleCreateMutationVariables
  >(SpendPaymentScheduleCreateDocument, options);
}
export type SpendPaymentScheduleCreateMutationHookResult = ReturnType<
  typeof useSpendPaymentScheduleCreateMutation
>;
export type SpendPaymentScheduleCreateMutationResult =
  Apollo.MutationResult<SpendPaymentScheduleCreateMutation>;
export type SpendPaymentScheduleCreateMutationOptions =
  Apollo.BaseMutationOptions<
    SpendPaymentScheduleCreateMutation,
    SpendPaymentScheduleCreateMutationVariables
  >;
export const SpendPaymentScheduleUpdateDocument = gql`
  mutation SpendPaymentScheduleUpdate(
    $spendPaymentScheduleUpdateId: String!
    $input: SpendPaymentScheduleInput!
  ) {
    spendPaymentScheduleUpdate(
      id: $spendPaymentScheduleUpdateId
      input: $input
    ) {
      id
    }
  }
`;
export type SpendPaymentScheduleUpdateMutationFn = Apollo.MutationFunction<
  SpendPaymentScheduleUpdateMutation,
  SpendPaymentScheduleUpdateMutationVariables
>;

/**
 * __useSpendPaymentScheduleUpdateMutation__
 *
 * To run a mutation, you first call `useSpendPaymentScheduleUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSpendPaymentScheduleUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [spendPaymentScheduleUpdateMutation, { data, loading, error }] = useSpendPaymentScheduleUpdateMutation({
 *   variables: {
 *      spendPaymentScheduleUpdateId: // value for 'spendPaymentScheduleUpdateId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSpendPaymentScheduleUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SpendPaymentScheduleUpdateMutation,
    SpendPaymentScheduleUpdateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SpendPaymentScheduleUpdateMutation,
    SpendPaymentScheduleUpdateMutationVariables
  >(SpendPaymentScheduleUpdateDocument, options);
}
export type SpendPaymentScheduleUpdateMutationHookResult = ReturnType<
  typeof useSpendPaymentScheduleUpdateMutation
>;
export type SpendPaymentScheduleUpdateMutationResult =
  Apollo.MutationResult<SpendPaymentScheduleUpdateMutation>;
export type SpendPaymentScheduleUpdateMutationOptions =
  Apollo.BaseMutationOptions<
    SpendPaymentScheduleUpdateMutation,
    SpendPaymentScheduleUpdateMutationVariables
  >;
export const SpendUserAchPaymentDocument = gql`
  mutation SpendUserAchPayment($input: SpendAchPaymentInput!) {
    spendUserAchPayment(input: $input) {
      id
      status
      amount
      descriptor
      transactionIdList
    }
  }
`;
export type SpendUserAchPaymentMutationFn = Apollo.MutationFunction<
  SpendUserAchPaymentMutation,
  SpendUserAchPaymentMutationVariables
>;

/**
 * __useSpendUserAchPaymentMutation__
 *
 * To run a mutation, you first call `useSpendUserAchPaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSpendUserAchPaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [spendUserAchPaymentMutation, { data, loading, error }] = useSpendUserAchPaymentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSpendUserAchPaymentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SpendUserAchPaymentMutation,
    SpendUserAchPaymentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SpendUserAchPaymentMutation,
    SpendUserAchPaymentMutationVariables
  >(SpendUserAchPaymentDocument, options);
}
export type SpendUserAchPaymentMutationHookResult = ReturnType<
  typeof useSpendUserAchPaymentMutation
>;
export type SpendUserAchPaymentMutationResult =
  Apollo.MutationResult<SpendUserAchPaymentMutation>;
export type SpendUserAchPaymentMutationOptions = Apollo.BaseMutationOptions<
  SpendUserAchPaymentMutation,
  SpendUserAchPaymentMutationVariables
>;
export const SpendUserAchPaymentCancelDocument = gql`
  mutation SpendUserAchPaymentCancel($input: SpendAchPaymentCancelInput!) {
    spendUserAchPaymentCancel(input: $input) {
      id
    }
  }
`;
export type SpendUserAchPaymentCancelMutationFn = Apollo.MutationFunction<
  SpendUserAchPaymentCancelMutation,
  SpendUserAchPaymentCancelMutationVariables
>;

/**
 * __useSpendUserAchPaymentCancelMutation__
 *
 * To run a mutation, you first call `useSpendUserAchPaymentCancelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSpendUserAchPaymentCancelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [spendUserAchPaymentCancelMutation, { data, loading, error }] = useSpendUserAchPaymentCancelMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSpendUserAchPaymentCancelMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SpendUserAchPaymentCancelMutation,
    SpendUserAchPaymentCancelMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SpendUserAchPaymentCancelMutation,
    SpendUserAchPaymentCancelMutationVariables
  >(SpendUserAchPaymentCancelDocument, options);
}
export type SpendUserAchPaymentCancelMutationHookResult = ReturnType<
  typeof useSpendUserAchPaymentCancelMutation
>;
export type SpendUserAchPaymentCancelMutationResult =
  Apollo.MutationResult<SpendUserAchPaymentCancelMutation>;
export type SpendUserAchPaymentCancelMutationOptions =
  Apollo.BaseMutationOptions<
    SpendUserAchPaymentCancelMutation,
    SpendUserAchPaymentCancelMutationVariables
  >;
export const DeleteCardPaymentDocument = gql`
  mutation DeleteCardPayment($input: PaymentsApiDetachPaymentMethodInput) {
    paymentsApiDetachPaymentMethod(input: $input) {
      paymentMethodId
    }
  }
`;
export type DeleteCardPaymentMutationFn = Apollo.MutationFunction<
  DeleteCardPaymentMutation,
  DeleteCardPaymentMutationVariables
>;

/**
 * __useDeleteCardPaymentMutation__
 *
 * To run a mutation, you first call `useDeleteCardPaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCardPaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCardPaymentMutation, { data, loading, error }] = useDeleteCardPaymentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteCardPaymentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteCardPaymentMutation,
    DeleteCardPaymentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteCardPaymentMutation,
    DeleteCardPaymentMutationVariables
  >(DeleteCardPaymentDocument, options);
}
export type DeleteCardPaymentMutationHookResult = ReturnType<
  typeof useDeleteCardPaymentMutation
>;
export type DeleteCardPaymentMutationResult =
  Apollo.MutationResult<DeleteCardPaymentMutation>;
export type DeleteCardPaymentMutationOptions = Apollo.BaseMutationOptions<
  DeleteCardPaymentMutation,
  DeleteCardPaymentMutationVariables
>;
export const PaymentsApiDetachPaymentMethodDocument = gql`
  mutation PaymentsApiDetachPaymentMethod(
    $input: PaymentsApiDetachPaymentMethodInput
  ) {
    paymentsApiDetachPaymentMethod(input: $input) {
      stripeEnv
      paymentMethodId
    }
  }
`;
export type PaymentsApiDetachPaymentMethodMutationFn = Apollo.MutationFunction<
  PaymentsApiDetachPaymentMethodMutation,
  PaymentsApiDetachPaymentMethodMutationVariables
>;

/**
 * __usePaymentsApiDetachPaymentMethodMutation__
 *
 * To run a mutation, you first call `usePaymentsApiDetachPaymentMethodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePaymentsApiDetachPaymentMethodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [paymentsApiDetachPaymentMethodMutation, { data, loading, error }] = usePaymentsApiDetachPaymentMethodMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePaymentsApiDetachPaymentMethodMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PaymentsApiDetachPaymentMethodMutation,
    PaymentsApiDetachPaymentMethodMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    PaymentsApiDetachPaymentMethodMutation,
    PaymentsApiDetachPaymentMethodMutationVariables
  >(PaymentsApiDetachPaymentMethodDocument, options);
}
export type PaymentsApiDetachPaymentMethodMutationHookResult = ReturnType<
  typeof usePaymentsApiDetachPaymentMethodMutation
>;
export type PaymentsApiDetachPaymentMethodMutationResult =
  Apollo.MutationResult<PaymentsApiDetachPaymentMethodMutation>;
export type PaymentsApiDetachPaymentMethodMutationOptions =
  Apollo.BaseMutationOptions<
    PaymentsApiDetachPaymentMethodMutation,
    PaymentsApiDetachPaymentMethodMutationVariables
  >;
export const PaymentsApiCustomerDocument = gql`
  mutation PaymentsApiCustomer {
    paymentsApiCustomer: paymentsApiCustomerGetOrCreate(stripeEnv: SPEND) {
      customerId
      email
      name
      paymentMethods {
        billingAddress {
          city
          country
          line1
          line2
          postalCode
          state
        }
        brand
        expiration {
          month
          year
        }
        id
        identifier
        type
        zipCode
      }
    }
  }
`;
export type PaymentsApiCustomerMutationFn = Apollo.MutationFunction<
  PaymentsApiCustomerMutation,
  PaymentsApiCustomerMutationVariables
>;

/**
 * __usePaymentsApiCustomerMutation__
 *
 * To run a mutation, you first call `usePaymentsApiCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePaymentsApiCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [paymentsApiCustomerMutation, { data, loading, error }] = usePaymentsApiCustomerMutation({
 *   variables: {
 *   },
 * });
 */
export function usePaymentsApiCustomerMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PaymentsApiCustomerMutation,
    PaymentsApiCustomerMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    PaymentsApiCustomerMutation,
    PaymentsApiCustomerMutationVariables
  >(PaymentsApiCustomerDocument, options);
}
export type PaymentsApiCustomerMutationHookResult = ReturnType<
  typeof usePaymentsApiCustomerMutation
>;
export type PaymentsApiCustomerMutationResult =
  Apollo.MutationResult<PaymentsApiCustomerMutation>;
export type PaymentsApiCustomerMutationOptions = Apollo.BaseMutationOptions<
  PaymentsApiCustomerMutation,
  PaymentsApiCustomerMutationVariables
>;
export const SpendUserBankAccessTokenCreateDocument = gql`
  mutation SpendUserBankAccessTokenCreate(
    $publicToken: String!
    $groupId: String!
    $status: String
  ) {
    spendUserBankAccessTokenCreate(
      publicToken: $publicToken
      groupId: $groupId
      status: $status
    ) {
      id
    }
  }
`;
export type SpendUserBankAccessTokenCreateMutationFn = Apollo.MutationFunction<
  SpendUserBankAccessTokenCreateMutation,
  SpendUserBankAccessTokenCreateMutationVariables
>;

/**
 * __useSpendUserBankAccessTokenCreateMutation__
 *
 * To run a mutation, you first call `useSpendUserBankAccessTokenCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSpendUserBankAccessTokenCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [spendUserBankAccessTokenCreateMutation, { data, loading, error }] = useSpendUserBankAccessTokenCreateMutation({
 *   variables: {
 *      publicToken: // value for 'publicToken'
 *      groupId: // value for 'groupId'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useSpendUserBankAccessTokenCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SpendUserBankAccessTokenCreateMutation,
    SpendUserBankAccessTokenCreateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SpendUserBankAccessTokenCreateMutation,
    SpendUserBankAccessTokenCreateMutationVariables
  >(SpendUserBankAccessTokenCreateDocument, options);
}
export type SpendUserBankAccessTokenCreateMutationHookResult = ReturnType<
  typeof useSpendUserBankAccessTokenCreateMutation
>;
export type SpendUserBankAccessTokenCreateMutationResult =
  Apollo.MutationResult<SpendUserBankAccessTokenCreateMutation>;
export type SpendUserBankAccessTokenCreateMutationOptions =
  Apollo.BaseMutationOptions<
    SpendUserBankAccessTokenCreateMutation,
    SpendUserBankAccessTokenCreateMutationVariables
  >;
export const SpendGroupBankAccessTokenCreateDocument = gql`
  mutation SpendGroupBankAccessTokenCreate(
    $publicToken: String!
    $spendGroupBankAccessTokenCreateId: String
    $status: String
  ) {
    spendGroupBankAccessTokenCreate(
      publicToken: $publicToken
      id: $spendGroupBankAccessTokenCreateId
      status: $status
    ) {
      id
    }
  }
`;
export type SpendGroupBankAccessTokenCreateMutationFn = Apollo.MutationFunction<
  SpendGroupBankAccessTokenCreateMutation,
  SpendGroupBankAccessTokenCreateMutationVariables
>;

/**
 * __useSpendGroupBankAccessTokenCreateMutation__
 *
 * To run a mutation, you first call `useSpendGroupBankAccessTokenCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSpendGroupBankAccessTokenCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [spendGroupBankAccessTokenCreateMutation, { data, loading, error }] = useSpendGroupBankAccessTokenCreateMutation({
 *   variables: {
 *      publicToken: // value for 'publicToken'
 *      spendGroupBankAccessTokenCreateId: // value for 'spendGroupBankAccessTokenCreateId'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useSpendGroupBankAccessTokenCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SpendGroupBankAccessTokenCreateMutation,
    SpendGroupBankAccessTokenCreateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SpendGroupBankAccessTokenCreateMutation,
    SpendGroupBankAccessTokenCreateMutationVariables
  >(SpendGroupBankAccessTokenCreateDocument, options);
}
export type SpendGroupBankAccessTokenCreateMutationHookResult = ReturnType<
  typeof useSpendGroupBankAccessTokenCreateMutation
>;
export type SpendGroupBankAccessTokenCreateMutationResult =
  Apollo.MutationResult<SpendGroupBankAccessTokenCreateMutation>;
export type SpendGroupBankAccessTokenCreateMutationOptions =
  Apollo.BaseMutationOptions<
    SpendGroupBankAccessTokenCreateMutation,
    SpendGroupBankAccessTokenCreateMutationVariables
  >;
export const SpendOrganizationBankAccessTokenCreateDocument = gql`
  mutation SpendOrganizationBankAccessTokenCreate(
    $publicToken: String!
    $status: String
  ) {
    spendOrganizationBankAccessTokenCreate(
      publicToken: $publicToken
      status: $status
    ) {
      id
    }
  }
`;
export type SpendOrganizationBankAccessTokenCreateMutationFn =
  Apollo.MutationFunction<
    SpendOrganizationBankAccessTokenCreateMutation,
    SpendOrganizationBankAccessTokenCreateMutationVariables
  >;

/**
 * __useSpendOrganizationBankAccessTokenCreateMutation__
 *
 * To run a mutation, you first call `useSpendOrganizationBankAccessTokenCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSpendOrganizationBankAccessTokenCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [spendOrganizationBankAccessTokenCreateMutation, { data, loading, error }] = useSpendOrganizationBankAccessTokenCreateMutation({
 *   variables: {
 *      publicToken: // value for 'publicToken'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useSpendOrganizationBankAccessTokenCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SpendOrganizationBankAccessTokenCreateMutation,
    SpendOrganizationBankAccessTokenCreateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SpendOrganizationBankAccessTokenCreateMutation,
    SpendOrganizationBankAccessTokenCreateMutationVariables
  >(SpendOrganizationBankAccessTokenCreateDocument, options);
}
export type SpendOrganizationBankAccessTokenCreateMutationHookResult =
  ReturnType<typeof useSpendOrganizationBankAccessTokenCreateMutation>;
export type SpendOrganizationBankAccessTokenCreateMutationResult =
  Apollo.MutationResult<SpendOrganizationBankAccessTokenCreateMutation>;
export type SpendOrganizationBankAccessTokenCreateMutationOptions =
  Apollo.BaseMutationOptions<
    SpendOrganizationBankAccessTokenCreateMutation,
    SpendOrganizationBankAccessTokenCreateMutationVariables
  >;
export const SpendUserBankAccessTokenDeleteDocument = gql`
  mutation SpendUserBankAccessTokenDelete {
    spendUserBankAccessTokenDelete {
      success
    }
  }
`;
export type SpendUserBankAccessTokenDeleteMutationFn = Apollo.MutationFunction<
  SpendUserBankAccessTokenDeleteMutation,
  SpendUserBankAccessTokenDeleteMutationVariables
>;

/**
 * __useSpendUserBankAccessTokenDeleteMutation__
 *
 * To run a mutation, you first call `useSpendUserBankAccessTokenDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSpendUserBankAccessTokenDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [spendUserBankAccessTokenDeleteMutation, { data, loading, error }] = useSpendUserBankAccessTokenDeleteMutation({
 *   variables: {
 *   },
 * });
 */
export function useSpendUserBankAccessTokenDeleteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SpendUserBankAccessTokenDeleteMutation,
    SpendUserBankAccessTokenDeleteMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SpendUserBankAccessTokenDeleteMutation,
    SpendUserBankAccessTokenDeleteMutationVariables
  >(SpendUserBankAccessTokenDeleteDocument, options);
}
export type SpendUserBankAccessTokenDeleteMutationHookResult = ReturnType<
  typeof useSpendUserBankAccessTokenDeleteMutation
>;
export type SpendUserBankAccessTokenDeleteMutationResult =
  Apollo.MutationResult<SpendUserBankAccessTokenDeleteMutation>;
export type SpendUserBankAccessTokenDeleteMutationOptions =
  Apollo.BaseMutationOptions<
    SpendUserBankAccessTokenDeleteMutation,
    SpendUserBankAccessTokenDeleteMutationVariables
  >;
export const SpendGroupBankAccessTokenDeleteDocument = gql`
  mutation SpendGroupBankAccessTokenDelete(
    $spendGroupBankAccessTokenDeleteId: String
  ) {
    spendGroupBankAccessTokenDelete(id: $spendGroupBankAccessTokenDeleteId) {
      success
    }
  }
`;
export type SpendGroupBankAccessTokenDeleteMutationFn = Apollo.MutationFunction<
  SpendGroupBankAccessTokenDeleteMutation,
  SpendGroupBankAccessTokenDeleteMutationVariables
>;

/**
 * __useSpendGroupBankAccessTokenDeleteMutation__
 *
 * To run a mutation, you first call `useSpendGroupBankAccessTokenDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSpendGroupBankAccessTokenDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [spendGroupBankAccessTokenDeleteMutation, { data, loading, error }] = useSpendGroupBankAccessTokenDeleteMutation({
 *   variables: {
 *      spendGroupBankAccessTokenDeleteId: // value for 'spendGroupBankAccessTokenDeleteId'
 *   },
 * });
 */
export function useSpendGroupBankAccessTokenDeleteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SpendGroupBankAccessTokenDeleteMutation,
    SpendGroupBankAccessTokenDeleteMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SpendGroupBankAccessTokenDeleteMutation,
    SpendGroupBankAccessTokenDeleteMutationVariables
  >(SpendGroupBankAccessTokenDeleteDocument, options);
}
export type SpendGroupBankAccessTokenDeleteMutationHookResult = ReturnType<
  typeof useSpendGroupBankAccessTokenDeleteMutation
>;
export type SpendGroupBankAccessTokenDeleteMutationResult =
  Apollo.MutationResult<SpendGroupBankAccessTokenDeleteMutation>;
export type SpendGroupBankAccessTokenDeleteMutationOptions =
  Apollo.BaseMutationOptions<
    SpendGroupBankAccessTokenDeleteMutation,
    SpendGroupBankAccessTokenDeleteMutationVariables
  >;
export const SpendOrganizationBankAccessTokenDeleteDocument = gql`
  mutation SpendOrganizationBankAccessTokenDelete {
    spendOrganizationBankAccessTokenDelete {
      success
    }
  }
`;
export type SpendOrganizationBankAccessTokenDeleteMutationFn =
  Apollo.MutationFunction<
    SpendOrganizationBankAccessTokenDeleteMutation,
    SpendOrganizationBankAccessTokenDeleteMutationVariables
  >;

/**
 * __useSpendOrganizationBankAccessTokenDeleteMutation__
 *
 * To run a mutation, you first call `useSpendOrganizationBankAccessTokenDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSpendOrganizationBankAccessTokenDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [spendOrganizationBankAccessTokenDeleteMutation, { data, loading, error }] = useSpendOrganizationBankAccessTokenDeleteMutation({
 *   variables: {
 *   },
 * });
 */
export function useSpendOrganizationBankAccessTokenDeleteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SpendOrganizationBankAccessTokenDeleteMutation,
    SpendOrganizationBankAccessTokenDeleteMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SpendOrganizationBankAccessTokenDeleteMutation,
    SpendOrganizationBankAccessTokenDeleteMutationVariables
  >(SpendOrganizationBankAccessTokenDeleteDocument, options);
}
export type SpendOrganizationBankAccessTokenDeleteMutationHookResult =
  ReturnType<typeof useSpendOrganizationBankAccessTokenDeleteMutation>;
export type SpendOrganizationBankAccessTokenDeleteMutationResult =
  Apollo.MutationResult<SpendOrganizationBankAccessTokenDeleteMutation>;
export type SpendOrganizationBankAccessTokenDeleteMutationOptions =
  Apollo.BaseMutationOptions<
    SpendOrganizationBankAccessTokenDeleteMutation,
    SpendOrganizationBankAccessTokenDeleteMutationVariables
  >;
export const SpendRoleChangeDocument = gql`
  mutation SpendRoleChange($roleId: String!, $sessionId: String!) {
    spendRoleChange(roleId: $roleId, sessionId: $sessionId) {
      id
    }
  }
`;
export type SpendRoleChangeMutationFn = Apollo.MutationFunction<
  SpendRoleChangeMutation,
  SpendRoleChangeMutationVariables
>;

/**
 * __useSpendRoleChangeMutation__
 *
 * To run a mutation, you first call `useSpendRoleChangeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSpendRoleChangeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [spendRoleChangeMutation, { data, loading, error }] = useSpendRoleChangeMutation({
 *   variables: {
 *      roleId: // value for 'roleId'
 *      sessionId: // value for 'sessionId'
 *   },
 * });
 */
export function useSpendRoleChangeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SpendRoleChangeMutation,
    SpendRoleChangeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SpendRoleChangeMutation,
    SpendRoleChangeMutationVariables
  >(SpendRoleChangeDocument, options);
}
export type SpendRoleChangeMutationHookResult = ReturnType<
  typeof useSpendRoleChangeMutation
>;
export type SpendRoleChangeMutationResult =
  Apollo.MutationResult<SpendRoleChangeMutation>;
export type SpendRoleChangeMutationOptions = Apollo.BaseMutationOptions<
  SpendRoleChangeMutation,
  SpendRoleChangeMutationVariables
>;
export const SpendUserRoleUpdateDocument = gql`
  mutation SpendUserRoleUpdate(
    $groupId: String
    $roleName: SpendRoleNameEnum!
    $userId: String!
  ) {
    spendUserRoleUpdate(
      groupId: $groupId
      roleName: $roleName
      userId: $userId
    ) {
      id
    }
  }
`;
export type SpendUserRoleUpdateMutationFn = Apollo.MutationFunction<
  SpendUserRoleUpdateMutation,
  SpendUserRoleUpdateMutationVariables
>;

/**
 * __useSpendUserRoleUpdateMutation__
 *
 * To run a mutation, you first call `useSpendUserRoleUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSpendUserRoleUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [spendUserRoleUpdateMutation, { data, loading, error }] = useSpendUserRoleUpdateMutation({
 *   variables: {
 *      groupId: // value for 'groupId'
 *      roleName: // value for 'roleName'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useSpendUserRoleUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SpendUserRoleUpdateMutation,
    SpendUserRoleUpdateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SpendUserRoleUpdateMutation,
    SpendUserRoleUpdateMutationVariables
  >(SpendUserRoleUpdateDocument, options);
}
export type SpendUserRoleUpdateMutationHookResult = ReturnType<
  typeof useSpendUserRoleUpdateMutation
>;
export type SpendUserRoleUpdateMutationResult =
  Apollo.MutationResult<SpendUserRoleUpdateMutation>;
export type SpendUserRoleUpdateMutationOptions = Apollo.BaseMutationOptions<
  SpendUserRoleUpdateMutation,
  SpendUserRoleUpdateMutationVariables
>;
export const SpendUserRoleSetDocument = gql`
  mutation SpendUserRoleSet($roleId: String!) {
    spendUserRoleSet(roleId: $roleId) {
      id
    }
  }
`;
export type SpendUserRoleSetMutationFn = Apollo.MutationFunction<
  SpendUserRoleSetMutation,
  SpendUserRoleSetMutationVariables
>;

/**
 * __useSpendUserRoleSetMutation__
 *
 * To run a mutation, you first call `useSpendUserRoleSetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSpendUserRoleSetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [spendUserRoleSetMutation, { data, loading, error }] = useSpendUserRoleSetMutation({
 *   variables: {
 *      roleId: // value for 'roleId'
 *   },
 * });
 */
export function useSpendUserRoleSetMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SpendUserRoleSetMutation,
    SpendUserRoleSetMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SpendUserRoleSetMutation,
    SpendUserRoleSetMutationVariables
  >(SpendUserRoleSetDocument, options);
}
export type SpendUserRoleSetMutationHookResult = ReturnType<
  typeof useSpendUserRoleSetMutation
>;
export type SpendUserRoleSetMutationResult =
  Apollo.MutationResult<SpendUserRoleSetMutation>;
export type SpendUserRoleSetMutationOptions = Apollo.BaseMutationOptions<
  SpendUserRoleSetMutation,
  SpendUserRoleSetMutationVariables
>;
export const SpendUserRoleUpdateIsApproverDocument = gql`
  mutation SpendUserRoleUpdateIsApprover($input: SpendIsApproverUpdateInput) {
    spendUserRoleUpdateIsApprover(input: $input) {
      id
    }
  }
`;
export type SpendUserRoleUpdateIsApproverMutationFn = Apollo.MutationFunction<
  SpendUserRoleUpdateIsApproverMutation,
  SpendUserRoleUpdateIsApproverMutationVariables
>;

/**
 * __useSpendUserRoleUpdateIsApproverMutation__
 *
 * To run a mutation, you first call `useSpendUserRoleUpdateIsApproverMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSpendUserRoleUpdateIsApproverMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [spendUserRoleUpdateIsApproverMutation, { data, loading, error }] = useSpendUserRoleUpdateIsApproverMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSpendUserRoleUpdateIsApproverMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SpendUserRoleUpdateIsApproverMutation,
    SpendUserRoleUpdateIsApproverMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SpendUserRoleUpdateIsApproverMutation,
    SpendUserRoleUpdateIsApproverMutationVariables
  >(SpendUserRoleUpdateIsApproverDocument, options);
}
export type SpendUserRoleUpdateIsApproverMutationHookResult = ReturnType<
  typeof useSpendUserRoleUpdateIsApproverMutation
>;
export type SpendUserRoleUpdateIsApproverMutationResult =
  Apollo.MutationResult<SpendUserRoleUpdateIsApproverMutation>;
export type SpendUserRoleUpdateIsApproverMutationOptions =
  Apollo.BaseMutationOptions<
    SpendUserRoleUpdateIsApproverMutation,
    SpendUserRoleUpdateIsApproverMutationVariables
  >;
export const SpendSessionCreateDocument = gql`
  mutation SpendSessionCreate($inviteId: String) {
    spendSessionCreate(inviteId: $inviteId) {
      url
      status
      expiresAt
      message
      id
      userId
      inviteId
      isDismissed
      role {
        groupId
        id
        name
        organizationId
        permissions
        isArchived
        seasonId
      }
      pendingInvites {
        id
        type
        groupId
        groupName
        rosterName
        seasonName
        organizationId
        organizationName
        expiresAt
        status
        isDismissed
      }
    }
  }
`;
export type SpendSessionCreateMutationFn = Apollo.MutationFunction<
  SpendSessionCreateMutation,
  SpendSessionCreateMutationVariables
>;

/**
 * __useSpendSessionCreateMutation__
 *
 * To run a mutation, you first call `useSpendSessionCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSpendSessionCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [spendSessionCreateMutation, { data, loading, error }] = useSpendSessionCreateMutation({
 *   variables: {
 *      inviteId: // value for 'inviteId'
 *   },
 * });
 */
export function useSpendSessionCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SpendSessionCreateMutation,
    SpendSessionCreateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SpendSessionCreateMutation,
    SpendSessionCreateMutationVariables
  >(SpendSessionCreateDocument, options);
}
export type SpendSessionCreateMutationHookResult = ReturnType<
  typeof useSpendSessionCreateMutation
>;
export type SpendSessionCreateMutationResult =
  Apollo.MutationResult<SpendSessionCreateMutation>;
export type SpendSessionCreateMutationOptions = Apollo.BaseMutationOptions<
  SpendSessionCreateMutation,
  SpendSessionCreateMutationVariables
>;
export const SpendSessionDeleteDocument = gql`
  mutation SpendSessionDelete {
    spendSessionDelete {
      id
    }
  }
`;
export type SpendSessionDeleteMutationFn = Apollo.MutationFunction<
  SpendSessionDeleteMutation,
  SpendSessionDeleteMutationVariables
>;

/**
 * __useSpendSessionDeleteMutation__
 *
 * To run a mutation, you first call `useSpendSessionDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSpendSessionDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [spendSessionDeleteMutation, { data, loading, error }] = useSpendSessionDeleteMutation({
 *   variables: {
 *   },
 * });
 */
export function useSpendSessionDeleteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SpendSessionDeleteMutation,
    SpendSessionDeleteMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SpendSessionDeleteMutation,
    SpendSessionDeleteMutationVariables
  >(SpendSessionDeleteDocument, options);
}
export type SpendSessionDeleteMutationHookResult = ReturnType<
  typeof useSpendSessionDeleteMutation
>;
export type SpendSessionDeleteMutationResult =
  Apollo.MutationResult<SpendSessionDeleteMutation>;
export type SpendSessionDeleteMutationOptions = Apollo.BaseMutationOptions<
  SpendSessionDeleteMutation,
  SpendSessionDeleteMutationVariables
>;
export const SpendSessionRefreshDocument = gql`
  mutation SpendSessionRefresh($sessionId: String!) {
    spendSessionRefresh(sessionId: $sessionId) {
      id
    }
  }
`;
export type SpendSessionRefreshMutationFn = Apollo.MutationFunction<
  SpendSessionRefreshMutation,
  SpendSessionRefreshMutationVariables
>;

/**
 * __useSpendSessionRefreshMutation__
 *
 * To run a mutation, you first call `useSpendSessionRefreshMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSpendSessionRefreshMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [spendSessionRefreshMutation, { data, loading, error }] = useSpendSessionRefreshMutation({
 *   variables: {
 *      sessionId: // value for 'sessionId'
 *   },
 * });
 */
export function useSpendSessionRefreshMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SpendSessionRefreshMutation,
    SpendSessionRefreshMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SpendSessionRefreshMutation,
    SpendSessionRefreshMutationVariables
  >(SpendSessionRefreshDocument, options);
}
export type SpendSessionRefreshMutationHookResult = ReturnType<
  typeof useSpendSessionRefreshMutation
>;
export type SpendSessionRefreshMutationResult =
  Apollo.MutationResult<SpendSessionRefreshMutation>;
export type SpendSessionRefreshMutationOptions = Apollo.BaseMutationOptions<
  SpendSessionRefreshMutation,
  SpendSessionRefreshMutationVariables
>;
export const MutationDocument = gql`
  mutation Mutation {
    logout
  }
`;
export type MutationMutationFn = Apollo.MutationFunction<
  MutationMutation,
  MutationMutationVariables
>;

/**
 * __useMutationMutation__
 *
 * To run a mutation, you first call `useMutationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMutationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mutationMutation, { data, loading, error }] = useMutationMutation({
 *   variables: {
 *   },
 * });
 */
export function useMutationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MutationMutation,
    MutationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<MutationMutation, MutationMutationVariables>(
    MutationDocument,
    options
  );
}
export type MutationMutationHookResult = ReturnType<typeof useMutationMutation>;
export type MutationMutationResult = Apollo.MutationResult<MutationMutation>;
export type MutationMutationOptions = Apollo.BaseMutationOptions<
  MutationMutation,
  MutationMutationVariables
>;
export const SpendUnitApplicationDocument = gql`
  mutation SpendUnitApplication {
    spendUnitApplication {
      url
    }
  }
`;
export type SpendUnitApplicationMutationFn = Apollo.MutationFunction<
  SpendUnitApplicationMutation,
  SpendUnitApplicationMutationVariables
>;

/**
 * __useSpendUnitApplicationMutation__
 *
 * To run a mutation, you first call `useSpendUnitApplicationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSpendUnitApplicationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [spendUnitApplicationMutation, { data, loading, error }] = useSpendUnitApplicationMutation({
 *   variables: {
 *   },
 * });
 */
export function useSpendUnitApplicationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SpendUnitApplicationMutation,
    SpendUnitApplicationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SpendUnitApplicationMutation,
    SpendUnitApplicationMutationVariables
  >(SpendUnitApplicationDocument, options);
}
export type SpendUnitApplicationMutationHookResult = ReturnType<
  typeof useSpendUnitApplicationMutation
>;
export type SpendUnitApplicationMutationResult =
  Apollo.MutationResult<SpendUnitApplicationMutation>;
export type SpendUnitApplicationMutationOptions = Apollo.BaseMutationOptions<
  SpendUnitApplicationMutation,
  SpendUnitApplicationMutationVariables
>;
export const SpendUserSignupUpdateDocument = gql`
  mutation SpendUserSignupUpdate($input: SpendUserSignupInput) {
    spendUserSignupUpdate(input: $input) {
      id
    }
  }
`;
export type SpendUserSignupUpdateMutationFn = Apollo.MutationFunction<
  SpendUserSignupUpdateMutation,
  SpendUserSignupUpdateMutationVariables
>;

/**
 * __useSpendUserSignupUpdateMutation__
 *
 * To run a mutation, you first call `useSpendUserSignupUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSpendUserSignupUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [spendUserSignupUpdateMutation, { data, loading, error }] = useSpendUserSignupUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSpendUserSignupUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SpendUserSignupUpdateMutation,
    SpendUserSignupUpdateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SpendUserSignupUpdateMutation,
    SpendUserSignupUpdateMutationVariables
  >(SpendUserSignupUpdateDocument, options);
}
export type SpendUserSignupUpdateMutationHookResult = ReturnType<
  typeof useSpendUserSignupUpdateMutation
>;
export type SpendUserSignupUpdateMutationResult =
  Apollo.MutationResult<SpendUserSignupUpdateMutation>;
export type SpendUserSignupUpdateMutationOptions = Apollo.BaseMutationOptions<
  SpendUserSignupUpdateMutation,
  SpendUserSignupUpdateMutationVariables
>;
export const SpendTransactionsCreateDocument = gql`
  mutation spendTransactionsCreate($input: SpendTransactionInput!) {
    spendTransactionsCreate(input: $input) {
      transactionIdList
    }
  }
`;
export type SpendTransactionsCreateMutationFn = Apollo.MutationFunction<
  SpendTransactionsCreateMutation,
  SpendTransactionsCreateMutationVariables
>;

/**
 * __useSpendTransactionsCreateMutation__
 *
 * To run a mutation, you first call `useSpendTransactionsCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSpendTransactionsCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [spendTransactionsCreateMutation, { data, loading, error }] = useSpendTransactionsCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSpendTransactionsCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SpendTransactionsCreateMutation,
    SpendTransactionsCreateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SpendTransactionsCreateMutation,
    SpendTransactionsCreateMutationVariables
  >(SpendTransactionsCreateDocument, options);
}
export type SpendTransactionsCreateMutationHookResult = ReturnType<
  typeof useSpendTransactionsCreateMutation
>;
export type SpendTransactionsCreateMutationResult =
  Apollo.MutationResult<SpendTransactionsCreateMutation>;
export type SpendTransactionsCreateMutationOptions = Apollo.BaseMutationOptions<
  SpendTransactionsCreateMutation,
  SpendTransactionsCreateMutationVariables
>;
export const SpendTransactionReconcileDocument = gql`
  mutation SpendTransactionReconcile($input: SpendTransactionReconcileInput!) {
    spendTransactionReconcile(input: $input) {
      id
    }
  }
`;
export type SpendTransactionReconcileMutationFn = Apollo.MutationFunction<
  SpendTransactionReconcileMutation,
  SpendTransactionReconcileMutationVariables
>;

/**
 * __useSpendTransactionReconcileMutation__
 *
 * To run a mutation, you first call `useSpendTransactionReconcileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSpendTransactionReconcileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [spendTransactionReconcileMutation, { data, loading, error }] = useSpendTransactionReconcileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSpendTransactionReconcileMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SpendTransactionReconcileMutation,
    SpendTransactionReconcileMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SpendTransactionReconcileMutation,
    SpendTransactionReconcileMutationVariables
  >(SpendTransactionReconcileDocument, options);
}
export type SpendTransactionReconcileMutationHookResult = ReturnType<
  typeof useSpendTransactionReconcileMutation
>;
export type SpendTransactionReconcileMutationResult =
  Apollo.MutationResult<SpendTransactionReconcileMutation>;
export type SpendTransactionReconcileMutationOptions =
  Apollo.BaseMutationOptions<
    SpendTransactionReconcileMutation,
    SpendTransactionReconcileMutationVariables
  >;
export const SpendTransactionReconcileV2Document = gql`
  mutation SpendTransactionReconcileV2(
    $input: SpendTransactionReconcileV2Input!
  ) {
    spendTransactionReconcileV2(input: $input) {
      id
    }
  }
`;
export type SpendTransactionReconcileV2MutationFn = Apollo.MutationFunction<
  SpendTransactionReconcileV2Mutation,
  SpendTransactionReconcileV2MutationVariables
>;

/**
 * __useSpendTransactionReconcileV2Mutation__
 *
 * To run a mutation, you first call `useSpendTransactionReconcileV2Mutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSpendTransactionReconcileV2Mutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [spendTransactionReconcileV2Mutation, { data, loading, error }] = useSpendTransactionReconcileV2Mutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSpendTransactionReconcileV2Mutation(
  baseOptions?: Apollo.MutationHookOptions<
    SpendTransactionReconcileV2Mutation,
    SpendTransactionReconcileV2MutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SpendTransactionReconcileV2Mutation,
    SpendTransactionReconcileV2MutationVariables
  >(SpendTransactionReconcileV2Document, options);
}
export type SpendTransactionReconcileV2MutationHookResult = ReturnType<
  typeof useSpendTransactionReconcileV2Mutation
>;
export type SpendTransactionReconcileV2MutationResult =
  Apollo.MutationResult<SpendTransactionReconcileV2Mutation>;
export type SpendTransactionReconcileV2MutationOptions =
  Apollo.BaseMutationOptions<
    SpendTransactionReconcileV2Mutation,
    SpendTransactionReconcileV2MutationVariables
  >;
export const SpendTransactionNoteCreateDocument = gql`
  mutation SpendTransactionNoteCreate($input: SpendTranscationNoteInput!) {
    spendTransactionNoteCreate(input: $input) {
      id
    }
  }
`;
export type SpendTransactionNoteCreateMutationFn = Apollo.MutationFunction<
  SpendTransactionNoteCreateMutation,
  SpendTransactionNoteCreateMutationVariables
>;

/**
 * __useSpendTransactionNoteCreateMutation__
 *
 * To run a mutation, you first call `useSpendTransactionNoteCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSpendTransactionNoteCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [spendTransactionNoteCreateMutation, { data, loading, error }] = useSpendTransactionNoteCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSpendTransactionNoteCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SpendTransactionNoteCreateMutation,
    SpendTransactionNoteCreateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SpendTransactionNoteCreateMutation,
    SpendTransactionNoteCreateMutationVariables
  >(SpendTransactionNoteCreateDocument, options);
}
export type SpendTransactionNoteCreateMutationHookResult = ReturnType<
  typeof useSpendTransactionNoteCreateMutation
>;
export type SpendTransactionNoteCreateMutationResult =
  Apollo.MutationResult<SpendTransactionNoteCreateMutation>;
export type SpendTransactionNoteCreateMutationOptions =
  Apollo.BaseMutationOptions<
    SpendTransactionNoteCreateMutation,
    SpendTransactionNoteCreateMutationVariables
  >;
export const SpendTransactionNoteUpdateDocument = gql`
  mutation SpendTransactionNoteUpdate(
    $spendTransactionNoteUpdateId: String!
    $input: SpendTranscationNoteInput!
  ) {
    spendTransactionNoteUpdate(
      id: $spendTransactionNoteUpdateId
      input: $input
    ) {
      id
    }
  }
`;
export type SpendTransactionNoteUpdateMutationFn = Apollo.MutationFunction<
  SpendTransactionNoteUpdateMutation,
  SpendTransactionNoteUpdateMutationVariables
>;

/**
 * __useSpendTransactionNoteUpdateMutation__
 *
 * To run a mutation, you first call `useSpendTransactionNoteUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSpendTransactionNoteUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [spendTransactionNoteUpdateMutation, { data, loading, error }] = useSpendTransactionNoteUpdateMutation({
 *   variables: {
 *      spendTransactionNoteUpdateId: // value for 'spendTransactionNoteUpdateId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSpendTransactionNoteUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SpendTransactionNoteUpdateMutation,
    SpendTransactionNoteUpdateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SpendTransactionNoteUpdateMutation,
    SpendTransactionNoteUpdateMutationVariables
  >(SpendTransactionNoteUpdateDocument, options);
}
export type SpendTransactionNoteUpdateMutationHookResult = ReturnType<
  typeof useSpendTransactionNoteUpdateMutation
>;
export type SpendTransactionNoteUpdateMutationResult =
  Apollo.MutationResult<SpendTransactionNoteUpdateMutation>;
export type SpendTransactionNoteUpdateMutationOptions =
  Apollo.BaseMutationOptions<
    SpendTransactionNoteUpdateMutation,
    SpendTransactionNoteUpdateMutationVariables
  >;
export const SpendTransactionAttachmentCreateDocument = gql`
  mutation SpendTransactionAttachmentCreate(
    $input: SpendTransactionAttachmentInput!
  ) {
    spendTransactionAttachmentCreate(input: $input) {
      id
    }
  }
`;
export type SpendTransactionAttachmentCreateMutationFn =
  Apollo.MutationFunction<
    SpendTransactionAttachmentCreateMutation,
    SpendTransactionAttachmentCreateMutationVariables
  >;

/**
 * __useSpendTransactionAttachmentCreateMutation__
 *
 * To run a mutation, you first call `useSpendTransactionAttachmentCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSpendTransactionAttachmentCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [spendTransactionAttachmentCreateMutation, { data, loading, error }] = useSpendTransactionAttachmentCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSpendTransactionAttachmentCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SpendTransactionAttachmentCreateMutation,
    SpendTransactionAttachmentCreateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SpendTransactionAttachmentCreateMutation,
    SpendTransactionAttachmentCreateMutationVariables
  >(SpendTransactionAttachmentCreateDocument, options);
}
export type SpendTransactionAttachmentCreateMutationHookResult = ReturnType<
  typeof useSpendTransactionAttachmentCreateMutation
>;
export type SpendTransactionAttachmentCreateMutationResult =
  Apollo.MutationResult<SpendTransactionAttachmentCreateMutation>;
export type SpendTransactionAttachmentCreateMutationOptions =
  Apollo.BaseMutationOptions<
    SpendTransactionAttachmentCreateMutation,
    SpendTransactionAttachmentCreateMutationVariables
  >;
export const SpendTransactionAttachmentDeleteDocument = gql`
  mutation SpendTransactionAttachmentDelete($id: String!) {
    spendTransactionAttachmentDelete(id: $id) {
      id
    }
  }
`;
export type SpendTransactionAttachmentDeleteMutationFn =
  Apollo.MutationFunction<
    SpendTransactionAttachmentDeleteMutation,
    SpendTransactionAttachmentDeleteMutationVariables
  >;

/**
 * __useSpendTransactionAttachmentDeleteMutation__
 *
 * To run a mutation, you first call `useSpendTransactionAttachmentDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSpendTransactionAttachmentDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [spendTransactionAttachmentDeleteMutation, { data, loading, error }] = useSpendTransactionAttachmentDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSpendTransactionAttachmentDeleteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SpendTransactionAttachmentDeleteMutation,
    SpendTransactionAttachmentDeleteMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SpendTransactionAttachmentDeleteMutation,
    SpendTransactionAttachmentDeleteMutationVariables
  >(SpendTransactionAttachmentDeleteDocument, options);
}
export type SpendTransactionAttachmentDeleteMutationHookResult = ReturnType<
  typeof useSpendTransactionAttachmentDeleteMutation
>;
export type SpendTransactionAttachmentDeleteMutationResult =
  Apollo.MutationResult<SpendTransactionAttachmentDeleteMutation>;
export type SpendTransactionAttachmentDeleteMutationOptions =
  Apollo.BaseMutationOptions<
    SpendTransactionAttachmentDeleteMutation,
    SpendTransactionAttachmentDeleteMutationVariables
  >;
export const UserImpersonateLogoutDocument = gql`
  mutation userImpersonateLogout {
    userImpersonateLogout {
      accessToken
      refreshToken
    }
  }
`;
export type UserImpersonateLogoutMutationFn = Apollo.MutationFunction<
  UserImpersonateLogoutMutation,
  UserImpersonateLogoutMutationVariables
>;

/**
 * __useUserImpersonateLogoutMutation__
 *
 * To run a mutation, you first call `useUserImpersonateLogoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserImpersonateLogoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userImpersonateLogoutMutation, { data, loading, error }] = useUserImpersonateLogoutMutation({
 *   variables: {
 *   },
 * });
 */
export function useUserImpersonateLogoutMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UserImpersonateLogoutMutation,
    UserImpersonateLogoutMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UserImpersonateLogoutMutation,
    UserImpersonateLogoutMutationVariables
  >(UserImpersonateLogoutDocument, options);
}
export type UserImpersonateLogoutMutationHookResult = ReturnType<
  typeof useUserImpersonateLogoutMutation
>;
export type UserImpersonateLogoutMutationResult =
  Apollo.MutationResult<UserImpersonateLogoutMutation>;
export type UserImpersonateLogoutMutationOptions = Apollo.BaseMutationOptions<
  UserImpersonateLogoutMutation,
  UserImpersonateLogoutMutationVariables
>;
export const SpendUserNotificationSettingUpdateDocument = gql`
  mutation spendUserNotificationSettingUpdate(
    $input: SpendUserNotificationSettingInput
  ) {
    spendUserNotificationSettingUpdate(input: $input) {
      id
    }
  }
`;
export type SpendUserNotificationSettingUpdateMutationFn =
  Apollo.MutationFunction<
    SpendUserNotificationSettingUpdateMutation,
    SpendUserNotificationSettingUpdateMutationVariables
  >;

/**
 * __useSpendUserNotificationSettingUpdateMutation__
 *
 * To run a mutation, you first call `useSpendUserNotificationSettingUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSpendUserNotificationSettingUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [spendUserNotificationSettingUpdateMutation, { data, loading, error }] = useSpendUserNotificationSettingUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSpendUserNotificationSettingUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SpendUserNotificationSettingUpdateMutation,
    SpendUserNotificationSettingUpdateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SpendUserNotificationSettingUpdateMutation,
    SpendUserNotificationSettingUpdateMutationVariables
  >(SpendUserNotificationSettingUpdateDocument, options);
}
export type SpendUserNotificationSettingUpdateMutationHookResult = ReturnType<
  typeof useSpendUserNotificationSettingUpdateMutation
>;
export type SpendUserNotificationSettingUpdateMutationResult =
  Apollo.MutationResult<SpendUserNotificationSettingUpdateMutation>;
export type SpendUserNotificationSettingUpdateMutationOptions =
  Apollo.BaseMutationOptions<
    SpendUserNotificationSettingUpdateMutation,
    SpendUserNotificationSettingUpdateMutationVariables
  >;
export const SpendPaymentsCardDetachDocument = gql`
  mutation SpendPaymentsCardDetach($input: SpendPaymentMethodDetach) {
    spendUserPaymentsCardDetach(input: $input) {
      paymentMethodId
    }
  }
`;
export type SpendPaymentsCardDetachMutationFn = Apollo.MutationFunction<
  SpendPaymentsCardDetachMutation,
  SpendPaymentsCardDetachMutationVariables
>;

/**
 * __useSpendPaymentsCardDetachMutation__
 *
 * To run a mutation, you first call `useSpendPaymentsCardDetachMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSpendPaymentsCardDetachMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [spendPaymentsCardDetachMutation, { data, loading, error }] = useSpendPaymentsCardDetachMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSpendPaymentsCardDetachMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SpendPaymentsCardDetachMutation,
    SpendPaymentsCardDetachMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SpendPaymentsCardDetachMutation,
    SpendPaymentsCardDetachMutationVariables
  >(SpendPaymentsCardDetachDocument, options);
}
export type SpendPaymentsCardDetachMutationHookResult = ReturnType<
  typeof useSpendPaymentsCardDetachMutation
>;
export type SpendPaymentsCardDetachMutationResult =
  Apollo.MutationResult<SpendPaymentsCardDetachMutation>;
export type SpendPaymentsCardDetachMutationOptions = Apollo.BaseMutationOptions<
  SpendPaymentsCardDetachMutation,
  SpendPaymentsCardDetachMutationVariables
>;
export const SpendBudgetsDocument = gql`
  query SpendBudgets {
    spendBudgets {
      budgets {
        category {
          createdAt
          id
          isDefault
          isHidden
          name
          organizationId
          type
          updatedAt
        }
        createdAt
        description
        id
        invoices {
          amount
          balanceDue
          budgetItemId
          createdAt
          description
          dueDate
          groupRosterId
          id
          isOptional
          lastNotifyDate
          lastNotifyId
          notificationAttempts
          optedIn
          paid
          paidDate
          paymentScheduleInvoiceId
          updatedAt
        }
        isDefault
        season {
          id
          name
        }
        targetAmount
        targetDateAt
        updatedAt
        vaultId
      }
      count
    }
  }
`;

/**
 * __useSpendBudgetsQuery__
 *
 * To run a query within a React component, call `useSpendBudgetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSpendBudgetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSpendBudgetsQuery({
 *   variables: {
 *   },
 * });
 */
export function useSpendBudgetsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SpendBudgetsQuery,
    SpendBudgetsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SpendBudgetsQuery, SpendBudgetsQueryVariables>(
    SpendBudgetsDocument,
    options
  );
}
export function useSpendBudgetsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SpendBudgetsQuery,
    SpendBudgetsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SpendBudgetsQuery, SpendBudgetsQueryVariables>(
    SpendBudgetsDocument,
    options
  );
}
export type SpendBudgetsQueryHookResult = ReturnType<
  typeof useSpendBudgetsQuery
>;
export type SpendBudgetsLazyQueryHookResult = ReturnType<
  typeof useSpendBudgetsLazyQuery
>;
export type SpendBudgetsQueryResult = Apollo.QueryResult<
  SpendBudgetsQuery,
  SpendBudgetsQueryVariables
>;
export const GroupBudgetSummaryDocument = gql`
  query GroupBudgetSummary(
    $groupId: String
    $filterBy: TransactionFilterEnum
    $filterValue: String
  ) {
    spendCategories(groupId: $groupId) {
      count
      categories {
        id
        name
        type
        budgets {
          targetAmount
          invoices {
            id
            amount
          }
        }
      }
    }
    spendTransactions(
      groupId: $groupId
      filterBy: $filterBy
      filterValue: $filterValue
    ) {
      count
      transactions {
        amount
        snapAmount
        metadata {
          source
          destination
        }
        reconciliation {
          id
        }
      }
    }
  }
`;

/**
 * __useGroupBudgetSummaryQuery__
 *
 * To run a query within a React component, call `useGroupBudgetSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGroupBudgetSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGroupBudgetSummaryQuery({
 *   variables: {
 *      groupId: // value for 'groupId'
 *      filterBy: // value for 'filterBy'
 *      filterValue: // value for 'filterValue'
 *   },
 * });
 */
export function useGroupBudgetSummaryQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GroupBudgetSummaryQuery,
    GroupBudgetSummaryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GroupBudgetSummaryQuery,
    GroupBudgetSummaryQueryVariables
  >(GroupBudgetSummaryDocument, options);
}
export function useGroupBudgetSummaryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GroupBudgetSummaryQuery,
    GroupBudgetSummaryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GroupBudgetSummaryQuery,
    GroupBudgetSummaryQueryVariables
  >(GroupBudgetSummaryDocument, options);
}
export type GroupBudgetSummaryQueryHookResult = ReturnType<
  typeof useGroupBudgetSummaryQuery
>;
export type GroupBudgetSummaryLazyQueryHookResult = ReturnType<
  typeof useGroupBudgetSummaryLazyQuery
>;
export type GroupBudgetSummaryQueryResult = Apollo.QueryResult<
  GroupBudgetSummaryQuery,
  GroupBudgetSummaryQueryVariables
>;
export const SpendBudgetsSummaryDocument = gql`
  query SpendBudgetsSummary($groupId: String!, $seasonId: String!) {
    spendBudgetsSummary(groupId: $groupId, seasonId: $seasonId) {
      summaryByCategory {
        id
        name
        type
        budgets {
          id
          targetAmount
          targetDateAt
          description
          invoices {
            id
            amount
          }
          reconciledTransactions {
            amount
            budgetItemId
            id
            transactionId
            reconciledTransaction {
              amount
              id
              paymentId
              updatedAt
              createdAt
              type
            }
          }
          reconciledBudgetTotal
          reconciledInvoicesTotal
          reconciledTotal
        }
      }
      summaryUnreconciled {
        debits {
          count
          total
        }
        credits {
          count
          total
        }
      }
    }
  }
`;

/**
 * __useSpendBudgetsSummaryQuery__
 *
 * To run a query within a React component, call `useSpendBudgetsSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useSpendBudgetsSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSpendBudgetsSummaryQuery({
 *   variables: {
 *      groupId: // value for 'groupId'
 *      seasonId: // value for 'seasonId'
 *   },
 * });
 */
export function useSpendBudgetsSummaryQuery(
  baseOptions: Apollo.QueryHookOptions<
    SpendBudgetsSummaryQuery,
    SpendBudgetsSummaryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SpendBudgetsSummaryQuery,
    SpendBudgetsSummaryQueryVariables
  >(SpendBudgetsSummaryDocument, options);
}
export function useSpendBudgetsSummaryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SpendBudgetsSummaryQuery,
    SpendBudgetsSummaryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SpendBudgetsSummaryQuery,
    SpendBudgetsSummaryQueryVariables
  >(SpendBudgetsSummaryDocument, options);
}
export type SpendBudgetsSummaryQueryHookResult = ReturnType<
  typeof useSpendBudgetsSummaryQuery
>;
export type SpendBudgetsSummaryLazyQueryHookResult = ReturnType<
  typeof useSpendBudgetsSummaryLazyQuery
>;
export type SpendBudgetsSummaryQueryResult = Apollo.QueryResult<
  SpendBudgetsSummaryQuery,
  SpendBudgetsSummaryQueryVariables
>;
export const SpendBudgetsProgramSummaryDocument = gql`
  query SpendBudgetsProgramSummary {
    spendBudgetsProgramSummary {
      summaryByCategory {
        id
        name
        type
        budgets {
          id
          targetAmount
          targetDateAt
          description
          invoices {
            id
            amount
          }
          reconciledBudgetTotal
          reconciledInvoicesTotal
        }
      }
      summaryUnreconciled {
        debits {
          count
          total
        }
        credits {
          count
          total
        }
      }
    }
  }
`;

/**
 * __useSpendBudgetsProgramSummaryQuery__
 *
 * To run a query within a React component, call `useSpendBudgetsProgramSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useSpendBudgetsProgramSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSpendBudgetsProgramSummaryQuery({
 *   variables: {
 *   },
 * });
 */
export function useSpendBudgetsProgramSummaryQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SpendBudgetsProgramSummaryQuery,
    SpendBudgetsProgramSummaryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SpendBudgetsProgramSummaryQuery,
    SpendBudgetsProgramSummaryQueryVariables
  >(SpendBudgetsProgramSummaryDocument, options);
}
export function useSpendBudgetsProgramSummaryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SpendBudgetsProgramSummaryQuery,
    SpendBudgetsProgramSummaryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SpendBudgetsProgramSummaryQuery,
    SpendBudgetsProgramSummaryQueryVariables
  >(SpendBudgetsProgramSummaryDocument, options);
}
export type SpendBudgetsProgramSummaryQueryHookResult = ReturnType<
  typeof useSpendBudgetsProgramSummaryQuery
>;
export type SpendBudgetsProgramSummaryLazyQueryHookResult = ReturnType<
  typeof useSpendBudgetsProgramSummaryLazyQuery
>;
export type SpendBudgetsProgramSummaryQueryResult = Apollo.QueryResult<
  SpendBudgetsProgramSummaryQuery,
  SpendBudgetsProgramSummaryQueryVariables
>;
export const SpendBudgetExportDocument = gql`
  query SpendBudgetExport {
    spendBudgetExport {
      content
      fileName
    }
  }
`;

/**
 * __useSpendBudgetExportQuery__
 *
 * To run a query within a React component, call `useSpendBudgetExportQuery` and pass it any options that fit your needs.
 * When your component renders, `useSpendBudgetExportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSpendBudgetExportQuery({
 *   variables: {
 *   },
 * });
 */
export function useSpendBudgetExportQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SpendBudgetExportQuery,
    SpendBudgetExportQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SpendBudgetExportQuery,
    SpendBudgetExportQueryVariables
  >(SpendBudgetExportDocument, options);
}
export function useSpendBudgetExportLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SpendBudgetExportQuery,
    SpendBudgetExportQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SpendBudgetExportQuery,
    SpendBudgetExportQueryVariables
  >(SpendBudgetExportDocument, options);
}
export type SpendBudgetExportQueryHookResult = ReturnType<
  typeof useSpendBudgetExportQuery
>;
export type SpendBudgetExportLazyQueryHookResult = ReturnType<
  typeof useSpendBudgetExportLazyQuery
>;
export type SpendBudgetExportQueryResult = Apollo.QueryResult<
  SpendBudgetExportQuery,
  SpendBudgetExportQueryVariables
>;
export const SpendBudgetsSummaryEmptyDocument = gql`
  query SpendBudgetsSummaryEmpty {
    spendBudgetsProgramSummary {
      summaryByCategory {
        id
      }
      summaryUnreconciled {
        credits {
          total
        }
        debits {
          total
        }
      }
    }
  }
`;

/**
 * __useSpendBudgetsSummaryEmptyQuery__
 *
 * To run a query within a React component, call `useSpendBudgetsSummaryEmptyQuery` and pass it any options that fit your needs.
 * When your component renders, `useSpendBudgetsSummaryEmptyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSpendBudgetsSummaryEmptyQuery({
 *   variables: {
 *   },
 * });
 */
export function useSpendBudgetsSummaryEmptyQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SpendBudgetsSummaryEmptyQuery,
    SpendBudgetsSummaryEmptyQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SpendBudgetsSummaryEmptyQuery,
    SpendBudgetsSummaryEmptyQueryVariables
  >(SpendBudgetsSummaryEmptyDocument, options);
}
export function useSpendBudgetsSummaryEmptyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SpendBudgetsSummaryEmptyQuery,
    SpendBudgetsSummaryEmptyQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SpendBudgetsSummaryEmptyQuery,
    SpendBudgetsSummaryEmptyQueryVariables
  >(SpendBudgetsSummaryEmptyDocument, options);
}
export type SpendBudgetsSummaryEmptyQueryHookResult = ReturnType<
  typeof useSpendBudgetsSummaryEmptyQuery
>;
export type SpendBudgetsSummaryEmptyLazyQueryHookResult = ReturnType<
  typeof useSpendBudgetsSummaryEmptyLazyQuery
>;
export type SpendBudgetsSummaryEmptyQueryResult = Apollo.QueryResult<
  SpendBudgetsSummaryEmptyQuery,
  SpendBudgetsSummaryEmptyQueryVariables
>;
export const SpendBudgetDocument = gql`
  query SpendBudget($spendBudgetId: String!, $spendGroupId: String) {
    spendBudget(id: $spendBudgetId, groupId: $spendGroupId) {
      budget {
        reconciledTransactions {
          reconciledTransaction {
            id
            amount
            createdAt
            transaction {
              totalReconciled
              type
              metadata {
                status
                description
                summary
              }
              effective
              history {
                date
                status
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useSpendBudgetQuery__
 *
 * To run a query within a React component, call `useSpendBudgetQuery` and pass it any options that fit your needs.
 * When your component renders, `useSpendBudgetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSpendBudgetQuery({
 *   variables: {
 *      spendBudgetId: // value for 'spendBudgetId'
 *      spendGroupId: // value for 'spendGroupId'
 *   },
 * });
 */
export function useSpendBudgetQuery(
  baseOptions: Apollo.QueryHookOptions<
    SpendBudgetQuery,
    SpendBudgetQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SpendBudgetQuery, SpendBudgetQueryVariables>(
    SpendBudgetDocument,
    options
  );
}
export function useSpendBudgetLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SpendBudgetQuery,
    SpendBudgetQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SpendBudgetQuery, SpendBudgetQueryVariables>(
    SpendBudgetDocument,
    options
  );
}
export type SpendBudgetQueryHookResult = ReturnType<typeof useSpendBudgetQuery>;
export type SpendBudgetLazyQueryHookResult = ReturnType<
  typeof useSpendBudgetLazyQuery
>;
export type SpendBudgetQueryResult = Apollo.QueryResult<
  SpendBudgetQuery,
  SpendBudgetQueryVariables
>;
export const SpendBudgetsSummarySimpleDocument = gql`
  query SpendBudgetsSummarySimple($groupId: String!, $seasonId: String!) {
    spendBudgetsSummary(groupId: $groupId, seasonId: $seasonId) {
      summaryByCategory {
        name
        budgets {
          description
          id
        }
        id
        type
      }
    }
  }
`;

/**
 * __useSpendBudgetsSummarySimpleQuery__
 *
 * To run a query within a React component, call `useSpendBudgetsSummarySimpleQuery` and pass it any options that fit your needs.
 * When your component renders, `useSpendBudgetsSummarySimpleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSpendBudgetsSummarySimpleQuery({
 *   variables: {
 *      groupId: // value for 'groupId'
 *      seasonId: // value for 'seasonId'
 *   },
 * });
 */
export function useSpendBudgetsSummarySimpleQuery(
  baseOptions: Apollo.QueryHookOptions<
    SpendBudgetsSummarySimpleQuery,
    SpendBudgetsSummarySimpleQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SpendBudgetsSummarySimpleQuery,
    SpendBudgetsSummarySimpleQueryVariables
  >(SpendBudgetsSummarySimpleDocument, options);
}
export function useSpendBudgetsSummarySimpleLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SpendBudgetsSummarySimpleQuery,
    SpendBudgetsSummarySimpleQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SpendBudgetsSummarySimpleQuery,
    SpendBudgetsSummarySimpleQueryVariables
  >(SpendBudgetsSummarySimpleDocument, options);
}
export type SpendBudgetsSummarySimpleQueryHookResult = ReturnType<
  typeof useSpendBudgetsSummarySimpleQuery
>;
export type SpendBudgetsSummarySimpleLazyQueryHookResult = ReturnType<
  typeof useSpendBudgetsSummarySimpleLazyQuery
>;
export type SpendBudgetsSummarySimpleQueryResult = Apollo.QueryResult<
  SpendBudgetsSummarySimpleQuery,
  SpendBudgetsSummarySimpleQueryVariables
>;
export const SpendCategoriesAddInvoiceDocument = gql`
  query SpendCategoriesAddInvoice(
    $groupId: String
    $seasonId: String
    $filterBy: CategoryFilterEnum
    $filterValue: String
  ) {
    spendCategories(
      groupId: $groupId
      seasonId: $seasonId
      filterBy: $filterBy
      filterValue: $filterValue
    ) {
      categories {
        budgets {
          id
          description
        }
      }
    }
  }
`;

/**
 * __useSpendCategoriesAddInvoiceQuery__
 *
 * To run a query within a React component, call `useSpendCategoriesAddInvoiceQuery` and pass it any options that fit your needs.
 * When your component renders, `useSpendCategoriesAddInvoiceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSpendCategoriesAddInvoiceQuery({
 *   variables: {
 *      groupId: // value for 'groupId'
 *      seasonId: // value for 'seasonId'
 *      filterBy: // value for 'filterBy'
 *      filterValue: // value for 'filterValue'
 *   },
 * });
 */
export function useSpendCategoriesAddInvoiceQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SpendCategoriesAddInvoiceQuery,
    SpendCategoriesAddInvoiceQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SpendCategoriesAddInvoiceQuery,
    SpendCategoriesAddInvoiceQueryVariables
  >(SpendCategoriesAddInvoiceDocument, options);
}
export function useSpendCategoriesAddInvoiceLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SpendCategoriesAddInvoiceQuery,
    SpendCategoriesAddInvoiceQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SpendCategoriesAddInvoiceQuery,
    SpendCategoriesAddInvoiceQueryVariables
  >(SpendCategoriesAddInvoiceDocument, options);
}
export type SpendCategoriesAddInvoiceQueryHookResult = ReturnType<
  typeof useSpendCategoriesAddInvoiceQuery
>;
export type SpendCategoriesAddInvoiceLazyQueryHookResult = ReturnType<
  typeof useSpendCategoriesAddInvoiceLazyQuery
>;
export type SpendCategoriesAddInvoiceQueryResult = Apollo.QueryResult<
  SpendCategoriesAddInvoiceQuery,
  SpendCategoriesAddInvoiceQueryVariables
>;
export const SpendBudgetByIdDocument = gql`
  query SpendBudgetById($spendBudgetId: String!) {
    spendBudget(id: $spendBudgetId) {
      budget {
        id
        description
        targetAmount
        category {
          name
          type
        }
      }
    }
  }
`;

/**
 * __useSpendBudgetByIdQuery__
 *
 * To run a query within a React component, call `useSpendBudgetByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useSpendBudgetByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSpendBudgetByIdQuery({
 *   variables: {
 *      spendBudgetId: // value for 'spendBudgetId'
 *   },
 * });
 */
export function useSpendBudgetByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    SpendBudgetByIdQuery,
    SpendBudgetByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SpendBudgetByIdQuery, SpendBudgetByIdQueryVariables>(
    SpendBudgetByIdDocument,
    options
  );
}
export function useSpendBudgetByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SpendBudgetByIdQuery,
    SpendBudgetByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SpendBudgetByIdQuery,
    SpendBudgetByIdQueryVariables
  >(SpendBudgetByIdDocument, options);
}
export type SpendBudgetByIdQueryHookResult = ReturnType<
  typeof useSpendBudgetByIdQuery
>;
export type SpendBudgetByIdLazyQueryHookResult = ReturnType<
  typeof useSpendBudgetByIdLazyQuery
>;
export type SpendBudgetByIdQueryResult = Apollo.QueryResult<
  SpendBudgetByIdQuery,
  SpendBudgetByIdQueryVariables
>;
export const SpendCategoriesDocument = gql`
  query spendCategories($filterBy: CategoryFilterEnum, $filterValue: String!) {
    spendCategories(filterBy: $filterBy, filterValue: $filterValue) {
      categories {
        id
        name
        isDefault
        type
        organizationId
        isHidden
      }
    }
  }
`;

/**
 * __useSpendCategoriesQuery__
 *
 * To run a query within a React component, call `useSpendCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSpendCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSpendCategoriesQuery({
 *   variables: {
 *      filterBy: // value for 'filterBy'
 *      filterValue: // value for 'filterValue'
 *   },
 * });
 */
export function useSpendCategoriesQuery(
  baseOptions: Apollo.QueryHookOptions<
    SpendCategoriesQuery,
    SpendCategoriesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SpendCategoriesQuery, SpendCategoriesQueryVariables>(
    SpendCategoriesDocument,
    options
  );
}
export function useSpendCategoriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SpendCategoriesQuery,
    SpendCategoriesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SpendCategoriesQuery,
    SpendCategoriesQueryVariables
  >(SpendCategoriesDocument, options);
}
export type SpendCategoriesQueryHookResult = ReturnType<
  typeof useSpendCategoriesQuery
>;
export type SpendCategoriesLazyQueryHookResult = ReturnType<
  typeof useSpendCategoriesLazyQuery
>;
export type SpendCategoriesQueryResult = Apollo.QueryResult<
  SpendCategoriesQuery,
  SpendCategoriesQueryVariables
>;
export const SpendAllCategoriesDocument = gql`
  query SpendAllCategories {
    spendCategories {
      categories {
        organizationId
        name
        isHidden
        isDefault
        id
        createdAt
        updatedAt
        type
      }
    }
  }
`;

/**
 * __useSpendAllCategoriesQuery__
 *
 * To run a query within a React component, call `useSpendAllCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSpendAllCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSpendAllCategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useSpendAllCategoriesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SpendAllCategoriesQuery,
    SpendAllCategoriesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SpendAllCategoriesQuery,
    SpendAllCategoriesQueryVariables
  >(SpendAllCategoriesDocument, options);
}
export function useSpendAllCategoriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SpendAllCategoriesQuery,
    SpendAllCategoriesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SpendAllCategoriesQuery,
    SpendAllCategoriesQueryVariables
  >(SpendAllCategoriesDocument, options);
}
export type SpendAllCategoriesQueryHookResult = ReturnType<
  typeof useSpendAllCategoriesQuery
>;
export type SpendAllCategoriesLazyQueryHookResult = ReturnType<
  typeof useSpendAllCategoriesLazyQuery
>;
export type SpendAllCategoriesQueryResult = Apollo.QueryResult<
  SpendAllCategoriesQuery,
  SpendAllCategoriesQueryVariables
>;
export const SpendBudgetCategoriesDocument = gql`
  query SpendBudgetCategories(
    $filterBy: CategoryFilterEnum
    $filterValue: String
    $groupId: String
    $seasonId: String
  ) {
    spendCategories(
      filterBy: $filterBy
      filterValue: $filterValue
      groupId: $groupId
      seasonId: $seasonId
    ) {
      count
      categories {
        id
        name
        type
        isDefault
        isHidden
        organizationId
        createdAt
        updatedAt
        budgets {
          id
          description
        }
      }
    }
  }
`;

/**
 * __useSpendBudgetCategoriesQuery__
 *
 * To run a query within a React component, call `useSpendBudgetCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSpendBudgetCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSpendBudgetCategoriesQuery({
 *   variables: {
 *      filterBy: // value for 'filterBy'
 *      filterValue: // value for 'filterValue'
 *      groupId: // value for 'groupId'
 *      seasonId: // value for 'seasonId'
 *   },
 * });
 */
export function useSpendBudgetCategoriesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SpendBudgetCategoriesQuery,
    SpendBudgetCategoriesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SpendBudgetCategoriesQuery,
    SpendBudgetCategoriesQueryVariables
  >(SpendBudgetCategoriesDocument, options);
}
export function useSpendBudgetCategoriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SpendBudgetCategoriesQuery,
    SpendBudgetCategoriesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SpendBudgetCategoriesQuery,
    SpendBudgetCategoriesQueryVariables
  >(SpendBudgetCategoriesDocument, options);
}
export type SpendBudgetCategoriesQueryHookResult = ReturnType<
  typeof useSpendBudgetCategoriesQuery
>;
export type SpendBudgetCategoriesLazyQueryHookResult = ReturnType<
  typeof useSpendBudgetCategoriesLazyQuery
>;
export type SpendBudgetCategoriesQueryResult = Apollo.QueryResult<
  SpendBudgetCategoriesQuery,
  SpendBudgetCategoriesQueryVariables
>;
export const SpendGroupDocument = gql`
  query SpendGroup {
    spendGroup {
      organizationFees {
        achBaseFee
        achPercent
        cardBaseFee
        cardPercent
        spendBaseFee
        spendPercent
      }
      id
      isArchived
      name
      accountId
      sharedAccount
      discountAmount
      discountCutOffDate
      enableDiscount
      isRequireAgreement
      minimumDiscountPurchase
      legacyAccountId
      seasons {
        id
        name
        groupRoster {
          id
          inviteId
          isArchived
          userId
          roster {
            email
            id
            name
          }
          rosterId
        }
        budgets {
          id
          description
          category {
            id
            type
            name
            createdAt
            isDefault
            isHidden
            organizationId
          }
          createdAt
          targetAmount
          targetDateAt
        }
      }
    }
  }
`;

/**
 * __useSpendGroupQuery__
 *
 * To run a query within a React component, call `useSpendGroupQuery` and pass it any options that fit your needs.
 * When your component renders, `useSpendGroupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSpendGroupQuery({
 *   variables: {
 *   },
 * });
 */
export function useSpendGroupQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SpendGroupQuery,
    SpendGroupQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SpendGroupQuery, SpendGroupQueryVariables>(
    SpendGroupDocument,
    options
  );
}
export function useSpendGroupLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SpendGroupQuery,
    SpendGroupQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SpendGroupQuery, SpendGroupQueryVariables>(
    SpendGroupDocument,
    options
  );
}
export type SpendGroupQueryHookResult = ReturnType<typeof useSpendGroupQuery>;
export type SpendGroupLazyQueryHookResult = ReturnType<
  typeof useSpendGroupLazyQuery
>;
export type SpendGroupQueryResult = Apollo.QueryResult<
  SpendGroupQuery,
  SpendGroupQueryVariables
>;
export const SpendGroupsFilteredDocument = gql`
  query SpendGroupsFiltered(
    $where: SpendGroupsWhereInput
    $pagination: SpendPaginationInput
    $sort: SpendSortInput
  ) {
    spendGroupsFiltered(where: $where, pagination: $pagination, sort: $sort) {
      count
      groups {
        id
        name
        accountId
        organizationId
        name
        hasAccount
        isArchived
        sharedAccount
        discountAmount
        discountCutOffDate
        enableDiscount
        isRequireAgreement
        minimumDiscountPurchase
        seasons {
          id
          name
          startDateAt
          endDateAt
          playerCount
          paymentScheduleStatus
          isLinkEnabled
          isBudgetShared
          budgets {
            id
            description
            category {
              id
              type
              name
              createdAt
              isDefault
              isHidden
              organizationId
            }
            createdAt
            targetAmount
            targetDateAt
          }
          groupRoster {
            id
            inviteId
            isArchived
            roster {
              email
              id
              name
            }
            rosterId
            joinedAt
          }
        }
        latestSeason {
          id
          name
          paymentScheduleStatus
          isBudgetShared
          isLinkEnabled
          startDateAt
          endDateAt
          groupId
          playerCount
          transactionTotals {
            paid
            processing
            upcoming
            pastDue
            dueToday
            credited
          }
        }
        unitAmount
        legacyAccountId
      }
    }
  }
`;

/**
 * __useSpendGroupsFilteredQuery__
 *
 * To run a query within a React component, call `useSpendGroupsFilteredQuery` and pass it any options that fit your needs.
 * When your component renders, `useSpendGroupsFilteredQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSpendGroupsFilteredQuery({
 *   variables: {
 *      where: // value for 'where'
 *      pagination: // value for 'pagination'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useSpendGroupsFilteredQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SpendGroupsFilteredQuery,
    SpendGroupsFilteredQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SpendGroupsFilteredQuery,
    SpendGroupsFilteredQueryVariables
  >(SpendGroupsFilteredDocument, options);
}
export function useSpendGroupsFilteredLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SpendGroupsFilteredQuery,
    SpendGroupsFilteredQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SpendGroupsFilteredQuery,
    SpendGroupsFilteredQueryVariables
  >(SpendGroupsFilteredDocument, options);
}
export type SpendGroupsFilteredQueryHookResult = ReturnType<
  typeof useSpendGroupsFilteredQuery
>;
export type SpendGroupsFilteredLazyQueryHookResult = ReturnType<
  typeof useSpendGroupsFilteredLazyQuery
>;
export type SpendGroupsFilteredQueryResult = Apollo.QueryResult<
  SpendGroupsFilteredQuery,
  SpendGroupsFilteredQueryVariables
>;
export const SpendGroupsDocument = gql`
  query SpendGroups {
    spendGroups {
      groups {
        id
        accountId
        organizationId
        name
        hasAccount
        seasons {
          id
          name
          startDateAt
          endDateAt
          playerCount
          paymentScheduleStatus
          isLinkEnabled
          isBudgetShared
          budgets {
            id
            description
            category {
              id
              type
            }
          }
          transactionTotals {
            paid
            processing
            upcoming
            pastDue
            credited
          }
          groupRoster {
            id
            inviteId
            isArchived
            roster {
              email
              id
              name
            }
            rosterId
          }
        }
      }
    }
  }
`;

/**
 * __useSpendGroupsQuery__
 *
 * To run a query within a React component, call `useSpendGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSpendGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSpendGroupsQuery({
 *   variables: {
 *   },
 * });
 */
export function useSpendGroupsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SpendGroupsQuery,
    SpendGroupsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SpendGroupsQuery, SpendGroupsQueryVariables>(
    SpendGroupsDocument,
    options
  );
}
export function useSpendGroupsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SpendGroupsQuery,
    SpendGroupsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SpendGroupsQuery, SpendGroupsQueryVariables>(
    SpendGroupsDocument,
    options
  );
}
export type SpendGroupsQueryHookResult = ReturnType<typeof useSpendGroupsQuery>;
export type SpendGroupsLazyQueryHookResult = ReturnType<
  typeof useSpendGroupsLazyQuery
>;
export type SpendGroupsQueryResult = Apollo.QueryResult<
  SpendGroupsQuery,
  SpendGroupsQueryVariables
>;
export const SpendGroupByIdDocument = gql`
  query SpendGroupById($spendGroupByIdId: String!) {
    spendGroupById(id: $spendGroupByIdId) {
      id
      accountId
      organizationId
      name
      hasAccount
      isArchived
      sharedAccount
      seasons {
        id
        name
        startDateAt
        endDateAt
        playerCount
        paymentScheduleStatus
        isLinkEnabled
        isBudgetShared
        budgets {
          id
          description
          category {
            id
            type
            name
            createdAt
            isDefault
            isHidden
            organizationId
          }
          createdAt
          targetAmount
          targetDateAt
        }
        transactionTotals {
          paid
          processing
          upcoming
          pastDue
          credited
        }
        groupRoster {
          id
          inviteId
          isArchived
          roster {
            email
            id
            name
          }
          rosterId
        }
      }
    }
  }
`;

/**
 * __useSpendGroupByIdQuery__
 *
 * To run a query within a React component, call `useSpendGroupByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useSpendGroupByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSpendGroupByIdQuery({
 *   variables: {
 *      spendGroupByIdId: // value for 'spendGroupByIdId'
 *   },
 * });
 */
export function useSpendGroupByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    SpendGroupByIdQuery,
    SpendGroupByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SpendGroupByIdQuery, SpendGroupByIdQueryVariables>(
    SpendGroupByIdDocument,
    options
  );
}
export function useSpendGroupByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SpendGroupByIdQuery,
    SpendGroupByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SpendGroupByIdQuery, SpendGroupByIdQueryVariables>(
    SpendGroupByIdDocument,
    options
  );
}
export type SpendGroupByIdQueryHookResult = ReturnType<
  typeof useSpendGroupByIdQuery
>;
export type SpendGroupByIdLazyQueryHookResult = ReturnType<
  typeof useSpendGroupByIdLazyQuery
>;
export type SpendGroupByIdQueryResult = Apollo.QueryResult<
  SpendGroupByIdQuery,
  SpendGroupByIdQueryVariables
>;
export const SpendSignupGroupIdDocument = gql`
  query SpendSignupGroupId($spendGroupByIdId: String!) {
    spendGroupById(id: $spendGroupByIdId) {
      organizationFees {
        achBaseFee
        achPercent
        cardBaseFee
        cardPercent
        spendBaseFee
        spendPercent
      }
      id
      name
      accountId
      sharedAccount
      discountAmount
      discountCutOffDate
      enableDiscount
      isRequireAgreement
      minimumDiscountPurchase
      legacyAccountId
      seasons {
        id
        name
        groupRoster {
          id
          inviteId
          isArchived
          userId
          roster {
            email
            id
            name
          }
          rosterId
        }
      }
    }
  }
`;

/**
 * __useSpendSignupGroupIdQuery__
 *
 * To run a query within a React component, call `useSpendSignupGroupIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useSpendSignupGroupIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSpendSignupGroupIdQuery({
 *   variables: {
 *      spendGroupByIdId: // value for 'spendGroupByIdId'
 *   },
 * });
 */
export function useSpendSignupGroupIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    SpendSignupGroupIdQuery,
    SpendSignupGroupIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SpendSignupGroupIdQuery,
    SpendSignupGroupIdQueryVariables
  >(SpendSignupGroupIdDocument, options);
}
export function useSpendSignupGroupIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SpendSignupGroupIdQuery,
    SpendSignupGroupIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SpendSignupGroupIdQuery,
    SpendSignupGroupIdQueryVariables
  >(SpendSignupGroupIdDocument, options);
}
export type SpendSignupGroupIdQueryHookResult = ReturnType<
  typeof useSpendSignupGroupIdQuery
>;
export type SpendSignupGroupIdLazyQueryHookResult = ReturnType<
  typeof useSpendSignupGroupIdLazyQuery
>;
export type SpendSignupGroupIdQueryResult = Apollo.QueryResult<
  SpendSignupGroupIdQuery,
  SpendSignupGroupIdQueryVariables
>;
export const SpendGroupByIdSettingsDocument = gql`
  query SpendGroupByIdSettings($spendGroupByIdId: String!) {
    spendGroupById(id: $spendGroupByIdId) {
      id
      accountId
      organizationId
      name
      latestSeason {
        id
        startDateAt
        endDateAt
        isLinkEnabled
        name
        paymentScheduleStatus
        transactionTotals {
          paid
          processing
          upcoming
          pastDue
          dueToday
          credited
        }
      }
    }
  }
`;

/**
 * __useSpendGroupByIdSettingsQuery__
 *
 * To run a query within a React component, call `useSpendGroupByIdSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSpendGroupByIdSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSpendGroupByIdSettingsQuery({
 *   variables: {
 *      spendGroupByIdId: // value for 'spendGroupByIdId'
 *   },
 * });
 */
export function useSpendGroupByIdSettingsQuery(
  baseOptions: Apollo.QueryHookOptions<
    SpendGroupByIdSettingsQuery,
    SpendGroupByIdSettingsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SpendGroupByIdSettingsQuery,
    SpendGroupByIdSettingsQueryVariables
  >(SpendGroupByIdSettingsDocument, options);
}
export function useSpendGroupByIdSettingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SpendGroupByIdSettingsQuery,
    SpendGroupByIdSettingsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SpendGroupByIdSettingsQuery,
    SpendGroupByIdSettingsQueryVariables
  >(SpendGroupByIdSettingsDocument, options);
}
export type SpendGroupByIdSettingsQueryHookResult = ReturnType<
  typeof useSpendGroupByIdSettingsQuery
>;
export type SpendGroupByIdSettingsLazyQueryHookResult = ReturnType<
  typeof useSpendGroupByIdSettingsLazyQuery
>;
export type SpendGroupByIdSettingsQueryResult = Apollo.QueryResult<
  SpendGroupByIdSettingsQuery,
  SpendGroupByIdSettingsQueryVariables
>;
export const SpendBudgetGroupsDocument = gql`
  query SpendBudgetGroups {
    spendGroups {
      groups {
        id
        organizationId
        name
        accountId
        hasAccount
        seasons {
          id
          name
          budgets {
            id
            targetAmount
            category {
              type
            }
            invoices {
              id
              amount
              paid
              isReconciled
            }
          }
          isBudgetShared
        }
      }
    }
  }
`;

/**
 * __useSpendBudgetGroupsQuery__
 *
 * To run a query within a React component, call `useSpendBudgetGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSpendBudgetGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSpendBudgetGroupsQuery({
 *   variables: {
 *   },
 * });
 */
export function useSpendBudgetGroupsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SpendBudgetGroupsQuery,
    SpendBudgetGroupsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SpendBudgetGroupsQuery,
    SpendBudgetGroupsQueryVariables
  >(SpendBudgetGroupsDocument, options);
}
export function useSpendBudgetGroupsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SpendBudgetGroupsQuery,
    SpendBudgetGroupsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SpendBudgetGroupsQuery,
    SpendBudgetGroupsQueryVariables
  >(SpendBudgetGroupsDocument, options);
}
export type SpendBudgetGroupsQueryHookResult = ReturnType<
  typeof useSpendBudgetGroupsQuery
>;
export type SpendBudgetGroupsLazyQueryHookResult = ReturnType<
  typeof useSpendBudgetGroupsLazyQuery
>;
export type SpendBudgetGroupsQueryResult = Apollo.QueryResult<
  SpendBudgetGroupsQuery,
  SpendBudgetGroupsQueryVariables
>;
export const SpendGroupRostersDocument = gql`
  query SpendGroupRosters {
    spendGroupRosters {
      count
      groupRosters {
        archivedAt
        createdAt
        groupId
        id
        isArchived
        joinedAt
        roster {
          name
          email
          id
        }
        group {
          id
          name
          accountId
        }
        season {
          id
          name
          startDateAt
          endDateAt
        }
        rosterId
        userId
        paymentScheduleStatus
        invoices {
          id
          amount
          paid
          paidDate
          dueDate
          balanceDue
          description
          isOptional
          optedIn
          authorizedAt
          transaction {
            id
            invoiceId
            externalId
          }
          reconciledTransactions {
            id
          }
        }
      }
    }
  }
`;

/**
 * __useSpendGroupRostersQuery__
 *
 * To run a query within a React component, call `useSpendGroupRostersQuery` and pass it any options that fit your needs.
 * When your component renders, `useSpendGroupRostersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSpendGroupRostersQuery({
 *   variables: {
 *   },
 * });
 */
export function useSpendGroupRostersQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SpendGroupRostersQuery,
    SpendGroupRostersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SpendGroupRostersQuery,
    SpendGroupRostersQueryVariables
  >(SpendGroupRostersDocument, options);
}
export function useSpendGroupRostersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SpendGroupRostersQuery,
    SpendGroupRostersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SpendGroupRostersQuery,
    SpendGroupRostersQueryVariables
  >(SpendGroupRostersDocument, options);
}
export type SpendGroupRostersQueryHookResult = ReturnType<
  typeof useSpendGroupRostersQuery
>;
export type SpendGroupRostersLazyQueryHookResult = ReturnType<
  typeof useSpendGroupRostersLazyQuery
>;
export type SpendGroupRostersQueryResult = Apollo.QueryResult<
  SpendGroupRostersQuery,
  SpendGroupRostersQueryVariables
>;
export const SpendGroupRostersAndPaymentScheduleDocument = gql`
  query SpendGroupRostersAndPaymentSchedule(
    $seasonId: String!
    $groupIdOrSeasonId: String!
  ) {
    spendGroupRosters(filterBy: seasonId, filterValue: $seasonId) {
      count
      groupRosters {
        inviteId
        invite {
          status
          isArchived
          id
        }
        archivedAt
        createdAt
        groupId
        id
        isArchived
        roster {
          name
          email
          id
        }
        group {
          id
          name
          accountId
        }
        season {
          id
          name
          startDateAt
          endDateAt
        }
        seasonId
        rosterId
        userId
        invoices {
          id
          amount
          paid
          paidDate
          dueDate
          balanceDue
          description
          status
          transaction {
            id
            invoiceId
            externalId
          }
          reconciledTransactions {
            id
          }
        }
      }
    }
    spendPaymentSchedules(groupIdOrSeasonId: $groupIdOrSeasonId) {
      id
      amountDue
      dueDate
      description
      status
      note
      isOptional
      budgetItemId
      seasonId
      groupId
      budgetItem {
        id
        description
        targetAmount
        targetDateAt
        vaultId
        category {
          name
          type
          id
        }
        isDefault
        createdAt
        updatedAt
        reconciledBudgetTotal
        reconciledInvoicesTotal
        reconciledTotal
        invoices {
          balanceDue
          description
          paid
          id
          reconciledTransactions {
            amount
            id
            invoiceId
            transactionId
          }
          amount
        }
      }
      season {
        id
        name
        startDateAt
        endDateAt
      }
      group {
        id
        name
        organizationId
        programId
        createdAt
        archivedAt
        hasAccount
      }
    }
  }
`;

/**
 * __useSpendGroupRostersAndPaymentScheduleQuery__
 *
 * To run a query within a React component, call `useSpendGroupRostersAndPaymentScheduleQuery` and pass it any options that fit your needs.
 * When your component renders, `useSpendGroupRostersAndPaymentScheduleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSpendGroupRostersAndPaymentScheduleQuery({
 *   variables: {
 *      seasonId: // value for 'seasonId'
 *      groupIdOrSeasonId: // value for 'groupIdOrSeasonId'
 *   },
 * });
 */
export function useSpendGroupRostersAndPaymentScheduleQuery(
  baseOptions: Apollo.QueryHookOptions<
    SpendGroupRostersAndPaymentScheduleQuery,
    SpendGroupRostersAndPaymentScheduleQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SpendGroupRostersAndPaymentScheduleQuery,
    SpendGroupRostersAndPaymentScheduleQueryVariables
  >(SpendGroupRostersAndPaymentScheduleDocument, options);
}
export function useSpendGroupRostersAndPaymentScheduleLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SpendGroupRostersAndPaymentScheduleQuery,
    SpendGroupRostersAndPaymentScheduleQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SpendGroupRostersAndPaymentScheduleQuery,
    SpendGroupRostersAndPaymentScheduleQueryVariables
  >(SpendGroupRostersAndPaymentScheduleDocument, options);
}
export type SpendGroupRostersAndPaymentScheduleQueryHookResult = ReturnType<
  typeof useSpendGroupRostersAndPaymentScheduleQuery
>;
export type SpendGroupRostersAndPaymentScheduleLazyQueryHookResult = ReturnType<
  typeof useSpendGroupRostersAndPaymentScheduleLazyQuery
>;
export type SpendGroupRostersAndPaymentScheduleQueryResult = Apollo.QueryResult<
  SpendGroupRostersAndPaymentScheduleQuery,
  SpendGroupRostersAndPaymentScheduleQueryVariables
>;
export const SpendSimpleGroupsDocument = gql`
  query SpendSimpleGroups {
    spendGroups {
      groups {
        id
        name
      }
    }
  }
`;

/**
 * __useSpendSimpleGroupsQuery__
 *
 * To run a query within a React component, call `useSpendSimpleGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSpendSimpleGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSpendSimpleGroupsQuery({
 *   variables: {
 *   },
 * });
 */
export function useSpendSimpleGroupsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SpendSimpleGroupsQuery,
    SpendSimpleGroupsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SpendSimpleGroupsQuery,
    SpendSimpleGroupsQueryVariables
  >(SpendSimpleGroupsDocument, options);
}
export function useSpendSimpleGroupsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SpendSimpleGroupsQuery,
    SpendSimpleGroupsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SpendSimpleGroupsQuery,
    SpendSimpleGroupsQueryVariables
  >(SpendSimpleGroupsDocument, options);
}
export type SpendSimpleGroupsQueryHookResult = ReturnType<
  typeof useSpendSimpleGroupsQuery
>;
export type SpendSimpleGroupsLazyQueryHookResult = ReturnType<
  typeof useSpendSimpleGroupsLazyQuery
>;
export type SpendSimpleGroupsQueryResult = Apollo.QueryResult<
  SpendSimpleGroupsQuery,
  SpendSimpleGroupsQueryVariables
>;
export const SpendGroupDebitCardsDocument = gql`
  query SpendGroupDebitCards($spendGroupDebitCardsId: String) {
    spendGroupDebitCards(id: $spendGroupDebitCardsId) {
      cards {
        expiration
        id
        lastFour
        status
        userId
      }
      count
    }
  }
`;

/**
 * __useSpendGroupDebitCardsQuery__
 *
 * To run a query within a React component, call `useSpendGroupDebitCardsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSpendGroupDebitCardsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSpendGroupDebitCardsQuery({
 *   variables: {
 *      spendGroupDebitCardsId: // value for 'spendGroupDebitCardsId'
 *   },
 * });
 */
export function useSpendGroupDebitCardsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SpendGroupDebitCardsQuery,
    SpendGroupDebitCardsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SpendGroupDebitCardsQuery,
    SpendGroupDebitCardsQueryVariables
  >(SpendGroupDebitCardsDocument, options);
}
export function useSpendGroupDebitCardsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SpendGroupDebitCardsQuery,
    SpendGroupDebitCardsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SpendGroupDebitCardsQuery,
    SpendGroupDebitCardsQueryVariables
  >(SpendGroupDebitCardsDocument, options);
}
export type SpendGroupDebitCardsQueryHookResult = ReturnType<
  typeof useSpendGroupDebitCardsQuery
>;
export type SpendGroupDebitCardsLazyQueryHookResult = ReturnType<
  typeof useSpendGroupDebitCardsLazyQuery
>;
export type SpendGroupDebitCardsQueryResult = Apollo.QueryResult<
  SpendGroupDebitCardsQuery,
  SpendGroupDebitCardsQueryVariables
>;
export const SpendGroupRosterInvoicesDocument = gql`
  query SpendGroupRosterInvoices(
    $filterValue: String
    $filterBy: GroupRosterFilterEnum
  ) {
    spendGroupRosters(filterValue: $filterValue, filterBy: $filterBy) {
      count
      groupRosters {
        invoices {
          description
          dueDate
          paymentMethodSource
          paymentMethodId
          balanceDue
          paid
          id
        }
        group {
          name
        }
        roster {
          name
        }
      }
    }
  }
`;

/**
 * __useSpendGroupRosterInvoicesQuery__
 *
 * To run a query within a React component, call `useSpendGroupRosterInvoicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSpendGroupRosterInvoicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSpendGroupRosterInvoicesQuery({
 *   variables: {
 *      filterValue: // value for 'filterValue'
 *      filterBy: // value for 'filterBy'
 *   },
 * });
 */
export function useSpendGroupRosterInvoicesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SpendGroupRosterInvoicesQuery,
    SpendGroupRosterInvoicesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SpendGroupRosterInvoicesQuery,
    SpendGroupRosterInvoicesQueryVariables
  >(SpendGroupRosterInvoicesDocument, options);
}
export function useSpendGroupRosterInvoicesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SpendGroupRosterInvoicesQuery,
    SpendGroupRosterInvoicesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SpendGroupRosterInvoicesQuery,
    SpendGroupRosterInvoicesQueryVariables
  >(SpendGroupRosterInvoicesDocument, options);
}
export type SpendGroupRosterInvoicesQueryHookResult = ReturnType<
  typeof useSpendGroupRosterInvoicesQuery
>;
export type SpendGroupRosterInvoicesLazyQueryHookResult = ReturnType<
  typeof useSpendGroupRosterInvoicesLazyQuery
>;
export type SpendGroupRosterInvoicesQueryResult = Apollo.QueryResult<
  SpendGroupRosterInvoicesQuery,
  SpendGroupRosterInvoicesQueryVariables
>;
export const SpendGroupRostersParentInvoicesDocument = gql`
  query SpendGroupRostersParentInvoices(
    $filterBy: GroupRosterFilterEnum
    $filterValue: String
  ) {
    spendGroupRosters(filterBy: $filterBy, filterValue: $filterValue) {
      count
      groupRosters {
        group {
          id
          name
        }
        season {
          name
        }
        invoices {
          balanceDue
          amount
          paidDate
          dueDate
          paid
          paymentMethodSource
          description
          id
          isAutoPayAuthorized
          isAutoPayStopped
          authorizedAt
          optedIn
          refundDate
          status
          isRefunded
          discountAmount
          isOptional
        }
        roster {
          id
          name
        }
        invite {
          status
        }
        userId
      }
    }
  }
`;

/**
 * __useSpendGroupRostersParentInvoicesQuery__
 *
 * To run a query within a React component, call `useSpendGroupRostersParentInvoicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSpendGroupRostersParentInvoicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSpendGroupRostersParentInvoicesQuery({
 *   variables: {
 *      filterBy: // value for 'filterBy'
 *      filterValue: // value for 'filterValue'
 *   },
 * });
 */
export function useSpendGroupRostersParentInvoicesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SpendGroupRostersParentInvoicesQuery,
    SpendGroupRostersParentInvoicesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SpendGroupRostersParentInvoicesQuery,
    SpendGroupRostersParentInvoicesQueryVariables
  >(SpendGroupRostersParentInvoicesDocument, options);
}
export function useSpendGroupRostersParentInvoicesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SpendGroupRostersParentInvoicesQuery,
    SpendGroupRostersParentInvoicesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SpendGroupRostersParentInvoicesQuery,
    SpendGroupRostersParentInvoicesQueryVariables
  >(SpendGroupRostersParentInvoicesDocument, options);
}
export type SpendGroupRostersParentInvoicesQueryHookResult = ReturnType<
  typeof useSpendGroupRostersParentInvoicesQuery
>;
export type SpendGroupRostersParentInvoicesLazyQueryHookResult = ReturnType<
  typeof useSpendGroupRostersParentInvoicesLazyQuery
>;
export type SpendGroupRostersParentInvoicesQueryResult = Apollo.QueryResult<
  SpendGroupRostersParentInvoicesQuery,
  SpendGroupRostersParentInvoicesQueryVariables
>;
export const SpendGroupBankAccountsDocument = gql`
  query SpendGroupBankAccounts($groupId: String) {
    spendGroupBankAccounts(groupId: $groupId) {
      externalAccounts {
        accountId
        availableBalance
        currentBalance
        mask
        name
        officialName
        subtype
        type
        institution {
          id
          name
        }
      }
      status
    }
  }
`;

/**
 * __useSpendGroupBankAccountsQuery__
 *
 * To run a query within a React component, call `useSpendGroupBankAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSpendGroupBankAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSpendGroupBankAccountsQuery({
 *   variables: {
 *      groupId: // value for 'groupId'
 *   },
 * });
 */
export function useSpendGroupBankAccountsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SpendGroupBankAccountsQuery,
    SpendGroupBankAccountsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SpendGroupBankAccountsQuery,
    SpendGroupBankAccountsQueryVariables
  >(SpendGroupBankAccountsDocument, options);
}
export function useSpendGroupBankAccountsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SpendGroupBankAccountsQuery,
    SpendGroupBankAccountsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SpendGroupBankAccountsQuery,
    SpendGroupBankAccountsQueryVariables
  >(SpendGroupBankAccountsDocument, options);
}
export type SpendGroupBankAccountsQueryHookResult = ReturnType<
  typeof useSpendGroupBankAccountsQuery
>;
export type SpendGroupBankAccountsLazyQueryHookResult = ReturnType<
  typeof useSpendGroupBankAccountsLazyQuery
>;
export type SpendGroupBankAccountsQueryResult = Apollo.QueryResult<
  SpendGroupBankAccountsQuery,
  SpendGroupBankAccountsQueryVariables
>;
export const SpendParentSignUpGroupRostersDocument = gql`
  query SpendParentSignUpGroupRosters {
    spendGroupRosters {
      groupRosters {
        roster {
          email
          groupRosters {
            invoices {
              amount
              isOptional
              optedIn
              description
              dueDate
              id
            }
          }
          id
          name
        }
        group {
          name
        }
        season {
          name
        }
      }
    }
  }
`;

/**
 * __useSpendParentSignUpGroupRostersQuery__
 *
 * To run a query within a React component, call `useSpendParentSignUpGroupRostersQuery` and pass it any options that fit your needs.
 * When your component renders, `useSpendParentSignUpGroupRostersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSpendParentSignUpGroupRostersQuery({
 *   variables: {
 *   },
 * });
 */
export function useSpendParentSignUpGroupRostersQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SpendParentSignUpGroupRostersQuery,
    SpendParentSignUpGroupRostersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SpendParentSignUpGroupRostersQuery,
    SpendParentSignUpGroupRostersQueryVariables
  >(SpendParentSignUpGroupRostersDocument, options);
}
export function useSpendParentSignUpGroupRostersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SpendParentSignUpGroupRostersQuery,
    SpendParentSignUpGroupRostersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SpendParentSignUpGroupRostersQuery,
    SpendParentSignUpGroupRostersQueryVariables
  >(SpendParentSignUpGroupRostersDocument, options);
}
export type SpendParentSignUpGroupRostersQueryHookResult = ReturnType<
  typeof useSpendParentSignUpGroupRostersQuery
>;
export type SpendParentSignUpGroupRostersLazyQueryHookResult = ReturnType<
  typeof useSpendParentSignUpGroupRostersLazyQuery
>;
export type SpendParentSignUpGroupRostersQueryResult = Apollo.QueryResult<
  SpendParentSignUpGroupRostersQuery,
  SpendParentSignUpGroupRostersQueryVariables
>;
export const SpendGroupPayeesDocument = gql`
  query SpendGroupPayees($groupId: String!) {
    spendGroupPayees(groupId: $groupId) {
      payees {
        id
        name
        address1
        address2
        city
        state
        zipCode
      }
    }
  }
`;

/**
 * __useSpendGroupPayeesQuery__
 *
 * To run a query within a React component, call `useSpendGroupPayeesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSpendGroupPayeesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSpendGroupPayeesQuery({
 *   variables: {
 *      groupId: // value for 'groupId'
 *   },
 * });
 */
export function useSpendGroupPayeesQuery(
  baseOptions: Apollo.QueryHookOptions<
    SpendGroupPayeesQuery,
    SpendGroupPayeesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SpendGroupPayeesQuery, SpendGroupPayeesQueryVariables>(
    SpendGroupPayeesDocument,
    options
  );
}
export function useSpendGroupPayeesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SpendGroupPayeesQuery,
    SpendGroupPayeesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SpendGroupPayeesQuery,
    SpendGroupPayeesQueryVariables
  >(SpendGroupPayeesDocument, options);
}
export type SpendGroupPayeesQueryHookResult = ReturnType<
  typeof useSpendGroupPayeesQuery
>;
export type SpendGroupPayeesLazyQueryHookResult = ReturnType<
  typeof useSpendGroupPayeesLazyQuery
>;
export type SpendGroupPayeesQueryResult = Apollo.QueryResult<
  SpendGroupPayeesQuery,
  SpendGroupPayeesQueryVariables
>;
export const GetGroupRosterByIdsDocument = gql`
  query GetGroupRosterByIds(
    $filterBy: GroupRosterFilterEnum
    $filterValue: String
  ) {
    spendGroupRosters(filterBy: $filterBy, filterValue: $filterValue) {
      groupRosters {
        rosterId
      }
      count
    }
  }
`;

/**
 * __useGetGroupRosterByIdsQuery__
 *
 * To run a query within a React component, call `useGetGroupRosterByIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGroupRosterByIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGroupRosterByIdsQuery({
 *   variables: {
 *      filterBy: // value for 'filterBy'
 *      filterValue: // value for 'filterValue'
 *   },
 * });
 */
export function useGetGroupRosterByIdsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetGroupRosterByIdsQuery,
    GetGroupRosterByIdsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetGroupRosterByIdsQuery,
    GetGroupRosterByIdsQueryVariables
  >(GetGroupRosterByIdsDocument, options);
}
export function useGetGroupRosterByIdsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetGroupRosterByIdsQuery,
    GetGroupRosterByIdsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetGroupRosterByIdsQuery,
    GetGroupRosterByIdsQueryVariables
  >(GetGroupRosterByIdsDocument, options);
}
export type GetGroupRosterByIdsQueryHookResult = ReturnType<
  typeof useGetGroupRosterByIdsQuery
>;
export type GetGroupRosterByIdsLazyQueryHookResult = ReturnType<
  typeof useGetGroupRosterByIdsLazyQuery
>;
export type GetGroupRosterByIdsQueryResult = Apollo.QueryResult<
  GetGroupRosterByIdsQuery,
  GetGroupRosterByIdsQueryVariables
>;
export const SpendUserSignUpDocument = gql`
  mutation SpendUserSignUp($input: SpendSignUpInput!) {
    spendUserSignUp(input: $input) {
      id
    }
  }
`;
export type SpendUserSignUpMutationFn = Apollo.MutationFunction<
  SpendUserSignUpMutation,
  SpendUserSignUpMutationVariables
>;

/**
 * __useSpendUserSignUpMutation__
 *
 * To run a mutation, you first call `useSpendUserSignUpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSpendUserSignUpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [spendUserSignUpMutation, { data, loading, error }] = useSpendUserSignUpMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSpendUserSignUpMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SpendUserSignUpMutation,
    SpendUserSignUpMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SpendUserSignUpMutation,
    SpendUserSignUpMutationVariables
  >(SpendUserSignUpDocument, options);
}
export type SpendUserSignUpMutationHookResult = ReturnType<
  typeof useSpendUserSignUpMutation
>;
export type SpendUserSignUpMutationResult =
  Apollo.MutationResult<SpendUserSignUpMutation>;
export type SpendUserSignUpMutationOptions = Apollo.BaseMutationOptions<
  SpendUserSignUpMutation,
  SpendUserSignUpMutationVariables
>;
export const SpendGroupsPublicDocument = gql`
  query SpendGroupsPublic($orgId: String!) {
    spendGroupsPublic(orgId: $orgId) {
      groups {
        id
        name
        seasons {
          id
          name
          isLinkEnabled
          paymentScheduleStatus
        }
      }
    }
  }
`;

/**
 * __useSpendGroupsPublicQuery__
 *
 * To run a query within a React component, call `useSpendGroupsPublicQuery` and pass it any options that fit your needs.
 * When your component renders, `useSpendGroupsPublicQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSpendGroupsPublicQuery({
 *   variables: {
 *      orgId: // value for 'orgId'
 *   },
 * });
 */
export function useSpendGroupsPublicQuery(
  baseOptions: Apollo.QueryHookOptions<
    SpendGroupsPublicQuery,
    SpendGroupsPublicQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SpendGroupsPublicQuery,
    SpendGroupsPublicQueryVariables
  >(SpendGroupsPublicDocument, options);
}
export function useSpendGroupsPublicLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SpendGroupsPublicQuery,
    SpendGroupsPublicQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SpendGroupsPublicQuery,
    SpendGroupsPublicQueryVariables
  >(SpendGroupsPublicDocument, options);
}
export type SpendGroupsPublicQueryHookResult = ReturnType<
  typeof useSpendGroupsPublicQuery
>;
export type SpendGroupsPublicLazyQueryHookResult = ReturnType<
  typeof useSpendGroupsPublicLazyQuery
>;
export type SpendGroupsPublicQueryResult = Apollo.QueryResult<
  SpendGroupsPublicQuery,
  SpendGroupsPublicQueryVariables
>;
export const SpendGroupsWithSeasonRosterDocument = gql`
  query SpendGroupsWithSeasonRoster($includeArchive: Boolean) {
    spendGroups(includeArchive: $includeArchive) {
      count
      groups {
        id
        name
        isArchived
        seasons {
          id
          name
          groupRoster {
            id
            roster {
              email
              id
              name
            }
          }
        }
      }
    }
  }
`;

/**
 * __useSpendGroupsWithSeasonRosterQuery__
 *
 * To run a query within a React component, call `useSpendGroupsWithSeasonRosterQuery` and pass it any options that fit your needs.
 * When your component renders, `useSpendGroupsWithSeasonRosterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSpendGroupsWithSeasonRosterQuery({
 *   variables: {
 *      includeArchive: // value for 'includeArchive'
 *   },
 * });
 */
export function useSpendGroupsWithSeasonRosterQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SpendGroupsWithSeasonRosterQuery,
    SpendGroupsWithSeasonRosterQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SpendGroupsWithSeasonRosterQuery,
    SpendGroupsWithSeasonRosterQueryVariables
  >(SpendGroupsWithSeasonRosterDocument, options);
}
export function useSpendGroupsWithSeasonRosterLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SpendGroupsWithSeasonRosterQuery,
    SpendGroupsWithSeasonRosterQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SpendGroupsWithSeasonRosterQuery,
    SpendGroupsWithSeasonRosterQueryVariables
  >(SpendGroupsWithSeasonRosterDocument, options);
}
export type SpendGroupsWithSeasonRosterQueryHookResult = ReturnType<
  typeof useSpendGroupsWithSeasonRosterQuery
>;
export type SpendGroupsWithSeasonRosterLazyQueryHookResult = ReturnType<
  typeof useSpendGroupsWithSeasonRosterLazyQuery
>;
export type SpendGroupsWithSeasonRosterQueryResult = Apollo.QueryResult<
  SpendGroupsWithSeasonRosterQuery,
  SpendGroupsWithSeasonRosterQueryVariables
>;
export const SpendGetGroupCounterpartiesDocument = gql`
  query SpendGetGroupCounterparties($groupOrOrgId: String!) {
    spendGetGroupCounterparties(groupOrOrgId: $groupOrOrgId) {
      counterparties {
        id
        name
        bankName
        routingNumber
        accountLastFour
      }
    }
  }
`;

/**
 * __useSpendGetGroupCounterpartiesQuery__
 *
 * To run a query within a React component, call `useSpendGetGroupCounterpartiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSpendGetGroupCounterpartiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSpendGetGroupCounterpartiesQuery({
 *   variables: {
 *      groupOrOrgId: // value for 'groupOrOrgId'
 *   },
 * });
 */
export function useSpendGetGroupCounterpartiesQuery(
  baseOptions: Apollo.QueryHookOptions<
    SpendGetGroupCounterpartiesQuery,
    SpendGetGroupCounterpartiesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SpendGetGroupCounterpartiesQuery,
    SpendGetGroupCounterpartiesQueryVariables
  >(SpendGetGroupCounterpartiesDocument, options);
}
export function useSpendGetGroupCounterpartiesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SpendGetGroupCounterpartiesQuery,
    SpendGetGroupCounterpartiesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SpendGetGroupCounterpartiesQuery,
    SpendGetGroupCounterpartiesQueryVariables
  >(SpendGetGroupCounterpartiesDocument, options);
}
export type SpendGetGroupCounterpartiesQueryHookResult = ReturnType<
  typeof useSpendGetGroupCounterpartiesQuery
>;
export type SpendGetGroupCounterpartiesLazyQueryHookResult = ReturnType<
  typeof useSpendGetGroupCounterpartiesLazyQuery
>;
export type SpendGetGroupCounterpartiesQueryResult = Apollo.QueryResult<
  SpendGetGroupCounterpartiesQuery,
  SpendGetGroupCounterpartiesQueryVariables
>;
export const SpendInvitesDocument = gql`
  query SpendInvites {
    spendInvites {
      count
      invites {
        createdAt
        email
        expiresAt
        firstName
        groupId
        id
        isUser
        lastName
        organizationId
        status
        type
        userId
      }
    }
  }
`;

/**
 * __useSpendInvitesQuery__
 *
 * To run a query within a React component, call `useSpendInvitesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSpendInvitesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSpendInvitesQuery({
 *   variables: {
 *   },
 * });
 */
export function useSpendInvitesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SpendInvitesQuery,
    SpendInvitesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SpendInvitesQuery, SpendInvitesQueryVariables>(
    SpendInvitesDocument,
    options
  );
}
export function useSpendInvitesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SpendInvitesQuery,
    SpendInvitesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SpendInvitesQuery, SpendInvitesQueryVariables>(
    SpendInvitesDocument,
    options
  );
}
export type SpendInvitesQueryHookResult = ReturnType<
  typeof useSpendInvitesQuery
>;
export type SpendInvitesLazyQueryHookResult = ReturnType<
  typeof useSpendInvitesLazyQuery
>;
export type SpendInvitesQueryResult = Apollo.QueryResult<
  SpendInvitesQuery,
  SpendInvitesQueryVariables
>;
export const SpendFilteredInvitesDocument = gql`
  query SpendFilteredInvites(
    $filterBy: InviteFilterEnum
    $filterValue: String
  ) {
    spendInvites(filterBy: $filterBy, filterValue: $filterValue) {
      invites {
        email
        userId
        firstName
        lastName
        status
        type
        id
        groupId
        isDeliverable
        isArchived
      }
    }
  }
`;

/**
 * __useSpendFilteredInvitesQuery__
 *
 * To run a query within a React component, call `useSpendFilteredInvitesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSpendFilteredInvitesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSpendFilteredInvitesQuery({
 *   variables: {
 *      filterBy: // value for 'filterBy'
 *      filterValue: // value for 'filterValue'
 *   },
 * });
 */
export function useSpendFilteredInvitesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SpendFilteredInvitesQuery,
    SpendFilteredInvitesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SpendFilteredInvitesQuery,
    SpendFilteredInvitesQueryVariables
  >(SpendFilteredInvitesDocument, options);
}
export function useSpendFilteredInvitesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SpendFilteredInvitesQuery,
    SpendFilteredInvitesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SpendFilteredInvitesQuery,
    SpendFilteredInvitesQueryVariables
  >(SpendFilteredInvitesDocument, options);
}
export type SpendFilteredInvitesQueryHookResult = ReturnType<
  typeof useSpendFilteredInvitesQuery
>;
export type SpendFilteredInvitesLazyQueryHookResult = ReturnType<
  typeof useSpendFilteredInvitesLazyQuery
>;
export type SpendFilteredInvitesQueryResult = Apollo.QueryResult<
  SpendFilteredInvitesQuery,
  SpendFilteredInvitesQueryVariables
>;
export const SpendInvitesFilteredDocument = gql`
  query SpendInvitesFiltered(
    $where: SpendInviteWhereInput
    $pagination: SpendPaginationInput
    $sort: SpendSortInput
    $includeGroup: Boolean = false
    $includeCards: Boolean = false
  ) {
    spendInvitesFiltered(where: $where, pagination: $pagination, sort: $sort) {
      count
      invites {
        id
        email
        userId
        firstName
        lastName
        status
        type
        expiresAt
        groupId
        group @include(if: $includeGroup) {
          id
          name
        }
        debitCard @include(if: $includeGroup)
        debitCards @include(if: $includeCards) {
          id
          lastFour
          status
        }
        isDeliverable
        isArchived
        isApprover
      }
    }
  }
`;

/**
 * __useSpendInvitesFilteredQuery__
 *
 * To run a query within a React component, call `useSpendInvitesFilteredQuery` and pass it any options that fit your needs.
 * When your component renders, `useSpendInvitesFilteredQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSpendInvitesFilteredQuery({
 *   variables: {
 *      where: // value for 'where'
 *      pagination: // value for 'pagination'
 *      sort: // value for 'sort'
 *      includeGroup: // value for 'includeGroup'
 *      includeCards: // value for 'includeCards'
 *   },
 * });
 */
export function useSpendInvitesFilteredQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SpendInvitesFilteredQuery,
    SpendInvitesFilteredQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SpendInvitesFilteredQuery,
    SpendInvitesFilteredQueryVariables
  >(SpendInvitesFilteredDocument, options);
}
export function useSpendInvitesFilteredLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SpendInvitesFilteredQuery,
    SpendInvitesFilteredQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SpendInvitesFilteredQuery,
    SpendInvitesFilteredQueryVariables
  >(SpendInvitesFilteredDocument, options);
}
export type SpendInvitesFilteredQueryHookResult = ReturnType<
  typeof useSpendInvitesFilteredQuery
>;
export type SpendInvitesFilteredLazyQueryHookResult = ReturnType<
  typeof useSpendInvitesFilteredLazyQuery
>;
export type SpendInvitesFilteredQueryResult = Apollo.QueryResult<
  SpendInvitesFilteredQuery,
  SpendInvitesFilteredQueryVariables
>;
export const SpendDebitCardInvitesDocument = gql`
  query SpendDebitCardInvites($groupId: String) {
    spendDebitCardInvites(groupId: $groupId) {
      count
      invites {
        firstName
        lastName
        userId
        groupId
        organizationId
      }
    }
  }
`;

/**
 * __useSpendDebitCardInvitesQuery__
 *
 * To run a query within a React component, call `useSpendDebitCardInvitesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSpendDebitCardInvitesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSpendDebitCardInvitesQuery({
 *   variables: {
 *      groupId: // value for 'groupId'
 *   },
 * });
 */
export function useSpendDebitCardInvitesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SpendDebitCardInvitesQuery,
    SpendDebitCardInvitesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SpendDebitCardInvitesQuery,
    SpendDebitCardInvitesQueryVariables
  >(SpendDebitCardInvitesDocument, options);
}
export function useSpendDebitCardInvitesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SpendDebitCardInvitesQuery,
    SpendDebitCardInvitesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SpendDebitCardInvitesQuery,
    SpendDebitCardInvitesQueryVariables
  >(SpendDebitCardInvitesDocument, options);
}
export type SpendDebitCardInvitesQueryHookResult = ReturnType<
  typeof useSpendDebitCardInvitesQuery
>;
export type SpendDebitCardInvitesLazyQueryHookResult = ReturnType<
  typeof useSpendDebitCardInvitesLazyQuery
>;
export type SpendDebitCardInvitesQueryResult = Apollo.QueryResult<
  SpendDebitCardInvitesQuery,
  SpendDebitCardInvitesQueryVariables
>;
export const SpendEmailInviteDocument = gql`
  query SpendEmailInvite($id: String!) {
    spendInvite(id: $id) {
      invites {
        id
        email
      }
    }
    me {
      id
      email
      firstName
      lastName
    }
  }
`;

/**
 * __useSpendEmailInviteQuery__
 *
 * To run a query within a React component, call `useSpendEmailInviteQuery` and pass it any options that fit your needs.
 * When your component renders, `useSpendEmailInviteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSpendEmailInviteQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSpendEmailInviteQuery(
  baseOptions: Apollo.QueryHookOptions<
    SpendEmailInviteQuery,
    SpendEmailInviteQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SpendEmailInviteQuery, SpendEmailInviteQueryVariables>(
    SpendEmailInviteDocument,
    options
  );
}
export function useSpendEmailInviteLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SpendEmailInviteQuery,
    SpendEmailInviteQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SpendEmailInviteQuery,
    SpendEmailInviteQueryVariables
  >(SpendEmailInviteDocument, options);
}
export type SpendEmailInviteQueryHookResult = ReturnType<
  typeof useSpendEmailInviteQuery
>;
export type SpendEmailInviteLazyQueryHookResult = ReturnType<
  typeof useSpendEmailInviteLazyQuery
>;
export type SpendEmailInviteQueryResult = Apollo.QueryResult<
  SpendEmailInviteQuery,
  SpendEmailInviteQueryVariables
>;
export const SpendInviteDocument = gql`
  query SpendInvite($spendInviteId: String!) {
    spendInvite(id: $spendInviteId) {
      count
      invites {
        id
        isArchived
        status
        expiresAt
        group {
          isArchived
        }
      }
    }
  }
`;

/**
 * __useSpendInviteQuery__
 *
 * To run a query within a React component, call `useSpendInviteQuery` and pass it any options that fit your needs.
 * When your component renders, `useSpendInviteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSpendInviteQuery({
 *   variables: {
 *      spendInviteId: // value for 'spendInviteId'
 *   },
 * });
 */
export function useSpendInviteQuery(
  baseOptions: Apollo.QueryHookOptions<
    SpendInviteQuery,
    SpendInviteQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SpendInviteQuery, SpendInviteQueryVariables>(
    SpendInviteDocument,
    options
  );
}
export function useSpendInviteLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SpendInviteQuery,
    SpendInviteQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SpendInviteQuery, SpendInviteQueryVariables>(
    SpendInviteDocument,
    options
  );
}
export type SpendInviteQueryHookResult = ReturnType<typeof useSpendInviteQuery>;
export type SpendInviteLazyQueryHookResult = ReturnType<
  typeof useSpendInviteLazyQuery
>;
export type SpendInviteQueryResult = Apollo.QueryResult<
  SpendInviteQuery,
  SpendInviteQueryVariables
>;
export const SpendPaginatedInvoicesDocument = gql`
  query SpendPaginatedInvoices(
    $pagination: SpendPaginationInput
    $filters: [SpendInvoiceFilter]
    $sort: SpendSortInput
  ) {
    spendPaginatedInvoices(
      pagination: $pagination
      filters: $filters
      sort: $sort
    ) {
      hasNext
      count
      invoices {
        amount
        description
        discountAmount
        groupRoster {
          roster {
            id
            name
            email
          }
          season {
            id
            name
          }
          group {
            id
            name
          }
          invite {
            status
          }
        }
        balanceDue
        dueDate
        paid
        createdAt
        status
        isRefunded
      }
    }
  }
`;

/**
 * __useSpendPaginatedInvoicesQuery__
 *
 * To run a query within a React component, call `useSpendPaginatedInvoicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSpendPaginatedInvoicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSpendPaginatedInvoicesQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      filters: // value for 'filters'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useSpendPaginatedInvoicesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SpendPaginatedInvoicesQuery,
    SpendPaginatedInvoicesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SpendPaginatedInvoicesQuery,
    SpendPaginatedInvoicesQueryVariables
  >(SpendPaginatedInvoicesDocument, options);
}
export function useSpendPaginatedInvoicesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SpendPaginatedInvoicesQuery,
    SpendPaginatedInvoicesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SpendPaginatedInvoicesQuery,
    SpendPaginatedInvoicesQueryVariables
  >(SpendPaginatedInvoicesDocument, options);
}
export type SpendPaginatedInvoicesQueryHookResult = ReturnType<
  typeof useSpendPaginatedInvoicesQuery
>;
export type SpendPaginatedInvoicesLazyQueryHookResult = ReturnType<
  typeof useSpendPaginatedInvoicesLazyQuery
>;
export type SpendPaginatedInvoicesQueryResult = Apollo.QueryResult<
  SpendPaginatedInvoicesQuery,
  SpendPaginatedInvoicesQueryVariables
>;
export const SpendInvoicesExportDocument = gql`
  query SpendInvoicesExport($limit: Int, $filters: [SpendInvoiceFilter]) {
    spendInvoicesExport(limit: $limit, filters: $filters) {
      content
      fileName
    }
  }
`;

/**
 * __useSpendInvoicesExportQuery__
 *
 * To run a query within a React component, call `useSpendInvoicesExportQuery` and pass it any options that fit your needs.
 * When your component renders, `useSpendInvoicesExportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSpendInvoicesExportQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useSpendInvoicesExportQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SpendInvoicesExportQuery,
    SpendInvoicesExportQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SpendInvoicesExportQuery,
    SpendInvoicesExportQueryVariables
  >(SpendInvoicesExportDocument, options);
}
export function useSpendInvoicesExportLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SpendInvoicesExportQuery,
    SpendInvoicesExportQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SpendInvoicesExportQuery,
    SpendInvoicesExportQueryVariables
  >(SpendInvoicesExportDocument, options);
}
export type SpendInvoicesExportQueryHookResult = ReturnType<
  typeof useSpendInvoicesExportQuery
>;
export type SpendInvoicesExportLazyQueryHookResult = ReturnType<
  typeof useSpendInvoicesExportLazyQuery
>;
export type SpendInvoicesExportQueryResult = Apollo.QueryResult<
  SpendInvoicesExportQuery,
  SpendInvoicesExportQueryVariables
>;
export const SpendInvoicesExportV2Document = gql`
  query SpendInvoicesExportV2($filters: [SpendInvoiceFilter]) {
    spendInvoicesExportV2(filters: $filters) {
      content
      fileName
    }
  }
`;

/**
 * __useSpendInvoicesExportV2Query__
 *
 * To run a query within a React component, call `useSpendInvoicesExportV2Query` and pass it any options that fit your needs.
 * When your component renders, `useSpendInvoicesExportV2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSpendInvoicesExportV2Query({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useSpendInvoicesExportV2Query(
  baseOptions?: Apollo.QueryHookOptions<
    SpendInvoicesExportV2Query,
    SpendInvoicesExportV2QueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SpendInvoicesExportV2Query,
    SpendInvoicesExportV2QueryVariables
  >(SpendInvoicesExportV2Document, options);
}
export function useSpendInvoicesExportV2LazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SpendInvoicesExportV2Query,
    SpendInvoicesExportV2QueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SpendInvoicesExportV2Query,
    SpendInvoicesExportV2QueryVariables
  >(SpendInvoicesExportV2Document, options);
}
export type SpendInvoicesExportV2QueryHookResult = ReturnType<
  typeof useSpendInvoicesExportV2Query
>;
export type SpendInvoicesExportV2LazyQueryHookResult = ReturnType<
  typeof useSpendInvoicesExportV2LazyQuery
>;
export type SpendInvoicesExportV2QueryResult = Apollo.QueryResult<
  SpendInvoicesExportV2Query,
  SpendInvoicesExportV2QueryVariables
>;
export const InvoicesDocument = gql`
  query Invoices($filterBy: InvoiceFilterEnum, $filterValue: String) {
    spendInvoices(filterBy: $filterBy, filterValue: $filterValue) {
      invoices {
        id
        amount
        paid
        paidDate
        dueDate
        balanceDue
        description
        optedIn
        isOptional
        groupRosterId
        budgetItemId
        lastNotifyId
        lastNotifyDate
        notificationAttempts
        paymentScheduleInvoiceId
        createdAt
        updatedAt
        isReconciled
        discountAmount
        paymentMethodId
        paymentMethodSource
        isAutoPayAuthorized
        groupRoster {
          id
          roster {
            id
            name
          }
          group {
            id
            name
          }
          season {
            id
            name
          }
        }
        budgetItem {
          description
          id
        }
        reconciledTransactions {
          id
          transactionId
          amount
        }
        transaction {
          id
          externalId
          invoiceId
          source
        }
      }
      count
    }
  }
`;

/**
 * __useInvoicesQuery__
 *
 * To run a query within a React component, call `useInvoicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvoicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvoicesQuery({
 *   variables: {
 *      filterBy: // value for 'filterBy'
 *      filterValue: // value for 'filterValue'
 *   },
 * });
 */
export function useInvoicesQuery(
  baseOptions?: Apollo.QueryHookOptions<InvoicesQuery, InvoicesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<InvoicesQuery, InvoicesQueryVariables>(
    InvoicesDocument,
    options
  );
}
export function useInvoicesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    InvoicesQuery,
    InvoicesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<InvoicesQuery, InvoicesQueryVariables>(
    InvoicesDocument,
    options
  );
}
export type InvoicesQueryHookResult = ReturnType<typeof useInvoicesQuery>;
export type InvoicesLazyQueryHookResult = ReturnType<
  typeof useInvoicesLazyQuery
>;
export type InvoicesQueryResult = Apollo.QueryResult<
  InvoicesQuery,
  InvoicesQueryVariables
>;
export const GetUsersPastDueInvoicesDocument = gql`
  query GetUsersPastDueInvoices {
    spendRosters {
      count
      rosters {
        id
        email
        name
        groupRosters {
          season {
            name
          }
          group {
            name
            id
          }
          invoices {
            id
            dueDate
            balanceDue
            amount
            paid
            description
            status
            isArchived
          }
          invite {
            firstName
            lastName
            email
            status
          }
        }
      }
    }
  }
`;

/**
 * __useGetUsersPastDueInvoicesQuery__
 *
 * To run a query within a React component, call `useGetUsersPastDueInvoicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersPastDueInvoicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersPastDueInvoicesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUsersPastDueInvoicesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetUsersPastDueInvoicesQuery,
    GetUsersPastDueInvoicesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetUsersPastDueInvoicesQuery,
    GetUsersPastDueInvoicesQueryVariables
  >(GetUsersPastDueInvoicesDocument, options);
}
export function useGetUsersPastDueInvoicesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUsersPastDueInvoicesQuery,
    GetUsersPastDueInvoicesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetUsersPastDueInvoicesQuery,
    GetUsersPastDueInvoicesQueryVariables
  >(GetUsersPastDueInvoicesDocument, options);
}
export type GetUsersPastDueInvoicesQueryHookResult = ReturnType<
  typeof useGetUsersPastDueInvoicesQuery
>;
export type GetUsersPastDueInvoicesLazyQueryHookResult = ReturnType<
  typeof useGetUsersPastDueInvoicesLazyQuery
>;
export type GetUsersPastDueInvoicesQueryResult = Apollo.QueryResult<
  GetUsersPastDueInvoicesQuery,
  GetUsersPastDueInvoicesQueryVariables
>;
export const GetInvoicesWithPaymentIdDocument = gql`
  query getInvoicesWithPaymentId(
    $filterValue: String
    $filterBy: GroupRosterFilterEnum
  ) {
    spendGroupRosters(filterValue: $filterValue, filterBy: $filterBy) {
      count
      groupRosters {
        invoices {
          paymentMethodId
          id
          paid
          status
        }
      }
    }
  }
`;

/**
 * __useGetInvoicesWithPaymentIdQuery__
 *
 * To run a query within a React component, call `useGetInvoicesWithPaymentIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInvoicesWithPaymentIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInvoicesWithPaymentIdQuery({
 *   variables: {
 *      filterValue: // value for 'filterValue'
 *      filterBy: // value for 'filterBy'
 *   },
 * });
 */
export function useGetInvoicesWithPaymentIdQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetInvoicesWithPaymentIdQuery,
    GetInvoicesWithPaymentIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetInvoicesWithPaymentIdQuery,
    GetInvoicesWithPaymentIdQueryVariables
  >(GetInvoicesWithPaymentIdDocument, options);
}
export function useGetInvoicesWithPaymentIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetInvoicesWithPaymentIdQuery,
    GetInvoicesWithPaymentIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetInvoicesWithPaymentIdQuery,
    GetInvoicesWithPaymentIdQueryVariables
  >(GetInvoicesWithPaymentIdDocument, options);
}
export type GetInvoicesWithPaymentIdQueryHookResult = ReturnType<
  typeof useGetInvoicesWithPaymentIdQuery
>;
export type GetInvoicesWithPaymentIdLazyQueryHookResult = ReturnType<
  typeof useGetInvoicesWithPaymentIdLazyQuery
>;
export type GetInvoicesWithPaymentIdQueryResult = Apollo.QueryResult<
  GetInvoicesWithPaymentIdQuery,
  GetInvoicesWithPaymentIdQueryVariables
>;
export const SpendInvoicesDocument = gql`
  query SpendInvoices(
    $filterValue: String
    $filterBy: InvoiceFilterEnum
    $parentSignUp: Boolean
  ) {
    spendInvoices(
      filterValue: $filterValue
      filterBy: $filterBy
      parentSignUp: $parentSignUp
    ) {
      count
      invoices {
        description
        amount
        optedIn
        isOptional
        groupRosterId
        dueDate
        balanceDue
        id
        isArchived
        paid
        creditMemos {
          id
          title
          note
          dateToApply
          creditApplied
          creditAmount
          isArchived
        }
      }
    }
  }
`;

/**
 * __useSpendInvoicesQuery__
 *
 * To run a query within a React component, call `useSpendInvoicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSpendInvoicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSpendInvoicesQuery({
 *   variables: {
 *      filterValue: // value for 'filterValue'
 *      filterBy: // value for 'filterBy'
 *      parentSignUp: // value for 'parentSignUp'
 *   },
 * });
 */
export function useSpendInvoicesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SpendInvoicesQuery,
    SpendInvoicesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SpendInvoicesQuery, SpendInvoicesQueryVariables>(
    SpendInvoicesDocument,
    options
  );
}
export function useSpendInvoicesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SpendInvoicesQuery,
    SpendInvoicesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SpendInvoicesQuery, SpendInvoicesQueryVariables>(
    SpendInvoicesDocument,
    options
  );
}
export type SpendInvoicesQueryHookResult = ReturnType<
  typeof useSpendInvoicesQuery
>;
export type SpendInvoicesLazyQueryHookResult = ReturnType<
  typeof useSpendInvoicesLazyQuery
>;
export type SpendInvoicesQueryResult = Apollo.QueryResult<
  SpendInvoicesQuery,
  SpendInvoicesQueryVariables
>;
export const SpendInvoiceHistoryDocument = gql`
  query SpendInvoiceHistory($input: SpendInvoiceHistoryInput) {
    spendInvoiceHistory(input: $input) {
      success
    }
  }
`;

/**
 * __useSpendInvoiceHistoryQuery__
 *
 * To run a query within a React component, call `useSpendInvoiceHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useSpendInvoiceHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSpendInvoiceHistoryQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSpendInvoiceHistoryQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SpendInvoiceHistoryQuery,
    SpendInvoiceHistoryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SpendInvoiceHistoryQuery,
    SpendInvoiceHistoryQueryVariables
  >(SpendInvoiceHistoryDocument, options);
}
export function useSpendInvoiceHistoryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SpendInvoiceHistoryQuery,
    SpendInvoiceHistoryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SpendInvoiceHistoryQuery,
    SpendInvoiceHistoryQueryVariables
  >(SpendInvoiceHistoryDocument, options);
}
export type SpendInvoiceHistoryQueryHookResult = ReturnType<
  typeof useSpendInvoiceHistoryQuery
>;
export type SpendInvoiceHistoryLazyQueryHookResult = ReturnType<
  typeof useSpendInvoiceHistoryLazyQuery
>;
export type SpendInvoiceHistoryQueryResult = Apollo.QueryResult<
  SpendInvoiceHistoryQuery,
  SpendInvoiceHistoryQueryVariables
>;
export const SpendInvoiceByIdDocument = gql`
  query SpendInvoiceById($id: String!) {
    spendInvoiceById(id: $id) {
      amount
      balanceDue
      budgetItemId
      createdAt
      description
      dueDate
      feesAmount
      groupRosterId
      id
      isArchived
      isAutoPayAuthorized
      isOptional
      isReconciled
      isRefunded
      lastNotifyId
      lastNotifyDate
      note
      notificationAttempts
      optedIn
      paid
      paidDate
      paymentMethodId
      paymentMethodSource
      paymentScheduleInvoiceId
      refundDate
      status
      updatedAt
      groupRoster {
        group {
          name
        }
        id
        roster {
          name
        }
        seasonId
        season {
          id
          name
        }
      }
    }
  }
`;

/**
 * __useSpendInvoiceByIdQuery__
 *
 * To run a query within a React component, call `useSpendInvoiceByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useSpendInvoiceByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSpendInvoiceByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSpendInvoiceByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    SpendInvoiceByIdQuery,
    SpendInvoiceByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SpendInvoiceByIdQuery, SpendInvoiceByIdQueryVariables>(
    SpendInvoiceByIdDocument,
    options
  );
}
export function useSpendInvoiceByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SpendInvoiceByIdQuery,
    SpendInvoiceByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SpendInvoiceByIdQuery,
    SpendInvoiceByIdQueryVariables
  >(SpendInvoiceByIdDocument, options);
}
export type SpendInvoiceByIdQueryHookResult = ReturnType<
  typeof useSpendInvoiceByIdQuery
>;
export type SpendInvoiceByIdLazyQueryHookResult = ReturnType<
  typeof useSpendInvoiceByIdLazyQuery
>;
export type SpendInvoiceByIdQueryResult = Apollo.QueryResult<
  SpendInvoiceByIdQuery,
  SpendInvoiceByIdQueryVariables
>;
export const SpendPaginatedInvoicesForAutoPayDocument = gql`
  query SpendPaginatedInvoicesForAutoPay {
    spendPaginatedInvoices {
      invoices {
        isAutoPayAuthorized
        groupRosterId
        paid
        balanceDue
        authorizedAt
        optedOutAt
        optedIn
        isOptional
        groupRoster {
          joinedAt
          roster {
            name
          }
          settings {
            isAutoPayAuthorized
          }
          invite {
            status
            isUser
          }
        }
      }
    }
  }
`;

/**
 * __useSpendPaginatedInvoicesForAutoPayQuery__
 *
 * To run a query within a React component, call `useSpendPaginatedInvoicesForAutoPayQuery` and pass it any options that fit your needs.
 * When your component renders, `useSpendPaginatedInvoicesForAutoPayQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSpendPaginatedInvoicesForAutoPayQuery({
 *   variables: {
 *   },
 * });
 */
export function useSpendPaginatedInvoicesForAutoPayQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SpendPaginatedInvoicesForAutoPayQuery,
    SpendPaginatedInvoicesForAutoPayQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SpendPaginatedInvoicesForAutoPayQuery,
    SpendPaginatedInvoicesForAutoPayQueryVariables
  >(SpendPaginatedInvoicesForAutoPayDocument, options);
}
export function useSpendPaginatedInvoicesForAutoPayLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SpendPaginatedInvoicesForAutoPayQuery,
    SpendPaginatedInvoicesForAutoPayQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SpendPaginatedInvoicesForAutoPayQuery,
    SpendPaginatedInvoicesForAutoPayQueryVariables
  >(SpendPaginatedInvoicesForAutoPayDocument, options);
}
export type SpendPaginatedInvoicesForAutoPayQueryHookResult = ReturnType<
  typeof useSpendPaginatedInvoicesForAutoPayQuery
>;
export type SpendPaginatedInvoicesForAutoPayLazyQueryHookResult = ReturnType<
  typeof useSpendPaginatedInvoicesForAutoPayLazyQuery
>;
export type SpendPaginatedInvoicesForAutoPayQueryResult = Apollo.QueryResult<
  SpendPaginatedInvoicesForAutoPayQuery,
  SpendPaginatedInvoicesForAutoPayQueryVariables
>;
export const SpendGuardianInvoicesDocument = gql`
  query SpendGuardianInvoices($filters: [SpendGuardianInvoiceFilter]) {
    spendGuardianInvoices(filters: $filters) {
      count
      invoices {
        amount
        authorizedAt
        balanceDue
        description
        discountAmount
        dueDate
        id
        isAutoPayAuthorized
        isAutoPayStopped
        isOptional
        isRefunded
        optedIn
        paid
        paidDate
        paymentMethodId
        paymentMethodSource
        refundDate
        status
        rosterId
        rosterName
        groupName
        seasonName
        groupId
        groupRosterId
        seasonId
        optedOutAt
        isArchived
        note
        creditMemos {
          id
          title
          note
          dateToApply
          creditApplied
          creditAmount
          isArchived
        }
      }
    }
  }
`;

/**
 * __useSpendGuardianInvoicesQuery__
 *
 * To run a query within a React component, call `useSpendGuardianInvoicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSpendGuardianInvoicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSpendGuardianInvoicesQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useSpendGuardianInvoicesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SpendGuardianInvoicesQuery,
    SpendGuardianInvoicesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SpendGuardianInvoicesQuery,
    SpendGuardianInvoicesQueryVariables
  >(SpendGuardianInvoicesDocument, options);
}
export function useSpendGuardianInvoicesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SpendGuardianInvoicesQuery,
    SpendGuardianInvoicesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SpendGuardianInvoicesQuery,
    SpendGuardianInvoicesQueryVariables
  >(SpendGuardianInvoicesDocument, options);
}
export type SpendGuardianInvoicesQueryHookResult = ReturnType<
  typeof useSpendGuardianInvoicesQuery
>;
export type SpendGuardianInvoicesLazyQueryHookResult = ReturnType<
  typeof useSpendGuardianInvoicesLazyQuery
>;
export type SpendGuardianInvoicesQueryResult = Apollo.QueryResult<
  SpendGuardianInvoicesQuery,
  SpendGuardianInvoicesQueryVariables
>;
export const SpendPastDueInvoicesDocument = gql`
  query SpendPastDueInvoices(
    $pagination: SpendPaginationInput
    $interval: SpendPastDueInterval
    $sort: SpendPastDueSort
  ) {
    spendPastDueInvoices(
      pagination: $pagination
      interval: $interval
      sort: $sort
    ) {
      count
      invoices {
        groupName
        email
        name
        groupId
        status
        rosterId
        amount
        seasonName
        seasonId
        description
        dueDate
        guardianStatus
        discountAmount
        creditMemos {
          creditApplied
        }
      }
    }
  }
`;

/**
 * __useSpendPastDueInvoicesQuery__
 *
 * To run a query within a React component, call `useSpendPastDueInvoicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSpendPastDueInvoicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSpendPastDueInvoicesQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      interval: // value for 'interval'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useSpendPastDueInvoicesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SpendPastDueInvoicesQuery,
    SpendPastDueInvoicesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SpendPastDueInvoicesQuery,
    SpendPastDueInvoicesQueryVariables
  >(SpendPastDueInvoicesDocument, options);
}
export function useSpendPastDueInvoicesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SpendPastDueInvoicesQuery,
    SpendPastDueInvoicesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SpendPastDueInvoicesQuery,
    SpendPastDueInvoicesQueryVariables
  >(SpendPastDueInvoicesDocument, options);
}
export type SpendPastDueInvoicesQueryHookResult = ReturnType<
  typeof useSpendPastDueInvoicesQuery
>;
export type SpendPastDueInvoicesLazyQueryHookResult = ReturnType<
  typeof useSpendPastDueInvoicesLazyQuery
>;
export type SpendPastDueInvoicesQueryResult = Apollo.QueryResult<
  SpendPastDueInvoicesQuery,
  SpendPastDueInvoicesQueryVariables
>;
export const SpendGroupsOverviewDashboardDocument = gql`
  query SpendGroupsOverviewDashboard($pagination: SpendPaginationInput) {
    spendGroupsOverviewDashboard(pagination: $pagination) {
      overview {
        groupId
        name
        seasonName
        seasonStartAt
        seasonEndAt
        balance
        collected
        pastDue
        sharedAccount
        cards {
          expiration
          id
          lastFour
          status
        }
      }
      count
    }
  }
`;

/**
 * __useSpendGroupsOverviewDashboardQuery__
 *
 * To run a query within a React component, call `useSpendGroupsOverviewDashboardQuery` and pass it any options that fit your needs.
 * When your component renders, `useSpendGroupsOverviewDashboardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSpendGroupsOverviewDashboardQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useSpendGroupsOverviewDashboardQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SpendGroupsOverviewDashboardQuery,
    SpendGroupsOverviewDashboardQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SpendGroupsOverviewDashboardQuery,
    SpendGroupsOverviewDashboardQueryVariables
  >(SpendGroupsOverviewDashboardDocument, options);
}
export function useSpendGroupsOverviewDashboardLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SpendGroupsOverviewDashboardQuery,
    SpendGroupsOverviewDashboardQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SpendGroupsOverviewDashboardQuery,
    SpendGroupsOverviewDashboardQueryVariables
  >(SpendGroupsOverviewDashboardDocument, options);
}
export type SpendGroupsOverviewDashboardQueryHookResult = ReturnType<
  typeof useSpendGroupsOverviewDashboardQuery
>;
export type SpendGroupsOverviewDashboardLazyQueryHookResult = ReturnType<
  typeof useSpendGroupsOverviewDashboardLazyQuery
>;
export type SpendGroupsOverviewDashboardQueryResult = Apollo.QueryResult<
  SpendGroupsOverviewDashboardQuery,
  SpendGroupsOverviewDashboardQueryVariables
>;
export const SpendOrganizationDocument = gql`
  query SpendOrganization {
    spendOrganization {
      email
      id
      isVerified
      legalName
      nickname
      logo
      orgId
      phone
      userId
      website
      hasLinkedAccount
      externalTransferOutEnabled
      owner {
        email
        firstName
        lastName
        id
      }
      debitCards {
        activated
        assigned
        shipped
        total
      }
      city
      state
      street
      street2
      zip
      cardBaseFee
      cardPercent
      spendBaseFee
      spendPercent
      achBaseFee
      achPercent
      legacyAccountId
      budgets {
        category {
          id
          type
          createdAt
          isDefault
          isHidden
          name
          organizationId
        }
        createdAt
        description
        id
        isDefault
        targetAmount
        targetDateAt
        updatedAt
      }
      groups {
        name
        id
        isArchived
        hasAccount
        sharedAccount
      }
    }
  }
`;

/**
 * __useSpendOrganizationQuery__
 *
 * To run a query within a React component, call `useSpendOrganizationQuery` and pass it any options that fit your needs.
 * When your component renders, `useSpendOrganizationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSpendOrganizationQuery({
 *   variables: {
 *   },
 * });
 */
export function useSpendOrganizationQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SpendOrganizationQuery,
    SpendOrganizationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SpendOrganizationQuery,
    SpendOrganizationQueryVariables
  >(SpendOrganizationDocument, options);
}
export function useSpendOrganizationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SpendOrganizationQuery,
    SpendOrganizationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SpendOrganizationQuery,
    SpendOrganizationQueryVariables
  >(SpendOrganizationDocument, options);
}
export type SpendOrganizationQueryHookResult = ReturnType<
  typeof useSpendOrganizationQuery
>;
export type SpendOrganizationLazyQueryHookResult = ReturnType<
  typeof useSpendOrganizationLazyQuery
>;
export type SpendOrganizationQueryResult = Apollo.QueryResult<
  SpendOrganizationQuery,
  SpendOrganizationQueryVariables
>;
export const SpendOrganizationDebitCardsDocument = gql`
  query SpendOrganizationDebitCards {
    spendOrganizationDebitCards {
      cards {
        expiration
        id
        lastFour
        status
        userId
      }
      count
    }
  }
`;

/**
 * __useSpendOrganizationDebitCardsQuery__
 *
 * To run a query within a React component, call `useSpendOrganizationDebitCardsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSpendOrganizationDebitCardsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSpendOrganizationDebitCardsQuery({
 *   variables: {
 *   },
 * });
 */
export function useSpendOrganizationDebitCardsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SpendOrganizationDebitCardsQuery,
    SpendOrganizationDebitCardsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SpendOrganizationDebitCardsQuery,
    SpendOrganizationDebitCardsQueryVariables
  >(SpendOrganizationDebitCardsDocument, options);
}
export function useSpendOrganizationDebitCardsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SpendOrganizationDebitCardsQuery,
    SpendOrganizationDebitCardsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SpendOrganizationDebitCardsQuery,
    SpendOrganizationDebitCardsQueryVariables
  >(SpendOrganizationDebitCardsDocument, options);
}
export type SpendOrganizationDebitCardsQueryHookResult = ReturnType<
  typeof useSpendOrganizationDebitCardsQuery
>;
export type SpendOrganizationDebitCardsLazyQueryHookResult = ReturnType<
  typeof useSpendOrganizationDebitCardsLazyQuery
>;
export type SpendOrganizationDebitCardsQueryResult = Apollo.QueryResult<
  SpendOrganizationDebitCardsQuery,
  SpendOrganizationDebitCardsQueryVariables
>;
export const SpendOrganizationAccountsDocument = gql`
  query SpendOrganizationAccounts {
    spendOrganizationAccounts {
      accounts {
        available
        balance
        id
        name
        status
        type
        groupStatus
        groupId
      }
      count
      groupsBalance {
        active
        archived
      }
      totalBalance
    }
  }
`;

/**
 * __useSpendOrganizationAccountsQuery__
 *
 * To run a query within a React component, call `useSpendOrganizationAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSpendOrganizationAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSpendOrganizationAccountsQuery({
 *   variables: {
 *   },
 * });
 */
export function useSpendOrganizationAccountsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SpendOrganizationAccountsQuery,
    SpendOrganizationAccountsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SpendOrganizationAccountsQuery,
    SpendOrganizationAccountsQueryVariables
  >(SpendOrganizationAccountsDocument, options);
}
export function useSpendOrganizationAccountsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SpendOrganizationAccountsQuery,
    SpendOrganizationAccountsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SpendOrganizationAccountsQuery,
    SpendOrganizationAccountsQueryVariables
  >(SpendOrganizationAccountsDocument, options);
}
export type SpendOrganizationAccountsQueryHookResult = ReturnType<
  typeof useSpendOrganizationAccountsQuery
>;
export type SpendOrganizationAccountsLazyQueryHookResult = ReturnType<
  typeof useSpendOrganizationAccountsLazyQuery
>;
export type SpendOrganizationAccountsQueryResult = Apollo.QueryResult<
  SpendOrganizationAccountsQuery,
  SpendOrganizationAccountsQueryVariables
>;
export const SpendOrganizationBankAccountsDocument = gql`
  query SpendOrganizationBankAccounts {
    spendOrganizationBankAccounts {
      externalAccounts {
        accountId
        availableBalance
        currentBalance
        mask
        name
        officialName
        subtype
        type
        institution {
          id
          name
        }
      }
      status
    }
  }
`;

/**
 * __useSpendOrganizationBankAccountsQuery__
 *
 * To run a query within a React component, call `useSpendOrganizationBankAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSpendOrganizationBankAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSpendOrganizationBankAccountsQuery({
 *   variables: {
 *   },
 * });
 */
export function useSpendOrganizationBankAccountsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SpendOrganizationBankAccountsQuery,
    SpendOrganizationBankAccountsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SpendOrganizationBankAccountsQuery,
    SpendOrganizationBankAccountsQueryVariables
  >(SpendOrganizationBankAccountsDocument, options);
}
export function useSpendOrganizationBankAccountsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SpendOrganizationBankAccountsQuery,
    SpendOrganizationBankAccountsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SpendOrganizationBankAccountsQuery,
    SpendOrganizationBankAccountsQueryVariables
  >(SpendOrganizationBankAccountsDocument, options);
}
export type SpendOrganizationBankAccountsQueryHookResult = ReturnType<
  typeof useSpendOrganizationBankAccountsQuery
>;
export type SpendOrganizationBankAccountsLazyQueryHookResult = ReturnType<
  typeof useSpendOrganizationBankAccountsLazyQuery
>;
export type SpendOrganizationBankAccountsQueryResult = Apollo.QueryResult<
  SpendOrganizationBankAccountsQuery,
  SpendOrganizationBankAccountsQueryVariables
>;
export const SpendOrgSettingsDocument = gql`
  query SpendOrgSettings {
    spendSettings {
      enableGroupBanks
      enableProgramAgreements
      notifyBankActivityAdmins
      notifyDueFrequencies
      notifyFailedAchAdmins
      notifyPastDueAdmins
      notifyPastDueNonUsers
      notifyUpcomingNonUsers
      pastDueFrequency
      signUpAgreement {
        content
        name
        createdAt
      }
      signUpLink
      internalTransferApproval
      internalTransferLimit
      externalTransferApproval
      externalTransferLimit
      sendCheckApproval
      sendCheckLimit
      debitCardApproval
    }
  }
`;

/**
 * __useSpendOrgSettingsQuery__
 *
 * To run a query within a React component, call `useSpendOrgSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSpendOrgSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSpendOrgSettingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useSpendOrgSettingsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SpendOrgSettingsQuery,
    SpendOrgSettingsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SpendOrgSettingsQuery, SpendOrgSettingsQueryVariables>(
    SpendOrgSettingsDocument,
    options
  );
}
export function useSpendOrgSettingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SpendOrgSettingsQuery,
    SpendOrgSettingsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SpendOrgSettingsQuery,
    SpendOrgSettingsQueryVariables
  >(SpendOrgSettingsDocument, options);
}
export type SpendOrgSettingsQueryHookResult = ReturnType<
  typeof useSpendOrgSettingsQuery
>;
export type SpendOrgSettingsLazyQueryHookResult = ReturnType<
  typeof useSpendOrgSettingsLazyQuery
>;
export type SpendOrgSettingsQueryResult = Apollo.QueryResult<
  SpendOrgSettingsQuery,
  SpendOrgSettingsQueryVariables
>;
export const SpendAccountExportDocument = gql`
  query SpendAccountExport($status: String!) {
    spendAccountExport(status: $status) {
      content
      fileName
    }
  }
`;

/**
 * __useSpendAccountExportQuery__
 *
 * To run a query within a React component, call `useSpendAccountExportQuery` and pass it any options that fit your needs.
 * When your component renders, `useSpendAccountExportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSpendAccountExportQuery({
 *   variables: {
 *      status: // value for 'status'
 *   },
 * });
 */
export function useSpendAccountExportQuery(
  baseOptions: Apollo.QueryHookOptions<
    SpendAccountExportQuery,
    SpendAccountExportQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SpendAccountExportQuery,
    SpendAccountExportQueryVariables
  >(SpendAccountExportDocument, options);
}
export function useSpendAccountExportLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SpendAccountExportQuery,
    SpendAccountExportQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SpendAccountExportQuery,
    SpendAccountExportQueryVariables
  >(SpendAccountExportDocument, options);
}
export type SpendAccountExportQueryHookResult = ReturnType<
  typeof useSpendAccountExportQuery
>;
export type SpendAccountExportLazyQueryHookResult = ReturnType<
  typeof useSpendAccountExportLazyQuery
>;
export type SpendAccountExportQueryResult = Apollo.QueryResult<
  SpendAccountExportQuery,
  SpendAccountExportQueryVariables
>;
export const SpendOrganizationPayeesDocument = gql`
  query SpendOrganizationPayees($orgId: String!) {
    spendOrganizationPayees(orgId: $orgId) {
      payees {
        id
        name
        address1
        address2
        city
        state
        zipCode
      }
    }
  }
`;

/**
 * __useSpendOrganizationPayeesQuery__
 *
 * To run a query within a React component, call `useSpendOrganizationPayeesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSpendOrganizationPayeesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSpendOrganizationPayeesQuery({
 *   variables: {
 *      orgId: // value for 'orgId'
 *   },
 * });
 */
export function useSpendOrganizationPayeesQuery(
  baseOptions: Apollo.QueryHookOptions<
    SpendOrganizationPayeesQuery,
    SpendOrganizationPayeesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SpendOrganizationPayeesQuery,
    SpendOrganizationPayeesQueryVariables
  >(SpendOrganizationPayeesDocument, options);
}
export function useSpendOrganizationPayeesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SpendOrganizationPayeesQuery,
    SpendOrganizationPayeesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SpendOrganizationPayeesQuery,
    SpendOrganizationPayeesQueryVariables
  >(SpendOrganizationPayeesDocument, options);
}
export type SpendOrganizationPayeesQueryHookResult = ReturnType<
  typeof useSpendOrganizationPayeesQuery
>;
export type SpendOrganizationPayeesLazyQueryHookResult = ReturnType<
  typeof useSpendOrganizationPayeesLazyQuery
>;
export type SpendOrganizationPayeesQueryResult = Apollo.QueryResult<
  SpendOrganizationPayeesQuery,
  SpendOrganizationPayeesQueryVariables
>;
export const SpendOrganizationAccountLimitsDocument = gql`
  query SpendOrganizationAccountLimits($groupId: String) {
    spendOrganizationAccountLimits(groupId: $groupId) {
      ach {
        limits {
          dailyDebit
          dailyCredit
          monthlyDebit
          monthlyCredit
        }
      }
      bookTransfer {
        limits {
          dailyDebit
          dailyCredit
          monthlyDebit
          monthlyCredit
        }
      }
      card {
        limits {
          dailyWithdrawal
          dailyDeposit
          dailyPurchase
          dailyCardTransaction
        }
      }
      checkDeposit {
        limits {
          daily
          monthly
        }
      }
      wire {
        limits {
          dailyTransfer
          monthlyTransfer
          dailyTransferSoft
          monthlyTransferSoft
        }
      }
      checkPayment {
        limits {
          dailySent
          monthlySent
        }
      }
    }
  }
`;

/**
 * __useSpendOrganizationAccountLimitsQuery__
 *
 * To run a query within a React component, call `useSpendOrganizationAccountLimitsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSpendOrganizationAccountLimitsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSpendOrganizationAccountLimitsQuery({
 *   variables: {
 *      groupId: // value for 'groupId'
 *   },
 * });
 */
export function useSpendOrganizationAccountLimitsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SpendOrganizationAccountLimitsQuery,
    SpendOrganizationAccountLimitsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SpendOrganizationAccountLimitsQuery,
    SpendOrganizationAccountLimitsQueryVariables
  >(SpendOrganizationAccountLimitsDocument, options);
}
export function useSpendOrganizationAccountLimitsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SpendOrganizationAccountLimitsQuery,
    SpendOrganizationAccountLimitsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SpendOrganizationAccountLimitsQuery,
    SpendOrganizationAccountLimitsQueryVariables
  >(SpendOrganizationAccountLimitsDocument, options);
}
export type SpendOrganizationAccountLimitsQueryHookResult = ReturnType<
  typeof useSpendOrganizationAccountLimitsQuery
>;
export type SpendOrganizationAccountLimitsLazyQueryHookResult = ReturnType<
  typeof useSpendOrganizationAccountLimitsLazyQuery
>;
export type SpendOrganizationAccountLimitsQueryResult = Apollo.QueryResult<
  SpendOrganizationAccountLimitsQuery,
  SpendOrganizationAccountLimitsQueryVariables
>;
export const SpendGetOrganizationCounterpartiesDocument = gql`
  query SpendGetOrganizationCounterparties($groupOrOrgId: String!) {
    spendGetOrganizationCounterparties(groupOrOrgId: $groupOrOrgId) {
      counterparties {
        id
        name
        bankName
        accountLastFour
        routingNumber
      }
    }
  }
`;

/**
 * __useSpendGetOrganizationCounterpartiesQuery__
 *
 * To run a query within a React component, call `useSpendGetOrganizationCounterpartiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSpendGetOrganizationCounterpartiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSpendGetOrganizationCounterpartiesQuery({
 *   variables: {
 *      groupOrOrgId: // value for 'groupOrOrgId'
 *   },
 * });
 */
export function useSpendGetOrganizationCounterpartiesQuery(
  baseOptions: Apollo.QueryHookOptions<
    SpendGetOrganizationCounterpartiesQuery,
    SpendGetOrganizationCounterpartiesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SpendGetOrganizationCounterpartiesQuery,
    SpendGetOrganizationCounterpartiesQueryVariables
  >(SpendGetOrganizationCounterpartiesDocument, options);
}
export function useSpendGetOrganizationCounterpartiesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SpendGetOrganizationCounterpartiesQuery,
    SpendGetOrganizationCounterpartiesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SpendGetOrganizationCounterpartiesQuery,
    SpendGetOrganizationCounterpartiesQueryVariables
  >(SpendGetOrganizationCounterpartiesDocument, options);
}
export type SpendGetOrganizationCounterpartiesQueryHookResult = ReturnType<
  typeof useSpendGetOrganizationCounterpartiesQuery
>;
export type SpendGetOrganizationCounterpartiesLazyQueryHookResult = ReturnType<
  typeof useSpendGetOrganizationCounterpartiesLazyQuery
>;
export type SpendGetOrganizationCounterpartiesQueryResult = Apollo.QueryResult<
  SpendGetOrganizationCounterpartiesQuery,
  SpendGetOrganizationCounterpartiesQueryVariables
>;
export const SpendPaymentScheduleByIdDocument = gql`
  query SpendPaymentScheduleById($spendPaymentScheduleByIdId: String!) {
    spendPaymentScheduleById(id: $spendPaymentScheduleByIdId) {
      amountDue
      budgetItemId
      description
      dueDate
      groupId
      id
      isOptional
      note
      seasonId
      status
    }
  }
`;

/**
 * __useSpendPaymentScheduleByIdQuery__
 *
 * To run a query within a React component, call `useSpendPaymentScheduleByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useSpendPaymentScheduleByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSpendPaymentScheduleByIdQuery({
 *   variables: {
 *      spendPaymentScheduleByIdId: // value for 'spendPaymentScheduleByIdId'
 *   },
 * });
 */
export function useSpendPaymentScheduleByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    SpendPaymentScheduleByIdQuery,
    SpendPaymentScheduleByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SpendPaymentScheduleByIdQuery,
    SpendPaymentScheduleByIdQueryVariables
  >(SpendPaymentScheduleByIdDocument, options);
}
export function useSpendPaymentScheduleByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SpendPaymentScheduleByIdQuery,
    SpendPaymentScheduleByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SpendPaymentScheduleByIdQuery,
    SpendPaymentScheduleByIdQueryVariables
  >(SpendPaymentScheduleByIdDocument, options);
}
export type SpendPaymentScheduleByIdQueryHookResult = ReturnType<
  typeof useSpendPaymentScheduleByIdQuery
>;
export type SpendPaymentScheduleByIdLazyQueryHookResult = ReturnType<
  typeof useSpendPaymentScheduleByIdLazyQuery
>;
export type SpendPaymentScheduleByIdQueryResult = Apollo.QueryResult<
  SpendPaymentScheduleByIdQuery,
  SpendPaymentScheduleByIdQueryVariables
>;
export const SpendPaymentSchedulesDocument = gql`
  query SpendPaymentSchedules {
    spendPaymentSchedules {
      amountDue
      budgetItemId
      description
      dueDate
      groupId
      id
      isOptional
      note
      seasonId
      status
    }
  }
`;

/**
 * __useSpendPaymentSchedulesQuery__
 *
 * To run a query within a React component, call `useSpendPaymentSchedulesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSpendPaymentSchedulesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSpendPaymentSchedulesQuery({
 *   variables: {
 *   },
 * });
 */
export function useSpendPaymentSchedulesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SpendPaymentSchedulesQuery,
    SpendPaymentSchedulesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SpendPaymentSchedulesQuery,
    SpendPaymentSchedulesQueryVariables
  >(SpendPaymentSchedulesDocument, options);
}
export function useSpendPaymentSchedulesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SpendPaymentSchedulesQuery,
    SpendPaymentSchedulesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SpendPaymentSchedulesQuery,
    SpendPaymentSchedulesQueryVariables
  >(SpendPaymentSchedulesDocument, options);
}
export type SpendPaymentSchedulesQueryHookResult = ReturnType<
  typeof useSpendPaymentSchedulesQuery
>;
export type SpendPaymentSchedulesLazyQueryHookResult = ReturnType<
  typeof useSpendPaymentSchedulesLazyQuery
>;
export type SpendPaymentSchedulesQueryResult = Apollo.QueryResult<
  SpendPaymentSchedulesQuery,
  SpendPaymentSchedulesQueryVariables
>;
export const SpendPaymentSchedulesByGroupOrSeasonDocument = gql`
  query SpendPaymentSchedulesByGroupOrSeason($groupIdOrSeasonId: String) {
    spendPaymentSchedules(groupIdOrSeasonId: $groupIdOrSeasonId) {
      id
      amountDue
      dueDate
      description
      status
      note
      isOptional
      budgetItemId
      seasonId
      groupId
      budgetItem {
        id
        description
        targetAmount
        targetDateAt
        vaultId
        category {
          name
          type
          id
        }
        isDefault
        createdAt
        updatedAt
        reconciledBudgetTotal
        reconciledInvoicesTotal
        reconciledTotal
        invoices {
          balanceDue
          description
          paid
          id
          reconciledTransactions {
            amount
            id
            invoiceId
            transactionId
          }
          amount
        }
      }
      season {
        id
        name
        startDateAt
        endDateAt
      }
      group {
        id
        name
        organizationId
        programId
        createdAt
        archivedAt
        hasAccount
      }
    }
  }
`;

/**
 * __useSpendPaymentSchedulesByGroupOrSeasonQuery__
 *
 * To run a query within a React component, call `useSpendPaymentSchedulesByGroupOrSeasonQuery` and pass it any options that fit your needs.
 * When your component renders, `useSpendPaymentSchedulesByGroupOrSeasonQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSpendPaymentSchedulesByGroupOrSeasonQuery({
 *   variables: {
 *      groupIdOrSeasonId: // value for 'groupIdOrSeasonId'
 *   },
 * });
 */
export function useSpendPaymentSchedulesByGroupOrSeasonQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SpendPaymentSchedulesByGroupOrSeasonQuery,
    SpendPaymentSchedulesByGroupOrSeasonQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SpendPaymentSchedulesByGroupOrSeasonQuery,
    SpendPaymentSchedulesByGroupOrSeasonQueryVariables
  >(SpendPaymentSchedulesByGroupOrSeasonDocument, options);
}
export function useSpendPaymentSchedulesByGroupOrSeasonLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SpendPaymentSchedulesByGroupOrSeasonQuery,
    SpendPaymentSchedulesByGroupOrSeasonQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SpendPaymentSchedulesByGroupOrSeasonQuery,
    SpendPaymentSchedulesByGroupOrSeasonQueryVariables
  >(SpendPaymentSchedulesByGroupOrSeasonDocument, options);
}
export type SpendPaymentSchedulesByGroupOrSeasonQueryHookResult = ReturnType<
  typeof useSpendPaymentSchedulesByGroupOrSeasonQuery
>;
export type SpendPaymentSchedulesByGroupOrSeasonLazyQueryHookResult =
  ReturnType<typeof useSpendPaymentSchedulesByGroupOrSeasonLazyQuery>;
export type SpendPaymentSchedulesByGroupOrSeasonQueryResult =
  Apollo.QueryResult<
    SpendPaymentSchedulesByGroupOrSeasonQuery,
    SpendPaymentSchedulesByGroupOrSeasonQueryVariables
  >;
export const MyPermissionsDocument = gql`
  query MyPermissions {
    me {
      email
      permissions
      id
      firstName
      lastName
      phoneNumber
      parents
    }
  }
`;

/**
 * __useMyPermissionsQuery__
 *
 * To run a query within a React component, call `useMyPermissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyPermissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyPermissionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useMyPermissionsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    MyPermissionsQuery,
    MyPermissionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MyPermissionsQuery, MyPermissionsQueryVariables>(
    MyPermissionsDocument,
    options
  );
}
export function useMyPermissionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MyPermissionsQuery,
    MyPermissionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MyPermissionsQuery, MyPermissionsQueryVariables>(
    MyPermissionsDocument,
    options
  );
}
export type MyPermissionsQueryHookResult = ReturnType<
  typeof useMyPermissionsQuery
>;
export type MyPermissionsLazyQueryHookResult = ReturnType<
  typeof useMyPermissionsLazyQuery
>;
export type MyPermissionsQueryResult = Apollo.QueryResult<
  MyPermissionsQuery,
  MyPermissionsQueryVariables
>;
export const SpendUserBankLinkTokenCreateDocument = gql`
  query SpendUserBankLinkTokenCreate {
    spendUserBankLinkTokenCreate {
      expiration
      requestId
      linkToken
    }
  }
`;

/**
 * __useSpendUserBankLinkTokenCreateQuery__
 *
 * To run a query within a React component, call `useSpendUserBankLinkTokenCreateQuery` and pass it any options that fit your needs.
 * When your component renders, `useSpendUserBankLinkTokenCreateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSpendUserBankLinkTokenCreateQuery({
 *   variables: {
 *   },
 * });
 */
export function useSpendUserBankLinkTokenCreateQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SpendUserBankLinkTokenCreateQuery,
    SpendUserBankLinkTokenCreateQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SpendUserBankLinkTokenCreateQuery,
    SpendUserBankLinkTokenCreateQueryVariables
  >(SpendUserBankLinkTokenCreateDocument, options);
}
export function useSpendUserBankLinkTokenCreateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SpendUserBankLinkTokenCreateQuery,
    SpendUserBankLinkTokenCreateQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SpendUserBankLinkTokenCreateQuery,
    SpendUserBankLinkTokenCreateQueryVariables
  >(SpendUserBankLinkTokenCreateDocument, options);
}
export type SpendUserBankLinkTokenCreateQueryHookResult = ReturnType<
  typeof useSpendUserBankLinkTokenCreateQuery
>;
export type SpendUserBankLinkTokenCreateLazyQueryHookResult = ReturnType<
  typeof useSpendUserBankLinkTokenCreateLazyQuery
>;
export type SpendUserBankLinkTokenCreateQueryResult = Apollo.QueryResult<
  SpendUserBankLinkTokenCreateQuery,
  SpendUserBankLinkTokenCreateQueryVariables
>;
export const SpendGroupBankLinkTokenCreateDocument = gql`
  query SpendGroupBankLinkTokenCreate(
    $spendGroupBankLinkTokenCreateId: String
  ) {
    spendGroupBankLinkTokenCreate(id: $spendGroupBankLinkTokenCreateId) {
      expiration
      linkToken
      requestId
    }
  }
`;

/**
 * __useSpendGroupBankLinkTokenCreateQuery__
 *
 * To run a query within a React component, call `useSpendGroupBankLinkTokenCreateQuery` and pass it any options that fit your needs.
 * When your component renders, `useSpendGroupBankLinkTokenCreateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSpendGroupBankLinkTokenCreateQuery({
 *   variables: {
 *      spendGroupBankLinkTokenCreateId: // value for 'spendGroupBankLinkTokenCreateId'
 *   },
 * });
 */
export function useSpendGroupBankLinkTokenCreateQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SpendGroupBankLinkTokenCreateQuery,
    SpendGroupBankLinkTokenCreateQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SpendGroupBankLinkTokenCreateQuery,
    SpendGroupBankLinkTokenCreateQueryVariables
  >(SpendGroupBankLinkTokenCreateDocument, options);
}
export function useSpendGroupBankLinkTokenCreateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SpendGroupBankLinkTokenCreateQuery,
    SpendGroupBankLinkTokenCreateQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SpendGroupBankLinkTokenCreateQuery,
    SpendGroupBankLinkTokenCreateQueryVariables
  >(SpendGroupBankLinkTokenCreateDocument, options);
}
export type SpendGroupBankLinkTokenCreateQueryHookResult = ReturnType<
  typeof useSpendGroupBankLinkTokenCreateQuery
>;
export type SpendGroupBankLinkTokenCreateLazyQueryHookResult = ReturnType<
  typeof useSpendGroupBankLinkTokenCreateLazyQuery
>;
export type SpendGroupBankLinkTokenCreateQueryResult = Apollo.QueryResult<
  SpendGroupBankLinkTokenCreateQuery,
  SpendGroupBankLinkTokenCreateQueryVariables
>;
export const SpendOrganizationBankLinkTokenCreateDocument = gql`
  query SpendOrganizationBankLinkTokenCreate {
    spendOrganizationBankLinkTokenCreate {
      linkToken
      expiration
      requestId
    }
  }
`;

/**
 * __useSpendOrganizationBankLinkTokenCreateQuery__
 *
 * To run a query within a React component, call `useSpendOrganizationBankLinkTokenCreateQuery` and pass it any options that fit your needs.
 * When your component renders, `useSpendOrganizationBankLinkTokenCreateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSpendOrganizationBankLinkTokenCreateQuery({
 *   variables: {
 *   },
 * });
 */
export function useSpendOrganizationBankLinkTokenCreateQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SpendOrganizationBankLinkTokenCreateQuery,
    SpendOrganizationBankLinkTokenCreateQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SpendOrganizationBankLinkTokenCreateQuery,
    SpendOrganizationBankLinkTokenCreateQueryVariables
  >(SpendOrganizationBankLinkTokenCreateDocument, options);
}
export function useSpendOrganizationBankLinkTokenCreateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SpendOrganizationBankLinkTokenCreateQuery,
    SpendOrganizationBankLinkTokenCreateQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SpendOrganizationBankLinkTokenCreateQuery,
    SpendOrganizationBankLinkTokenCreateQueryVariables
  >(SpendOrganizationBankLinkTokenCreateDocument, options);
}
export type SpendOrganizationBankLinkTokenCreateQueryHookResult = ReturnType<
  typeof useSpendOrganizationBankLinkTokenCreateQuery
>;
export type SpendOrganizationBankLinkTokenCreateLazyQueryHookResult =
  ReturnType<typeof useSpendOrganizationBankLinkTokenCreateLazyQuery>;
export type SpendOrganizationBankLinkTokenCreateQueryResult =
  Apollo.QueryResult<
    SpendOrganizationBankLinkTokenCreateQuery,
    SpendOrganizationBankLinkTokenCreateQueryVariables
  >;
export const SpendRostersFilteredDocument = gql`
  query SpendRostersFiltered(
    $where: SpendGroupRostersWhereInput
    $pagination: SpendPaginationInput
    $sort: SpendSortInput
  ) {
    spendRostersFiltered(where: $where, pagination: $pagination, sort: $sort) {
      count
      rosters {
        name
        id
        email
        groupRosters {
          id
          groupId
          userId
          seasonId
          rosterId
          inviteId
          invite {
            id
            status
            expiresAt
          }
          isArchived
          createdAt
          joinedAt
          status
          group {
            id
            name
            latestSeason {
              id
              name
              startDateAt
              endDateAt
            }
            seasons {
              id
              name
              startDateAt
              endDateAt
            }
          }
          total {
            pastDue
            upcoming
            paid
            dueToday
            credited
            processing
            pastDueDays
          }
        }
      }
    }
  }
`;

/**
 * __useSpendRostersFilteredQuery__
 *
 * To run a query within a React component, call `useSpendRostersFilteredQuery` and pass it any options that fit your needs.
 * When your component renders, `useSpendRostersFilteredQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSpendRostersFilteredQuery({
 *   variables: {
 *      where: // value for 'where'
 *      pagination: // value for 'pagination'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useSpendRostersFilteredQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SpendRostersFilteredQuery,
    SpendRostersFilteredQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SpendRostersFilteredQuery,
    SpendRostersFilteredQueryVariables
  >(SpendRostersFilteredDocument, options);
}
export function useSpendRostersFilteredLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SpendRostersFilteredQuery,
    SpendRostersFilteredQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SpendRostersFilteredQuery,
    SpendRostersFilteredQueryVariables
  >(SpendRostersFilteredDocument, options);
}
export type SpendRostersFilteredQueryHookResult = ReturnType<
  typeof useSpendRostersFilteredQuery
>;
export type SpendRostersFilteredLazyQueryHookResult = ReturnType<
  typeof useSpendRostersFilteredLazyQuery
>;
export type SpendRostersFilteredQueryResult = Apollo.QueryResult<
  SpendRostersFilteredQuery,
  SpendRostersFilteredQueryVariables
>;
export const SpendRostersDocument = gql`
  query SpendRosters {
    spendRosters {
      rosters {
        id
        name
        email
        groupRosters {
          userId
          inviteId
          season {
            name
            startDateAt
            endDateAt
          }
          seasonId
          group {
            name
            id
          }
          invoices {
            amount
            balanceDue
            paid
            dueDate
          }
          invite {
            status
          }
        }
      }
    }
  }
`;

/**
 * __useSpendRostersQuery__
 *
 * To run a query within a React component, call `useSpendRostersQuery` and pass it any options that fit your needs.
 * When your component renders, `useSpendRostersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSpendRostersQuery({
 *   variables: {
 *   },
 * });
 */
export function useSpendRostersQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SpendRostersQuery,
    SpendRostersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SpendRostersQuery, SpendRostersQueryVariables>(
    SpendRostersDocument,
    options
  );
}
export function useSpendRostersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SpendRostersQuery,
    SpendRostersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SpendRostersQuery, SpendRostersQueryVariables>(
    SpendRostersDocument,
    options
  );
}
export type SpendRostersQueryHookResult = ReturnType<
  typeof useSpendRostersQuery
>;
export type SpendRostersLazyQueryHookResult = ReturnType<
  typeof useSpendRostersLazyQuery
>;
export type SpendRostersQueryResult = Apollo.QueryResult<
  SpendRostersQuery,
  SpendRostersQueryVariables
>;
export const GetParticipantsDetailsDocument = gql`
  query GetParticipantsDetails(
    $filterBy: GroupRosterFilterEnum
    $filterValue: String
  ) {
    spendGroupRosters(filterBy: $filterBy, filterValue: $filterValue) {
      groupRosters {
        id
        userId
        rosterId
        isArchived
        invoices {
          id
          amount
          description
          paid
          paidDate
          paymentMethodSource
          isOptional
          dueDate
          status
          balanceDue
          note
          feesAmount
          discountAmount
          budgetItemId
          isRefunded
          creditMemos {
            creditAmount
            creditApplied
            dateToApply
            id
            isArchived
            note
            title
          }
          reconciledTransactions {
            note
          }
        }
        invite {
          email
          firstName
          lastName
          status
        }
        joinedAt
        roster {
          name
          email
        }
        group {
          name
          accountId
          id
          sharedAccount
          latestSeason {
            name
            startDateAt
            endDateAt
          }
          seasons {
            id
            name
            startDateAt
            endDateAt
          }
        }
        phoneNumber
        guardianName
        seasonId
      }
      count
    }
  }
`;

/**
 * __useGetParticipantsDetailsQuery__
 *
 * To run a query within a React component, call `useGetParticipantsDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetParticipantsDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetParticipantsDetailsQuery({
 *   variables: {
 *      filterBy: // value for 'filterBy'
 *      filterValue: // value for 'filterValue'
 *   },
 * });
 */
export function useGetParticipantsDetailsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetParticipantsDetailsQuery,
    GetParticipantsDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetParticipantsDetailsQuery,
    GetParticipantsDetailsQueryVariables
  >(GetParticipantsDetailsDocument, options);
}
export function useGetParticipantsDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetParticipantsDetailsQuery,
    GetParticipantsDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetParticipantsDetailsQuery,
    GetParticipantsDetailsQueryVariables
  >(GetParticipantsDetailsDocument, options);
}
export type GetParticipantsDetailsQueryHookResult = ReturnType<
  typeof useGetParticipantsDetailsQuery
>;
export type GetParticipantsDetailsLazyQueryHookResult = ReturnType<
  typeof useGetParticipantsDetailsLazyQuery
>;
export type GetParticipantsDetailsQueryResult = Apollo.QueryResult<
  GetParticipantsDetailsQuery,
  GetParticipantsDetailsQueryVariables
>;
export const SpendGroupRostersBySeasonDocument = gql`
  query SpendGroupRostersBySeason(
    $seasonId: String!
    $pagination: SpendPaginationInput
  ) {
    spendGroupRostersBySeason(seasonId: $seasonId, pagination: $pagination) {
      groupRosters {
        id
        userId
        isArchived
        rosterId
        seasonId
        invite {
          id
          email
          firstName
          lastName
          status
          createdAt
          expiresAt
          organizationId
          userId
          isUser
          groupId
          type
          isDeliverable
          isArchived
        }
        roster {
          id
          name
          email
        }
        total {
          paid
          upcoming
          pastDue
          credited
          dueToday
          processing
          statuses
        }
        group {
          id
          name
        }
      }
      count
    }
  }
`;

/**
 * __useSpendGroupRostersBySeasonQuery__
 *
 * To run a query within a React component, call `useSpendGroupRostersBySeasonQuery` and pass it any options that fit your needs.
 * When your component renders, `useSpendGroupRostersBySeasonQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSpendGroupRostersBySeasonQuery({
 *   variables: {
 *      seasonId: // value for 'seasonId'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useSpendGroupRostersBySeasonQuery(
  baseOptions: Apollo.QueryHookOptions<
    SpendGroupRostersBySeasonQuery,
    SpendGroupRostersBySeasonQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SpendGroupRostersBySeasonQuery,
    SpendGroupRostersBySeasonQueryVariables
  >(SpendGroupRostersBySeasonDocument, options);
}
export function useSpendGroupRostersBySeasonLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SpendGroupRostersBySeasonQuery,
    SpendGroupRostersBySeasonQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SpendGroupRostersBySeasonQuery,
    SpendGroupRostersBySeasonQueryVariables
  >(SpendGroupRostersBySeasonDocument, options);
}
export type SpendGroupRostersBySeasonQueryHookResult = ReturnType<
  typeof useSpendGroupRostersBySeasonQuery
>;
export type SpendGroupRostersBySeasonLazyQueryHookResult = ReturnType<
  typeof useSpendGroupRostersBySeasonLazyQuery
>;
export type SpendGroupRostersBySeasonQueryResult = Apollo.QueryResult<
  SpendGroupRostersBySeasonQuery,
  SpendGroupRostersBySeasonQueryVariables
>;
export const SpendGroupRostersBySeasonV2Document = gql`
  query spendGroupRostersBySeasonV2(
    $seasonId: String!
    $sort: SpendSortInput
    $pagination: SpendPaginationInput
  ) {
    spendGroupRostersBySeasonV2(
      seasonId: $seasonId
      sort: $sort
      pagination: $pagination
    ) {
      count
      groupRosters {
        id
        userId
        isArchived
        rosterId
        seasonId
        invite {
          id
          email
          firstName
          lastName
          status
          createdAt
          expiresAt
          organizationId
          userId
          isUser
          groupId
          type
          isDeliverable
          isArchived
        }
        roster {
          id
          name
          email
        }
        total {
          paid
          upcoming
          pastDue
          credited
          dueToday
          processing
          statuses
        }
        group {
          id
          name
        }
      }
    }
  }
`;

/**
 * __useSpendGroupRostersBySeasonV2Query__
 *
 * To run a query within a React component, call `useSpendGroupRostersBySeasonV2Query` and pass it any options that fit your needs.
 * When your component renders, `useSpendGroupRostersBySeasonV2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSpendGroupRostersBySeasonV2Query({
 *   variables: {
 *      seasonId: // value for 'seasonId'
 *      sort: // value for 'sort'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useSpendGroupRostersBySeasonV2Query(
  baseOptions: Apollo.QueryHookOptions<
    SpendGroupRostersBySeasonV2Query,
    SpendGroupRostersBySeasonV2QueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SpendGroupRostersBySeasonV2Query,
    SpendGroupRostersBySeasonV2QueryVariables
  >(SpendGroupRostersBySeasonV2Document, options);
}
export function useSpendGroupRostersBySeasonV2LazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SpendGroupRostersBySeasonV2Query,
    SpendGroupRostersBySeasonV2QueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SpendGroupRostersBySeasonV2Query,
    SpendGroupRostersBySeasonV2QueryVariables
  >(SpendGroupRostersBySeasonV2Document, options);
}
export type SpendGroupRostersBySeasonV2QueryHookResult = ReturnType<
  typeof useSpendGroupRostersBySeasonV2Query
>;
export type SpendGroupRostersBySeasonV2LazyQueryHookResult = ReturnType<
  typeof useSpendGroupRostersBySeasonV2LazyQuery
>;
export type SpendGroupRostersBySeasonV2QueryResult = Apollo.QueryResult<
  SpendGroupRostersBySeasonV2Query,
  SpendGroupRostersBySeasonV2QueryVariables
>;
export const GetSpendRosterDocument = gql`
  query GetSpendRoster($filterValue: String) {
    spendGroupRosters(filterBy: seasonId, filterValue: $filterValue) {
      count
      groupRosters {
        inviteId
        invite {
          status
          isArchived
          id
        }
        archivedAt
        createdAt
        groupId
        id
        isArchived
        roster {
          name
          email
          id
        }
        group {
          id
          name
          accountId
        }
        season {
          id
          name
          startDateAt
          endDateAt
        }
        seasonId
        rosterId
        userId
        invoices {
          id
          amount
          paid
          paidDate
          dueDate
          balanceDue
          description
          status
          transaction {
            id
            invoiceId
            externalId
          }
          reconciledTransactions {
            id
          }
        }
      }
    }
  }
`;

/**
 * __useGetSpendRosterQuery__
 *
 * To run a query within a React component, call `useGetSpendRosterQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSpendRosterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSpendRosterQuery({
 *   variables: {
 *      filterValue: // value for 'filterValue'
 *   },
 * });
 */
export function useGetSpendRosterQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetSpendRosterQuery,
    GetSpendRosterQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetSpendRosterQuery, GetSpendRosterQueryVariables>(
    GetSpendRosterDocument,
    options
  );
}
export function useGetSpendRosterLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSpendRosterQuery,
    GetSpendRosterQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetSpendRosterQuery, GetSpendRosterQueryVariables>(
    GetSpendRosterDocument,
    options
  );
}
export type GetSpendRosterQueryHookResult = ReturnType<
  typeof useGetSpendRosterQuery
>;
export type GetSpendRosterLazyQueryHookResult = ReturnType<
  typeof useGetSpendRosterLazyQuery
>;
export type GetSpendRosterQueryResult = Apollo.QueryResult<
  GetSpendRosterQuery,
  GetSpendRosterQueryVariables
>;
export const SpendSeasonDocument = gql`
  query SpendSeason($spendSeasonId: String!) {
    spendSeason(id: $spendSeasonId) {
      id
      endDateAt
      groupId
      isActive
      isBudgetShared
      isLinkEnabled
      name
      paymentScheduleStatus
      playerCount
      startDateAt
      transactionTotals {
        credited
        dueToday
        paid
        pastDue
        pastDueDays
        processing
        upcoming
        statuses
      }
    }
  }
`;

/**
 * __useSpendSeasonQuery__
 *
 * To run a query within a React component, call `useSpendSeasonQuery` and pass it any options that fit your needs.
 * When your component renders, `useSpendSeasonQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSpendSeasonQuery({
 *   variables: {
 *      spendSeasonId: // value for 'spendSeasonId'
 *   },
 * });
 */
export function useSpendSeasonQuery(
  baseOptions: Apollo.QueryHookOptions<
    SpendSeasonQuery,
    SpendSeasonQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SpendSeasonQuery, SpendSeasonQueryVariables>(
    SpendSeasonDocument,
    options
  );
}
export function useSpendSeasonLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SpendSeasonQuery,
    SpendSeasonQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SpendSeasonQuery, SpendSeasonQueryVariables>(
    SpendSeasonDocument,
    options
  );
}
export type SpendSeasonQueryHookResult = ReturnType<typeof useSpendSeasonQuery>;
export type SpendSeasonLazyQueryHookResult = ReturnType<
  typeof useSpendSeasonLazyQuery
>;
export type SpendSeasonQueryResult = Apollo.QueryResult<
  SpendSeasonQuery,
  SpendSeasonQueryVariables
>;
export const SpendSettingsDocument = gql`
  query SpendSettings {
    spendSettings {
      signUpAgreement {
        content
        name
      }
    }
  }
`;

/**
 * __useSpendSettingsQuery__
 *
 * To run a query within a React component, call `useSpendSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSpendSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSpendSettingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useSpendSettingsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SpendSettingsQuery,
    SpendSettingsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SpendSettingsQuery, SpendSettingsQueryVariables>(
    SpendSettingsDocument,
    options
  );
}
export function useSpendSettingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SpendSettingsQuery,
    SpendSettingsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SpendSettingsQuery, SpendSettingsQueryVariables>(
    SpendSettingsDocument,
    options
  );
}
export type SpendSettingsQueryHookResult = ReturnType<
  typeof useSpendSettingsQuery
>;
export type SpendSettingsLazyQueryHookResult = ReturnType<
  typeof useSpendSettingsLazyQuery
>;
export type SpendSettingsQueryResult = Apollo.QueryResult<
  SpendSettingsQuery,
  SpendSettingsQueryVariables
>;
export const SpendUserNotificationSettingDocument = gql`
  query SpendUserNotificationSetting {
    spendUserNotificationSetting {
      notificationSetting {
        id
        notifyOnFailedCardPayments
        notifyOnBankActivity
        copyPastDueInvoices
        userRoleId
        organizationId
        roleName
        groupId
      }
    }
  }
`;

/**
 * __useSpendUserNotificationSettingQuery__
 *
 * To run a query within a React component, call `useSpendUserNotificationSettingQuery` and pass it any options that fit your needs.
 * When your component renders, `useSpendUserNotificationSettingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSpendUserNotificationSettingQuery({
 *   variables: {
 *   },
 * });
 */
export function useSpendUserNotificationSettingQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SpendUserNotificationSettingQuery,
    SpendUserNotificationSettingQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SpendUserNotificationSettingQuery,
    SpendUserNotificationSettingQueryVariables
  >(SpendUserNotificationSettingDocument, options);
}
export function useSpendUserNotificationSettingLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SpendUserNotificationSettingQuery,
    SpendUserNotificationSettingQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SpendUserNotificationSettingQuery,
    SpendUserNotificationSettingQueryVariables
  >(SpendUserNotificationSettingDocument, options);
}
export type SpendUserNotificationSettingQueryHookResult = ReturnType<
  typeof useSpendUserNotificationSettingQuery
>;
export type SpendUserNotificationSettingLazyQueryHookResult = ReturnType<
  typeof useSpendUserNotificationSettingLazyQuery
>;
export type SpendUserNotificationSettingQueryResult = Apollo.QueryResult<
  SpendUserNotificationSettingQuery,
  SpendUserNotificationSettingQueryVariables
>;
export const GetNotesAndAttachmentsDocument = gql`
  query GetNotesAndAttachments($id: String!) {
    spendTransactionNotesByPaymentId(id: $id) {
      notes {
        content
        createdAt
        id
        lastUpdatedByUserId
        paymentId
        updatedAt
      }
    }
    spendTransactionAttachmentsByPaymentId(id: $id) {
      attachments {
        createdAt
        description
        id
        lastUpdatedByUserId
        name
        paymentId
        updatedAt
        url
      }
    }
  }
`;

/**
 * __useGetNotesAndAttachmentsQuery__
 *
 * To run a query within a React component, call `useGetNotesAndAttachmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNotesAndAttachmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNotesAndAttachmentsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetNotesAndAttachmentsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetNotesAndAttachmentsQuery,
    GetNotesAndAttachmentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetNotesAndAttachmentsQuery,
    GetNotesAndAttachmentsQueryVariables
  >(GetNotesAndAttachmentsDocument, options);
}
export function useGetNotesAndAttachmentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetNotesAndAttachmentsQuery,
    GetNotesAndAttachmentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetNotesAndAttachmentsQuery,
    GetNotesAndAttachmentsQueryVariables
  >(GetNotesAndAttachmentsDocument, options);
}
export type GetNotesAndAttachmentsQueryHookResult = ReturnType<
  typeof useGetNotesAndAttachmentsQuery
>;
export type GetNotesAndAttachmentsLazyQueryHookResult = ReturnType<
  typeof useGetNotesAndAttachmentsLazyQuery
>;
export type GetNotesAndAttachmentsQueryResult = Apollo.QueryResult<
  GetNotesAndAttachmentsQuery,
  GetNotesAndAttachmentsQueryVariables
>;
export const GetNotesDocument = gql`
  query GetNotes($id: String) {
    spendTransactionNotesByPaymentId(id: $id) {
      notes {
        content
        createdAt
        id
        lastUpdatedByUserId
        paymentId
        updatedAt
      }
    }
  }
`;

/**
 * __useGetNotesQuery__
 *
 * To run a query within a React component, call `useGetNotesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNotesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNotesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetNotesQuery(
  baseOptions?: Apollo.QueryHookOptions<GetNotesQuery, GetNotesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetNotesQuery, GetNotesQueryVariables>(
    GetNotesDocument,
    options
  );
}
export function useGetNotesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetNotesQuery,
    GetNotesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetNotesQuery, GetNotesQueryVariables>(
    GetNotesDocument,
    options
  );
}
export type GetNotesQueryHookResult = ReturnType<typeof useGetNotesQuery>;
export type GetNotesLazyQueryHookResult = ReturnType<
  typeof useGetNotesLazyQuery
>;
export type GetNotesQueryResult = Apollo.QueryResult<
  GetNotesQuery,
  GetNotesQueryVariables
>;
export const GetAttachmentsDocument = gql`
  query GetAttachments($id: String!) {
    spendTransactionAttachmentsByPaymentId(id: $id) {
      attachments {
        createdAt
        description
        id
        lastUpdatedByUserId
        name
        paymentId
        updatedAt
        url
      }
    }
  }
`;

/**
 * __useGetAttachmentsQuery__
 *
 * To run a query within a React component, call `useGetAttachmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAttachmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAttachmentsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetAttachmentsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAttachmentsQuery,
    GetAttachmentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAttachmentsQuery, GetAttachmentsQueryVariables>(
    GetAttachmentsDocument,
    options
  );
}
export function useGetAttachmentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAttachmentsQuery,
    GetAttachmentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAttachmentsQuery, GetAttachmentsQueryVariables>(
    GetAttachmentsDocument,
    options
  );
}
export type GetAttachmentsQueryHookResult = ReturnType<
  typeof useGetAttachmentsQuery
>;
export type GetAttachmentsLazyQueryHookResult = ReturnType<
  typeof useGetAttachmentsLazyQuery
>;
export type GetAttachmentsQueryResult = Apollo.QueryResult<
  GetAttachmentsQuery,
  GetAttachmentsQueryVariables
>;
export const SpendTransactionsFilteredDocument = gql`
  query SpendTransactionsFiltered(
    $filters: [SpendTransactionFilter]
    $pagination: SpendPaginationInput
    $disableCache: Boolean
    $allAccounts: Boolean
    $sort: SpendSortInput
  ) {
    spendTransactionsFiltered(
      filters: $filters
      pagination: $pagination
      disableCache: $disableCache
      allAccounts: $allAccounts
      sort: $sort
    ) {
      count
      transactions {
        id
        processor
        externalId
        direction
        description
        type
        amount
        snapAmount
        effective
        correlationId
        source
        destination
        transactionType
        transactionStatus
        transactionNote
        metadata {
          account
          addenda
          customer
          description
          destination
          externalId
          payment
          processor
          source
          summary
          tags {
            spendDestinationId
            spendExternalId
            spendInvoiceIds
            spendPaymentType
            spendOriginalPaymentId
            spendSourceId
          }
        }
        reconciliation {
          amount
          createdAt
          id
          type
          updatedAt
        }
        groupRoster {
          group {
            name
          }
          roster {
            name
          }
        }
        status
        attachments {
          id
          name
          url
        }
        payee {
          payeeId
          referenceId
          organizationName
          groupName
          payeeName
        }
        external {
          externalId
          invoiceDescription
          playerName
          groupName
          seasonName
        }
        creditMemo {
          id
          title
          note
          dateToApply
          creditApplied
          creditAmount
          isArchived
          groupName
          invoice {
            id
            description
            participantName
            rosterId
            groupName
            groupId
            status
            dueDate
            seasonId
            seasonName
            amount
            balanceDue
            discountAmount
          }
        }
      }
    }
  }
`;

/**
 * __useSpendTransactionsFilteredQuery__
 *
 * To run a query within a React component, call `useSpendTransactionsFilteredQuery` and pass it any options that fit your needs.
 * When your component renders, `useSpendTransactionsFilteredQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSpendTransactionsFilteredQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      disableCache: // value for 'disableCache'
 *      allAccounts: // value for 'allAccounts'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useSpendTransactionsFilteredQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SpendTransactionsFilteredQuery,
    SpendTransactionsFilteredQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SpendTransactionsFilteredQuery,
    SpendTransactionsFilteredQueryVariables
  >(SpendTransactionsFilteredDocument, options);
}
export function useSpendTransactionsFilteredLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SpendTransactionsFilteredQuery,
    SpendTransactionsFilteredQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SpendTransactionsFilteredQuery,
    SpendTransactionsFilteredQueryVariables
  >(SpendTransactionsFilteredDocument, options);
}
export type SpendTransactionsFilteredQueryHookResult = ReturnType<
  typeof useSpendTransactionsFilteredQuery
>;
export type SpendTransactionsFilteredLazyQueryHookResult = ReturnType<
  typeof useSpendTransactionsFilteredLazyQuery
>;
export type SpendTransactionsFilteredQueryResult = Apollo.QueryResult<
  SpendTransactionsFilteredQuery,
  SpendTransactionsFilteredQueryVariables
>;
export const SpendTransactionsWheredDocument = gql`
  query SpendTransactionsWhered(
    $pagination: SpendPaginationInput
    $where: SpendTransactionWhereInput
    $disableCache: Boolean
  ) {
    spendTransactionsFiltered(
      pagination: $pagination
      where: $where
      disableCache: $disableCache
    ) {
      count
      transactions {
        id
        processor
        externalId
        description
        direction
        type
        amount
        snapAmount
        effective
        correlationId
        source
        destination
        hasAttachments
        transactionType
        transactionStatus
        transactionNote
        isReconciled
        totalReconciled
        typeReconciled
        metadata {
          account
          addenda
          customer
          description
          destination
          externalId
          payment
          processor
          source
          summary
          tags {
            spendDestinationId
            spendExternalId
            spendInvoiceIds
            spendPaymentType
            spendOriginalPaymentId
            spendSourceId
          }
        }
        reconciliation {
          amount
          createdAt
          id
          type
          updatedAt
          budgetTransactions {
            amount
            id
            budgetItemId
            transactionId
          }
          invoiceTransactions {
            amount
            id
            invoiceId
            transactionId
            rosterName
          }
          reconciledTo {
            amount
            description
            type
            invoiceId
            budgetId
            groupName
            seasonName
            seasonId
          }
        }
        groupRoster {
          group {
            name
          }
          roster {
            name
          }
        }
        status
        attachments {
          id
          name
          url
        }
        payee {
          payeeId
          referenceId
          organizationName
          groupName
          payeeName
        }
        external {
          externalId
          invoiceDescription
          playerName
          groupName
          seasonName
        }
      }
    }
  }
`;

/**
 * __useSpendTransactionsWheredQuery__
 *
 * To run a query within a React component, call `useSpendTransactionsWheredQuery` and pass it any options that fit your needs.
 * When your component renders, `useSpendTransactionsWheredQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSpendTransactionsWheredQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      where: // value for 'where'
 *      disableCache: // value for 'disableCache'
 *   },
 * });
 */
export function useSpendTransactionsWheredQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SpendTransactionsWheredQuery,
    SpendTransactionsWheredQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SpendTransactionsWheredQuery,
    SpendTransactionsWheredQueryVariables
  >(SpendTransactionsWheredDocument, options);
}
export function useSpendTransactionsWheredLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SpendTransactionsWheredQuery,
    SpendTransactionsWheredQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SpendTransactionsWheredQuery,
    SpendTransactionsWheredQueryVariables
  >(SpendTransactionsWheredDocument, options);
}
export type SpendTransactionsWheredQueryHookResult = ReturnType<
  typeof useSpendTransactionsWheredQuery
>;
export type SpendTransactionsWheredLazyQueryHookResult = ReturnType<
  typeof useSpendTransactionsWheredLazyQuery
>;
export type SpendTransactionsWheredQueryResult = Apollo.QueryResult<
  SpendTransactionsWheredQuery,
  SpendTransactionsWheredQueryVariables
>;
export const SpendTransactionsCompletedWheredDocument = gql`
  query SpendTransactionsCompletedWhered(
    $pagination: SpendPaginationInput
    $where: SpendTransactionWhereInput
    $disableCache: Boolean
    $dateRange: dateRangeEnum
  ) {
    spendTransactionsFiltered(
      pagination: $pagination
      where: $where
      disableCache: $disableCache
      dateRange: $dateRange
    ) {
      count
      transactions {
        created
        id
        processor
        externalId
        description
        direction
        type
        amount
        snapAmount
        effective
        correlationId
        source
        destination
        hasAttachments
        transactionType
        transactionStatus
        transactionNote
        isReconciled
        totalReconciled
        typeReconciled
        metadata {
          account
          addenda
          customer
          description
          destination
          externalId
          payment
          processor
          source
          summary
          tags {
            spendDestinationId
            spendExternalId
            spendInvoiceIds
            spendPaymentType
            spendOriginalPaymentId
            spendSourceId
          }
        }
        reconciliation {
          amount
          createdAt
          id
          type
          updatedAt
          budgetTransactions {
            amount
            id
            budgetItemId
            transactionId
          }
          invoiceTransactions {
            amount
            id
            invoiceId
            transactionId
            rosterName
          }
          reconciledTo {
            amount
            description
            type
            invoiceId
            budgetId
            groupName
            seasonName
            seasonId
          }
        }
        groupRoster {
          group {
            name
          }
          roster {
            name
          }
        }
        status
        attachments {
          id
          name
          url
        }
        payee {
          payeeId
          referenceId
          organizationName
          groupName
          payeeName
        }
        external {
          externalId
          invoiceDescription
          playerName
          groupName
          seasonName
        }
      }
    }
  }
`;

/**
 * __useSpendTransactionsCompletedWheredQuery__
 *
 * To run a query within a React component, call `useSpendTransactionsCompletedWheredQuery` and pass it any options that fit your needs.
 * When your component renders, `useSpendTransactionsCompletedWheredQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSpendTransactionsCompletedWheredQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      where: // value for 'where'
 *      disableCache: // value for 'disableCache'
 *      dateRange: // value for 'dateRange'
 *   },
 * });
 */
export function useSpendTransactionsCompletedWheredQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SpendTransactionsCompletedWheredQuery,
    SpendTransactionsCompletedWheredQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SpendTransactionsCompletedWheredQuery,
    SpendTransactionsCompletedWheredQueryVariables
  >(SpendTransactionsCompletedWheredDocument, options);
}
export function useSpendTransactionsCompletedWheredLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SpendTransactionsCompletedWheredQuery,
    SpendTransactionsCompletedWheredQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SpendTransactionsCompletedWheredQuery,
    SpendTransactionsCompletedWheredQueryVariables
  >(SpendTransactionsCompletedWheredDocument, options);
}
export type SpendTransactionsCompletedWheredQueryHookResult = ReturnType<
  typeof useSpendTransactionsCompletedWheredQuery
>;
export type SpendTransactionsCompletedWheredLazyQueryHookResult = ReturnType<
  typeof useSpendTransactionsCompletedWheredLazyQuery
>;
export type SpendTransactionsCompletedWheredQueryResult = Apollo.QueryResult<
  SpendTransactionsCompletedWheredQuery,
  SpendTransactionsCompletedWheredQueryVariables
>;
export const SpendTransactionsPendingWheredDocument = gql`
  query SpendTransactionsPendingWhered(
    $pagination: SpendPaginationInput
    $where: SpendTransactionWhereInput
    $disableCache: Boolean
  ) {
    spendTransactionsFiltered(
      pagination: $pagination
      where: $where
      disableCache: $disableCache
    ) {
      count
      transactions {
        created
        id
        processor
        externalId
        description
        direction
        type
        amount
        snapAmount
        effective
        correlationId
        source
        destination
        hasAttachments
        transactionType
        transactionStatus
        transactionNote
        isReconciled
        totalReconciled
        typeReconciled
        metadata {
          account
          addenda
          customer
          description
          destination
          externalId
          payment
          processor
          source
          summary
          tags {
            spendDestinationId
            spendExternalId
            spendInvoiceIds
            spendPaymentType
            spendOriginalPaymentId
            spendSourceId
          }
        }
        reconciliation {
          amount
          createdAt
          id
          type
          updatedAt
          budgetTransactions {
            amount
            id
            budgetItemId
            transactionId
          }
          invoiceTransactions {
            amount
            id
            invoiceId
            transactionId
            rosterName
          }
          reconciledTo {
            amount
            description
            type
            invoiceId
            budgetId
            groupName
            seasonName
            seasonId
          }
        }
        groupRoster {
          group {
            name
          }
          roster {
            name
          }
        }
        status
        attachments {
          id
          name
          url
        }
        payee {
          payeeId
          referenceId
          organizationName
          groupName
          payeeName
        }
        external {
          externalId
          invoiceDescription
          playerName
          groupName
          seasonName
        }
      }
    }
  }
`;

/**
 * __useSpendTransactionsPendingWheredQuery__
 *
 * To run a query within a React component, call `useSpendTransactionsPendingWheredQuery` and pass it any options that fit your needs.
 * When your component renders, `useSpendTransactionsPendingWheredQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSpendTransactionsPendingWheredQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      where: // value for 'where'
 *      disableCache: // value for 'disableCache'
 *   },
 * });
 */
export function useSpendTransactionsPendingWheredQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SpendTransactionsPendingWheredQuery,
    SpendTransactionsPendingWheredQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SpendTransactionsPendingWheredQuery,
    SpendTransactionsPendingWheredQueryVariables
  >(SpendTransactionsPendingWheredDocument, options);
}
export function useSpendTransactionsPendingWheredLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SpendTransactionsPendingWheredQuery,
    SpendTransactionsPendingWheredQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SpendTransactionsPendingWheredQuery,
    SpendTransactionsPendingWheredQueryVariables
  >(SpendTransactionsPendingWheredDocument, options);
}
export type SpendTransactionsPendingWheredQueryHookResult = ReturnType<
  typeof useSpendTransactionsPendingWheredQuery
>;
export type SpendTransactionsPendingWheredLazyQueryHookResult = ReturnType<
  typeof useSpendTransactionsPendingWheredLazyQuery
>;
export type SpendTransactionsPendingWheredQueryResult = Apollo.QueryResult<
  SpendTransactionsPendingWheredQuery,
  SpendTransactionsPendingWheredQueryVariables
>;
export const SpendTransactionsExportDocument = gql`
  query SpendTransactionsExport(
    $filters: [SpendTransactionFilter]
    $limit: Int
    $allAccounts: Boolean
  ) {
    spendTransactionsExport(
      filters: $filters
      limit: $limit
      allAccounts: $allAccounts
    ) {
      content
      fileName
    }
  }
`;

/**
 * __useSpendTransactionsExportQuery__
 *
 * To run a query within a React component, call `useSpendTransactionsExportQuery` and pass it any options that fit your needs.
 * When your component renders, `useSpendTransactionsExportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSpendTransactionsExportQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      limit: // value for 'limit'
 *      allAccounts: // value for 'allAccounts'
 *   },
 * });
 */
export function useSpendTransactionsExportQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SpendTransactionsExportQuery,
    SpendTransactionsExportQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SpendTransactionsExportQuery,
    SpendTransactionsExportQueryVariables
  >(SpendTransactionsExportDocument, options);
}
export function useSpendTransactionsExportLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SpendTransactionsExportQuery,
    SpendTransactionsExportQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SpendTransactionsExportQuery,
    SpendTransactionsExportQueryVariables
  >(SpendTransactionsExportDocument, options);
}
export type SpendTransactionsExportQueryHookResult = ReturnType<
  typeof useSpendTransactionsExportQuery
>;
export type SpendTransactionsExportLazyQueryHookResult = ReturnType<
  typeof useSpendTransactionsExportLazyQuery
>;
export type SpendTransactionsExportQueryResult = Apollo.QueryResult<
  SpendTransactionsExportQuery,
  SpendTransactionsExportQueryVariables
>;
export const SpendTransactionsFilteredForParentDashDocument = gql`
  query SpendTransactionsFilteredForParentDash(
    $pagination: SpendPaginationInput
  ) {
    spendTransactionsFiltered(pagination: $pagination, disableCache: true) {
      transactions {
        amount
        correlationId
        destination
        effective
        externalId
        direction
        id
        metadata {
          destination
          source
          description
          status
          summary
          tags {
            spendDestinationId
            spendExternalId
            spendInvoiceIds
            spendOriginalPaymentId
            spendPaymentType
            spendSourceId
            spendUserId
          }
          processor
          payment
          externalId
          addenda
        }
        groupRoster {
          group {
            id
            name
          }
          roster {
            id
            name
          }
        }
        external {
          externalId
          invoiceDescription
          playerName
          groupName
          seasonName
        }
        creditMemo {
          id
          title
          note
          dateToApply
          creditApplied
          creditAmount
          isArchived
          groupName
          invoice {
            id
            description
            participantName
            rosterId
            groupName
            groupId
            status
            dueDate
            seasonId
            seasonName
            amount
            balanceDue
            discountAmount
          }
        }
        processor
        snapAmount
        source
        status
        type
        transactionType
        transactionStatus
      }
      count
    }
  }
`;

/**
 * __useSpendTransactionsFilteredForParentDashQuery__
 *
 * To run a query within a React component, call `useSpendTransactionsFilteredForParentDashQuery` and pass it any options that fit your needs.
 * When your component renders, `useSpendTransactionsFilteredForParentDashQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSpendTransactionsFilteredForParentDashQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useSpendTransactionsFilteredForParentDashQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SpendTransactionsFilteredForParentDashQuery,
    SpendTransactionsFilteredForParentDashQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SpendTransactionsFilteredForParentDashQuery,
    SpendTransactionsFilteredForParentDashQueryVariables
  >(SpendTransactionsFilteredForParentDashDocument, options);
}
export function useSpendTransactionsFilteredForParentDashLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SpendTransactionsFilteredForParentDashQuery,
    SpendTransactionsFilteredForParentDashQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SpendTransactionsFilteredForParentDashQuery,
    SpendTransactionsFilteredForParentDashQueryVariables
  >(SpendTransactionsFilteredForParentDashDocument, options);
}
export type SpendTransactionsFilteredForParentDashQueryHookResult = ReturnType<
  typeof useSpendTransactionsFilteredForParentDashQuery
>;
export type SpendTransactionsFilteredForParentDashLazyQueryHookResult =
  ReturnType<typeof useSpendTransactionsFilteredForParentDashLazyQuery>;
export type SpendTransactionsFilteredForParentDashQueryResult =
  Apollo.QueryResult<
    SpendTransactionsFilteredForParentDashQuery,
    SpendTransactionsFilteredForParentDashQueryVariables
  >;
export const SpendTransactionByIdDocument = gql`
  query SpendTransactionByID($spendTransactionId: String) {
    spendTransaction(id: $spendTransactionId) {
      transactionDetail {
        id
        canCancel
        amount
        description
        destination
        direction
        effective
        externalId
        processor
        type
        status
        snapAmount
        metadata {
          account
          addenda
          customer
          description
          destination
          externalId
          payment
          processor
          snapAmount
          source
          summary
          status
          tags {
            spendDestinationId
            spendExternalId
            spendInvoiceIds
            spendPaymentType
            spendAutoPay
            spendOriginalPaymentId
            spendUserId
            spendSourceId
          }
          originalTransaction
          returnedTransaction
        }
        history {
          date
          status
          transactionId
        }
        reconciliation {
          budgetTransactions {
            budgetItemId
            amount
            id
            transactionId
          }
          invoiceTransactions {
            amount
            id
            invoiceId
            transactionId
            rosterName
            rosterId
            creditMemos {
              id
              title
              note
              dateToApply
              creditApplied
              creditAmount
              isArchived
            }
          }
          reconciledTo {
            amount
            description
            type
            invoiceId
            budgetId
            budgetName
            categoryName
            categoryId
            groupName
            seasonName
            groupId
          }
          amount
          createdAt
          id
          paymentId
          type
          updatedAt
        }
        groupRoster {
          group {
            name
          }
          season {
            name
          }
        }
      }
    }
  }
`;

/**
 * __useSpendTransactionByIdQuery__
 *
 * To run a query within a React component, call `useSpendTransactionByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useSpendTransactionByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSpendTransactionByIdQuery({
 *   variables: {
 *      spendTransactionId: // value for 'spendTransactionId'
 *   },
 * });
 */
export function useSpendTransactionByIdQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SpendTransactionByIdQuery,
    SpendTransactionByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SpendTransactionByIdQuery,
    SpendTransactionByIdQueryVariables
  >(SpendTransactionByIdDocument, options);
}
export function useSpendTransactionByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SpendTransactionByIdQuery,
    SpendTransactionByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SpendTransactionByIdQuery,
    SpendTransactionByIdQueryVariables
  >(SpendTransactionByIdDocument, options);
}
export type SpendTransactionByIdQueryHookResult = ReturnType<
  typeof useSpendTransactionByIdQuery
>;
export type SpendTransactionByIdLazyQueryHookResult = ReturnType<
  typeof useSpendTransactionByIdLazyQuery
>;
export type SpendTransactionByIdQueryResult = Apollo.QueryResult<
  SpendTransactionByIdQuery,
  SpendTransactionByIdQueryVariables
>;
export const SpendTransactionsLegacyDocument = gql`
  query SpendTransactionsLegacy(
    $pagination: SpendPaginationInput
    $groupId: String
  ) {
    spendTransactionsLegacy(pagination: $pagination, groupId: $groupId) {
      count
      transactions {
        amount
        attachments {
          createdAt
          description
          id
          lastUpdatedByUserId
          name
          paymentId
          updatedAt
          url
        }
        correlationId
        created
        description
        destination
        direction
        effective
        externalId
        id
        metadata
        note {
          content
          createdAt
          id
          lastUpdatedByUserId
          paymentId
          updatedAt
        }
        processor
        reconciliation {
          reconciledTo {
            amount
            description
            type
            invoiceId
            budgetId
            budgetName
            categoryName
            categoryId
            groupName
            seasonName
            groupId
          }
          amount
          budgetTransactions {
            amount
            budgetItemId
            id
            transactionId
          }
          createdAt
          id
          invoiceTransactions {
            amount
            id
            invoiceId
            rosterId
            rosterName
            transactionId
          }
          paymentId
          type
          updatedAt
        }
        snapAmount
        source
        status
        transactionNote
        type
      }
    }
  }
`;

/**
 * __useSpendTransactionsLegacyQuery__
 *
 * To run a query within a React component, call `useSpendTransactionsLegacyQuery` and pass it any options that fit your needs.
 * When your component renders, `useSpendTransactionsLegacyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSpendTransactionsLegacyQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      groupId: // value for 'groupId'
 *   },
 * });
 */
export function useSpendTransactionsLegacyQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SpendTransactionsLegacyQuery,
    SpendTransactionsLegacyQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SpendTransactionsLegacyQuery,
    SpendTransactionsLegacyQueryVariables
  >(SpendTransactionsLegacyDocument, options);
}
export function useSpendTransactionsLegacyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SpendTransactionsLegacyQuery,
    SpendTransactionsLegacyQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SpendTransactionsLegacyQuery,
    SpendTransactionsLegacyQueryVariables
  >(SpendTransactionsLegacyDocument, options);
}
export type SpendTransactionsLegacyQueryHookResult = ReturnType<
  typeof useSpendTransactionsLegacyQuery
>;
export type SpendTransactionsLegacyLazyQueryHookResult = ReturnType<
  typeof useSpendTransactionsLegacyLazyQuery
>;
export type SpendTransactionsLegacyQueryResult = Apollo.QueryResult<
  SpendTransactionsLegacyQuery,
  SpendTransactionsLegacyQueryVariables
>;
export const SpendUserVerificationTokenDocument = gql`
  query SpendUserVerificationToken {
    spendUserVerificationToken {
      verificationToken
    }
  }
`;

/**
 * __useSpendUserVerificationTokenQuery__
 *
 * To run a query within a React component, call `useSpendUserVerificationTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useSpendUserVerificationTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSpendUserVerificationTokenQuery({
 *   variables: {
 *   },
 * });
 */
export function useSpendUserVerificationTokenQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SpendUserVerificationTokenQuery,
    SpendUserVerificationTokenQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SpendUserVerificationTokenQuery,
    SpendUserVerificationTokenQueryVariables
  >(SpendUserVerificationTokenDocument, options);
}
export function useSpendUserVerificationTokenLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SpendUserVerificationTokenQuery,
    SpendUserVerificationTokenQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SpendUserVerificationTokenQuery,
    SpendUserVerificationTokenQueryVariables
  >(SpendUserVerificationTokenDocument, options);
}
export type SpendUserVerificationTokenQueryHookResult = ReturnType<
  typeof useSpendUserVerificationTokenQuery
>;
export type SpendUserVerificationTokenLazyQueryHookResult = ReturnType<
  typeof useSpendUserVerificationTokenLazyQuery
>;
export type SpendUserVerificationTokenQueryResult = Apollo.QueryResult<
  SpendUserVerificationTokenQuery,
  SpendUserVerificationTokenQueryVariables
>;
export const SpendUserAccountBearerTokenDocument = gql`
  query SpendUserAccountBearerToken($input: SpendAccountBearerToken) {
    spendUserAccountBearerToken(input: $input) {
      expiresIn
      token
    }
  }
`;

/**
 * __useSpendUserAccountBearerTokenQuery__
 *
 * To run a query within a React component, call `useSpendUserAccountBearerTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useSpendUserAccountBearerTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSpendUserAccountBearerTokenQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSpendUserAccountBearerTokenQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SpendUserAccountBearerTokenQuery,
    SpendUserAccountBearerTokenQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SpendUserAccountBearerTokenQuery,
    SpendUserAccountBearerTokenQueryVariables
  >(SpendUserAccountBearerTokenDocument, options);
}
export function useSpendUserAccountBearerTokenLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SpendUserAccountBearerTokenQuery,
    SpendUserAccountBearerTokenQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SpendUserAccountBearerTokenQuery,
    SpendUserAccountBearerTokenQueryVariables
  >(SpendUserAccountBearerTokenDocument, options);
}
export type SpendUserAccountBearerTokenQueryHookResult = ReturnType<
  typeof useSpendUserAccountBearerTokenQuery
>;
export type SpendUserAccountBearerTokenLazyQueryHookResult = ReturnType<
  typeof useSpendUserAccountBearerTokenLazyQuery
>;
export type SpendUserAccountBearerTokenQueryResult = Apollo.QueryResult<
  SpendUserAccountBearerTokenQuery,
  SpendUserAccountBearerTokenQueryVariables
>;
export const SpendGetInstitutionByRoutingNumberDocument = gql`
  query SpendGetInstitutionByRoutingNumber($routingNumber: String!) {
    spendGetInstitutionByRoutingNumber(routingNumber: $routingNumber) {
      routingNumber
      name
      address
      isACHSupported
      isWireSupported
    }
  }
`;

/**
 * __useSpendGetInstitutionByRoutingNumberQuery__
 *
 * To run a query within a React component, call `useSpendGetInstitutionByRoutingNumberQuery` and pass it any options that fit your needs.
 * When your component renders, `useSpendGetInstitutionByRoutingNumberQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSpendGetInstitutionByRoutingNumberQuery({
 *   variables: {
 *      routingNumber: // value for 'routingNumber'
 *   },
 * });
 */
export function useSpendGetInstitutionByRoutingNumberQuery(
  baseOptions: Apollo.QueryHookOptions<
    SpendGetInstitutionByRoutingNumberQuery,
    SpendGetInstitutionByRoutingNumberQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SpendGetInstitutionByRoutingNumberQuery,
    SpendGetInstitutionByRoutingNumberQueryVariables
  >(SpendGetInstitutionByRoutingNumberDocument, options);
}
export function useSpendGetInstitutionByRoutingNumberLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SpendGetInstitutionByRoutingNumberQuery,
    SpendGetInstitutionByRoutingNumberQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SpendGetInstitutionByRoutingNumberQuery,
    SpendGetInstitutionByRoutingNumberQueryVariables
  >(SpendGetInstitutionByRoutingNumberDocument, options);
}
export type SpendGetInstitutionByRoutingNumberQueryHookResult = ReturnType<
  typeof useSpendGetInstitutionByRoutingNumberQuery
>;
export type SpendGetInstitutionByRoutingNumberLazyQueryHookResult = ReturnType<
  typeof useSpendGetInstitutionByRoutingNumberLazyQuery
>;
export type SpendGetInstitutionByRoutingNumberQueryResult = Apollo.QueryResult<
  SpendGetInstitutionByRoutingNumberQuery,
  SpendGetInstitutionByRoutingNumberQueryVariables
>;
export const ExternalAccountsDocument = gql`
  query ExternalAccounts {
    spendUserBankAccounts {
      externalAccounts {
        id
        accountId
        availableBalance
        currentBalance
        mask
        name
        officialName
        subtype
        type
        institution {
          id
          name
        }
      }
      status
    }
  }
`;

/**
 * __useExternalAccountsQuery__
 *
 * To run a query within a React component, call `useExternalAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useExternalAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExternalAccountsQuery({
 *   variables: {
 *   },
 * });
 */
export function useExternalAccountsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ExternalAccountsQuery,
    ExternalAccountsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ExternalAccountsQuery, ExternalAccountsQueryVariables>(
    ExternalAccountsDocument,
    options
  );
}
export function useExternalAccountsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ExternalAccountsQuery,
    ExternalAccountsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ExternalAccountsQuery,
    ExternalAccountsQueryVariables
  >(ExternalAccountsDocument, options);
}
export type ExternalAccountsQueryHookResult = ReturnType<
  typeof useExternalAccountsQuery
>;
export type ExternalAccountsLazyQueryHookResult = ReturnType<
  typeof useExternalAccountsLazyQuery
>;
export type ExternalAccountsQueryResult = Apollo.QueryResult<
  ExternalAccountsQuery,
  ExternalAccountsQueryVariables
>;
export const SpendGuardianHighlightDocument = gql`
  query SpendGuardianHighlight {
    spendGuardianHighlight {
      activeGroups {
        dueDate
        group {
          accountId
          archivedAt
          createdAt
          hasAccount
          id
          isArchived
          name
          organizationId
          programId
          seasons {
            id
            name
            startDateAt
            endDateAt
            isBudgetShared
            roster {
              id
              name
              email
            }
          }
          latestSeason {
            id
            name
            startDateAt
            endDateAt
            isBudgetShared
          }
        }
      }
      comingSoonInvoices {
        invoiceAmountDue
        invoiceDescription
        invoiceDueDate
        invoiceId
        invoiceStatus
        rosterName
        seasonName
        invoiceAutoPayAuthorized
        invoicePaymentMethod
        groupName
        isOptional
        optedIn
      }
      counts {
        activeGroups
        comingSoonInvoices
      }
    }
  }
`;

/**
 * __useSpendGuardianHighlightQuery__
 *
 * To run a query within a React component, call `useSpendGuardianHighlightQuery` and pass it any options that fit your needs.
 * When your component renders, `useSpendGuardianHighlightQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSpendGuardianHighlightQuery({
 *   variables: {
 *   },
 * });
 */
export function useSpendGuardianHighlightQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SpendGuardianHighlightQuery,
    SpendGuardianHighlightQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SpendGuardianHighlightQuery,
    SpendGuardianHighlightQueryVariables
  >(SpendGuardianHighlightDocument, options);
}
export function useSpendGuardianHighlightLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SpendGuardianHighlightQuery,
    SpendGuardianHighlightQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SpendGuardianHighlightQuery,
    SpendGuardianHighlightQueryVariables
  >(SpendGuardianHighlightDocument, options);
}
export type SpendGuardianHighlightQueryHookResult = ReturnType<
  typeof useSpendGuardianHighlightQuery
>;
export type SpendGuardianHighlightLazyQueryHookResult = ReturnType<
  typeof useSpendGuardianHighlightLazyQuery
>;
export type SpendGuardianHighlightQueryResult = Apollo.QueryResult<
  SpendGuardianHighlightQuery,
  SpendGuardianHighlightQueryVariables
>;
export const SpendGuardianHighlight2Document = gql`
  query SpendGuardianHighlight2 {
    spendGuardianHighlight {
      unauthorizedInvoices {
        invoiceId
        invoiceDescription
        seasonName
        rosterName
        invoiceDueDate
        invoiceAmountDue
        invoiceStatus
        invoiceAutoPayAuthorized
        invoiceAutoPayStopped
        invoicePaymentMethod
        groupName
        isOptional
        optedIn
        groupRosterId
        groupId
        isAuthorized
        seasonId
      }
      activeGroups {
        dueDate
        group {
          accountId
          archivedAt
          createdAt
          hasAccount
          id
          isArchived
          name
          organizationId
          programId
          seasons {
            id
            name
            startDateAt
            endDateAt
            isBudgetShared
            roster {
              id
              name
              email
            }
          }
          latestSeason {
            id
            name
            startDateAt
            endDateAt
            isBudgetShared
          }
        }
      }
      comingSoonInvoices {
        invoiceAmountDue
        invoiceDescription
        invoiceDueDate
        invoiceId
        invoiceStatus
        rosterName
        seasonName
        invoiceAutoPayStopped
        invoiceAutoPayAuthorized
        invoicePaymentMethod
        invoiceOptedOutAt
        groupName
        isAuthorized
        isOptional
        optedIn
        invoiceNote
      }
      counts {
        activeGroups
        comingSoonInvoices
        unauthorizedInvoices
      }
    }
  }
`;

/**
 * __useSpendGuardianHighlight2Query__
 *
 * To run a query within a React component, call `useSpendGuardianHighlight2Query` and pass it any options that fit your needs.
 * When your component renders, `useSpendGuardianHighlight2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSpendGuardianHighlight2Query({
 *   variables: {
 *   },
 * });
 */
export function useSpendGuardianHighlight2Query(
  baseOptions?: Apollo.QueryHookOptions<
    SpendGuardianHighlight2Query,
    SpendGuardianHighlight2QueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SpendGuardianHighlight2Query,
    SpendGuardianHighlight2QueryVariables
  >(SpendGuardianHighlight2Document, options);
}
export function useSpendGuardianHighlight2LazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SpendGuardianHighlight2Query,
    SpendGuardianHighlight2QueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SpendGuardianHighlight2Query,
    SpendGuardianHighlight2QueryVariables
  >(SpendGuardianHighlight2Document, options);
}
export type SpendGuardianHighlight2QueryHookResult = ReturnType<
  typeof useSpendGuardianHighlight2Query
>;
export type SpendGuardianHighlight2LazyQueryHookResult = ReturnType<
  typeof useSpendGuardianHighlight2LazyQuery
>;
export type SpendGuardianHighlight2QueryResult = Apollo.QueryResult<
  SpendGuardianHighlight2Query,
  SpendGuardianHighlight2QueryVariables
>;
export const SpendUserExportEmailsDocument = gql`
  query SpendUserExportEmails($input: SpendExportEmailsInput!) {
    spendUserExportEmails(input: $input) {
      content
      fileName
    }
  }
`;

/**
 * __useSpendUserExportEmailsQuery__
 *
 * To run a query within a React component, call `useSpendUserExportEmailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSpendUserExportEmailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSpendUserExportEmailsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSpendUserExportEmailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    SpendUserExportEmailsQuery,
    SpendUserExportEmailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SpendUserExportEmailsQuery,
    SpendUserExportEmailsQueryVariables
  >(SpendUserExportEmailsDocument, options);
}
export function useSpendUserExportEmailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SpendUserExportEmailsQuery,
    SpendUserExportEmailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SpendUserExportEmailsQuery,
    SpendUserExportEmailsQueryVariables
  >(SpendUserExportEmailsDocument, options);
}
export type SpendUserExportEmailsQueryHookResult = ReturnType<
  typeof useSpendUserExportEmailsQuery
>;
export type SpendUserExportEmailsLazyQueryHookResult = ReturnType<
  typeof useSpendUserExportEmailsLazyQuery
>;
export type SpendUserExportEmailsQueryResult = Apollo.QueryResult<
  SpendUserExportEmailsQuery,
  SpendUserExportEmailsQueryVariables
>;
export const RolesDocument = gql`
  query Roles {
    spendRoles {
      roles {
        groupId
        id
        isArchived
        name
        organizationId
        permissions
        isNotSignedUp
        organizationName
        groupName
      }
      count
    }
  }
`;

/**
 * __useRolesQuery__
 *
 * To run a query within a React component, call `useRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useRolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRolesQuery({
 *   variables: {
 *   },
 * });
 */
export function useRolesQuery(
  baseOptions?: Apollo.QueryHookOptions<RolesQuery, RolesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<RolesQuery, RolesQueryVariables>(
    RolesDocument,
    options
  );
}
export function useRolesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<RolesQuery, RolesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<RolesQuery, RolesQueryVariables>(
    RolesDocument,
    options
  );
}
export type RolesQueryHookResult = ReturnType<typeof useRolesQuery>;
export type RolesLazyQueryHookResult = ReturnType<typeof useRolesLazyQuery>;
export type RolesQueryResult = Apollo.QueryResult<
  RolesQuery,
  RolesQueryVariables
>;
export const PermissionsDocument = gql`
  query Permissions {
    me {
      email
      permissions
      id
    }
  }
`;

/**
 * __usePermissionsQuery__
 *
 * To run a query within a React component, call `usePermissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePermissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePermissionsQuery({
 *   variables: {
 *   },
 * });
 */
export function usePermissionsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    PermissionsQuery,
    PermissionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PermissionsQuery, PermissionsQueryVariables>(
    PermissionsDocument,
    options
  );
}
export function usePermissionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PermissionsQuery,
    PermissionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PermissionsQuery, PermissionsQueryVariables>(
    PermissionsDocument,
    options
  );
}
export type PermissionsQueryHookResult = ReturnType<typeof usePermissionsQuery>;
export type PermissionsLazyQueryHookResult = ReturnType<
  typeof usePermissionsLazyQuery
>;
export type PermissionsQueryResult = Apollo.QueryResult<
  PermissionsQuery,
  PermissionsQueryVariables
>;
export const SuperBasicUserInfoDocument = gql`
  query SuperBasicUserInfo {
    me {
      firstName
      lastName
      id
    }
  }
`;

/**
 * __useSuperBasicUserInfoQuery__
 *
 * To run a query within a React component, call `useSuperBasicUserInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useSuperBasicUserInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSuperBasicUserInfoQuery({
 *   variables: {
 *   },
 * });
 */
export function useSuperBasicUserInfoQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SuperBasicUserInfoQuery,
    SuperBasicUserInfoQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SuperBasicUserInfoQuery,
    SuperBasicUserInfoQueryVariables
  >(SuperBasicUserInfoDocument, options);
}
export function useSuperBasicUserInfoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SuperBasicUserInfoQuery,
    SuperBasicUserInfoQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SuperBasicUserInfoQuery,
    SuperBasicUserInfoQueryVariables
  >(SuperBasicUserInfoDocument, options);
}
export type SuperBasicUserInfoQueryHookResult = ReturnType<
  typeof useSuperBasicUserInfoQuery
>;
export type SuperBasicUserInfoLazyQueryHookResult = ReturnType<
  typeof useSuperBasicUserInfoLazyQuery
>;
export type SuperBasicUserInfoQueryResult = Apollo.QueryResult<
  SuperBasicUserInfoQuery,
  SuperBasicUserInfoQueryVariables
>;
