import DisplayContext from "context/display-context";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import FixedModal from "shared-components/fixed-modal/fixed-modal";
import { useContextStrict } from "../../helpers/context-strict";
import SplitIOContext from "context/splitio-context";

type UnsavedChangesProps = {
  savedChangesOpen: boolean;
  savedChangesToggle: () => void;
  path: string;
};

function UnsavedChanges({
  savedChangesOpen,
  savedChangesToggle,
  path,
}: UnsavedChangesProps) {
  const display = useContext(DisplayContext);
  const splits = useContextStrict(SplitIOContext);
  const navigate = useNavigate();
  useEffect(() => {
    setSe456flag(splits.isTreatmentOn(splits.act.se456));
  }, [splits]);
  const [se456flag, setSe456flag] = useState<boolean | undefined>();
  return (
    <FixedModal
      isOpen={savedChangesOpen}
      toggle={savedChangesToggle}
      title={"Unsaved Changes"}
      icon="exclamation-solid"
      iconColor="#FEE2E2"
      btn1={{
        text: "Leave",
        btnStyle: "danger",
        onClick: () => {
          display?.setSettingsSaved(true);
          navigate(path);
          savedChangesToggle();
        },
      }}
      btn2={{
        text: "Stay",
        btnStyle: "tertiary",
        onClick: () => {
          if (se456flag && !path.includes("settings")) {
            navigate("settings/budgeting");
          }
          savedChangesToggle();
        },
      }}
    >
      <p className="text-sm text-gray-500 text-center">
        You have unsaved changes. Leaving this page will delete any changes
        made. Do you want to leave this page?
      </p>
    </FixedModal>
  );
}

export default UnsavedChanges;
