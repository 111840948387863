import SplitIOContext from "context/splitio-context";
import UserContext from "context/user-context";
import { useContextStrict } from "helpers/context-strict";
import { DateFormatSupported, FormatDate } from "helpers/format-date";
import { useEffect, useState } from "react";
import { SnapAlert, SnapAlertTitle, SnapAlertDescription } from "suit";

function MigrationAlert() {
  const splits = useContextStrict(SplitIOContext);
  const user = useContextStrict(UserContext);

  const [spe1554BannerWarning, setSpe1554BannerWarning] = useState<
    { treatment: boolean | undefined; value: string } | undefined
  >();
  useEffect(() => {
    const userRole = user.getRole()?.toLocaleLowerCase() || "";
    if (!userRole.includes("observer")) {
      const treatmentLong = splits.getTreatment(splits.act.spe1554);
      if (!["off", "control"].includes(treatmentLong)) {
        // treatment looks like "on_05-30-2024"
        const [treatment, value] = treatmentLong.split("_");

        setSpe1554BannerWarning({ treatment: treatment === "on", value });
      }
    }
  }, [splits, user]);

  const showWarningBanner = () => {
    if (!spe1554BannerWarning?.treatment) {
      return false;
    } else {
      const date = new Date(spe1554BannerWarning.value);
      const diff = Math.abs(new Date().getTime() - date.getTime()) / 36e5 <= 48;
      if (!diff) {
        return false;
      }
    }
    return true;
  };
  const getDateData = (date?: string) => {
    if (date) {
      const dateObj = new Date(date);
      return FormatDate(dateObj, DateFormatSupported.WordsWithDOW);
    }
  };

  return spe1554BannerWarning ? (
    <>
      {showWarningBanner() && (
        <SnapAlert
          id={"recon-warning-alert"}
          type="warning"
          className="mt-4 mx-1 lg:mx-5"
        >
          <SnapAlertTitle>Staff Alert</SnapAlertTitle>
          <SnapAlertDescription>
            Partial system downtime will occur on{" "}
            <b>{getDateData(spe1554BannerWarning?.value)}</b>. Please be advised
            that reconciliation and budget data will appear missing. Do not
            attempt to re-reconcile any transaction during this time. Normal
            banking actions will still be available.
          </SnapAlertDescription>
        </SnapAlert>
      )}
    </>
  ) : null;
}

export default MigrationAlert;
